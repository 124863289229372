import React from "react";
import GetWindowDimension from "./HOC/GetWindowDimension";

function NO_DATA_AVAILABLE_IN({width, height, children, period}) {
    // return null;
    return <div style={{width:"100%",  justifyContent: "center", alignItems: "center"}}>
        <h5>{children && children.trim() ? children : "No data found for the last " + (period == "1D" ? "1 day" : period == "1W" ? "1 week" : period)}</h5>
    </div>;
}

// export const NO_DATA_AVAILABLE = GetWindowDimension(NO_DATA_AVAILABLE_IN);

export default function NO_DATA_AVAILABLE({height = null}){
    return <div style={{width: '100%', display: "flex", alignItems: 'center', justifyContent: 'center', height: height || window.innerHeight/2.2}}>
        <span>No data found</span>
    </div>;
}
