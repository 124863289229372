import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "MakeWithStyles";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./ExtendTable.css";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "../components/TablePaginationActions";
import TableSearch from "../components/TableSearch";
import Storage from "../images/resources/Storage_Account.svg";
import Function from "../images/resources/Function_App.svg";
import CosmosDB from "../images/resources/Azure_Cosmos_DB.svg";
import AppServices from "../images/resources/Application_Service.svg";
import AppCenter from "../images/resources/Platform_Service.svg";
import Adb2c from "../images/resources/Azure_Active_Directory_B2C.svg";
import LogAnalytics from "../images/resources/Application_Insights.svg";
import ApplicationGateway from "../images/resources/Application_Gateway.svg";
import AzureAppCervices from "../images/resources/API_Management_Service.svg";
import ContainerRegistry from "../images/resources/Container_Registry.svg";
import PublicIpAddress from "../images/resources/Public_IPAddress.svg";
import IotHub from "../images/resources/IoT_Hub.svg";
import KeyValut from "../images/resources/Key_Vault.svg";
import Disk from "../images/resources/Managed_Disk.svg";
import Redis from "../images/resources/Azure_Cache_for_Redis.svg";
import Profile from "../images/resources/CDN_Profile.svg";
import NameSpace from "../images/resources/namespaces.svg";
import LoadBalance from "../images/resources/Load_Balancer.svg";
import VirtualMachine from "../images/resources/Virtual_Machine.svg";
import WorkSpaces from "../images/resources/Workspace.svg";
import WorkFlow from "../images/resources/workflows.svg";
import ServerFarms from "../images/resources/serverfarms.svg";
import VirtualNetwork from "../images/resources/Virtual_Network.svg";
import VirtualMachineScalable from "../images/resources/Virtual_Machine_Scale_Set.svg";
import ResourceGroup from "../images/resources/Resource_Group.svg";
import Format from "./NumberFormat";
import colors from "styles/color";
import {OverlayTrigger, Tooltip, Popover} from "react-bootstrap";

const StyledTableCell = withStyles(TableCell,(theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
        fontWeight: "bold"
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: theme.spacing(1.2),
    },
}));
const StyledTableRow = withStyles(TableRow,(theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            // backgroundColor: theme.palette.action.hover,
        },
    },
}));

const useRowStyles = makeStyles()({
    root: {
        "& > *": {
            borderBottom: "unset",
            padding: 3.5,
        },
    },
    table: {
        minWidth: 500,
    },
    container: {
        maxHeight: 440,
    },
    container1: {
        maxHeight: 400,
    },
});

export default function ExpandableTable(props) {
    const { tableRow, data, currency, dropdown } = props;
    const {classes} = useRowStyles();
    const [subName, setSubname] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [search, setSearch] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.resourceId,
                f?.resourceType,
                f?.subscriptionName,
                f?.resourceGroupName,
                
            ], search);
        }
        return true;
    }

    useEffect(() => {
        tableRow.map((i) => {
            "subscriptionName" in i ? setSubname(true) : setSubname(false);
        });
    }, [tableRow]);

    return (
        <Paper elevation={0}>
            <TableContainer className={classes.container}>
            <TableSearch onSearch={setSearch} />
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell />
                            <StyledTableCell align="inherit">
                                Resource name
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                Resource type
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                Location
                            </StyledTableCell>
                            {dropdown == "Resources" ? (
                                <StyledTableCell align="left" >
                                    Resource group
                                </StyledTableCell>
                            ) : null}
                           
                            <StyledTableCell align="center">
                                Cost
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableRow?.filter(filteredTable)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((r, i) => (
                            <ExpandRow row={r} data={data} index={i} currency={currency} dropdown={dropdown} slicedData={tableRow?.filter(filteredTable)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination ActionsComponent={TablePaginationActions}
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={tableRow?.filter(filteredTable)?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
        </Paper>
    );
}

export function ExpandRow(props) {
    const { row, data, index, currency, dropdown, slicedData } = props;
    const [open, setOpen] = useState(false);
    const [list, setList] = useState(null);
    const {classes} = useRowStyles();

    return (
        <React.Fragment>
            <StyledTableRow hover className={classes.root} key={row.resourceId}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell component="th" scope="row" size="small" padding="none">
                    {Format.checkSC(row.resourceType) ? (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {row.resourceType.split(Format.getSC(row.resourceType))[1] == "b2cdirectories" && <img src={Adb2c} width={40} height={35} />}
                            {row.resourceType.split(Format.getSC(row.resourceType))[1] == "workspaces" && <img src={WorkSpaces} width={25} height={20} style={{ marginRight: 5, marginLeft: 8 }} />}
                            {row.resourceType.split(Format.getSC(row.resourceType))[1] == "serverfarms" && <img src={ServerFarms} width={25} height={20} style={{ marginRight: 5, marginLeft: 10 }} />}
                            {row.resourceType.split(Format.getSC(row.resourceType))[1] == "loadbalancers" && <img src={LoadBalance} width={40} height={35} />}
                            {row.resourceType.split(Format.getSC(row.resourceType))[1] == "publicipaddresses" && <img src={PublicIpAddress} width={40} height={35} />}
                            {row.resourceType.split(Format.getSC(row.resourceType))[1] == "redis" && <img src={Redis} width={40} height={35} />}
                            {row.resourceType.split(Format.getSC(row.resourceType))[1] == "disks" && <img src={Disk} width={35} height={25} style={{ marginRight: 5, marginLeft: 4 }} />}
                            {row.resourceType.split(Format.getSC(row.resourceType))[1] == "namespaces" && <img src={NameSpace} width={25} height={20} style={{ marginRight: 5, marginLeft: 10 }} />}
                            {row.resourceType.split(Format.getSC(row.resourceType))[1] == "iothubs" && <img src={IotHub} width={40} height={35} />}
                            {row.resourceType.split(Format.getSC(row.resourceType))[1] == "applicationgateways" && <img src={ApplicationGateway} width={40} height={35} />}
                            {row.resourceType.split(Format.getSC(row.resourceType))[1] == "profiles" && <img src={Profile} width={40} height={35} />}
                            {row.resourceType.split(Format.getSC(row.resourceType))[1] == "vaults" && <img src={KeyValut} width={40} height={35} />}
                            {row.resourceType.split(Format.getSC(row.resourceType))[1] == "virtualmachinescalesets" && <img src={VirtualMachineScalable} width={40} height={35} />}
                            {row?.resourceType == "virtualmachines" && <img src={VirtualMachine} width={40} height={35} />}
                            {row.resourceType.split(Format.getSC(row.resourceType))[1] == "workflows" && <img src={WorkFlow} width={20} height={20} style={{ marginRight: 5, marginLeft: 10 }} />}
                            {"resourceId" in row ? row?.resourceId == "" ? <span style={{ color: colors.gray }}>{"unassigned"}</span> : row?.resourceId.replace(/\//g,"") : <span style={{ color: colors.gray }}>{"unassigned"}</span>}
                        </div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {row.resourceType == "Storage account" && <img src={Storage} width={40} height={35} />}
                            {row?.resourceType == "Application Insights" && <img src={LogAnalytics} width={40} height={35} />}
                            {row?.resourceType == "App Service" && <img src={AppServices} width={40} height={35} />}
                            {row?.resourceType == "API Management service" && <img src={AzureAppCervices} width={40} height={35} />}
                            {row?.resourceType == "Azure Cosmos DB account" && <img src={CosmosDB} width={40} height={35} />}

                            {"resourceId" in row ? row?.resourceId == "" ? <span style={{ color: colors.gray }}>{"unassigned"}</span> : row?.resourceId.replace(/\//g,"") : <span style={{ color: colors.gray }}>{"unassigned"}</span>}
                        </div>
                    )}
                </TableCell>
                <TableCell align="left">{Format.checkSC(row.resourceType) ? row.resourceType.split(Format.getSC(row.resourceType))[1] : row.resourceType}</TableCell>
                <TableCell align="left">{row.location}</TableCell>

                {dropdown == "Resources" ? <TableCell align="left">{row.resourceGroupName}</TableCell> : null}


                <TableCell align="center">{currency + Format.formatNumber(Number(row["cost"]).toFixed(2))}</TableCell>
            </StyledTableRow>
            <ExpandLavelData data={{resources: slicedData}} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

export function ExpandLavelData({ data, open, select, currency }) {
    const [resource, setResource] = useState(null);

    useEffect(() => {
        if (select != null) {
            let value = data.resources[select];
            setResource(value);
        }
    }, [select, data]);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2.5}>{resource && <Htmltable data={resource} currency={currency} />}</Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <div style={{ maxHeight: 300 }} className="overflow-auto">
            <table id="customers">
                <tr>
                    <th>Service</th>
                    <th>Meter</th>
                    <th>Cost</th>
                </tr>

                {data &&
                    data.services.map((i) =>
                        i.meter.map((m) => (
                            <tr>
                                <td>{m.serviceName}</td>
                                <td>{m.meterName}</td>
                                <td>{currency + Format.formatNumber(Number(m["cost"]))}</td>
                            </tr>
                        ))
                    )}
            </table>
        </div>
    );
}
