import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "MakeWithStyles";
import { Table, TableBody, TableCell as TCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Divider, LinearProgress } from "@mui/material";
import storage_account_icon from "../../images/resources/Storage_Account.svg";
import function_apps_icon from "../../images/newCloudTrakrIcons/AzureFunction.png";
import Cost from "../../components/Cost";
import { bytesToSize, convertToInternationalCurrencySystem } from "../../util/Miscellaneous";
import axios from "../../connection/axios";
import { CancelRounded } from "@mui/icons-material";
import SlideShow from "../../components/CustomSlider/SlideShow";
import ReactD3BarChart from "../../components/D3Charts/ReactD3BarChart";
import { commonConversion } from "../../util/DateRangeFormatter";
import Format from "components/NumberFormat";
import { getAzureFunctionRequest,getAzureFunctionAccountRequest,getAzureFunctionTenantRequest } from "redux/actions/actions";
import { Tooltip, OverlayTrigger,Popover } from "react-bootstrap";

import LineChart from "pages/Charts/LineChart";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "../../components/TablePaginationActions";
import TableSearch from "../../components/TableSearch";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import DetailsDialogBox from '../../components/DetailsDialogBox';

import Collapse from "@mui/material/Collapse";

import IconButton from "@mui/material/Icon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import moment from "moment";

const { REACT_APP_GET_AZURE_FUNCTIONS, REACT_APP_GET_AZURE_FUNCTION_DETAILS, REACT_APP_GET_AZURE_SUBSCRIPTION_FUNCTIONS, REACT_APP_GET_AZURE_FUNCTIONS_APP_INSIGHTS_METRICS } = process.env;

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        //marginBottom: 5,
    },
}));

function TableCell(props) {
    return (
        <TCell {...props} style={{ padding: 0,paddingRight:8 }}>
            {props.children}
        </TCell>
    );
}

export default function AzureFunctions(props) {
    const dispatch = useDispatch();
    const { all, tenantId, subscriptionId, progress, customerId,currency } = props;

    const {classes} = useStyles();
    const [data, setData] = useState(null);
    const [showingFunction, setShowingFunction] = useState(null);
    const [showingMetrics, setShowingMetrics] = useState(null);
    const [metricsData, setMetricsData] = useState([]);
    // const [functionsList, setFunctionsList] = useState([]);
    const [showMetricSpecific, setShowMetricSpecific] = useState([]);
    const [metricTotals, setMetricTotals] = useState([]);
    const [page, setPage] = React.useState(0);  
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState(null);
    const [list, setList] = useState(null);

    const [detailsLoading, setDetailsLoading] = useState(false);
    const [openDetails, setOpenDetails] = useState(false);

    const handleClose = () => {
        setOpenDetails(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (subscriptionId) {
            dispatch(getAzureFunctionAccountRequest({
                customerId,
                subscriptionId,
                tenantId,
            }));
                
           
        }else if(tenantId){
            dispatch(
                getAzureFunctionTenantRequest({
                    customerId,
                    tenantId,
                })
            );
        } else {
            if(functionsList==null){
            dispatch(getAzureFunctionRequest({
                customerId,
            }));
        }
        }
    }, [customerId,subscriptionId,tenantId]);

    
    let functionsList = useSelector((state) => subscriptionId ? state?.azureFunctionReducer?.azureFunctionSubData:(tenantId)?state?.azureFunctionReducer?.azureFunctionTenantData:state?.azureFunctionReducer?.azureFunctionData);
    let loading = useSelector((state) => subscriptionId ? state?.azureFunctionReducer?.azureFunctionSubLoading : state?.azureFunctionReducer?.loading );


    // useEffect(() => {
        
    //     if (customerId){
    //         // setFunctionsList(commonConversion(AzureFunctions.data.azureFunctions.map(d=>d.rows ).flat(1)))
    //         axios
    //             .get(subscriptionId ? REACT_APP_GET_AZURE_SUBSCRIPTION_FUNCTIONS : REACT_APP_GET_AZURE_FUNCTIONS, {
    //                 params: {
    //                     customerId,
    //                     subscriptionId,
    //                     tenantId
    //                 },
    //             })
    //             .then((response) => response.data)
    //             .then((response) => {
    //                 console.log('AzureFunctions',response?.data)
    //                 setFunctionsList(response?.data)
    //                 // var functionApps = response?.data?.azureFunctions?.map((a) => a.rows).flat(1) || [];
    //                 // functionApps = functionApps.map((d) => {
    //                 //     return {
    //                 //         location: d[3],
    //                 //         currency: d[5],
    //                 //         date: d[1],
    //                 //         subscriptionId: d[2].split("/")[2],
    //                 //         subscriptionName: d[4],
    //                 //         resourceGroup: d[2].split("/")[4],
    //                 //         functionApp: d[2].split("/")[8],
    //                 //         cost: d[0],
    //                 //     };
    //                 // });
    //                 // const s = new Set(functionApps.map((d) => d.functionApp));

    //                 // setFunctionsList(
    //                 //     Array.from(s).map((k) => {
    //                 //         const data = functionApps.filter((d) => d.functionApp === k);
    //                 //         return { ...data?.[0], cost: data.map((d) => parseFloat(d.cost)).reduce((a, b) => a + b, 0) };
    //                 //     })
    //                 // );
    //             })
    //             .finally(() => setLoading(false));
    //         }
    // }, [customerId, subscriptionId, tenantId]);

    function showFunctionDetails(e) {
        setShowingFunction({ ...e });
        setDetailsLoading(true)
        axios
            .get(REACT_APP_GET_AZURE_FUNCTION_DETAILS, {
                params: {
                    customerId,
                    subscriptionId: e.subscriptionId,
                    resourceGroups: e.resourceGroupName,
                    functionAppName: e.resourceId,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200 && response.data) {
                        setShowingFunction({ ...e, ...response.data?.functionAppDetails?.[0] });
                        getFunctionMetricData(e);
                    } else {
                    }
                } else {
                }
            })
            .catch((e) => {})
            .finally(() => setDetailsLoading(false));
    }

    function getFunctionMetricData(e) {
        setDetailsLoading(true)
        axios
            .get(REACT_APP_GET_AZURE_FUNCTIONS_APP_INSIGHTS_METRICS, {
                params: {
                    customerId,
                    subscriptionId: e.subscriptionId,
                    resourceGroups: e.resourceGroupName,
                    functionAppName: e.resourceId,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200 && response.data) {
                        setShowingMetrics(response?.data?.metrics);
                    } else {
                    }
                } else {
                }
            })
            .catch((e) => {})
            .finally(() => setDetailsLoading(false));
    }

    useEffect(() => {
        if (showingMetrics) {
            var result = [];
            for (var obj in showingMetrics) {
                const selected = showingMetrics[obj];

                const name = selected.totalData.value?.[0].name?.localizedValue;
                const unit = selected.totalData.value?.[0]?.unit;

                var metricData = selected.totalData.value?.[0].timeseries?.[0]?.data?.[0];
                var timeseries = selected.metrics.value?.[0].timeseries?.[0]?.data;

                if(metricData && timeseries){
                    var dataKey = new Set(Object.keys(metricData));
                    dataKey.delete("timeStamp");
                    dataKey = dataKey.keys().next().value;

                    var dataKeyT = new Set(Object.keys(timeseries?.[0]));
                    dataKeyT.delete("timeStamp");
                    dataKeyT = dataKeyT.keys().next().value;

                    timeseries = timeseries.map(({ timeStamp, ...rest }) => {
                        let date = new Date(timeStamp);
                        date = date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth()+1) : (date.getMonth()+1)) + "-" + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
                        return { date, value: rest[dataKeyT] };
                    });

                    timeseries = sumDuplicates(timeseries, "date", "value").map(({ date, ...rest }) => ({ ...rest, date: new Date(date) }));
                    metricData = { timeseries, unit, total: metricData[dataKey] };
                    result.push({ name, unit, metricData });
                }
            }
            setMetricsData(result);
            setMetricTotals(result.map((r, i) => r?.metricData?.timeseries.map((r) => r.value).reduce((a, b) => a + b)));
        }
    }, [showingMetrics]);

    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.resourceId,
                f?.subscriptionName,
                f?.location,
                f?.resourceGroupName,
                f?.cost,
                
            ], search);
        }
        return true;
    }

    return loading ? (
        <div className={classes.root}>
            <LinearProgress variant="determinate" value={progress} />
        </div>
    ) : (
        <div>
            <ContainerCardWithDateRange
                title={"Function Apps"}
                titleIcon={function_apps_icon}
                defaultPeriod={"1M"}
                showDateRange={true}
               
                collapsible={true}
                
                datePeriod={({ period }) => ({
                    start:"__",
                    end: "__",
                    rawStart: moment(functionsList?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(functionsList?.[period]?.endDate, "YYYYMMDD"),
                })}
                totalCost={({ period }) => currency + Format.formatNumber(functionsList?.[period]?.resources?.map((t) => parseFloat(t.cost)).reduce((a, b) => a + b, 0))}
                datePeriodEnabled={true}
                showDatePeriod={(period) => !!functionsList != null && functionsList?.[period]?.resources?.length}
                onPeriodChange={()=>handleChangeRowsPerPage({target: {value: 10}})}
            >
                {({ period }) => (
            <div elevation={10} style={{ padding: 10 }}>
                 {functionsList && functionsList?.[period]?.resources?.length ? (
                <>                    
                <Box display={!loading && functionsList!=null && functionsList?.[period]?.resources?.length ? "flex" : "none"}>
                    <Box flex={showingFunction?.[period]?.resources?.length ? 0.25 : 1}>
                    <TableSearch onSearch={setSearch} />
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead style={{ backgroundColor: "#cfdac8" }}>
                                    <TableRow>
                                        <TableCell/>
                                        <TableCell>
                                            <b>Name</b>
                                        </TableCell>
                                        <TableCell align="left">
                                            <b>Subscription</b>
                                        </TableCell>
                                                <TableCell align="left">
                                                    <b>Resource Group</b>
                                                </TableCell>
                                                
                                                <TableCell align="left">
                                                    <b>Location</b>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <b>Cost</b>
                                                </TableCell>
                                            
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ padding: 0 }}>
                                    {functionsList?.[period]?.resources
                                        ?.sort((r1, r2) => r2.cost - r1.cost)
                                        ?.filter(filteredTable)
                                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row,index) => (
                                            <MyTableRow  functionsList={functionsList} period={period} row={row} index={index} list={list} setList={setList} currency={currency} setOpenDetails={setOpenDetails} showFunctionDetails={(row)=>showFunctionDetails(row)}  />
                                                            
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    {/* <Box flex={showingFunction ? 0.75 : 0}>
                        {showingFunction ? (
                            <Paper elevation={5} style={{ padding: 10 }}>
                                <Box display={"flex"} alignItems={"center"}>
                                    <Box display={"flex"} flex={1} justifyContent={"space-between"} style={{ paddingRight: 10 }} alignItems={"center"}>
                                        <Typography component={"h5"} variant={"h5"}>
                                            <img src={function_apps_icon} height={25} /> {showingFunction?.name}
                                        </Typography>
                                        <Typography component={"h5"} variant={"h5"}>
                                            {currency}
                                            <Cost>{showingFunction?.cost}</Cost>
                                        </Typography>
                                    </Box>
                                    <CancelRounded
                                        onClick={() => {
                                            setShowingFunction(null);
                                        }}
                                    />
                                </Box>
                                <Divider />


                                <Box style={{ padding: 10 }} flexDirection={"row"} display={"flex"}>
                                    <Box flex={1}>
                                        <Typography>
                                            <b>Location</b>
                                        </Typography>
                                        <Typography>{showingFunction?.location || "-"}</Typography>
                                        <Typography style={{ marginTop: 10 }}>
                                            <b>Resource Group</b>
                                        </Typography>
                                        <Typography>{showingFunction?.resourceGroup}</Typography>
                                    </Box>
                                    <Box flex={1}>
                                        <Typography style={{ marginTop: 10 }}>
                                            <b>Subscription Id</b>
                                        </Typography>
                                        <Typography>{showingFunction?.subscriptionId}</Typography>
                                        <Typography style={{ marginTop: 10 }}>
                                            <b>Subscription Name</b>
                                        </Typography>
                                        <Typography>{showingFunction?.subscriptionName}</Typography>
                                        <Typography style={{ marginTop: 10 }}>
                                            <b>Status</b>
                                        </Typography>
                                        <Typography>{showingFunction?.properties?.state}</Typography>
                                    </Box>
                                </Box>
                                <Divider />

                                <Box>
                                    {metricsData && metricsData.length
                                        ? metricsData.map((m, i) => (
                                              <Box>
                                                  <Typography>{m.name}</Typography>
                                                  <LineChart
                                                      // height={500} width={1200}
                                                      data={m?.metricData?.timeseries}
                                                      currency={currency}
                                                      currencyRequired={false}
                                                      unit={m?.unit}
                                                      onMouseOverData={(data) => {
                                                          let d = showMetricSpecific;
                                                          d[i] = data;
                                                          setShowMetricSpecific(d);
                                                      }}
                                                  />
                                                  <Box>
                                                      <Typography>
                                                          {m.name}:{" "}
                                                          {
                                                              {
                                                                  Count: convertToInternationalCurrencySystem(showMetricSpecific?.[i] || metricTotals[i]),
                                                                  Bytes: bytesToSize(showMetricSpecific?.[i] || metricTotals[i]),
                                                              }[m?.unit]
                                                          }{" "}
                                                          {m?.unit}
                                                      </Typography>
                                                  </Box>
                                              </Box>
                                          ))
                                        : null}
                                </Box>
                            </Paper>
                        ) : null}
                    </Box> */}
                </Box>
                 <TablePagination
                    ActionsComponent={TablePaginationActions}
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={functionsList?.[period]?.resources?.filter(filteredTable)?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    /> 
                                      </>
                        ) : (
                            <Typography style={{ display: "flex", justifyContent: "center" }}>No data found</Typography>
                        )}

                        <DetailsDialogBox
                            open={openDetails}
                            data={showingFunction}
                            title={'Azure functions'}
                            detailsData={showingFunction}
                            chartData={metricsData}
                            handleClose={() => {
                                handleClose();
                            }}
                            currency={currency}
                            loading={detailsLoading}
                            metricTotals={metricTotals}
                            rawStart={moment(functionsList?.[period]?.startDate, "YYYYMMDD")}
                            rawEnd={moment(functionsList?.[period]?.endDate, "YYYYMMDD")}
                        />
            </div>
             )}
             </ContainerCardWithDateRange>
        </div>
    );
}


export function MyTableRow(props) {
    const { functionsList, period, row, index, list, setList, currency, showFunctionDetails,setOpenDetails } = props;
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            
             <TableRow key={row?.resourceId+index}>
             <TableCell>
                    <IconButton
                        style={{ cursor: "pointer" }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                                                <TableCell component="th" scope="row" style={{ display: "flex", alignItems: "center" }}>
                                                    <img src={function_apps_icon} height={22} style={{ marginRight: 5 }} />{" "}
                                                    <a
                                                        href={"#"}
                                                        onClick={() => {
                                                            showFunctionDetails({...row, subscriptionId:row?.subscriptionId || functionsList?.subscriptionId});
                                                            setOpenDetails(true);
                                                            // setShowStorage(row);
                                                            // meterStorageAccountCharts(row);
                                                        }}
                                                        
                                                    >
                                                        {row?.resourceId}
                                                    </a>
                                                </TableCell>
                                                <TableCell component="th" scope="row" style={{ display: "flex", alignItems: "center" }}>
                                                <OverlayTrigger
                                                                                placement="bottom"
                                                                                overlay={
                                                                                    <Popover id="popover-contained">

                                                                                        <Popover.Body style={{fontSize:11}}>
                                                                                            {row?.subscriptionId || functionsList?.subscriptionId}
                                                                                        </Popover.Body>
                                                                                    </Popover>
                                                                                }
                                                                            >
                                                                                <div>
                                                {row?.subscriptionName || functionsList?.subscriptionName}
                                                 </div>
                                                                            </OverlayTrigger>
                                                </TableCell>

                                                {/* {showingFunction ? null : (
                                                    <> */}
                                                        <TableCell align="left">{row?.resourceGroupName}</TableCell>
                                                        <TableCell align="left">{row?.location}</TableCell>
                                                        <TableCell align="right">
                                                            {currency}
                                                            {parseFloat(row?.cost).toFixed(2)}
                                                        </TableCell>
                                                    {/* </>
                                                )} */}
                                            </TableRow>
            <ExpandLavelData index={index} data={row} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

export function ExpandLavelData({ data, open, select, set, currency }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                       <Box margin={2.5}>
                            <Htmltable data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <div style={{ maxHeight: 300 }} className="overflow-auto">
            <table id="customers">
                 {data?.billing && Object.keys(data?.billing)?.length ? (
                    <tr>
                        <th>Service name</th>
                        <th>Meter name</th>                      
                        <th>Cost</th>
                    </tr>
                ):null}

                {data &&
                    data?.billing?.map((b) =>
                    Object.keys(b)?.length ? (
                        b?.meters?.map((m) =>
                        <tr>
                            <td>{m?.serviceName||'-'}</td>
                            <td>{m?.meterName||'-'}</td>
                            <td>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                        </tr>
                        )
                    ) : null
                )}
                    
            </table>
        </div>
    );
}


function sumDuplicates(data, checkKey, valueKey) {
    var result = [];
    data.forEach(function (a) {
        if (!this[a[checkKey]]) {
            const data = {};
            data[checkKey] = a[checkKey];
            data[valueKey] = 0;
            // this[a.date] = { date: a.date, value: 0 };
            this[a[checkKey]] = data;
            result.push(this[a[checkKey]]);
        }
        this[a[checkKey]][valueKey] += a[valueKey];
    }, Object.create(null));
    return result;
}
