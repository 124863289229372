import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getAwsRDSRecommendationSuccess, getAwsRDSRecommendationFailure,getAwsAccountRDSRecommendationFailure,getAwsAccountRDSRecommendationSuccess} from "../actions/actions";
const { REACT_APP_AWS_RDS_RECOMMENDATION,REACT_APP_AWS_RDS_ACCOUNT_RECOMMENDATION } = process.env;

function* getawsRDSRecommendationSagaData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AWS_RDS_RECOMMENDATION, {
            params: {
                customerId: payload.customerId,
               
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAwsRDSRecommendationFailure(statusCode));
            } else {
                yield put(getAwsRDSRecommendationSuccess(res.data));
            }
        } else {
            yield put(getAwsRDSRecommendationFailure(res));
        }
    } catch (error) {
        yield put(getAwsRDSRecommendationFailure(error));
    }
}

function* getawsRDSRecommendationAccountSagaData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AWS_RDS_ACCOUNT_RECOMMENDATION, {
            params: {
                customerId: payload.customerId,
                tenantId:payload.tenantId,
                subscriptionId:payload.subscriptionId
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAwsAccountRDSRecommendationFailure(statusCode));
            } else {
                yield put(getAwsAccountRDSRecommendationSuccess(res.data));
            }
        } else {
            yield put(getAwsAccountRDSRecommendationFailure(res));
        }
    } catch (error) {
        yield put(getAwsAccountRDSRecommendationFailure(error));
    }
}


export default function* awsRDSRecommendationSaga() {
    yield takeLatest(types.AWS_RDS_RECOMMENDATION_REQUEST, getawsRDSRecommendationSagaData);
    yield takeLatest(types.AWS_RDS_ACCOUNT_RECOMMENDATION_REQUEST, getawsRDSRecommendationAccountSagaData);

}
