import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "MakeWithStyles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { getBudgetSummaryRequest } from "../../redux/actions/actions";
import { useSelector, useDispatch } from "react-redux";
import VerticalBarChart from "../Charts/VerticalBarChart";
import { Paper } from "@mui/material";
import { CircularProgress } from "@mui/material/";

const Months = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
const useStyles = makeStyles()((theme) => ({
    button: {
        margin: theme.spacing(0.5),
    },
    table: {
        maxHeight: 100,
    },
}));

export default function BudgetSummaryChart({ cloud = null, tenantId = null, customerId }) {
    const dispatch = useDispatch();
    const [chart, setChart] = useState([]);
    const [chartTotalCost, setChartTotalCost] = useState("");
    const [budgetPeriod, setbudgetPeriod] = useState("monthly");

    const {classes} = useStyles();
    // const userData = useSelector((state) => ({
    //   id: state.userDetailsReducer.id,
    // }));
    const getbudgetsSummary = useSelector((state) => state.getbudgetSummaryReducer?.budgets?.billingData);
    const budgetsAmount = useSelector((state) => state.getbudgetSummaryReducer?.budgets?.budget?.budgetAmount);

    const alertLevelPercentage1 = useSelector((state) => state.getbudgetSummaryReducer?.budgets?.budget?.alertLevelPercentage);

    var alertAmount = (alertLevelPercentage1 / 100) * (budgetsAmount || 0);
    alertAmount = Number.isNaN(alertAmount) ? 0 : alertAmount;
    alertAmount = alertAmount == 0 ? 0 : alertAmount.toFixed(2);

    const currency = useSelector((state) => state.getbudgetSummaryReducer?.budgets?.currency);
    const loading = useSelector((state) => state.getbudgetSummaryReducer?.loading);

    useEffect(() => {
        // let customerId = userData.id;
        let tenantID = tenantId;
        let budgetLevel = cloud;
        let budgetType = budgetPeriod;

        dispatch(getBudgetSummaryRequest({ customerId, tenantID, budgetLevel, budgetType }));
    }, []);

    useEffect(() => {
        if (budgetPeriod == "monthly") {
            getSummaryDataForMonths();
        }
        handlevent(budgetPeriod);
    }, [getbudgetsSummary]);

    function handleClick(e) {
        setChart([]);
        setbudgetPeriod(e);

        // let customerId = userData.id;
        let tenantID = tenantId;
        let budgetLevel = cloud;
        let budgetType = e;
        dispatch(getBudgetSummaryRequest({ customerId, tenantID, budgetLevel, budgetType }));
    }

    function handlevent(e) {
        if (e == "daily") {
            getSummaryDataForDaily();
        } else if (e == "monthly") {
            getSummaryDataForMonths();
        } else if (e == "quarterly") {
            getSummaryDataForQuaterly();
        } else if (e == "yearly") {
            getSummaryDataForYearly();
        }
    }

    function getSummaryDataForDaily() {
        let data = [];
        for (let i = 0; i < getbudgetsSummary?.length; i++) {
            data.push({
                label: getbudgetsSummary[i]?.date,
                value: getbudgetsSummary[i]?.dailyTotalCost,
                line: budgetsAmount,
                line2: (budgetsAmount * alertLevelPercentage1) / 100,
            });
        }
        setChart(data);
    }

    function getSummaryDataForMonths() {
        let data = [];
        let months1;
        for (let i = 0; i < getbudgetsSummary?.length; i++) {
            months1 = Number(getbudgetsSummary[i]?.month).toString();
            data.push({
                label: Months[months1] + ", " + getbudgetsSummary[i]?.year,
                value: getbudgetsSummary[i]?.monthlyTotalCost,
                line: budgetsAmount,
                line2: (budgetsAmount * alertLevelPercentage1) / 100,
            });
        }
        setChart(data);
    }

    function getSummaryDataForQuaterly() {
        let data = [];
        let startMonth;
        let startYear;
        let endMonth;
        let endyear;

        for (let i = 0; i < getbudgetsSummary?.length; i++) {
            startYear = getbudgetsSummary[i]?.quarterlyStartYearMonth.substring(0, 4);
            startMonth = getbudgetsSummary[i]?.quarterlyStartYearMonth.substring(4);

            endyear = getbudgetsSummary[i]?.quarterlyEndYearMonth.substring(0, 4);
            endMonth = getbudgetsSummary[i]?.quarterlyEndYearMonth.substring(4);

            data.push({
                label: Months[Number(startMonth).toString()] + " " + startYear + " - " + Months[Number(endMonth).toString()] + " " + endyear,
                value: getbudgetsSummary[i]?.quarterlyTotalCost,
                line: budgetsAmount,
                line2: (budgetsAmount * alertLevelPercentage1) / 100,
            });
        }
        setChart(data);
    }

    function getSummaryDataForYearly() {
        let data = [];
        for (let i = 0; i < getbudgetsSummary?.length; i++) {
            data.push({
                label: getbudgetsSummary[i]?.year,
                value: getbudgetsSummary[i]?.yearlyTotalCost,
                line: budgetsAmount,
                line2: (budgetsAmount * alertLevelPercentage1) / 100,
            });
        }
        setChart(data);
    }

    return (
        <div style={{ marginTop: -20 }}>
            <FormControl component="fieldset">
                <RadioGroup row aria-label="position" name="position" defaultValue={budgetPeriod} onChange={(e) => handleClick(e.target.value)} value={budgetPeriod}>
                    <FormControlLabel value="daily" control={<Radio color="primary" />} label="Daily" labelPlacement="end" />
                    <FormControlLabel value="monthly" control={<Radio color="primary" />} label="Monthly" labelPlacement="end" />
                    <FormControlLabel value="quarterly" control={<Radio color="primary" />} label="Quarterly" labelPlacement="end" />
                    <FormControlLabel value="yearly" control={<Radio color="primary" />} label="Yearly" labelPlacement="end" />
                </RadioGroup>
            </FormControl>
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    {chart?.length ? (
                        <Paper elevation={3}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignSelf: "center",
                                }}
                            >
                                <VerticalBarChart title={`View ${budgetPeriod} budgets`} budgetamount={budgetsAmount} threshold={alertAmount} currency={currency} mouseEffect={false} data={chart} />
                            </div>
                        </Paper>
                    ) : (
                        <span>
                            No chart found for {cloud} {budgetPeriod}{" "}
                        </span>
                    )}
                </div>
            )}
        </div>
    );
}
