import React, { useState, useEffect } from "react";
import AWS_LOGO from "../../../images/AWS_LOGO";
import ConnectionHeader from "../../../components/ConnectionHeader";
import axios from "../../../connection/axios";
import LeftArrow from "../../../images/left-arrow.png";
import { Button, Form, InputGroup, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import RoutesList from "../../../routes/RoutesList";
import Color from "../../../styles/color";
import { Paper, CircularProgress, Stepper, Step, StepLabel, StepContent, Typography, StepButton, colors, Radio, RadioGroup, FormControlLabel, FormControl } from "@mui/material";
import { Alert, AlertTitle } from "@mui/lab";
import { Link } from "react-router-dom";
import { Tooltip, OverlayTrigger,Popover } from "react-bootstrap";
// import { ReportGmailerrorredIcon } from "@mui/icons-material";
import ReportIcon from '@mui/icons-material/Report';
import Copy from "../../../images/copy.svg";
import {makeStyles} from "MakeWithStyles";
const { REACT_APP_AWS_ACCOUNT_INSTRUCTIONS, REACT_APP_EDIT_AWS_CONNECTION, REACT_APP_AWS_CLOUD_FORMATION } = process.env;

const useStyles = makeStyles()((theme) => ({
    root: {
        display: "flex",
        "& > * + *": {
            marginLeft: theme.spacing(2),
            justifyContent: "center",
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
    },
}));

export default function EditAWSAccount(props) {
    const [isArnGenerated, setIsArnGenerated] = useState(false);
    const [connectionLoading, setConnectionLoading] = useState(false);
    const [cloudFormationLoading, setCloudFormationLoading] = useState(false);
    const [s3URL, setS3URL] = useState("");
    const [connectionDialog, toggleConnectionDialog] = useState(!false);
    const [uuid, setuuid] = useState(""); //props.data.uuidAccountId
    const [s3BucketName, setS3BucketName] = useState(""); //props.data.s3BucketName
    const [s3bucketCostreportName, setS3bucketCostreportName] = useState(""); //props.data.s3BucketCostReportName
    const [reportPathPrefix, setreportPathPrefix] = useState(""); //props.data.s3bucketCostreportPathPrefix

    const [displayName, setDisplayName] = useState(""); //props.data.displayName
    const [roleArn, setRoleArn] = useState(""); //props.data.roleArn

    const [s3BucketError, setS3BucketError] = useState({
        status: false,
        msg: "",
    });
    const [reportNameError, setReportNameError] = useState({
        status: false,
        msg: "",
    });
    const [reportPathPrefixError, setReportPathPrefixError] = useState({
        status: false,
        msg: "",
    });
    const [roleARNError, setRoleARNError] = useState({ status: false, msg: "" });
    const [displayNameError, setDisplayNameError] = useState({
        status: false,
        msg: "",
    });

    const {classes} = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [alertTitle, setAlertTitle] = useState("");
    const [alertMsg, setAlertMsg] = useState("");

    const [alertOpen, setalertOpen] = useState(false);

    const { customerId } = useSelector((state) => ({
        customerId: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
    }));

    function onConnectionSuccess() {
        props.onConnectionSuccess();
        setalertOpen(false);
    }

    function validateFields() {
        var noErrors = true;
        if (!s3BucketName.trim()) {
            setS3BucketError({
                status: true,
                msg: "Please enter the S3 bucket name",
            });
            noErrors = false;
        } else if (!/^[a-z0-9.\-_]{1,255}$/.test(s3BucketName.trim())) {
            setS3BucketError({
                status: true,
                msg: "Bucket name must not contain spaces or uppercase letters",
            });
            noErrors = false;
        }

        if (!s3bucketCostreportName.trim()) {
            setReportNameError({ status: true, msg: "Please enter the Report Name" });
            noErrors = false;
        } else if (!/^[a-zA-Z0-9.*!'()\-_]{1,255}$/.test(s3bucketCostreportName.trim())) {
            setReportNameError({
                status: true,
                msg: "Report name must be unique and only contains alphanumerics and characters ! - _ . * ' ( )",
            });
            noErrors = false;
        }
        if (!reportPathPrefix.trim()) {
            setReportPathPrefixError({
                status: true,
                msg: "Please enter the Report Path Prefix",
            });
            noErrors = false;
        } else if (!/^[a-zA-Z0-9.*!'()\-_/]{1,255}$/.test(reportPathPrefix.trim())) {
            setReportPathPrefixError({
                status: true,
                msg: "Report path prefix only contains alphanumerics and characters ! - _ . * ' ( )",
            });
            noErrors = false;
        }

        if (!displayName.trim()) {
            setDisplayNameError({
                status: true,
                msg: "Please enter Connection Display Name",
            });
            noErrors = false;
        } else if (!/^[a-zA-Z0-9.\-_\s]{3,50}$/.test(displayName.trim())) {
            setDisplayNameError({
                status: true,
                msg: "Display name only contains alphanumerics and characters",
            });
            noErrors = false;
        }

        if (!roleArn.trim()) {
            setRoleARNError({ status: true, msg: "Please enter Role ARN" });
            noErrors = false;
        } else if (!/^arn:aws:iam::\d{12}:role\/[a-zA-Z0-9-_\/]+$/.test(roleArn.trim())) {
            setRoleARNError({
                status: true,
                msg: "Please enter the valid Role ARN. (arn:aws:iam::account-id:role/role-name-with-path)",
            });
            noErrors = false;
        }
        return noErrors;
    }

    async function onConnect(e) {
        e.preventDefault();
        if (validateFields()) {
            setConnectionLoading(true);
            await axios
                .post(REACT_APP_EDIT_AWS_CONNECTION, {
                    costUsageReports: {
                        s3bucketCostreportName: s3bucketCostreportName.trim(),
                        s3bucketName: s3BucketName.trim(),
                        s3bucketCostreportPathPrefix: reportPathPrefix.trim(),
                    },
                    customerId,
                    displayName: displayName.trim(),
                    roleArn: roleArn.trim(),
                    uuidAccountId: uuid.trim(),
                })
                .then((response) => response.data)
                .then((response) => {
                    if ("statusCode" in response) {
                        setalertOpen(true);
                        switch (response.statusCode.toString()) {
                            case "200":
                                {
                                    setAlertTitle("success");
                                    setAlertMsg(response.successMessage);
                                    // setTimeout(function () {
                                    //   onConnectionSuccess();
                                    // }, 2000);
                                }
                                break;
                            case "3001":
                                {
                                    setAlertTitle("error");
                                    setAlertMsg("Null CustomerID");
                                    // alert("Null CustomerID");
                                }
                                break;
                            case "3005":
                                {
                                    setAlertTitle("warning");
                                    setAlertMsg("Account already exists");
                                    // alert("Account already exists");
                                }
                                break;
                            case "3048":
                                {
                                    setAlertTitle("error");
                                    setAlertMsg("Role arn is invalid");

                                    // alert("Role arn is invalid");
                                    // setTimeout(function () {
                                    //   onConnectionSuccess();
                                    // }, 2000);
                                }
                                break;
                            case "3049":
                                {
                                    setAlertTitle("error");
                                    setAlertMsg("Role arn is invalid");

                                    // alert("Role arn is invalid");
                                    // setTimeout(function () {
                                    //   onConnectionSuccess();
                                    // }, 2000);
                                }
                                break;
                            case "3050":
                                {
                                    setAlertTitle("error");
                                    setAlertMsg("s3bucketName is invalid");

                                    // alert("s3bucketName is invalid");
                                    // setTimeout(function () {
                                    //   onConnectionSuccess();
                                    // }, 2000);
                                }
                                break;
                            case "3051":
                                {
                                    setAlertTitle("error");
                                    setAlertMsg("S3bucket cost and usage report prefix name or report name is invalid");

                                    // alert(
                                    //   "S3bucket cost and usage report prefix name or report name is invalid"
                                    // );

                                    // setTimeout(function () {
                                    //   onConnectionSuccess();
                                    // }, 2000);
                                }
                                break;
                            case "3045":
                                {
                                    setAlertTitle("error");
                                    setAlertMsg("S3bucket Costreport Name is invalid");

                                    // alert("S3bucket Costreport Name is invalid");
                                    // setTimeout(function () {
                                    //   onConnectionSuccess();
                                    // }, 2000);
                                }
                                break;
                            default: {
                                setAlertTitle("error");
                                setAlertMsg("Please contact your administrator or our support team, support@cloudtrakr.com");
                            }
                        }
                    } else {
                        setAlertTitle("error");
                        setAlertMsg("Please contact your administrator or our support team, support@cloudtrakr.com");
                    }
                })
                .catch((e) => {
                    // console.log(e)
                })
                .finally(() => setConnectionLoading(false));
        }
        e.preventDefault();
    }

    async function onCloudTemplateGenerate(e) {
        e.preventDefault();
        const bucketName = s3BucketName.trim();
        var noErrors = true;
        if (bucketName == "") {
            setS3BucketError({
                status: true,
                msg: "Please enter the S3 bucket name",
            });
            noErrors = false;
        } else if (/^[a-z0-9.\-_]{1,255}$/.test(bucketName) == false) {
            setS3BucketError({
                status: true,
                msg: "Bucket name must not contain spaces or uppercase letters",
            });
            noErrors = false;
        }

        if (!s3bucketCostreportName.trim()) {
            setReportNameError({ status: true, msg: "Please enter the Report Name" });
            noErrors = false;
        } else if (!/^[a-zA-Z0-9.*!'()\-_]{1,255}$/.test(s3bucketCostreportName.trim())) {
            setReportNameError({
                status: true,
                msg: "Report name must be unique and only contains alphanumerics and characters ! - _ . * ' ( )",
            });
            noErrors = false;
        }

        if (!reportPathPrefix.trim()) {
            setReportPathPrefixError({
                status: true,
                msg: "Please enter the Report Path Prefix",
            });
            noErrors = false;
        } else if (!/^[a-zA-Z0-9.*!'()\-_/]{1,255}$/.test(reportPathPrefix.trim())) {
            setReportPathPrefixError({
                status: true,
                msg: "Report path prefix only contains alphanumerics and characters ! - _ . * ' ( )",
            });
            noErrors = false;
        }

        if (noErrors) {
            setCloudFormationLoading(true);
            await axios
                .get(REACT_APP_AWS_CLOUD_FORMATION, {
                    params: {
                        customerId,
                        s3BucketName: bucketName,
                    },
                })
                .then((response) => response.data)
                .then((response) => response.data)
                .then((response) => {
                    if (response) {
                        // setActiveStep(1);
                        setIsArnGenerated(true);
                        setS3URL(response.s3URL);
                    } else {
                        alert("Something went wrong");
                    }
                })
                .finally(() => setCloudFormationLoading(false));
        }
        e.preventDefault();
    }

    function onCopyToClipboard() {
        var input = document.querySelector("#s3URLField");
        input.select();
        document.execCommand("copy");
    }

    function onBlurHandle(currentField = null) {
        const bucketName = s3BucketName?.trim() || "";
        var noErrors = 0;
        var noArnErrors = 0;
        if (bucketName == "") {
            noErrors++;
            if (currentField == "s3BucketName")
                setS3BucketError({
                    status: true,
                    msg: "Please enter the S3 bucket name",
                });
        } else if (/^[a-z0-9.\-_]{1,255}$/.test(bucketName) == false) {
            noErrors++;
            if (currentField == "s3BucketName")
                setS3BucketError({
                    status: true,
                    msg: "Bucket name must not contain spaces or uppercase letters",
                });
        }

        if (!s3bucketCostreportName.trim()) {
            noErrors++;
            if (currentField == "s3bucketCostreportName")
                setReportNameError({
                    status: true,
                    msg: "Please enter the Report Name",
                });
        } else if (!/^[a-zA-Z0-9.*!'()\-_]{1,255}$/.test(s3bucketCostreportName.trim())) {
            noErrors++;
            if (currentField == "s3bucketCostreportName")
                setReportNameError({
                    status: true,
                    msg: "Report name must be unique and only contains alphanumerics and characters ! - _ . * ' ( )",
                });
        }
        if (!reportPathPrefix.trim()) {
            noErrors++;
            if (currentField == "reportPathPrefix")
                setReportPathPrefixError({
                    status: true,
                    msg: "Please enter the Report Path Prefix",
                });
        } else if (!/^[a-zA-Z0-9.*!'()\-_/]{1,255}$/.test(reportPathPrefix.trim())) {
            noErrors++;
            if (currentField == "reportPathPrefix")
                setReportPathPrefixError({
                    status: true,
                    msg: "Report path prefix only contains alphanumerics and characters ! - _ . * ' ( )",
                });
        }

        if (noErrors == 0) {
            // setActiveStep(1);
        }

        if (!roleArn.trim()) {
            noArnErrors++;
            if (currentField == "roleArn") setRoleARNError({ status: true, msg: "Please enter Role ARN" });
        } else if (!/^arn:aws:iam::\d{12}:role\/[a-zA-Z0-9-_\/]+$/.test(roleArn.trim())) {
            noArnErrors++;
            if (currentField == "roleArn")
                setRoleARNError({
                    status: true,
                    msg: "Please enter the valid Role ARN. (arn:aws:iam::account-id:role/role-name-with-path)",
                });
        }

        if (noArnErrors == 0) {
            // setActiveStep(2);
        }

        if (!displayName.trim()) {
            // noArnErrors++;
            if (currentField == "displayName")
                setDisplayNameError({
                    status: true,
                    msg: "Please enter Connection Display Name",
                });
        } else if (!/^[a-zA-Z0-9.\-_\s]{3,50}$/.test(displayName.trim())) {
            // noArnErrors++;
            if (currentField == "displayName")
                setDisplayNameError({
                    status: true,
                    msg: "Only contains alphanumerics and characters",
                });
        }
    }

    useEffect(() => {
        //   console.log
        setS3BucketName("");
        setS3bucketCostreportName("");
        setreportPathPrefix("");
        setRoleArn("");
        setDisplayName("");
        setuuid("");
        if (props.data) {
            setS3BucketName(props.data?.s3BucketName);
            setS3bucketCostreportName(props.data?.s3BucketCostReportName);
            setreportPathPrefix(props.data?.s3bucketCostreportPathPrefix);
            setRoleArn(props.data?.roleArn);
            setDisplayName(props.data?.displayName);
            setuuid(props.data.uuidAccountId);
        }
    }, [props.data]);

    return (
        <div>
            <Card.Header>
                <ConnectionHeader connectionTitle={"Edit AWS Account"} buttonHidden={true} Icon={AWS_LOGO} connectButtonName={"View AWS connection"} tooltip={"List AWS Accounts"} onConnect={props.onBack} breadCrumHidden={true} />
            </Card.Header>

            <Paper
                style={{
                    padding: 15,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                }}
            >
                <div style={{ width: "50%" }}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        <Step>
                            <StepButton
                                disabled={activeStep === 0}
                                style={{ outline: "none" }}
                                // onClick={() => setActiveStep(0)}
                            >
                                <StepLabel>
                                    <Typography style={{ fontSize: 18, fontWeight: "bold" }} color="black">
                                        Step 1 : Cost and usage report
                                    </Typography>
                                </StepLabel>{" "}
                            </StepButton>
                            <StepContent>
                                <div>
                                    <Form style={{ flex: 1, marginBottom: 10 }} onSubmit={(e) => e.preventDefault()}>
                                        <TextField
                                            value={s3BucketName}
                                            onBlur={() => onBlurHandle("s3BucketName")}
                                            label={"S3 Bucket name"}
                                            hasError={s3BucketError.status}
                                            errorMessage={s3BucketError.msg}
                                            onInput={(v) => {
                                                setIsArnGenerated(false);
                                                setS3BucketName(v);
                                                setS3BucketError({ status: false, msg: "" });
                                            }}
                                            placeholder={"Enter S3 Bucket name"}
                                        />
                                        <TextField
                                            value={s3bucketCostreportName}
                                            onBlur={() => onBlurHandle("s3bucketCostreportName")}
                                            label={"Report name"}
                                            hasError={reportNameError.status}
                                            errorMessage={reportNameError.msg}
                                            onInput={(v) => {
                                                setIsArnGenerated(false);
                                                setS3bucketCostreportName(v);
                                                setReportNameError({ status: false, msg: "" });
                                            }}
                                            placeholder={"Enter Report name"}
                                        />
                                        <TextField
                                            value={reportPathPrefix}
                                            label={"Report path prefix"}
                                            onBlur={() => onBlurHandle("reportPathPrefix")}
                                            hasError={reportPathPrefixError.status}
                                            errorMessage={reportPathPrefixError.msg}
                                            onInput={(v) => {
                                                setIsArnGenerated(false);
                                                setreportPathPrefix(v);
                                                setReportPathPrefixError({ status: false, msg: "" });
                                            }}
                                            placeholder={"Enter Report path prefix"}
                                        />
                                    </Form>
                                </div>

                                <div className={classes.actionsContainer}></div>
                            </StepContent>
                        </Step>
                        <Step active={true}>
                            <StepButton
                                disabled={activeStep === 1 || activeStep === 0}
                                style={{ outline: "none" }}
                                // onClick={() => setActiveStep(1)}
                            >
                                <StepLabel>
                                    <Typography style={{ fontSize: 18, fontWeight: "bold" }} color="black">
                                        Step 2 : Cross account role
                                    </Typography>
                                </StepLabel>
                            </StepButton>
                            <StepContent>
                                <span>Role is the primary and secure way to grant cross-account access. You grant access to cost and usage report in S3 bucket with restriced read only access.</span>
                                <div>
                                    <Form style={{ flex: 1, marginBottom: 10 }}>
                                        <br />
                                        <span>
                                            <a href={REACT_APP_AWS_ACCOUNT_INSTRUCTIONS} target={"_blank"} style={{ color: "#14aeff" }}>
                                                View Instructions
                                            </a>{" "}
                                            to create cross account role using this cloud formation template in your AWS account.{' '}
                                        </span>
                                        <button onClick={onCloudTemplateGenerate} className="btn btn-primary btn-sm" type="submit">
                                            Generate Cloudformation Template
                                        </button>
                                        
                                        <br />
                                        <br />
                                        {s3URL ? (
                                            <div style={{ marginTop: 5, marginBottom: 5 }}>
                                                <Form.Label
                                                    style={{
                                                        marginRight: 10,
                                                        fontFamily: "Poppins_SemiBold",
                                                    }}
                                                >
                                                    Here is an unique cloudformation template for your account to provide cross-account role access to collect cost and usage report. (Copy this S3 URL to create a role in the cloud formation stack)
                                                </Form.Label>
                                                <InputGroup style={{ alignItems: "center" }} className="mb-3">
                                                    <Form.Control value={s3URL} id={"s3URLField"} placeholder="AWS S3 URL" aria-label="" aria-describedby="basic-addon1" />
                                                    <InputGroup.Append>
                                                        <img
                                                            onClick={() => {
                                                                onCopyToClipboard();
                                                            }}
                                                            src={Copy}
                                                            alt="copy"
                                                            style={{
                                                                width: 20,
                                                                height: 20,
                                                                marginLeft: 8,
                                                            }}
                                                        />
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </div>
                                        ) : null}
                                        <TextField
                                            value={roleArn}
                                            // disabled={!isArnGenerated}
                                            onBlur={() => onBlurHandle("roleArn")}
                                            label={"Role ARN"}
                                            hasError={roleARNError.status}
                                            errorMessage={roleARNError.msg}
                                            onInput={(v) => {
                                                setRoleArn(v);
                                                setRoleARNError({ status: false, msg: "" });
                                            }}
                                            placeholder={"Enter Role ARN"}
                                        />
                                        {/* <Button onClick={() => setActiveStep(2)}>Submit</Button> */}
                                        <TextField
                                            value={displayName}
                                            //   disabled={!isArnGenerated}
                                            onBlur={() => onBlurHandle("displayName")}
                                            label={"Connection Display name"}
                                            hasError={displayNameError.status}
                                            errorMessage={displayNameError.msg}
                                            onInput={(v) => {
                                                setDisplayName(v);
                                                setDisplayNameError({ status: false, msg: "" });
                                            }}
                                            placeholder={"Enter Connection Display name"}
                                        />
                                        <Button
                                            //   disabled={!isArnGenerated}
                                            onClick={onConnect}
                                            variant={"primary"}
                                            type="submit"
                                        >
                                            Save changes
                                        </Button>{" "}
                                    </Form>
                                </div>
                                <div className={classes.actionsContainer}></div>
                            </StepContent>
                        </Step>

                        {/* <Step active={true}>
              <StepLabel>
                {" "}
                <Typography
                  style={{ fontSize: 18, fontWeight: "bold" }}
                  color="black"
                >
                  Step 3 : Connection name
                </Typography>
              </StepLabel>{" "}
              <StepContent>
                <div>
                  <Form style={{ flex: 1, marginBottom: 10 }}>
                    <TextField
                      value={displayName}
                      //   disabled={!isArnGenerated}
                      label={"Connection Display name *"}
                      hasError={displayNameError.status}
                      errorMessage={displayNameError.msg}
                      onInput={setDisplayName}
                      placeholder={"Enter Connection Display name"}
                    />

                    <Button
                      //   disabled={!isArnGenerated}
                      onClick={onConnect}
                      variant={"primary"}
                      type="submit"
                    >
                      Save changes
                    </Button>
                  </Form>
                </div>
                <div className={classes.actionsContainer}></div>
              </StepContent>
            </Step>
          */}
                    </Stepper>
                </div>

                <div
                    style={{
                        width: "50%",
                        justifyContent: "center",
                        display: "flex",
                    }}
                >
                    {cloudFormationLoading || connectionLoading ? <CircularProgress /> : alertOpen && <AlertBox title={alertTitle} msg={alertMsg} onclose={() => setalertOpen(false)} />}

                </div>
            </Paper>
        </div>
    );
}


function TextField({ onBlur, value, hasError, label, placeholder, onInput, errorMessage, disabled = false }) {
    return (
        <Form.Group controlId="formBasicEmail">
            <Form.Label className={disabled ? "disabled-field" : ""}>
                {label}
                <span style={{color:'red'}}> *</span>
                {label == "Role ARN" ? (
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Popover id="popover-contained">
                                <Popover.Body style={{ fontSize: 11 }}>arn:aws:iam::YOUR_AWS_ACCOUNT_ID:role/CloudTrakrAWSIntegrationRole</Popover.Body>
                            </Popover>
                        }
                    >
                        <ReportIcon />
                    </OverlayTrigger>
                ) : null}
            </Form.Label>
            <Form.Control onBlur={onBlur} value={value} disabled={disabled} placeholder={placeholder} onChange={(event) => onInput(event.target.value)} />
            {hasError ? <Form.Text style={{ color: "red" }}>{errorMessage}</Form.Text> : null}
        </Form.Group>
    );
}


function AlertBox({ title = null, msg = null, onclose }) {
    return title == "success" || msg == "Role arn is invalid" || msg == "s3bucketName is invalid" || msg == "S3bucket cost and usage report prefix name or report name is invalid" || msg == "S3bucket Costreport Name is invalid" ? (
        <Alert
            action={
                // <Link to={history.back(0)}>
                <button className="btn btn-primary btn-sm" onClick={() => (window.location.href += "#aws") && window.location.reload()} style={{ width: 180 }} color="inherit" size="small">
                    View AWS connection
                </button>
                // </Link>
            }
            severity={title}
            // style={{ width: "80%" }}
        >
            <AlertTitle>Successfully connected your AWS account.</AlertTitle>
            {msg}
        </Alert>
    ) : (
        <Alert onClose={onclose} severity={title} style={{ width: "60%" }}>
            {/* <AlertTitle>{title}</AlertTitle> */}
            {msg}
        </Alert>
    );
}

function BillingDataRadioButtons({}) {
    const [datacollection, setDataCollection] = useState("nodata");

    return (
        <FormControl component="fieldset">
            <Form.Label>
                {" "}
                <b>Billing data collection</b>
            </Form.Label>
            <RadioGroup onChange={(e) => setDataCollection(e.target.value)} value={datacollection}>
                <tbody>
                    <tr>
                        <td>
                            <FormControlLabel value="nodata" control={<Radio color="primary" />} label="No data collection" />
                        </td>
                        <td>
                            <FormControlLabel value="lastyear" control={<Radio color="primary" />} label="Last 1 year" />
                        </td>
                        <td>
                            <FormControlLabel value="lastmonth" control={<Radio color="primary" />} label="Last month" />
                        </td>
                        <td>
                            <FormControlLabel value="lastdate" control={<Radio color="primary" />} label="Month-to-date" />
                        </td>
                    </tr>
                </tbody>
            </RadioGroup>
        </FormControl>
    );
}
