import React, { useRef, useEffect, useState } from "react";

import {
    select,
    scaleOrdinal,
    scaleLinear,
    max,
    scaleBand,
    axisLeft,
    axisBottom,
    easeLinear,
    sum
} from "d3";
import Format from "components/NumberFormat";
import GetWindowDimension from "components/HOC/GetWindowDimension";
import { tooltipVB } from "./ReactDrillDownd3BarChart";
export const colorScale = scaleOrdinal(["#31BEF3", "#19A979", "#FBD63D", "#08619D", "#E8743B", "#194350", "#7b113a", "#301b3f"]);

function ReactD3GridChart(props) {
    const { title, cost, currency, mouseEffect, data, width, height } = props;
    const svgRef = useRef();
    const currencySymbol = Format.formatCurrencySymbol(currency);
    const totalCost = Format.formatNumber(cost);

    const margin = {
        top: 80,
        right: 80,
        bottom: 25,
        left: 80,
    };

    const chartwidth = width / 2.5 - margin.left - margin.right;
    const chartheight = height / 2.2 - margin.top - margin.bottom;

    useEffect(() => {
        const svg = select(svgRef.current);
        if(data){
            VerticalBarChart(svg);
        } else {
            drawNoData();
        }
    }, [data, width, height]);

    const VerticalBarChart = (svg) => {
        svg.selectAll("g").remove();
        svg.selectAll("text").remove();

        const yScale = scaleLinear()
            .range([chartheight, 0])
            .domain([0, max(data, (d) => Number(d.value))]);
        const xScale = scaleBand()
            .range([0, chartwidth])
            .domain(data.map((s) => s.label))
            .padding(0.1);

        const chart = svg.append("g").attr("transform", `translate(${margin.left}, ${margin.top})`);

        chart
            .append("g")
            .attr("class", "grid")
            .style("font-Family", "Poppins_SemiBold")
            .call(
                axisLeft(yScale)
                    .ticks(4)
                    .tickFormat((d) => `${currencySymbol}${Format.formatNumberInt(d)}`)
            );

        chart.append("g").attr("transform", `translate(0, ${chartheight})`).call(axisBottom(xScale)).selectAll("text").remove();
        // .style("font-Family", "Poppins_Regular")
        // .attr("text-anchor", "end")
        // .attr("dx", "-.8em")
        // .attr("dy", ".15em")
        // .attr("transform", `rotate(-25)`);

        const rect = chart
            .selectAll()
            .data(data)
            .enter()
            .append("rect")
            .attr("x", (s) => xScale(s.label))
            .attr("y", (s) => yScale(s.value))
            .attr("height", (s) => chartheight - yScale(s.value))
            .attr("width", xScale.bandwidth())
            .attr("fill", (d, i) => colorScale(i))
            .on("mouseover", (d, i) => {
                tooltipVB.html(`<div>${i.label}<br> ${currencySymbol + Format.formatNumber(i.value)}</div>`);
            })
            .on("mousemove", function (d, i) {
                if (mouseEffect) {
                    select(this)
                        .attr("stroke", "none")
                        .style("cursor", "pointer")
                        .attr("height", (d) => chartheight - yScale(d.value) + 2)
                        .attr("y", (d) => yScale(d.value) - 2)
                        .attr("width", xScale.bandwidth() + 5)
                        .attr("x", (s) => xScale(s.label) - 2)
                        .transition()
                        .duration(200);
                    tooltipVB
                        .style("left", d.pageX - 50 + "px")
                        .style("top", d.pageY - 70 + "px")
                        .style("display", "inline-block");
                }
            })
            .on("mouseout", function (d, i) {
                if (mouseEffect) {
                    select(this)
                        .attr("stroke", "none")
                        .attr("height", (d) => chartheight - yScale(d.value))
                        .attr("y", (d) => yScale(d.value))
                        .attr("width", xScale.bandwidth())
                        .attr("x", (s) => xScale(s.label))
                        .transition()
                        .duration(200);
                    tooltipVB.style("display", "none");
                }
            });

        rect.transition()
            .ease(easeLinear)
            .duration(1000)
            .attr("y", (s) => yScale(s.value))
            .attr("height", (s) => chartheight - yScale(s.value))
            .delay((d, i) => i * 300);

        chart
            .selectAll()
            .data(data)
            .enter()
            .append("text")
            .attr("class", "val") // add class to text label
            .attr("text-anchor", "middle")
            .attr("x", function (d, i) {
                return xScale(d.label) + xScale.bandwidth() / 2;
            })
            .attr("dx", 0)
            .attr("y", function (d, i) {
                return yScale(d.value);
            })
            .attr("dy", (d) => (d.value < yScale(d.value) ? -2 : 15))
            .style("font-Size", (d) => (d.value > 10000 ? "10px" : "11px"))
            .text((d) => `${currencySymbol}` + Format.formatNumber(d.value));

        svg.append("text")
            .attr("x", margin.left + margin.top)
            .attr("y", 25)
            .attr("text-anchor", "middle")
            .style("font-Family", "Poppins_SemiBold")
            .text(title);

        svg.append("text")
            .attr("id", "d3_grid_currency")
            .attr("x", 0)
            .attr("y", 25)
            .attr("text-anchor", "middle")
            .style("font-Family", "Poppins_SemiBold")
            .text(currencySymbol + totalCost);

        const currency = svg.selectAll('#d3_grid_currency');

        currency.attr("transform", `translate(${chartwidth - currency.nodes()[0].getBBox().width + chartheight}, 0)`)

        try {
            drawLegend(svg.append("g").attr("class", "legend_c").attr("transform", `translate(25,35)`))
        } catch (e) {
            svg.selectAll(".legend_c").remove();
        }
        // svg
        //   .append("text")
        //   .attr("class", "label")
        //   .attr("x", chartwidth / 2 + margin)
        //   .attr("y", chartheight + margin * 1.7)
        //   .attr("text-anchor", "middle")
        //   .text("Services");
    };

    function drawNoData() {
        select(svgRef.current).selectAll("g").remove();
        select(svgRef.current).selectAll("text").remove();
        select(svgRef.current).append("g")
            .attr("transform", `translate(${chartwidth / 2}, ${height/4.5})`)

            // .attr("transform", `translate(${margin.left}, ${margin.top})`)
            .style("display", "flex")
            .style("align-items", "center")
            .style("justify-content", "center")
            .append('text')
            .text("No data found")
    }

    function drawLegend(legend){
        const colorPairsByKey = data.map(d=>({
            key: d.label
        }));
        const chartWidth = chartwidth + margin.left + margin.right;

        let legendElement = legend
            .append('g')
            .attr("class", "legend__container")
            .attr("transform", `translate(10,10)`)
            .selectAll("g.legend__element")
            .data(colorPairsByKey)
            .enter()
            .append("g")

        legendElement
            .append("text")
            .attr("x", 10)
            .attr("font-size", "12px")
            .text(({key})=>key || "unassigned")
            .attr("fill", ({key})=>(key && (key?.toString()?.trim())?"":"#a9a9a9"));

        legendElement
            .append("circle")
            .attr("stroke", ({key},i)=>colorScale(i))
            .attr("stroke-width", 2)
            .attr("cx", 0)
            .attr("cy", -3)
            .attr("r", 5)
            .attr("fill", ({key},i)=>colorScale(i));


        var padding = 10;
        var lastIndex = 0;
        const nextRow =  [0];
        const yValuesArray = new Array(colorPairsByKey.length);

        function advanceSumCalculate(){
            var sum = 0;
            for(var j = 0; j < colorPairsByKey.slice(lastIndex).length; j++){
                const newWidth = legendElement.nodes()[j + lastIndex].getBBox().width;
                if(sum + newWidth + padding >chartWidth){
                    nextRow.push(j + lastIndex);
                    const k = lastIndex;
                    lastIndex += j;
                    j = colorPairsByKey.slice(k).length;
                    advanceSumCalculate();
                } else {
                    sum += newWidth;
                }
            }
        }
        advanceSumCalculate();
        nextRow.forEach((n,i)=>{
            const index1 = nextRow[i];
            const index2 = nextRow[i+1] || nextRow[yValuesArray.length - 1];
            yValuesArray[index2] = i * 15;
            yValuesArray.fill(i * 15, index1, index2);
        });

        lastIndex = 0;
        legendElement.attr('transform', function(d,i) {
            var total = 0;
            sum(colorPairsByKey.map(k=>k.key),
                (e,j) => {
                    if(j + nextRow?.[lastIndex] < i ){
                        const newWidth = legendElement.nodes()[j + (nextRow?.[lastIndex] || 0)].getBBox().width;
                        total += newWidth;
                    } else {
                        total += 0;
                    }
                    if(chartWidth < total){
                        total = 0;
                    }
                }
            )
            const minus = yValuesArray[i] / 15 ;
            if((yValuesArray[i] - (yValuesArray[i-1] || 0))> 0) {
                total = 0;
                lastIndex++;
            }

            const totalPadding = padding * i;
            // debugger
            return "translate("+ ( total + (padding * ((i - nextRow[lastIndex]) ))) + `,${yValuesArray[i]})`;
        })
            .attr("class", (_,o)=>"element#"+o)
    }

    return <svg ref={svgRef} width="100%" height={height / 2.2} />;
}

export default GetWindowDimension(ReactD3GridChart);
