import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getAzureFunctionSuccess, getAzureFunctionFailure,getAzureFunctionAccountFailure,getAzureFunctionAccountSuccess,
    getAzureFunctionTenantSuccess,getAzureFunctionTenantFailure } from "../actions/actions";
const { REACT_APP_GET_AZURE_FUNCTIONS,REACT_APP_GET_AZURE_SUBSCRIPTION_FUNCTIONS,REACT_APP_GET_AZURE_TENANT_FUNCTIONS } = process.env;

function* getAzureFunctionSagaData({ payload }) {
        try {
        const res = yield call(axios.get, REACT_APP_GET_AZURE_FUNCTIONS, {
            params: {
                customerId: payload.customerId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureFunctionFailure(statusCode));
            } else {
                yield put(getAzureFunctionSuccess(res.data));
            }
        } else {
            yield put(getAzureFunctionFailure(res));
        }
    } catch (error) {
        yield put(getAzureFunctionFailure(error));
    }
}

function* getAzureFunctionSubSagaData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_GET_AZURE_SUBSCRIPTION_FUNCTIONS, {
            params: {
                customerId: payload.customerId,
                tenantId: payload.tenantId,
                subscriptionId: payload.subscriptionId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureFunctionAccountFailure(statusCode));
            } else {
                yield put(getAzureFunctionAccountSuccess(res.data));
            }
        } else {
            yield put(getAzureFunctionAccountFailure(res));
        }
    } catch (error) {
        yield put(getAzureFunctionAccountFailure(error));
    }
}

function* getAzureFunctionTenantSagaData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_GET_AZURE_TENANT_FUNCTIONS, {
            params: {
                customerId: payload.customerId,
                tenantId: payload.tenantId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureFunctionTenantFailure(statusCode));
            } else {
                yield put(getAzureFunctionTenantSuccess(res.data));
            }
        } else {
            yield put(getAzureFunctionTenantFailure(res));
        }
    } catch (error) {
        yield put(getAzureFunctionTenantFailure(error));
    }
}

export default function* azureFunctionSaga() {
    yield takeLatest(types.AZURE_FUNCTION_REQUEST, getAzureFunctionSagaData);
    yield takeLatest(types.AZURE_FUNCTION_ACCOUNT_REQUEST, getAzureFunctionSubSagaData);
    yield takeLatest(types.AZURE_FUNCTION_TENANT_REQUEST, getAzureFunctionTenantSagaData);

}
