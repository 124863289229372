import React, { useState } from "react";
import { makeStyles, withStyles } from "MakeWithStyles";
import LinearProgress from "@mui/material/LinearProgress";
import AzureLogo from "../../images/GCP_logo.svg";

import "./style.css";
import TotalRecommend from "./TotalRecommend";
import Recommendationtypes from "./Recommendationtypes";
import ContainerCardWithDateRange from "../../components/ContainerCardWithDateRange";
import ArrowDown from "../../images/double-chevron-down.svg";
import MountUnmount from "../../components/MountUnmount";
import ArrowUp from "../../images/double-chevron-up.svg";

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
    },
}));

export default function GCPRecommendation({showCards = true, showCollapseButton = true, tenantId, subscriptionId, customerId, progress, collapseRef }) {
    const {classes} = useStyles();
    const [allResources, setAllResources] = useState(false);
    const [subStoppedSumCard, setSubStoppedSumCard] = useState(null);

    const [dashboardComponentsRef, setDashboardComponentsRef] = useState({});
    const [dashboardComponentsCollapsed, setDashboardComponentsCollapsed] = useState(false);

    function collapseCards() {
        const h = dashboardComponentsRef;
        for(const rg in h){
            h[rg](dashboardComponentsCollapsed);
        }
        setDashboardComponentsCollapsed(!dashboardComponentsCollapsed);
    }

    return false
    ? (
        <div className={classes.root}>
            <LinearProgress variant="determinate" value={progress} />
        </div>
    ) : (
        <>
            {showCollapseButton?<div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <span onClick={collapseCards} className={'link-button'}>{dashboardComponentsCollapsed?"Expand All":"Collapse All"}
                    <img src={!dashboardComponentsCollapsed?ArrowUp:ArrowDown} height={20} />
                            </span>
            </div>:null}
            <MountUnmount onUnmount={()=> {
                            setDashboardComponentsRef({});
                            setDashboardComponentsCollapsed(false);
                        }} />
            <div>
                <ContainerCardWithDateRange someref={{how: setDashboardComponentsRef, what:'recomm1', where: dashboardComponentsRef}}
                                            myref={collapseRef} title={"Stopped Compute Engine Recommendations"} titleIcon={AzureLogo} showDateRange={false} collapsible={true} datePeriodEnabled={false}>
                    {() => (
                        <>
                                <div style={{ textAlign: "center" }}>
                                    <span>No data found</span>
                                </div>
                        </>
                    )}
                </ContainerCardWithDateRange>
            </div>
            <br />
            <div>
                <ContainerCardWithDateRange someref={{how: setDashboardComponentsRef, what:'recomm2', where: dashboardComponentsRef}}
                                            myref={collapseRef} title={"Idle Compute Engine Recommendations"} titleIcon={AzureLogo} showDateRange={false} collapsible={true} datePeriodEnabled={false}>
                    {() => (
                        <>
                            {/*{allResources ? recommendations && azureAllRecommendation && <TotalRecommend data={recommendations} allRec={null} /> : recommendations && <TotalRecommend data={recommendations} allRec={null} />}*/}
                            {/*{recommendations && recommendations != null ? (*/}
                            {/*    <Recommendationtypes detail={recommendations} />*/}
                            {/*) : (*/}
                                <div style={{ textAlign: "center" }}>
                                    <span>No data found</span>
                                </div>
                            {/*)}*/}
                        </>
                    )}
                </ContainerCardWithDateRange>
            </div>
            <br />
            <div>
                <ContainerCardWithDateRange someref={{how: setDashboardComponentsRef, what:'recomm3', where: dashboardComponentsRef}}
                                            myref={collapseRef} title={"Rightsizing Compute Engine Recommendations"} titleIcon={AzureLogo} showDateRange={false} collapsible={true} datePeriodEnabled={false}>
                    {() => (
                        <>
                            {/*{allResources ? recommendations && azureAllRecommendation && <TotalRecommend data={recommendations} allRec={null} /> : recommendations && <TotalRecommend data={recommendations} allRec={null} />}*/}
                            {/*{recommendations && recommendations != null ? (*/}
                            {/*    <Recommendationtypes detail={recommendations} />*/}
                            {/*) : (*/}
                                <div style={{ textAlign: "center" }}>
                                    <span>No data found</span>
                                </div>
                            {/*)}*/}
                        </>
                    )}
                </ContainerCardWithDateRange>
            </div>
            <br />

            <div>
                <ContainerCardWithDateRange someref={{how: setDashboardComponentsRef, what:'recomm4', where: dashboardComponentsRef}}
                                            myref={collapseRef} title={"Migrate previous to latest generation Compute Engine Recommendations"} titleIcon={AzureLogo} showDateRange={false} collapsible={true} datePeriodEnabled={false}>
                    {() => (
                        <>
                            {/*{allResources ? recommendations && azureAllRecommendation && <TotalRecommend data={recommendations} allRec={null} /> : recommendations && <TotalRecommend data={recommendations} allRec={null} />}*/}
                            {/*{recommendations && recommendations != null ? (*/}
                            {/*    <Recommendationtypes detail={recommendations} />*/}
                            {/*) : (*/}
                                <div style={{ textAlign: "center" }}>
                                    <span>No data found</span>
                                </div>
                            {/*)}*/}
                        </>
                    )}
                </ContainerCardWithDateRange>
            </div>
            <br />
            <div>
                <ContainerCardWithDateRange someref={{how: setDashboardComponentsRef, what:'recomm45', where: dashboardComponentsRef}}
                                            myref={collapseRef} title={"Cloud SQL Recommendations"} titleIcon={AzureLogo} showDateRange={false} collapsible={true} datePeriodEnabled={false}>
                    {() => (
                        <>
                            {/*{allResources ? recommendations && azureAllRecommendation && <TotalRecommend data={recommendations} allRec={null} /> : recommendations && <TotalRecommend data={recommendations} allRec={null} />}*/}
                            {/*{recommendations && recommendations != null ? (*/}
                            {/*    <Recommendationtypes detail={recommendations} />*/}
                            {/*) : (*/}
                            <div style={{ textAlign: "center" }}>
                                <span>No data found</span>
                            </div>
                            {/*)}*/}
                        </>
                    )}
                </ContainerCardWithDateRange>
            </div>

            <br />
            <div>
                <ContainerCardWithDateRange someref={{how: setDashboardComponentsRef, what:'recomm5', where: dashboardComponentsRef}}
                                            myref={collapseRef} title={"Cloud Storage Recommendations"} titleIcon={AzureLogo} showDateRange={false} collapsible={true} datePeriodEnabled={false}>
                    {() => (
                        <>
                            {/*{allResources ? recommendations && azureAllRecommendation && <TotalRecommend data={recommendations} allRec={null} /> : recommendations && <TotalRecommend data={recommendations} allRec={null} />}*/}
                            {/*{recommendations && recommendations != null ? (*/}
                            {/*    <Recommendationtypes detail={recommendations} />*/}
                            {/*) : (*/}
                                <div style={{ textAlign: "center" }}>
                                    <span>No data found</span>
                                </div>
                            {/*)}*/}
                        </>
                    )}
                </ContainerCardWithDateRange>
            </div>
        </>
    );
}

async function filterResources(category, data, impact) {
    let filteredData = [];
    let unique = {};
    var result = data.filter(function (v, i) {
        return v.properties["category"] === category && v.properties.impact === impact;
    });

    for (let i in result) {
        let objValue = result[i].properties["impactedValue"];
        unique[objValue] = result[i].properties;
    }

    for (let i in unique) {
        filteredData.push(unique[i]);
    }

    return filteredData;
}

export async function filterByTypeID(result) {
    let array = [];
    let uniqueArray = {};
    for (let i in result) {
        let objValue = result[i]["impactedValue"];
        uniqueArray[objValue] = result[i];
    }

    for (let i in uniqueArray) {
        array.push(uniqueArray[i]);
    }
    return array;
}

export async function resourcesExtraction(values) {
    let newArray = [];
    let CostIR = [];
    let SecurityIR = [];
    let ExcellenceIR = [];
    let AvailabilityIR = [];
    let PerformanceIR = [];

    let uniqueObject = {};

    let costHigh = await filterResources("Cost", values, "High");
    let costMedium = await filterResources("Cost", values, "Medium");
    let costLow = await filterResources("Cost", values, "Low");

    let securityHigh = await filterResources("Security", values, "High");
    let securityMedium = await filterResources("Security", values, "Medium");
    let securityLow = await filterResources("Security", values, "Low");

    let excellenceHigh = await filterResources("OperationalExcellence", values, "High");
    let excellenceMedium = await filterResources("OperationalExcellence", values, "Medium");
    let excellenceLow = await filterResources("OperationalExcellence", values, "Low");

    let availabilityHigh = await filterResources("HighAvailability", values, "High");
    let availabilityMedium = await filterResources("HighAvailability", values, "Medium");
    let availabilityLow = await filterResources("HighAvailability", values, "Low");

    //------------------------------
    let performanceHigh = await filterResources("Performance", values, "High");
    let performanceMedium = await filterResources("Performance", values, "Medium");
    let performanceLow = await filterResources("Performance", values, "Low");

    //------------------------------
    costHigh.map((i) => CostIR.push(i));
    costMedium.map((i) => CostIR.push(i));
    costLow.map((i) => CostIR.push(i));

    securityHigh.map((i) => SecurityIR.push(i));
    securityMedium.map((i) => SecurityIR.push(i));
    securityLow.map((i) => SecurityIR.push(i));

    availabilityHigh.map((i) => AvailabilityIR.push(i));
    availabilityMedium.map((i) => AvailabilityIR.push(i));
    availabilityLow.map((i) => AvailabilityIR.push(i));

    excellenceHigh.map((i) => ExcellenceIR.push(i));
    excellenceMedium.map((i) => ExcellenceIR.push(i));
    excellenceLow.map((i) => ExcellenceIR.push(i));

    performanceHigh.map((i) => PerformanceIR.push(i));
    performanceMedium.map((i) => PerformanceIR.push(i));
    performanceLow.map((i) => PerformanceIR.push(i));

    for (let i in values) {
        let objTitle = values[i].properties["recommendationTypeId"];
        uniqueObject[objTitle] = values[i].properties;
    }

    for (let i in uniqueObject) {
        newArray.push(uniqueObject[i]);
    }

    var costIR = await filterByTypeID(CostIR);
    var securityIR = await filterByTypeID(SecurityIR);
    var availabilityIR = await filterByTypeID(AvailabilityIR);
    var performanceIR = await filterByTypeID(PerformanceIR);
    var excellenceIR = await filterByTypeID(ExcellenceIR);

    //console.log(" Security ADDD == ", JSON.stringify(securityIR));

    //----------------------------------------------------------------
    const recommendationsVal = {
        costVal: costIR.length,
        securityVal: securityIR.length,
        availabilityVal: availabilityIR.length,
        performanceVal: performanceIR.length,
        excellenceVal: excellenceIR.length,
    };
    const totalIR = costIR.length + securityIR.length + availabilityIR.length + performanceIR.length + excellenceIR.length;

    //----------------------------------------------------------------
    return {
        newArray,
        CostIR,
        AvailabilityIR,
        SecurityIR,
        PerformanceIR,
        ExcellenceIR,
        recommendationsVal,
        totalIR,
    };
}
