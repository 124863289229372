import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { accountSuccess, accountFail } from "../actions/actions";
const { REACT_APP_ACCOUNT_STATUS } = process.env;

function* getAccountStatusData({ payload }) {
  // console.log("account status == ", payload);
  try {
    const res = yield call(axios.get, REACT_APP_ACCOUNT_STATUS, {
      params: { customerID: payload },
    });
    // console.log("account status22 == ", res);

    yield put(accountSuccess(res?.data));
  } catch (error) {
    yield put(accountFail(error));
  }
}

export default function* accountStatus() {
  yield takeLatest(types.CLOUD_ACCOUNT_REQUEST, getAccountStatusData);
}
