export function createDelay(ms) {
    return function(x) {
        return new Promise(resolve => setTimeout(() => resolve(x), ms));
    };
}


export function convertToInternationalCurrencySystem (labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

        ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + " B"
        // Six Zeroes for Millions
        : Math.abs(Number(labelValue)) >= 1.0e+6

            ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + " M"
            // Three Zeroes for Thousands
            : Math.abs(Number(labelValue)) >= 1.0e+3

                ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + " K"

                : Math.abs(Number(labelValue));

}

export function bytesToSiz2e(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export function bytesToSize(bytes, decimals = 2) {
    if(bytes) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    } else {
        return "-";
    }
}

export function apiDataCheck(r){
    if(r && r.statusCode === STATUS_CODES.SUCCESS){
        if(r.data != null){
            return r;
        }
        return null;
    } else {
        return null;
    }
}

const STATUS_CODES = {
    "SUCCESS": 200,
    "DATA NOT AVAILABLE": 1002,
}

export function SKIP_CODE(){
    return null;
}

export const ALPHA_NUM_SORTER = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

export function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export function arraySaveToCSV(rows = [], filename = 'download.csv'){
    // let csvContent = "data:text/csv;charset=utf-8,";

    // rows.forEach((rowArray)=>{
    //     let row = rowArray.join(",");
    //     csvContent += row + "\r\n";
    // });
    let csvContent = "data:text/csv;charset=utf-8,"
        + rows.map(e => e.join(",")).join('\n');
    const frame = document.querySelector('#my_iframe');
    frame.href = csvContent;
    frame.download = filename;
    frame.click()
    frame.href = null;
    frame.download = null
    // document.querySelector('#my_iframe')
    // window.open(csvContent, "_blank");
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const objectIterator=(obj)=>Object.keys(obj).map(k=>({label: k, value: obj[k]}))
