import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getAllAccountSuccess, getAllAccountFailure} from "../actions/actions";
const { REACT_APP_ALL_CLOUD_ACCOUNT } = process.env;

function* getAllAccountData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_ALL_CLOUD_ACCOUNT, {
            params: { customerId: payload.customerId },
           
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAllAccountFailure(statusCode));
            } else {
                yield put(getAllAccountSuccess(res.data));
            }
        } else {
            yield put(getAllAccountFailure(res));
        }
    } catch (error) {
        yield put(getAllAccountFailure(error));
    }
}


export default function* allAccountSaga() {
    yield takeLatest(types.ALL_ACCOUNT_REQUEST, getAllAccountData);
}
