import React from "react";
import {getCurrentMonth, getMonthForDate, getLastMonth} from "../util/DateFormatter";
import CountUp from "react-countup";
import { Paper } from "@mui/material";
import moment from "moment";
import Format from "./NumberFormat";

const width = window.innerWidth;
export default function CostHeader({
  cloudName,
  cost,
  cloudIcon,
  color,
  currency,
  headerFontSize = null,
  lastMonthSpend,
  logoSize = 40,
  haslastmonth,
  children,
  date='',
  startDate='',
  lastMonthDate='',
  hasPotentialSavings=false,
  potentialSavings
}) {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
        id="test"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={cloudIcon} height={logoSize} style={{ marginRight: 5 }} />
        </div>
        <Paper
          elevation={3}
          style={{
            paddingBottom: 10,
            marginLeft: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // border: "0.2px solid lightgray",
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 4,
          }}
        >
          <span
            className={"cost-counter"}
            style={{
              fontSize: "2.3em",
              fontFamily: "'Segoe UI', Verdana, Helvetica, sans-serif",
              color,
              fontWeight: "bold",
            }}
          >
             <CountUp prefix={currency} end={cost} duration={1} decimals={2} separator={','}/>
          </span>
          <div style={{ display: "flex", flexDirection: "column",alignItems:'center' }}>
            <span
              style={{
                lineHeight: "0px",
                marginTop: "-5px",
                marginBottom: 0,
                fontSize: "1.0em",
                paddingLeft: 5,
                fontFamily: "'Segoe UI', Verdana, Helvetica, sans-serif",
                color,
                whiteSpace: "nowrap"
              }}
            >
             {date?getMonthForDate(date):getCurrentMonth()} {startDate!=date? moment(startDate, "YYYYMMDD").format("D")+'-'+moment(date, "YYYYMMDD").format("D"):moment(startDate, "YYYYMMDD").format("D")} Spend
            </span>
          </div>
        </Paper>
      </div>
        <div style={{display: 'flex', justifyContent: 'space-between', marginLeft: 10}}>
      {!haslastmonth && (
        <Paper
          elevation={3}
          style={{
            paddingBottom: 10,
            // marginLeft: 30,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // border: "0.2px solid lightgray",
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 4,
          }}
        >
          {lastMonthSpend>0?
          (<span
            className={"cost-counter"}
            style={{
              fontSize: "2.3em",
              fontFamily: "'Segoe UI', Verdana, Helvetica, sans-serif",
              color,
              fontWeight: "bold",
            }}
          >
            <CountUp prefix={currency} end={lastMonthSpend} duration={1} decimals={2} separator={','} />
          </span>)
          :(<span
            style={{
              fontSize: "2em",
              // fontFamily: "'Segoe UI', Verdana, Helvetica, sans-serif",
              color,
              fontWeight: "bold",
              marginBottom: 8,
            }}
        >
          $_._
        </span>)}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                lineHeight: "0px",
                marginTop: "-5px",
                marginBottom: 0,
                fontSize: "1.0em",
                paddingLeft: 5,
                fontFamily: "'Segoe UI', Verdana, Helvetica, sans-serif",
                color,
                whiteSpace: "nowrap"
              }}
            >
              {getMonthForDate(lastMonthDate)} Spend
            </span>
          </div>
        </Paper>
      )}

      {hasPotentialSavings && potentialSavings!=null && potentialSavings>0 ? (
        <Paper
          elevation={3}
          style={{
            paddingBottom: 10,
            marginLeft: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // border: "0.2px solid lightgray",
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 4,
          }}
        >
          <span
            className={"cost-counter"}
            style={{
              fontSize: "2.3em",
              fontFamily: "'Segoe UI', Verdana, Helvetica, sans-serif",
              color,
              fontWeight: "bold",
            }}
          >
            <CountUp prefix={currency} end={potentialSavings} duration={1} decimals={2} separator={','} />
          </span>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                lineHeight: "0px",
                marginTop: "-5px",
                marginBottom: 0,
                fontSize: "1.0em",
                paddingLeft: 5,
                fontFamily: "'Segoe UI', Verdana, Helvetica, sans-serif",
                color,
                whiteSpace: "nowrap"
              }}
            >
              Potential Savings /mon
            </span>
          </div>
        </Paper>
      ):null}

        {children}
        </div>
    </div>
  );
}

export function CostHeaderDataComponent({title, data, currency,shouldShow=false}){
    return (
      (data>0 || shouldShow) ?
        (<Paper
            elevation={3}
            style={{
                paddingBottom: 10,
                marginLeft: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 4,
            }}
        >
          <span
              className={"cost-counter"}
              style={{
                  fontSize: "2.3em",
                  // fontFamily: "'Segoe UI', Verdana, Helvetica, sans-serif",
                  fontWeight: 600,
              }}
          >
              <CountUp end={data} duration={1} separator={','}/>
          </span>
            <div style={{ display: "flex", flexDirection: "column",whiteSpace: "nowrap" }}>
            <span
                style={{
                    lineHeight: "0px",
                    marginTop: "-5px",
                    marginBottom: 0,
                    fontSize: "1.0em",
                    paddingLeft: 5,
                    fontFamily: "'Segoe UI', Verdana, Helvetica, sans-serif",
                }}
            >
                {title}
            </span>
            </div>
        </Paper>
        ):null
    )
}
