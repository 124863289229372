import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import {
    getAWSCostCategoryFailure,
    getAWSCostCategorySuccess,
    getAWSTagsFailure,
    getAWSTagsSuccess
} from "../actions/actions";
import axiosCreate from "axios";
const { REACT_APP_AWS_COST_CATEGORIES, REACT_APP_AWS_TAG_COSTS, REACT_APP_NEW_SUBSCRIPTION_KEY } = process.env;

const axios = axiosCreate.create({
    headers: {
        "Ocp-Apim-Subscription-Key": REACT_APP_NEW_SUBSCRIPTION_KEY,
    },
});

function* awsTagsSaga({ payload }) {
    try {
        const res = yield call(axios.post, REACT_APP_AWS_TAG_COSTS, payload);

        const { statusCode } = res.data;
        const status = res.status;

        // if ("statusCode" in res.data) {
        if (status) {
            // if (statusCode !== 200) {
            if (status !== 200) {
                yield put(getAWSTagsFailure(status));
            } else {
                yield put(getAWSTagsSuccess({data: res.data, period: payload.period}));
            }
        } else {
            yield put(getAWSTagsFailure(res));
        }
    } catch (error) {
        yield put(getAWSTagsFailure(error));
    }
}

function* awsCostCategorySaga({ payload }) {
    try {
        const res = yield call(axios.post, REACT_APP_AWS_COST_CATEGORIES, payload);

        // const { statusCode } = res.data;
        const status = res.status;

        // if ("statusCode" in res.data) {
        if (status) {
            // if (statusCode !== 200) {
            if (status !== 200) {
                yield put(getAWSCostCategoryFailure(status));
            } else {
                yield put(getAWSCostCategorySuccess({data: res.data, period: payload.period}));
            }
        } else {
            yield put(getAWSCostCategoryFailure(res));
        }
    } catch (error) {
        yield put(getAWSCostCategoryFailure(error));
    }
}

export default function* () {
    yield takeLatest(types.AWS_TAGS_REQUEST, awsTagsSaga);
    yield takeLatest(types.AWS_COST_CATEGORY_REQUEST, awsCostCategorySaga);
}
