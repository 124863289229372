import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getAllDatabaseSuccess, getAllDatabaseFailure} from "../actions/actions";
const { REACT_APP_ALL_CLOUD_DATABASE } = process.env;

function* getAllDatabaseData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_ALL_CLOUD_DATABASE, {
            params: { customerId: payload.customerId },
           
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAllDatabaseFailure(statusCode));
            } else {
                yield put(getAllDatabaseSuccess(res.data));
            }
        } else {
            yield put(getAllDatabaseFailure(res));
        }
    } catch (error) {
        yield put(getAllDatabaseFailure(error));
    }
}


export default function* allDatabaseSaga() {
    yield takeLatest(types.ALL_DATABASE_REQUEST, getAllDatabaseData);
}
