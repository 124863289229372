import React, { useState, useEffect } from "react";
//import "./style.css";
import { makeStyles, withStyles } from "MakeWithStyles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";

import { ExpandRow } from "./AwsSpendingTable";
import TablePaginationActions from "../TablePaginationActions";
import TableSearch from "../TableSearch";

const StyledTableCell = withStyles(TableCell,(theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: theme.spacing(1.5),
    },
}));
const StyledTableRow = withStyles(TableRow,(theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            // backgroundColor: theme.palette.action.hover,
        },
    },
}));
const useRowStyles = makeStyles()({
    root: {
        "& > *": {
            borderBottom: "unset",
            padding: 3.5,
        },
    },
    table: {
        minWidth: 700,
    },
    container: {
        maxHeight: 440,
    },
    container1: {
        maxHeight: 400,
    },
});

export default function AwsServicesTable({tableRow, view, data, currency }) {
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const {classes} = useRowStyles();

    const [search, setSearch] = useState(null);

    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.serviceCode,
                f?.serviceName,
                f?.resourceId,
                f?.location,
                f?.usageAccountId,
                f?.meter?.[0]?.productFamily
            ], search);
        }
        return true;
    }

    return (
        <Paper elevation={0}>
            <TableSearch onSearch={setSearch} />
            <TableContainer style={{ height: 350 }}className={classes.container}>
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell />
                            <StyledTableCell align="inherit" style={{ fontSize: 17, fontWeight: "bold" }}>
                                Resource
                            </StyledTableCell>

                            <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                Service
                            </StyledTableCell>

                            <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                Location
                            </StyledTableCell>
                            {view=='all' ?
                            <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                AWS Account
                            </StyledTableCell>
                                :null}

                            <StyledTableCell align="center" style={{ fontSize: 17, fontWeight: "bold" }}>
                                Cost
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.services
                            ?.filter(filteredTable)
                            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((r, i) => (
                            <ExpandRow view={view} row={r} index={i} data={data} currency={currency} table={tableRow} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination ActionsComponent={TablePaginationActions} rowsPerPageOptions={[10, 25, 50, 75]} component="div" count={data?.services?.filter(filteredTable)?.length} rowsPerPage={rowsPerPage} page={page} onChangePage={handleChangePage} onChangeRowsPerPage={handleChangeRowsPerPage} />
        </Paper>
    );
}
