import * as types from "../actions/types";

const userState = {
  awsS3RecommendationAccount: null,
  error: null,
  loading: false,
};

function awsS3RecommendationReducer(state = userState, { type, payload }) {
  switch (type) {
     
      case types.AWS_ACCOUNT_S3RECOMMENDATION_REQUEST: {
          return {
              ...state,
              loading: true,
          };
      }
      case types.AWS_ACCOUNT_S3RECOMMENDATION_SUCCESS: {
          return {
              ...state,
              awsS3RecommendationAccount: payload.data,
              loading: false,
          };
      }
      case types.AWS_ACCOUNT_S3RECOMMENDATION_FAILURE: {
          return {
              ...state,
              loading: false,
              awsS3RecommendationAccount: null,
              error: payload,
          };
      }
      case "SWITCH_TEAM_CLEAR": {
          return {
              ...state,
              awsS3RecommendationAccount: null,
              error: null,
              loading: false,
          };
      }

      default:
          return state;
  }
}
export default awsS3RecommendationReducer;
