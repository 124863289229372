import {takeLatest, call, put, takeEvery} from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import {
    getAWSLambdaSuccess,
    getAWSLambdaFailure,
    getAWSLambdaAccountFailure,
    getAWSLambdaAccountSuccess,
    getAWSS3BucketSuccess,
    getAWSS3BucketFailure,
    getAWSRecommendationsFailure, getAWSRecommendationsSuccess,
	getAWSS3MetricDataSuccess,
	getAWSS3MetricDataFailure
} from "../actions/actions";
const { REACT_APP_AWS_LAMBDA, REACT_APP_AWS_LAMBDA_ACCOUNT, REACT_APP_AWS_S3BUCKET_ALL, REACT_APP_AWS_S3BUCKET_ACCOUNT,
    REACT_APP_AWS_IDLE_EC2_RECOMMENDATIONS,
    REACT_APP_AWS_STOPPED_EC2_RECOMMENDATIONS,
    REACT_APP_AWS_ACCOUNT_IDLE_EC2_RECOMMENDATIONS,
    REACT_APP_AWS_ACCOUNT_STOPPED_EC2_RECOMMENDATIONS,
	REACT_APP_AWS_S3_MATRIC_DATA
} = process.env;

function* getAwsLambdaData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AWS_LAMBDA, {
            params: {
            customerId: payload.customerId,
            }
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAWSLambdaFailure(statusCode));
            } else {
                yield put(getAWSLambdaSuccess(res.data));
            }
        } else {
            yield put(getAWSLambdaFailure(res));
        }
    } catch (error) {
        yield put(getAWSLambdaFailure(error));
    }
}

function* getAwsLambdaAccountData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AWS_LAMBDA_ACCOUNT, {
            params: {
            customerId: payload.customerId,
            tenantId: payload.tenantId,
            subscriptionId: payload.subscriptionId,
            }
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAWSLambdaAccountFailure(statusCode));
            } else {
                yield put(getAWSLambdaAccountSuccess(res.data));
            }
        } else {
            yield put(getAWSLambdaAccountFailure(res));
        }
    } catch (error) {
        yield put(getAWSLambdaAccountFailure(error));
    }
}
//------------------------------- AWS S3 Recommendations -------------------------------------------
function* getAwsRecommendations({payload: {data, type}}) {
    if (data?.subscriptionId) {
        try {
            const res = yield call(axios.get,
                type == 'stopped'?
                    REACT_APP_AWS_ACCOUNT_STOPPED_EC2_RECOMMENDATIONS:
                    REACT_APP_AWS_ACCOUNT_IDLE_EC2_RECOMMENDATIONS
                , {
                params: {
                    customerId: data.customerId,
                    tenantId: data.tenantId,
                    subscriptionId: data.subscriptionId,
                }
            });

            const { statusCode } = res.data;

            if ("statusCode" in res.data) {
                if (statusCode !== 200) {
                    yield put(getAWSRecommendationsFailure(statusCode)[type]);
                } else {
                    yield put(getAWSRecommendationsSuccess({...res?.data?.data, accountLevel: true})[type]);
                }
            } else {
                yield put(getAWSRecommendationsFailure(res)[type]);
            }
        } catch (error) {}
    } else {
        try {
            const res = yield call(axios.get, type == 'stopped'?
                REACT_APP_AWS_STOPPED_EC2_RECOMMENDATIONS:
                REACT_APP_AWS_IDLE_EC2_RECOMMENDATIONS, {
                params: {
                    customerId: data.customerId,
                }
            });

            const { statusCode } = res.data;

            if ("statusCode" in res.data) {
                if (statusCode !== 200) {
                    yield put(getAWSRecommendationsFailure(statusCode)[type]);
                } else {
                    yield put(getAWSRecommendationsSuccess({...res.data?.data, accountLevel: false})[type]);
                }
            } else {
                yield put(getAWSRecommendationsFailure(res)[type]);
            }
        } catch (error) {
            yield put(getAWSRecommendationsFailure(error)[type]);
        }
    }
}

//------------------------------- AWS S3 Bucket -------------------------------------------
function* getAwsS3BucketData({payload}) {
    if (payload?.subscriptionId && payload?.tenantId) {
        try {
            const res = yield call(axios.get, REACT_APP_AWS_S3BUCKET_ACCOUNT, {
                params: {
                customerId: payload.customerId,
                tenantId: payload.tenantId,
                subscriptionId: payload.subscriptionId,
                }
            });

            const { statusCode } = res.data;

            if ("statusCode" in res.data) {
                if (statusCode !== 200) {
                    yield put(getAWSS3BucketFailure(statusCode));
                } else {
                    yield put(getAWSS3BucketSuccess(res.data));
                }
            } else {
                yield put(getAWSS3BucketFailure(res));
            }
        } catch (error) {}
    } else {
        try {
            const res = yield call(axios.get, REACT_APP_AWS_S3BUCKET_ALL, {
                params: {
                customerId: payload.customerId,
                }
            });

            const { statusCode } = res.data;

            if ("statusCode" in res.data) {
                if (statusCode !== 200) {
                    yield put(getAWSS3BucketFailure(statusCode));
                } else {
                    yield put(getAWSS3BucketSuccess({data:res.data, all:true}));
                }
            } else {
                yield put(getAWSS3BucketFailure(res));
            }
        } catch (error) {
            yield put(getAWSS3BucketFailure(error));
        }
    }
}

function* getAWSSS3MetricData({ payload}) {
        try {
        const res = yield call(axios.post, REACT_APP_AWS_S3_MATRIC_DATA, {
            customerId: payload.customerId,
            bucketName: payload.bucketName,
            tenantId: payload.tenantId,
            bucketRegion: payload.bucketRegion,
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAWSS3MetricDataFailure(statusCode));
            } else {
                yield put(getAWSS3MetricDataSuccess(res.data));
            }
        } else {
            yield put(getAWSS3MetricDataFailure(res));
        }
    } catch (error) {
        yield put(getAWSS3MetricDataFailure(error));
    }
}

export default function* awsLambdaSaga() {
    yield takeLatest(types.AWS_LAMBDA_REQUEST, getAwsLambdaData);
    yield takeLatest(types.AWS_LAMBDA_ACCOUNT_REQUEST, getAwsLambdaAccountData);
    yield takeLatest(types.AWS_S3BUCKET_REQUEST, getAwsS3BucketData);
    yield takeLatest(types.AWS_S3METRIC_DATA_REQUEST, getAWSSS3MetricData);
    yield takeEvery(types.AWS_RECOMMENDATIONS_REQUEST, getAwsRecommendations);
}
