import * as types from "../actions/types";

const userState = {
  cloudProvider: null,
  tenantId: null,
  subscriptionId: null,
  awsAccountSpending: {
      "1D":{},
      "1W":{},
      "1M":{},
      "3M":{},
      "6M":{},
      "1Y":{}
  },
  awsServicesSpending: {
      "1D": {},
      "1W": {},
      "1M": {},
      "3M": {},
      "6M": {},
      "1Y": {}
  },
  awsSubAccountSpending: {
      "1D": {},
      "1W": {},
      "1M": {},
      "3M": {},
      "6M": {},
      "1Y": {}
  },
  awsSubServicesSpending: {
      "1D":{},
      "1W":{},
      "1M":{},
      "3M":{},
      "6M":{},
      "1Y":{}
  },
  charts: null,
  awsOrgSpending: null,
  awsOrgAccountSpending: null,
  loading: false,
  error: null,
  currency: "",
};

function awsServerlessReducer(state = userState, { type, payload }) {
  switch (type) {
      case types.AWS_SERVERLESS_REQUEST: {
          return {
              ...state,
              loading: true,
          };
      }
      case types.AWS_SERVERLESS_SUCCESS: {
          const { cloudProvider, charts, tenantId, subscriptionId, currency } = payload.data;
          return {
              ...state,
              cloudProvider: cloudProvider,
              tenantId: tenantId,
              subscriptionId: subscriptionId,
              currency: currency,
              charts: charts,
              loading: false,
          };
      }
      case types.AWS_SERVERLESS_FAIL: {
          return {
              ...state,
              loading: false,
              charts: null,
              error: payload,
          };
      }
      case types.GET_AWS_ACCOUNT_SPENDING_REQUEST: {
          return {
              ...state,
              awsSubAccountSpending: userState.awsSubAccountSpending,
              awsSubServicesSpending: userState.awsSubServicesSpending,
              loading: true,
          };
      }
      case types.GET_AWS_ACCOUNT_SPENDING_SUCCESS: {
          const period = payload.view;
          const dataType = payload.dataType;
          const data = dataType == 'accounts' ? {...state.awsAccountSpending}: {...state.awsServicesSpending};
          data[period] = payload?.data?.data?.[period];

          if (dataType == 'accounts') {
              return {
                  ...state,
                  awsAccountSpending: data,
                  loading: false,
              };
          } else {
              return {
                  ...state,
                  awsServicesSpending: data,
                  loading: false,
              };
          }
      }
      case types.GET_AWS_ACCOUNT_SPENDING_FAILURE: {
          return {
              ...state,
              loading: false,
              error: payload,
          };
      }

      case types.GET_AWS_SUB_SPENDING_REQUEST: {
          return {
              ...state,
              loading: true,
              awsSubAccountSpending: userState.awsSubAccountSpending,
              awsSubServicesSpending: userState.awsSubServicesSpending,
          };
      }
      case types.GET_AWS_SUB_SPENDING_SUCCESS: {
          const period = payload.view;
          const dataType = payload.dataType;
          const data = dataType == 'accounts' ? {...state.awsSubAccountSpending}: {...state.awsSubServicesSpending};
          data[period] = payload?.data?.data?.[period];

          if (dataType == 'accounts') {
              return {
                  ...state,
                  awsSubAccountSpending: data,
                  loading: false,
              };
          } else {
              return {
                  ...state,
                  awsSubServicesSpending: data,
                  loading: false,
              };
          }
      }
      case types.GET_AWS_SUB_SPENDING_FAILURE: {
          return {
              ...state,
              loading: false,
              error: payload,
              awsSubAccountSpending: userState.awsSubAccountSpending,
              awsSubServicesSpending: userState.awsSubServicesSpending
          };
      }
      case types.GET_AWS_ORG_SPENDING_REQUEST: {
          return {
              ...state,
              loading: true,
              awsOrgAccountSpending: null,
          };
      }
      case types.GET_AWS_ORG_SPENDING_SUCCESS: {
          let { organizations, tenantId } = payload?.data;
          if ("tenantId" in payload?.data) {
              return {
                  ...state,
                  awsOrgAccountSpending: organizations,
                  loading: false,
              };
          } else {
              return {
                  ...state,
                  awsOrgSpending: organizations,
                  loading: false,
              };
          }
      }
      case types.GET_AWS_ORG_SPENDING_FAILURE: {
          return {
              ...state,
              loading: false,
              error: payload,
              awsOrgAccountSpending: null,
          };
      }
      case "SWITCH_TEAM_CLEAR": {
          return {
              ...state,
              cloudProvider: null,
              tenantId: null,
              subscriptionId: null,
              awsAccountSpending: userState.awsAccountSpending,
              awsServicesSpending: userState.awsServicesSpending,
              awsSubAccountSpending: userState.awsSubAccountSpending,
              awsSubServicesSpending: userState.awsSubServicesSpending,
              charts: null,
              awsOrgSpending: null,
              awsOrgAccountSpending: null,
              loading: false,
              error: null,
              currency: "",
          };
      }

      default:
          return state;
  }
}
export default awsServerlessReducer;
