import * as types from "../actions/types";

const userState = {
  invoices: null,
  loadingI: false,
  error: null,
};

function invoiceReducer(state = userState, { type, payload }) {
  switch (type) {
      case "SWITCH_TEAM_CLEAR": {
          return {
              ...state,
              invoices: null,
              loadingI: false,
              error: null,
          };
      }
      case types.GET_INVOICE_JSON_REQUEST: {
          return {
              ...state,
              loadingI: true,
          };
      }
      case types.GET_INVOICE_JSON_SUCCESS: {
          const { invoices } = payload.data;
          return {
              ...state,
              invoices: invoices,
              loadingI: false,
          };
      }
      case types.GET_INVOICE_JSON_FAILURE: {
          return {
              ...state,
              loadingI: false,
              error: payload,
          };
      }

      default:
          return state;
  }
}
export default invoiceReducer;
