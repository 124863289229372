import React, { useEffect, useState } from "react";
import "./style.css";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "MakeWithStyles";
import { getAwsAccountSpendingRequest, getAwsSubSpendingRequest } from "../../../redux/actions/actions";
import { LinearProgress, Paper, Collapse, IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import AwsSpendingTable, { ExpandableTableAws } from "components/table/AwsSpendingTable";
import AwsServicesTable from "components/table/AwsServicesTable";
import Format from "components/NumberFormat";
import DateRange from "components/DateRange";
import aws_account from "../../../images/newCloudTrakrIcons/AWSAccounts.png";
import resource_group from "../../../images/newCloudTrakrIcons/AWSResources.png";
import StartEndDate from "components/StartEndDate";
import moment from "moment";
import ContainerCardWithDateRange from "../../../components/ContainerCardWithDateRange";
import TableSearch from "../../../components/TableSearch";
import TableMonthlySpend from "../../AllClouds/TableMonthlySpend";
import {getMonthsFromNow} from "../../../util/DateFormatter";
import {isNumeric} from "../../../util/Miscellaneous";
import all_resource from "../../../images/All-Resources.svg";
import NO_DATA_AVAILABLE from "../../../components/NO_DATA_AVAILABLE";

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        //marginBottom: 5,
    },
}));
export default function AwsAccountData({yearTableData, yearTableDataLoading, selectedSubscription, currency, view, customerId }) {
    const dispatch = useDispatch();
    const {classes} = useStyles();
    const [period, setPeriod] = useState("1M");
    const [totalExpand, setTotalExpand] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [parentWidth, setParentWidth] = useState(null);
    const [spendByMonthAccountData, setSpendByMonthAccountData] = useState(null);
    const [ytdSearch, setYTDSearch] = useState(null);
    const { awsAccountSpending, awsServicesSpending, awsSubAccountSpending, awsSubServicesSpending, loading } = useSelector((state) => ({
        awsAccountSpending: state.awsServerlessReducer.awsAccountSpending,
        awsServicesSpending: state.awsServerlessReducer.awsServicesSpending,
        awsSubAccountSpending: state.awsServerlessReducer.awsSubAccountSpending,
        awsSubServicesSpending: state.awsServerlessReducer.awsSubServicesSpending,
        loading: state.awsServerlessReducer.loading,
    }));
    useEffect(() => {
        let { title, tenantId, value } = selectedSubscription;
        if (title == "All") {
            let obj1 = { customerId, view: period, dataType: view };
            dispatch(getAwsAccountSpendingRequest(obj1));
            // if (view == "accounts") {
            //     if (awsAccountSpending?.[view] || awsAccountSpending?.[view] == undefined) {
            //         dispatch(getAwsAccountSpendingRequest(obj1));
            //     }
            // } else {
            //     if (awsServicesSpending?.[view] || awsServicesSpending?.[view] == undefined) {
            //         dispatch(getAwsAccountSpendingRequest(obj1));
            //     }
            // }
        } else {
            let obj = { customerId, tenantId, value, view: period, dataType: view };
            dispatch(getAwsSubSpendingRequest(obj));
        }
    }, [period, customerId, selectedSubscription]);

    useEffect(()=>{
        if(period){
            const final = selectedSubscription.title == "All"?
                (view == "accounts" ?
                    awsAccountSpending && {start: moment(awsAccountSpending[period]?.startDate, "YYYYMMDD").format("MMM DD, YYYY"), end: moment(awsAccountSpending[period]?.endDate, "YYYYMMDD").format("MMM DD, YYYY") }:
                    awsServicesSpending && {start: moment(awsServicesSpending[period]?.startDate, "YYYYMMDD").format("MMM DD, YYYY") , end: moment(awsServicesSpending[period]?.endDate, "YYYYMMDD").format("MMM DD, YYYY")})
                        : (view == "accounts"
                            ? awsSubAccountSpending && {start: moment(awsSubAccountSpending[period]?.startDate, "YYYYMMDD").format("MMM DD, YYYY"), end: moment(awsSubAccountSpending[period]?.endDate, "YYYYMMDD").format("MMM DD, YYYY") }:
                            awsSubServicesSpending &&   {start: moment(awsSubServicesSpending[period]?.startDate, "YYYYMMDD").format("MMM DD, YYYY") , end: moment(awsSubServicesSpending[period]?.endDate, "YYYYMMDD").format("MMM DD, YYYY")})
            setStartDate(moment(final?.start).isValid()? final?.start : null);
            setEndDate(moment(final?.end).isValid()? final?.end : null);
        }
    }, [period, selectedSubscription, awsAccountSpending, awsServicesSpending, awsSubAccountSpending, awsSubServicesSpending]);

    function rootLoaded() {
        if (parentWidth) return;
        const e = window.$("#root_dashboard");
        try {
            setParentWidth(e.width());
        } catch (e) {}
    }

    useEffect(()=>{
        setSpendByMonthAccountData(null)
        if(yearTableData){
            const accounts = {};
            const finalData = [];

            if(view == 'accounts') {
                if(selectedSubscription?.tenantId){
                    yearTableData?.forEach(monthWise => {
                        if (selectedSubscription?.value in accounts) {
                            accounts[selectedSubscription?.value].list.push({month: monthWise.month, cost: monthWise.cost});
                        } else {
                            accounts[selectedSubscription?.value] = {list: [], name: selectedSubscription?.title || selectedSubscription?.value};
                            accounts[selectedSubscription?.value].list.push({month: monthWise.month, cost: monthWise.cost});
                        }
                    })
                } else {
                    yearTableData?.forEach(monthWise => {
                        monthWise?.subscriptions?.forEach((subWise) => {
                            if (subWise.subscriptionId in accounts) {
                                accounts[subWise.subscriptionId].list.push({month: monthWise.month, cost: subWise.cost});
                            } else {
                                accounts[subWise.subscriptionId] = {list: [], name: subWise.subscriptionName || subWise.subscriptionId};
                                accounts[subWise.subscriptionId].list.push({month: monthWise.month, cost: subWise.cost});
                            }
                        })
                    });
                }
            } else {
                yearTableData?.forEach(monthWise=>{
                    monthWise?.resources?.forEach((subWise)=>{
                        if(subWise.resourceId in accounts){
                            accounts[subWise.resourceId].list.push({month: monthWise.month, cost: subWise.cost});
                        } else {
                            accounts[subWise.resourceId] = {list: []};
                            accounts[subWise.resourceId].list.push({month: monthWise.month, cost: subWise.cost});
                        }

                    })
                });
            }

            Object.keys(accounts).forEach(key=>{
                const account = accounts[key].list;

                const newData = getMonthsFromNow().reverse().map((m, i) => {
                    const time = moment(m, "MMM YYYY");
                    const found = account.findIndex((d) => {
                        const int = moment(d?.month, "YYYYMM");
                        return time.isSame(int);
                    });
                    if (found > -1) {
                        return account?.[found];
                    } else {
                        return {month: parseInt(moment(m).format("YYYYMM")), cost: "_"};
                    }
                });

                finalData.push({subscriptionId: key, name: accounts[key].name, list: newData})
            });

            let otherRow = [];
            if(finalData && finalData.length){
                const totalData = finalData.map(f=>f.list.map(l=>isNumeric(l.cost)?parseFloat(l.cost):'-'));
                const finalTotal = [null,null,null,null,null,null,null,null,null,null,null,null,null];
                for(var col = 0; col < (totalData?.[0]?.length) || 0; col++){
                    totalData.forEach(row=>{
                        if(isNumeric(String(row[col])))
                            finalTotal[col] = (finalTotal[col] || 0) + row[col];
                    })
                }

                otherRow.push({
                    subscriptionId: 'All ' + (view == "accounts" ? "Accounts" : "Resources"),
                    list: finalData?.[0]?.list?.map((f, i)=>({month: f.month, cost: ""+finalTotal[i]}))
                });
            }

            setSpendByMonthAccountData({list: finalData, other: otherRow});
        }
    }, [yearTableData]);

    return (
        <div>
            <ContainerCardWithDateRange
                title={view == "accounts" ? "Spend by accounts" : "Spend by resources"}
                titleIcon={view == "accounts" ? aws_account : resource_group}
                defaultPeriod={"1M"}
                showDateRange={true}
                collapsible={true}
                datePeriod={({ period }) => ({
                    start:"__",
                    end: "__",
                    rawStart: startDate,
                    rawEnd: endDate,
                })}
                totalCost={({ period }) =>
                    selectedSubscription.title == "All" ? (
                        view == "accounts"
                            ? awsAccountSpending && `${currency + Format.formatNumber(awsAccountSpending?.[period]?.totalCost || 0)}`
                            : awsServicesSpending && `${currency + Format.formatNumber(awsServicesSpending?.[period]?.totalCost || 0)}`
                    ) : (
                        view == "accounts"
                        ? awsSubAccountSpending && `${currency + Format.formatNumber(awsSubAccountSpending?.[period]?.totalCost || 0)}`
                        : awsSubServicesSpending && `${currency + Format.formatNumber(awsSubServicesSpending?.[period]?.totalCost || 0)}`
                    )
                }
                datePeriodEnabled={true}
                showDatePeriod={(period) => startDate && endDate}
                onPeriodChange={(period)=> {
                    setPeriod(period)
                }}
            >
                {({ period }) => (
                    loading?
                    <LinearProgress />:
                    selectedSubscription.title == "All" ? (
                            view == "accounts" ? (
                                awsAccountSpending != null ? (
                                    awsAccountSpending && "accounts" in awsAccountSpending?.[period] ? (
                                        <AwsSpendingTable view={'all'} tableRow={awsAccountSpending[period]?.accounts} data={awsAccountSpending[period]} currency={currency} />
                                    ) : (
                                        <h6 style={{ textAlign: "center" }}>No data found</h6>
                                    )
                                ) : (
                                    <h6 style={{ textAlign: "center" }}>No data found</h6>
                                )
                            ) : awsServicesSpending != null ? (
                                awsServicesSpending && "services" in awsServicesSpending?.[period] ? (
                                    <AwsServicesTable view={'all'} tableRow={view} data={awsServicesSpending[period]} currency={currency} />
                                ) : (
                                    <h6 style={{ textAlign: "center" }}>No data found</h6>
                                )
                            ) : (
                                <h6 style={{ textAlign: "center" }}>No data found</h6>
                            )
                        ) : view == "accounts" ? (
                            awsSubAccountSpending != null ? (
                                awsSubAccountSpending && "accounts" in awsSubAccountSpending[period] ? (
                                    <AwsSpendingTable view={'accounts'}tableRow={awsSubAccountSpending[period]?.accounts} data={awsSubAccountSpending[period]} currency={currency} />
                                ) : (
                                    <h6 style={{ textAlign: "center" }}>No data found</h6>
                                )
                            ) : (
                                <h6 style={{ textAlign: "center" }}>No data found</h6>
                            )
                        ) : awsSubServicesSpending != null ? (
                            awsSubServicesSpending && "services" in awsSubServicesSpending[period] ? (
                                <AwsServicesTable view={'accounts'} tableRow={view} data={awsSubServicesSpending[period]} currency={currency} />
                            ) : (
                                <h6 style={{ textAlign: "center" }}>No data found</h6>
                            )
                        ) : (
                            <h6 style={{ textAlign: "center" }}>No data found</h6>
                        )
                )}
            </ContainerCardWithDateRange>
            <br/>
            <div style={{}}>
                <ContainerCardWithDateRange title={"Spend by months"} titleIconHeight={view == "accounts"? 25:25}
                                            titleIcon={view == "accounts" ? aws_account : resource_group}
                                            showDateRange={false}
                                            collapsible={true}>
                    {
                        ()=>(
                            <>
                                {(yearTableDataLoading || spendByMonthAccountData)?<TableSearch onSearch={setYTDSearch}/>:null}
                                {(yearTableDataLoading || spendByMonthAccountData)?<TableMonthlySpend
                                    loading={yearTableDataLoading} cols={14}
                                    subscriptionNameAsOverlay={(view == "accounts")}
                                    subscriptionLevel={!(view == "accounts")}
                                    tableLabel={view == "accounts" ? "Account" : "Resource"}
                                    width={parentWidth}
                                    data={spendByMonthAccountData?.list?.filter(s=>{
                                        if (ytdSearch && ytdSearch.toString().length) {
                                            return !!TableSearch.doesItIncludes(
                                                [
                                                    s.cloud,
                                                    s.subscriptionId
                                                ],
                                                ytdSearch
                                            );
                                        }
                                        return true;
                                    })}
                                    staticRows={spendByMonthAccountData?.other}
                                    currency={currency}
                                    showCloud={false}
                                />:<NO_DATA_AVAILABLE height={'auto'} />}
                            </>
                        )
                    }
                </ContainerCardWithDateRange>
            </div>
        </div>
    );
}
