import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { azureServerlessSuccess, azureServerlessFail,azureServerlessHomeFail,azureServerlessHomeSuccess,azureServerlessTenantFail,azureServerlessTenantSuccess } from "../actions/actions";
const { REACT_APP_AZURE_SERVERLESS,REACT_APP_AZURE_HOME_SERVERLESS,REACT_APP_AZURE_TENANT_SERVERLESS } = process.env;

function* getAzureServerlessData({ payload }) {
    const { customerId, tenantId, subscriptionId } = payload;
    try {
        const res = yield call(axios.get, REACT_APP_AZURE_SERVERLESS, {
            params: {
                customerId: customerId,
                tenantId: tenantId,
                subscriptionId: subscriptionId,
            },
        });

        const { statusCode } = res.data;
        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(azureServerlessFail(statusCode));
            } else {
                yield put(azureServerlessSuccess(res.data));
            }
        } else {
            yield put(azureServerlessFail(res.data));
        }
    } catch (error) {
        yield put(azureServerlessFail(error));
    }
}

function* getAzureServerlessHomeData({ payload }) {
    const { customerId} = payload;
    try {
        const res = yield call(axios.get, REACT_APP_AZURE_HOME_SERVERLESS, {
            params: {
                customerId: customerId,
            },
        });

        const { statusCode } = res.data;
        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(azureServerlessHomeFail(statusCode));
            } else {
                yield put(azureServerlessHomeSuccess(res.data));
            }
        } else {
            yield put(azureServerlessHomeFail(res.data));
        }
    } catch (error) {
        yield put(azureServerlessHomeFail(error));
    }
}

function* getAzureServerlessTenantData({ payload }) {
    const { customerId, tenantId, subscriptionId } = payload;
    try {
        const res = yield call(axios.get, REACT_APP_AZURE_TENANT_SERVERLESS, {
            params: {
                customerId: customerId,
                tenantId: tenantId,
            },
        });

        const { statusCode } = res.data;
        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(azureServerlessTenantFail(statusCode));
            } else {
                yield put(azureServerlessTenantSuccess(res.data));
            }
        } else {
            yield put(azureServerlessTenantFail(res.data));
        }
    } catch (error) {
        yield put(azureServerlessTenantFail(error));
    }
}

export default function* azureServerlessSaga() {
    yield takeLatest(types.AZURE_SERVERLESS_REQUEST, getAzureServerlessData);
    yield takeLatest(types.AZURE_SERVERLESS_HOME_REQUEST, getAzureServerlessHomeData);
    yield takeLatest(types.AZURE_SERVERLESS_TENANT_REQUEST, getAzureServerlessTenantData);

}
