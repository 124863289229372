import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    azureRecommendationRequest,
    getAzureVmStoppedRecommendationRequest,
    getAzureTenantVmStoppedRecommendationRequest,
    getAzureCosmosDBRequest,
    getAzureDBServerRecommendationsRequest,
    getAzureFunctionAppRecommendationsRequest,
    getAzureIdleVMRecommendationsRequest,
    getAzureSQLManagedInstanceRecommendationsRequest,
    getAzureStoppedVMAllRecommendationsRequest,
    getAzureStorageRecommendationsSuccess,
    getAzureStorageRecommendationsRequest,
    getAzureVMMigrateRecommendationsRequest,
    getAzureVMRightsizingRecommendationsRequest
} from "../../redux/actions/actions";
import * as types from "../../redux/actions/types";
import { makeStyles, withStyles } from "MakeWithStyles";
import LinearProgress from "@mui/material/LinearProgress";
import {Info, ThumbUp} from "@mui/icons-material";
import AzureLogo from "../../images/Azure_logo.svg";

import "./style.css";
import TotalRecommend from "./TotalRecommend";
import Recommendationtypes from "./Recommendationtypes";
import AWSAccountRecommendations from "./AWSAccountRecommendations";
import AzureSubscriptionRecommendations from "./AzureSubscriptionRecommendations";
import AzureStoppedVmRecommendation from "./AzureStoppedVmRecommendation";
import Format from "../../components/NumberFormat";
import recommendation_icon from "../../images/thumbsup_icon.png";
import ContainerCardWithDateRange from "../../components/ContainerCardWithDateRange";
import RecommendationsCard from "../../components/RecommendationsCard";
import Ec2Icon from "../../images/EC2.svg";
import AwsLogo from "../../images/aws_dark.svg";
import moment from "moment/moment";
import RecommendationsSummaryCards from "./RecommendationSummaryCards";
import RecommendationsTable from "./RecommendationTable";
import {CircularProgress, TableBody} from "@mui/material";
import DashboardIcon from "../../images/newCloudTrakrIcons/MultiCloudIcon.png";
import billing_icon from "../../images/billing_icon.svg";
import aws_recommendation from "../../images/aws_recommendation.svg";
import aws_account from "../../images/aws_org.svg";
import azure_service from "../../images/resource_group.svg";
import all_resource from "../../images/All-Resources.svg";
import allCloudCompute_icon from "../../images/allCloudCompute.png";
import database_icon from "../../images/database.png";
import allCloudFunctions_icon from "../../images/allCloudFunctions.png";
import allCloudStorage_icon from "../../images/allCloudStorage.png";
import COMBINED_CLOUD_LOGO from "../../images/allCloudLogo.svg";
import management_group from "../../images/management_group.svg";
import subscription_icon from "../../images/key.svg";
import ResourceGroupsIcon from "../../images/resources/Resource_Group.svg";
import ComputeIcon from "../../images/Virtual-Machine.svg";
import Azure_cosmosDB_icon from "../../images/Azure-Cosmos-DB.svg";
import function_apps_icon from "../../images/function-apps.png";
import azure_reservations from "../../images/newCloudTrakrIcons/AzureReservations.png";
import AzureTagsIcon from "../../images/newCloudTrakrIcons/AzureTags.png";
import AZURE_LOGO from "../../images/Azure_logo.svg";
import aws_org from "../../images/awsAccounts/Aws-Org-newIcon.svg";
import resource_group from "../../images/resource_group.svg";
import S3Icon from "../../images/awsAccounts/Amazon-Simple-_Storage-Service-S3.svg";
import RDS from "../../images/RDS.svg";
import DynamoDBIcon from "../../images/DynamoDB.svg";
import lambdaFunctionIcon from "../../images/lambdaFucntion.png";
import reservation from "../../images/Reserved-Instance-Reporting.svg";
import AWS_LOGO from "../../images/aws_dark.svg";
import gcp_org from "../../images/gcp_org.svg";
import gcp_billing from "../../images/gcp_billing.svg";
import gcp_project from "../../images/gcp_project.svg";
import gcp_compute from "../../images/gcp_compute.svg";
import gcp_storage from "../../images/gcp_storage.svg";
import gcp_big_query from "../../images/gcp_bigquery.svg";
import GCP_LOGO from "../../images/GCP_logo.svg";
import vm_icon from "../../images/Virtual-Machine.svg";
import azure_storage from "../../images/storage-accounts.png";
import ArrowDown from "../../images/double-chevron-down.svg";
import MountUnmount from "../../components/MountUnmount";
import ArrowUp from "../../images/double-chevron-up.svg";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Color from "../../styles/color";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TablePaginationActions from "../../components/TablePaginationActions";
import TablePagination from "@mui/material/TablePagination";
import TableSearch from "../../components/TableSearch";
import TableSortHeader from "../../components/TableSortHeader";
import {TABLE_SORT} from "../../util/AppConstants";
import {ALPHA_NUM_SORTER} from "../../util/Miscellaneous";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const {
    REACT_APP_AZURE_COSMOSDB_RECOMMENDATION,
    REACT_APP_AZURE_DBSERVER_RECOMMENDATION,
    REACT_APP_AZURE_FUNCTION_APP_RECOMMENDATION,
    REACT_APP_AZURE_IDLE_VM_RECOMMENDATION,
    REACT_APP_AZURE_SQL_MANAGED_INSTANCE_RECOMMENDATION,
    REACT_APP_AZURE_STOPPED_VM_RECOMMENDATION,
    REACT_APP_AZURE_STORAGE_RECOMMENDATION,
    REACT_APP_AZURE_VM_MIGRATE_RECOMMENDATION,
    REACT_APP_AZURE_VM_RIGHTSIZING_RECOMMENDATION
} = process.env;



const useStyles = makeStyles()((theme) => ({
    // root: {
    //     flexGrow: 1,
    //     width: "100%",
    //     //marginBottom: 5,
    // },
        root: {
            backgroundColor: Color.tableHeader,
            padding: theme.spacing(0.5),
        },

        tableRow: {
            whiteSpace: "nowrap",
            fontWeight: "bold",
            backgroundColor: Color.tableHeader,
            fontSize:12
        },
    sortCell: {
        backgroundColor: Color.tableHeader,
    },

    metricsStyle: {
            border: "1px solid #338307",margin:2,padding:2,fontSize:14
        }
}));

export default function AzureRecommendation({level = 'all', somerefs = [], showCards = true, showCollapseButton = true, currency, tenantId, subscriptionId, customerId, progress, collapseRef }) {
    const {classes} = useStyles();
    const [allResources, setAllResources] = useState(false);
    const [stoppedSumCard, setStoppedSumCard] = useState(null);
    const [tenentStoppedSumCard, setTenantStoppedSumCard] = useState(null);
    const [subStoppedSumCard, setSubStoppedSumCard] = useState(null);

    const dispatch = useDispatch();
    const recommendations = useSelector((state) => state.azureRecommendationReducer.recommendations);
    const azureAllRecommendation = useSelector((state) => state.azureRecommendationReducer.azureAllRecommendation);
    const azureAllStoppedVMRecommendation = useSelector((state) => state.azureRecommendationReducer.stoppedVMRecommendationsData);
    const azureTenantStoppedVMRecommendation = useSelector((state) => state.azureStoppedVmRecommendationReducer.azureStoppedVmRecommendationTenantData);
    const subStoppedVMRecommendationsData = useSelector((state) => state.azureRecommendationReducer.subStoppedVMRecommendationsData);

    const loading = useSelector((state) => state.azureRecommendationReducer.loading);
    const azureAllStoppedVMRecommendationLoading = useSelector((state) => state.azureStoppedVmRecommendationReducer.loading);
    const subStoppedVMRecommendationsLoading = useSelector((state) => state.azureRecommendationReducer.subStoppedVMRecommendationsLoading);

    const {idleRecommendations, idleRecommendationsLoading, stoppedRecommendations, stoppedRecommendationsLoading, rightsizingRecommendations, rightsizingRecommendationsLoading} = useSelector((state) =>({
        idleRecommendations: state.azureRecommendationReducer.idleVMRecommendationsData,
        idleRecommendationsLoading: state.azureRecommendationReducer.idleVMRecommendationsLoading,
        stoppedRecommendations: state.azureRecommendationReducer.stoppedVMRecommendationsData,
        stoppedRecommendationsLoading: state.azureRecommendationReducer.stoppedVMRecommendationsLoading,
        rightsizingRecommendations: state.azureRecommendationReducer.vmRightsizingRecommendationsData,
        rightsizingRecommendationsLoading: state.azureRecommendationReducer.vmRightsizingRecommendationsLoading,
    }));

    const [dashboardComponentsRef, setDashboardComponentsRef] = useState({});
    const [dashboardComponentsCollapsed, setDashboardComponentsCollapsed] = useState(false);
    const [totalIdleCost, setTotalIdleCost] = useState(null);

    const [totalRecommendationsCost, setTotalRecommendationsCost] = useState(null);

    useEffect(()=>{
        let cost = null;
        if(stoppedRecommendations?.recommendations?.length || subStoppedVMRecommendationsData?.recommendations?.length){
            cost = parseFloat(((subStoppedSumCard || stoppedSumCard)?.[1]?.value?.split(currency)?.[1]) || 0);
        }
        if(totalIdleCost!== '-'){
            cost = (cost || 0) + totalIdleCost;
        }
        setTotalRecommendationsCost((cost !== null)?Format.formatNumber(cost):"--")
    }, [subStoppedSumCard, stoppedSumCard, totalIdleCost]);
    async function CallRecommendation(data) {
        dispatch(azureRecommendationRequest(data.customerId));
        dispatch({
            type: types.AZURE_SUB_STOPPED_VM_RECOMMENDATION_REQUEST,
            payload: data,
        });
        dispatch(getAzureCosmosDBRequest(data.customerId));
        dispatch(getAzureDBServerRecommendationsRequest(data));
        dispatch(getAzureFunctionAppRecommendationsRequest(data));
        dispatch(getAzureIdleVMRecommendationsRequest(data));
        dispatch(getAzureSQLManagedInstanceRecommendationsRequest(data));
        dispatch(getAzureStoppedVMAllRecommendationsRequest(data));
        dispatch(getAzureStorageRecommendationsRequest(data));
        dispatch(getAzureVMMigrateRecommendationsRequest(data));
        dispatch(getAzureVMRightsizingRecommendationsRequest(data));
    }
    async function AllAzureRecommend(id) {
        if (azureAllRecommendation == null) {
            dispatch({ type: types.AZURE_HOME_RECOMMENDATION_REQUEST, payload: id });
        }
        // if (azureAllStoppedVMRecommendation == null) {
        //     const obj = { customerId: customerId };
        //     dispatch(getAzureVmStoppedRecommendationRequest(obj));
        // }
    }
    useEffect(() => {
        if (subscriptionId) {
            setAllResources(false);
            const data = { customerId, tenantId, subscriptionId, level };
            CallRecommendation(data);
        }
        else if(tenantId){
            const data = { customerId, tenantId, level };
            CallRecommendation(data);
            // dispatch(
            //     getAzureTenantVmStoppedRecommendationRequest({
            //         customerId,
            //         tenantId,
            //     })
            // );
        }
         else {
            setAllResources(true);
            CallRecommendation({customerId, level});
            AllAzureRecommend(customerId);
        }
    }, [customerId, tenantId, subscriptionId]);

    useEffect(() => {
        setSubStoppedSumCard(null);
        if (subStoppedVMRecommendationsData?.recommendations?.length) {
            const sumCards = [];
            sumCards.push({ name: "Stopped Virtual Machines", value: subStoppedVMRecommendationsData?.recommendations?.length });
            sumCards.push({
                name: "Potential Savings / month",
                value: Format.formatCurrencySymbol(subStoppedVMRecommendationsData?.currency) + Format.formatNumber(subStoppedVMRecommendationsData?.recommendations?.map((r) => r?.totalAvgDiskCostPerDay)?.reduce((a, b) => a + b, 0) * 30),
            });
            sumCards.push({ name: "Total Saved", value: Format.formatCurrencySymbol(subStoppedVMRecommendationsData?.currency) + "0.00" });
            setSubStoppedSumCard(sumCards);
        }
    }, [subStoppedVMRecommendationsData]);

    useEffect(() => {
        setStoppedSumCard(null);
        if (stoppedRecommendations?.recommendations?.length) {
            const sumCards = [];
            sumCards.push({ name: "Stopped Virtual Machines", value: stoppedRecommendations?.recommendations?.length });
            sumCards.push({
                name: "Potential Savings / month",
                value: Format.formatCurrencySymbol(stoppedRecommendations?.currency) + Format.formatNumber(stoppedRecommendations?.recommendations?.map((r) => r?.totalAvgDiskCostPerDay)?.reduce((a, b) => a + b, 0) * 30),
            });
            sumCards.push({ name: "Total Saved", value: Format.formatCurrencySymbol(stoppedRecommendations?.currency) + "0.00" });
            setStoppedSumCard(sumCards);
        }
    }, [stoppedRecommendations]);

    // useEffect(() => {
    //     setStoppedSumCard(null);
    //     if (azureAllStoppedVMRecommendation?.recommendations?.length) {
    //         const sumCards = [];
    //         sumCards.push({ name: "Stopped Virtual Machines", value: azureAllStoppedVMRecommendation?.recommendations?.length });
    //         sumCards.push({
    //             name: "Potential Savings / month",
    //             value: Format.formatCurrencySymbol(azureAllStoppedVMRecommendation?.currency) + Format.formatNumber(azureAllStoppedVMRecommendation?.recommendations?.map((r) => r?.totalAvgDiskCostPerDay)?.reduce((a, b) => a + b, 0) * 30),
    //         });
    //         sumCards.push({ name: "Total Saved", value: Format.formatCurrencySymbol(azureAllStoppedVMRecommendation?.currency) + "0.00" });
    //         setStoppedSumCard(sumCards);
    //     }
    // }, [azureAllStoppedVMRecommendation]);

    // useEffect(() => {
    //     setTenantStoppedSumCard(null);
    //     if (azureTenantStoppedVMRecommendation?.recommendations?.length) {
    //         const sumCards = [];
    //         sumCards.push({ name: "Stopped Virtual Machines", value: azureTenantStoppedVMRecommendation?.recommendations?.length });
    //         sumCards.push({
    //             name: "Potential Savings / month",
    //             value: Format.formatCurrencySymbol(azureTenantStoppedVMRecommendation?.currency) + Format.formatNumber(azureTenantStoppedVMRecommendation?.recommendations?.map((r) => r?.totalAvgDiskCostPerDay)?.reduce((a, b) => a + b, 0) * 30),
    //         });
    //         sumCards.push({ name: "Total Saved", value: Format.formatCurrencySymbol(azureTenantStoppedVMRecommendation?.currency) + "0.00" });
    //         setTenantStoppedSumCard(sumCards);
    //     }
    // }, [azureTenantStoppedVMRecommendation]);

    function collapseCards() {
        const h = dashboardComponentsRef;
        for(const rg in h){
            h[rg](dashboardComponentsCollapsed);
        }
        setDashboardComponentsCollapsed(!dashboardComponentsCollapsed);
    }

    // useEffect(()=>{
    //     setStoppedSumCard(null);
    //     setTenantStoppedSumCard(null);
    //     setSubStoppedSumCard(null);
    // }, [level])

    useEffect(()=>{
        const totalIdleCost = idleRecommendations?.data?.map(a=>parseFloat(a?.recommendations?.monthlySavings)).reduce((a,b)=> a+b, 0);
        setTotalIdleCost(idleRecommendations?.data?.length?parseFloat(totalIdleCost):"-");
    }, [idleRecommendations])


    return loading||subStoppedVMRecommendationsLoading
    // || azureAllStoppedVMRecommendationLoading
    ? (
        <div className={classes.root}>
            <LinearProgress variant="determinate" value={progress} />
        </div>
    ) : (
        <>
            {showCards?<div>
                <RecommendationsCard summary={[
                    {
                        title: "Total Potential Savings / mon",
                        cost: currency + totalRecommendationsCost,
                    },
                    {
                        title: "Stopped VM ",
                        value: (subStoppedSumCard || stoppedSumCard)?.[0]?.value || 0,
                        costTitle: 'Potential savings /mon',
                        cost: (subStoppedSumCard || stoppedSumCard)?.[1]?.value || (currency + "-"),
                        icon: vm_icon
                    },
                    {
                        title: "Idle VM ",
                        value: idleRecommendations?.data?.length || 0,
                        costTitle: 'Potential savings /mon',
                        cost: currency + Format.formatNumber(idleRecommendations?.data?.map(a=>parseFloat(a?.recommendations?.monthlySavings)).reduce((a,b)=> a+b, 0)) || (currency + "-"),
                        icon: vm_icon
                    },
                    {
                        title: "Rightsizing VM",
                        value: 0,
                        costTitle: 'Potential savings /mon',
                        cost: currency + "-",
                        icon: vm_icon
                    },
                    {
                        title: "CosmosDB ",
                        value: 0,
                        costTitle: 'Potential savings /mon',
                        cost: currency + "-",
                        icon: Azure_cosmosDB_icon
                    },
                    {
                        title: "DB Server ",
                        value: 0,
                        costTitle: 'Potential savings /mon',
                        cost: currency + "-",
                        icon: database_icon
                    },
                    {
                        title: "Function App ",
                        value: 0,
                        costTitle: 'Potential savings /mon',
                        cost: currency + "-",
                        icon: function_apps_icon
                    },
                    {
                        title: "SQL Managed Instance ",
                        value: 0,
                        costTitle: 'Potential savings /mon',
                        cost: currency + "-",
                        icon: Azure_cosmosDB_icon
                    },
                    {
                        title: "Storage ",
                        value: 0,
                        costTitle: 'Potential savings /mon',
                        cost: currency + "-",
                        icon: azure_storage
                    },
                    {
                        title: "VM Migrate ",
                        value: 0,
                        costTitle: 'Potential savings /mon',
                        cost: currency + "-",
                        icon: vm_icon
                    },

                ]}/>
            </div>:null}
            {showCollapseButton?<div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <span onClick={collapseCards} className={'link-button'}>{dashboardComponentsCollapsed?"Expand All":"Collapse All"}
                    <img src={!dashboardComponentsCollapsed?ArrowUp:ArrowDown} height={20} />
                            </span>
            </div>:null}
            <MountUnmount onUnmount={()=> {
                            setDashboardComponentsRef({});
                            setDashboardComponentsCollapsed(false);
                        }} />
            {subscriptionId? (
                    <AzureStoppedVmRecommendation
                        data={subStoppedVMRecommendationsData}
                        title={"Stopped VM Recommendations"}
                        cards={subStoppedSumCard}
                        customerId={customerId}
                        subscriptionId={subStoppedVMRecommendationsData?.subscriptionId}
                        tenantName={subStoppedVMRecommendationsData?.tenantName}
                        lastUpdated={subStoppedVMRecommendationsData?.dateCreated}
                        currency={subStoppedVMRecommendationsData?.currency}
                        type={"stopped"}
                        loading={subStoppedVMRecommendationsLoading}
                        someref={{how: setDashboardComponentsRef, what:'stoppedSub', where: dashboardComponentsRef}}
                    />
                ) :
                tenantId ? (
                        <AzureStoppedVmRecommendation
                            data={azureTenantStoppedVMRecommendation}
                            title={"Stopped VM Recommendations"}
                            view={"tenant"}
                            cards={tenentStoppedSumCard}
                            customerId={customerId}
                            tenantName={azureTenantStoppedVMRecommendation?.tenantName}
                            lastUpdated={azureTenantStoppedVMRecommendation?.dateCreated}
                            currency={azureTenantStoppedVMRecommendation?.currency}
                            loading={azureAllStoppedVMRecommendationLoading}
                            someref={{how: setDashboardComponentsRef, what:'stoppedTenant', where: dashboardComponentsRef}}
                        />
                    ):
                    <AzureStoppedVmRecommendation
                        data={azureAllStoppedVMRecommendation}
                        title={"Stopped VM Recommendations"}
                        view={"all"}
                        cards={stoppedSumCard}
                        customerId={customerId}
                        tenantName={azureAllStoppedVMRecommendation?.tenantName}
                        lastUpdated={azureAllStoppedVMRecommendation?.dateCreated}
                        currency={azureAllStoppedVMRecommendation?.currency}
                        loading={azureAllStoppedVMRecommendationLoading}
                        someref={{how: setDashboardComponentsRef, what:'stoppedAll', where: dashboardComponentsRef}}
                    />
            }
            {/*<AzureRecommendationCard currency={currency} classes={classes} someref={{how: setDashboardComponentsRef, what:'recomm6', where: dashboardComponentsRef}} collapseRef={collapseRef} title={'Stopped VM Recommendations'} icon={vm_icon} />*/}
            <br/>
            <AzureRecommendationCard lastUpdated={idleRecommendations?.dateCreated} loading={idleRecommendationsLoading} currency={currency} classes={classes} someref={{how: setDashboardComponentsRef, what:'recomm5', where: dashboardComponentsRef}} collapseRef={collapseRef} title={'Idle VM Recommendations'} icon={vm_icon} data={idleRecommendations?.data} />
            <br/>
            <RightsizingVMRecommendationCard
                lastUpdated={rightsizingRecommendations?.dateCreated}
                loading={rightsizingRecommendationsLoading}
                data={rightsizingRecommendations}
                subscriptionId={rightsizingRecommendations?.subscriptionId}
                subscriptionName={rightsizingRecommendations?.subscriptionName}
                currency={currency} classes={classes} someref={{how: setDashboardComponentsRef, what:'rightsizing', where: dashboardComponentsRef}} collapseRef={collapseRef} title={'Rightsizing VM Recommendations'} icon={vm_icon} />
            <br/>
            <AzureRecommendationCard currency={currency} classes={classes} someref={{how: setDashboardComponentsRef, what:'recomm7', where: dashboardComponentsRef}} collapseRef={collapseRef} title={'Migrate VM Recommendations'} icon={vm_icon} />
            <br/>
            <AzureRecommendationCard currency={currency} classes={classes} someref={{how: setDashboardComponentsRef, what:'recomm2', where: dashboardComponentsRef}} collapseRef={collapseRef} title={'DB Server Recommendations'} icon={database_icon} />
            <br/>
            <AzureRecommendationCard currency={currency} classes={classes} someref={{how: setDashboardComponentsRef, what:'recomm4', where: dashboardComponentsRef}} collapseRef={collapseRef} title={'SQL Managed Instance Recommendations'} icon={Azure_cosmosDB_icon} />
            <br/>
            <AzureRecommendationCard currency={currency} classes={classes} someref={{how: setDashboardComponentsRef, what:'recomm1', where: dashboardComponentsRef}} collapseRef={collapseRef} title={'Cosmos DB Recommendations'} icon={Azure_cosmosDB_icon} />
            <br/>
            <AzureRecommendationCard currency={currency} classes={classes} someref={{how: setDashboardComponentsRef, what:'recomm3', where: dashboardComponentsRef}} collapseRef={collapseRef} title={'Function App Recommendations'} icon={function_apps_icon} />
            <br/>
            <AzureRecommendationCard currency={currency} classes={classes} someref={{how: setDashboardComponentsRef, what:'recomm9', where: dashboardComponentsRef}} collapseRef={collapseRef} title={'Storage Recommendations'} icon={azure_storage} />
            <br/>
            <div>
                <ContainerCardWithDateRange someref={{how: setDashboardComponentsRef, what:'recomm10', where: dashboardComponentsRef}} myref={collapseRef} title={"High/Medium/Low impact Recommendations"} titleIcon={AzureLogo} showDateRange={false} collapsible={true} datePeriodEnabled={false}>
                    {() => (
                        <>
                            {allResources ? recommendations && azureAllRecommendation && <TotalRecommend data={recommendations} allRec={null} /> : recommendations && <TotalRecommend data={recommendations} allRec={null} />}
                            {recommendations && recommendations != null ? (
                                <Recommendationtypes detail={recommendations} />
                            ) : (
                                <div style={{ textAlign: "center" }}>
                                    <span>No data found</span>
                                </div>
                            )}
                        </>
                    )}
                </ContainerCardWithDateRange>
            </div>
            <br />
        </>
    );
}

async function filterResources(category, data, impact) {
    let filteredData = [];
    let unique = {};
    var result = data.filter(function (v, i) {
        return v.properties["category"] === category && v.properties.impact === impact;
    });

    for (let i in result) {
        let objValue = result[i].properties["impactedValue"];
        unique[objValue] = result[i].properties;
    }

    for (let i in unique) {
        filteredData.push(unique[i]);
    }

    return filteredData;
}

export async function filterByTypeID(result) {
    let array = [];
    let uniqueArray = {};
    for (let i in result) {
        let objValue = result[i]["impactedValue"];
        uniqueArray[objValue] = result[i];
    }

    for (let i in uniqueArray) {
        array.push(uniqueArray[i]);
    }
    return array;
}

export async function resourcesExtraction(values) {
    let newArray = [];
    let CostIR = [];
    let SecurityIR = [];
    let ExcellenceIR = [];
    let AvailabilityIR = [];
    let PerformanceIR = [];

    let uniqueObject = {};

    let costHigh = await filterResources("Cost", values, "High");
    let costMedium = await filterResources("Cost", values, "Medium");
    let costLow = await filterResources("Cost", values, "Low");

    let securityHigh = await filterResources("Security", values, "High");
    let securityMedium = await filterResources("Security", values, "Medium");
    let securityLow = await filterResources("Security", values, "Low");

    let excellenceHigh = await filterResources("OperationalExcellence", values, "High");
    let excellenceMedium = await filterResources("OperationalExcellence", values, "Medium");
    let excellenceLow = await filterResources("OperationalExcellence", values, "Low");

    let availabilityHigh = await filterResources("HighAvailability", values, "High");
    let availabilityMedium = await filterResources("HighAvailability", values, "Medium");
    let availabilityLow = await filterResources("HighAvailability", values, "Low");

    //------------------------------
    let performanceHigh = await filterResources("Performance", values, "High");
    let performanceMedium = await filterResources("Performance", values, "Medium");
    let performanceLow = await filterResources("Performance", values, "Low");

    //------------------------------
    costHigh.map((i) => CostIR.push(i));
    costMedium.map((i) => CostIR.push(i));
    costLow.map((i) => CostIR.push(i));

    securityHigh.map((i) => SecurityIR.push(i));
    securityMedium.map((i) => SecurityIR.push(i));
    securityLow.map((i) => SecurityIR.push(i));

    availabilityHigh.map((i) => AvailabilityIR.push(i));
    availabilityMedium.map((i) => AvailabilityIR.push(i));
    availabilityLow.map((i) => AvailabilityIR.push(i));

    excellenceHigh.map((i) => ExcellenceIR.push(i));
    excellenceMedium.map((i) => ExcellenceIR.push(i));
    excellenceLow.map((i) => ExcellenceIR.push(i));

    performanceHigh.map((i) => PerformanceIR.push(i));
    performanceMedium.map((i) => PerformanceIR.push(i));
    performanceLow.map((i) => PerformanceIR.push(i));

    for (let i in values) {
        let objTitle = values[i].properties["recommendationTypeId"];
        uniqueObject[objTitle] = values[i].properties;
    }

    for (let i in uniqueObject) {
        newArray.push(uniqueObject[i]);
    }

    var costIR = await filterByTypeID(CostIR);
    var securityIR = await filterByTypeID(SecurityIR);
    var availabilityIR = await filterByTypeID(AvailabilityIR);
    var performanceIR = await filterByTypeID(PerformanceIR);
    var excellenceIR = await filterByTypeID(ExcellenceIR);

    //console.log(" Security ADDD == ", JSON.stringify(securityIR));

    //----------------------------------------------------------------
    const recommendationsVal = {
        costVal: costIR.length,
        securityVal: securityIR.length,
        availabilityVal: availabilityIR.length,
        performanceVal: performanceIR.length,
        excellenceVal: excellenceIR.length,
    };
    const totalIR = costIR.length + securityIR.length + availabilityIR.length + performanceIR.length + excellenceIR.length;

    //----------------------------------------------------------------
    return {
        newArray,
        CostIR,
        AvailabilityIR,
        SecurityIR,
        PerformanceIR,
        ExcellenceIR,
        recommendationsVal,
        totalIR,
    };
}

function AzureRecommendationCard({someref, collapseRef, title, data = [], loading, lastUpdated, icon, currency}){
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [list, setList] = useState(null);
    const [search, setSearch] = useState(null);
    const [cards, setCards] = useState(null);
    const [order, setOrder] = useState(TABLE_SORT.ORDER_DESC);
    const [orderBy, setOrderBy] = useState('cost');

    const {classes} = useStyles();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(()=>{
        setCards(null);
        if(data?.length){
            const sumCards = [];
            sumCards.push({ name: data?.length>1 ? "Idle VM instances":"Idle VM instance", value: data?.length });
            sumCards.push({
                name: "Potential Savings /month",
                value: currency +
                    Format.formatNumber(
                        data?.map(d=>parseFloat(d?.recommendations?.monthlySavings))?.reduce((a,b)=>a+b, 0)
                    )
            });

            setCards(sumCards);
        }
    }, [data]);

    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.name,
                f?.location,
                f?.vmSize,
                f?.os,
            ], search);
        }
        return true;
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === TABLE_SORT.ORDER_ASC;
        setOrder(isAsc ? TABLE_SORT.ORDER_DESC : TABLE_SORT.ORDER_ASC);
        setOrderBy(property);
    };

    return (
        <div>
            <ContainerCardWithDateRange
                someref={someref}
                myref={collapseRef}
                title={title}
                titleIcon={AzureLogo}
                showDateRange={false}
                collapsible={true}
                HeaderTitleComponent={()=>(
                    <div>
                        <img src={icon} height={25} style={{marginLeft: 8, marginRight: 8}} />
                        <span>
                            {title}
                        </span>
                    </div>
                )}
                CenterComponent={()=>(
                    <div>
                        {cards != null && cards ?<h6 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20}}>{cards?.[1]?.value} {cards?.[1]?.name} </h6>:null}
                    </div>
                )}
                ExtraHeaderComponent={({ period }) =>
                    data != null && data?.length ? (
                        <div style={{ marginTop: 4 }}>
                            <span style={{fontSize: 14}}>Last updated {moment(lastUpdated).fromNow(true)} ago</span>
                        </div>
                    ) : null
                }
            >
                {({ period }) => (
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        {!loading ?
                            <div style={{flex:1}}>
                                {data && data?.length ? (
                                    <>
                                        {cards && <RecommendationsSummaryCards summary={cards} />}
                                        <TableSearch onSearch={setSearch} />
                                        <TableContainer style={{maxHeight:450}}>
                                            <Table className={classes.table} stickyHeader aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                                <TableHead className={classes.root}>
                                                <TableSortHeader
                                                    classes={classes}
                                                    numSelected={1
                                                        // ||
                                                        //selected.length
                                                    }
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={handleRequestSort}
                                                    rowCount={data?.filter(filteredTable)?.length}
                                                    headCells={[
                                                        { numeric:0, id:'id', label: 'VM Name'},
                                                        { numeric:1, id:'cost', label: 'Potential Savings'},
                                                        { numeric:0, id:'os', label: 'OS'},
                                                        { numeric:0, id:'type', label: 'VM Type'},
                                                        { numeric:1, id:'cpu', label: 'vCPU'},
                                                        { numeric:1, id:'memory', label: 'Memory'},
                                                        { numeric:1, id:'disk', label: 'Disk Attached'},
                                                        { numeric:0, id:'location', label: 'Location'},
                                                        // { enabled: false, numeric:0, id:'action', label: 'Action'},
                                                        // { enabled: !(showingDetailsStorage || showingDetailsS3), numeric:1, id:'cost', label: 'Cost'},
                                                    ]}
                                                />
                                                    <TableCell align={"center"} className={classes.sortCell}>Action</TableCell>
                                                </TableHead>
                                            <TableBody>
                                            {data
                                                ?.filter(filteredTable)
                                                .sort((r1, r2) => {
                                                    const fi =  ({
                                                        cost: (parseFloat(r1.recommendations?.monthlySavings) - parseFloat(r2.recommendations?.monthlySavings)),
                                                        cpu: (r1.cpu - r2.cpu),
                                                        memory:(r1.memory - r2.memory),
                                                        disk:(r1.diskAttached - r2.diskAttached),
                                                        id: ALPHA_NUM_SORTER.compare(r1.name, r2.name),
                                                        location: ALPHA_NUM_SORTER.compare(r1.location, r2.location),
                                                        os: ALPHA_NUM_SORTER.compare(r1.os, r2.os),
                                                        type: ALPHA_NUM_SORTER.compare(r1.vmSize, r2.vmSize),
                                                    }[orderBy]);
                                                    return fi * order;
                                                })
                                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                ?.map(d=>(
                                                <TableRow>
                                                    <TableCell>{d?.name}</TableCell>
                                                    <TableCell align={"right"}>{Format.formatCurrencySymbol(currency)}{currency}{Format.formatNumber(d?.recommendations?.monthlySavings)}</TableCell>
                                                    <TableCell>{d?.os}</TableCell>
                                                    <TableCell>{d?.vmSize}</TableCell>
                                                    <TableCell align={"right"}>{d?.cpu}</TableCell>
                                                    <TableCell align={"right"}>{d?.memory} {d?.memoryUnit}</TableCell>
                                                    <TableCell align={"right"}>{d?.diskAttached} {parseFloat(d?.diskAttached)>1?" disks":" disk"}</TableCell>
                                                    <TableCell>{d?.location}</TableCell>
                                                    <TableCell align={"right"}>
                                                        <Button
                                                            onClick={()=>{}}
                                                            variant="contained" style={{ fontSize: 11, borderRadius: 15, backgroundColor: "#3f50b5", color: "#FFF", textTransform: "initial", whiteSpace: "nowrap" }}>
                                                            Fix in Azure
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        ActionsComponent={TablePaginationActions}
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={data?.filter(filteredTable).length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                    </>
                                ) : (
                                    <h6 style={{ textAlign: "center" }}>No data found</h6>
                                )}
                            </div>
                            :
                            <CircularProgress thickness={2} size={60} />
                        }
                    </div>
                )}
            </ContainerCardWithDateRange>
        </div>
    );

}

function RightsizingVMRecommendationCard({someref, collapseRef, title, data = [], loading, lastUpdated, icon, currency, subscriptionId, subscriptionName}){
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [list, setList] = useState(null);
    const [search, setSearch] = useState(null);
    const [cards, setCards] = useState(null);
    const [order, setOrder] = useState(TABLE_SORT.ORDER_DESC);
    const [orderBy, setOrderBy] = useState('cost');

    const {classes} = useStyles();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(()=>{
        setCards(null);
        if(data?.resizingRecommendations?.length){
            const sumCards = [];
            sumCards.push({ name: data?.resizingRecommendations?.length>1 ? "Virtual Machines":"Virtual Machine", value: data?.resizingRecommendations?.length });
            sumCards.push({
                name: "Potential Savings /month",
                value: Format.formatCurrencySymbol(data?.currency) + Format.formatNumber(data?.totalPotentialSavings)
            });

            setCards(sumCards);
        }
    }, [data]);

    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.vmname,
                f?.vmId,
                f?.location,
                f?.currentVmSize,
                f?.recommendedVmSize,
                f?.os,
            ], search);
        }
        return true;
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === TABLE_SORT.ORDER_ASC;
        setOrder(isAsc ? TABLE_SORT.ORDER_DESC : TABLE_SORT.ORDER_ASC);
        setOrderBy(property);
    };


    return (
        <div>
            <ContainerCardWithDateRange myref={collapseRef}
                                        title={title}
                                        titleIcon={AzureLogo}
                                        showDateRange={false}
                                        collapsible={true}
                                        HeaderTitleComponent={()=>(
                                            <div>
                                                <img src={vm_icon} height={25} style={{marginLeft: 8, marginRight: 8}} />
                                                <span>
                            {title}
                        </span>
                                            </div>
                                        )}
                                        ExtraHeaderComponent={({ period }) =>
                                            data != null && Object.keys(data)?.length ? (
                                                <div style={{ marginTop: 4 }}>
                                                    <span style={{ fontSize: 14 }}>Last updated {moment(lastUpdated).fromNow(true)} ago</span>
                                                </div>
                                            ) : null
                                        }
                                        CenterComponent={()=>(
                                            <div>
                                                {cards != null && cards ?<h6 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20}}>{cards?.[1]?.value} {cards?.[1]?.name} </h6>:null}
                                            </div>
                                        )}
            >
                {({ period }) => (
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        {!loading ?(
                            <div style={{flex:1}}>
                                {cards && <RecommendationsSummaryCards summary={cards} />}
                                <div className={classes.root}>
                                    {data != null && data?.resizingRecommendations?.length ? (
                                        <Paper className={classes.paper}>
                                            <div style={{display:'flex', alignItems:'center',justifyContent:'flex-start'}}>
                                            <TableSearch onSearch={setSearch} filterWidth={true}/>
                                                <div style={{display:'flex',marginLeft:'14%'}}>
                                                    <b>Metrics:</b>

                                                    <td style={{marginLeft:4}}>
                                                        <span className={classes.metricsStyle}> {" Max. CPU Utilization > 5% and <41% " || null}</span>{" "}Last 30 days
                                                    </td>
                                                </div>
                                            </div>
                                            <TableContainer style={{maxHeight:450}}>
                                                <Table className={classes.table} stickyHeader aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                                    <TableHead className={classes.root}>
                                                        <TableRow>
                                                            <TableCell className={classes.tableRow}>VM Name</TableCell>
                                                            <TableCell className={classes.tableRow}>Potential Savings</TableCell>
                                                            <TableCell className={classes.tableRow}>Curr. Instance Type</TableCell>
                                                            <TableCell className={classes.tableRow}>Recomm. Instance Type</TableCell>
                                                            <TableCell className={classes.tableRow}>Curr. CPU Util.</TableCell>
                                                            <TableCell className={classes.tableRow}>Projected CPU Util.</TableCell>
                                                            <TableCell className={classes.tableRow}>Location</TableCell>
                                                            <TableCell className={classes.tableRow}>Subscription</TableCell>

                                                            <TableCell align={"center"} className={classes.tableRow}>
                                                                {" "}
                                                                Action
                                                                {/*<div>*/}
                                                                {/*    <OverlayTrigger placement="left"*/}
                                                                {/*                    overlay={<Tooltip id="button-tooltip">How to terminate Prev.Gen. EC2 instance?</Tooltip>}>*/}
                                                                {/*        <Badge badgeContent={0} color="secondary">*/}
                                                                {/*            <Help*/}
                                                                {/*                onClick={()=>window.open(AWS_TERMINATE_INSTANCE_HELP, "_blank")}*/}
                                                                {/*                style={{cursor: "pointer", fontSize:20, color: "#303" }} />*/}
                                                                {/*        </Badge>*/}
                                                                {/*    </OverlayTrigger>*/}
                                                                {/*</div>*/}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data?.resizingRecommendations
                                                            ?.filter(filteredTable)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            ?.sort((a,b)=>(b?.potentialSavings || 0)-(a?.potentialSavings || 0))
                                                            ?.map((row, index) => {
                                                                return <TableRow>
                                                                    <TableCell style={{whiteSpace: "nowrap"}}>
                                                                        {row?.vmname}
                                                                        <OverlayTrigger placement="bottom"
                                                                                        overlay={
                                                                                            <Tooltip id="button-tooltip">
                                                                                                <span style={{}}>
                                                                                                    VM ID: {row?.vmId}<br/>
                                                                                                    OS: {row?.os}
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                        }
                                                                            // <Tooltip id="button-tooltip">{row?.instanceType}</Tooltip>

                                                                        >
                                                                            <Info aria-label={'disabled'} style={{cursor: "pointer", fontSize: 15, color: "#adadad" }} />
                                                                        </OverlayTrigger>
                                                                    </TableCell>
                                                                    <TableCell align={"right"} style={{whiteSpace: "nowrap"}}>
                                                                        {(currency) + Format.formatNumber(row?.potentialSavings)} /mon
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row?.currentVmSize}<br/>
                                                                        <span style={{whiteSpace: "nowrap", fontSize: 11, color: "#7f7f7f"}}>
                                                                            vCPU: {row?.currentNumberOfCPUCores} Memory: {row?.currentMemoryInGB} GB
                                                                        </span>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row?.recommendedVmSize}<br/>
                                                                        <span style={{fontSize: 11, color: "#7f7f7f"}}>
                                                                            vCPU: {row?.recommendedNumberOfCPUCores} Memory: {row?.recommendedMemoryInGB} GB
                                                                        </span>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {Format.formatNumber(row?.currentAvgCPUInPercent)} %
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {Format.formatNumber(row?.projectedAvgCPUInPercent)} %
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row?.location}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {(subscriptionName?.length > 20)?
                                                                            subscriptionName?.substring(0,20) + "...":
                                                                            subscriptionName}
                                                                        <OverlayTrigger placement="bottom"
                                                                                        overlay={
                                                                                            <Tooltip id="button-tooltip">
                                                                                                <span style={{}}>
                                                                                                    {(subscriptionName?.length > 20)? subscriptionName: null}<br/>
                                                                                                    {subscriptionId}
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                        }
                                                                            // <Tooltip id="button-tooltip">{row?.instanceType}</Tooltip>

                                                                        >
                                                                            <Info aria-label={'disabled'} style={{cursor: "pointer", fontSize: 15, color: "#adadad" }} />
                                                                        </OverlayTrigger>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Button
                                                                            onClick={()=>0}
                                                                            variant="contained" style={{ fontSize: 11,
                                                                            borderRadius: 15,
                                                                            backgroundColor: "#3f50b5",
                                                                            color: "#FFF", textTransform: "initial", whiteSpace: "nowrap" }}>
                                                                            Fix in Azure
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                ActionsComponent={TablePaginationActions}
                                                rowsPerPageOptions={[10, 25, 50, 75]}
                                                component="div"
                                                count={data?.resizingRecommendations?.filter(filteredTable)?.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />
                                        </Paper>
                                    ) : (
                                        <h6 style={{ textAlign: "center" }}>No data found</h6>
                                    )}
                                </div>
                            </div>
                        ):<CircularProgress thickness={2} size={60} />
                        }
                    </div>
                )}
            </ContainerCardWithDateRange>
        </div>
    );

}
