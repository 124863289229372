import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getAzureStorageSuccess, getAzureStorageFailure,getAzureStorageAccountSuccess,getAzureStorageAccountFailure,
    getAzureStorageTenantAccountSuccess,getAzureStorageTenantAccountFailure } from "../actions/actions";
const { REACT_APP_AZURE_STORAGE_ACCOUNTS, REACT_APP_AZURE_STORAGE,REACT_APP_AZURE_STORAGE_TENANT_ACCOUNTS } = process.env;

function* getAzureStorageSagaData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AZURE_STORAGE, {
            params: {
                customerId: payload.customerId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureStorageFailure(statusCode));
            } else {
                yield put(getAzureStorageSuccess(res.data));
            }
        } else {
            yield put(getAzureStorageFailure(res));
        }
    } catch (error) {
        yield put(getAzureStorageFailure(error));
    }
}

function* getAzureStorageSubSagaData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AZURE_STORAGE_ACCOUNTS, {
            params: {
                customerId: payload.customerId,
                tenantId: payload.tenantId,
                subscriptionId: payload.subscriptionId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureStorageAccountFailure(statusCode));
            } else {
                yield put(getAzureStorageAccountSuccess(res.data));
            }
        } else {
            yield put(getAzureStorageAccountFailure(res));
        }
    } catch (error) {
        yield put(getAzureStorageAccountFailure(error));
    }
}
function* getAzureStorageTenantSagaData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AZURE_STORAGE_TENANT_ACCOUNTS, {
            params: {
                customerId: payload.customerId,
                tenantId: payload.tenantId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureStorageTenantAccountFailure(statusCode));
            } else {
                yield put(getAzureStorageTenantAccountSuccess(res.data));
            }
        } else {
            yield put(getAzureStorageTenantAccountFailure(res));
        }
    } catch (error) {
        yield put(getAzureStorageTenantAccountFailure(error));
    }
}

export default function* azureStorageSaga() {
    yield takeLatest(types.AZURE_STORAGE_REQUEST, getAzureStorageSagaData);
    yield takeLatest(types.AZURE_STORAGE_TENANT_REQUEST, getAzureStorageTenantSagaData);
    yield takeLatest(types.AZURE_STORAGE_ACCOUNT_REQUEST, getAzureStorageSubSagaData);
}
