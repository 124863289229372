import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import Color from "../../../styles/color";

export default function AzureBillingExport() {
  const [connectionLoading, setConnectionLoading] = useState(false);

  const [tenantId, setTenantId] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [clientId, setClientId] = useState("");
  const [cliendSecret, setCliendSecret] = useState("");
  const [storageAccountName, setStorageAccountName] = useState("");
  const [containerName, setContainerName] = useState("");
  const [directoryName, setDirectoryName] = useState("");
  const [costExportName, setCostExportName] = useState("");
  const [connectionDisplayName, setConnectionDisplayName] = useState("");

  const [tenantIdError, setTenantIdError] = useState({
    status: false,
    msg: "",
  });
  const [subscriptionIdError, setSubscriptionIdError] = useState({
    status: false,
    msg: "",
  });
  const [clientIdError, setClientIdError] = useState({
    status: false,
    msg: "",
  });
  const [cliendSecretError, setCliendSecretError] = useState({
    status: false,
    msg: "",
  });
  const [storageAccountNameError, setStorageAccountNameError] = useState({
    status: false,
    msg: "",
  });
  const [containerNameError, setContainerNameError] = useState({
    status: false,
    msg: "",
  });
  const [directoryNameError, setDirectoryNameError] = useState({
    status: false,
    msg: "",
  });
  const [costExportNameError, setCostExportNameError] = useState({
    status: false,
    msg: "",
  });
  const [connectionDisplayNameError, setConnectionDisplayNameError] = useState({
    status: false,
    msg: "",
  });

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ width: "50%" }}>
        <Form
          style={{ flex: 1, margin: 16 }}
          onSubmit={(e) => e.preventDefault()}
        >
          <TextField
            value={tenantId}
            label={"Tenant Id *"}
            // onBlur={() => onBlurHandle("TenantId")}
            hasError={tenantIdError.status}
            errorMessage={tenantIdError.msg}
            onInput={(v) => {
              setTenantId(v);
              setTenantIdError({ status: false, msg: "" });
            }}
            placeholder={"Enter Tenant Id"}
          />
          <TextField
            value={subscriptionId}
            label={"Subscription Id *"}
            // onBlur={() => onBlurHandle("TenantId")}
            hasError={subscriptionIdError.status}
            errorMessage={subscriptionIdError.msg}
            onInput={(v) => {
              setSubscriptionId(v);
              setSubscriptionIdError({ status: false, msg: "" });
            }}
            placeholder={"Enter Subscription Id"}
          />
          <TextField
            value={clientId}
            label={"Client Id *"}
            // onBlur={() => onBlurHandle("TenantId")}
            hasError={clientIdError.status}
            errorMessage={clientIdError.msg}
            onInput={(v) => {
              setClientId(v);
              setClientIdError({ status: false, msg: "" });
            }}
            placeholder={"Enter Client Id"}
          />
          <TextField
            value={cliendSecret}
            label={"CliendS Secret *"}
            // onBlur={() => onBlurHandle("TenantId")}
            hasError={cliendSecretError.status}
            errorMessage={cliendSecretError.msg}
            onInput={(v) => {
              setCliendSecret(v);
              setCliendSecretError({ status: false, msg: "" });
            }}
            placeholder={"Enter cliend Secret"}
          />
          <TextField
            value={storageAccountName}
            label={"Storage Account Name *"}
            // onBlur={() => onBlurHandle("TenantId")}
            hasError={storageAccountNameError.status}
            errorMessage={storageAccountNameError.msg}
            onInput={(v) => {
              setStorageAccountName(v);
              setStorageAccountNameError({ status: false, msg: "" });
            }}
            placeholder={"Enter storage Account Name"}
          />

          <TextField
            value={containerName}
            label={"Container Name *"}
            // onBlur={() => onBlurHandle("TenantId")}
            hasError={containerNameError.status}
            errorMessage={containerNameError.msg}
            onInput={(v) => {
              setContainerName(v);
              setContainerNameError({ status: false, msg: "" });
            }}
            placeholder={"Enter Container Name"}
          />

          <TextField
            value={directoryName}
            label={"Directory Name *"}
            // onBlur={() => onBlurHandle("TenantId")}
            hasError={directoryNameError.status}
            errorMessage={directoryNameError.msg}
            onInput={(v) => {
              setDirectoryName(v);
              setDirectoryNameError({ status: false, msg: "" });
            }}
            placeholder={"Enter Directory Name"}
          />

          <TextField
            value={costExportName}
            label={"Cost Export Name *"}
            // onBlur={() => onBlurHandle("TenantId")}
            hasError={costExportNameError.status}
            errorMessage={costExportNameError.msg}
            onInput={(v) => {
              setCostExportName(v);
              setCostExportNameError({ status: false, msg: "" });
            }}
            placeholder={"Enter Cost Export Name"}
          />
          <TextField
            value={connectionDisplayName}
            label={"Connection Display Name *"}
            // onBlur={() => onBlurHandle("TenantId")}
            hasError={connectionDisplayNameError.status}
            errorMessage={connectionDisplayNameError.msg}
            onInput={(v) => {
              setConnectionDisplayName(v);
              setConnectionDisplayNameError({ status: false, msg: "" });
            }}
            placeholder={"Enter Connection Display Name"}
          />

          <br />
          <Button onClick={onConnect} variant={"primary"} type="submit">
            Connect Azure Billing Export
          </Button>
        </Form>
      </div>
      <div style={{ width: "50%" }}></div>
    </div>
  );

  function validateFields() {
    var noErrors = true;
    if (!tenantId.trim()) {
      setTenantIdError({
        status: true,
        msg: "Please enter the Tenant Id",
      });
      noErrors = false;
    } else if (!/^[a-z0-9.\-_]{1,255}$/.test(tenantId.trim())) {
      setTenantIdError({
        status: true,
        msg: ".",
      });
      noErrors = false;
    }

    if (!subscriptionId.trim()) {
      setSubscriptionIdError({
        status: true,
        msg: "Please enter the Subscription Id",
      });
      noErrors = false;
    } else if (!/^[a-zA-Z0-9.*!'()\-_]{1,255}$/.test(subscriptionId.trim())) {
      setSubscriptionIdError({
        status: true,
        msg: ".",
      });
      noErrors = false;
    }
    if (!clientId.trim()) {
      setClientIdError({
        status: true,
        msg: "Please enter the Client Id",
      });
      noErrors = false;
    } else if (!/^[a-zA-Z0-9.*!'()\-_/]{1,255}$/.test(clientId.trim())) {
      setClientIdError({
        status: true,
        msg: ".",
      });
      noErrors = false;
    }

    if (!cliendSecret.trim()) {
      setCliendSecretError({
        status: true,
        msg: "Please enter Cliend Secret",
      });
      noErrors = false;
    } else if (!/^[a-zA-Z0-9.\-_\s]{3,50}$/.test(cliendSecret.trim())) {
      setCliendSecretError({
        status: true,
        msg: ".",
      });
      noErrors = false;
    }

    if (!storageAccountName.trim()) {
      setStorageAccountNameError({
        status: true,
        msg: "Please enter Storage Account Name",
      });
      noErrors = false;
      // setActiveStep(1);
    } else if (
      !/^arn:aws:iam::\d{12}:role\/[a-zA-Z0-9-_\/]+$/.test(
        storageAccountName.trim()
      )
    ) {
      setStorageAccountNameError({
        status: true,
        msg: ".",
      });
      noErrors = false;
    }

    if (!containerName.trim()) {
      setContainerNameError({
        status: true,
        msg: "Please enter Container Name",
      });
      noErrors = false;
      // setActiveStep(1);
    } else if (
      !/^arn:aws:iam::\d{12}:role\/[a-zA-Z0-9-_\/]+$/.test(containerName.trim())
    ) {
      setContainerNameError({
        status: true,
        msg: ".",
      });
      noErrors = false;
    }

    if (!directoryName.trim()) {
      setDirectoryNameError({
        status: true,
        msg: "Please enter Directory Name",
      });
      noErrors = false;
      // setActiveStep(1);
    } else if (
      !/^arn:aws:iam::\d{12}:role\/[a-zA-Z0-9-_\/]+$/.test(directoryName.trim())
    ) {
      setDirectoryNameError({
        status: true,
        msg: ".",
      });
      noErrors = false;
    }

    if (!costExportName.trim()) {
      setCostExportNameError({
        status: true,
        msg: "Please enter Cost Export Name",
      });
      noErrors = false;
      // setActiveStep(1);
    } else if (
      !/^arn:aws:iam::\d{12}:role\/[a-zA-Z0-9-_\/]+$/.test(
        costExportName.trim()
      )
    ) {
      setCostExportNameError({
        status: true,
        msg: ".",
      });
      noErrors = false;
    }

    if (!connectionDisplayName.trim()) {
      setConnectionDisplayNameError({
        status: true,
        msg: "Please enter Connection Display Name",
      });
      noErrors = false;
      // setActiveStep(1);
    } else if (
      !/^arn:aws:iam::\d{12}:role\/[a-zA-Z0-9-_\/]+$/.test(
        connectionDisplayName.trim()
      )
    ) {
      setConnectionDisplayNameError({
        status: true,
        msg: ".",
      });
      noErrors = false;
    }
    return noErrors;
  }

  async function onConnect(e) {
    e.preventDefault();
    if (validateFields()) {
      //   setConnectionLoading(true);
      //   await axios
      //     .post(REACT_APP_AWS_CONNECTION, {
      //       costUsageReports: {
      //         s3bucketCostreportName: s3bucketCostreportName.trim(),
      //         s3bucketName: s3BucketName.trim(),
      //         s3bucketCostreportPathPrefix: reportPathPrefix.trim(),
      //       },
      //       customerId,
      //       displayName: displayName.trim(),
      //       roleArn: roleArn.trim(),
      //     })
      //     .then((response) => response.data)
      //     .then((response) => {
      //       if ("statusCode" in response) {
      //         setalertOpen(true);
      //         switch (response.statusCode.toString()) {
      //           case "200":
      //             {
      //               // alert(response.successMessage);
      //               setAlertTitle("success");
      //               setAlertMsg(response.successMessage);
      //               // setTimeout(function () {
      //               //   onConnectionSuccess();
      //               // }, 2000);
      //             }
      //             break;
      //           case "3001":
      //             {
      //               setAlertTitle("error");
      //               setAlertMsg("Null CustomerID");
      //               // alert("Null CustomerID");
      //             }
      //             break;
      //           case "3005":
      //             {
      //               setAlertTitle("warning");
      //               setAlertMsg("Account already exists");
      //               // alert("Account already exists");
      //             }
      //             break;
      //           case "3048":
      //             {
      //               setAlertTitle("error");
      //               setAlertMsg("Role arn is invalid");
      //               // alert("Role arn is invalid");
      //               // setTimeout(function () {
      //               //   onConnectionSuccess();
      //               // }, 2000);
      //             }
      //             break;
      //           case "3049":
      //             {
      //               setAlertTitle("error");
      //               setAlertMsg("Role arn is invalid");
      //               // alert("Role arn is invalid");
      //               // setTimeout(function () {
      //               //   onConnectionSuccess();
      //               // }, 2000);
      //             }
      //             break;
      //           case "3050":
      //             {
      //               setAlertTitle("error");
      //               setAlertMsg("s3bucketName is invalid");
      //               // alert("s3bucketName is invalid");
      //               // setTimeout(function () {
      //               //   onConnectionSuccess();
      //               // }, 2000);
      //             }
      //             break;
      //           case "3051":
      //             {
      //               setAlertTitle("error");
      //               setAlertMsg(
      //                 "S3bucket cost and usage report prefix name or report name is invalid"
      //               );
      //               // alert(
      //               //   "S3bucket cost and usage report prefix name or report name is invalid"
      //               // );
      //               // setTimeout(function () {
      //               //   onConnectionSuccess();
      //               // }, 2000);
      //             }
      //             break;
      //           case "3045":
      //             {
      //               setAlertTitle("error");
      //               setAlertMsg("S3bucket Costreport Name is invalid");
      //               // alert("S3bucket Costreport Name is invalid");
      //               // setTimeout(function () {
      //               //   onConnectionSuccess();
      //               // }, 2000);
      //             }
      //             break;
      //           default: {
      //             setAlertTitle("error");
      //             setAlertMsg(
      //               "Please contact your administrator or our support team, support@cloudtrakr.com"
      //             );
      //             // alert(
      //             //   "Please contact your administrator or our support team, support@cloudtrakr.com"
      //             // );
      //           }
      //         }
      //       } else {
      //         setAlertTitle("error");
      //         setAlertMsg(
      //           "Please contact your administrator or our support team, support@cloudtrakr.com"
      //         );
      //       }
      //     })
      //     .catch((e) => {
      //       // console.log(e)
      //     })
      //     .finally(() => setConnectionLoading(false));
    }
    e.preventDefault();
  }
}

function TextField({
  onBlur,
  value,
  hasError,
  label,
  placeholder,
  onInput,
  errorMessage,
  disabled = false,
}) {
  return (
    <Form.Group controlId="formBasicEmail">
      <Form.Label className={disabled ? "disabled-field" : ""}>
        {label}
      </Form.Label>
      <Form.Control
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(event) => onInput(event.target.value)}
      />
      {hasError ? (
        <Form.Text style={{ color: "red" }}>{errorMessage}</Form.Text>
      ) : null}
    </Form.Group>
  );
}
