import { makeStyles, withStyles } from "MakeWithStyles";
import colors from "../styles/color";

import ApiManagement_logo from "../images/api-management-services.png";
import AzureCosmosDB_logo from "../images/azure-cosmos-db.png";
import FunctionApps_logo from "../images/function-apps.png";
import LogAnalytics_logo from "../images/Log-Analytics-Workspaces.svg";
import StorageAccounts_logo from "../images/Storage-Accounts.svg";
import ApplicationInsights_logo from "../images/application-insights.png";
import AppService from "../images/App-Services.svg";
import Function_logo from "../images/function.png";
import big_query from "../images/gcpResources/gcp_bigquery.svg";
import ComputeIcon from "../images/Virtual-Machine.svg";
import DataFactoryIcon from "../images/Data_Factory.svg";
import FrontDoorLogo from "../images/Front-Doors.svg";
import LogicApps from "../images/Logic-Apps.svg";
import AdB2c from "../images/Azure-AD-B2C.svg";
import EventGrid from "../images/Event-Grid-Subscriptions.svg";

import VirtualMachine from "../images/Virtual-Machine.svg";
import SQL from "../images/SQL-Managed-Instance.svg";
import VirtualCluster from "../images/Virtual-Clusters.svg";
import VirtualNetwork from "../images/Virtual-Network-Gateways.svg";
import LoadBalancer from "../images/Load-Balancers.svg";
import ServiceBus from "../images/Service-Bus.svg";
import CacheRedis from "../images/Cache-Redis.svg";
import InfraStructureBackup from "../images/Infrastructure-Backup.svg";
import ServiceValults from "../images/Recovery-Services-Vaults.svg";
import ExpressRouts from "../images/ExpressRoute-Circuits.svg";
import VirtualNetworks from "../images/Virtual-Networks.svg";
import KubernetesServices from "../images/Kubernetes-Services.svg";
import AzureDevOps from "../images/Azure-DevOps.svg";
import PowerUp from "../images/Power-Up.svg";
import KeyVaults from "../images/Key-Vaults.svg";
import SQLDatabase from "../images/SQL-Database.svg";
import DNSZones from "../images/DNS-Zones.svg";
import Monitor from "../images/Monitor.svg";
import AutomationAccounts from "../images/Automation-Accounts.svg";
import TrafficManager from "../images/Traffic-Manager-Profiles.svg";
import ContainerRegistries from "../images/Container-Registries.svg";

//-------------------AWS Service Icon-------------------------------
import AmazonRDS from "../images/awsAccounts/Amazon-RDS.svg";
import AwsELC from "../images/awsAccounts/Amazon-ElastiCache_light-bg.svg";
import AwsEC2 from "../images/awsAccounts/Amazon-EC2.svg";
import AmazonS3 from "../images/awsAccounts/Amazon-Simple-_Storage-Service-S3.svg";
import AmazonRoute53 from "../images/awsAccounts/Amazon-Route-53.svg";
import AmazonCloudFront from "../images/awsAccounts/Amazon-CloudFront_light-bg.svg";
import AmazonApiGateway from "../images/awsAccounts/Amazon-API-Gateway.svg";
import AWSSecretmanager from "../images/awsAccounts/AWS-Secrets-Manager.svg";
import AWSConfig from "../images/awsAccounts/AWS-Config.svg";
import AmazonCloudWatch from "../images/awsAccounts/Amazon-CloudWatch_Alarm_light-bg.svg";
import lambdaFunctionIcon from "../images/lambdaFucntion.png";
import DynamoDBIcon from "../images/DynamoDB.svg";
import AWSCostExplorer from '../images/AWS-Cost-Explorer.svg'
import AWS_SNS from "../images/AWS_SNS.svg";
import AWS_SES from "../images/AWS_SES.svg";
import AWS_ECR from "../images/AWS_ECR.svg";
import AWS_KinesisFirehose from "../images/AWSKinesisFirehose.svg";


export default function IconMapper({ name, height }) {
    const useStyles = makeStyles()((theme) => ({
        img: {
            width: 18,
            height: 18,
            marginRight: 4,
        },
        }));

    const {classes} = useStyles();

    return (
        <div>
            {name == "API Management" && <img className={classes.img} src={ApiManagement_logo} />}
            {name == "Azure App Service" && <img className={classes.img} src={AppService} />}
            {name == "Azure Cosmos DB" && <img className={classes.img} src={AzureCosmosDB_logo} />}
            {name == "Functions" && <img className={classes.img} src={FunctionApps_logo} />}
            {name == "Log Analytics" && <img className={classes.img} src={LogAnalytics_logo} />}
            {name == "Storage" && <img className={classes.img} src={StorageAccounts_logo} />}
            {name == "Bandwidth" && <img className={classes.img} src={ApplicationInsights_logo} />}
            {name == "Big Query" && <img className={classes.img} src={big_query} />}
            {name == "Compute" && <img className={classes.img} src={ComputeIcon} />}
            {name == "Application Insight" && <img className={classes.img} src={ApplicationInsights_logo} />}
            {name == "Azure Data Factory v2" && <img className={classes.img} src={DataFactoryIcon} />}
            {name == "Logic Apps" && <img className={classes.img} src={LogicApps} />}
            {name == "Azure Active Directory B2C" && <img className={classes.img} src={AdB2c} />}
            {name == "Event Grid" && <img className={classes.img} src={EventGrid} />}
            {name == "Azure Front Door Service" && <img className={classes.img} src={FrontDoorLogo} />}
            {name == "Virtual Machines" && <img className={classes.img} src={VirtualMachine} />}
            {name == "SQL Managed Instance" && <img className={classes.img} src={SQL} />}
            {name == "Virtual Machines Licenses" && <img className={classes.img} src={VirtualCluster} />}
            {name == "VPN Gateway" && <img className={classes.img} src={VirtualNetwork} />}
            {name == "Load Balancer" && <img className={classes.img} src={LoadBalancer} />}
            {name == "Service Bus" && <img className={classes.img} src={ServiceBus} />}
            {name == "Backup" && <img className={classes.img} src={InfraStructureBackup} />}
            {name == "Redis Cache" && <img className={classes.img} src={CacheRedis} />}
            {name == "Azure Site Recovery" && <img className={classes.img} src={ServiceValults} />}
            {name == "ExpressRoute" && <img className={classes.img} src={ExpressRouts} />}
            {name == "Virtual Network" && <img className={classes.img} src={VirtualNetworks} />}
            {name == "Azure Kubernetes Service" && <img className={classes.img} src={KubernetesServices} />}
            {name == "Azure DevOps" && <img className={classes.img} src={AzureDevOps} />}
            {name == "Power BI Embedded" && <img className={classes.img} src={PowerUp} />}
            {name == "Key Vault" && <img className={classes.img} src={KeyVaults} />}
            {name == "SQL Database" && <img className={classes.img} src={SQLDatabase} />}
            {name == "Azure DNS" && <img className={classes.img} src={DNSZones} />}
            {name == "Azure Monitor" && <img className={classes.img} src={Monitor} />}
            {name == "Automation" && <img className={classes.img} src={AutomationAccounts} />}
            {name == "Traffic Manager" && <img className={classes.img} src={TrafficManager} />}
            {name == "Container Registry" && <img className={classes.img} src={ContainerRegistries} />}

            {/* ------------------------AWS Service Icon----------------------------------------- */}
            {name == "AmazonRDS" && <img src={AmazonRDS} className={classes.img} />}
            {name == "AWSELB" && <img src={AwsELC} className={classes.img} />}
            {name == "AmazonEC2" && <img src={AwsEC2} className={classes.img} />}
            {name == "AmazonS3" && <img src={AmazonS3}className={classes.img} />}
            {name == "AmazonRoute53" && <img src={AmazonRoute53} className={classes.img} />}
            {name == "AmazonCloudFront" && <img src={AmazonCloudFront} className={classes.img} />}
            {name == "AmazonApiGateway" && <img src={AmazonApiGateway} className={classes.img} />}
            {name == "AWSSecretsManager" && <img src={AWSSecretmanager} className={classes.img} />}
            {name == "AWSConfig" && <img src={AWSConfig} className={classes.img} />}
            {name == "AmazonCloudWatch" && <img src={AmazonCloudWatch} className={classes.img} />}
            {name == "AWSLambda" && <img src={lambdaFunctionIcon} className={classes.img} />}
            {name == "AmazonDynamoDB" && <img src={DynamoDBIcon} className={classes.img} />}
            {name == "AmazonECR" && <img src={AWS_ECR} className={classes.img} />}
            {name == "AmazonKinesisFirehose" && <img src={AWS_KinesisFirehose} className={classes.img} />}
            {name == "AmazonSES" && <img src={AWS_SES} className={classes.img} />}
            {name == "AmazonSNS" && <img src={AWS_SNS} className={classes.img} />}
            {name == "AWSCostExplorer" && <img src={AWSCostExplorer} className={classes.img} />}

        </div>
    );
}
