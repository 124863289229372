import React, { useEffect, useState } from "react";
import "./style.css";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "MakeWithStyles";
import { getAwsAccountSpendingRequest, getAwsSubSpendingRequest } from "../../../redux/actions/actions";
import { LinearProgress, Paper, Collapse, IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import AwsSpendingTable, { ExpandableTableAws } from "components/table/AwsSpendingTable";
import AwsServicesTable from "components/table/AwsServicesTable";
import AwsGroupByServices from "components/table/AwsGroupByService";
import Format from "components/NumberFormat";
import DateRange from "components/DateRange";
import aws_account from "../../../images/newCloudTrakrIcons/AWSAccounts.png";
import resource_group from "../../../images/newCloudTrakrIcons/AWSServices.png";
import StartEndDate from "components/StartEndDate";
import moment from "moment";
import { json } from "d3-fetch";
import ContainerCardWithDateRange from "../../../components/ContainerCardWithDateRange";
import TableMonthlySpend from "../../AllClouds/TableMonthlySpend";
import {getMonthsFromNow} from "../../../util/DateFormatter";
import {isNumeric} from "../../../util/Miscellaneous";
import TableSearch from "../../../components/TableSearch";
import NO_DATA_AVAILABLE from "../../../components/NO_DATA_AVAILABLE";

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        //marginBottom: 5,
    },
}));
export default function AWSServices({ yearTableData, yearTableDataLoading, selectedSubscription, currency, view, customerId }) {
    const dispatch = useDispatch();
    const {classes} = useStyles();
    const [period, setPeriod] = useState("1M");
    const [totalExpand, setTotalExpand] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [data, setData] = useState(null);
    const [parentWidth, setParentWidth] = useState(null);
    const [spendByMonthAccountData, setSpendByMonthAccountData] = useState(null);
    const [ytdSearch, setYTDSearch] = useState(null);

    const { awsServicesSpending, awsSubServicesSpending, loading } = useSelector((state) => ({
        awsServicesSpending: state.awsServerlessReducer.awsServicesSpending,
        awsSubServicesSpending: state.awsServerlessReducer.awsSubServicesSpending,
        loading: state.awsServerlessReducer.loading,
    }));
    useEffect(() => {
        let { title, tenantId, value } = selectedSubscription;
        if (title == "All") {
            let obj1 = { customerId, view: period, dataType: 'services' };
            dispatch(getAwsAccountSpendingRequest(obj1));
            // if (awsServicesSpending == null) {
            //     dispatch(getAwsAccountSpendingRequest(obj1));
            // }
        } else {
            let obj = { customerId, tenantId, value, dataType: 'services', view: period };
            dispatch(getAwsSubSpendingRequest(obj));
        }
    }, [period, customerId, selectedSubscription]);


    function filterByTypeID(valueArray) {
        // let array = [];
        // let uniqueArray = {};

        // for (let i in result) {
        //     let objValue = result[i]["serviceName"];
        //     uniqueArray[objValue] = result[i];
        // }
        // for (let i in uniqueArray) {
        //     array.push(uniqueArray[i]);
        // }
        // setData(array)
        // console.log('result',array)

        // return array;

        let services = [];
                     
        for(let value of valueArray){
        let servicesIndex = services.findIndex(
            (objRes) => objRes.serviceName == value.serviceName
        );
        if (servicesIndex != -1) {
            services[servicesIndex].cost = String(Number(value.cost) + Number(services[servicesIndex].cost));
            // services[servicesIndex].cost = costRemoveExponential(JSON.parse(JSON.stringify(tempServiceCost)));
            
            // objRes.payerAccountId == value.payerAccountId && objRes.usageAccountId == value.usageAccountId && objRes.serviceCode == value.serviceCode && objRes.location == value.location
               let resourceIndex = services[servicesIndex].resources.findIndex(
                (objRes) => objRes.payerAccountId == value.payerAccountId && objRes.usageAccountId == value.usageAccountId && objRes.location == value.location

            );

            if (resourceIndex != -1) {
                services[servicesIndex].resources[resourceIndex].cost = String(Number(value.cost) + Number(services[servicesIndex].resources[resourceIndex].cost));
                services[servicesIndex].resources[resourceIndex].meter.push(...value.meter)


            }else{

                services[servicesIndex].resources.push({
                    resourceId:value.resourceId,
                    location: value.location,
                    cost: String(value.cost),
                    usageAccountId:value.usageAccountId,
                    usageAccountName:value.usageAccountName,
                    payerAccountId:value.payerAccountId,
                    meter:value.meter
               })
            }

        //     for(let meter of value.meter){
        //     let meterIndex = services[servicesIndex].meter.findIndex(
        //         (objRes) => objRes.operation == meter.operation && objRes.productFamily == meter.productFamily && objRes.lineItemUsageType == meter.lineItemUsageType && objRes.lineItemDescription == meter.lineItemDescription
        //     );

        //     if (meterIndex != -1) {
        //         services[servicesIndex].meter[meterIndex].cost = String(Number(meter.cost) + Number(services[servicesIndex].meter[meterIndex].cost));
        //         // services[servicesIndex].meter[meterIndex].cost = costRemoveExponential(JSON.parse(JSON.stringify(tempMeterCost)));
        //     } else {
        //         services[servicesIndex].meter.push({
        //             operation: meter.operation,
        //             productFamily: meter.productFamily,
        //             lineItemUsageType: meter.lineItemUsageType,
        //             lineItemDescription: meter.lineItemDescription,
        //             cost: String(meter.cost),
        //         });
        //     }
        // }
        
        } else {
            services.push({
                payerAccountId: value.payerAccountId,
                usageAccountId: value.usageAccountId,
                resourceId: value.resourceId,
                serviceCode: value.serviceCode,
                serviceName: value.serviceName,
                location: value.location,
                cost: String(value.cost),
                resources: [{
                     resourceId:value.resourceId,
                     location: value.location,
                     cost:String(value.cost),
                     usageAccountId:value.usageAccountId,
                     usageAccountName:value.usageAccountName,
                     payerAccountId:value.payerAccountId,
                     meter:value.meter
                }],
            });
        }
    }
         setData(services)
    }

    useEffect(() => {
        
        if (period) {
            const final =
                selectedSubscription.title == "All"
                    ? awsServicesSpending && { start: moment(awsServicesSpending[period]?.startDate, "YYYYMMDD").format("MMM DD, YYYY"), end: moment(awsServicesSpending[period]?.endDate, "YYYYMMDD").format("MMM DD, YYYY") }
                    : awsSubServicesSpending && { start: moment(awsSubServicesSpending[period]?.startDate, "YYYYMMDD").format("MMM DD, YYYY"), end: moment(awsSubServicesSpending[period]?.endDate, "YYYYMMDD").format("MMM DD, YYYY") };
            setStartDate(moment(final?.start).isValid() ? final?.start : null);
            setEndDate(moment(final?.end).isValid() ? final?.end : null);
            
            if(selectedSubscription.title == "All"){
                if (awsServicesSpending != null && "services" in awsServicesSpending?.[period] && Object.keys(awsServicesSpending?.[period])?.length) {
                    filterByTypeID(JSON.parse(JSON.stringify(awsServicesSpending?.[period]?.services)));
                }
            }else{
                if (awsSubServicesSpending != null && "services" in awsSubServicesSpending?.[period] && Object.keys(awsSubServicesSpending?.[period])?.length) {
                    filterByTypeID(JSON.parse(JSON.stringify(awsSubServicesSpending?.[period]?.services)));
                }

            }

            
        }
    }, [period, selectedSubscription, awsServicesSpending, awsSubServicesSpending]);

    function rootLoaded() {
        if (parentWidth) return;
        const e = window.$("#root_dashboard");
        try {
            setParentWidth(e.width());
        } catch (e) {}
    }

    useEffect(()=>{
        setSpendByMonthAccountData(null)
        if(yearTableData){
            const accounts = {};
            const finalData = [];

            yearTableData?.forEach(monthWise=>{
                monthWise.services.forEach((subWise)=>{
                    if(subWise.serviceCode in accounts){
                        accounts[subWise.serviceCode].list.push({month: monthWise.month, cost: subWise.cost});
                    } else {
                        accounts[subWise.serviceCode] = {  list: [], name: subWise?.serviceName};
                        accounts[subWise.serviceCode].list.push({month: monthWise.month, cost: subWise.cost});
                    }

                })
            })

            Object.keys(accounts).forEach(key=>{
                const account = accounts[key].list;

                const newData = getMonthsFromNow().reverse().map((m, i) => {
                    const time = moment(m, "MMM YYYY");
                    const found = account.findIndex((d) => {
                        const int = moment(d?.month, "YYYYMM");
                        return time.isSame(int);
                    });
                    if (found > -1) {
                        return account?.[found];
                    } else {
                        return {month: parseInt(moment(m).format("YYYYMM")), cost: "_"};
                    }
                });

                finalData.push({subscriptionId: key, name: accounts[key].name, list: newData})
            });

            let otherRow = [];
            if(finalData && finalData.length){
                const totalData = finalData.map(f=>f.list.map(l=>isNumeric(l.cost)?parseFloat(l.cost):'-'));
                const finalTotal = [null,null,null,null,null,null,null,null,null,null,null,null,null];
                for(var col = 0; col < (totalData?.[0]?.length) || 0; col++){
                    totalData.forEach(row=>{
                        if(isNumeric(String(row[col])))
                            finalTotal[col] = (finalTotal[col] || 0) + row[col];
                    })
                }

                otherRow.push({
                    subscriptionId: 'All Services',
                    list: finalData?.[0]?.list?.map((f, i)=>({month: f.month, cost: ""+finalTotal[i]}))
                });
            }

            setSpendByMonthAccountData({list: finalData, other: otherRow});
        }
    }, [yearTableData]);

    return (
        <div>
            <ContainerCardWithDateRange
                title={view == "accounts" ? "Spend by accounts" : "Spend by services"}
                titleIcon={view == "accounts" ? aws_account : resource_group}
                defaultPeriod={"1M"}
                showDateRange={true}
                collapsible={true}
                datePeriod={({ period }) => ({
                    start:"__",
                    end: "__",
                    rawStart: startDate,
                    rawEnd: endDate,
                })}
                totalCost={({ period }) =>
                    selectedSubscription.title == "All"
                        ? awsServicesSpending && `${currency + Format.formatNumber(awsServicesSpending[period]?.totalCost || 0)}`
                        : awsSubServicesSpending && `${currency + Format.formatNumber(awsSubServicesSpending[period]?.totalCost || 0)}`
                }
                datePeriodEnabled={true}
                showDatePeriod={(period) => true}
                onPeriodChange={setPeriod}
            >
                {({ period }) => (
                    loading?
                        <LinearProgress />:
                            selectedSubscription.title == "All" ? (

                                awsServicesSpending?.[period] != null && data  != null && Object.keys(awsServicesSpending?.[period])?.length  ? (
                                    <AwsGroupByServices result={data} view={"all"} tableRow={view} data={data} currency={currency} />
                                ) : (
                                    <h6 style={{ textAlign: "center" }}>No data found</h6>
                                )

                            ) : (
                                awsSubServicesSpending?.[period] != null && data != null &&  Object.keys(awsSubServicesSpending?.[period])?.length ? (
                                    <AwsGroupByServices result={data} view={"account"} tableRow={view} data={awsSubServicesSpending[period]} currency={currency} />
                                ) : (
                                    <h6 style={{ textAlign: "center" }}>No data found</h6>
                                )
                            )
                        )}
            </ContainerCardWithDateRange>
            {/*loading && data == null*/}
            <br/>
            <div style={{}}>
        <ContainerCardWithDateRange title={"Spend by months"}
                                    titleIcon={resource_group}
                                    showDateRange={false}
                                    collapsible={true}>
            {
                ()=>(
                    <>
                        {(yearTableDataLoading || spendByMonthAccountData)?<TableSearch onSearch={setYTDSearch}/>:null}
                        {(yearTableDataLoading || spendByMonthAccountData)?<TableMonthlySpend
                            loading={yearTableDataLoading} cols={14}
                            subscriptionNameAsOverlay={true}
                            // subscriptionLevel={true}
                            tableLabel={"Service"}
                            width={parentWidth}
                            data={spendByMonthAccountData?.list?.filter(s=>{
                                if (ytdSearch && ytdSearch.toString().length) {
                                    return !!TableSearch.doesItIncludes(
                                        [
                                            s.cloud,
                                            s.subscriptionId
                                        ],
                                        ytdSearch
                                    );
                                }
                                return true;
                            })}
                            staticRows={spendByMonthAccountData?.other}
                            currency={currency}
                            showCloud={false}
                        />:<NO_DATA_AVAILABLE height={'auto'}  />}
                    </>
                )
            }
        </ContainerCardWithDateRange>
    </div>
        </div>
    );
}
