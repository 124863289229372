import React, {useEffect} from "react";

export default function MountUnmount({onMount = () => {}, onUnmount = () => {}}) {
    useEffect(()=>{
        onMount();
        return ()=>{
            onUnmount();
        }
    }, [])
    return (
        <>
        </>
  );
}
