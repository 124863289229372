import React, { useState, useEffect } from "react";
import "./style.css";
import Format from "components/NumberFormat";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import moment from "moment";
// import {similarObject} from "../listS3bucket/ConversionFunc";
import RecommendationsSummaryCards from "./RecommendationSummaryCards";
import { makeStyles, withStyles } from "MakeWithStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {CancelRounded, Help, Info} from "@mui/icons-material";
import { Divider, Typography } from "@mui/material";
import Cost from "components/Cost";
import "./style.css";
import MatricLevelChart from "./MatricLevelChart";
import MatricObjectData from "./MatricObjectData";
import TablePaginationActions from "../../components/TablePaginationActions";
import Badge from "@mui/material/Badge";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import aws_recommendation from '../../images/recommended.png'
import Button from "@mui/material/Button";
import vm_icon from '../../images/Virtual-Machine.svg'
import colors from "../../styles/color";

const StyledTableRow = withStyles(TableRow,(theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            // backgroundColor: theme.palette.action.hover,
        },
    },
}));

const DAYS_PERIOD = 30;

export default function AzureSubscriptionRecommendations({ title, data, customerId, tenantId, tenantName,
                                                      subscriptionId, lastUpdated, currency, cards, type }) {
    const [list, setList] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [totalCost, setTotalCost] = useState(0.003);
    const [sum, setSum] = useState(null);

    return (
        <div>
                <ContainerCardWithDateRange
                    title={title}
                    titleIcon={vm_icon}
                    showDateRange={false}
                    collapsible={true}
                    ExtraHeaderComponent={({ period }) =>
                        data != null && data?.length ? (
                            <div style={{ marginTop: 4 }}>
                                <span style={{fontSize: 14}}>Last updated {moment(lastUpdated).fromNow(true)} ago</span>
                            </div>
                        ) : null
                    }
                >
                    {({ period }) => (
                        <>
                            {cards && <RecommendationsSummaryCards summary={cards}/>}
                            {data && <RecommendationsTable tenantName={tenantName} type={type} subscriptionId={subscriptionId} list={data} currency={currency} customerId={customerId} />}
                        </>
                    )}
                </ContainerCardWithDateRange>
        </div>
    );
}

const AZURE_VM_TERMINATE_HELP = "https://docs.microsoft.com/en-us/azure-stack/user/delete-vm?view=azs-2108&tabs=portal";

function RecommendationsTable(props) {
    const { list, currency, customerId, subscriptionId, type, tenantName } = props;
    const {classes} = useRowStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [showingDetails, setShowingDetails] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const showS3Details = (detail) => {
        setShowingDetails(null);
        if (detail) {
            setShowingDetails(detail);
        }
    };
    return (
        <div>
            <div className={classes.root}>
                {list != null && list?.length ? (
                    <div style={{padding: 0, margin: 0}}>
                        <Paper elevation={3} >
                            <Box display={"flex"} flex={1}>
                                <Box flex={showingDetails ? 0.3 : 1}>
                                    <TableContainer className={classes.container}>
                                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell  align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                    </StyledTableCell>
                                                    <StyledTableCell  align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                        VM name
                                                    </StyledTableCell>

                                                    <StyledTableCell  align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                        OS Type
                                                    </StyledTableCell>

                                                    {showingDetails ? null : (
                                                        <>

                                                            <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                                Location
                                                            </StyledTableCell>

                                                            {/*<StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>*/}
                                                            {/*    Subscription*/}
                                                            {/*</StyledTableCell>*/}

                                                            {type =='stopped' && <StyledTableCell align="left" style={{
                                                                fontSize: 17,
                                                                fontWeight: "bold"
                                                            }}>
                                                                # days <br/>stopped
                                                            </StyledTableCell>}

                                                            <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                                # Disks <br/> attached
                                                            </StyledTableCell>

                                                            {type == 'stopped' && <StyledTableCell align="left" style={{
                                                                fontSize: 17,
                                                                fontWeight: "bold"
                                                            }}>
                                                                Total Disk <br/> size
                                                            </StyledTableCell>}

                                                            <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                                Disk {type==='stopped'?<br/>:null}Savings
                                                                {type==='idle'?<br/>:null}
                                                                {type==='idle' && " (per month)"}
                                                            </StyledTableCell>

                                                            {type == 'idle' && <StyledTableCell align="left" style={{
                                                                fontSize: 17,
                                                                fontWeight: "bold"
                                                            }}>
                                                                EC2 savings{type==='idle'?<br/>:null}{type==='idle' && " (per month)"}
                                                            </StyledTableCell>}

                                                            <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                                Total {type==='stopped'?<br/>:null}Savings{type==='idle'?<br/>:null}{type==='idle' && " (per month)"}
                                                            </StyledTableCell>

                                                            <StyledTableCell align="center" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                                Action
                                                                <div>
                                                                    <OverlayTrigger placement="left"
                                                                                    overlay={<Tooltip id="button-tooltip">How to terminate virtual machines?</Tooltip>}>
                                                                        <Badge badgeContent={0} color="secondary">
                                                                            <Help onClick={()=>window.open(AZURE_VM_TERMINATE_HELP, "_blank")} style={{cursor: "pointer", fontSize:20, color: "#303" }} />
                                                                        </Badge>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </StyledTableCell>
                                                        </>
                                                    )}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(list || [])?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((r, i) => (
                                                    <ExpandRow tenantName={tenantName} type={type} subscriptionId={subscriptionId} row={r} index={i} currency={currency} showingDetails={showingDetails} setShowingDetails={showS3Details} icon={aws_recommendation} />
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Box display={showingDetails ? "flex" : "none"} flex={showingDetails ? 0.7 : 0}>
                                    <Paper style={{ flex: 1, padding: 5 }} elevation={3}>
                                        <Box display={"flex"} alignItems={"center"}>
                                            <Box display={"flex"} flex={1} justifyContent={"space-between"} style={{ paddingRight: 10 }} alignItems={"center"}>
                                                <Typography component={"h4"} variant={"h4"}>
                                                    <img src={aws_recommendation} height={30} /> {showingDetails?.ResourceId}
                                                </Typography>
                                                <Typography component={"h5"} variant={"h5"}>
                                                    {Format.formatCurrencySymbol(currency)}
                                                    {showingDetails?.cost ? <Cost>{showingDetails?.cost}</Cost> : "__.__"}
                                                </Typography>
                                            </Box>
                                            <CancelRounded onClick={() => setShowingDetails(null)} />
                                        </Box>
                                        <Divider />
                                        <Box padding={1}>
                                            <MatricObjectData customerId={customerId} bucketName={showingDetails?.ResourceId} tenantId={showingDetails?.UsageAccountId} bucketRegion={showingDetails?.Region} />
                                        </Box>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: 10 }}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
                                                    <Typography>
                                                        <b>Linked account:</b>
                                                    </Typography>
                                                    <Typography className="typoMargin">
                                                        <b>PricingTerm:</b>
                                                    </Typography>
                                                    <Typography className="typoMargin">
                                                        <b>PricingUnit:</b>
                                                    </Typography>
                                                    <Typography className="typoMargin">
                                                        <b>LineItemType:</b>
                                                    </Typography>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
                                                    <Typography>{showingDetails?.UsageAccountId || "-"}</Typography>
                                                    <Typography className="typoMargin">{showingDetails?.PricingTerm || "-"}</Typography>
                                                    <Typography className="typoMargin">{showingDetails?.PricingUnit || "-"}</Typography>
                                                    <Typography className="typoMargin">{showingDetails?.LineItemType || "-"}</Typography>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", marginRight: 20 }}>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <Typography>
                                                        <b>ResourceTags:</b>
                                                    </Typography>

                                                    <Typography className="typoMargin">
                                                        <b>Region:</b>
                                                    </Typography>

                                                    <Typography className="typoMargin">
                                                        <b>ProductName:</b>
                                                    </Typography>

                                                    <Typography className="typoMargin">
                                                        <b>BillType:</b>
                                                    </Typography>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
                                                    <Typography>{showingDetails?.ResourceTags || "-"}</Typography>
                                                    <Typography className="typoMargin">{showingDetails?.Region}</Typography>
                                                    <Typography className="typoMargin">{showingDetails?.ProductName}</Typography>
                                                    <Typography className="typoMargin">{showingDetails?.BillType || "-"}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider />
                                        <Box padding={1}>{showingDetails?.meterLevel && <Metertable data={showingDetails?.meterLevel} currency={currency} />}</Box>
                                        <Divider />
                                        <Box padding={2}>
                                            <MatricLevelChart customerId={customerId} bucketName={showingDetails?.ResourceId} tenantId={showingDetails?.UsageAccountId} bucketRegion={showingDetails?.Region} />
                                        </Box>
                                    </Paper>
                                </Box>
                            </Box>
                            <TablePagination ActionsComponent={TablePaginationActions} rowsPerPageOptions={[10, 25, 50, 75]} component="div" count={list.length} rowsPerPage={rowsPerPage} page={page} onChangePage={handleChangePage} onChangeRowsPerPage={handleChangeRowsPerPage} />
                        </Paper>
                    </div>
                ) : (
                    <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                )}
            </div>
        </div>
    );
}

function Metertable({ data, currency }) {
    return (
        <div>
            <Typography style={{ fontWeight: "bold" }}>Meter data</Typography>
            <div style={{ maxHeight: 300 }} className="overflow-auto">
                <table id="customers">
                    <tr>
                        <th>Operation</th>
                        <th>Product family</th>
                        <th>Usage type</th>
                        <th>Description</th>
                        <th>Cost</th>
                    </tr>

                    {data &&
                        data.meter.map((m) => (
                            <tr>
                                <td>{m.Operation}</td>
                                <td>{m.ProductFamily}</td>
                                <td>{m.LineItemUsageType}</td>
                                <td>{m.LineItemDescription}</td>
                                <td>{Format.formatCurrencySymbol(currency) + Format.formatNumber(Number(m["cost"]))}</td>
                            </tr>
                        ))}
                </table>
            </div>
        </div>
    );
}

const StyledTableCell = withStyles(TableCell,(theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: theme.spacing(1),

    },
}));

const useRowStyles = makeStyles()((theme) => ({
    root: {
        "& > *": {
            padding: theme.spacing(1.5),
        },
    },
    table: {
        // minWidth: 700,
    },
    container: {
        maxHeight: 600,
    },
    link: {
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

 function ExpandRow(props) {
    const { row, currency, showingDetails, setShowingDetails,icon, subscriptionId, type, tenantName } = props;

    const {classes} = useRowStyles();

    /**
     *
     * BASE_PATH =
     AWS_REGION=_________
     EC2_INSTANCE_ID=__________________
     EC2_PATH=#InstanceDetails:instanceId

     https://AWS_REGION/BASE_PATH+ AWS_REGION+EC2_PATH+EC2_INSTANCE_ID

     https://_________.console.aws.amazon.com/ec2/v2/home?region=________#InstanceDetails:instanceId=_______


     */

    function generateURL(vm_path){
        if(!tenantName && !vm_path) return;
        const BASE_PATH = "https://portal.azure.com/#@";
        const TENANT_NAME_DOMAIN = tenantName + '.com';
        const RESOURCE = "/resource";
        const VM_PATH = vm_path;

        const FINAL_URL = BASE_PATH + TENANT_NAME_DOMAIN + RESOURCE + VM_PATH;
        window.open(FINAL_URL, "_blank");
    }

    function getTotalVolumeSize(e, output_unit){
        function sizeInBytes(input_unit, size, to = null, new_size){
            if(!((size?.toString() || false) || (new_size?.toString() || false))) return null;
            var newSize = size;
            if(to){
                size = 1;
            }
            switch(to || input_unit){
                case "KB":  newSize = size*1000;
                    break;
                case "MB":  newSize = size*1000*1000;
                    break;
                case "GB":  newSize = size*1000*1000*1000;
                    break;
                case "TB":  newSize = size*1000*1000*1000*1000;
                    break;
                case "PB":  newSize = size*1000*1000*1000*1000*1000;
                    break;
                case "KiB": newSize = size*1024;
                    break;
                case "MiB": newSize = size*1024*1024;
                    break;
                case "GiB": newSize = size*1024*1024*1024;
                    break;
                case "TiB": newSize = size*1024*1024*1024*1024;
                    break;
                case "PiB": newSize = size*1024*1024*1024*1024*1024;
                    break;
            };
            return to != null?new_size/newSize:newSize;
        }
        var newSize = e?.disks?.map(v=>sizeInBytes(v?.unit, v?.size, null))
            ?.filter(f=>f!=null)
            ?.reduce((a,b)=>a+b, null);
        newSize = sizeInBytes(null,null,output_unit, newSize);
        return newSize || "--";
    }

    return (
        <React.Fragment>
            <StyledTableRow hover className={classes.root} key={row.resourceId}
                // style={{ cursor: "pointer" }}
                            onClick={() => setShowingDetails(row.details)}>
                <TableCell align="left">
                    {" "}
                    <img src={vm_icon} height={20} />
                </TableCell>
                <TableCell align="left">
                    <span
                        // className={classes.link}
                        style={{
                            // color: colors.blue,
                            whiteSpace: 'nowrap'}}>
                        {row?.vmName || row?.vmId}{" "}
                        <OverlayTrigger placement="bottom" overlay={
                            <Tooltip id="button-tooltip">
                                <span style={{}}>
                                    <b>VM ID</b><br/>
                                    <span style={{color: colors.gray}}>{row?.vmId}</span>
                                </span>
                            </Tooltip>
                        }>
                            <Info aria-label={'disabled'} style={{cursor: "pointer", fontSize: 15, color: "#adadad" }} />
                        </OverlayTrigger>
                    </span>
                </TableCell>
                <TableCell align="left">
                    <span style={{whiteSpace: 'nowrap'}}>
                        {Array.from(new Set(row.disks.map(d=>d.properties.osType)))?.[0]}
                    </span>
                </TableCell>
                {/* <TableCell align="center">{row?.serviceName}</TableCell> */}
                {showingDetails != null ? null : (
                    <>
                        <TableCell align="left" style={{whiteSpace: 'nowrap'}}>{row?.location}</TableCell>
                        {/*<TableCell align="left">{subscriptionId || row?.accountId}</TableCell>*/}
                        {type == 'stopped' && <TableCell align="left">{
                            (row?.numberOfDaysStopped?.toString()) +
                            (parseInt(row?.numberOfDaysStopped) > 1? " days":" day" )
                            || "-"
                        }</TableCell>}
                        <TableCell align="left">{
                            (row?.disks?.length?.toString()) +
                            (row?.disks?.length > 1?" disks": " disk")
                            || "-"
                        }</TableCell>
                        {type == 'stopped' &&
                            <TableCell align="left">
                                {row?.totalDiskSizeGB} GB
                                {/*{getTotalVolumeSize(row, "GB")+" GB"}*/}
                            </TableCell>
                        }
                        <TableCell align="left">
                            {
                            Format.formatCurrencySymbol(currency) +
                                Format.formatNumber(Number(row?.totalAvgDiskCostPerDay))
                            } / day
                        </TableCell>
                        {type == 'idle' &&
                            <TableCell align="left">
                                {
                                    Format.formatCurrencySymbol(currency) +
                                    Format.formatNumber(
                                        parseFloat(row?.totalAvgCostPerDay)*DAYS_PERIOD
                                    )
                                }
                            </TableCell>
                        }
                        <TableCell align="left">
                            {Format.formatCurrencySymbol(currency)}
                            {
                                type=='stopped'?
                                    Format.formatNumber(Number(row?.totalAvgDiskCostPerDay) * DAYS_PERIOD):
                                    Format.formatNumber(Number(
                                        (parseFloat(row.avgCost) +
                                            row?.disks?.map(r=>parseFloat(r.avgCost))?.reduce((a,b)=>a+b, 0)) * DAYS_PERIOD).toFixed(2))
                            } / month
                            <span style={{paddingLeft: 2}}>
                                <OverlayTrigger placement="bottom" overlay={
                                    <Tooltip id="button-tooltip">
                                        {Format.formatCurrencySymbol(currency)}{Format.formatNumber(Number(row?.totalDiskCost))} / {row?.numberOfDaysStopped + ((parseInt(row?.numberOfDaysStopped) > 1? " days":" day" ))}
                                    </Tooltip>
                                }>
                                    <Info aria-label={'disabled'} style={{cursor: "pointer", fontSize: 15, color: "#adadad" }} />
                                </OverlayTrigger>
                            </span>
                        </TableCell>
                        <TableCell align="center">
                            <Button
                                onClick={()=>generateURL(row?.id)}
                                // onClick={onUpgrade}
                                variant="contained"
                                // color="primary"
                                // size="large"
                                style={{ fontSize: 11, borderRadius: 15 , backgroundColor: "#3f50b5", color: "#FFF",textTransform: 'initial'}}
                            >
                                Fix in Azure
                            </Button>
                        </TableCell>
                    </>
                )}
            </StyledTableRow>
        </React.Fragment>
    );
}

