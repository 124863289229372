import * as types from "../actions/types";

const userState = {
    allComputeData: null,
    allContainerData: null,
    allKubernetesData: null,
    error: null,
    allCCKloading:false,
    allContainersloading:false,
    allKubernetesloading:false,
};

function allComputeReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.ALL_COMPUTE_REQUEST: {
            return {
                ...state,
                allCCKloading:true,
                allContainersloading:true,
                allKubernetesloading:true,
            };
        }
        case types.ALL_COMPUTE_SUCCESS: {
            const data = {};

            if (payload.dataType == "compute") {
                data["allComputeData"] = payload.data.data;
                data["allCCKloading"] = false

            } else if (payload.dataType == "containers") {
                data["allContainerData"] = payload.data.data;
                data["allContainersloading"] =false

            } else if (payload.dataType == "kubernetes") {
                data["allKubernetesData"] = payload.data.data;
                data["allKubernetesloading"] = false
            }

            return {
                ...state,
                ...data
            };
        
        }
        case types.ALL_COMPUTE_FAILURE: {
            return {
                ...state,
                allCCKloading:false,
                allContainersloading:false,
                allKubernetesloading:false,
                allComputeData: null,
                allContainerData: null,
                allKubernetesData: null,
                error: payload,
            };
        }
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                allComputeData: null,
                allContainerData: null,
                allKubernetesData: null,
                error: null,
                
            };
        }

        default:
            return state;
    }
}
export default allComputeReducer;
