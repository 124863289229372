import * as types from "../actions/types";

const userState = {
    lambdaData: null,
    lambdaAccountData: null,
    error: null,
    loading: false,
    lambdaAccountLoading: false,
    S3BucketAll : null,
    S3BucketAccount: null,
    S3MetricData: null,

    stoppedEC2recommendationLoading: false,
    stoppedEC2recommendationData: null,
    stoppedEC2recommendationAccountData: null,

    idleEC2recommendationLoading: false,
    idleEC2recommendationData: null,
    idleEC2recommendationAccountData: null
};

function awsLambdaReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.AWS_LAMBDA_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AWS_LAMBDA_SUCCESS: {
            return {
                ...state,
                lambdaData: payload.data,
                loading: false,
            };
        }
        case types.AWS_LAMBDA_FAILURE: {
            return {
                ...state,
                loading: false,
                lambdaData: null,
                error: payload,
            };
        }
        //------------------------------ AWS S3 Bucket ------------------------------
        case types.AWS_S3BUCKET_REQUEST: {
            return {
                ...state,
                loading: true,
                S3BucketAccount: null,
            };
        }
        case types.AWS_S3BUCKET_SUCCESS: {
            const { data } = payload;
            if (payload?.all) {
            return {
                    ...state,
                    S3BucketAll: payload?.data?.data,
                    loading: false,
                };
            } else {
                return {
                    ...state,
                    S3BucketAccount: data,
                    loading: false,
                };
            }
        }
        case types.AWS_S3BUCKET_FAILURE: {
            return {
                ...state,
                loading: false,
                S3BucketAll: null,
                S3BucketAccount: null,
                error: payload,
            };
        }
        case types.AWS_S3METRIC_DATA_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AWS_S3METRIC_DATA_SUCCESS: {
            return {
                ...state,
                S3MetricData: payload,
                loading: false,
            };
        }
        case types.AWS_S3METRIC_DATA_FAILURE: {
            return { ...state, loading: false, S3MetricData: null };
        }

        case types.AWS_LAMBDA_ACCOUNT_REQUEST: {
            return {
                ...state,
                lambdaAccountLoading: true,
            };
        }
        case types.AWS_LAMBDA_ACCOUNT_SUCCESS: {
            return {
                ...state,
                lambdaAccountData: payload.data,
                lambdaAccountLoading: false,
            };
        }
        case types.AWS_LAMBDA_ACCOUNT_FAILURE: {
            return {
                ...state,
                lambdaAccountLoading: false,
                lambdaAccountData: null,
                error: payload,
            };
        }
        case types.AWS_RECOMMENDATIONS_REQUEST: {
            return {
                stopped: {
                    ...state,
                    stoppedEC2recommendationLoading: true,
                },
                idle: {
                    ...state,
                    idleEC2recommendationLoading: true,
                }
            }[payload.type];
        }
        case types.AWS_RECOMMENDATIONS_SUCCESS: {
            if(payload?.data?.accountLevel == false){
                return {
                    stopped: {
                        ...state,
                        stoppedEC2recommendationData: {
                            ...payload?.data,
                            data: payload?.data?.recommendations,
                            recommendations: undefined,
                            customerId: undefined
                        },
                        stoppedEC2recommendationLoading: false,
                    },
                    idle: {
                        ...state,
                        idleEC2recommendationData: {
                            ...payload?.data,
                            data: payload?.data?.recommendations,
                            recommendations: undefined,
                            customerId: undefined
                        },
                        idleEC2recommendationLoading: false,
                    }
                }[payload.type];
            } else {
                return {
                    stopped: {
                        ...state,
                        stoppedEC2recommendationAccountData: {
                            ...payload?.data,
                            data: payload?.data?.recommendations,
                            recommendations: undefined,
                            customerId: undefined
                        },
                        stoppedEC2recommendationLoading: false,
                    },
                    idle: {
                        ...state,
                        idleEC2recommendationAccountData: {
                            ...payload?.data,
                            data: payload?.data?.recommendations,
                            recommendations: undefined,
                            customerId: undefined
                        },
                        idleEC2recommendationLoading: false,
                    }
                }[payload.type];
            }
        }
        case types.AWS_RECOMMENDATIONS_FAILURE: {
            return {
                stopped: {
                    ...state,
                    stoppedEC2recommendationLoading: false,
                    stoppedEC2recommendationAccountData: null,
                    // error: payload
                },
                idle: {
                    ...state,
                    idleEC2recommendationLoading: false,
                    idleEC2recommendationAccountData: null,
                    // error: payload
                }
            }[payload.type];
        }
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                lambdaData: null,
                lambdaAccountData: null,
                error: null,
                loading: false,
                lambdaAccountLoading: false,
                S3BucketAll: null,
                S3BucketAccount: null,
                S3MetricData: null,
            };
        }

        default:
            return state;
    }
}
export default awsLambdaReducer;
