import React, { useState, useEffect } from "react";
import GCP_ICON from "../../images/GCP_logo.svg";
import plusicon from "../../images/plusicon.svg";
import Form from "react-bootstrap/Form";
import MuiAlert from "@mui/lab/Alert";
import BudgetList from "../Budgets/BudgetList";
import { useSelector, useDispatch } from "react-redux";
import { getBudgetsRequest, accountRequest } from "../../redux/actions/actions";
import { makeStyles, withStyles } from "MakeWithStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Azure_logo from "../../images/Azure_logo.svg";
import AWS_LOGO from "../../images/aws_dark.svg";
import axios from "../../connection/axios";
import { Card, Divider, Paper, SnackbarContent } from "@mui/material";
import RoutesList from "../../routes/RoutesList";
import { InputGroup, Modal } from "react-bootstrap";
import BudgetAlert from "./BudgetAlert";
import ProgressBar from "react-bootstrap/ProgressBar";
import Colors from "../../styles/color";
import { Radio, RadioGroup, FormControlLabel, FormControl, LinearProgress } from "@mui/material";
import BUDGET_ICON from "../../images/budget_calculator.svg";
import Overview from "../../images/overview.svg";
import { useSnackbar } from "notistack";
import Format from "../../components/NumberFormat";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import BudgetSummaryChart from "../../pages/Budgets/BudgetSummaryCharts";
import BudgetOverview from "../Budgets/BudgetOverview";
import { Link } from "react-router-dom";
import DashboardIcon from "../../images/newCloudTrakrIcons/MultiCloudIcon.png";
import {showSnackBar} from "../MessagesBox";

const $ = window.$;
const { REACT_APP_CREATEBUDGETS, REACT_APP_DELETEBUDGETS, REACT_APP_EDITBUDGETS } = process.env;

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    tabPanel: {
        padding: theme.spacing(-10),
    },
    labelContainer: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
    },

    tab: {
        fontWeight: "bold",
        textTransform: "none",
        outline: "none",
    },
});

function Dialogtitle2(props){
    const { children, classes, onClose, ...other } = props;
    // const classes = withStyles.getClasses(props);

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
}

const DialogTitle = withStyles(Dialogtitle2, styles);

const DialogContent = makeStyles(MuiDialogContent)((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}));

const DialogActions = makeStyles(MuiDialogActions)((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}));

const useStyles2 = makeStyles()((theme) => ({
    root: {
        [theme.breakpoints.up("sm")]: {
            minWidth: "344px !important",
        },
    },
    card: {
        backgroundColor: "#fddc6c",
        width: "100%",
    },
    typography: {
        fontWeight: "bold",
    },
    actionRoot: {
        padding: "8px 8px 8px 16px",
        justifyContent: "space-between",
    },
    icons: {
        marginLeft: "auto",
    },
    expand: {
        padding: "8px 8px",
        transform: "rotate(0deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    collapse: {
        padding: 16,
    },
    checkIcon: {
        fontSize: 20,
        color: "#b3b3b3",
        paddingRight: 4,
    },
    button: {
        padding: 0,
        textTransform: "none",
    },
    tab: {
        fontWeight: "bold",
        textTransform: "none",
        outline: "none",
    },
}));

export default function Budgets() {
    const {classes: classes2} = useStyles2();
    const { customerId, currency, role } = useSelector((state) => ({
        customerId: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
        currency: state.allHomeReducer.currency || Format.FallBackCurrency,
        role: state.userDetailsReducer.role,
    }));
    const [show, setShow] = useState(false);
    const [showData, setShowData] = useState(null);
    const handleClose1 = () => setShow(false);
    const [open, setOpen] = React.useState(false);
    const [budgetDeleteData, setBudgetDeleteData] = React.useState({});
    const [budgetMode, setBudgetMode] = React.useState("create");
    const [editableData, setEditableData] = React.useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const dispatch = useDispatch();
    const [connectionLoading, setConnectionLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // const userData = useSelector((state) => ({
    //     id: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,

    // }));
    const getBudgets = useSelector((state) => state.getBudgetReducer?.budgets?.result);

    var azuretenantID;
    var awstenantID;
    var gcptenantID;

    for (let i = 0; i < getBudgets?.length; i++) {
        if (getBudgets[i]?.cloudProvider == "azure") {
            azuretenantID = getBudgets[i]?.tenantID;
        } else if (getBudgets[i]?.cloudProvider == "aws") {
            awstenantID = getBudgets[i]?.tenantID;
        } else if (getBudgets[i]?.cloudProvider == "gcp") {
            gcptenantID = getBudgets[i]?.tenantID;
        }
    }

    const azureAccountStatus = useSelector((state) => state.accountStatusReducer.azureAccountStatus);
    const awsAccountStatus = useSelector((state) => state.accountStatusReducer.awsAccountStatus);
    const gcpAccountStatus = useSelector((state) => state.accountStatusReducer.gcpAccountStatus);

    const loading = useSelector((state) => state.getBudgetReducer?.loading);

    const [value, setTabValue] = React.useState("1");

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        // let customerId = userData.id;
        dispatch(getBudgetsRequest({ customerId, all: "all" }));
    }, [customerId]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (azureAccountStatus || awsAccountStatus || gcpAccountStatus == null) {
            AccountStatus(customerId);
        }
        AccountStatus(customerId);
    }, [azureAccountStatus, awsAccountStatus, gcpAccountStatus, customerId]);

    function AccountStatus(id) {
        if (id) {
            dispatch(accountRequest(id));
        }
    }

    const {classes: classes1} = useStyles1();

    function createBudget(budgetName, budgetAmount, budgetPeriod, cloudProvider, alertLevel) {
        // showSnackBar(enqueueSnackbar, closeSnackbar).warning('Creating Budget');
        axios
            .post(REACT_APP_CREATEBUDGETS, {
                customerID: customerId,
                budgetLevel: cloudProvider,
                budgetPeriod,
                budgetAmount,
                budgetName,
                alertLevel: alertLevel?.toString(),
            })
            .then((response) => response.data)
            .then((responseJson) => {
                // console.log("connect", responseJson);
                if ("status" in responseJson) {
                    if (responseJson.status == "200") {
                        handleClose();
                        if (responseJson?.result?.msg && responseJson?.result?.msg?.Code === 7001) {
                            dispatch(getBudgetsRequest({ customerId, all: "all" }));
                            showSnackBar(enqueueSnackbar, closeSnackbar, {
                                autoHideDuration: 5000,
                            }).warning("Budget already exists!");
                        } else {
                            dispatch(getBudgetsRequest({ customerId, all: "all" }));
                            showSnackBar(enqueueSnackbar, closeSnackbar, {
                                autoHideDuration: 5000,
                            }).success("Budget created successfully");
                        }
                    } else if (responseJson && responseJson.errorMessage) {
                        showSnackBar(enqueueSnackbar, closeSnackbar).error("Required data is missing");
                    } else {
                        alert("Something went wrong");
                    }
                }
            })
            .catch();
    }

    function editBudget(budgetName, budgetAmount, budgetPeriod, cloudProvider, alertLevel) {
        axios
            .post(REACT_APP_EDITBUDGETS, {
                customerID: customerId,
                budgetLevel: cloudProvider,
                budgetPeriod,
                budgetAmount,
                budgetName,
                alertLevel: alertLevel?.toString(),
            })
            .then((response) => response.data)
            .then((responseJson) => {
                // console.log("connect", responseJson);
                if ("status" in responseJson) {
                    if (responseJson.status == "200") {
                        setBudgetMode("create");
                        handleClose();
                        dispatch(getBudgetsRequest({ customerId, all: "all" }));
                        showSnackBar(enqueueSnackbar, closeSnackbar, {
                            autoHideDuration: 5000,
                        }).success("Budget edited successfully");
                    } else if (responseJson && responseJson.errorMessage) {
                        showSnackBar(enqueueSnackbar, closeSnackbar).error("Required data is missing");
                    } else {
                        alert("Something went wrong");
                    }
                }
            })
            .catch();
    }

    function showModal(data) {
        setShowData(data);
        setShow(true);
    }

    function onEdit(data) {}

    function onDelete(data) {
        const { budgetLevel, budgetPeriod, cloudProvider = null } = data;
        setBudgetDeleteData({ budgetLevel, budgetPeriod, cloudProvider });
        setShow(true);
    }

    function onDeleteConfirm() {
        const { budgetLevel, budgetPeriod } = budgetDeleteData;
        axios
            .get(REACT_APP_DELETEBUDGETS, {
                params: {
                    customerID: customerId,
                    budgetLevel,
                    budgetPeriod,
                },
            })
            .then((response) => response.data)
            .then((responseJson) => {
                // console.log("res", responseJson);
                if ("status" in responseJson) {
                    if (responseJson.status == 200) {
                        dispatch(getBudgetsRequest({ customerId, all: "all" }));
                        handleClose();
                    } else {
                    }
                }
            })
            .finally(() => setShow(false))
            .catch((error) => {
                console.error(error);
            });
    }

    const [isBlurred, setIsBlurred] = useState(false);
    const [actualValue, setActualValue] = useState(null);
    const [visibleValue, setVisibleValue] = useState(null);

    return (
        <>
            <DeleteBudgetPrompt show={show} handleClose={() => setShow(false)} onYes={onDeleteConfirm} period={budgetDeleteData?.budgetPeriod} cloud={budgetDeleteData?.cloudProvider} />
            <div
                style={{
                    backgroundColor: Colors.background,
                    minHeight: "95vh",
                }}
            >
                {connectionLoading || loading ? (
                    <div style={{ flexGrow: 1, width: "100%" }}>
                        <LinearProgress variant="determinate" value={progress} />
                    </div>
                ) : (
                    <div style={{ padding: 20 }}>
                        <div
                            style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                display: "flex",
                                alignItems: "center",
                            }}
                            className={'connection_buttons'}
                        >
                            {" "}
                            <div
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <img
                                    style={{
                                        // width: 40,
                                        height: 30,
                                        alignItems: "center",
                                        marginTop: -4,
                                    }}
                                    src={BUDGET_ICON}
                                />
                                <h4>
                                    Budgets
                                </h4>
                            </div>
                            {azureAccountStatus || awsAccountStatus || gcpAccountStatus ? (
                                <div>
                                    <Button className={classes1.btnStyle} onClick={handleClickOpen} disabled={role == "Owner" || role == null ? false : true}>
                                        <img
                                            src={plusicon}
                                            alt="addbudgets"
                                            style={{
                                                width: 26,
                                                height: 26,
                                                marginRight: 8,
                                            }}
                                        />
                                        Create Budget
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    <Link to={RoutesList.ACCOUNTS}>
                                        <h5 className="addAcount">
                                            You don't have any <span style={{ fontWeight: "bold" }}>Account</span> added, please add your cloud accounts
                                        </h5>
                                    </Link>
                                </div>
                            )}
                        </div>
                        {/* <br /> */}
                        {/* <div className={classes.root}> */}
                        <TabContext value={value}>
                            <div
                                style={{
                                    backgroundColor: Colors.background,
                                }}
                                position="static"
                            >
                                <TabList onChange={handleChange} aria-label="simple tabs example" textColor="primary" indicatorColor="primary">
                                    <Tab
                                        style={stylestab.tab}
                                        label={
                                            <div style={stylestab.labelContainer}>
                                                <img style={stylestab.icon} src={DashboardIcon} />
                                                Dashboard
                                            </div>
                                        }
                                        value="1"
                                        className={classes2.tab}
                                    />
                                    <Tab
                                        style={stylestab.tab}
                                        label={
                                            <div className={classes2.labelContainer}>
                                                <img style={stylestab.icon} src={Azure_logo} />
                                                Azure
                                            </div>
                                        }
                                        value="2"
                                        className={classes2.tab}
                                    />
                                    <Tab
                                        style={stylestab.tab}
                                        label={
                                            <div className={classes2.labelContainer}>
                                                <img style={stylestab.icon} src={AWS_LOGO} />
                                                AWS
                                            </div>
                                        }
                                        value="3"
                                        className={classes2.tab}
                                    />
                                    <Tab
                                        style={stylestab.tab}
                                        label={
                                            <div className={classes2.labelContainer}>
                                                <img style={stylestab.icon} src={GCP_ICON} />
                                                GCP
                                            </div>
                                        }
                                        value="4"
                                        className={classes2.tab}
                                    />
                                </TabList>
                            </div>
                            <Divider />
                            <TabPanel value="1" className={'connection_buttons'}>
                                {" "}
                                {getBudgets != null ? (
                                    getBudgets[0]?.budgets?.length || getBudgets[1]?.budgets?.length || getBudgets[2]?.budgets?.length > 0 ? (
                                        <BudgetList
                                            onEdit={(data) => {
                                                setBudgetMode("edit");
                                                setEditableData(data);
                                                setOpen(true);
                                            }}
                                            onDelete={onDelete}
                                            data={getBudgets}
                                            showModal={(a, b) => showModal(a, b)}
                                            customerId={customerId}
                                            role={role}
                                        />
                                    ) : (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                textAlign: "center",
                                            }}
                                        >
                                            <span>You currently have no budgets.</span>
                                            <span>
                                                Create custom budgets that will automatically alert when your <img src={Azure_logo} height={16} /> Azure or <img style={{ height: 24, width: 24 }} src={AWS_LOGO} /> AWS or <img src={GCP_ICON} /> GCP
                                                cost exceeds the threshold.
                                            </span>
                                        </div>
                                    )
                                ) : null}
                                <br />
                                {getBudgets != null ? getBudgets[0]?.budgets?.length || getBudgets[1]?.budgets?.length || getBudgets[2]?.budgets?.length > 0 ? <BudgetAlert data={getBudgets} /> : null : null}
                            </TabPanel>
                            <TabPanel value="2">
                                <BudgetSummaryChart cloud={"azure"} tenantId={azuretenantID} customerId={customerId} />
                            </TabPanel>
                            <TabPanel value="3">
                                {" "}
                                <BudgetSummaryChart cloud={"aws"} tenantId={awstenantID} customerId={customerId} />
                            </TabPanel>
                            <TabPanel value="4">
                                {" "}
                                <BudgetSummaryChart cloud={"gcp"} tenantId={gcptenantID} customerId={customerId} />
                            </TabPanel>
                        </TabContext>
                        {/* </div> */}

                        <CreateBudgetDialog
                            classes={classes1}
                            open={open}
                            awsAccountStatus={awsAccountStatus}
                            azureAccountStatus={azureAccountStatus}
                            gcpAccountStatus={gcpAccountStatus}
                            handleClose={() => {
                                handleClose();
                                setBudgetMode("create");
                            }}
                            onCreate={createBudget}
                            onEdit={editBudget}
                            mode={budgetMode}
                            editableData={editableData}
                            currency={Format.formatCurrencySymbol(currency)}
                        />
                    </div>
                )}
            </div>
        </>
    );
}

function CreateBudgetDialog({
    handleClose,
    azureAccountStatus,
    awsAccountStatus,
    gcpAccountStatus,
    open,
    classes,
    cloud = null,
    alertLevel2 = null,
    onCreate = () => {},
    mode,
    budget = {
        name: null,
        amount: null,
        period: null,
    },
    editableData,
    onEdit,
    currency,
}) {
    const [noChange, setNoChange] = useState(true);
    const [cloudProvider, setCloudProvider] = useState(cloud || "");
    const [budgetName, setBudgetName] = useState(budget.name);
    const [budgetAmount, setBudgetAmount] = useState(budget.amount);
    const [budgetPeriod, setBudgetPeriod] = useState(budget.period);
    const [alertLevel, setAlertLevel] = useState(alertLevel2 || 0);
    const [onValidate, setOnValidate] = useState(false);

    const [isEditing, setIsEditing] = useState(false);
    const [visibleAmount, setVisibleAmount] = useState(null);

    useEffect(() => {
        setNoChange(true);
        if (mode === "edit") {
            setCloudProvider(editableData?.cloudProvider);
            setBudgetName(editableData?.budgetName);
            setBudgetAmount(editableData?.budgetAmount);
            setBudgetPeriod(editableData?.budgetPeriod);
            setAlertLevel(editableData?.alertLevel);
            setVisibleAmount(Format.formatNumber(editableData?.budgetAmount) || 0);
        } else {
            beforeClose();
        }
    }, [mode]);

    function onClose() {
        beforeClose();
        handleClose();
    }

    function beforeClose() {
        setCloudProvider(null);
        setBudgetName(null);
        setBudgetAmount(null);
        setBudgetPeriod(null);
        setAlertLevel(0);
        setVisibleAmount(null);
        setOnValidate(false);
    }

    function onBudgetCreate(e) {
        e.preventDefault();
        setOnValidate(true);
        if (validateInputs()) {
            setOnValidate(false);
            onCreate(budgetName, budgetAmount, budgetPeriod, cloudProvider, alertLevel);
        }
    }

    function onBudgetEdit(e) {
        e.preventDefault();
        setOnValidate(true);
        if (validateInputs() && !noChange) {
            setOnValidate(false);
            onEdit(budgetName, budgetAmount, budgetPeriod, cloudProvider, alertLevel);
        } else {
            onClose();
        }
    }

    function validateInputs() {
        if (!(cloudProvider || cloudProvider?.trim())) {
            return false;
        } else if (!(budgetName && budgetName?.trim() && /^[a-zA-Z0-9.\-_\s]{3,25}$/.test(budgetName))) {
            return false;
        } else if (!(budgetAmount && budgetAmount?.toString().trim() && /^-?[\d.]+(?:e-?\d+)?$/.test(budgetAmount))) {
            return false;
        } else if (!budgetPeriod) {
            return false;
        } else if (!alertLevel || alertLevel === 0 || !alertLevel?.toString()?.trim() || alertLevel?.toString()?.trim() === "0") {
            return false;
        } else {
            return true;
        }
    }

    let alertAmount = (alertLevel / 100) * (budgetAmount || 0);
    alertAmount = Number.isNaN(alertAmount) ? 0 : alertAmount;
    alertAmount = alertAmount == 0 ? 0 : alertAmount.toFixed(2);

    return (
        <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true} maxWidth={"sm"} style={{ backgroundColor: Colors.tableHeader }}>
            <DialogTitle style={{ backgroundColor: Colors.tableHeader }} id="customized-dialog-title" onClose={onClose}>
                {" "}
                <b>{mode === "edit" ? "Edit Budget" : "Create Budget"} </b>
            </DialogTitle>
            <DialogContent dividers>
                <div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                        aria-disabled={"false"}
                    >
                        <Form.Label>
                            <b>Select cloud provider :</b>
                        </Form.Label>
                        <RadioGroup onChange={(e) => setCloudProvider(e.target.value)} value={cloudProvider}>
                            <tbody>
                                <tr>
                                    <td>
                                        <FormControlLabel value="azure" control={<Radio />} label="Azure" disabled={!azureAccountStatus || mode === "edit"} />
                                    </td>
                                    <td>
                                        <FormControlLabel value="aws" control={<Radio />} label="AWS" disabled={!awsAccountStatus || mode === "edit"} />
                                    </td>
                                    <td>
                                        <FormControlLabel value="gcp" control={<Radio />} label="GCP" disabled={!gcpAccountStatus || mode === "edit"} />
                                    </td>
                                </tr>
                                <p style={{ color: "red" }}>{onValidate && (cloudProvider?.trim() ? "" : "Cloud Provider is required")}</p>
                            </tbody>
                        </RadioGroup>
                        <Form.Group as={Form.Row}>
                            <Form.Label>
                                <b>Budget name :</b>
                            </Form.Label>
                            {/*<Form.Col sm={10}>*/}
                            <Form.Control
                                name="budgetname"
                                placeholder="Budget Name"
                                value={budgetName}
                                onInput={({ target: { value } }) => {
                                    setNoChange(false);
                                    setBudgetName(value);
                                }}
                            />

                            {/*</Form.Col>*/}
                        </Form.Group>
                        <p style={{ color: "red" }}>
                            {onValidate && (!(budgetName && budgetName?.toString()?.trim()) ? "Please enter Budget Name" : !(budgetName && /^[a-zA-Z0-9.\-_\s]{3,25}$/.test(budgetName)) ? "Please enter Valid Budget Name" : "")}
                        </p>
                        <Form.Group as={Form.Row}>
                            <Form.Label>
                                <b>Budget amount :</b>
                            </Form.Label>
                            {/*<Form.Col sm={10}>*/}
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>{currency}</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    onBlur={({ target: { value } }) => {
                                        if (value && value != null && value != undefined && /^-?[\d.]+(?:e-?\d+)?$/.test(value) && !Number.isNaN(value)) {
                                            try {
                                                const e = parseFloat(value).toFixed(2);
                                                if (e < 0) {
                                                    setBudgetAmount(0);
                                                    setVisibleAmount(0);
                                                } else {
                                                    setBudgetAmount(e);
                                                    setVisibleAmount(Format.formatNumber(e));
                                                }
                                            } catch (e) {
                                                setBudgetAmount(0);
                                                setVisibleAmount(0);
                                            }
                                        } else {
                                            setBudgetAmount(0);
                                            setVisibleAmount(0);
                                        }
                                        // setVisibleAmount(Format.formatNumber(actualAmount));
                                        setIsEditing(false);
                                    }}
                                    name="budgetamount"
                                    // placeholder="12,900.95"
                                    maxLength="13"
                                    // value={budgetAmount}
                                    value={isEditing ? budgetAmount : visibleAmount}
                                    onFocus={() => setIsEditing(true)}
                                    onInput={({ target: { value } }) => {
                                        setNoChange(false);
                                        setBudgetAmount(value);
                                    }}
                                />
                            </InputGroup>
                            {/*</Form.Col>*/}
                        </Form.Group>

                        <p style={{ color: "red" }}>
                            {onValidate &&
                                (!(budgetAmount && budgetAmount?.toString()?.trim()) ? "Please enter Budget Amount" : !(budgetAmount && /^-?[\d.]+(?:e-?\d+)?$/.test(budgetAmount)) ? "Please enter Budget Amount in digit (Only 13 digits)" : "")}
                        </p>
                        <fieldset>
                            <FormControl component="fieldset">
                                <Form.Label>
                                    {" "}
                                    <b>Budget period :</b>
                                </Form.Label>
                                <RadioGroup onChange={(e) => setBudgetPeriod(e.target.value)} value={budgetPeriod}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <FormControlLabel disabled={mode === "edit"} value="daily" control={<Radio />} label="Daily" />
                                            </td>
                                            <td>
                                                <FormControlLabel disabled={mode === "edit"} value="monthly" control={<Radio />} label="Monthly" />
                                            </td>
                                            <td>
                                                <FormControlLabel disabled={mode === "edit"} value="quarterly" control={<Radio />} label="Quarterly" />
                                            </td>
                                            <td>
                                                <FormControlLabel disabled={mode === "edit"} value="yearly" control={<Radio />} label="Yearly" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </RadioGroup>
                            </FormControl>
                            <p style={{ color: "red" }}>{onValidate && (!(budgetPeriod && budgetPeriod?.toString()?.trim()) ? "Budget Period is required" : "")}</p>
                        </fieldset>

                        <Form.Group controlId="formBasicRangeCustom">
                            <Form.Label>
                                <b>Alert level :</b>
                            </Form.Label>
                            <div
                                style={{
                                    display: "flex",
                                    flexdirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Form.Control
                                    style={{ flex: 8, marginRight: 10 }}
                                    type="range"
                                    value={alertLevel}
                                    maxLength="3"
                                    custom
                                    onChange={(e) => {
                                        setNoChange(false);
                                        setAlertLevel(e.target.value);
                                    }}
                                />
                                <Form.Control
                                    size="sm"
                                    style={{ flex: 2, fontSize: 12, padding: 3 }}
                                    type="number"
                                    overflow="hidden"
                                    // readOnly={true}
                                    min={0}
                                    max="100"
                                    placeholder=""
                                    value={alertLevel}
                                    // disabled={(budgetAmount?.test(''))}
                                    onChange={(e) => {
                                        // console.log(e.currentTarget.value)
                                        // console.log(Number.isInteger(e.target.value))
                                        setNoChange(false);

                                        setAlertLevel((s) => Format.formatNumberInt(e.target.value < 0 ? 0 : e.target.value > 100 ? 100 : Number.isNaN(e.target.value) ? s : e.target.value));
                                    }}
                                />
                            </div>
                            <p style={{ color: "red" }}>{onValidate && (!alertLevel || alertLevel === 0 ? "Please select alert level" : "")}</p>
                        </Form.Group>
                        {alertLevel > 0 && budgetAmount !== null && budgetAmount > 0 && budgetAmount.trim() != "" ? (
                            <Card style={{ backgroundColor: Colors.tableHeader, padding: 4 }}>
                                <div>
                                    <span style={{ marginLeft: 10 }}>
                                        <b>Alert threshold:</b>
                                    </span>
                                    <div style={{ marginLeft: 10, marginRight: 10 }}>
                                        You will receive an alert at greater than
                                        <b> ${alertAmount}</b>.
                                    </div>
                                </div>
                            </Card>
                        ) : null}
                    </Form>
                </div>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={mode === "edit" ? onBudgetEdit : onBudgetCreate} color="primary" className={classes.btnStyle}>
                    {mode === "edit" ? "Save Changes" : "Create Budget"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function DeleteBudgetPrompt({ cloud, period, show, handleClose, onYes }) {
    return (
        <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Budget</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Do you want to Delete your {cloud} {period} budget?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    No
                </Button>
                <Button onClick={onYes} variant="primary">
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const useStyles1 = makeStyles()({
    btnStyle: {
        background: "linear-gradient(45deg, #3f51b5 50%, #3f51b5 100%)",
        border: 0,
        borderRadius: 3,
        boxShadow: "0 3px 5px 2px rgba(63, 81, 181, .3)",
        color: "white",
        height: 40,
        padding: "0 40px",
        textTransform: "none",
    },
});

const stylestab = {
    tab: {
        outline: "none",
        textDecoration: "none",
    },
    labelContainer: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        marginBottom: -10,
        marginLeft: -15,
    },

    icon: { width: 18, height: 18, marginRight: 4 },
};
