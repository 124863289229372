import React, { useRef, useEffect, useState } from "react";
import {
  select,
  selectAll,
  scaleOrdinal,
  schemeCategory10,
  csv,
  event,
  scaleLinear,
  max,
  scaleBand,
  axisLeft,
  axisBottom,
  format,
  easeLinear,
  line,
  median,
} from "d3";
import Format from "components/NumberFormat";
import * as d3 from "d3";
import "./chartStyle.css";

function VerticalBarChart({
  title,
  budgetamount,
  threshold,
  currency,
  mouseEffect,
  data,
}) {
  const svgRef = useRef();
  // const [dimention, setDimention] = useState(null);
  const [lval, setLval] = useState("");
  const currencySymbol = Format.formatCurrencySymbol(currency);
  const budgetAmount = Format.formatNumber(budgetamount);
  const budgetThreshold = Format.formatNumber(threshold);

  // var data = [
  //   { label: "March, 2021", line: 100, line2: 150, value: 125.36 },
  //   { label: "February, 2021", line: 100, line2: 150, value: 119.09 },
  //   { label: "January, 2021", line: 100, line2: 150, value: 150.33 },
  //   { label: "December, 2020", line: 100, line2: 150, value: 132.24 },
  //   { label: "November, 2020", line: 100, line2: 150, value: 382.98 },
  //   { label: "October, 2020", line: 100, line2: 150, value: 327.92 },
  //   { label: "September, 2020", line: 100, line2: 150, value: 15.29 },
  //   { label: "August, 2020", line: 100, line2: 150, value: 90.68 },
  //   { label: "July, 2020", line: 100, line2: 150, value: 118.67 },
  //   { label: "June, 2020", line: 100, line2: 150, value: 95.95 },
  //   { label: "May, 2020", line: 100, line2: 150, value: 178.96 },
  //   { label: "April, 2020", line: 100, line2: 150, value: 197.11 },
  //   { label: "March, 2020", line: 100, line2: 150, value: 182.47 },
  //   { label: "February, 2020", line: 100, line2: 150, value: 159.78 },
  // ];

  // const colorScale = scaleOrdinal(schemeCategory10);
  let lgth = 0;
  let longest;
  for (var i = 0; i < data.length; i++) {
    if (data[i]?.label?.length > lgth) {
      let value = data[i].label.length;
      longest = value;
    }
  }

  const margin = {
    top: 50,
    right: 40,
    bottom: 60,
    left: title.length <= 20 ? 50 : 70,
  };
  // let w = window.innerWidth / 1.7;

  const width = 1350;
  const height = 400;
  const chartwidth = width - margin.left - margin.right;
  const chartheight = height - margin.top - margin.bottom;

  // const median = 100;

  // const median1 = 200;

  const VerticalBarChart = (svg) => {
    // const w = svgRef.current;
    // console.log("text length == ", w);
    svg.selectAll("g").remove();
    svg.selectAll("text").remove();

    //  svg.append("g").attr("viewBox", `0 0 ${width} ${height}`); //  for responsive charts

    //  console.log("inner width == ", w);

    const yScale = scaleLinear()
      .range([chartheight, 0])
      .domain([0, max(data, (d) => Number(d.value))])
      .nice();
    const xScale = scaleBand()
      .range([0, chartwidth])
      .domain(data.map((s) => s.label))
      .padding(0.1);

    // const median1 = yScale(d3.median(data, (d) => d.value));
    // console.log("median === ", median1);

    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    chart
      .append("rect")
      .attr("fill", "#fff")
      .attr("width", chartwidth)
      .attr("height", chartheight);

    chart
      .append("g")
      .attr("class", "grid")
      .style("font-Family", "Poppins_SemiBold")
      .call(
        axisLeft(yScale)
          .ticks(4)
          .tickFormat((d) => `${currencySymbol}${d}`)
      );

    chart
      .append("g")
      .attr("transform", `translate(0, ${chartheight})`)
      .call(axisBottom(xScale))
      .selectAll("text")
      .style("font-Family", "Poppins_Regular")

      .attr("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", `rotate(-25)`);
    // .remove();

    var tooltip = svg.append("g").attr("class", "tooltip");

    chart
      .selectAll()
      .data(data)
      .enter()
      .append("rect")
      // .transition()
      //  .duration(1000)
      .attr("x", (s) => xScale(s.label))
      .attr("y", (s) => yScale(s.value))
      .attr("height", (s) => chartheight - yScale(s.value))
      .attr("width", xScale.bandwidth())
      .attr("fill", "#31BEF3")
      .on("mouseenter", function (d, i) {
        if (mouseEffect) {
          select(this).attr();
          select(this)
            .transition()
            .duration(400)
            .attr("width", xScale.bandwidth() + 3)
            .attr("text-anchor", "middle")
            .attr("y", function (d) {
              return yScale(d.value);
            })
            .attr("height", function (d) {
              return chartheight - yScale(d.value) - 3;
            });

          svg
            .append("text")
            .attr("class", "val") // add class to text label
            .attr("text-anchor", "middle")
            .attr("x", function () {
              return xScale(i.label) + xScale.bandwidth() / 2 + margin.left;
            })
            .attr("dx", 0)
            .attr("y", function () {
              return yScale(i.value) + margin.top;
            })
            .attr("dy", () => (i.value < yScale(i.value) ? -2 : 15))
            .style("font-Size", () => (i.value > 10000 ? "10px" : "11px"))
            .text(currencySymbol + Format.formatNumber(i.value));
          // svg.select(this).attr("opacity", 0.5);
        }
      })
      .on("mouseleave", function (d, i) {
        if (mouseEffect) {
          select(this).attr();
          select(this)
            .transition()
            .duration(400)
            .attr("width", xScale.bandwidth())
            .attr("y", function (d) {
              return yScale(d.value);
            })
            .attr("height", function (d) {
              return chartheight - yScale(d.value);
            });

          svg.selectAll(".val").remove();
        }
      });

    // svg
    //   .append("text")
    //   .attr("x", -chartheight / 2 - margin)
    //   .attr("y", margin / 2.4)
    //   .attr("transform", "rotate(-90)")
    //   .attr("text-anchor", "middle")
    //   .text("Cloud cost($)");
    if (!mouseEffect) {
      chart
        .selectAll()
        .data(data)
        .enter()
        .append("text")
        .attr("class", "val") // add class to text label
        .attr("text-anchor", "middle")
        .attr("x", function (d, i) {
          return xScale(d.label) + xScale.bandwidth() / 2;
        })
        .attr("dx", 0)
        .attr("y", function (d, i) {
          return yScale(d.value);
        })
        .attr("dy", (d) => (d.value < yScale(d.value) ? -2 : 15))
        .style("font-Size", (d) => (d.value > 10000 ? "10px" : "11px"))
        .text((d) => `${currencySymbol}` + Format.formatNumber(d.value));
    }
    // var legendElement = chart
    //   .append("g")
    //   .attr("class", "legend__container")
    //   .attr(
    //     "transform",
    //     `translate(${chartwidth},${
    //       margin.right - 2 * margin.bottom - 2 * margin.top
    //     })`
    //   )
    //   .selectAll("g.legend__element")
    //   .data(xScale.domain())
    //   .enter()
    //   .append("g")
    //   .attr("transform", (d, i) => `translate(${15},${i * 15})`);

    // legendElement
    //   .append("text")
    //   .attr("x", 25)
    //   .attr("font-size", "12px")
    //   .text((d) => d);

    // legendElement
    //   .append("rect")
    //   .attr("x", -10)
    //   .attr("y", -190)
    //   .attr("width", 15)
    //   .attr("height", 15)
    //   .attr("fill", (d, i) => colorScale(i));

    svg
      .append("text")
      .attr("x", margin.left + margin.top)
      .attr("y", 25)
      .attr("text-anchor", "middle")
      .style("font-Family", "Poppins_SemiBold")
      .text(title);

    svg
      .append("text")
      .attr("x", chartwidth / 1.7 + 1 * margin.top)
      .attr("y", 25)
      .attr("text-anchor", "start")
      .style("font-Family", "Poppins_SemiBold")
      .text("Budget Amount " + currencySymbol + budgetAmount);

    svg
      .append("text")
      .attr("x", chartwidth / 1.7 + 6 * margin.top)
      .attr("y", 25)
      .attr("text-anchor", "start")
      .style("font-Family", "Poppins_SemiBold")
      .text("Budget Threshold " + budgetThreshold + "%");

    //---------------------- line draw------------------------

    var line = d3
      .line()
      .x(function (d, i) {
        return xScale(d.label) + xScale.bandwidth() / 2;
      })
      .y(function (d) {
        return yScale(d.line);
      })
      .curve(d3.curveMonotoneX);

    var line2 = d3
      .line()
      .x(function (d, i) {
        return xScale(d.label) + xScale.bandwidth() / 2;
      })
      .y(function (d) {
        return yScale(d.line2);
      })
      .curve(d3.curveMonotoneX);

    chart
      .append("path") // 11. Calls the line generator
      .attr("stroke", "#FF0000") // Assign a class for styling
      .attr("stroke-width", 1.5)
      .attr("d", line(data)); // 11. Calls the line generator

    chart
      .append("path") // 11. Calls the line generator
      .attr("stroke", "green") // Assign a class for styling
      .attr("stroke-width", 1.5)
      .attr("d", line2(data)); // 11. Calls the line generator

    // svg
    //   .append("line")
    //   .attr("stroke", "#953159")
    //   .attr("x1", margin.left)
    //   .attr("y1", median)
    //   .attr("x2", width)
    //   .attr("y2", median);

    // svg
    //   .selectAll()
    //   .data(data)
    //   .enter()
    //   .append("text")
    //   .attr("transform", `translate(${width}, ${margin.top})`)
    //   .attr("dy", "-0.5em")
    //   .attr("text-anchor", "end")
    //   .style("fill", "#953159")
    //   .html(+data[0].line);

    svg
      .selectAll()
      .data(data)
      .enter()
      .append("rect")
      .style("fill", "green")
      .attr("width", 15)
      .attr("height", 15)
      .attr("x", chartwidth - 240)
      .attr("y", 12);

    svg
      .selectAll()
      .data(data)
      .enter()
      .append("rect")
      .style("fill", "red")
      .attr("width", 15)
      .attr("height", 15)
      .attr("x", chartwidth / 1.7 + 1 * margin.top - 20)
      .attr("y", 12);
    // .attr("x", 770)
    // .attr("y", 12);

    // svg
    //   .append("rect")
    //   .attr("transform", `translate(${width - 20}, ${margin.top})`)
    //   .attr("dy", "-0.5em")
    //   .style("fill", "#953159")
    //   .html(+data[0].line);

    // svg
    //   .append("text")
    //   .attr("class", "label")
    //   .attr("x", chartwidth / 2 + margin)
    //   .attr("y", chartheight + margin * 1.7)
    //   .attr("text-anchor", "middle")
    //   .text("Services");
  };

  useEffect(() => {
    const svg = select(svgRef.current);
    VerticalBarChart(svg);
  }, [data]);
  return (
    <div>
      <svg ref={svgRef} width={width} height={height} />
    </div>
  );
}

export default VerticalBarChart;
