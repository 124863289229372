import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "MakeWithStyles";
import { useSelector, useDispatch } from "react-redux";
import Azure_cosmosDB_icon from "../../images/newCloudTrakrIcons/AzureCosmosDB.png";
import Format from "components/NumberFormat";
import moment from "moment";
import {
    getAzureCosmosDBRequest,
    getAzureCosmosDBSubRequest,
    getAzureCosmosDBMetricsRequest,
    getAzureCosmosDBDetailsRequest,
    getAzureCosmosDBChartDataRequest,
    getAzureCosmosDBSub1YTableDataRequest,
    getAzureCosmosDB1YTableDataRequest, getAzureCosmosDBSub3MMetricRequest, getAzureCosmosDB3MMetricRequest,
    getAzureCosmosDBTenantRequest
} from "../../redux/actions/actions";
import TablePagination from "@mui/material/TablePagination";
import { Tooltip, OverlayTrigger, Popover } from "react-bootstrap";
import DetailsDialogBox from '../../components/DetailsDialogBox';
import { Table, TableBody, TableCell as TCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Divider, LinearProgress,CircularProgress } from "@mui/material";
import TablePaginationActions from "../../components/TablePaginationActions";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import AzureCosmosDB1YTable from "./AzureCosmosDB1YTable";
import TableSearch from "../../components/TableSearch";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AzureCosmosDB3MMetricTable from "./AzureCosmosDB3MMetricTable";
import TableSortHeader from "../../components/TableSortHeader";
import {ALPHA_NUM_SORTER, isNumeric} from "../../util/Miscellaneous";
import {TABLE_SORT} from "../../util/AppConstants";
const useStyles = makeStyles()((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
}));

function TableCell(props) {
    return (
        <TCell {...props} style={{ padding: 5 }}>
            {props.children}
        </TCell>
    );
}

export default function AzureCosmosDB({customerId,tenantId=null,subscriptionId=null,period, currency }) {
    const dispatch = useDispatch();
    const {classes} = useStyles();
    const [progress, setProgress] = useState(0);
    const [selected, setSelected] = React.useState([]);
    const [list, setList] = useState(null);
    const [total, setTotal] = useState("");
    const [cosmosData, setCosmosData] = useState([]);
    const [cosmosMetricsData, setCosmosMetricsData] = useState([]);
    const [cosmosMetricsFormatedData, setCosmosMetricsFormatedData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [showMetricSpecific, setShowMetricSpecific] = useState([]);

    const [showingDetails, setShowDetails] = useState(null);
    const [metricTotals, setMetricTotals] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [tableWidth, setTableWidth] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const TABLE_SORT_DEFAULT_COLUMN = 'cost';
    const [ytdSearch, setYTDSearch] = useState(null);
    const [order, setOrder] = React.useState(TABLE_SORT.ORDER_DESC);
    const [orderBy, setOrderBy] = React.useState(TABLE_SORT_DEFAULT_COLUMN);

    let azureCosmosDB = useSelector((state) => subscriptionId ? state?.azureCosmosDBSubReducer?.cosmosDBSubData:(tenantId)?state?.azureCosmosDBReducer?.cosmosDBTenantData:state?.azureCosmosDBReducer?.cosmosDBData);

    let loading = useSelector((state) => subscriptionId? state?.azureCosmosDBSubReducer?.loading:state?.azureCosmosDBReducer?.loading);


    useEffect(() => {
        if (subscriptionId) {
            dispatch(getAzureCosmosDBSubRequest({
                customerId,
                subscriptionId,
                tenantId,
            }));
            dispatch(getAzureCosmosDBSub1YTableDataRequest({
                customerId,
                subscriptionId,
                tenantId,
            }));
            dispatch(getAzureCosmosDBSub3MMetricRequest({
                customerId,
                subscriptionId,
                tenantId,
            }));
        } else if(tenantId){
            if(azureCosmosDB==null){
            dispatch(
                getAzureCosmosDBTenantRequest({
                    customerId,
                    tenantId,
                })
            );
            }
        }else {
            if(azureCosmosDB==null) {
                dispatch(getAzureCosmosDBRequest({
                    customerId,
                }));
                dispatch(getAzureCosmosDB3MMetricRequest({ customerId }));
            }
            dispatch(getAzureCosmosDB1YTableDataRequest({customerId}));
        }
    }, [customerId, subscriptionId,tenantId]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, [subscriptionId,tenantId]);

    function azureCosmosDBMetrics(subscriptionId, resourceGroupName, accountName) {
                dispatch(
                    getAzureCosmosDBDetailsRequest({
                        customerId,
                        subscriptionId,
                        resourceGroupName,
                        accountName,
                    })
                );

                dispatch(
                    getAzureCosmosDBMetricsRequest({
                        customerId,
                        subscriptionId,
                        resourceGroupName,
                        accountName,
                    })
                );
                dispatch(
                    getAzureCosmosDBChartDataRequest({
                        customerId,
                        subscriptionId,
                        resourceGroupName,
                        accountName,
                    })
                );
           
    }

    let azureCosmosDBDetailData = useSelector((state) => state?.azureCosmosDBMetricsReducer?.cosmosDBDetailsData?.azureCosmosDbAccountDetails[0]);
    let azureCosmosDBMetricsData = useSelector((state) => state?.azureCosmosDBMetricsReducer?.cosmosDBData);
    let cosmosDBChartData = useSelector((state) => state?.azureCosmosDBMetricsReducer?.cosmosDBChartData?.metrics);

    let detailsLoading = useSelector((state) => state?.azureCosmosDBMetricsReducer?.chartLoading || state?.azureCosmosDBMetricsReducer?.chartLoading);

    useEffect(() => {
        
            dataMetricsConversion(azureCosmosDBMetricsData);
            chartDataConversion(cosmosDBChartData);
        
    }, [azureCosmosDBMetricsData, cosmosDBChartData]);

    function dataMetricsConversion(data) {
        var final = {};
        for (var key in data?.metrics) {
            final[key] = data?.metrics[key]?.value[0];
            final[key] = final[key]?.timeseries.map((t) => {
                const data = {};
                var dataKey = new Set(Object.keys(t.data[0]));
                dataKey.delete("timeStamp");
                dataKey = dataKey.keys().next().value;
                data[dataKey] = t.data[0][dataKey];

                data["name"] = t?.metadatavalues[0]?.value;
                data["unit"] = final[key]?.unit;
                return data;
            });
        }
        setCosmosMetricsData(final);
    }

    function chartDataConversion(showingMetrics) {
        var result = [];
        for (var obj in showingMetrics) {
            const selected = showingMetrics[obj];

            const name = selected.value?.[0]?.name?.localizedValue;
            const unit = selected.value?.[0]?.unit;

            var metricData = selected.value?.[0]?.timeseries?.[0]?.data?.[0];
            var timeseries = selected.value?.[0]?.timeseries?.[0]?.data;
            
            var dataKey  = null;
            var dataKeyT = null;

            if (timeseries && timeseries?.length) {
                dataKey = new Set(Object.keys(metricData));
                dataKey.delete("timeStamp");
                dataKey = dataKey.keys().next().value;

                dataKeyT = new Set(Object.keys(timeseries?.[0]));
                dataKeyT.delete("timeStamp");
                dataKeyT = dataKeyT.keys().next().value;

                if(dataKeyT){
                    timeseries = timeseries.map(({ timeStamp, ...rest }) => {
                        let date = new Date(timeStamp);
                        date = date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth()+1) : (date.getMonth()+1)) + "-" + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
                        return { date, value: rest[dataKeyT] };
                    });

                    timeseries = sumDuplicates(timeseries, "date", "value").map(({ date, ...rest }) => ({ ...rest, date: new Date(date) }));
                    metricData = { timeseries, unit, total: metricData[dataKey] };
                }
            }
            if(dataKey && dataKeyT){
                result.push({ name, unit, metricData, key: obj });
            }
        }

        setChartData(result);
        setMetricTotals(
            result.map((r, i) => {
                return (r?.metricData?.timeseries.map((r) => r.value).reduce((a, b) => a + b) / (r?.unit?.toLowerCase() === "percent" ? r?.metricData?.timeseries.length : 1)).toFixed(2);
            })
        );
    }

    useEffect(() => {
        window.$(document).ready(() => {
            rootLoaded();
            // console.log((document.querySelector('#root_dashboard')));
        });
    }, []);

    window.$(document).ready(rootLoaded);

    function rootLoaded() {
        if (tableWidth) return;
        const e = window.$("#root_dashboard");
        try {
            setTableWidth(e.width());
        } catch (e) {}
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === TABLE_SORT.ORDER_ASC;
        setOrder(isAsc ? TABLE_SORT.ORDER_DESC : TABLE_SORT.ORDER_ASC);
        setOrderBy(property);
    };

    function filterTable(s){
        if (ytdSearch && ytdSearch.toString().length) {
            return !!TableSearch.doesItIncludes(
                [
                    s?.resourceId,
                    s.subscriptionId,
                    s.subscriptionName,
                    s.resourceGroupName,
                    s.resourceType,
                    s.location,
                ],
                ytdSearch
            );
        }
        return true;
    }

    function sortTable(r1,r2) {
        let fi = ({
            account: ALPHA_NUM_SORTER.compare(r1?.resourceId, r2?.resourceId),
            subscription: ALPHA_NUM_SORTER.compare(r1?.subscriptionId, r2?.subscriptionId),
            resource_group: ALPHA_NUM_SORTER.compare(r1?.resourceGroupName, r2?.resourceGroupName),
            type: ALPHA_NUM_SORTER.compare(r1?.resourceType, r2?.resourceType),
            location: ALPHA_NUM_SORTER.compare(r1?.location, r2?.location),
            cost:  r1.billing?.map((b) => parseFloat(b.cost))?.reduce((a, b) => a + b, 0) - r2.billing?.map((b) => parseFloat(b.cost))?.reduce((a, b) => a + b, 0)
        });
        return fi[orderBy] * order;
    }

    const subscriptionColumn = subscriptionId?'null_column':{ numeric:0, align: 'left', id:'subscription', label: 'Subscription'};

    return loading ? (
        <LinearProgress variant="determinate" value={progress} />
    ) : (
        <div id={"root_dashboard"} onLoad={rootLoaded}>
            <ContainerCardWithDateRange
                title={"Azure CosmosDB"}
                titleIcon={Azure_cosmosDB_icon}
                defaultPeriod={"1M"}
                showDateRange={true}
                collapsible={true}
                datePeriod={({ period }) => ({
                    start: "__",
                    end: "__",
                    rawStart: moment(azureCosmosDB?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(azureCosmosDB?.[period]?.endDate, "YYYYMMDD"),
                })}
                totalCost={({ period }) => currency + Format.formatNumber(azureCosmosDB?.[period]?.resources?.map((t) => parseFloat(t.cost)).reduce((a, b) => a + b, 0))}
                datePeriodEnabled={true}
                showDatePeriod={(period) => !!azureCosmosDB != null && azureCosmosDB?.[period]?.resources?.length}
                onPeriodChange={() => handleChangeRowsPerPage({ target: { value: 10 } })}
            >
                {({ period }) => (
                    <div elevation={10} style={{ padding: 10 }}>
                        {azureCosmosDB != null && azureCosmosDB?.[period]?.resources?.length ? (
                            <>
                                <Box>
                                    <Box>
                                        <TableSearch onSearch={setYTDSearch} />
                                        <TableContainer component={Paper}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead style={{ backgroundColor: "#cfdac8" }}>
                                                    <TableRow>
                                                        <TableSortHeader
                                                            classes={classes}
                                                            numSelected={0}
                                                            order={order}
                                                            orderBy={orderBy}
                                                            onRequestSort={handleRequestSort}
                                                            rowCount={azureCosmosDB?.[period]?.resources?.length}
                                                            headCells={[
                                                                { numeric:0, align: 'left', id:'account', label: 'Account name'},
                                                                subscriptionColumn,
                                                                { numeric:0, align: 'left', id:'resource_group', label: 'Resource Group'},
                                                                { numeric:0, align: 'left', id:'type', label: 'Type'},
                                                                { numeric:0, align: 'left', id:'location', label: 'Location'},
                                                                { numeric:1, align: 'left', id:'cost', label: 'Cost'},
                                                            ].filter(f=>f!='null_column')}
                                                        />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {azureCosmosDB?.[period]?.resources
                                                        ?.filter(filterTable)
                                                        ?.sort(sortTable)
                                                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        ?.map((row, index) => (
                                                            <TableRow key={row?.resourceId + index}>
                                                                <TableCell component="th" scope="row">
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <img src={Azure_cosmosDB_icon} height={26} style={{ marginRight: 4 }} />{" "}
                                                                        <a
                                                                            href={"#"}
                                                                            onClick={() => {
                                                                                setShowDetails(row);
                                                                                setOpen(true);

                                                                                azureCosmosDBMetrics(row?.subscriptionId||azureCosmosDB?.subscriptionId, row?.resourceGroupName, row?.resourceId);
                                                                            }}
                                                                        >
                                                                            {row?.resourceId}
                                                                        </a>
                                                                    </div>
                                                                </TableCell>

                                                                {subscriptionId?null:<TableCell align="left">
                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        overlay={
                                                                            <Popover id="popover-contained">
                                                                                <Popover.Body
                                                                                    style={{fontSize: 11}}>{row?.subscriptionId}</Popover.Body>
                                                                            </Popover>
                                                                        }
                                                                    >
                                                                        <div>{row?.subscriptionName || azureCosmosDB?.subscriptionName}</div>
                                                                    </OverlayTrigger>
                                                                </TableCell>}

                                                                <TableCell align="left"> {row?.resourceGroupName}</TableCell>

                                                                <TableCell align="left">{row?.resourceType}</TableCell>
                                                                <TableCell align="left">{row?.location}</TableCell>

                                                                <TableCell align="right">
                                                                    {currency}
                                                                    {Format.formatNumber(Number(row.cost))}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                    <Box></Box>
                                </Box>
                                <TablePagination
                                    ActionsComponent={TablePaginationActions}
                                    rowsPerPageOptions={[10, 25, 50, 75]}
                                    component="div"
                                    count={azureCosmosDB?.[period]?.resources?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </>
                        ) : (
                            <Typography style={{ display: "flex", justifyContent: "center" }}>No data found</Typography>
                        )}

                        <DetailsDialogBox
                            open={open}
                            data={showingDetails}
                            title={'Azure cosmosDB'}
                            detailsData={azureCosmosDBDetailData}
                            metricData={cosmosMetricsData}
                            chartData={chartData}
                            handleClose={() => {
                                handleClose();
                            }}
                            currency={currency}
                            loading={detailsLoading}
                            metricTotals={metricTotals}
                            rawStart={moment(azureCosmosDB?.[period]?.startDate, "YYYYMMDD")}
                            rawEnd={moment(azureCosmosDB?.[period]?.endDate, "YYYYMMDD")}
                        />
                    </div>
                )}
            </ContainerCardWithDateRange>
            <br/>
            {/*A B Q   A&B    A|B     !(A|B)     (A&B)|!(A|B)*/}
            {/*0 0 1   0       0       1           1*/}
            {/*1 1 1   1       1       0           1*/}
            {/*1 0 0   0       1       0           0*/}
            {/*0 1 0   0       1       0           0*/}
            {/*subscriptionId = true  tenantId = true  then SHOW*/}
            {/*subscriptionId = false tenantId = false then SHOW*/}
            {/*subscriptionId = false tenantId = true  then NOSHOW*/}
            {/*subscriptionId = true  tenantId = false then NOSHOW*/}
            {((subscriptionId && tenantId) || !(subscriptionId || tenantId)) && <AzureCosmosDB1YTable subscriptionId={subscriptionId} tenantId={tenantId} width={tableWidth}
                                   currency={currency}/>}
            <br/>
            {((subscriptionId && tenantId) || !(subscriptionId || tenantId)) && <AzureCosmosDB3MMetricTable classes={classes} currency={currency} tenantId={tenantId}
                                         subscriptionId={subscriptionId}/>}
        </div>
    );
}

function sumDuplicates(data, checkKey, valueKey) {
    var result = [];
    data.forEach(function (a) {
        if (!this[a[checkKey]]) {
            const data = {};
            data[checkKey] = a[checkKey];
            data[valueKey] = 0;
            // this[a.date] = { date: a.date, value: 0 };
            this[a[checkKey]] = data;
            result.push(this[a[checkKey]]);
        }
        this[a[checkKey]][valueKey] += a[valueKey];
    }, Object.create(null));
    return result;
}
