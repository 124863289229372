import React from "react";
import GetWindowDimension from "components/HOC/GetWindowDimension";
import ContentLoader, { List } from "react-content-loader";

function ReportLoader(props) {
    const { width, height } = props;
    return (
        <div>
            <ContentLoader viewBox="0 0 400 160" height={height} width={width} backgroundColor={"#DDDDDD"} foregroundColor={"#A2D2FF"} {...props}>
                <circle cx="180" cy="86" r="3" />
                <circle cx="199" cy="86" r="3" />
                <circle cx="216" cy="86" r="3" />
            </ContentLoader>
        </div>
    );
}
export default GetWindowDimension(ReportLoader);
