import { Box, LinearProgress, Typography } from "@mui/material";
import axios from "connection/axios";
import LineChart from "pages/Charts/LineChart";
import React, { useEffect, useState } from "react";
import { bytesToSize, convertToInternationalCurrencySystem } from "util/Miscellaneous";
const { REACT_APP_AWS_S3_MATRIC_DATA } = process.env;

export default function MatricLevelChart(props) {
    const { customerId, tenantId, bucketName, bucketRegion } = props;
    const [showingMetricDetails, setShowingMetricDetails] = useState(null);
    const [metricTotals, setMetricTotals] = useState(null);
    const [showMetricSpecific, setShowMetricSpecific] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setShowingMetricDetails(null);
        setMetricTotals(null);
        return axios
            .post(REACT_APP_AWS_S3_MATRIC_DATA, {
                customerId,
                bucketName,
                tenantId,
                bucketRegion,
            })
            .then((response) => response.data)
            .then((response) => {
                if (response?.data) {
                    const final = [];
                    for (var o in response?.data) {
                        if (Array.isArray(response?.data?.[o]) && response?.data?.[o].length > 0) {
                            const data = response?.data?.[o]?.[0];
                            if ("Timestamps" in data && "Values" in data) {
                                const timeseries = data.Timestamps.map((t, i) => ({
                                    date: new Date(t),
                                    value: data?.Values[i],
                                }));
                                const label = data.Label.match(/[A-Z][a-z]+/g);
                                const unit = data.Id;
                                final.push({ label, timeseries, unit: unit === "s3" ? "Count" : unit });
                            }
                        }
                    }
                    setShowingMetricDetails(final);
                    setMetricTotals(final.map((r, i) => (r?.timeseries.map((r) => r.value).reduce((a, b) => a + b) / 1).toFixed(2)));
                }
            })
            .finally(() => setLoading(false));
    }, [customerId, tenantId, bucketName, bucketRegion]);
    return !loading ? (
        <div>
            {showingMetricDetails && showingMetricDetails?.length
                ? showingMetricDetails.map((m, i) => (
                      <>
                          <Box>
                              <Typography style={{fontWeight: 'bold'}}>Bucket size in GB</Typography>
                              <LineChart
                                  onMouseOverData={(data) => {
                                      let d = showMetricSpecific;
                                      d[i] = data;
                                      setShowMetricSpecific(d);
                                  }}
                                  data={m?.timeseries}
                                  currency={"USD"}
                                  currencyRequired={false}
                                  unit={m?.unit}
                              />
                          </Box>
                          <Box style={{ marginTop: 10 }}>
                              <Typography>
                                  {m.label}:
                                  {
                                      {
                                          Count: convertToInternationalCurrencySystem(showMetricSpecific?.[i] || metricTotals?.[i]),
                                          Bytes: bytesToSize(showMetricSpecific?.[i] || metricTotals?.[i]),
                                          Percent: (showMetricSpecific?.[i] || metricTotals?.[i]) + "%",
                                          Milliseconds: convertToInternationalCurrencySystem(showMetricSpecific?.[i] || metricTotals?.[i]) + " ms",
                                      }[m?.unit]
                                  }
                              </Typography>
                          </Box>
                          <hr />
                      </>
                  ))
                : null}
        </div>
    ) : (
        <LinearProgress />
    );
}
