import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import {
  getConnectionErrorSuccess,
  getConnectionErrorFailure,
} from "../actions/actions";
const { REACT_APP_GET_CONNECTION_ERROR } = process.env;

function* getConnectionErrorData({ payload }) {
  try {
    const res = yield call(axios.get, REACT_APP_GET_CONNECTION_ERROR, {
      params: {
        customer_id: payload,
      },
    });

    const { statusCode } = res.data;
    if ("statusCode" in res.data) {
      if (statusCode !== "200") {
        yield put(getConnectionErrorFailure(statusCode));
      } else {
        yield put(getConnectionErrorSuccess(res.data));
      }
    } else {
      yield put(getConnectionErrorFailure(res));
    }
  } catch (error) {
    yield put(getConnectionErrorFailure(error));
  }
}

export default function* connectionErrorSaga() {
  yield takeLatest(types.GET_CONNECTIONERROR_REQUEST, getConnectionErrorData);
}
