import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import {
  getBudgetSummarySuccess,
  getBudgetSummaryFailure,
} from "../actions/actions";
const { REACT_APP_BUDGET_SUMMARY } = process.env;

function* getBudgetsSummaryData({ payload }) {
  // const { customerId, tenantID, budgetLevel, budgetType } = payload;
  try {
    const res = yield call(axios.get, REACT_APP_BUDGET_SUMMARY, {
      params: {
        customerId: payload.customerId,
        tenantId: payload.tenantID,
        budgetLevel: payload.budgetLevel,
        budgetType: payload.budgetType,
      },
    });

    const { statusCode } = res.data;

    if ("statusCode" in res.data) {
      if (statusCode !== 200) {
        yield put(getBudgetSummaryFailure(statusCode));
      } else {
        yield put(getBudgetSummarySuccess(res.data));
      }
    } else {
      yield put(getBudgetSummaryFailure(res));
    }
  } catch (error) {
    yield put(getBudgetSummaryFailure(error));
  }
}

export default function* getBudgetSummarySaga() {
  yield takeLatest(types.GET_BUDGET_SUMMARY_REQUEST, getBudgetsSummaryData);
}
