import * as types from "../actions/types";

const userState = {
  managementGroups: null,
  mgmtHome: null,
  loading: false,
  error: null,
};

function azureManagementGroupReducer(state = userState, { type, payload }) {
  switch (type) {
      case "SWITCH_TEAM_CLEAR": {
          return {
              ...state,
              managementGroups: null,
              mgmtHome: null,
              loading: false,
              error: null,
          };
      }
      case types.GET_AZURE_MANAGEMENT_REQUEST: {
          return {
              ...state,
              managementGroups:null,
              loading: true,
          };
      }
      case types.GET_AZURE_MANAGEMENT_SUCCESS: {
          if("tenantId" in payload.data){
            return {
                ...state,
                managementGroups: payload.data,
                loading: false,
            };
          }else {
            return {
                ...state,
                mgmtHome: payload.data,
                loading: false,
            };
          }
      
      }
      case types.GET_AZURE_MANAGEMENT_FAILURE: {
          return {
              ...state,
              loading: false,
              managementGroups: null,
              error: payload,
          };
      }

      default:
          return state;
  }
}
export default azureManagementGroupReducer;
