import React, {useEffect, useRef, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "MakeWithStyles";
import { Table, TableBody, TableCell as TCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Divider, LinearProgress } from "@mui/material";
import storage_account_icon from "../../images/resources/Storage_Account.svg";
import AWSRecommendations from "../recommendations/AWSRecommendations";
import AzureRecommendation from '../../../src/pages/recommendations/AzureRecommendation';
import Format from "components/NumberFormat";
import TabContext from "@mui/lab/TabContext";
import Colors from "../../styles/color";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import Azure_logo from "../../images/Azure_logo.svg";
import AWS_LOGO from "../../images/aws_dark.svg";
import GCP_ICON from "../../images/GCP_logo.svg";
import TabPanel from "@mui/lab/TabPanel";
import ListAWSAccount from "../Accounts/aws/ListAWSAccount";
import ContainerCardWithDateRange from "../../components/ContainerCardWithDateRange";
import AzureLogo from "../../images/Azure_logo.svg";
import GCPRecommendation from "../recommendations/GCPRecommendation";
import axios from "../../connection/axios";
import AWSTrustedAdvisor from "../recommendations/AWSTrustedAdvisor";
// import { func } from "prop-types";

const {REACT_APP_ALL_CURRENT_LAST_MONTH_SPENDING, REACT_APP_LINKED_ACCOUNTS} = process.env;

const stylestab = {
    tab: {
        outline: "none",
        textDecoration: "none",
    },
    labelContainer: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        marginBottom: -10,
        marginLeft: -15,
    },

    icon: { width: 18, height: 18, marginRight: 4 },
};

const useStyles = makeStyles()((theme) => ({
    root: {
        backgroundColor: Colors.tableHeader,
        padding: theme.spacing(0.2),
    },

    tableRow: {
        fontWeight: "normal",
    },
    tab: {
        textTransform: "none",
    }
}));

function AllCloudRecommendation(props) {
    const { customerId,progress} = props;
    const azureRef = useRef({});
    const { currency } = useSelector((state) => ({
        currency: state.allHomeReducer.currency
    }));
    const [value, setValue] = useState("2");
    const [loading, setLoading] = useState(false);
    const [lastMonth, setLastMonth] = useState(null);
    const [linkedAccounts, setLinkedAccounts] = useState(null);

    const {classes} = useStyles();

    function handleChange(e,v){
        setValue(v);
    }

    function getLastMonthSpending(){
        setLoading(true);
        axios.get(REACT_APP_ALL_CURRENT_LAST_MONTH_SPENDING, {
            params: {
                customerId
            }
        })
        .then(d=>d.data)
        .then(data=>{
            const cloud = {};
            data.data.LastMonth.dataSet.map(l=>cloud[l.label] = parseFloat(l.value));
            setLastMonth(cloud);
        })
        .finally(t=>{
            setLoading(false);
        })
    }

    useEffect(()=>{
        if(lastMonth){
            if(lastMonth.Azure > lastMonth.AWS){
                setValue("3");
            } else {
                setValue("2");
            }
        }
    }, [lastMonth])

    useEffect(()=>{
        getLinkedAccounts();
    },[]);

    async function getLinkedAccounts() {
        axios.get(REACT_APP_LINKED_ACCOUNTS, {
            params: { customerId },
        })
            .then((response) => response.data)
            .then((response) => {
                setLinkedAccounts(response.response);
            })
    }


    return  (
        <div>
            <TabContext value={value}>
                <div
                    style={{
                        backgroundColor: Colors.background,
                    }}
                    position="static"
                >
                    <TabList value={value} onChange={handleChange} aria-label="simple tabs example" textColor="primary" indicatorColor="primary">
                        {/*
                        <Tab
                            style={stylestab.tab}
                            label={
                                <div className={classes.labelContainer}>
                                    {/*<img style={stylestab.icon} src={Azure_logo} />*/}
                                    {/* Summary */}
                                {/* </div> */}
                            {/* } */}
                            {/* value="1" */}
                            {/* className={classes.tab} */}
                        {/* /> */}
                        <Tab
                            style={stylestab.tab}
                            label={
                                <div className={classes.labelContainer}>
                                    <img style={stylestab.icon} src={AWS_LOGO} />
                                    AWS Recommendations
                                </div>
                            }
                            value="2"
                            className={classes.tab}
                        />
                        <Tab
                            style={stylestab.tab}
                            label={
                                <div className={classes.labelContainer}>
                                    <img style={stylestab.icon} src={Azure_logo} />
                                    Azure Recommendations
                                </div>
                            }
                            value="3"
                            className={classes.tab}
                        />
                        <Tab
                            style={stylestab.tab}
                            label={
                                <div className={classes.labelContainer}>
                                    <img style={stylestab.icon} src={GCP_ICON} />
                                    GCP Recommendations
                                </div>
                            }
                            value="4"
                            className={classes.tab}
                        />
                        <Tab
                            hidden={linkedAccounts==null || linkedAccounts?.aws?.length==0}
                            style={stylestab.tab}
                            // style={{width: 150}}
                            label={
                                <div className={classes.labelContainer} style={{whiteSpace: "nowrap"}}>
                                    <img style={stylestab.icon} src={AWS_LOGO} />
                                    AWS Trusted Advisor
                                </div>
                            }
                            value="5"
                            className={classes.tab}
                        />
                    </TabList>
                </div>
                <Divider />

                {/* <TabPanel value="1">
                    <br/>
                    <AWSRecommendations extraRef={azureRef} cloud={"all"} currency={Format.formatCurrencySymbol(currency)} subscriptionId={"all"} customerId={customerId} />
                    <br />
                    <AzureRecommendation showrefs={[

                    ]}
                        showCards={false} showCollapseButton={false} currency={currency} collapseRef={azureRef} customerId={customerId} progress={progress} />
                    <br/>
                    <GCPRecommendation collapseRef={azureRef} customerId={customerId} progress={progress} />
                </TabPanel> */}
                <TabPanel value="2">
                    <br/>
                    <AWSRecommendations extraRef={azureRef} cloud={"all"} currency={Format.formatCurrencySymbol(currency)} subscriptionId={"all"} customerId={customerId} />
                </TabPanel>
                <TabPanel value="3">
                    <br />
                    <AzureRecommendation currency={Format.formatCurrencySymbol(currency)} collapseRef={azureRef} customerId={customerId} progress={progress} />
                </TabPanel>
                <TabPanel value="4">
                    <GCPRecommendation customerId={customerId} progress={progress} />
                </TabPanel>
                <TabPanel value="5">
                    <AWSTrustedAdvisor linkedAccounts={linkedAccounts} currency={currency} customerId={customerId} progress={progress} />
                </TabPanel>
            </TabContext>

            {/*<AWSRecommendations extraRef={azureRef} cloud={"all"} currency={Format.formatCurrencySymbol(currency)} subscriptionId={"all"} customerId={customerId} />*/}
            {/*<br />*/}
            {/*<AzureRecommendation collapseRef={azureRef} customerId={customerId} progress={progress} />*/}
        </div>
    );

}
export default AllCloudRecommendation;
