import React, { useState, useEffect, useRef } from "react";
import * as moment from "moment";
import "../Accounts/GCP/Gcp.css";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import axios from "../../connection/axios";
import { useSelector, useDispatch } from "react-redux";
import GCP_ICON from "../../images/GCP_logo.svg";
import gcp_billing from "../../images/gcp_billing.svg";
import "../Charts/style.css";
import CostHeader from "components/CostHeader";
import Format from "../../components/NumberFormat";
import { accountRequest, gcpServerlessRequest, getSubscriptionsRequest } from "../../redux/actions/actions";
import { LinearProgress, Link, Typography, Paper, Grid, Card, TextField } from "@mui/material";
import ConenctAccountSkipPage from "pages/ConnectAccountSkipPage";
import { Autocomplete } from "@mui/lab";
import BreadCrumbs from "../../components/BreadCrumbs";
import Color from "../../styles/color";
import GcpHomeTab from "components/tab/GcpHomeTab";
import GcpBillingTab from "components/tab/GcpBillingTab";
import GCPProjectHome from "../../components/tab/GCPProjectHome";
import DashboardLoader from "components/loaders/DashboardLoader";

import ReactPlayer from "react-player";
import Carousel from "react-bootstrap/Carousel";

import backgroundImage from "../../images/bg1.jpg";
import backgroundImage1 from "../../images/bg2.jpg";
import backgroundImage2 from "../../images/bg3.jpg";
import backgroundImage3 from "../../images/bg4.jpg";

import {makeStyles} from "MakeWithStyles";
import RoutesList from "../../routes/RoutesList";

const { REACT_APP_LIVE_GCP_SUBSCRIPTION, REACT_APP_GCP_PROJECT_HOME, REACT_APP_GCP_HOME } = process.env;
const introVideo = process.env.REACT_APP_CLOUDTRAKR_INTRO;

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
    },
    paper: {
        padding: theme.spacing(2),
    },
}));

const DEFAULT_SUBSCRIPTION = {
    organizationId: null,
    organizationName: "Default",
    title: "All",
    value: "all",
    projectId: null,
};

const DEFAULT_PROJECT = {
    title: "All",
    projectId: null,
};

export default function GcpHome() {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState("1");
    const [activeTabSub, setActiveTabSub] = useState("1");
    const [tabSelect, setTabSelect] = useState(null);
    const [loading, setloading] = useState(false);
    const [currency, setCurrency] = useState();
    const [billingAccount, setBillingAccount] = useState();

    const [progress, setProgress] = useState(0);
    const [isSticky, setSticky] = useState(false);
    const ref = useRef(null);
    const ref2 = useRef(null);

    const handleScroll = () => {
        if (ref.current) {
            setSticky(ref.current.getBoundingClientRect().top <= 80);
        }
        if (ref2.current) {
            setSticky(ref2.current.getBoundingClientRect().top <= 150);
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", () => handleScroll);
        };
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const {classes} = useStyles();

    const [thisMonthSpend, setThisMonthSpend] = useState(null);
    const [lastMonthSpend, setLastMonthSpend] = useState(null);
    const [lastMonthDate, setLastMonthDate] = useState(null);
    const [lastMonthVariation, setLastMonthVariation] = useState(null);
    const [charts, setCharts] = useState(null);

    const [response, setResponse] = useState();
    const [serverlessTotalValue, setServerlessTotalValue] = useState([]);

    const [serverlessList, setServerlessList] = useState([]);

    const [selectedSubscription, setSubscription] = useState(DEFAULT_SUBSCRIPTION);
    const [selectedProject, setProject] = useState(DEFAULT_PROJECT);
    const [subscriptionList, setSubscriptionList] = useState([DEFAULT_SUBSCRIPTION]);
    const [projectList, setProjectList] = useState([DEFAULT_PROJECT]);
    const [rec, setRec] = useState({
        all: null,
        tenantId: null,
        subscriptionId: null,
    });

    const userData = useSelector((state) => ({
        id: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
        subscriptions: state.allHomeReducer.allSubscriptions?.gcp,
        role: state.userDetailsReducer.role,
    }));

    const gcpAccountStatus = useSelector((state) => state.accountStatusReducer.gcpAccountStatus);

    const loadingA = useSelector((state) => state.accountStatusReducer.loading);

    useEffect(() => {
        if (userData.id) {
            accountListApi();
        }
    }, [userData.id]);

    useEffect(() => {
        if (userData.id) {
            getGcpAllHome();
        }
    }, [userData.id]);

    function AccountStatus(id) {
        if (id) {
            dispatch(accountRequest(id));
        }
    }
    function getGcpAllHome() {
        setRec({ all: "all", billingAcountId: null, organizationId: null });
        dispatch(getSubscriptionsRequest(userData.id));
        accountListApi();
    }

    useEffect(() => {
        if (gcpAccountStatus == null) {
            AccountStatus(userData.id);
        }
    }, [gcpAccountStatus, userData.id]);

    function onSubscriptionChange(subscription) {
        const { value, organizationId } = subscription;
        setSubscription(subscription);
        if (subscription.title != "All") {
            gcpBillingAccounts(subscription.value, subscription.organizationId);
            setProject(DEFAULT_PROJECT);
            setProjectList([
                DEFAULT_PROJECT,
                ...(subscription?.projects?.map((p) => ({
                    title: p.id,
                    projectId: p.id,
                })) || []),
            ]);
        } else {
            getGcpAllHome();
        }
    }

    function onProjectChange(project) {
        if (selectedProject.title != project.title) {
            // const { value, organizationId } = project;
            setProject(project);
            if (project.title != "All") {
                nullifyValues();
                gcpProjectHome(project.projectId, selectedSubscription.value, selectedSubscription.organizationId);
            } else {
                nullifyValues();
                onSubscriptionChange(selectedSubscription);
            }
        }
    }
    useEffect(() => {
        if (userData.subscriptions && userData.subscriptions?.constructor.name == "Array") {
            const list = userData.subscriptions
                .map((b) =>
                    b.organizations.map((o) => ({
                        organizationId: o.organizationId,
                        organizationName: o.organizationName,
                        title: o.billingAccountName,
                        value: o.billingAccountId,
                        projectId: o.projectId,
                        projects: o.projects,
                    }))
                )
                .flat(1);
            setSubscriptionList((b) => [DEFAULT_SUBSCRIPTION, ...list]);
        }
    }, [userData.subscriptions]);

    function nullifyValues(){
        setThisMonthSpend(null);
        setLastMonthSpend(null);
        setCharts(null);
    }

    return !loadingA && !loading ? (
        gcpAccountStatus ? (
            <div
                style={{
                    backgroundColor: Color.background,
                    paddingLeft: 10,
                    paddingRight: 10,
                    minHeight: "95vh",
                }}
            >
                {charts && charts == null ? (
                    <div>
                    <Paper elevation={3}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: 25,
                                flexDirection: "column",
                            }}
                        >
                            <h6>
                                GCP Account added successfully <DoneAllIcon style={{ color: "green" }} />
                            </h6>
                            <h5 style={{ color: "steelblue" }}>We are collecting and analyzing your data. Usually cloud providers takes less than 24 hours to provide data from your cloud accounts. Please check back CloudTrakr portal in 24 hours to see the deep visibility and optimization for your cloud spend.</h5>
                        </div>
                    </Paper>
                    <Paper elevation={3} style={{marginTop:8}}>
                        <div style={{display: "flex", flexDirection: "row",justifyContent: "space-between",padding:8 }}>
                        <ReactPlayer
                        style={{ margin: 8}}
                            width={"80%"}
                            height={"300px"}
                            url={introVideo}
                            controls
                            playing
                            muted
                            config={{
                                file: {
                                    attributes: {
                                        autoPlay: true,
                                        muted: true,
                                    },
                                },
                            }}
                        />
                        <div style={{ margin: 8, width: "80%" }}>
                            <Carousel controls={false} pause={"hover"}>
                                <Carousel.Item>
                                    <img style={{ height: 300, width: 300 }} className="d-block w-100" src={backgroundImage} alt="Second slide" />

                                    <Carousel.Caption>
                                        <h3>SPEND LESS DO MORE.</h3>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img style={{ height: 300, width: 300 }} className="d-block w-100" src={backgroundImage1} alt="Second slide" />

                                    <Carousel.Caption>
                                        <h3>VISUALIZE</h3>
                                        <p>Visualize cost breakdowns of each cloud.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img style={{ height: 300, width: 300 }} className="d-block w-100" src={backgroundImage2} alt="Third slide" />

                                    <Carousel.Caption>
                                        <h3>SET</h3>
                                        <p>Set budget and keep your expenses in check.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img style={{ height: 300, width: 300 }} className="d-block w-100" src={backgroundImage3} alt="Fourth slide" />

                                    <Carousel.Caption>
                                        <h3>GET</h3>
                                        <p>Get customized cost, security and performance recommendations.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                    </Paper>
                    </div>
                ) : (
                    <div>
                        <div style={{ padding: 0.5 }}>
                            <div
                                style={{
                                    padding: 2,
                                }}
                            >
                                {selectedSubscription.title == "All" ? (
                                    <BreadCrumbs parent={"Home"} child={"GCP"} parentLink={RoutesList.ALL} hasChild={true} hasSubChild={false} />
                                ) : (
                                    <BreadCrumbs
                                        parent={"Home"}
                                        child={"GCP"}
                                        subChild={selectedSubscription.title}
                                        parentLink={RoutesList.ALL}
                                        subchildLink={RoutesList.GCP_HOME}
                                        thirdchild={selectedProject.title}
                                        hasChild={true}
                                        hasSubChild={true}
                                        hasthirdChild={selectedProject.title != "All"}
                                    />
                                )}

                                <div
                                    style={{
                                        flexDirection: "row",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        // border: '1px solid'
                                    }}
                                >
                                    <div>
                                        <CostHeader
                                            logoSize={45}
                                            cloudName={"GCP"}
                                            currency={Format.formatCurrencySymbol(currency)}
                                            cost={thisMonthSpend?.totalCost || 0}
                                            cloudIcon={GCP_ICON}
                                            lastMonthSpend={lastMonthSpend}
                                            // headerFontSize={'5ex'}
                                            color={"#000"}
                                            ExtraComponent={() => false}
                                            date={thisMonthSpend?.endDate|| 0}
                                            startDate={thisMonthSpend?.startDate|| 0}
                                            lastMonthDate={lastMonthDate?.endDate}
                                        />
                                    </div>

                                    <div
                                        style={{
                                            marginTop: -25,
                                            flex: 0.25,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            // border: '1px solid'
                                        }}
                                    >

                                        <div style={{}} className={`sticky-wrapper${isSticky ? " sticky-aws-daterange" : ""}`} ref={ref}>
                                            <div className={"sticky-inner-aws-daterange"} style={{ marginTop: 10, backgroundColor: "white" }}>
                                                <Autocomplete
                                                    clearIndicator={true}
                                                    disableClearable={true}
                                                    title={"Billing accounts"}
                                                    value={selectedSubscription}
                                                    options={subscriptionList || []}
                                                    // options={[]}
                                                    groupBy={(option) => (option.organizationId ? option.organizationName : null)}
                                                    renderOption={(state, option) => {
                                                        if (!option.title || option.value == "all") {
                                                            return <span onClick={() => onSubscriptionChange({ title: option.title, tenantId: option?.tenantId, value: option?.value })}
                                                                         className={'dropdown-subscription'}
                                                                         style={{ paddingLeft: 15, textTransform: "capitalize" }}>{option?.value || ""}</span>;
                                                        } else {
                                                            return (
                                                                <div onClick={() => onSubscriptionChange({ title: option.title, tenantId: option?.tenantId, value: option?.value })}
                                                                     style={{paddingLeft: 30}}
                                                                     className={'dropdown-subscription'}>
                                                                    <span>{option?.title || ""}</span>
                                                                    <span style={{ fontSize: 10 }}>{option?.value || ""}</span>
                                                                </div>
                                                            );
                                                        }
                                                    }}
                                                    getOptionLabel={(option) => option?.title || ""}
                                                    style={{ width: 370 }}
                                                    size={"small"}
                                                    onChange={(e, value) => onSubscriptionChange(value)}
                                                    renderInput={(params) => {
                                                        return <TextField {...params} label="Billing accounts" variant="outlined" />;
                                                    }}
                                                />
                                                {selectedSubscription.title != "All" ? (
                                                    <div>
                                                        <img
                                                            src={gcp_billing}
                                                            alt="gcp_billing"
                                                            style={{
                                                                width: 20,
                                                                height: 20,
                                                                marginLeft: 8,
                                                            }}
                                                        />
                                                        <span>{selectedSubscription.value}</span>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div style={{ display: selectedSubscription.title == "All" ? "none" : "" }} className={`sticky-wrapper${isSticky ? " sticky-aws-dropdown" : ""}`} ref={ref2}>
                                            <div className={"sticky-inner-aws-dropdown"} style={{ marginTop: 40, backgroundColor: "white" }}>
                                                <Autocomplete
                                                    clearIndicator={true}
                                                    disableClearable={true}
                                                    title={"Projects"}
                                                    value={selectedProject}
                                                    options={projectList || []}
                                                    getOptionLabel={(option) => option?.title || ""}
                                                    style={{ width: 370 }}
                                                    size={"small"}
                                                    onChange={(e, value) => onProjectChange(value)}
                                                    renderInput={(params) => {
                                                        return <TextField {...params} label="Projects" variant="outlined" />;
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* //--------------------------------------------------------------------  */}

                        {selectedSubscription.title == "All" ? (
                            charts != null ? (
                                <GcpHomeTab
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    setTabSelect={setTabSelect}
                                    charts={charts}
                                    currency={currency}
                                    rec={rec}
                                    progress={progress}
                                    selectedSubscription={selectedSubscription}
                                    serverlessTotalValue={serverlessTotalValue}
                                    serverlessList={serverlessList}
                                    customerId={userData.id}
                                    subscriptionList={subscriptionList}
                                    billingAccount={billingAccount}
                                    loading={loading}
                                    // managmentGroup={managmentGroup}
                                    // mgmtLoading={mgmtLoading}
                                />
                            ) : null
                        ) : selectedProject.title == "All" ? (
                            <GcpBillingTab
                                activeTabSub={activeTabSub}
                                setActiveTabSub={setActiveTabSub}
                                setTabSelect={setTabSelect}
                                charts={charts}
                                currency={currency}
                                rec={rec}
                                progress={progress}
                                selectedSubscription={selectedSubscription}
                                serverlessTotalValue={serverlessTotalValue}
                                serverlessList={serverlessList}
                                customerId={userData.id}
                                subscriptionList={subscriptionList}
                                billingAccount={billingAccount}
                                loading={loading}
                            />
                        ) : (
                            <GCPProjectHome
                                charts={charts}
                                customerId={userData.id}
                                currency={currency}
                                selectedSubscription={selectedSubscription}
                                loading={loading}
                                setActiveTab={setActiveTabSub}
                                activeTabSub={activeTabSub}
                                setTabSelect={setTabSelect}
                                billingAccountId={selectedSubscription.value}
                                organizationId={selectedSubscription.organizationId}
                                projectId={selectedProject.projectId}
                            />
                        )}

                        <br />
                    </div>
                )}
            </div>
        ) : (
            <ConenctAccountSkipPage hasAWS={false} hasAzure={false} hasGCP={true} cloudName={"your GCP"} role={userData.role} />
        )
    ) : (
        <div className={classes.root}>
            {/* <LinearProgress variant="determinate" value={progress} /> */}
            <DashboardLoader/>
        </div>
    );

    function accountListApi() {
        setloading(true);
        axios
            .get(REACT_APP_GCP_HOME, {
                params: {
                    customerId: userData.id,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200) {
                        gcpDataSetter(response);
                    } else {
                        gcpNullDataSetter(response);
                    }
                } else {
                    setResponse("No status code");
                }
            })

            .catch((error) => {
                console.error(error);
            })
            .finally(() => setloading(false));
    }

    function gcpBillingAccounts(billingId, orgId) {
        setloading(true);
        axios
            .get(REACT_APP_LIVE_GCP_SUBSCRIPTION, {
                params: {
                    customerId: userData.id,
                    billingAcountId: billingId,
                    organizationId: orgId,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200) {
                        gcpDataSetter(response);
                    } else {
                        gcpNullDataSetter(response);
                    }
                } else {
                    setResponse("No status code");
                }
            })

            .catch((error) => {
                console.error(error);
            })
            .finally(() => setloading(false));
    }

    function gcpProjectHome(projectId, billingAcountId, organizationId) {
        setloading(true);
        axios
            .get(REACT_APP_GCP_PROJECT_HOME, {
                params: {
                    customerId: userData.id,
                    billingAcountId,
                    organizationId,
                    projectId,
                },
            })

            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    gcpDataSetter(response);
                } else {
                    setResponse("No status code");
                }
            })

            .catch((error) => {
                console.error(error);
            })
            .finally(() => setloading(false));
    }

    function gcpDataSetter(response) {
        // setCharts({
        //     ThisMonthSpend: null,
        //     LastMonthSpend: null,
        //     TopFiveLocations: null,
        //     GCPCloudSpending: null,
        //     TopFiveServices: null,
        //     SpendByAccounts: null,
        //     SpendByProject: null,
        //     SpendByProjects: null,
        //     TopFiveServerlessServices: null,
        //     SpendByOrgAndBillingAccountWithProject: null,
        //     SpendByServices: null,
        //     SpendByBillingAccounts: null,
        // });
        if (response?.statusCode == 200) {
            const {
                data: {
                    currency,
                    cloudProvider,
                    charts: {
                        ThisMonthSpend,
                        LastMonthSpend,
                        TopFiveLocations,
                        GCPCloudSpending,
                        TopFiveServices,
                        SpendByAccounts,
                        SpendByProject = null,
                        SpendByProjects = null,
                        TopFiveServerlessServices = null,
                        SpendByOrgAndBillingAccountWithProject,
                        SpendByServices = null,
                        SpendByBillingAccounts = null,
                    },
                },
            } = response;
            setCharts({
                TopFiveServices,
                SpendByAccounts,
                SpendByProject,
                SpendByProjects,
                TopFiveLocations,
                TopFiveServerlessServices,
                SpendByOrgAndBillingAccountWithProject,
                SpendByServices,
                GCPCloudSpending,
                SpendByBillingAccounts,
            });
            setResponse(response?.statusCode);
            setCurrency(currency);
            setThisMonthSpend(ThisMonthSpend);
            setLastMonthSpend(LastMonthSpend.dataSet?.[0].value || 0);
            setLastMonthVariation(LastMonthSpend.dataSet?.[1].value || 0);
            setLastMonthDate(LastMonthSpend);
            if ("billingAccounts" in response?.data) {
                setBillingAccount(response?.data?.billingAccounts);
            } else {
                setBillingAccount(response?.data?.billingAccount);
            }
        } else {
            setResponse(response?.statusCode);
        }
    }

    function gcpNullDataSetter(response) {
        setResponse(response);
        setCharts(null);
        setCurrency("USD");
        setThisMonthSpend(null);
        setLastMonthSpend(null);
        setLastMonthVariation(null);
        setBillingAccount(null);
    }
}
