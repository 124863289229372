import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers/rootReducer";
import rootSaga from "./sagas/rootSaga";

// const sagaMonitor =
//   process.env.NODE_ENV === "development"
//     ? Reactotron.createSagaMonitor()
//     : null;

const sagaMiddlware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  process.env.NODE_ENV === "development"
    ? composeWithDevTools(applyMiddleware(sagaMiddlware))
    : applyMiddleware(sagaMiddlware)
);
sagaMiddlware.run(rootSaga);
export default store;
