import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "MakeWithStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import {TableCell as TCell} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Format from "components/NumberFormat";
import moment from "moment";
import Color from "../../styles/color";
import { Divider, LinearProgress, Typography } from "@mui/material";
import Cost from "../../components/Cost";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import gcp_big_query from "../../images/gcp_bigquery.svg";
import error_icon from "../../images/error.svg";

import { lambdaCommonConversion } from "../../util/LambdaFunctionFormatter";
import { CancelRounded } from "@mui/icons-material";
import { bytesToSize, convertToInternationalCurrencySystem } from "../../util/Miscellaneous";
import colors from "../../styles/color";
import axios from "../../connection/axios";
import LineChart from "../Charts/LineChart";
import { OverlayTrigger, Popover } from "react-bootstrap";
import LinkText from "../../components/LinkText";
import TablePaginationActions from "../../components/TablePaginationActions";

const {
    REACT_APP_GCP_ALL_BIGQUERY_LIST,
    REACT_APP_GCP_BIGQUERY_DATASET,
    REACT_APP_GCP_BIGQUERY_TABLE,
    REACT_APP_GCP_BILLING_BIGQUERY_LIST,
    REACT_APP_GCP_PROJECT_BIGQUERY_LIST,
    REACT_APP_GCP_BIGQUERY_TABLE_METRICS,
} = process.env;

const StyledTableCell = withStyles(TableCell,(theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
        fontSize:14,
        fontWeight:'bold'
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: theme.spacing(1.5),
    },
}));
const StyledTableRow = withStyles(TableRow,(theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            // backgroundColor: theme.palette.action.hover,
        },
    },
}));

const useRowStyles = makeStyles()((theme) => ({
    root: {
        "& > *": {
            padding: theme.spacing(1.5),
        },
    },
    table: {
        // minWidth: 700,
    },
    container: {
        maxHeight: 600,
    },
    link: {
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

function TableCell(props) {
    return (
        <TCell {...props} style={{ padding: 5 }}>
            {props.children}
        </TCell>
    );
}
export default function GCPBigQuery({ currency, customerId, billingAccountId, projectId, type = null }) {
    const dispatch = useDispatch();
    // const {classes} = useStyles();
    const [progress, setProgress] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [showingDetails, setShowingDetails] = React.useState(null);
    const [queryData, setQueryData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [datasetData, setDatasetData] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [showingDetailType, setShowingDetailType] = useState(null);
    const {classes} = useRowStyles();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (customerId) {
            // setQueryData(queryList.filter(q=>q.bigQueryDataSet?.length!=0));
            getBigQueryList();
        }
    }, [customerId]);

    // useEffect(() => {
    //     if (gcpCompute != null) {
    //         Conversion(gcpCompute);
    //     }
    // }, [gcpCompute]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    function getBigQueryList(){
        setLoading(true);
        axios.post(
            type == 'project'?REACT_APP_GCP_PROJECT_BIGQUERY_LIST:
                type == 'billing'?REACT_APP_GCP_BILLING_BIGQUERY_LIST:
                REACT_APP_GCP_ALL_BIGQUERY_LIST, {
                customerId,
                billingAccountId,
                projectId
        }).then(response=>response.data)
            .then(response=>{
                if(type == 'project'){
                    setQueryData(response?.data?.bigQueryDataSet.map(bq=>bq.tables.map(t=>({ ...response?.data, ...t.tableReference, bigQueryDataSet: undefined}))).flat(1));
                } else if(type == 'billing'){
                    setQueryData(
                        response?.data?.projects?.filter(q=>q.bigQueryDataSet?.length!=0).map(b=>{
                            return b.bigQueryDataSet.map(bq=>bq.tables.map(t=>({...b, ...t.tableReference, bigQueryDataSet: undefined}))).flat(1)
                        }).flat(1)
                    );
                } else {
                    setQueryData(
                        response?.data?.billingAccounts?.filter(q=>q.bigQueryDataSet?.length!=0).map(b=>{
                            return b.bigQueryDataSet.map(bq=>bq.tables.map(t=>({...b, ...t.tableReference, bigQueryDataSet: undefined}))).flat(1)
                        }).flat(1)
                    )
                }
                // setQueryData(response?.data?.billingAccounts)
            })
            .finally(()=>setLoading(false))
    }

    function Conversion(data) {
        var final = [];
        for (let b of data.billingAccounts) {
            if (b?.compute && b?.compute?.length) {
                final.push(b?.compute?.map(({ name, id, ...rest }) => ({ name, id, ...b, compute: rest })) || []);
            } else if (b?.queryDataError) {
                final.push(b);
            }
        }
        final.flat(1);
        setQueryData(final.flat(1));
    }

    function showTableDetails(data){
        setDatasetData(null);
        setTableData(null);
        axios.post(REACT_APP_GCP_BIGQUERY_TABLE, {
            customerId,
            projectId: data?.projectId,
            datasetId: data?.datasetId,
            tableId : data?.tableId
        })
            .then(response=>response.data)
            .then(response=>{
                setShowingDetailType('table');
                setTableData(response?.data?.metadata);
            })
    }

    function showDatasetDetails(data){
        setDatasetData(null);
        setTableData(null);
        axios.post(REACT_APP_GCP_BIGQUERY_DATASET, {
            customerId,
            projectId: data?.projectId,
            datasetId: data?.datasetId,
        })
            .then(response=>response.data)
            .then(response=>{
                setShowingDetailType('dataset');
                setDatasetData(response?.data?.metadata);
            })
    }

    return loading ? (
        <LinearProgress variant="determinate" value={progress} />
    ) : (
        <div>
            <ContainerCardWithDateRange title={"Big Query"} titleIcon={gcp_big_query} showDateRange={false} collapsible={false}>
                {({ period }) => (
                    <div className={classes.root}>
                        {queryData != null && queryData?.length ? (
                            <div>
                                {/**
                                 bigQueryDataSet: []
                                 billingAccountId: "013C7E-D9BD1C-CA8D55"
                                 billingAccountName: "My Billing Account"
                                 billingEnabled: true
                                 organizationId: "557802677452"
                                 organizationName: "space88.space"
                                 projectId: "venus-project-289317
                                 **/}
                                <Paper elevation={3}>
                                    <Box display={"flex"} flex={1}>
                                        <Box flex={showingDetails ? 0.3 : 1}>
                                            <TableContainer className={classes.container}>
                                                <Table className={classes.table} stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            {(showingDetailType == null || showingDetailType == 'table') &&
                                                            (<StyledTableCell align="left">
                                                                Table Id
                                                            </StyledTableCell>)}
                                                            {(showingDetailType == null || showingDetailType == 'dataset') &&
                                                            (<StyledTableCell align="left" >
                                                                Dataset Id
                                                            </StyledTableCell>)}

                                                            {(tableData || datasetData) ? null : (
                                                                <>
                                                                    <StyledTableCell align="left" >
                                                                        Project Id
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="left" >
                                                                        Billing account Id
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="left" >
                                                                        Billing account name
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="left" >
                                                                        Organization Id
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="left" >
                                                                        Organization name
                                                                    </StyledTableCell>
                                                                </>
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(queryData || [])?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((r, i) => (
                                                            <ExpandRow row={r} index={i} currency={currency} showingDetails={tableData || datasetData} showingDetailType={showingDetailType}
                                                                       setShowingDetails={(data, type)=>type=='table'?showTableDetails(data):showDatasetDetails(data)}
                                                            />
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                        <Box display={(tableData || datasetData) ? "flex" : "none"} flex={(tableData || datasetData) ? 0.7 : 0}>
                                            {
                                                datasetData?
                                                    <DatasetData showingDetails={datasetData} classes={classes}
                                                                 closeDetails={()=> {
                                                                     setShowingDetailType(null);
                                                                     setDatasetData(null)
                                                                     setTableData(null);
                                                                 }}
                                                    />:
                                                    <TableData showingDetails={tableData} classes={classes}
                                                               closeDetails={()=> {
                                                                   setShowingDetailType(null);
                                                                   setTableData(null);
                                                                   setDatasetData(null)
                                                               }}
                                                    />
                                            }
                                        </Box>
                                    </Box>
                                    <TablePagination
                                        ActionsComponent={TablePaginationActions}
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={queryData?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </Paper>
                            </div>
                        ) : (
                            <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                        )}
                    </div>
                )}
            </ContainerCardWithDateRange>
            <br />
        </div>
    );
}

export function ExpandRow(props) {
    const { row, currency, showingDetails, setShowingDetails, showingDetailType = null } = props;
    const {classes} = useRowStyles();

    return (
        <React.Fragment>
            <StyledTableRow hover className={classes.root} key={row.id}>
                {(showingDetailType == null || showingDetailType == 'table') &&
                (<TableCell align="left" style={{cursor: "pointer", maxWidth: '20%'}}>
                    <div style={{display: "flex"}}>
                        <img src={gcp_big_query} height={20}/>
                        <LinkText onClick={() => setShowingDetails(row, 'table')}>
                            {row?.tableId || "unassigned"}
                        </LinkText>
                    </div>
                </TableCell>)}
                {(showingDetailType == null || showingDetailType == 'dataset') &&
                (<TableCell align="left">
                    <div style={{display: "flex"}}>
                        {showingDetailType == 'dataset' && <img src={gcp_big_query} height={20}/>}
                        <LinkText onClick={() => setShowingDetails(row, 'dataset')}>
                            {row?.datasetId}
                        </LinkText>
                    </div>
                </TableCell>)}
                {showingDetails != null ? null : (
                    <>
                        {/*{" "}*/}
                        {/*<TableCell align="left">*/}
                        {/*    {" "}*/}
                        {/*    <span style={{ color: colors.blue }}>*/}
                        {/*        {"queryDataError" in row ? (*/}
                        {/*            <OverlayTrigger*/}
                        {/*                placement="bottom"*/}
                        {/*                overlay={*/}
                        {/*                    <Popover id="popover-contained">*/}
                        {/*                        <Popover.Title as="h3">Error</Popover.Title>*/}
                        {/*                        <Popover.Body>{row?.queryDataError}</Popover.Body>*/}
                        {/*                    </Popover>*/}
                        {/*                }*/}
                        {/*            >*/}
                        {/*                <div>*/}
                        {/*                    {" "}*/}
                        {/*                    <img src={error_icon} height={14} /> <span style={{ color: "red" }}>{row?.queryDataError?.slice(0, 40)}...</span>*/}
                        {/*                </div>*/}
                        {/*            </OverlayTrigger>*/}
                        {/*        ) : (*/}
                        {/*            <span>{row?.name}</span>*/}
                        {/*        )}*/}
                        {/*    </span>*/}
                        {/*</TableCell>*/}
                        <TableCell align="left">{row?.projectId}</TableCell>
                        <TableCell align="l     ft">{row?.billingAccountId}</TableCell>
                        <TableCell align="left">{row?.billingAccountName}</TableCell>
                        <TableCell align="left">{row?.organizationId}</TableCell>
                        <TableCell align="left">{row?.organizationName}</TableCell>
                    </>
                )}
            </StyledTableRow>
        </React.Fragment>
    );
}

function DatasetData({classes, showingDetails, closeDetails = ()=>null}){
    return (
            <Paper style={{ flex: 1, padding: 5 }} elevation={3}>
                <Box display={"flex"} alignItems={"center"}>
                    <Box display={"flex"} flex={1} justifyContent={"space-between"} style={{ paddingRight: 10 }} alignItems={"center"}>
                        <Typography component={"h5"} variant={"h5"}>
                            <img src={gcp_big_query} height={30} /> {showingDetails?.datasetReference?.datasetId}
                        </Typography>
                    </Box>
                    <CancelRounded onClick={closeDetails} />
                </Box>
                <Divider />
                <Box style={{ padding: 10 }} flexDirection={"coloum"}>
                    {showingDetails ? (
                        <Box>
                            <Box flexDirection={"row"} display={"flex"} justifyContent={"spacebetween"}>
                                <Box flex={1}>
                                    <Typography>
                                        <b>Project Id</b>
                                    </Typography>
                                    <Typography>{showingDetails?.datasetReference?.projectId}</Typography>
                                    <Typography style={{ marginTop: 10 }}>
                                        <b>Kind</b>
                                    </Typography>
                                    <Typography>{showingDetails?.kind || "-"}</Typography>
                                    <Typography style={{ marginTop: 10 }}>
                                        <b>Location</b>
                                    </Typography>
                                    <Typography>{showingDetails?.location || "-"}</Typography>
                                    <Typography style={{ marginTop: 10 }}>
                                        <b>Created Date</b>
                                    </Typography>
                                    <Typography>{moment(new Date(parseInt(showingDetails?.creationTime))).format("MMM DD, YYYY") || "-"}</Typography>
                                </Box>

                                <Box flex={1}>
                                    <Typography>
                                        <b>Type</b>
                                    </Typography>
                                    <Typography>{showingDetails?.type}</Typography>
                                    <Typography style={{ marginTop: 10 }}>
                                        <b>Etag</b>
                                    </Typography>
                                    <Typography>{showingDetails?.etag || "-"}</Typography>
                                    <Typography style={{ marginTop: 10 }}>
                                        <b>Id</b>
                                    </Typography>
                                    <Typography>{showingDetails?.id || "-"}</Typography>
                                    <Typography style={{ marginTop: 10 }}>
                                        <b>Last Modified</b>
                                    </Typography>
                                    <Typography>{moment(new Date(parseInt(showingDetails?.lastModifiedTime))).format("MMM DD, YYYY") || "-"}</Typography>
                                </Box>
                            </Box>
                            <Typography style={{ marginTop: 10 }}>
                                <b>Self Link</b>
                            </Typography>
                            <Typography>
                                <a target='_blank' href={showingDetails?.selfLink}>
                                    {showingDetails?.selfLink || "-"}
                                </a>
                            </Typography>

                            <Typography style={{ marginTop: 10 }}>
                                <b>Role Access</b>
                            </Typography>
                            <TableContainer className={classes.container}>
                                <Table className={classes.table} stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left" style={{ fontSize: 14, fontWeight: "bold" }}>
                                                Role
                                            </StyledTableCell>
                                            <StyledTableCell align="left" style={{ fontSize: 14, fontWeight: "bold" }}>
                                                Special Group / Email
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(showingDetails?.access || [])?.map((row, i) => (
                                            <React.Fragment>
                                                <StyledTableRow hover className={classes.root} key={row.resourceId} style={{ cursor: "pointer" }}>
                                                    <TableCell align="left">{row?.role}</TableCell>
                                                    <TableCell align="left">{row?.specialGroup || row?.userByEmail}</TableCell>
                                                </StyledTableRow>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    ) : null}
                </Box>
                <Divider />
            </Paper>
    )
}

function TableData({classes, showingDetails, closeDetails = ()=>null}){
    return (
            <Paper style={{ flex: 1, padding: 5 }} elevation={3}>
                <Box display={"flex"} alignItems={"center"}>
                    <Box display={"flex"} flex={1} justifyContent={"space-between"} style={{ paddingRight: 10 }} alignItems={"center"}>
                        <Typography component={"h6"} variant={"h6       "}>
                            <img src={gcp_big_query} height={30} /> {showingDetails?.tableReference?.tableId}
                        </Typography>
                    </Box>
                    <CancelRounded onClick={closeDetails} />
                </Box>
                <Divider />
                <Box style={{ padding: 10 }} flexDirection={"coloum"}>
                    {showingDetails ? (
                        <Box>
                            <Box flexDirection={"row"} display={"flex"} justifyContent={"spacebetween"}>
                                <Box flex={1}>
                                    <Typography>
                                        <b>Dataset Id</b>
                                    </Typography>
                                    <Typography>{showingDetails?.tableReference?.datasetId}</Typography>
                                    <Typography style={{ marginTop: 10 }}>
                                        <b>Project Id</b>
                                    </Typography>
                                    <Typography>{showingDetails?.tableReference?.projectId || "-"}</Typography>
                                    <Typography style={{ marginTop: 10 }}>
                                        <b>Created Date</b>
                                    </Typography>
                                    <Typography>{moment(new Date(parseInt(showingDetails?.creationTime))).format("MMM DD, YYYY") || "-"}
                                    </Typography>
                                    <Typography style={{ marginTop: 10 }}>
                                        <b>Location</b>
                                    </Typography>
                                    <Typography>{showingDetails?.location || "-"}</Typography>
                                    <Typography style={{ marginTop: 10 }}>
                                        <b>Num Rows</b>
                                    </Typography>
                                    <Typography>{showingDetails?.numRows || "-"}</Typography>
                                    <Typography style={{ marginTop: 10 }}>
                                        <b>Num Long Term Bytes</b>
                                    </Typography>
                                    <Typography>{showingDetails?.numLongTermBytes || "-"}</Typography>
                                </Box>

                                <Box flex={1}>
                                    <Typography>
                                        <b>Etag</b>
                                    </Typography>
                                    <Typography>{showingDetails?.etag}</Typography>
                                    <Typography style={{ marginTop: 10 }}>
                                        <b>Kind</b>
                                    </Typography>
                                    <Typography>{showingDetails?.kind || "-"}</Typography>
                                    <Typography style={{ marginTop: 10 }}>
                                        <b>Last Modified</b>
                                    </Typography>
                                    <Typography>{moment(new Date(parseInt(showingDetails?.lastModifiedTime))).format("MMM DD, YYYY") || "-"}</Typography>
                                    <Typography style={{ marginTop: 10 }}>
                                        <b>Num Bytes</b>
                                    </Typography>
                                    <Typography>{showingDetails?.numBytes || "-"}</Typography>
                                    <Typography style={{ marginTop: 10 }}>
                                        <b>Type</b>
                                    </Typography>
                                    <Typography>{showingDetails?.type || "-"}</Typography>
                                    <Typography style={{ marginTop: 10 }}>
                                        <b>Time Partitioning</b>
                                    </Typography>
                                    <Typography>{showingDetails?.timePartitioning?.type || "-"}</Typography>
                                </Box>
                            </Box>
                            {/*<br/>*/}
                            <Typography style={{ marginTop: 10 }}>
                                <b>Id</b>
                            </Typography>
                            <Typography>{showingDetails?.id || "-"}</Typography>
                            <Typography style={{ marginTop: 10 }}>
                                <b>Self Link</b>
                            </Typography>
                            <Typography>
                                <a target='_blank' href={showingDetails?.selfLink}>
                                    {showingDetails?.selfLink || "-"}
                                </a>
                            </Typography>
                            <hr/>
                            <Typography style={{ marginTop: 10 }}>
                                <b>Table Field</b>
                            </Typography>
                            <TableContainer className={classes.container}>
                                <Table className={classes.table} stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left" style={{ fontSize: 14, fontWeight: "bold" }}>
                                                Name
                                            </StyledTableCell>
                                            <StyledTableCell align="left" style={{ fontSize: 14, fontWeight: "bold" }}>
                                                Type
                                            </StyledTableCell>
                                            <StyledTableCell align="left" style={{ fontSize: 14, fontWeight: "bold" }}>
                                                Mode
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(showingDetails?.schema?.fields || [])?.map((row, i) => (
                                            <React.Fragment>
                                                <StyledTableRow hover className={classes.root} key={row.resourceId} style={{ cursor: "pointer" }}>
                                                    <TableCell align="left">{row?.name}</TableCell>
                                                    <TableCell align="left">{row?.type}</TableCell>
                                                    <TableCell align="left">{row?.mode}</TableCell>
                                                </StyledTableRow>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    ) : null}
                </Box>
                <Divider />
            </Paper>
    )
}
