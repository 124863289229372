import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "MakeWithStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import {TableCell as TCell} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/Icon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Format from "components/NumberFormat";
import moment from "moment";
import Color from "../../styles/color";
import Ec2Icon from "../../images/EC2.svg";
import EBSIcon from "../../images/AWS_EBS_icon.svg";
import CarousalCard from "components/CarousalCard";
import { getAWSCCKRequest, getAWSOptimizerRequest } from "../../redux/actions/actions";
import { LinearProgress, Grid } from "@mui/material";
import Cost from "../../components/Cost";
import ComputeIcon from "../../images/Virtual-Machine.svg";
import KubernetesIcon from "../../images/Kubernetes-Services.svg";
import ContainersIcon from "../../images/Container-Instances.svg";
import { Tooltip, OverlayTrigger, Popover } from "react-bootstrap";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import AwsCostOptimizerBar from "components/AwsCostOptimizerBar";
import colors from "../../styles/color";
import TablePaginationActions from "../../components/TablePaginationActions";
import TableSearch from "../../components/TableSearch";
const useStyles = makeStyles()((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    InstancePaper: {
        width: "100%",
        height: 160,
        backgroundColor: Color.background,
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
}));

function TableCell(props) {
    return (
        <TCell {...props} style={{paddingRight:8,padding:0 }}>
            {props.children}
        </TCell>
    );
}

export default function AWSCompute({ period, currency, customerId }) {
    const dispatch = useDispatch();
    const {classes} = useStyles();
    const [progress, setProgress] = useState(0);
    const [selected, setSelected] = React.useState([]);
    const [list, setList] = useState(null);
    const [optimizeData, setOptimizeData] = useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [ec2Data,setEc2Data] = React.useState([]);
    const [ebsData,setEBSData] = React.useState([]);
    const [searchEc2, setSearchEc2] = useState(null);
    const [searchEbs, setSearchEbs] = useState(null);
    const [searchContainer, setSearchContainer] = useState(null);
    const [searchKubernetes, setSearchKubernetes] = useState(null);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    let awsCompute = useSelector((state) => state?.AWSCCKReducer?.AWSCCKdata?.data?.SpendByCompute);
    let awsContainers = useSelector((state) => state?.AWSCCKReducer?.AWSContainersData?.data?.SpendByContainers);
    let awsKubernetes = useSelector((state) => state?.AWSCCKReducer?.AWSKubernetesData?.data?.SpendByKubernetes);

    let awsComputeOptimizer = useSelector((state) => state?.awsComputeOptimizerReducer?.computeOptimizerData);

    let AWSCCKloading = useSelector((state) => state?.AWSCCKReducer?.AWSCCKloading);
    let AWSContainersloading = useSelector((state) => state?.AWSCCKReducer?.AWSContainersloading);
    let AWSKubernetesloading = useSelector((state) => state?.AWSCCKReducer?.AWSKubernetesloading);

    useEffect(()=>{
    let vol={}; 
    let i_data={};
    if(!awsCompute) return
    Object.keys(awsCompute).forEach(key=>{
        const data = awsCompute[key];
        vol[key] = Object.assign({},data);
        i_data[key] = Object.assign({},data);
        vol[key]['resources'] = [];
        i_data[key]['resources'] = [];
        data?.resources?.forEach(r=>r.resourceId.toString().startsWith('i-')?i_data[key].resources.push(r):vol[key].resources.push(r))
        vol[key]['totalCost'] = vol[key].resources.map((r)=>parseFloat(parseFloat(r.cost).toPrecision(4))).reduce((a,b)=>(a)+(b), 0)
        i_data[key]['totalCost'] = i_data[key].resources.map((r)=>parseFloat(parseFloat(r.cost).toPrecision(4))).reduce((a,b)=>(a)+(b), 0)
    })   
      setEBSData(vol);
      setEc2Data(i_data);
    }, [awsCompute]);

    useEffect(() => {
        if (awsCompute == null) {
            let dataType = "compute";
            dispatch(
                getAWSCCKRequest({
                    customerId,
                    dataType,
                })
            );
        }
        if (awsContainers == null) {
            let dataType = "containers";
            dispatch(
                getAWSCCKRequest({
                    customerId,
                    dataType,
                })
            );
        }
        if (awsKubernetes == null) {
            let dataType = "kubernetes";
            dispatch(
                getAWSCCKRequest({
                    customerId,
                    dataType,
                })
            );
        }
        // if (awsComputeOptimizer == null) {
        //     dispatch(getAWSOptimizerRequest({ customerId }));
        // }
    }, [customerId]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    // useEffect(() => {
    //     dataConversion(awsComputeOptimizer);
    // }, [awsComputeOptimizer]);

    function dataConversion(data) {
        const finalData = {};
        data?.forEach((d) => {
            const y = {};
            d.summaries.forEach((s) => {
                y[s.name] = s.value;
            });

            if (finalData[d.recommendationResourceType]?.push(y)) {
            } else {
                finalData[d.recommendationResourceType] = [y];
            }
        });
        Object.keys(finalData).forEach((k) => {
            const h = {};
            let keys = Array.from(new Set(finalData[k].map((j) => Object.keys(j)).flat(1)));
            keys.forEach((j) => {
                h[j] = finalData[k].map((i) => i[j]).reduce((a, b) => a + b, 0);
            });
            finalData[k] = h;
        });
        setOptimizeData(finalData);
    }


    const filterTableEc2 = (f)=> {
        if(searchEc2 && searchEc2.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.resourceId,
                f?.resourceName,
                f?.cloudProvider,
                f?.resourceType,
                f?.location
            ], searchEc2);
        }
        return true;
    }
    const filterTableEbs = (f)=> {
        if(searchEbs && searchEbs.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.resourceId,
                f?.resourceName,
                f?.cloudProvider,
                f?.resourceType,
                f?.location
            ], searchEbs);
        }
        return true;
    }
    const filterTableContainer = (f)=> {
        if(searchContainer && searchContainer.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.resourceId,
                f?.resourceName,
                f?.cloudProvider,
                f?.resourceType,
                f?.location
            ], searchContainer);
        }
        return true;
    }
    const filterTableKubernetes = (f)=> {
        if(searchKubernetes && searchKubernetes.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.resourceId,
                f?.resourceName,
                f?.cloudProvider,
                f?.resourceType,
                f?.location
            ], searchKubernetes);
        }
        return true;
    }

    console.log('data',ec2Data)

    return AWSCCKloading || AWSContainersloading || AWSKubernetesloading ? (
        <LinearProgress variant="determinate" value={progress} />
    ) : (
        <div>
            {/* <ContainerCardWithDateRange title={"Compute Optimizer"} titleIcon={ComputeIcon} showDateRange={false} collapsible={true}>
                {({ period }) => (
                    <div className={classes.root}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Paper className={classes.InstancePaper}>
                                    <div style={{ padding: 6 }}>
                                        <h6>
                                            <b>EC2 instances</b>
                                        </h6>
                                        {optimizeData?.Ec2Instance?.OPTIMIZED != 0 && optimizeData?.Ec2Instance?.UNDER_PROVISIONED != 0 && optimizeData?.Ec2Instance?.OVER_PROVISIONED != 0 ? (
                                            <div>
                                                <AwsCostOptimizerBar high={optimizeData?.Ec2Instance?.OPTIMIZED} medium={optimizeData?.Ec2Instance?.UNDER_PROVISIONED} low={optimizeData?.Ec2Instance?.OVER_PROVISIONED} />
                                            </div>
                                        ) : (
                                            <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                                        )}
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper className={classes.InstancePaper}>
                                    <div style={{ padding: 6 }}>
                                        <h6>
                                            <b>Auto scaling groups</b>
                                        </h6>
                                        {optimizeData?.AutoScalingGroup?.OPTIMIZED != 0 && optimizeData?.AutoScalingGroup?.NOT_OPTIMIZED != 0 ? <div></div> : <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>}
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper className={classes.InstancePaper}>
                                    <div style={{ padding: 6 }}>
                                        <h6>
                                            <b>EBS volumes</b>
                                        </h6>
                                        {optimizeData?.EbsVolume?.Optimized != 0 && optimizeData?.EbsVolume?.NotOptimized != 0 ? <div></div> : <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>}
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper className={classes.InstancePaper}>
                                    <div style={{ padding: 6 }}>
                                        <h6>
                                            <b>Lambda functions</b>
                                        </h6>
                                        {optimizeData?.LambdaFunction?.Optimized != 0 && optimizeData?.LambdaFunction?.NotOptimized != 0 ? (
                                            <div>
                                                <AwsCostOptimizerBar high={optimizeData?.LambdaFunction?.Optimized} medium={optimizeData?.LambdaFunction?.NotOptimized} />
                                            </div>
                                        ) : (
                                            <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                                        )}
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </ContainerCardWithDateRange>
            <br /> */}
            <ContainerCardWithDateRange
                title={"Spend by EC2"}
                titleIcon={Ec2Icon}
                showDateRange={true}
                datePeriod={({period})=>({
                    start:"__",
                    end: "__",
                    rawStart: moment(awsCompute?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(awsCompute?.[period]?.endDate, "YYYYMMDD")
                })}
                totalCost={({period})=>(
                    currency + Format.formatNumber(ec2Data?.[period]?.totalCost)
                )}
                datePeriodEnabled={true}
                showDatePeriod={(period)=>!!(ec2Data != null && ec2Data?.[period]?.resources?.length)}
                collapsible={true}
            >
                {({ period }) => (
                    <div className={classes.root}>
                        {ec2Data?.[period] != null && ec2Data?.[period]?.resources?.length ? (
                            <Paper className={classes.paper}>
                               <CarousalCard cardTitle1={'Total cost'} cardTitle2={"Total Resources"} cost={ec2Data?.[period]?.totalCost} res={ec2Data?.[period]?.resources?.length} currency={currency} />

                                <TableSearch onSearch={setSearchEc2} />
                                <TableContainer style={{ height: 350 }}>
                                <Table className={classes.table} stickyHeader aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                        <AWSComputeHead spendByEC2={true} classes={classes} numSelected={selected.length} rowCount={ec2Data.length} totalCost={ec2Data?.[period]?.totalCost} currency={currency} />
                                        <TableBody>
                                            {ec2Data?.[period]?.resources
                                                ?.filter(filterTableEc2)
                                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                return <MyTableRow spendByEC2={true} row={row} index={index} list={list} setList={setList} currency={currency} />;
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination ActionsComponent={TablePaginationActions}
                                    rowsPerPageOptions={[10, 25, 50, 75]}
                                    component="div"
                                    count={ec2Data?.[period]?.resources?.filter(filterTableEc2)?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Paper>
                        ) : (
                            <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                        )}
                    </div>
                )}
            </ContainerCardWithDateRange>
            <br />
            <ContainerCardWithDateRange
                title={"Spend by EBS / NAT / Snapshots"}
                titleIcon={EBSIcon}
                showDateRange={true}
                datePeriod={({period})=>({
                    start:"__",
                    end: "__",
                    rawStart: moment(awsCompute?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(awsCompute?.[period]?.endDate, "YYYYMMDD")
                })}
                totalCost={({period})=>(
                    currency + Format.formatNumber(ebsData?.[period]?.totalCost)
                )}
                datePeriodEnabled={true}
                showDatePeriod={(period)=>!!(ebsData != null && ebsData?.[period]?.resources?.length)}
                collapsible={true}
            >
                {({ period }) => (
                    <div className={classes.root}>
                        {ebsData?.[period] != null && ebsData?.[period]?.resources?.length ? (
                            <Paper className={classes.paper}>
                               <CarousalCard cardTitle1={'Total cost'} cardTitle2={"Total Resources"} cost={ebsData?.[period]?.totalCost} res={ebsData?.[period]?.resources?.length} currency={currency} />

                                <TableSearch onSearch={setSearchEbs} />
                                <TableContainer style={{ height: 350 }}>
                                    <Table className={classes.table} stickyHeader aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                        <AWSComputeHead classes={classes} numSelected={selected.length} rowCount={ebsData.length} totalCost={ebsData?.[period]?.totalCost} currency={currency} />
                                        <TableBody>
                                            {ebsData?.[period]?.resources
                                                ?.filter(filterTableEbs)
                                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                return <MyTableRow row={row} index={index} list={list} setList={setList} currency={currency} />;
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination ActionsComponent={TablePaginationActions}
                                    rowsPerPageOptions={[10, 25, 50, 75]}
                                    component="div"
                                    count={ebsData?.[period]?.resources?.filter(filterTableEbs)?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Paper>
                        ) : (
                            <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                        )}
                    </div>
                )}
            </ContainerCardWithDateRange>
            <br />
            
            {/* ----------------------------------------AWS containers-------------------------------------------------- */}

            <ContainerCardWithDateRange
                title={"Spend by Containers"}
                titleIcon={ContainersIcon}
                showDateRange={true}
                collapsible={true}
                datePeriod={({period})=>({
                    start:"__",
                    end: "__",
                    rawStart: moment(awsContainers?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(awsContainers?.[period]?.endDate, "YYYYMMDD")
                })}
                totalCost={({period})=>(
                    currency + Format.formatNumber(awsContainers?.[period]?.totalCost)
                )}
                datePeriodEnabled={true}
                showDatePeriod={(period)=>!!(awsContainers != null && awsContainers?.[period]?.resources?.length)}
            >
                {({ period }) => (
                    <div className={classes.root}>
                        {awsContainers?.[period] != null && awsContainers?.[period]?.resources?.length ? (
                            <Paper className={classes.paper}>
                              <CarousalCard cardTitle1={'Total cost'} cardTitle2={"Total Resources"} cost={awsContainers?.[period]?.totalCost} res={awsContainers?.[period]?.resources?.length} currency={currency} />

                                <TableSearch onSearch={setSearchContainer} />
                                <TableContainer style={{ height: 350 }}>
                                    <Table className={classes.table} stickyHeader aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                        <AWSComputeHead classes={classes} numSelected={selected.length} rowCount={awsContainers.length} totalCost={awsContainers?.[period]?.totalCost} currency={currency} />
                                        <TableBody>
                                            {awsContainers?.[period]?.resources
                                            ?.filter(filterTableContainer)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                return <MyTableRow row={row} index={index} list={list} setList={setList} currency={currency} />;
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination ActionsComponent={TablePaginationActions}
                                    rowsPerPageOptions={[10, 25, 50, 75]}
                                    component="div"
                                    count={awsContainers?.[period]?.resources?.filter(filterTableContainer)?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Paper>
                        ) : (
                            <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                        )}
                    </div>
                )}
            </ContainerCardWithDateRange>
            <br />
            {/* ------------------------------------------------AWS Kubernetes-------------------------------------------- */}
            <ContainerCardWithDateRange
                title={"Spend by Kubernetes"}
                titleIcon={KubernetesIcon}
                showDateRange={true}
                collapsible={true}
                datePeriod={({period})=>({
                    start:"__",
                    end: "__",
                    rawStart: moment(awsKubernetes?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(awsKubernetes?.[period]?.endDate, "YYYYMMDD")
                })}
                totalCost={({period})=>(
                    currency + Format.formatNumber(awsKubernetes?.[period]?.totalCost)
                )}
                datePeriodEnabled={true}
                showDatePeriod={(period)=>!!(awsKubernetes != null && awsKubernetes?.[period]?.resources?.length)}

            >
                {({ period }) => (
                    <div className={classes.root}>
                        {awsKubernetes?.[period] != null && awsKubernetes?.[period]?.resources?.length ? (
                            <Paper className={classes.paper}>
                              <CarousalCard cardTitle1={'Total cost'} cardTitle2={"Total Resources"} cost={awsKubernetes?.[period]?.totalCost} res={awsKubernetes?.[period]?.resources?.length} currency={currency} />

                                <TableSearch onSearch={setSearchKubernetes} />
                                <TableContainer style={{ height: 350 }}>
                                    <Table className={classes.table} stickyHeader aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                        <AWSComputeHead classes={classes} numSelected={selected.length} rowCount={awsKubernetes.length} totalCost={awsKubernetes?.[period]?.totalCost} currency={currency} />
                                        <TableBody>
                                            {awsKubernetes?.[period]?.resources
                                            ?.filter(filterTableKubernetes)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                return <MyTableRow row={row} index={index} list={list} setList={setList} currency={currency} />;
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination ActionsComponent={TablePaginationActions}
                                    rowsPerPageOptions={[10, 25, 50, 75]}
                                    component="div"
                                    count={awsKubernetes?.[period]?.resources?.filter(filterTableKubernetes)?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Paper>
                        ) : (
                            <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                        )}
                    </div>
                )}
            </ContainerCardWithDateRange>
        </div>
    );
}

function AWSComputeHead({ spendByEC2, totalCost, currency = "" }) {
    const useStyles = makeStyles()((theme) => ({
        root: {
            backgroundColor: Color.tableHeader,
            padding: theme.spacing(0.5),
        },

        tableRow: {
            fontWeight: "bold",
            backgroundColor: Color.tableHeader,
        },
    }));
    const {classes} = useStyles();

    return (
        <TableHead className={classes.root}>
            <TableRow>
                <TableCell className={classes.tableRow}></TableCell>
                <TableCell className={classes.tableRow}>Resource</TableCell>
                <TableCell className={classes.tableRow}>{spendByEC2 ?'Type':'Service'}</TableCell>
                {spendByEC2 ?<TableCell className={classes.tableRow}>OS</TableCell>:null}
                <TableCell className={classes.tableRow}>Location</TableCell>
                <TableCell className={classes.tableRow}>Account</TableCell>
                <TableCell className={classes.tableRow}>Cost</TableCell>

                {/* <TableCell className={classes.tableRow}>
                    {currency}
                    <Cost>{totalCost}</Cost>
                </TableCell> */}
            </TableRow>
        </TableHead>
    );
}

function MyTableRow({ row, index, list, setList, currency, spendByEC2 }) {
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState("");
    const [type, setType] = useState(null);

    useEffect(() => {
        if (row) {
            setDate(moment(row.date, "YYYYMMDD").format("MMM DD, YYYY"));
            {
                row?.meters?.map((a) => (a?.operation.toString().startsWith("RunInstances") && a?.productFamily == "Compute Instance" ? 
                setType(a?.lineItemDescription) : null));
            }

        }

    }, [row]);

    return (
        <React.Fragment>
            <TableRow hover key={row.date}>
                <TableCell>
                    <IconButton
                        style={{ cursor: "pointer" }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {/* <TableCell component="th" scope="row" padding="none">
                 {date}
                </TableCell> */}
                <TableCell>{row?.resourceId}</TableCell>
                <TableCell>
                    {spendByEC2 && type!=null ?
                    <OverlayTrigger
                        placement="left"
                        overlay={
                             <Popover id="popover-contained">
                                <Popover.Body style={{ fontSize: 11 }}>{type!=null ? type?.split(" ")[2]+' '+type?.split(" ")[3]+' '+type?.split(" ")[4]:null}</Popover.Body>
                            </Popover>
                        }
                    >
                        <div>
                            {type!=null ? type?.split(" ")?.reverse()[2] + " " + type?.split(" ")[0] + " /hr" : '-'}
                        </div>
                    </OverlayTrigger>
                    :spendByEC2?'-':row?.resourceId?.startsWith('vol')?'Amazon Elastic Block Store':row?.resourceId?.startsWith('snap')?'Amazon Elastic Block Store':row?.resourceId?.startsWith('nat')?'Amazon Elastic Compute Cloud':row?.resourceId?.startsWith('h-')?'Amazon Elastic Compute Cloud':row?.resourceId?.startsWith('eni')?'Amazon Elastic Compute Cloud':row?.serviceName}
                </TableCell>
                {spendByEC2?<TableCell>{type!=null ? type?.includes('Windows')?'Windows':type?.includes('Linux')?'Linux':type?.includes('macOS')?'macOS':type?.includes('Raspberry Pi')?'Raspberry Pi':'-':'-'}</TableCell>:null}
                <TableCell>{row?.location}</TableCell>
                <TableCell>
                    {"subscriptionName" in row ?
                        <OverlayTrigger placement="bottom" overlay={<Popover id="popover-contained">{"subscriptionName" in row &&
                         <Popover.Body style={{ fontSize: 11 }}>{row?.subscriptionName}</Popover.Body>}</Popover>}>
                           <span style={{whiteSpace: 'nowrap'}}>
                            {row.subscriptionId}
                        </span>
                        </OverlayTrigger>
                        :row?.subscriptionId}
                    </TableCell>

                <TableCell>
                    {currency}
                    {Format.formatNumber(Number(row.cost))}
                </TableCell>
            </TableRow>
            <ExpandLavelData data={row} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

export function ExpandLavelData({ data, open, select, set, currency }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2.5}>
                            <Htmltable data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <div style={{ maxHeight: 300 }} className="overflow-auto">
            <table id="customers">
                <tr>
                    <th>Operation</th>
                    <th>Product family</th>
                    <th>Usage type</th>
                    <th>Description</th>
                    <th>Total</th>
                </tr>

                {data &&
                    data?.meters
                        ?.sort((a, b) => {
                            return parseFloat(b?.cost || 0) - parseFloat(a?.cost || 0);
                        })
                        .map((m) => (
                            <tr>
                                <td style={{fontSize:13}}>{m.operation||'-'}</td>
                                <td style={{fontSize:13}}>{m?.productFamily||'-'}</td>
                                <td style={{fontSize:13}}>{m.lineItemUsageType||'-'}</td>
                                <td style={{fontSize:13}}>{m.lineItemDescription||'-'}</td>
                                <td style={{fontSize:13}}>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                            </tr>
                        ))}
            </table>
        </div>
    );
}
