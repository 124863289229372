import React, { useState, useEffect } from "react";
//import "./style.css";
import { makeStyles, withStyles } from "MakeWithStyles";
import {
    Box,
    Typography,
    IconButton,
    Collapse,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    LinearProgress,
    Divider,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePagination from "@mui/material/TablePagination";
import Format from "components/NumberFormat";
import management_group from "../../images/management_group.svg";
import key from "../../images/key.svg";
import gcp_project from "../../images/gcp_project.svg";
import gcp_billing from "../../images/gcp_billing.svg";
import gcp_org from "../../images/gcp_org.svg";
import TablePaginationActions from "../TablePaginationActions";

const useRowStyles = makeStyles()({
    root: {
        "& > *": {
            borderBottom: "unset",
            padding: 3.5,
        },
    },
    table: {
        minWidth: 700,
    },
    container: {
        // maxHeight: 440,
    },
    container1: {
        maxHeight: 400,
    },
});
export function ManagementTotalCost(data, period) {
    let rootTotal = [];
    let total = 0;
    if (data && data != null) {
        data &&
        data.map((i, j) => {
            if (i?.children?.length > 0) {
                i?.children &&
                i?.children.map((k, l) => {
                    total = k?.children?.length + total;

                    k?.children &&
                    k?.children.map((m, n) => {
                        rootTotal.push(
                            m?.SpendBySubscription?.[period]?.totalCost || 0
                        );
                    });
                });
            } else {
                rootTotal.push(0);
            }
        });
    }
    return { rootTotal, total };
}

const DateRange = ["1D", "1W", "1M", "3M", "6M", "1Y"];

function dataFormatter(data) {
    const total = {};
    DateRange.forEach((d) => {
        total[d] = 0;
    });

    function organisationResolver(e) {
        if (e?.billingAccounts) {
            const children = e.billingAccounts.map((c) => billingResolver(c));

            return {
                name: e.organizationName,
                id: e.organizationId,
                type: "organization",
                cost: parseFloat(e.totalCost),
                children
            };
        } else {
            return {
                name: e.organizationName,
                id: e.organizationId,
                type: "organization",
                cost: parseFloat(e.totalCost),
            };
        }
    }

    function billingResolver(e) {
        const children = e.projects.map((c) => projectResolver(c));

        return {
            name: e.billingAccountName,
            id: e.billingAccountId,
            type: "billingAccount",
            cost: parseFloat(e.totalCost),
            children
        };
    }

    function projectResolver(e) {
        return {
            name: e.projectId,
            id: e.projectId,
            type: "project",
            cost: parseFloat(e.totalCost),
        };
    }

    return  data?.map((d) => organisationResolver(d));
}

export const AzureManagementGroupFormatter = dataFormatter;

export default function GCPOrganisationTable({
                                                 period,
                                                 data,
                                                 currency,
                                                 headerData,
                                                 loading,
                                                 progress,
                                             }) {
    const {classes} = useRowStyles();
    const [total, setTotalCost] = useState();
    const [totalSubscription, setTotalSubscription] = useState(0);
    const [organisationData, setOrganisationData] = useState([]);
    const [open, setOpen] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (data) {
            setOrganisationData(dataFormatter(data));
        }
    }, [data]);

    useEffect(() => {
        setTotalCost(organisationData.map(o=>o.cost).reduce((a,b)=>a+b, 0));
        // if (managementData && managementData.length) {
        //     var subscriptions = 0;
        //     managementData.forEach((m) => {
        //         subscriptions += m?.subsCount || (m.type === "subscriptions" ? 1 : 0);
        //     });
        //     const total = managementData
        //         .map((m) => m.cost)
        //         .reduce((a, b) => {
        //             const total = {};
        //             DateRange.forEach((d) => {
        //                 total[d] = parseFloat(a?.[d] || 0) + parseFloat(b?.[d] || 0);
        //             });
        //             return total || 0;
        //         }, 0);
        //     setTotalCost(total);
        //     setTotalSubscription(subscriptions);
        // }
    }, [organisationData]);

    return <>
        <span style={{display: 'flex', justifyContent: "flex-end"}}>
                Total: {currency +
        (!(data && data.length) || (isNaN(Number(total).toFixed(2)))
            ? "__.__"
            : Format.formatNumber(Number(total).toFixed(2))) ||
        "__.__"}
            </span>
    {
        loading ? (
            <div className={classes.root}>
                <LinearProgress variant="determinate" value={progress}/>
            </div>
        ) : (data && data.length) ? (
            <div>
                <Paper elevation={3}>
                    <div
                        style={{
                            // border: '1px solid',
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "flex-start",
                            backgroundColor: "#cfdac8",
                            padding: 8,
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4,
                        }}
                    >
                        <div
                            style={{
                                flex: 3,
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "flex-start",
                            }}
                        >
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => {
                                    setOpen(!open);
                                }}
                            >
                                {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                            </IconButton>
                            <Typography style={{marginLeft: 4, fontWeight: "bold"}}>
                                {/*{" "}*/}
                                {/*{headerData?.displayName}*/}
                                Organization name
                            </Typography>
                        </div>
                        <div style={{flex: 3}}>
                            <Typography style={{fontWeight: "bold"}}>
                                {/*{" "}*/}
                                {/*{headerData?.tenantId}*/}
                                Organization Id
                            </Typography>
                        </div>
                        <div style={{flex: 2, display: "flex", justifyContent: "flex-end"}}>
                            <Typography style={{fontWeight: "bold"}}>
                                {/*{totalSubscription && totalSubscription > 1*/}
                                {/*    ? totalSubscription + " subscriptions"*/}
                                {/*    : totalSubscription <= 1*/}
                                {/*        ? totalSubscription + " subscription"*/}
                                {/*        : null || "--"}{" "}*/}
                                Billing Account ({(organisationData.map(f => f.children).flat(1).length) || 0})
                            </Typography>
                        </div>
                        <div
                            style={{
                                flex: 2,
                                display: "flex",
                                justifyContent: "flex-end",
                                paddingRight: 5,
                            }}
                        >
                            <Typography style={{fontWeight: "bold"}}>
                                Cost
                            </Typography>
                        </div>
                    </div>

                    {organisationData
                        .sort((a, b) => b - a)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        ?.map((o) => (
                            <OrganisationGroup
                                data={o}
                                currency={currency}
                                period={period}
                                collapseControl={open}
                            />
                        ))}
                         <TablePagination
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={organisationData?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                </Paper>
            </div>
        ) : (
            <div style={{display: "flex", justifyContent: 'center'}}>
                <Typography>No data found</Typography>
            </div>
        )
    }
        </>
}

function OrganisationGroup({
                             data: e,
                             period,
                             currency,
                             collapseControl,
                         }) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(collapseControl);
    }, [collapseControl]);

    if (e?.children) {
        const children = e.children
            .sort((a, b) =>b-a)
            .map((c) => (
                <OrganisationGroup
                    data={c}
                    currency={currency}
                    period={period}
                    collapseControl={open}
                />
            ));
        if(e?.type === "project"){
            return (
                <ProjectComponent currency={currency}
                                  period={period}
                                  e={e}
                                  open={open}
                                  setOpen={setOpen} />
            )
        }
        return (
            <>
                <OrganisationComponent
                    currency={currency}
                    period={period}
                    e={e}
                    open={open}
                    setOpen={setOpen}
                    count={((e?.children?.length) ||0)}
                />
                <Collapse in={open} unmountOnExit timeout="auto">
                    <div
                        style={{
                            margin: 15,
                            padding: 10,
                        }}
                    >
                        {children}
                    </div>
                </Collapse>
            </>
        );
    } else {
        return (
            <ProjectComponent
                currency={currency}
                period={period}
                e={e}
                open={open}
                setOpen={setOpen}
            />
        );
    }
}

function OrganisationComponent({ open, setOpen, e, currency, period, count }) {
    return (
        <>
            <div
                style={{
                    // border: '1px solid',
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "flex-start",
                    padding: 4,
                    marginBottom: 2,
                    // flex: 1
                }}
            >
                <div
                    style={{
                        flex: 3,
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "flex-start",
                    }}
                >
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    <Typography>
                        <img
                            src={e.type=="organization"?gcp_org:gcp_billing}
                            height={24}
                            style={{ marginRight: 5 }}
                        />
                        {e.name}
                    </Typography>
                </div>
                <div style={{ flex: 3 }}>
                    <Typography>{e.id}</Typography>
                </div>
                <div
                    style={{
                        flex: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <Typography>
                        {count > 1
                            ? count + (e.type === "organization"? " Billing Accounts" : " Projects")
                            : count + (e.type === "organization"? " Billing Account" : " Project")}
                    </Typography>
                </div>
                <div
                    style={{
                        flex: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: 8,
                    }}
                >
                    <Typography>
                        {currency +
                        (isNaN(Number(e?.cost).toFixed(2))
                            ? "--"
                            : Format.formatNumber(Number(e?.cost).toFixed(2))) ||
                        "--"}
                    </Typography>
                </div>
            </div>
            <Divider />
        </>
    );
}

function ProjectComponent({ e, currency, period }) {
    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    background: "#f3f3f3",
                    padding: 8,
                }}
            >
                <div style={{ display: "flex", flex: 1, justifyContent: "flex-start" }}>
          <span>
              <img
                  src={gcp_project}
                  alt="gcp_project"
                  style={{
                      width: 20,
                      height: 20,
                      marginRight: 8,
                  }}
              />
              {e.name}
          </span>
                </div>
                <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                    <span>{e.id}</span>
                </div>
                <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
          <span>
            {currency +
            (isNaN(Number(e?.cost).toFixed(2))
                ? "--"
                : Format.formatNumber(Number(e?.cost).toFixed(2))) ||
            "--"}
          </span>
                </div>
            </div>
            <Divider />
        </>
    );
}
