import { takeLatest, call, put, takeEvery } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getAzureCCKSuccess, getAzureCCKFailure, getAzureCCKSubFailure, getAzureCCKSubSuccess,getAzureCCKTenantSuccess,getAzureCCKTenantFailure } from "../actions/actions";
const { REACT_APP_AZURE_CCK, REACT_APP_AZURE_SUB_CCK,REACT_APP_AZURE_TENANT_CCK } = process.env;

function* getAzureCCKData({ payload }) {
    try {
        const res = yield call(axios.post, REACT_APP_AZURE_CCK, {
            customerId: payload.customerId,
            dataType: payload.dataType,
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureCCKFailure(statusCode));
            } else {
                yield put(getAzureCCKSuccess({ data: res.data, dataType: payload.dataType }));
            }
        } else {
            yield put(getAzureCCKFailure(res));
        }
    } catch (error) {
        yield put(getAzureCCKFailure(error));
    }
}

function* getAzureCCKSubData({ payload }) {
    try {
        const res = yield call(axios.post, REACT_APP_AZURE_SUB_CCK, {
            customerId: payload.customerId,
            tenantId: payload.tenantId,
            subscriptionId: payload.subscriptionId,
            dataType: payload.dataType,
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureCCKSubFailure(statusCode));
            } else {
                yield put(getAzureCCKSubSuccess({ data: res.data, dataType: payload.dataType }));
            }
        } else {
            yield put(getAzureCCKSubFailure(res));
        }
    } catch (error) {
        yield put(getAzureCCKSubFailure(error));
    }
}

function* getAzureCCKtenantData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AZURE_TENANT_CCK, {
            params: {
            customerId: payload.customerId,
            tenantId: payload.tenantId,
            dataType: payload.dataType,
            }
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureCCKTenantFailure(statusCode));
            } else {
                yield put(getAzureCCKTenantSuccess({ data: res.data, dataType: payload.dataType }));
            }
        } else {
            yield put(getAzureCCKTenantFailure(res));
        }
    } catch (error) {
        yield put(getAzureCCKTenantFailure(error));
    }
}

export default function* azureCCKSaga() {
    yield takeEvery(types.AZURE_CCK_REQUEST, getAzureCCKData);
    yield takeEvery(types.AZURE_CCK_SUB_REQUEST, getAzureCCKSubData);
    yield takeEvery(types.AZURE_CCK_TENANT_REQUEST, getAzureCCKtenantData);

}
