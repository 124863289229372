import GetWindowDimension from "components/HOC/GetWindowDimension";
import React from "react";
import ContentLoader, { List } from "react-content-loader";
import colors from "styles/color";

function DashboardLoader(props) {
    const { width, height } = props;
    return (
        <div>
            <ContentLoader height={height} width={width} speed={2} backgroundColor={"#DDDDDD"} foregroundColor={"#A2D2FF"} {...props}>
                <rect x="20" y="10" rx="5" ry="5" width="190" height="80" />
                <rect x="270" y="10" rx="5" ry="5" width="190" height="80" />
                <rect x="540" y="10" rx="5" ry="5" width="190" height="80" />

                {/* Tab bar */}
                <rect x="10" y="125" rx="5" ry="5" width="200" height="30" />
                <rect x="230" y="125" rx="5" ry="5" width="200" height="30" />
                <rect x="500" y="125" rx="5" ry="5" width="200" height="30" />
                <rect x="770" y="125" rx="5" ry="5" width="200" height="30" />
                <rect x="990" y="125" rx="5" ry="5" width="200" height="30" />
                <rect x="1210" y="125" rx="5" ry="5" width="200" height="30" />
                {/* Chart grid loader */}

                <rect x="250" y="280" rx="5" ry="5" width="95" height="200" />
                <rect x="350" y="420" rx="5" ry="5" width="95" height="60" />
                <rect x="450" y="280" rx="5" ry="5" width="95" height="200" />
                <rect x="550" y="300" rx="5" ry="5" width="95" height="180" />
                <rect x="650" y="220" rx="5" ry="5" width="95" height="260" />
                <rect x="750" y="320" rx="5" ry="5" width="95" height="160" />
                <rect x="850" y="180" rx="5" ry="5" width="95" height="300" />
                <rect x="950" y="400" rx="5" ry="5" width="95" height="80" />
                <rect x="1050" y="340" rx="5" ry="5" width="95" height="140" />
                 {/* table */}
                 <rect x="10" y="510" rx="5" ry="5" width="600" height="8" />
                 <rect x="40" y="530" rx="5" ry="5" width="800" height="8" />
                 <rect x="30" y="550" rx="5" ry="5" width="700" height="8" />
                 <rect x="10" y="570" rx="5" ry="5" width="1000" height="8" />
                 <rect x="60" y="590" rx="5" ry="5" width="950" height="8" />
                 <rect x="50" y="610" rx="5" ry="5" width="888" height="8" />
            </ContentLoader>
        </div>
    );
}

export default GetWindowDimension(DashboardLoader);
