import * as types from "../actions/types";

const userState = {
    ProjectData: null,
    ProjectAccountData: null,
    error: null,
    loading: false,
    ProjectAccountLoading: false,
};

function gcpProjectReducer(state = userState, { type, payload }) {
    switch (type) {
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                ProjectData: null,
                ProjectAccountData: null,
                error: null,
                loading: false,
                ProjectAccountLoading: false,
            };
        }
        case types.GCP_PROJECT_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.GCP_PROJECT_SUCCESS: {
            return {
                ...state,
                ProjectData: payload.data,
                loading: false,
            };
        }
        case types.GCP_PROJECT_FAILURE: {
            return {
                ...state,
                loading: false,
                ProjectData: null,
                error: payload,
            };
        }

        case types.GCP_PROJECT_ACCOUNT_REQUEST: {
            return {
                ...state,
                ProjectAccountLoading: true,
            };
        }
        case types.GCP_PROJECT_ACCOUNT_SUCCESS: {
            return {
                ...state,
                ProjectAccountData: payload.data,
                ProjectAccountLoading: false,
            };
        }
        case types.GCP_PROJECT_ACCOUNT_FAILURE: {
            return {
                ...state,
                ProjectAccountLoading: false,
                ProjectAccountData: null,
                error: payload,
            };
        }

        default:
            return state;
    }
}
export default gcpProjectReducer;
