import GetWindowDimension from "components/HOC/GetWindowDimension";
import React, {useEffect, useRef, useState} from "react";
import * as d3 from "d3";
import Format from "components/NumberFormat";

function ReactD3PieChart(props) {
  const { title, cost, currency, data, width, height } = props;
  const ref = useRef();
  const currencySymbol = Format.formatCurrencySymbol(currency);
  const totalCost = Format.formatNumber(cost);

  var colorScale = d3
    .scaleOrdinal()
    .range([
      "#31BEF3",
      "#19A979",
      "#FBD63D",
      "#08619D",
      "#E8743B",
      "steelblue",
    ]);
  const rad = {
    inner: (width + height) / 28,
    outer: (width + height) / 18,
  };
  const margin = {
    top: 40,
    right: width / 3,
    bottom: 10,
    left: width / 3,
  };
  const innerRadius = rad.inner;
  const outerRadius = rad.outer;
  const Svgwidth = 2 * outerRadius + margin.left + margin.right;
  const Svgheight = 2 * outerRadius + margin.top + margin.bottom;

  useEffect(() => {
      // console.log(Object.assign({}, {data, width, height}))
    if(data) {
        drawChart();
    } else {
        drawNoData();
    }
  }, [data, width, height]);

    function drawNoData() {
        d3.select(ref.current).select("g").remove();
        d3.select(ref.current).append("g")
            .attr("transform", `translate(${Svgwidth / 2}, ${Svgheight / 2})`)
            .style("display", "flex")
            .style("align-items", "center")
            .style("justify-content", "center")
            .append('text')
            .text("No data found")
    }

    function drawChart() {
        var myColor = d3.scaleOrdinal().domain(data).range(["#295939"]);
    // Remove the old svg
    d3.select(ref.current).selectAll("g").remove();

    // Create new svg
    const svg = d3
      .select(ref.current)
      .append("g")
      .attr("transform", `translate(${Svgwidth / 2}, ${Svgheight / 2})`);

    const arcGenerator = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);

    const pieGenerator = d3
      .pie()
      .padAngle(0.01)
      .value((d) => d.value);

    const arc = svg.selectAll().data(pieGenerator(data)).enter();

    // Append arcs

    const percentage = (value) => {
      let per = (value / cost) * 100;
      return per.toFixed(2);
    };
    arc
      .append("path")
      .attr("d", arcGenerator)
      .style("fill", (_, i) => colorScale(i))
      .style("stroke", "#fff")
      .style("stroke-width", 0)
      .on("mouseover", (d, i) => {
        // console.log("full pie iiiiiiii ", d);
        svg.selectAll(".total").remove();
        d3.select(this).transition().attr("d", arcGenerator);

        svg
          .append("text")
          .attr("class", "val")
          .attr("y", -15)
          .attr("text-anchor", "middle")
          .style("fill", (d) => myColor(d))
          .style("font-Size", "11px")
          .style("font-Family", "Poppins_SemiBold")
          .text(i.data.label);

        svg
          .append("text")
          .attr("class", "val")
          .attr("y", 5)
          .attr("text-anchor", "middle")
          .style("fill", (d) => myColor(d))
          .text(currencySymbol + Format.formatNumber(i.value));

        svg
          .append("text")
          .attr("class", "val")
          .attr("y", 22)
          .attr("text-anchor", "middle")
          .style("font-Size", "12px")
          .style("fill", "gray")
          .text(percentage(i.value) + "%");
      })
      .on("mouseout", (d, i) => {
        d3.select(this)
          .transition()
          .duration(500)
          // .ease("bounce")
          .attr("d", arcGenerator + 10);
        svg
          .append("text")
          .attr("class", "total")
          .attr("y", 5)
          .attr("text-anchor", "middle")
          .text(currencySymbol + totalCost);
        svg.selectAll(".val").remove();
      });

    // Append text labels
    arc
      .append("text")
      .attr("d", arcGenerator)
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      // .text((d) => d.data.label)
      .style("fill", "black")
      .style("font-Size", (d) => (d.data.label.length > 10 ? "10px" : "12px"))
      .attr("transform", (d) => {
        const [x, y] = arcGenerator.centroid(d);
        return `translate(${x}, ${y})`;
      });

    svg
      .append("text")
      .attr("x", -Svgwidth / 4)
      .attr("y", -Svgheight / 2.2)
      .attr("text-anchor", "middle")
      .style("font-Family", "Poppins_SemiBold")
      .text(title);

    svg
      .append("text")
      .attr("x", Svgwidth / 3.3)
      .attr("y", -Svgheight / 2.2)
      .attr("text-anchor", "middle")
      .style("font-Family", "Poppins_SemiBold")
      .text(currencySymbol + totalCost);

    svg
      .append("text")
      .attr("class", "total")
      .attr("y", 5)
      .attr("text-anchor", "middle")
      .text(currencySymbol + totalCost);

    var legends = svg
      .append("g")
      .selectAll(".legends")
      .data(data)
      .enter()
      .append("g")
      .classed("legends", true)
      .attr("transform", function (d, i) {
        return `translate(0,${i * 25})`;
      });

    legends
      .append("text")
      .attr("x", 220)
      .attr("y", -85)
      .text((d) => d.label);

    legends
      .append("circle")
      .attr("cx", 210)
      .attr("cy", -90)
      .attr("r", "0.5em")
      .attr("fill", (_, i) => colorScale(i));
  }

  return <svg ref={ref} width="100%" height={Svgheight} />;

  // return <div ref={ref}></div>;
}

export default GetWindowDimension(ReactD3PieChart);