import * as types from "../actions/types";

const userState = {
  allAccountStatus: null,
  azureAccountStatus: null,
  awsAccountStatus: null,
  gcpAccountStatus: null,
  loading: false,
  error: null,
};

function accountStatusReducer(state = userState, { type, payload }) {
  switch (type) {
      case types.CLOUD_ACCOUNT_REQUEST: {
          return {
              ...state,
              loading: true,
          };
      }
      case types.CLOUD_ACCOUNT_SUCCESS: {
          return {
              ...state,
              allAccountStatus: payload?.allAccountStatus,
              azureAccountStatus: payload?.azureAccountStatus,
              awsAccountStatus: payload?.awsAccountStatus,
              gcpAccountStatus: payload?.gcpAccountStatus,
              loading: false,
          };
      }
      case types.CLOUD_ACCOUNT_FAIL: {
          return {
              ...state,
              loading: false,
              error: payload,
          };
      }
      case "SWITCH_TEAM_CLEAR": {
          return {
              ...state,
              allAccountStatus: null,
              azureAccountStatus: null,
              awsAccountStatus: null,
              gcpAccountStatus: null,
          };
      }

      default:
          return state;
  }
}
export default accountStatusReducer;
