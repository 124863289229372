import * as types from "../actions/types";

const userState = {
    awsRDSRecommendationData: null,
    awsRDSAccountRecommendationData:null,
    error: null,
    loading: false,
    awsRDSAccountLoading:false
    
};

function awsRDSRecommendationReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.AWS_RDS_RECOMMENDATION_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AWS_RDS_RECOMMENDATION_SUCCESS: {
            return {
                ...state,
                awsRDSRecommendationData: payload.data,
                loading: false,
            };
        }
        case types.AWS_RDS_RECOMMENDATION_FAILURE: {
            return {
                ...state,
                loading: false,
                awsRDSRecommendationData: null,
                error: payload,
            };
        }

        case types.AWS_RDS_ACCOUNT_RECOMMENDATION_REQUEST: {
            return {
                ...state,
                awsRDSAccountLoading: true,
            };
        }
        case types.AWS_RDS_ACCOUNT_RECOMMENDATION_SUCCESS: {
            return {
                ...state,
                awsRDSAccountRecommendationData: payload.data,
                awsRDSAccountLoading: false,
            };
        }
        case types.AWS_RDS_ACCOUNT_RECOMMENDATION_FAILURE: {
            return {
                ...state,
                awsRDSAccountLoading: false,
                awsRDSAccountRecommendationData: null,
                error: payload,
            };
        }
       
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                awsRDSRecommendationData: null,
                awsRDSAccountRecommendationData:null,
                error: null,
                loading: false,
                awsRDSAccountLoading:false
                
            };
        }

        default:
            return state;
    }
}
export default awsRDSRecommendationReducer;
