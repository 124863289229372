import React, { useRef, useEffect, useState } from "react";
import GetWindowDimension from "components/HOC/GetWindowDimension";
import {
  select,
  selectAll,
  scaleOrdinal,
  schemeCategory10,
  csv,
  scaleLinear,
  max,
  scaleBand,
  axisLeft,
  axisBottom,
  format,
  easeLinear,
} from "d3";
import "./styleDrilDown.css";
import * as d3 from "d3";
import Format from "components/NumberFormat";
import { colorScale } from "./ReactD3BarChart";

//------ mouse over tooltip
export const tooltipVB = d3
  .select("body")
  .append("div")
  .attr("class", "toolTipVerticalBarChart");

function ReactDrillDownd3BarChart(props) {
  const { data, title, cost, currency, width, height } = props;
  const [reload, setReload] = useState(null);
  const svgRef = useRef();
  const currencySymbol = Format.formatCurrencySymbol(currency);
  const totalCost = Format.formatNumber(cost);

  function getIndex(v) {
    const svg = select(svgRef.current);
    let data = v.childData;
    VerticalBarChart(svg, data);
  }

  const margin = {
    top: 50,
    right: 80,
    bottom: 25,
    left: 80,
  };

  const chartwidth = width / 2.5 - margin.left - margin.right;
  const chartheight = height / 2.2 - margin.top - margin.bottom;

  useEffect(() => {
    const svg = select(svgRef.current);
    VerticalBarChart(svg, data);
  }, [data, width, height, reload]);

  const VerticalBarChart = (svg, data) => {
    svg.selectAll("g").remove(); // delete graph from svg
    svg.selectAll("text").remove(); // delete text from svg

    data.sort((a, b) => Number(b.value) - Number(a.value), 0);

    //-------- Creating y-axis scale
    const yScale = scaleLinear()
      .range([chartheight, 0])
      .domain([0, max(data, (d) => Number(d.value))]);

    //-------- Creating x-axis scale
    const xScale = scaleBand()
      .range([0, chartwidth])
      .domain(data.map((s) => s.label))
      .padding(0.2);

    //------- creating chart area and passing data
    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    chart
      .append("g")
      .attr("class", "axis axis--y")
      .call(
        axisLeft(yScale)
          .ticks(4)
          .tickFormat((d) => `${currencySymbol}${Format.formatNumberInt(d)}`)
      );

    chart
      .append("g")
      .attr("class", "axis axis--x")
      .attr("transform", `translate(0, ${chartheight})`)
      .call(axisBottom(xScale))
      .selectAll("text")
      .remove();
    // .style("font-Family", "Poppins_Regular")
    // .attr("text-anchor", "end")
    // .attr("dx", "-.8em")
    // .attr("dy", ".15em")
    // .attr("transform", `rotate(-25)`);

    const rect = chart
      .selectAll()
      .data(data)
      .enter()
      .append("rect")
      .attr("x", (s) => xScale(s.label))
      .attr("y", (s) => yScale(s.value))
      .attr("height", (s) => chartheight - yScale(s.value))
      .attr("width", xScale.bandwidth())
      .attr("fill", (d, i) => colorScale(i))
      .on("mouseover", (d, i) => {
        tooltipVB.html(`<div>${i.label}<br> ${currencySymbol + Format.formatNumber(i.value)}</div>`);
      })
      .on("mousemove", function (d, i) {
        select(this)
          .attr("stroke", "none")
          .style("cursor", "pointer")
          .attr("height", (d) => chartheight - yScale(d.value) + 2)
          .attr("y", (d) => yScale(d.value) - 2)
          .attr("width", xScale.bandwidth() + 5)
          .attr("x", (s) => xScale(s.label) - 2)
          .transition()
          .duration(200);
        tooltipVB
          .style("left", d.pageX - 50 + "px")
          .style("top", d.pageY - 70 + "px")
          .style("display", "inline-block");
      })
      .on("mouseout", function (d, i) {
        select(this)
          .attr("stroke", "none")
          .attr("height", (d) => chartheight - yScale(d.value))
          .attr("y", (d) => yScale(d.value))
          .attr("width", xScale.bandwidth())
          .attr("x", (s) => xScale(s.label))
          .transition()
          .duration(200);
        tooltipVB.style("display", "none");
      })
      .on("click", (d, i) => {
        if ("childData" in i && i.childData.length > 0) {
          tooltipVB.style("display", "none");
          getIndex(i);
        } else {
          tooltipVB.style("display", "none");
          setReload(null);
          setReload("true");
        }
      });

    rect
      .transition()
      .ease(easeLinear)
      .duration(1000)
      .attr("y", (s) => yScale(s.value))
      .attr("height", (s) => chartheight - yScale(s.value))
      .delay((d, i) => i * 300);

    chart
      .selectAll()
      .data(data)
      .enter()
      .append("text")
      .attr("class", "val") // add class to text label
      .attr("text-anchor", "middle")
      .attr("x", function (d, i) {
        return xScale(d.label) + xScale.bandwidth() / 2;
      })
      .attr("dx", 0)
      .attr("y", function (d, i) {
        return yScale(d.value);
      })
      .attr("dy", (d) => (d.value < yScale(d.value) ? -2 : 15))
      .style("font-Size", (d) => (d.value > 10000 ? "10px" : "11px"))
      .text((d) => `${currencySymbol}` + Format.formatNumber(d.value));

    //---------------- legend ---------------
    var legendElement = chart
      .append("g")
      .attr("class", "legend__container")
      .attr(
        "transform",
        `translate(${chartwidth},${margin.right - 4 * margin.top})`
      )
      .selectAll("g.legend__element")
      .data(xScale.domain())
      .enter()
      .append("g")
      .attr("transform", function (d, i) {
        return `translate(${15},${i * 25})`;
      });

    legendElement
      .append("text")
      .attr("x", 25)
      .attr("font-size", "12px")
      .text((d) => d);

    legendElement
      .append("rect")
      .attr("x", 0)
      .attr("y", -15)
      .attr("width", 20)
      .attr("height", 20)
      .attr("fill", function (d, i) {
        return colorScale(i);
      });

    svg
      .append("text")
      .attr("class", "title")
      .attr("x", margin.left + margin.top)
      .attr("y", 25)
      .attr("text-anchor", "middle")
      .style("font-Family", "Poppins_SemiBold")
      .text(title);

    svg
      .append("text")
        .attr("id", "d3_grid_currency")
      .attr("class", "totalCost")
      .attr("x", 0)
      .attr("y", 25)
      .attr("text-anchor", "middle")
      .style("font-Family", "Poppins_SemiBold")
      .text(currencySymbol + totalCost);

    const currency = svg.selectAll('#d3_grid_currency');

    currency.attr("transform", `translate(${chartwidth - currency.nodes()[0].getBBox().width + chartheight - 40}, 0)`)

  };

  return <svg ref={svgRef} width="100%" height={height / 2.2} />;
}

export default GetWindowDimension(ReactDrillDownd3BarChart);
