import React, { useEffect, useState } from "react";
import MsalReactAuth from "MsalReactAuth";
import AppLogo from "../images/app_logo.png";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { CircularProgress, Typography, Dialog, IconButton, DialogContent, Paper } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { makeStyles, withStyles } from "MakeWithStyles";
import CloseIcon from "@mui/icons-material/Close";
import ReactPlayer from "react-player";
import Carousel from "react-bootstrap/Carousel";

import backgroundImage from "../images/bg1.jpg";
import backgroundImage1 from "../images/bg2.jpg";
import backgroundImage2 from "../images/bg3.jpg";
import backgroundImage3 from "../images/bg4.jpg";
import {Navigate, Outlet, useNavigate, useOutlet} from "react-router-dom";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import RoutesList from "../routes/RoutesList";

const introVideo = process.env.REACT_APP_CLOUDTRAKR_INTRO;
const REACT_APP_REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

export default function GetStarted() {
    const {classes} = useStyles();
    const loading = useSelector((state) => state.userDetailsReducer.loading);
    const {inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [open, setOpen] = useState(false);
    const [maxWidth, setMaxWidth] = useState("xl");
    const navigate = useNavigate();
    const outlet = useOutlet();

    useEffect(()=>{
        if (isAuthenticated){
            navigate(RoutesList.APP)
        }
    },[isAuthenticated])

    return (
        <div style={{height: '100vh', backgroundColor: '#f1f3f6'}}>
            <div className={classes.header}>
                <div onClick={()=>window.location.href = REACT_APP_REDIRECT_URL} style={{ alignItems: "center", display: "flex", cursor: "pointer" }}>
                    <img className={classes.logoStyle} src={AppLogo} />
                    <span className={classes.headerTextStyle}>CloudTrakr</span>
                </div>
            </div>


            {outlet || <div style={{
                minHeight: "95vh",
                padding: 24,
                background: "linear-gradient(to right, #ffffff 20%, #4b6cb7 100%)"
            }}>
                <div style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <div style={{flexDirection: "column", display: "flex"}}>
                        <span className={classes.textStyle}>Manage your</span>
                        <span className={classes.textStyle}>multi-cloud cost</span>
                        <span className={classes.textStyle}>in one place</span>
                        <span className={classes.grayTextStyle}>Maximum savings and improve efficiency.</span>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <Button style={{marginTop: 16}} className={classes.getStarted}
                                    onClick={MsalReactAuth().LoginHandler}>
                                {loading ?
                                    <CircularProgress size={25} color="secondary"/> + "loading..." : "login / Signup"}
                            </Button>
                        </div>
                    </div>
                    <div style={{
                        justifyContent: "center",
                        height: "50%",
                        width: "45%",
                        alignSelf: "center",
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <ReactPlayer
                            width={"80%"}
                            height={"300px"}
                            url={introVideo}
                            controls
                            playing
                            muted
                            config={{
                                file: {
                                    attributes: {
                                        autoPlay: true,
                                        muted: true,
                                    },
                                },
                            }}
                        />
                        <div style={{marginTop: 8, width: "80%"}}>
                            <Carousel controls={false} pause={"hover"}>
                                <Carousel.Item>
                                    <img style={{height: 300, width: 300}} className="d-block w-100"
                                         src={backgroundImage} alt="Second slide"/>

                                    <Carousel.Caption>
                                        <h3>SPEND LESS DO MORE.</h3>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img style={{height: 300, width: 300}} className="d-block w-100"
                                         src={backgroundImage1} alt="Second slide"/>

                                    <Carousel.Caption>
                                        <h3>VISUALIZE</h3>
                                        <p>Visualize cost breakdowns of each cloud.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img style={{height: 300, width: 300}} className="d-block w-100"
                                         src={backgroundImage2} alt="Third slide"/>

                                    <Carousel.Caption>
                                        <h3>SET</h3>
                                        <p>Set budget and keep your expenses in check.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img style={{height: 300, width: 300}} className="d-block w-100"
                                         src={backgroundImage3} alt="Fourth slide"/>

                                    <Carousel.Caption>
                                        <h3>GET</h3>
                                        <p>Get customized cost, security and performance recommendations.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    );
}

const useStyles = makeStyles()({
    header: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: 20,
        paddingRight: 20,
        flexDirection: "row",
        height: 40,
        backgroundColor: "#3f51b5",
    },
    getStarted: {
        background: "linear-gradient(45deg, #3f51b5 50%, #3f51b5 100%)",
        border: 0,
        borderRadius: 3,
        boxShadow: "0 3px 5px 2px rgba(63, 81, 181, .3)",
        color: "white",
        height: 50,
        padding: "0 40px",
        width: "60%",
        marginRight: 4,
    },
    getStarted1: {
        borderRadius: 3,
        border: "1px solid black",
        boxShadow: "0 3px 5px 2px rgba(63, 81, 181, .3)",
        color: "black",
        height: 50,
        padding: "0 40px",
        width: "16%",
        marginLeft: 4,
    },
    containerStyle: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginLeft: 50,
        display: "flex",
        alignItems: "center",
    },
    contentStyle: {
        flexDirection: "column",
        marginLeft: 16,
        display: "flex",
        justifyContent: "center",
    },
    textStyle: {
        fontWeight: "bold",
        fontSize: 40,
    },
    grayTextStyle: {
        fontWeight: "bold",
        fontSize: 26,
        color: "gray",
    },
    logoStyle: {
        height: 30,
    },
    headerTextStyle: {
        fontSize: 16,
        marginLeft: 16,
        color: "white",
    },
});
