import * as types from "../actions/types";

const userState = {
    awsEC2RecommendationData: null,
    awsEC2AccountRecommendationData:null,
    error: null,
    loading: false,
    awsEC2AccountLoading:false
    
};

function awsEC2RecommendationReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.AWS_EC2_RECOMMENDATION_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AWS_EC2_RECOMMENDATION_SUCCESS: {
            return {
                ...state,
                awsEC2RecommendationData: payload.data,
                loading: false,
            };
        }
        case types.AWS_EC2_RECOMMENDATION_FAILURE: {
            return {
                ...state,
                loading: false,
                awsEC2RecommendationData: null,
                error: payload,
            };
        }

        case types.AWS_EC2_ACCOUNT_RECOMMENDATION_REQUEST: {
            return {
                ...state,
                awsEC2AccountLoading: true,
            };
        }
        case types.AWS_EC2_ACCOUNT_RECOMMENDATION_SUCCESS: {
            return {
                ...state,
                awsEC2AccountRecommendationData: payload.data,
                awsEC2AccountLoading: false,
            };
        }
        case types.AWS_EC2_ACCOUNT_RECOMMENDATION_FAILURE: {
            return {
                ...state,
                awsEC2AccountLoading: false,
                awsEC2AccountRecommendationData: null,
                error: payload,
            };
        }
       
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                awsEC2RecommendationData: null,
                awsEC2AccountRecommendationData:null,
                error: null,
                loading: false,
                awsEC2AccountLoading:false
                
            };
        }

        default:
            return state;
    }
}
export default awsEC2RecommendationReducer;
