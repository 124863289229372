import * as types from "../actions/types";

const userState = {
  connections: null,
  awsConnections: null,
  gcpConnections: null,
  error: null,
  loading: false,
  azureConnectionErrors: null,
  awsConnectionErrors: null,
  gcpConnectionErrors: null,
  deletedErrors: [],
};

function connectionErrorReducer(state = userState, { type, payload }) {
  switch (type) {
      case "SWITCH_TEAM_CLEAR": {
          return {
              ...state,
              connections: null,
              awsConnections: null,
              gcpConnections: null,
              error: null,
              loading: false,
              azureConnectionErrors: null,
              awsConnectionErrors: null,
              gcpConnectionErrors: null,
              deletedErrors: [],
          };
      }
      case types.GET_CONNECTIONERROR_REQUEST: {
          return {
              ...state,
              loading: true,
          };
      }
      case types.GET_CONNECTIONERROR_SUCCESS: {
          return {
              ...state,
              connections: payload.message.connections,
              awsConnections: payload.message.connections,
              gcpConnections: payload.message.connections,

              azureConnectionErrors: payload.message.connections[0]?.azure.tenant
                  .map((t) => {
                      const data = {
                          ...t,
                          tenantId: t.tenantId,
                          tenantName: t.tenantName,
                          lastModified: t.lastModified,
                          status: t.status,
                          subscription: t.subscriptions,
                      };
                      delete data.errors;
                      return data;
                  })
                  .flat(1),

              awsConnectionErrors: payload.message.connections?.[0]?.aws?.tenant?.map((s) => {
                  const data = {
                      ...s,
                      error_desc: s.errors?.[0]?.aws,
                  };
                  delete data.errors;
                  return data;
              }),
              //   gcpConnectionErrors:
              //   payload.message.connections?.[0]?.gcp?.tenant?.[0]?.subscriptions.map(
              //     (s) => {
              //       const data = {
              //         ...s,
              //         error_desc: s.errors.error_desc,
              //       };
              //       delete data.errors;
              //       return data;
              //     }
              //   ),
              loading: false,
          };
      }
      case types.GET_CONNECTIONERROR_FAILURE: {
          return {
              ...state,
              loading: false,
              connections: null,
              azureConnectionErrors: [],
              error: payload,
          };
      }
      case types.SET_DELETED_ERROR: {
          return {
              ...state,
              deletedErrors: [...state.deletedErrors, payload],
          };
      }
      case "AZURE_DELETE_ERROR": {
          return {
              ...state,
              connections: null,
          };
      }
      case "AWS_DELETE_ERROR": {
          return {
              ...state,
              awsConnections: null,
          };
      }
      case "GCP_DELETE_ERROR": {
          return {
              ...state,

              gcpConnections: null,
          };
      }

      default:
          return state;
  }
}
export default connectionErrorReducer;
