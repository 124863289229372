import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "MakeWithStyles";
import { LinearProgress, Paper, Collapse, IconButton, CircularProgress } from "@mui/material";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import AzureServicesTable from 'components/table/AzureServicesTable';
import Format from "components/NumberFormat";
import moment from "moment";
import HtmlECTable from "components/table/HtmlECTable";
import azure_service from "../../images/newCloudTrakrIcons/AzureServices.png";
import { getAzureSubcriptionServiceRequest,getAzureServiceRequest,getAzureTenantServiceRequest } from "redux/actions/actions";
import TableSearch from "../../components/TableSearch";
import TableMonthlySpend from "../AllClouds/TableMonthlySpend";
import NO_DATA_AVAILABLE from "../../components/NO_DATA_AVAILABLE";
import ResourceGroupsIcon from "../../images/resources/Resource_Group.svg";

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        //marginBottom: 5,
    },
}));

export default function AzureServices({ showData, currency,tenantId = null, subscriptionId = null, customerId, spendByMonthServicesData, loadingCalendarTableData, parentWidth }) {
    const {classes} = useStyles();
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [ytdSearch, setYTDSearch] = useState(null);

   
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    let azureServicesData = useSelector((state) => subscriptionId ? state?.azureServicesReducer?.azureServicesSubscriptionData:(tenantId) ? state?.azureServicesReducer?.azureTenantData : state?.azureServicesReducer?.azureServicesData);
    let loading = useSelector((state) => state?.azureServicesReducer?.loading );
      
    useEffect(() => {
        if (showData.all != null) {
            if (azureServicesData == null) {
                dispatch(getAzureServiceRequest({customerId}));
            }
        } else if(tenantId){
            const { tenantId } = showData;
            dispatch(
                getAzureTenantServiceRequest({
                    customerId,
                    tenantId,
                })
            );
        }else {
            const { tenantId, subscriptionId } = showData;
            dispatch(
                getAzureSubcriptionServiceRequest({
                    customerId,
                    tenantId,
                    subscriptionId,
                })
            );
        }
    }, [customerId, showData, tenantId]);

    
    return loading ? (
        <div className={classes.root}>
            <LinearProgress />
        </div>
    ) : (
        <div>
            <ContainerCardWithDateRange
                title={"Services"}
                titleIcon={azure_service}
                titleIconHeight = {26}
                defaultPeriod={"1M"}
                showDateRange={true}
                collapsible={true}
                datePeriod={({ period }) => ({
                    start: "__",
                    end: "__",
                    rawStart:moment(azureServicesData?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd:moment(azureServicesData?.[period]?.endDate, "YYYYMMDD")
                })}
                totalCost={({ period }) =>currency + Format.formatNumber(azureServicesData?.[period]?.totalCost) }
                datePeriodEnabled={true}
                showDatePeriod={(period) => azureServicesData?.[period]?.services?.length}
                onPeriodChange={() => handleChangeRowsPerPage({ target: { value: 10 } })}
            >
                {({ period }) => (
                    <>
                            <div>
                                {azureServicesData != null ? (
                                    azureServicesData &&  azureServicesData?.[period]?.services ? (
                                        <AzureServicesTable result={azureServicesData?.[period]?.services} currency={currency} />
                                    ) : (
                                        <div style={{ textAlign: "center" }}>No data found</div>
                                    )
                                ) : null}
                            </div>
                         
                    </>
                )}
            </ContainerCardWithDateRange>
            <br/>
            <div style={{}}>
                <ContainerCardWithDateRange title={"Spend by months"}
                                            titleIcon={azure_service}
                                            showDateRange={false}
                                            collapsible={true}>
                    {
                        ()=>(
                            <>
                                {(loadingCalendarTableData || spendByMonthServicesData)?<TableSearch onSearch={setYTDSearch}/>:null}
                                {(loadingCalendarTableData || spendByMonthServicesData)?<TableMonthlySpend
                                    loading={loadingCalendarTableData} cols={14}
                                    subscriptionNameAsOverlay={false}
                                    subscriptionLevel={true}
                                    tableLabel={"Service"}
                                    width={parentWidth}
                                    data={spendByMonthServicesData?.list?.filter(s => {
                                        if (ytdSearch && ytdSearch.toString().length) {
                                            return !!TableSearch.doesItIncludes(
                                                [
                                                    s.cloud,
                                                    s.subscriptionId,
                                                    s.name
                                                ],
                                                ytdSearch
                                            );
                                        }
                                        return true;
                                    })}
                                    staticRows={spendByMonthServicesData?.other}
                                    currency={currency}
                                    showCloud={false}
                                />:<NO_DATA_AVAILABLE height={'auto'}/> }
                            </>
                        )
                    }
                </ContainerCardWithDateRange>
            </div>
        </div>
    );
}
