import * as types from "../actions/types";

const userState = {
    cosmosDBData: null,
    loading: false,
    error: null,
    cosmosTableData: null,
    cosmosTableLoading: null,
    cosmos3MMetric: null,
    cosmos3MMetricLoading: null,
    cosmosDBTenantData:null
};

function azureCosmosDBReducer(state = userState, { type, payload }) {
    switch (type) {
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                cosmosDBData: null,
                loading: false,
                error: null,
            };
        }
        case types.AZURE_COSMOSDB_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AZURE_COSMOSDB_SUCCESS: {
            return {
                ...state,
                cosmosDBData: payload.data,
                loading: false,
            };
        }
        case types.AZURE_COSMOSDB_FAILURE: {
            return {
                ...state,
                loading: false,
                cosmosDBData: null,
                error: payload,
            };
        }

        case types.AZURE_COSMOSDB_TENANT_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AZURE_COSMOSDB_TENANT_SUCCESS: {
            return {
                ...state,
                cosmosDBTenantData: payload.data,
                loading: false,
            };
        }
        case types.AZURE_COSMOSDB_TENANT_FAILURE: {
            return {
                ...state,
                loading: false,
                cosmosDBTenantData: null,
                error: payload,
            };
        }

        case types.AZURE_COSMOSDB_1Y_TABLE_DATA_REQUEST: {
            return {
                ...state,
                cosmosTableLoading: true,
                cosmosTableData: null
            };
        }
        case types.AZURE_COSMOSDB_1Y_TABLE_DATA_SUCCESS: {
            return {
                ...state,
                cosmosTableLoading: false,
                cosmosTableData: payload.data
            };
        }
        case types.AZURE_COSMOSDB_1Y_TABLE_DATA_FAILURE: {
            return {
                ...state,
                cosmosTableLoading: false,
                cosmosTableData: null,
                error: payload,
            };
        }

        case types.AZURE_COSMOSDB_3M_METRIC_REQUEST: {
            return {
                ...state,
                cosmos3MMetricLoading: true,
                cosmos3MMetric: null
            };
        }
        case types.AZURE_COSMOSDB_3M_METRIC_SUCCESS: {
            return {
                ...state,
                cosmos3MMetric: payload.data,
                cosmos3MMetricLoading: false,
            };
        }
        case types.AZURE_COSMOSDB_3M_METRIC_FAILURE: {
            return {
                ...state,
                cosmos3MMetricLoading: false,
                cosmos3MMetric: null,
                error: payload,
            };
        }

        default:
            return state;
    }
}
export default azureCosmosDBReducer;
