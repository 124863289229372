const colors = {
  skyBlue: "#31BEF3",
  yellow: "#FBD63D",
  white: "#EFEFEF",
  green: "#81C784",
  background: "#f5f5f5",
  tableHeader: "#cfdac8",
  blue: "#3f51b5",
  gray: "#a9a9a9",
  lightGreen:'#39b83e',

  // cond1: 'rgba(33,218,148,0.58)',
  // cond2: '#724646',
  // cond3: '#ff0000',
  // cond4: '#ffb900',
  // cond5: '#137300',
  // cond6: '#19537e',
  // cond7: 'rgba(101,14,203,0.58)',
  // cond8: 'rgb(207,82,255)',

  // cond1: "#000000",
  // cond2: "#333333",
  // cond3: "#000080",
  // cond4: "#006400",
  // cond5: "#8B0000",
  // cond6: "#800080",
  // cond7: "#654321",
  // cond8: "#800000",

  cond1: "#3a86ff",
  cond2: "#8338ec",
  cond3: "#ff006e",
  cond4: "#fb5607",
  cond5: "#775707",
  cond6: "#006d77",
  cond7: "#3d405b",
  cond8: "#a0a02f",
};
export default colors;
