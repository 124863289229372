import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "MakeWithStyles";
import { Table, TableBody, TableCell as TCell, TableContainer,TablePagination, TableHead, TableRow, Paper, Box, Typography, Divider, LinearProgress } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import Format from "components/NumberFormat";
import moment from "moment";
import Color from "../../styles/color";
import { getAWSLambdaRequest, getAWSLambdaAccountRequest } from "../../redux/actions/actions";
import Cost from "../../components/Cost";
import lambdaFunction from "../../images/newCloudTrakrIcons/AWSLambda.png";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";

import { lambdaCommonConversion } from "../../util/LambdaFunctionFormatter";
import {CancelRounded} from "@mui/icons-material";
import {bytesToSize, convertToInternationalCurrencySystem} from "../../util/Miscellaneous";
import colors from "../../styles/color";
import axios from "../../connection/axios";
import LineChart from "../Charts/LineChart";
import TablePaginationActions from "../../components/TablePaginationActions";
import TableSearch from "../../components/TableSearch";
import {OverlayTrigger, Tooltip, Popover} from "react-bootstrap";

import Collapse from "@mui/material/Collapse";

import IconButton from "@mui/material/Icon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
const {REACT_APP_AWS_LAMBDA_DETAILS, REACT_APP_AWS_LAMBDA_METRICS} = process.env;

const StyledTableCell = withStyles(TableCell,(theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: theme.spacing(1.5),
    },
}));
const StyledTableRow = withStyles(TableRow,(theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            // backgroundColor: theme.palette.action.hover,
        },
    },
}));

function TableCell(props) {
    return (
        <TCell {...props} style={{ padding: 0,paddingRight:8  }}>
            {props.children}
        </TCell>
    );
}

const useRowStyles = makeStyles()((theme) => ({
    root: {
        "& > *": {
            padding: theme.spacing(1.5),
        },
    },
    table: {
        // minWidth: 700,
    },
    container: {
        maxHeight: 450,
    },
    link: {
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

export default function AWSLambdaFunction({ currency, tenantId, subscriptionId, customerId }) {
    const dispatch = useDispatch();
    // const {classes} = useStyles();
    const [progress, setProgress] = useState(0);
    const [selected, setSelected] = React.useState([]);
    const [list, setList] = useState(null);
    const [total, setTotal] = useState("");
    // const [lambdaData, setLambdaData] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [totalCost, setTotalCost] = useState("");
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [showingDetails, setShowingDetails] = React.useState(null);
    const [showingMetricDetails, setShowingMetricDetails] = React.useState(null);
    const [metricTotals, setMetricTotals] = React.useState(null);
    const [showMetricSpecific, setShowMetricSpecific] = useState([]);
    const [metricDetailsLoading, setMetricDetailsLoading] = React.useState(false);
    const {classes} = useRowStyles();
    let awsLambda = useSelector((state) => (subscriptionId != "all" && tenantId ? state?.awsLambdaReducer?.lambdaAccountData : state?.awsLambdaReducer?.lambdaData));
    let loading = useSelector((state) => (subscriptionId != "all" && tenantId ? state?.awsLambdaReducer?.lambdaAccountLoading : state?.awsLambdaReducer?.loading));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (customerId) {
            if (awsLambda == null && !tenantId && subscriptionId == "all") {
                dispatch(
                    getAWSLambdaRequest({
                        customerId,
                    })
                );
            } else if (subscriptionId && tenantId) {
                dispatch(
                    getAWSLambdaAccountRequest({
                        customerId,
                        tenantId,
                        subscriptionId,
                    })
                );
            }
        }
    }, [customerId, subscriptionId, tenantId]);

    // useEffect(() => {
    //     if (awsLambda !== null) {
    //         let allArray = [];

    //         if (subscriptionId != "all" && tenantId) {
    //             if (awsLambda?.billing?.length > 0) {
    //                 awsLambda && allArray.push(...awsLambda?.billing);
    //             } else {
    //                 allArray.push([]);
    //             }
    //         } else {
    //             if (awsLambda?.length > 0) {
    //                 awsLambda &&
    //                     awsLambda?.map((d) => {
    //                         d.subscriptions?.map((s) => {
    //                             allArray.push(...s.billing);
    //                         });
    //                     });
    //             }
    //         }

    //         const { services, startDate, endDate, totalCost } = lambdaCommonConversion(allArray);

    //         setLambdaData(services);
    //         setStartDate(startDate);
    //         setEndDate(endDate);
    //         setTotalCost(totalCost);
    //     }
    // }, [awsLambda]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    function resolveDateTime(dateTime) {
        const date = moment(dateTime);
        return date.format("LL") + " at " + date.format("LTS");
    }

    function showLambdaFunctionDetails(data){
        // if(showingDetails?.TableName != data?.resourceId){
        //     getLambdaDynamoDBDetails(data)
        //     getLambdaDynamoDBMetrics(data)
        // }
    }

    async function getLambdaDynamoDBDetails(data){
        // setDetailsLoading(true);
        setShowingDetails(null);
        return axios.post(REACT_APP_AWS_LAMBDA_DETAILS, {
            customerId,
            functionName: data.resourceId,
            tenantId: data.payerAccountId,
            region: data.location
        })
            .then(response => response.data)
            .then(response => {
                if(response?.data){
                    setShowingDetails({...data, ...response?.data})
                }
            })
            // .finally(() => setDetailsLoading(false));
    }

    async function getLambdaDynamoDBMetrics(data) {
        setMetricDetailsLoading(true);
        setShowingMetricDetails(null);
        setMetricTotals(null);
        return axios
            .post(REACT_APP_AWS_LAMBDA_METRICS, {
                customerId,
                functionName: data.resourceId,
                tenantId: data.payerAccountId,
                region: data.location
            })
            .then((response) => response.data)
            .then((response) => {
                if (response?.data) {
                    const final = [];
                    for(var o in response?.data){
                        const data = response?.data?.[o]?.[0];
                        const timeseries = data.Timestamps.map((t,i)=>({
                            date: new Date(t), value: data?.Values[i]
                        }));
                        const label = data.Label.match(/[A-Z][a-z]+/g).join(' ');
                        const unit = data.Id.match(/[A-Z][a-z]+/g).join(' ');
                        final.push({label, timeseries, unit: unit === "Sum"?"Count":unit});
                    }
                    setShowingMetricDetails(final);
                    setMetricTotals(
                        final.map((r, i) => (r?.timeseries.map((r) => r.value).reduce((a, b) => a + b) / 1).toFixed(2))
                    );
                }
            })
            .finally(() => setMetricDetailsLoading(false));
    }

    const [search, setSearch] = useState(null);

    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.resourceId,
                f?.meters?.[0]?.productFamily,
                f?.location,
                f?.usageAccountId,
                f?.usageAccountName
            ], search);
        }
        return true;
    }

    return loading ? (
        <LinearProgress variant="determinate" value={progress} />
    ) : (
        <div>
            <ContainerCardWithDateRange
                title={"Lambda functions"}
                titleIcon={lambdaFunction}
                showDateRange={true}
                collapsible={true}
                // ExtraHeaderComponent={({ period }) =>
                //     awsLambda != null && awsLambda?.[period]?.resources?.length ? (
                //         <div style={{ marginTop: 4 }}>
                //             <span>{moment(awsLambda?.[period]?.startDate, "YYYYMMDD").format("MMM DD, YYYY")}</span> - <span>{moment(awsLambda?.[period]?.endDate, "YYYYMMDD").format("MMM DD, YYYY")}</span>
                //         </div>
                //     ) : null
                // }
                // MiddleComponent={({ period }) =>
                // awsLambda?.[period]?.totalCost && (
                //         <span style={{ display: "flex", justifyContent: "flex-end" }}>
                //             Total: {currency}
                //             {Format.formatNumber(awsLambda?.[period]?.totalCost)}
                //         </span>
                //     )
                // }
                datePeriod={({ period }) => ({
                    start:"__",
                    end: "__",
                    rawStart: moment(awsLambda?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(awsLambda?.[period]?.endDate, "YYYYMMDD"),
                })}
                totalCost={({ period }) => currency + Format.formatNumber(awsLambda?.[period]?.totalCost)}
                datePeriodEnabled={true}
                showDatePeriod={(period) => !!awsLambda != null && awsLambda?.[period]?.resources?.length}
                onPeriodChange={() => handleChangeRowsPerPage({ target: { value: 10 } })}
            >
                {({ period }) => (
                    <div className={classes.root}>
                        {awsLambda != null && awsLambda?.[period]?.resources?.length ? (
                            <div>
                                <Paper elevation={3}>
                                    <Box display={'flex'} flex={1}>
                                        <Box flex={showingDetails ? 0.30 : 1}>
                                            <TableSearch onSearch={setSearch} />
                                            <TableContainer style={{ height: 350 }} className={classes.container}>
                                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell/>
                                                    <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                        Function name
                                                    </StyledTableCell>

                                                    {/* <StyledTableCell align="center" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                Service name
                                            </StyledTableCell> */}

                                                    {showingDetails?null:(<>

                                                        <StyledTableCell align="left"
                                                                         style={{fontSize: 17, fontWeight: "bold"}}>
                                                            Location
                                                        </StyledTableCell>

                                                        <StyledTableCell align="left"
                                                                         style={{fontSize: 17, fontWeight: "bold"}}>
                                                            AWS Account
                                                        </StyledTableCell>


                                                        <StyledTableCell align="left"
                                                                         style={{fontSize: 17, fontWeight: "bold"}}>
                                                            Cost
                                                        </StyledTableCell>
                                                    </>)}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(awsLambda?.[period]?.resources || [])
                                                    ?.filter(filteredTable)
                                                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((r, i) => (
                                                    <MyTableRow 
                                                    data={awsLambda} 
                                                    row={r} 
                                                    index={i} 
                                                    list={list} 
                                                    setList={setList} 
                                                    currency={currency}
                                                    showingDetails={showingDetails}
                                                    setShowingDetails={showLambdaFunctionDetails}
                                                    />
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                        </Box>
                                        <Box display={showingDetails?'flex':'none'} flex={showingDetails ? 0.7 : 0}>
                                            <Paper style={{flex:1, padding: 5}} elevation={3}>
                                                <Box display={"flex"} alignItems={"center"}>
                                                    <Box display={"flex"} flex={1} justifyContent={"space-between"} style={{ paddingRight: 10 }} alignItems={"center"}>
                                                        <Typography component={"h4"} variant={"h4"}>
                                                            <img src={lambdaFunction} height={30} /> {" "}{showingDetails?.FunctionName}
                                                        </Typography>
                                                        <Typography component={"h5"} variant={"h5"}>
                                                            {currency}
                                                            {showingDetails?.cost?
                                                                <Cost>
                                                                    {showingDetails?.cost}
                                                                </Cost>:
                                                                "__.__"
                                                            }
                                                        </Typography>
                                                      </Box>
                                                    <CancelRounded onClick={() => setShowingDetails(null)} />
                                                </Box>
                                                <Divider />
                                                <Box style={{ padding: 10 }} flexDirection={"row"} display={"flex"}>
                                                    <Box flex={1}>
                                                        <Typography>
                                                            <b>Function Arn</b>
                                                        </Typography>
                                                        <Typography>{showingDetails?.FunctionArn || "-"}</Typography>
                                                        <Typography style={{ marginTop: 10 }}>
                                                            <b>Runtime</b>
                                                        </Typography>
                                                        <Typography>{showingDetails?.Runtime || "-"}</Typography>
                                                        <Typography style={{ marginTop: 10 }}>
                                                            <b>Role</b>
                                                        </Typography>
                                                        <Typography>{showingDetails?.Role || "-"}</Typography>
                                                        <Typography style={{ marginTop: 10 }}>
                                                            <b>Code Size</b>
                                                        </Typography>
                                                        <Typography>{bytesToSize(showingDetails?.CodeSize)}</Typography>
                                                        <Typography style={{ marginTop: 10 }}>
                                                            <b>Last Modified</b>
                                                        </Typography>
                                                        <Typography>{resolveDateTime(showingDetails?.LastModified)}</Typography>
                                                        <Typography style={{ marginTop: 10 }}>
                                                            <b>Version</b>
                                                        </Typography>
                                                        <Typography>{showingDetails?.Version}</Typography>
                                                        <Typography style={{ marginTop: 10 }}>
                                                            <b>Description</b>
                                                        </Typography>
                                                        <Typography>{showingDetails?.Description || "-"}</Typography>

                                                        {/*<Typography style={{marginTop: 10}}><b>Cost</b></Typography>*/}
                                                        {/*<Typography>{currency}<Cost>{showingDetails?.billing.map(b=>parseFloat(b.cost)).reduce((a,b)=>a+b, 0).toFixed(2)}</Cost></Typography>*/}
                                                    </Box>
                                                    <Box flex={1}>
                                                        <Typography>
                                                            <b>State</b>
                                                        </Typography>
                                                        <Typography>{showingDetails?.State || "-"}</Typography>
                                                        <Typography style={{ marginTop: 10 }}>
                                                            <b>Last Update Status</b>
                                                        </Typography>
                                                        <Typography>{showingDetails?.LastUpdateStatus}</Typography>
                                                        <Typography style={{ marginTop: 10 }}>
                                                            <b>Timeout</b>
                                                        </Typography>
                                                        <Typography>{showingDetails?.Timeout || "-"}</Typography>
                                                        <Typography style={{ marginTop: 10 }}>
                                                            <b>Memory Size</b>
                                                        </Typography>
                                                        <Typography>{bytesToSize(showingDetails?.MemorySize) || "-"}</Typography>
                                                        <Typography style={{ marginTop: 10 }}>
                                                            <b>Revision Id</b>
                                                        </Typography>
                                                        <Typography>{showingDetails?.RevisionId || "-"}</Typography>
                                                        <Typography style={{ marginTop: 10 }}>
                                                            <b>Package Type</b>
                                                        </Typography>
                                                        <Typography>{showingDetails?.PackageType || "-"}</Typography>
                                                        {/*<Typography style={{ marginTop: 10 }}>*/}
                                                        {/*    <b>Size</b>*/}
                                                        {/*</Typography>*/}
                                                        {/*<Typography>{showingDetails?.subscription_id}</Typography>*/}
                                                    </Box>
                                                </Box>
                                                <Divider />
                                                {metricDetailsLoading?
                                                    (
                                                        <LinearProgress variant="determinate" value={progress} />
                                                    ):
                                                    <Box padding={2}>
                                                        {showingMetricDetails && showingMetricDetails?.length
                                                            ? showingMetricDetails.map((m, i) => (
                                                                <>
                                                                    <Box>
                                                                        <Typography>{m.label}</Typography>
                                                                        <LineChart
                                                                            onMouseOverData={(data) => {
                                                                                let d = showMetricSpecific;
                                                                                d[i] = data;
                                                                                setShowMetricSpecific(d);
                                                                            }}
                                                                            data={m?.timeseries}
                                                                            currency={currency}
                                                                            currencyRequired={false}
                                                                            unit={m?.unit}
                                                                        />
                                                                    </Box>
                                                                    <Box style={{marginTop: 10}}>
                                                                        <Typography>
                                                                            {m.label}:{" "}
                                                                            {
                                                                                {
                                                                                    Count: convertToInternationalCurrencySystem(showMetricSpecific?.[i] || metricTotals?.[i]),
                                                                                    Bytes: bytesToSize(showMetricSpecific?.[i] || metricTotals?.[i]),
                                                                                    Percent: (showMetricSpecific?.[i] || metricTotals?.[i]) + "%",
                                                                                    Milliseconds: convertToInternationalCurrencySystem(showMetricSpecific?.[i] || metricTotals?.[i]) + " ms"
                                                                                }[m?.unit]
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                    <hr/>
                                                                </>
                                                            ))
                                                            : null}
                                                    </Box>
                                                }
                                            </Paper>
                                        </Box>
                                    </Box>
                                    <TablePagination ActionsComponent={TablePaginationActions}
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={awsLambda?.[period]?.resources?.filter(filteredTable)?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </Paper>
                            </div>
                        ) : (
                            <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                        )}
                    </div>
                )}
            </ContainerCardWithDateRange>
            <br />
        </div>
    );
}

export function MyTableRow(props) {
    const { data, row, index, list, setList, currency, showingDetails, setShowingDetails } = props;
    const [open, setOpen] = useState(false);
    const {classes} = useRowStyles();

    return (
        <React.Fragment>
            <StyledTableRow hover className={classes.root} key={row.resourceId} style={{ cursor: "pointer" }} onClick={() => setShowingDetails(row)}>
                 <TableCell>
                  <IconButton
                      style={{ cursor: "pointer" }}
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                          setOpen(!open);
                          setList(index);
                      }}
                  >
                      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                 </TableCell>
                <TableCell align="left">
                    {" "}
                    <img src={lambdaFunction} height={20} />
                    <span> {row?.resourceId || <span style={{ color: colors.gray }}> {row?.meters?.[0]?.productFamily} </span>}</span>
                </TableCell>
                {/* <TableCell align="center">{row?.serviceName}</TableCell> */}
                {showingDetails != null ? null : (
                    <>
                        <TableCell align="left">{row?.location}</TableCell>
                        <TableCell align="left">

                            {"usageAccountName" in row ?
                           <OverlayTrigger placement="right" overlay={<Popover id="popover-contained">{"usageAccountName" in row &&
                          <Popover.Body style={{ fontSize: 11 }}>{row?.usageAccountName}</Popover.Body>}</Popover>}>
                           <span style={{whiteSpace: 'nowrap'}}>
                            {row?.usageAccountId || row.usageAccountName}
                        </span>
                        </OverlayTrigger>
                        :row?.usageAccountId || data?.usageAccountId}
                        </TableCell>
                        <TableCell align="left"> {currency + Format.formatNumber(Number(row?.cost).toFixed(2))}</TableCell>{" "}
                    </>
                )}
            </StyledTableRow>
            <ExpandLavelData data={row} open={open} select={list} currency={currency} />

        </React.Fragment>
    );
}

export function ExpandLavelData({ data, open, select, set, currency }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                       <Box margin={2.5}>
                            <Htmltable data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <div style={{ maxHeight: 300 }} className="overflow-auto">
            <table id="customers">
                 {data?.meters && Object.keys(data?.meters?.[0])?.length ? (
                    <tr>
                        <th>Operation</th>
                        <th>Product family</th>
                        <th>Usage type</th>
                        <th>Description</th>
                        <th>Total</th>
                    </tr>
                ) : null}

                        {data &&
                    data?.meters
                        ?.sort((a, b) => {
                            return parseFloat(b?.cost || 0) - parseFloat(a?.cost || 0);
                        })
                        .map((m) =>
                            Object.keys(m)?.length ? (
                                <tr>
                                    <td>{m?.operation||'-'}</td>
                                    <td>{m?.productFamily||'-'}</td>
                                    <td>{m.LineItemUsageType||'-'}</td>
                                    <td>{m.lineItemDescription||'-'}</td>

                                    <td>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                                </tr>
                            ) : null
                        )}
            </table>
        </div>
    );
}
