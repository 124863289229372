import React, { useState, useEffect } from "react";
import { similarObject } from "./ConversionFunc";
import "./style.css";
import S3BucketTable from "./S3BucketTable";
import S3SummaryCards from "./S3SummaryCards";
import Format from "components/NumberFormat";
import S3Icon from "../../images/newCloudTrakrIcons/AWSS3.png";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import moment from "moment";

export default function AccountS3BucketList({ data, customerId }) {
    const [list, setList] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [totalCost, setTotalCost] = useState(0.003);
    const [sum, setSum] = useState(null);

    // useEffect(() => {
    //     const accountAsync = async() => {
    //         var s3sum = [];
    //         if (data && Object.keys(data).length > 0) {
    //             const obj = [];
    //             data.billing.map((j) => obj.push(j));
    //             const val = await similarObject(obj);
    //             const { result, total } = val;
    //             setList(result);
    //             setTotalCost(total);
    //             const start = data.startDate;
    //             const end = data.endDate;
    //             const curr = data.currency;
    //             setStartDate(start);
    //             setEndDate(end);
    //             setCurrency(curr);
    //             s3sum.push({ name: "Total cost", value: `${Format.formatCurrencySymbol(curr)} ${Format.formatNumber(data?.[period]?.totalCost)}` });
    //             s3sum.push({ name: "Total buckets", value: data?.[period]?.resources?.length });
    //             s3sum.push({ name: "Total object", value: data?.[period]?.resources?.length });
    //             setSum(s3sum);
    //         }
    //     };
    //     accountAsync();
    // }, [data]);
    return (
        <div>
                <ContainerCardWithDateRange
                    title={"S3"}
                    titleIcon={S3Icon}
                    showDateRange={true}
                    collapsible={true}
                    defaultPeriod={"1M"}
                    // ExtraHeaderComponent={({ period }) =>
                    // data != null && data?.[period]?.resources?.length ? (
                    //         <div style={{ marginTop: 4 }}>
                    //             <span>{moment(data?.[period]?.startDate, "YYYYMMDD").format("MMM DD, YYYY")}</span> - <span>{moment(data?.[period]?.endDate, "YYYYMMDD").format("MMM DD, YYYY")}</span>
                    //         </div>
                    //     ) : null
                    // }
                    // MiddleComponent={({ period }) =>
                    // data?.[period]?.totalCost && (
                    //         <span className="totalCost">
                    //             Total: {Format.formatCurrencySymbol(data?.currency)}
                    //             {Format.formatNumber(data?.[period]?.totalCost)}
                    //         </span>
                    //     )
                    // }
                    datePeriod={({ period }) => ({
                        start:"__",
                        end: "__",
                        rawStart: moment(data?.[period]?.startDate, "YYYYMMDD"),
                        rawEnd: moment(data?.[period]?.endDate, "YYYYMMDD"),
                    })}
                    totalCost={({ period }) => Format.formatCurrencySymbol(data?.currency) + Format.formatNumber(data?.[period]?.totalCost)}
                    datePeriodEnabled={true}
                    showDatePeriod={(period) => !!data != null && data?.[period]?.resources?.length}
                >
                    {({ period }) => (
                        <>
                            {/* {data && <S3SummaryCards summary={sum} period={period} />} */}
                            {data && <S3BucketTable list={data?.[period]?.resources} data={data} currency={Format.formatCurrencySymbol(data?.currency)} customerId={customerId} />}
                        </>
                    )}
                </ContainerCardWithDateRange>
        </div>
    );
}
