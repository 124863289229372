import React, {useEffect, useState} from "react";
import GetWindowDimension from "components/HOC/GetWindowDimension";
import ContentLoader, { List } from "react-content-loader";

function MonthlySpendTableLoader(props) {
    const { width, height } = props;

    return (
        <ContentLoader
            speed={2}
            height={height}
            viewBox={"0 0 " + '100%'+ " " + height}
            backgroundColor={"#DDDDDD"} foregroundColor={"#A2D2FF"} {...props} width={'100%'}
        >
            <rect x="5" y={10} rx="3" ry="3" width="99%" height="18"/>
            {/*<rect x="5" y={15} rx="3" ry="3" width="99%" height="0" />*/}
            {new Array(11).fill(0).map((j,i)=>(
                <>
                    <rect x="5" y={((i+1)*25) + 10} rx="3" ry="3" width="99%" height="18"/>
                </>
            ))}
            {/*<rect x="48" y="26" rx="3" ry="3" width="52" height="6" />*/}
            {/*<rect x="0"  y="56" rx="3" ry="3" width="410" height="6" />*/}
            {/*<rect x="0"  y="72" rx="3" ry="3" width="380" height="6" />*/}
            {/*{*/}
            {/*    new Array(14).fill(0).map((j,i)=>(*/}
            {/*        <rect x={(i*90)} y="30" lx="10" ry="3" width="70" height="15" />*/}
            {/*    ))*/}
            {/*}*/}

            {/*<rect x="0" y="80" rx="3" ry="3" width="480" height="50" />*/}

            {/*{*/}
            {/*    new Array(6).fill(0).map((j,ri)=>(*/}
            {/*        new Array(7).fill(0).map((j,i)=>(*/}
            {/*            <rect x={(i*70)} y={150+ (ri*60)} lx="10" ry="3" width="50" height="50" />*/}
            {/*        ))*/}
            {/*    ))*/}
            {/*}*/}

            {/*<rect x="500" y="80" rx="3" ry="3" width="480" height="50" />*/}

            {/*{*/}
            {/*    new Array(9).fill(0).map((j,i)=>(*/}
            {/*        // new Array(2).fill(0).map((j,i)=>(*/}
            {/*        <>*/}
            {/*            <rect x={500} y={150+ (i*40)} lx="10" ry="3" width="400" height="20" />*/}
            {/*            <rect x={930} y={150+ (i*40)} lx="10" ry="3" width="50" height="20" />*/}
            {/*        </>*/}
            {/*        // ))*/}
            {/*    ))*/}
            {/*}*/}

            {/*<rect x="1000" y="80" rx="3" ry="3" width="330" height="50" />*/}

            {/*{*/}
            {/*    new Array(9).fill(0).map((j,i)=>(*/}
            {/*        // new Array(2).fill(0).map((j,i)=>(*/}
            {/*        <>*/}
            {/*            <rect x={1000} y={150+ (i*40)} lx="20" ry="3" width="200" height="20" />*/}
            {/*            <rect x={1230} y={150+ (i*40)} lx="20" ry="3" width="100" height="20" />*/}
            {/*        </>*/}
            {/*        // ))*/}
            {/*    ))*/}
            {/*}*/}

            {/*<rect x="1000" y="80" rx="3" ry="3" width="175" height="30" />*/}

            {/*<rect x="1190" y="80" rx="3" ry="3" width="175" height="30" />*/}

            {/*<rect x="70" y="120" rx="3" ry="3" width="70" height="15" />*/}
            {/*<rect x="0" y="120" rx="3" ry="3" width="70" height="15" />*/}
            {/*<rect x="0" y="120" rx="3" ry="3" width="70" height="15" />*/}
            {/*<rect x="0" y="120" rx="3" ry="3" width="70" height="15" />*/}
        </ContentLoader>
    );
}
export default GetWindowDimension(MonthlySpendTableLoader);
