import React, { useRef, useEffect, useState } from "react";

import { select, selectAll, scaleOrdinal, schemeCategory10, csv, event, scaleLinear, max, scaleBand, axisLeft, axisBottom, format, easeLinear } from "d3";
import Format from "components/NumberFormat";
import GetWindowDimension from "components/HOC/GetWindowDimension";
import { tooltipVB } from "./ReactDrillDownd3BarChart";
export const colorScale = scaleOrdinal(["#31BEF3", "#19A979", "#FBD63D", "#08619D", "#E8743B", "#194350", "#7b113a", "#301b3f"]);

function ReactD3BarChart(props) {
    const { title, cost, currency, mouseEffect, data, width, height } = props;
    const svgRef = useRef();

    const currencySymbol = Format.formatCurrencySymbol(currency);
    const totalCost = Format.formatNumber(cost);

    //   let lgth = 0;
    //   let longest;
    //   for (var i = 0; i < data.length; i++) {
    //     if (data[i]?.label?.length > lgth) {
    //       let value = data[i].label.length;
    //       longest = value;
    //     }
    //   }

    const margin = {
        top: 50,
        right: 200,
        bottom: 25,
        left: 350,
    };

    const chartwidth = width / 1.3 - margin.left - margin.right;
    const chartheight = height / 2.2 - margin.top - margin.bottom;

    useEffect(() => {
        const svg = select(svgRef.current);
        if(data){
            VerticalBarChart(svg);
        } else {
            drawNoData();
        }
    }, [data, width, height]);

    const VerticalBarChart = (svg) => {
        svg.selectAll("g").remove();
        svg.selectAll("text").remove();

        const yScale = scaleLinear()
            .range([chartheight, 0])
            .domain([0, max(data, (d) => Number(d.value))]);
        const xScale = scaleBand()
            .range([0, chartwidth])
            .domain(data.map((s) => s.label))
            .padding(0.1);

        const chart = svg.append("g").attr("transform", `translate(${margin.left}, ${margin.top})`);

        chart
            .append("g")
            .attr("class", "grid")
            .style("font-Family", "Poppins_SemiBold")
            .call(
                axisLeft(yScale)
                    .ticks(4)
                    .tickFormat((d) => `${currencySymbol}${d}`)
            );

        chart.append("g").attr("transform", `translate(0, ${chartheight})`).call(axisBottom(xScale)).selectAll("text").remove();
        // .style("font-Family", "Poppins_Regular")
        // .attr("text-anchor", "end")
        // .attr("dx", "-.8em")
        // .attr("dy", ".15em")
        // .attr("transform", `rotate(-25)`);

        const rect = chart
            .selectAll()
            .data(data)
            .enter()
            .append("rect")
            .attr("x", (s) => xScale(s.label))
            .attr("y", (s) => yScale(s.value))
            .attr("height", (s) => chartheight - yScale(s.value))
            .attr("width", xScale.bandwidth())
            .attr("fill", (d, i) => colorScale(i))
            .on("mouseover", (d, i) => {
                tooltipVB.html(`<div>${i.label}<br> ${currencySymbol + Format.formatNumber(i.value)}</div>`);
            })
            .on("mousemove", function (d, i) {
                if (mouseEffect) {
                    select(this)
                        .attr("stroke", "none")
                        .style("cursor", "pointer")
                        .attr("height", (d) => chartheight - yScale(d.value) + 2)
                        .attr("y", (d) => yScale(d.value) - 2)
                        .attr("width", xScale.bandwidth() + 5)
                        .attr("x", (s) => xScale(s.label) - 2)
                        .transition()
                        .duration(200);
                    tooltipVB
                        .style("left", d.pageX - 50 + "px")
                        .style("top", d.pageY - 70 + "px")
                        .style("display", "inline-block");
                }
            })
            .on("mouseout", function (d, i) {
                if (mouseEffect) {
                    select(this)
                        .attr("stroke", "none")
                        .attr("height", (d) => chartheight - yScale(d.value))
                        .attr("y", (d) => yScale(d.value))
                        .attr("width", xScale.bandwidth())
                        .attr("x", (s) => xScale(s.label))
                        .transition()
                        .duration(200);
                    tooltipVB.style("display", "none");
                }
            });

        rect.transition()
            .ease(easeLinear)
            .duration(1000)
            .attr("y", (s) => yScale(s.value))
            .attr("height", (s) => chartheight - yScale(s.value))
            .delay((d, i) => i * 300);

        chart
            .selectAll()
            .data(data)
            .enter()
            .append("text")
            .attr("class", "val") // add class to text label
            .attr("text-anchor", "middle")
            .attr("x", function (d, i) {
                return xScale(d.label) + xScale.bandwidth() / 2;
            })
            .attr("dx", 0)
            .attr("y", function (d, i) {
                return yScale(d.value);
            })
            .attr("dy", (d) => (d.value < yScale(d.value) ? -2 : 15))
            .style("font-Size", (d) => (d.value > 10000 ? "10px" : "11px"))
            .text((d) => `${currencySymbol}` + Format.formatNumber(d.value));

        var legendElement = chart
            .append("g")
            .attr("class", "legend__container")
            .attr("transform", `translate(${chartwidth},${margin.right - 4 * margin.top})`)
            .selectAll("g.legend__element")
            .data(xScale.domain())
            .enter()
            .append("g")
            .attr("transform", function (d, i) {
                return `translate(${15},${i * 25})`;
            });

        legendElement
            .append("text")
            .attr("x", 25)
            .attr("font-size", "12px")
            .text((d) => (d.length > 30 ? d.substring(0, 25) + "..." : d));

        legendElement
            .append("rect")
            .attr("x", 0)
            .attr("y", -15)
            .attr("width", 20)
            .attr("height", 20)
            .attr("fill", function (d, i) {
                return colorScale(i);
            });

        svg.append("text")
            .attr("x", margin.left + margin.top)
            .attr("y", 25)
            .attr("text-anchor", "middle")
            .style("font-Family", "Poppins_SemiBold")
            .text(title);

        svg.append("text")
            .attr("x", chartwidth + 2 * margin.top)
            .attr("y", 25)
            .attr("text-anchor", "middle")
            .style("font-Family", "Poppins_SemiBold")
            .text(currencySymbol + totalCost);

        // svg
        //   .append("text")
        //   .attr("class", "label")
        //   .attr("x", chartwidth / 2 + margin)
        //   .attr("y", chartheight + margin * 1.7)
        //   .attr("text-anchor", "middle")
        //   .text("Services");
    };

    function drawNoData() {
        select(svgRef.current).selectAll("g").remove();
        select(svgRef.current).selectAll("text").remove();
        select(svgRef.current).append("g")
            .attr("transform", `translate(${window.innerWidth / 2.3}, ${height/4.5})`)

            // .attr("transform", `translate(${margin.left}, ${margin.top})`)
            .style("display", "flex")
            .style("align-items", "center")
            .style("justify-content", "center")
            .append('text')
            .text("No data found")
    }

    return <svg ref={svgRef} width="100%" height={height / 2.2} />;
}

export default GetWindowDimension(ReactD3BarChart);