import React from "react";
import BlueButton from "./BlueButton";
import { Button, Link, Typography } from "@mui/material";
import { Tooltip, OverlayTrigger, paper } from "react-bootstrap";
import QuestionMark from "../images/questionmark.png";
import MaterialBlueButton from "./MaterialBlueButton";
import BreadCrumbs from "./BreadCrumbs";
import Colors from "../styles/color";
import RoutesList from "../routes/RoutesList";
const { REACT_APP_AWS_ACCOUNT_HELP } = process.env;
export default function ConnectionHeader({
  Icon,
  onConnect,
  tooltip,
  connectionTitle = "Connect AWS Account",
  buttonHidden = false,
  connectButtonName = "Connect Account",
  breadCrumHidden = false,
}) {
  return (
    <div>
      {!breadCrumHidden && (
        <BreadCrumbs
          parent={"Home"}
          child={"AWS"}
          subChild={"Account"}
          parentLink={RoutesList.ALL}
          subchildLink={RoutesList.AWS_HOME}
          hasChild={true}
          hasSubChild={true}
        />
      )}

      <div
        className={"connection-header-container"}
        style={{ marginBottom: 10, backgroundColor: Colors.background }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {Icon ? (
            <div className={"icon-background"}>
              <Icon />
            </div>
          ) : null}
          <span
            style={{
              marginLeft: 10,
              fontFamily: "Poppins_SemiBold",
              fontSize: 20,
            }}
          >
            {connectionTitle}
          </span>
        </div>
        {/*{ !buttonHidden && <BlueButton onClick={onConnect} name={connectButtonName}  />}*/}
        {/* {!buttonHidden && (
          <MaterialBlueButton
            title={connectButtonName}
            onClick={onConnect}
            tooltip={tooltip}
          />
        )} */}
      </div>

      <div>
        {!breadCrumHidden && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ color: "gray" }}>
              Pre-requisites to connect AWS account
            </span>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="button-tooltip">Click to open help file</Tooltip>
              }
            >
              <a
                href="https://docs.cloudtrakr.com/SetupAwsConnection/"
                target="_blank"
              >
                <img
                  src={QuestionMark}
                  alt="QuestionMark"
                  style={{ width: 20, height: 20, marginLeft: 12 }}
                />
              </a>
            </OverlayTrigger>
          </div>
        )}
      </div>
    </div>
  );
}
