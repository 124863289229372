import * as types from "./types";
import {AWS_RECOMMENDATIONS_FAILURE, AWS_RECOMMENDATIONS_REQUEST, AWS_RECOMMENDATIONS_SUCCESS} from "./types";

//---------------------------- Signin/Signup------------------------------------------------
export function signInRequest(token) {
    return {
        type: types.USER_API_LOADING,
        payload: token,
    };
}
export function signInSuccess(user) {
    return {
        type: types.USER_API_SUCCESS,
        payload: user,
    };
}

export function signFailure() {
    return {
        type: types.USER_API_FAIL,
    };
}
//------------------------------------------------------------------------------------------
//-------------------------- allHome ------------------------------------------------------
export function allHomeRequest(id) {
    return {
        type: types.ALL_HOME_LOADING,
        payload: id,
    };
}
export function allHomeSuccess(data) {
    return {
        type: types.ALL_HOME_SUCCESS,
        payload: data,
    };
}
export function allHomeFailure(data) {
    return {
        type: types.ALL_HOME_FAIL,
        payload: data,
    };
}
//----------------------------------------------------------------------------------------------
//------------------------------ allGcpRequest ------------------------------------------------
export function allGcpRequest(id) {
    return {
        type: types.ALL_GCP_LOADING,
        payload: id,
    };
}
export function allGcpSuccess(data) {
    return {
        type: types.ALL_GCP_SUCCESS,
        payload: data,
    };
}
export function allGcpFailure(data) {
    return {
        type: types.ALL_GCP_FAIL,
        payload: data,
    };
}
//-----------------------------------------------------------------------------------------
// ---------------------------------------- accountrequest --------------------------------
export function accountRequest(cid) {
    return {
        type: types.CLOUD_ACCOUNT_REQUEST,
        payload: cid,
    };
}
export function accountSuccess(data) {
    return {
        type: types.CLOUD_ACCOUNT_SUCCESS,
        payload: data,
    };
}
export function accountFail(data) {
    return {
        type: types.CLOUD_ACCOUNT_FAIL,
        payload: data,
    };
}
//------------------------------- AZURE RECOMMENDATIONS --------------------------------- //
export function azureRecommendationRequest(data) {
    // console.log("data data2 ==", data);
    return {
        type: types.AZURE_RECOMMENDATION_REQUEST,
        payload: data,
    };
}
export function azureRecommendationSuccess(data) {
    return {
        type: types.AZURE_RECOMMENDATION_SUCCESS,
        payload: data,
    };
}
export function azureRecommendationFail(data) {
    return {
        type: types.AZURE_RECOMMENDATION_FAIL,
        payload: data,
    };
}
//---------------------------------AZURE SERVERLESS----------------------------------------//

export function azureServerlessRequest(data) {
    return {
        type: types.AZURE_SERVERLESS_REQUEST,
        payload: data,
    };
}
export function azureServerlessSuccess(data) {
    return {
        type: types.AZURE_SERVERLESS_SUCCESS,
        payload: data,
    };
}
export function azureServerlessFail(data) {
    return {
        type: types.AZURE_SERVERLESS_FAIL,
        payload: data,
    };
}

export function azureServerlessHomeRequest(data) {
    return {
        type: types.AZURE_SERVERLESS_HOME_REQUEST,
        payload: data,
    };
}
export function azureServerlessHomeSuccess(data) {
    return {
        type: types.AZURE_SERVERLESS_HOME_SUCCESS,
        payload: data,
    };
}
export function azureServerlessHomeFail(data) {
    return {
        type: types.AZURE_SERVERLESS_HOME_FAIL,
        payload: data,
    };
}
export function azureServerlessTenantRequest(data) {
    return {
        type: types.AZURE_SERVERLESS_TENANT_REQUEST,
        payload: data,
    };
}
export function azureServerlessTenantSuccess(data) {
    return {
        type: types.AZURE_SERVERLESS_TENANT_SUCCESS,
        payload: data,
    };
}
export function azureServerlessTenantFail(data) {
    return {
        type: types.AZURE_SERVERLESS_TENANT_FAIL,
        payload: data,
    };
}
//----------------------------------------------------------------------------------------
//------------------------- aws Serverless ----------------------------------------------
export function awsServerlessRequest(data) {
    // console.log("data", data);
    return {
        type: types.AWS_SERVERLESS_REQUEST,
        payload: data,
    };
}
export function awsServerlessSuccess(data) {
    return {
        type: types.AWS_SERVERLESS_SUCCESS,
        payload: data,
    };
}
export function awsServerlessFail(data) {
    return {
        type: types.AWS_SERVERLESS_FAIL,
        payload: data,
    };
}
//------------------------------------------------------------------------------
//----------------------------gcp Serverlesss ---------------------------------------
export function gcpServerlessRequest(data) {
    // console.log("data", data);
    return {
        type: types.GCP_SERVERLESS_REQUEST,
        payload: data,
    };
}
export function gcpServerlessSuccess(data) {
    return {
        type: types.GCP_SERVERLESS_SUCCESS,
        payload: data,
    };
}
export function gcpServerlessFail(data) {
    return {
        type: types.GCP_SERVERLESS_FAIL,
        payload: data,
    };
}
//-----------------------------------------------------------------------------------------
//---------------------------------Subscription List----------------------------------------//

export function getSubscriptionsRequest(data) {
    return {
        type: types.GET_SUBSCRIPTIONS_REQUEST,
        payload: data,
    };
}
export function getSubscriptionsSuccess(data) {
    return {
        type: types.GET_SUBSCRIPTIONS_SUCCESS,
        payload: data,
    };
}
export function getSubscriptionsFailure(data) {
    return {
        type: types.GET_SUBSCRIPTIONS_FAILURE,
        payload: data,
    };
}
//--------------------------------------------------------------------------------
// ------------------------------------ AZURE RESOURCES -------------------------------//
export function getAzureResourcesRequest(data) {
    return {
        type: types.AZURE_RESOURCES_REQUEST,
        payload: data,
    };
}
export function getAzureResourcesSuccess(data) {
    return {
        type: types.AZURE_RESOURCES_SUCCESS,
        payload: data,
    };
}
export function getAzureResourcesFailure(data) {
    return {
        type: types.AZURE_RESOURCES_FAIL,
        payload: data,
    };
}
export function getAzureTenantResourcesRequest(data) {
    return {
        type: types.AZURE_TENANT_RESOURCES_REQUEST,
        payload: data,
    };
}
export function getAzureTenantResourcesSuccess(data) {
    return {
        type: types.AZURE_TENANT_RESOURCES_SUCCESS,
        payload: data,
    };
}
export function getAzureTenantResourcesFailure(data) {
    return {
        type: types.AZURE_TENANT_RESOURCES_FAIL,
        payload: data,
    };
}
//------------------------------------------------------------------------------------
//----------------------------- AZURE RESOURCE GROUPS ----------------------------------//
export function getAzureResourceGroupsRequest(data) {
    return {
        type: types.AZURE_RESOURCE_GROUPS_REQUEST,
        payload: data,
    };
}
export function getAzureResourceGroupsSuccess(data) {
    return {
        type: types.AZURE_RESOURCE_GROUPS_SUCCESS,
        payload: data,
    };
}
export function getAzureResourceGroupsFailure(data) {
    return {
        type: types.AZURE_RESOURCE_GROUPS_FAIL,
        payload: data,
    };
}

export function getAzureTenantResourceGroupsRequest(data) {
    return {
        type: types.AZURE_TENANT_RESOURCE_GROUPS_REQUEST,
        payload: data,
    };
}
export function getAzureTenantResourceGroupsSuccess(data) {
    return {
        type: types.AZURE_TENANT_RESOURCE_GROUPS_SUCCESS,
        payload: data,
    };
}
export function getAzureTenantResourceGroupsFailure(data) {
    return {
        type: types.AZURE_TENANT_RESOURCE_GROUPS_FAIL,
        payload: data,
    };
}

//-------------------------------BUDGETS-------------------------------------------------

export function getBudgetsRequest(data) {
    return {
        type: types.GET_BUDGETS_REQUEST,
        payload: data,
    };
}
export function getBudgetsSuccess(data) {
    return {
        type: types.GET_BUDGETS_SUCCESS,
        payload: data,
    };
}
export function getBudgetsFailure(data) {
    return {
        type: types.GET_BUDGETS_FAILURE,
        payload: data,
    };
}

export function getBudgetSummaryRequest(data) {
    return {
        type: types.GET_BUDGET_SUMMARY_REQUEST,
        payload: data,
    };
}
export function getBudgetSummarySuccess(data) {
    return {
        type: types.GET_BUDGET_SUMMARY_SUCCESS,
        payload: data,
    };
}
export function getBudgetSummaryFailure(data) {
    return {
        type: types.GET_BUDGET_SUMMARY_FAILURE,
        payload: data,
    };
}
//----------------------------- GET REPORTS ---------------------------------------------
export function getReportNameRequest(data) {
    return {
        type: types.GET_REPORTS_NAME_REQUEST,
        payload: data,
    };
}
export function getReportNameSuccess(data) {
    return {
        type: types.GET_REPORTS_NAME_SUCCESS,
        payload: data,
    };
}
export function getReportNameFailure(data) {
    return {
        type: types.GET_REPORTS_NAME_FAILURE,
        payload: data,
    };
}
export function getReportArrayRequest(data) {
    return {
        type: types.GET_REPORTS_ARRAY_REQUEST,
        payload: data,
    };
}
export function getReportArraySuccess(data) {
    return {
        type: types.GET_REPORTS_ARRAY_SUCCESS,
        payload: data,
    };
}
export function getReportArrayFailure(data) {
    return {
        type: types.GET_REPORTS_ARRAY_FAILURE,
        payload: data,
    };
}
//-------------------------------------------------------------------------------------------------------------
//----------------------------------- Aws linked account spending ---------------------------------------------

export function getAwsAccountSpendingRequest(data) {
    return {
        type: types.GET_AWS_ACCOUNT_SPENDING_REQUEST,
        payload: data,
    };
}
export function getAwsAccountSpendingSuccess(data) {
    return {
        type: types.GET_AWS_ACCOUNT_SPENDING_SUCCESS,
        payload: data,
    };
}
export function getAwsAccountSpendingFailure(data) {
    return {
        type: types.GET_AWS_ACCOUNT_SPENDING_FAILURE,
        payload: data,
    };
}

export function getAwsSubSpendingRequest(data) {
    return {
        type: types.GET_AWS_SUB_SPENDING_REQUEST,
        payload: data,
    };
}
export function getAwsSubSpendingSuccess(data) {
    return {
        type: types.GET_AWS_SUB_SPENDING_SUCCESS,
        payload: data,
    };
}
export function getAwsSubSpendingFailure(data) {
    return {
        type: types.GET_AWS_SUB_SPENDING_FAILURE,
        payload: data,
    };
}

//-----------------------------------------------------------------------------------------------------
//------------------------------ Aws Organization ----------------------------------------------------------

export function getAwsOrgSpendingRequest(data) {
    return {
        type: types.GET_AWS_ORG_SPENDING_REQUEST,
        payload: data,
    };
}
export function getAwsOrgSpendingSuccess(data) {
    return {
        type: types.GET_AWS_ORG_SPENDING_SUCCESS,
        payload: data,
    };
}
export function getAwsOrgSpendingFailure(data) {
    return {
        type: types.GET_AWS_ORG_SPENDING_FAILURE,
        payload: data,
    };
}
//-------------------------------------------------------------------------------------------------------------
//------------------------------ Invoice --------------------------------------------------------------------

export function getInvoicesRequest(data) {
    return {
        type: types.GET_INVOICE_JSON_REQUEST,
        payload: data,
    };
}
export function getInvoicesSuccess(data) {
    return {
        type: types.GET_INVOICE_JSON_SUCCESS,
        payload: data,
    };
}
export function getInvoicesFailure(data) {
    return {
        type: types.GET_INVOICE_JSON_FAILURE,
        payload: data,
    };
}

//----------------------------------Azure Management Group (Business units)--------------------------------------------------------

export function getAzureManagementRequest(data) {
    return {
        type: types.GET_AZURE_MANAGEMENT_REQUEST,
        payload: data,
    };
}
export function getAzureManagementSuccess(data) {
    return {
        type: types.GET_AZURE_MANAGEMENT_SUCCESS,
        payload: data,
    };
}
export function getAzureManagementFailure(data) {
    return {
        type: types.GET_AZURE_MANAGEMENT_FAILURE,
        payload: data,
    };
}

           //----------------------- Azure tenant mgmt group --------------------------------------------
           export function getAzureTenantManagementRequest(data) {
            return {
                type: types.GET_AZURE_TENANT_MANAGEMENT_REQUEST,
                payload: data,
            };
        }
        export function getAzureTenantManagementSuccess(data) {
            return {
                type: types.GET_AZURE_TENANT_MANAGEMENT_SUCCESS,
                payload: data,
            };
        }

        export function getAzureTenantManagementFailure(data) {
            return {
                type: types.GET_AZURE_TENANT_MANAGEMENT_FAILURE,
                payload: data,
            };
        }

//----------------------------------AWS Reservations----------------------------------------------------------------
export function getAWSReservationRequest(data) {
    return {
        type: types.GET_AWS_RESERVATION_REQUEST,
        payload: data,
    };
}
export function getAWSReservationSuccess(data) {
    return {
        type: types.GET_AWS_RESERVATION_SUCCESS,
        payload: data,
    };
}
export function getAWSReservationFailure(data) {
    return {
        type: types.AWS_RESERVATION_FAILURE,
        payload: data,
    };
}

//------------------------AWS ACCOUNT RESERVATION---------------------------

export function getAWSAccountReservationRequest(data) {
    return {
        type: types.GET_AWS_ACCOUNT_RESERVATION_REQUEST,
        payload: data,
    };
}
export function getAWSAccountReservationSuccess(data) {
    return {
        type: types.GET_AWS_ACCOUNT_RESERVATION_SUCCESS,
        payload: data,
    };
}
export function getAWSAccountReservationFailure(data) {
    return {
        type: types.AWS_ACCOUNT_RESERVATION_FAILURE,
        payload: data,
    };
}
//--------------------------CONNECTION ERROR ---------------------------------

export function getConnectionErrorRequest(data) {
    return {
        type: types.GET_CONNECTIONERROR_REQUEST,
        payload: data,
    };
}
export function getConnectionErrorSuccess(data) {
    return {
        type: types.GET_CONNECTIONERROR_SUCCESS,
        payload: data,
    };
}
export function getConnectionErrorFailure(data) {
    return {
        type: types.GET_CONNECTIONERROR_FAILURE,
        payload: data,
    };
}
//-------------------Azure Reservation----------------------------

export function getAzureReservationRequest(data) {
    return {
        type: types.AZURE_RESERVATION_REQUEST,
        payload: data,
    };
}
export function getAzureReservationSuccess(data) {
    return {
        type: types.AZURE_RESERVATION_SUCCESS,
        payload: data,
    };
}
export function getAzureReservationFailure(data) {
    return {
        type: types.AZURE_RESERVATION_FAILURE,
        payload: data,
    };
}

//-------------------Azure Reservation subscritpion-------------------
export function getAzureReservationSubRequest(data) {
    return {
        type: types.AZURE_RESERVATION_SUB_REQUEST,
        payload: data,
    };
}
export function getAzureReservationSubSuccess(data) {
    return {
        type: types.AZURE_RESERVATION_SUB_SUCCESS,
        payload: data,
    };
}
export function getAzureReservationSubFailure(data) {
    return {
        type: types.AZURE_RESERVATION_SUB_FAILURE,
        payload: data,
    };
}

//---------------------Azure CCK ------------------------------------
export function getAzureCCKRequest(data) {
    return {
        type: types.AZURE_CCK_REQUEST,
        payload: data,
    };
}
export function getAzureCCKSuccess(data) {
    return {
        type: types.AZURE_CCK_SUCCESS,
        payload: data,
    };
}
export function getAzureCCKFailure(data) {
    return {
        type: types.AZURE_CCK_FAILURE,
        payload: data,
    };
}

//---------------------Azure Tenant ------------------------------------
export function getAzureCCKTenantRequest(data) {
    return {
        type: types.AZURE_CCK_TENANT_REQUEST,
        payload: data,
    };
}
export function getAzureCCKTenantSuccess(data) {
    return {
        type: types.AZURE_CCK_TENANT_SUCCESS,
        payload: data,
    };
}
export function getAzureCCKTenantFailure(data) {
    return {
        type: types.AZURE_CCK_TENANT_FAILURE,
        payload: data,
    };
}
//-------------------Azure CCK subscritpion-------------------
export function getAzureCCKSubRequest(data) {
    return {
        type: types.AZURE_CCK_SUB_REQUEST,
        payload: data,
    };
}
export function getAzureCCKSubSuccess(data) {
    return {
        type: types.AZURE_CCK_SUB_SUCCESS,
        payload: data,
    };
}
export function getAzureCCKSubFailure(data) {
    return {
        type: types.AZURE_CCK_SUB_FAILURE,
        payload: data,
    };
}
//---------------------AWS CCK ------------------------------------
export function getAWSCCKRequest(data) {
    return {
        type: types.AWS_CCK_REQUEST,
        payload: data,
    };
}
export function getAWSCCKSuccess(data) {
    return {
        type: types.AWS_CCK_SUCCESS,
        payload: data,
    };
}
export function getAWSCCKFailure(data) {
    return {
        type: types.AWS_CCK_FAILURE,
        payload: data,
    };
}
//-------------------AWS CCK subscritpion-------------------
export function getAWSCCKSubRequest(data) {
    return {
        type: types.AWS_CCK_SUB_REQUEST,
        payload: data,
    };
}
export function getAWSCCKSubSuccess(data) {
    return {
        type: types.AWS_CCK_SUB_SUCCESS,
        payload: data,
    };
}
export function getAWSCCKSubFailure(data) {
    return {
        type: types.AWS_CCK_SUB_FAILURE,
    };
}

//--------------------AWS ComputeOptimizer -------------------
export function getAWSOptimizerRequest(data) {
    return {
        type: types.AWS_COMPUTE_OPTIMIZER_REQUEST,
        payload: data,
    };
}
export function getAWSOptimizerSuccess(data) {
    return {
        type: types.AWS_COMPUTE_OPTIMIZER_SUCCESS,
        payload: data,
    };
}
export function getAWSOptimizerFailure(data) {
    return {
        type: types.AWS_COMPUTE_OPTIMIZER_FAILURE,
        payload: data,
    };
}

//------------------Azure CosmosDB ----------------------------

export function getAzureCosmosDBRequest(data) {
    return {
        type: types.AZURE_COSMOSDB_REQUEST,
        payload: data,
    };
}

export function getAzureCosmosDBSuccess(data) {
    return {
        type: types.AZURE_COSMOSDB_SUCCESS,
        payload: data,
    };
}
export function getAzureCosmosDBFailure(data) {
    return {
        type: types.AZURE_COSMOSDB_FAILURE,
        payload: data,
    };
}

//----------------CosmosDB Details ---------------------------------

export function getAzureCosmosDBDetailsRequest(data) {
    return {
        type: types.AZURE_COSMOSDB_DETAILS_REQUEST,
        payload: data,
    };
}
export function getAzureCosmosDBDetailsSuccess(data) {
    return {
        type: types.AZURE_COSMOSDB_DETAILS_SUCCESS,
        payload: data,
    };
}
export function getAzureCosmosDBDetailsFailure(data) {
    return {
        type: types.AZURE_COSMOSDB_DETAILS_FAILURE,
        payload: data,
    };
}
//-----------------CosmosDB Metrics---------------------------------

export function getAzureCosmosDBMetricsRequest(data) {
    return {
        type: types.AZURE_COSMOSDB_METRICS_REQUEST,
        payload: data,
    };
}
export function getAzureCosmosDBMetricsSuccess(data) {
    return {
        type: types.AZURE_COSMOSDB_METRICS_SUCCESS,
        payload: data,
    };
}
export function getAzureCosmosDBMetricsFailure(data) {
    return {
        type: types.AZURE_COSMOSDB_METRICS_FAILURE,
        payload: data,
    };
}
//----------------------Azure CosmosDB chart data -----------------------

export function getAzureCosmosDBChartDataRequest(data) {
    return {
        type: types.AZURE_COSMOSDB_CHARTDATA_REQUEST,
        payload: data,
    };
}
export function getAzureCosmosDBChartDataSuccess(data) {
    return {
        type: types.AZURE_COSMOSDB_CHARTDATA_SUCCESS,
        payload: data,
    };
}
export function getAzureCosmosDBChartDataFailure(data) {
    return {
        type: types.AZURE_COSMOSDB_CHARTDATA_FAILURE,
        payload: data,
    };
}

//----------------------Azure CosmosDB Tenant -------------------

export function getAzureCosmosDBTenantRequest(data) {
    return {
        type: types.AZURE_COSMOSDB_TENANT_REQUEST,
        payload: data,
    };
}
export function getAzureCosmosDBTenantSuccess(data) {
    return {
        type: types.AZURE_COSMOSDB_TENANT_SUCCESS,
        payload: data,
    };
}
export function getAzureCosmosDBTenantFailure(data) {
    return {
        type: types.AZURE_COSMOSDB_TENANT_FAILURE,
        payload: data,
    };
}
//----------------------Azure CosmosDB Sub -------------------

export function getAzureCosmosDBSubRequest(data) {
    return {
        type: types.AZURE_COSMOSDB_SUB_REQUEST,
        payload: data,
    };
}
export function getAzureCosmosDBSubSuccess(data) {
    return {
        type: types.AZURE_COSMOSDB_SUB_SUCCESS,
        payload: data,
    };
}
export function getAzureCosmosDBSubFailure(data) {
    return {
        type: types.AZURE_COSMOSDB_SUB_FAILURE,
        payload: data,
    };
}
//----------------------Azure CosmosDB Subscription Year Table Data -------------------

export function getAzureCosmosDBSub1YTableDataRequest(data) {
    return {
        type: types.AZURE_COSMOSDB_SUB_1Y_TABLE_DATA_REQUEST,
        payload: data,
    };
}
export function getAzureCosmosDBSub1YTableDataSuccess(data) {
    return {
        type: types.AZURE_COSMOSDB_SUB_1Y_TABLE_DATA_SUCCESS,
        payload: data,
    };
}
export function getAzureCosmosDBSub1YTableDataFailure(data) {
    return {
        type: types.AZURE_COSMOSDB_SUB_1Y_TABLE_DATA_FAILURE,
        payload: data,
    };
}
//----------------------Azure CosmosDB Subscription 3M Metric Data -------------------

export function getAzureCosmosDBSub3MMetricRequest(data) {
    return {
        type: types.AZURE_COSMOSDB_SUB_3M_METRIC_REQUEST,
        payload: data,
    };
}
export function getAzureCosmosDBSub3MMetricSuccess(data) {
    return {
        type: types.AZURE_COSMOSDB_SUB_3M_METRIC_SUCCESS,
        payload: data,
    };
}
export function getAzureCosmosDBSub3MMetricFailure(data) {
    return {
        type: types.AZURE_COSMOSDB_SUB_3M_METRIC_FAILURE,
        payload: data,
    };
}
//----------------------Azure CosmosDB 3M Metric Data -------------------
export function getAzureCosmosDB3MMetricRequest(data) {
    return {
        type: types.AZURE_COSMOSDB_3M_METRIC_REQUEST,
        payload: data,
    };
}
export function getAzureCosmosDB3MMetricSuccess(data) {
    return {
            type: types.AZURE_COSMOSDB_3M_METRIC_SUCCESS,
        payload: data,
    };
}
export function getAzureCosmosDB3MMetricFailure(data) {
    return {
        type: types.AZURE_COSMOSDB_3M_METRIC_FAILURE,
        payload: data,
    };
}

//----------------------Azure CosmosDB Subscription Year Table Data -------------------

export function getAzureCosmosDB1YTableDataRequest(data) {
    return {
        type: types.AZURE_COSMOSDB_1Y_TABLE_DATA_REQUEST,
        payload: data,
    };
}
export function getAzureCosmosDB1YTableDataSuccess(data) {
    return {
        type: types.AZURE_COSMOSDB_1Y_TABLE_DATA_SUCCESS,
        payload: data,
    };
}
export function getAzureCosmosDB1YTableDataFailure(data) {
    return {
        type: types.AZURE_COSMOSDB_1Y_TABLE_DATA_FAILURE,
        payload: data,
    };
}
//--------------------AWS DynamoDB--------------------

export function getAWSDynamoDBRequest(data) {
    return {
        type: types.AWS_DYNAMODB_REQUEST,
        payload: data,
    };
}

export function getAWSDynamoDBSuccess(data) {
    return {
        type: types.AWS_DYNAMODB_SUCCESS,
        payload: data,
    };
}

export function getAWSDynamoDBFailure(data) {
    return {
        type: types.AWS_DYNAMODB_FAILURE,
        payload: data,
    };
}
//----------------------------------------------

export function getAWSDynamoDBAccRequest(data) {
    return {
        type: types.AWS_DYNAMODB_ACC_REQUEST,
        payload: data,
    };
}

export function getAWSDynamoDBAccSuccess(data) {
    return {
        type: types.AWS_DYNAMODB_ACC_SUCCESS,
        payload: data,
    };
}

export function getAWSDynamoDBAccFailure(data) {
    return {
        type: types.AWS_DYNAMODB_ACC_FAILURE,
        payload: data,
    };
}

//------------------------AWS Lambda function -----------

export function getAWSLambdaRequest(data) {
    return {
        type: types.AWS_LAMBDA_REQUEST,
        payload: data,
    };
}
export function getAWSLambdaSuccess(data) {
    return {
        type: types.AWS_LAMBDA_SUCCESS,
        payload: data,
    };
}

export function getAWSLambdaFailure(data) {
    return {
        type: types.AWS_LAMBDA_FAILURE,
        payload: data,
    };
}

export function getAWSLambdaAccountRequest(data) {
    return {
        type: types.AWS_LAMBDA_ACCOUNT_REQUEST,
        payload: data,
    };
}
export function getAWSLambdaAccountSuccess(data) {
    return {
        type: types.AWS_LAMBDA_ACCOUNT_SUCCESS,
        payload: data,
    };
}

export function getAWSLambdaAccountFailure(data) {
    return {
        type: types.AWS_LAMBDA_ACCOUNT_FAILURE,
        payload: data,
    };
}

//----------------- AWS S3 bucket ---------------------------------------
export function getAWSS3BucketRequest(data) {
    return {
        type: types.AWS_S3BUCKET_REQUEST,
        payload: data,
    };
}
export function getAWSS3BucketSuccess(data) {
    return {
        type: types.AWS_S3BUCKET_SUCCESS,
        payload: data,
    };
}

export function getAWSS3BucketFailure(data) {
    return {
        type: types.AWS_S3BUCKET_FAILURE,
        payload: data,
    };
}

export function getAWSS3MetricDataRequest(data) {
    return {
        type: types.AWS_S3METRIC_DATA_REQUEST,
        payload: data,
    };
}
export function getAWSS3MetricDataSuccess(data) {
    return {
        type: types.AWS_S3METRIC_DATA_SUCCESS,
        payload: data,
    };
}

export function getAWSS3MetricDataFailure(data) {
    return {
        type: types.AWS_S3METRIC_DATA_FAILURE,
        payload: data,
}
}

//----------------- AWS Recommendations ---------------------------------------
export function getAWSRecommendationsRequest(data) {
    return {
        stopped: {
            type: types.AWS_RECOMMENDATIONS_REQUEST,
            payload: {
                data,
                type: 'stopped'
            },
        },
        idle: {
            type: types.AWS_RECOMMENDATIONS_REQUEST,
            payload: {
                data,
                type: 'idle'
            }
        }
    };
}
export function getAWSRecommendationsSuccess(data) {
    return {
        stopped: {
            type: types.AWS_RECOMMENDATIONS_SUCCESS,
            payload: {
                data,
                type: 'stopped'
            },
        },
        idle: {
            type: types.AWS_RECOMMENDATIONS_SUCCESS,
            payload: {
                data,
                type: 'idle'
            }
        }
    };
}

export function getAWSRecommendationsFailure(data) {
    return {
        stopped: {
            type: types.AWS_RECOMMENDATIONS_FAILURE,
            payload: {
                data,
                type: 'stopped'
            },
        },
        idle: {
            type: types.AWS_RECOMMENDATIONS_FAILURE,
            payload: {
                data,
                type: 'idle'
            }
        }
    };
}

//----------------------Gcp Project Accounts ------------------------------

export function getGCPProjectRequest(data) {
    return {
        type: types.GCP_PROJECT_REQUEST,
        payload: data,
    };
}
export function getGCPProjectSuccess(data) {
    return {
        type: types.GCP_PROJECT_SUCCESS,
        payload: data,
    };
}

export function getGCPProjectFailure(data) {
    return {
        type: types.GCP_PROJECT_FAILURE,
        payload: data,
    };
}

export function getGCPProjectAccountRequest(data) {
    return {
        type: types.GCP_PROJECT_ACCOUNT_REQUEST,
        payload: data,
    };
}
export function getGCPProjectAccountSuccess(data) {
    return {
        type: types.GCP_PROJECT_ACCOUNT_SUCCESS,
        payload: data,
    };
}

export function getGCPProjectAccountFailure(data) {
    return {
        type: types.GCP_PROJECT_ACCOUNT_FAILURE,
        payload: data,
    };
}
//------------------------GCP Services ----------------------------

export function getGCPServicesRequest(data) {
    return {
        type: types.GCP_SERVICES_REQUEST,
        payload: data,
    };
}
export function getGCPServicesSuccess(data) {
    return {
        type: types.GCP_SERVICES_SUCCESS,
        payload: data,
    };
}

export function getGCPServicesFailure(data) {
    return {
        type: types.GCP_SERVICES_FAILURE,
        payload: data,
    };
}

export function getGCPServicesAccountRequest(data) {
    return {
        type: types.GCP_SERVICES_ACCOUNT_REQUEST,
        payload: data,
    };
}
export function getGCPServicesAccountSuccess(data) {
    return {
        type: types.GCP_SERVICES_ACCOUNT_SUCCESS,
        payload: data,
    };
}

export function getGCPServicesAccountFailure(data) {
    return {
        type: types.GCP_SERVICES_ACCOUNT_FAILURE,
        payload: data,
    };
}

export function getGCPServicesProjectAccountRequest(data) {
    return {
        type: types.GCP_SERVICES_PROJECT_ACCOUNT_REQUEST,
        payload: data,
    };
}
export function getGCPServicesProjectAccountSuccess(data) {
    return {
        type: types.GCP_SERVICES_PROJECT_ACCOUNT_SUCCESS,
        payload: data,
    };
}

export function getGCPServicesProjectAccountFailure(data) {
    return {
        type: types.GCP_SERVICES_PROJECT_ACCOUNT_FAILURE,
        payload: data,
    };
}

//-----------------------------GCP Compute------------------------------

export function getGCPComputeRequest(data) {
    return {
        type: types.GCP_COMPUTE_REQUEST,
        payload: data,
    };
}
export function getGCPComputeSuccess(data) {
    return {
        type: types.GCP_COMPUTE_SUCCESS,
        payload: data,
    };
}

export function getGCPComputeFailure(data) {
    return {
        type: types.GCP_COMPUTE_FAILURE,
        payload: data,
    };
}

export function getGCPComputeAccountRequest(data) {
    return {
        type: types.GCP_COMPUTE_ACCOUNT_REQUEST,
        payload: data,
    };
}
export function getGCPComputeAccountSuccess(data) {
    return {
        type: types.GCP_COMPUTE_ACCOUNT_SUCCESS,
        payload: data,
    };
}

export function getGCPComputeAccountFailure(data) {
    return {
        type: types.GCP_COMPUTE_ACCOUNT_FAILURE,
        payload: data,
    };
}

export function getGCPComputeProjectAccountRequest(data) {
    return {
        type: types.GCP_COMPUTE_PROJECT_ACCOUNT_REQUEST,
        payload: data,
    };
}
export function getGCPComputeProjectAccountSuccess(data) {
    return {
        type: types.GCP_COMPUTE_PROJECT_ACCOUNT_SUCCESS,
        payload: data,
    };
}

export function getGCPComputeProjectAccountFailure(data) {
    return {
        type: types.GCP_COMPUTE_PROJECT_ACCOUNT_FAILURE,
        payload: data,
    };
}

//-----------------------------GCP Storage------------------------------

export function getGCPStorageRequest(data) {
    return {
        type: types.GCP_STORAGE_REQUEST,
        payload: data,
    };
}
export function getGCPStorageSuccess(data) {
    return {
        type: types.GCP_STORAGE_SUCCESS,
        payload: data,
    };
}

export function getGCPStorageFailure(data) {
    return {
        type: types.GCP_STORAGE_FAILURE,
        payload: data,
    };
}

export function getGCPStorageAccountRequest(data) {
    return {
        type: types.GCP_STORAGE_ACCOUNT_REQUEST,
        payload: data,
    };
}
export function getGCPStorageAccountSuccess(data) {
    return {
        type: types.GCP_STORAGE_ACCOUNT_SUCCESS,
        payload: data,
    };
}

export function getGCPStorageAccountFailure(data) {
    return {
        type: types.GCP_STORAGE_ACCOUNT_FAILURE,
        payload: data,
    };
}

export function getGCPStorageProjectAccountRequest(data) {
    return {
        type: types.GCP_STORAGE_PROJECT_ACCOUNT_REQUEST,
        payload: data,
    };
}
export function getGCPStorageProjectAccountSuccess(data) {
    return {
        type: types.GCP_STORAGE_PROJECT_ACCOUNT_SUCCESS,
        payload: data,
    };
}

export function getGCPStorageProjectAccountFailure(data) {
    return {
        type: types.GCP_STORAGE_PROJECT_ACCOUNT_FAILURE,
        payload: data,
    };
}
//---------------------ALL Compute ------------------------------------

export function getAllComputeRequest(data) {
    return {
        type: types.ALL_COMPUTE_REQUEST,
        payload: data,
    };
}
export function getAllComputeSuccess(data) {
    return {
        type: types.ALL_COMPUTE_SUCCESS,
        payload: data,
    };
}
export function getAllComputeFailure(data) {
    return {
        type: types.ALL_COMPUTE_FAILURE,
        payload: data,
    };
}
//---------------------ALL Database ------------------------------------

export function getAllDatabaseRequest(data) {
    return {
        type: types.ALL_DATABASE_REQUEST,
        payload: data,
    };
}
export function getAllDatabaseSuccess(data) {
    return {
        type: types.ALL_DATABASE_SUCCESS,
        payload: data,
    };
}
export function getAllDatabaseFailure(data) {
    return {
        type: types.ALL_DATABASE_FAILURE,
        payload: data,
    };
}

//---------------------ALL Functions ------------------------------------

export function getAllFunctionsRequest(data) {
    return {
        type: types.ALL_FUNCTIONS_REQUEST,
        payload: data,
    };
}
export function getAllFunctionsSuccess(data) {
    return {
        type: types.ALL_FUNCTIONS_SUCCESS,
        payload: data,
    };
}
export function getAllFunctionsFailure(data) {
    return {
        type: types.ALL_FUNCTIONS_FAILURE,
        payload: data,
    };
}

//---------------------ALL Account ------------------------------------

export function getAllAccountRequest(data) {
    return {
        type: types.ALL_ACCOUNT_REQUEST,
        payload: data,
    };
}
export function getAllAccountSuccess(data) {
    return {
        type: types.ALL_ACCOUNT_SUCCESS,
        payload: data,
    };
}
export function getAllAccountFailure(data) {
    return {
        type: types.ALL_ACCOUNT_FAILURE,
        payload: data,
    };
}
//---------------------ALL Services ------------------------------------

export function getAllServicesRequest(data) {
    return {
        type: types.ALL_SERVICES_REQUEST,
        payload: data,
    };
}
export function getAllServicesSuccess(data) {
    return {
        type: types.ALL_SERVICES_SUCCESS,
        payload: data,
    };
}
export function getAllServicesFailure(data) {
    return {
        type: types.ALL_SERVICES_FAILURE,
        payload: data,
    };
}
//---------------------ALL Resources ------------------------------------

export function getAllResourcesRequest(data) {
    return {
        type: types.ALL_RESOURCES_REQUEST,
        payload: data,
    };
}
export function getAllResourcesSuccess(data) {
    return {
        type: types.ALL_RESOURCES_SUCCESS,
        payload: data,
    };
}
export function getAllResourcesFailure(data) {
    return {
        type: types.ALL_RESOURCES_FAILURE,
        payload: data,
    };
}

//---------------------ALL Storage ------------------------------------

export function getAllStorageRequest(data) {
    return {
        type: types.ALL_STORAGE_REQUEST,
        payload: data,
    };
}
export function getAllStorageSuccess(data) {
    return {
        type: types.ALL_STORAGE_SUCCESS,
        payload: data,
    };
}
export function getAllStorageFailure(data) {
    return {
        type: types.ALL_STORAGE_FAILURE,
        payload: data,
    };
}
//--------------------Azure Functions-------------------------------------


export function getAzureFunctionRequest(data) {
    return {
        type: types.AZURE_FUNCTION_REQUEST,
        payload: data,
    };
}
export function getAzureFunctionSuccess(data) {
    return {
        type: types.AZURE_FUNCTION_SUCCESS,
        payload: data,
    };
}
export function getAzureFunctionFailure(data) {
    return {
        type: types.AZURE_FUNCTION_FAILURE,
        payload: data,
    };
}
//---------------------------------Azure Tenant FUNCTION---------------------------------
export function getAzureFunctionTenantRequest(data) {
    return {
        type: types.AZURE_FUNCTION_TENANT_REQUEST,
        payload: data,
    };
}
export function getAzureFunctionTenantSuccess(data) {
    return {
        type: types.AZURE_FUNCTION_TENANT_SUCCESS,
        payload: data,
    };
}
export function getAzureFunctionTenantFailure(data) {
    return {
        type: types.AZURE_FUNCTION_TENANT_FAILURE,
        payload: data,
    };
}
//---------------------------------Azure Subscripiton FUNCTION---------------------------------
export function getAzureFunctionAccountRequest(data) {
    return {
        type: types.AZURE_FUNCTION_ACCOUNT_REQUEST,
        payload: data,
    };
}
export function getAzureFunctionAccountSuccess(data) {
    return {
        type: types.AZURE_FUNCTION_ACCOUNT_SUCCESS,
        payload: data,
    };
}
export function getAzureFunctionAccountFailure(data) {
    return {
        type: types.AZURE_FUNCTION_ACCOUNT_FAILURE,
        payload: data,
    };
}

//--------------------Azure Storage-------------------------------------

export function getAzureStorageRequest(data) {
    return {
        type: types.AZURE_STORAGE_REQUEST,
        payload: data,
    };
}
export function getAzureStorageSuccess(data) {
    return {
        type: types.AZURE_STORAGE_SUCCESS,
        payload: data,
    };
}
export function getAzureStorageFailure(data) {
    return {
        type: types.AZURE_STORAGE_FAILURE,
        payload: data,
    };
}

//---------------------------------Azure Tenant Storage ---------------------------
export function getAzureStorageTenantAccountRequest(data) {
    return {
        type: types.AZURE_STORAGE_TENANT_REQUEST,
        payload: data,
    };
}
export function getAzureStorageTenantAccountSuccess(data) {
    return {
        type: types.AZURE_STORAGE_TENANT_SUCCESS,
        payload: data,
    };
}
export function getAzureStorageTenantAccountFailure(data) {
    return {
        type: types.AZURE_STORAGE_TENANT_FAILURE,
        payload: data,
    };
}
//---------------------------------Azure Subscription Storage ---------------------------
export function getAzureStorageAccountRequest(data) {
    return {
        type: types.AZURE_STORAGE_ACCOUNT_REQUEST,
        payload: data,
    };
}
export function getAzureStorageAccountSuccess(data) {
    return {
        type: types.AZURE_STORAGE_ACCOUNT_SUCCESS,
        payload: data,
    };
}
export function getAzureStorageAccountFailure(data) {
    return {
        type: types.AZURE_STORAGE_ACCOUNT_FAILURE,
        payload: data,
    };
}

//--------------------AWS RDS-------------------------------------


export function getAwsRDSRequest(data) {
    return {
        type: types.AWS_RDS_REQUEST,
        payload: data,
    };
}
export function getAwsRDSSuccess(data) {
    return {
        type: types.AWS_RDS_SUCCESS,
        payload: data,
    };
}
export function getAwsRDSFailure(data) {
    return {
        type: types.AWS_RDS_FAILURE,
        payload: data,
    };
}
//--------------------AWS Account RDS-------------------------------------


export function getAwsRDSAccountRequest(data) {
    return {
        type: types.AWS_RDS_ACCOUNT_REQUEST,
        payload: data,
    };
}
export function getAwsRDSAccountSuccess(data) {
    return {
        type: types.AWS_RDS_ACCOUNT_SUCCESS,
        payload: data,
    };
}
export function getAwsRDSAccountFailure(data) {
    return {
        type: types.AWS_RDS_ACCOUNT_FAILURE,
        payload: data,
    };
}

//-----------------AWS RDS Recommendation ------------------------------------

export function getAwsRDSRecommendationRequest(data) {
    return {
        type: types.AWS_RDS_RECOMMENDATION_REQUEST,
        payload: data,
    };
}
export function getAwsRDSRecommendationSuccess(data) {
    return {
        type: types.AWS_RDS_RECOMMENDATION_SUCCESS,
        payload: data,
    };
}
export function getAwsRDSRecommendationFailure(data) {
    return {
        type: types.AWS_RDS_RECOMMENDATION_FAILURE,
        payload: data,
    };
}


export function getAwsAccountRDSRecommendationRequest(data) {
    return {
        type: types.AWS_RDS_ACCOUNT_RECOMMENDATION_REQUEST,
        payload: data,
    };
}
export function getAwsAccountRDSRecommendationSuccess(data) {
    return {
        type: types.AWS_RDS_ACCOUNT_RECOMMENDATION_SUCCESS,
        payload: data,
    };
}
export function getAwsAccountRDSRecommendationFailure(data) {
    return {
        type: types.AWS_RDS_ACCOUNT_RECOMMENDATION_FAILURE,
        payload: data,
    };
}

//-------------- AWS EC2 Recommendation-------------------

export function getAwsEC2RecommendationRequest(data) {
    return {
        type: types.AWS_EC2_RECOMMENDATION_REQUEST,
        payload: data,
    };
}
export function getAwsEC2RecommendationSuccess(data) {
    return {
        type: types.AWS_EC2_RECOMMENDATION_SUCCESS,
        payload: data,
    };
}
export function getAwsEC2RecommendationFailure(data) {
    return {
        type: types.AWS_EC2_RECOMMENDATION_FAILURE,
        payload: data,
    };
}

export function getAwsAccountEC2RecommendationRequest(data) {
    return {
        type: types.AWS_EC2_ACCOUNT_RECOMMENDATION_REQUEST,
        payload: data,
    };
}
export function getAwsAccountEC2RecommendationSuccess(data) {
    return {
        type: types.AWS_EC2_ACCOUNT_RECOMMENDATION_SUCCESS,
        payload: data,
    };
}
export function getAwsAccountEC2RecommendationFailure(data) {
    return {
        type: types.AWS_EC2_ACCOUNT_RECOMMENDATION_FAILURE,
        payload: data,
    };
}

//--------------------------AWS DynamoDB Recommendation-------------

export function getAwsDynamoDbRecommendationRequest(data) {
    return {
        type: types.AWS_RECOMMENDATION_DYNAMODB_REQUEST,
        payload: data,
    };
}
export function getAwsDynamoDbRecommendationSuccess(data) {
    return {
        type: types.AWS_RECOMMENDATION_DYNAMODB_SUCCESS,
        payload: data,
    };
}
export function getAwsDynamoDbRecommendationFailure(data) {
    return {
        type: types.AWS_RECOMMENDATION_DYNAMODB_FAILURE,
        payload: data,
    };
}

//--------------------------AWS DynamoDB Account Recommendation-------------

export function getAwsAccountDynamoDbRecommendationRequest(data) {
    return {
        type: types.AWS_ACCOUNT_RECOMMENDATION_DYNAMODB_REQUEST,
        payload: data,
    };
}
export function getAwsAccountDynamoDbRecommendationSuccess(data) {
    return {
        type: types.AWS_ACCOUNT_RECOMMENDATION_DYNAMODB_SUCCESS,
        payload: data,
    };
}
export function getAwsAccountDynamoDbRecommendationFailure(data) {
    return {
        type: types.AWS_ACCOUNT_RECOMMENDATION_DYNAMODB_FAILURE,
        payload: data,
    };
}

//-----------------Azure Tags ------------------------------------

export function getAzureTagsRequest(data) {
    return {
        type: types.AZURE_TAGS_REQUEST,
        payload: data,
    };
}
export function getAzureTagsSuccess(data) {
    return {
        type: types.AZURE_TAGS_SUCCESS,
        payload: data,
    };
}
export function getAzureTagsFailure(data) {
    return {
        type: types.AZURE_TAGS_FAILURE,
        payload: data,
    };
}

//-----------------AWS Recommendation summary ------------------------------------

export function getAwsRecommendationSummaryRequest(data) {
    return {
        type: types.AWS_RECOMMENDATION_SUMMARY_REQUEST,
        payload: data,
    };
}
export function getAwsRecommendationSummarySuccess(data) {
    return {
        type: types.AWS_RECOMMENDATION_SUMMARY_SUCCESS,
        payload: data,
    };
}
export function getAwsRecommendationSummaryFailure(data) {
    return {
        type: types.AWS_RECOMMENDATION_SUMMARY_FAILURE,
        payload: data,
    };
}

export function getAwsAccountRecommendationSummaryRequest(data) {
    return {
        type: types.AWS_ACCOUNT_RECOMMENDATION_SUMMARY_REQUEST,
        payload: data,
    };
}
export function getAwsAccountRecommendationSummarySuccess(data) {
    return {
        type: types.AWS_ACCOUNT_RECOMMENDATION_SUMMARY_SUCCESS,
        payload: data,
    };
}
export function getAwsAccountRecommendationSummaryFailure(data) {
    return {
        type: types.AWS_ACCOUNT_RECOMMENDATION_SUMMARY_FAILURE,
        payload: data,
    };
}


//-----------------Azure Services ------------------------------------

export function getAzureServiceRequest(data) {
    return {
        type: types.AZURE_SERVICES_REQUEST,
        payload: data,
    };
}
export function getAzureServiceSuccess(data) {
    return {
        type: types.AZURE_SERVICES_SUCCESS,
        payload: data,
    };
}
export function getAzureServiceFailure(data) {
    return {
        type: types.AZURE_SERVICES_FAILURE,
        payload: data,
    };
}

export function getAzureTenantServiceRequest(data) {
    return {
        type: types.AZURE_TENANT_SERVICES_REQUEST,
        payload: data,
    };
}
export function getAzureTenantServiceSuccess(data) {
    return {
        type: types.AZURE_TENANT_SERVICES_SUCCESS,
        payload: data,
    };
}
export function getAzureTeanatServiceFailure(data) {
    return {
        type: types.AZURE_TENANT_SERVICES_FAILURE,
        payload: data,
    };
}

export function getAzureSubcriptionServiceRequest(data) {
    return {
        type: types.AZURE_SUBSCRIPTION_SERVICES_REQUEST,
        payload: data,
    };
}
export function getAzureSubcriptionServiceSuccess(data) {
    return {
        type: types.AZURE_SUBSCRIPTION_SERVICES_SUCCESS,
        payload: data,
    };
}
export function getAzureSubcriptionServiceFailure(data) {
    return {
        type: types.AZURE_SUBSCRIPTION_SERVICES_FAILURE,
        payload: data,
    };
}
//-----------------Azure VM Stopped Recommendations ------------------------------------

export function getAzureVmStoppedRecommendationRequest(data) {
    return {
        type: types.AZURE_STOPPED_VM_RECOMMENDATION_REQUEST,
        payload: data,
    };
}
export function getAzureVmStoppedRecommendationSuccess(data) {
    return {
        type: types.AZURE_STOPPED_VM_RECOMMENDATION_SUCCESS,
        payload: data,
    };
}
export function getAzureVmStoppedRecommendationFailure(data) {
    return {
        type: types.AZURE_STOPPED_VM_RECOMMENDATION_FAIL,
        payload: data,
    };
}

export function getAzureTenantVmStoppedRecommendationRequest(data) {
    return {
        type: types.AZURE_STOPPED_VM_RECOMMENDATION_TENANT_REQUEST,
        payload: data,
    };
}
export function getAzureTenantVmStoppedRecommendationSuccess(data) {
    return {
        type: types.AZURE_STOPPED_VM_RECOMMENDATION_TENANT_SUCCESS,
        payload: data,
    };
}
export function getAzureTeanatVmStoppedRecommendationFailure(data) {
    return {
        type: types.AZURE_STOPPED_VM_RECOMMENDATION_TENANT_FAIL,
        payload: data,
    };
}
//---------------------------------AWS S3 RECOMMENDATION SUMMARY--------------------

export function getAwsAccountS3RecommendationRequest(data) {
    return {
        type: types.AWS_ACCOUNT_S3RECOMMENDATION_REQUEST,
        payload: data,
    };
}
export function getAwsAccountS3RecommendationSuccess(data) {
    return {
        type: types.AWS_ACCOUNT_S3RECOMMENDATION_SUCCESS,
        payload: data,
    };
}
export function getAwsAccountS3RecommendationFailure(data) {
    return {
        type: types.AWS_ACCOUNT_S3RECOMMENDATION_FAILURE,
        payload: data,
    };
}




/** Azure Recommendations **/

export function getAzureCosmosDBRecommendationsRequest(data) {
    return {
        type: types.AZURE_COSMOSDB_RECOMMENDATION_REQUEST,
        payload: data,
    };
}

export function getAzureCosmosDBRecommendationsSuccess(data) {
    return {
        type: types.AZURE_COSMOSDB_RECOMMENDATION_SUCCESS,
        payload: data,
    };
}

export function getAzureCosmosDBRecommendationsFailure(data) {
    return {
        type: types.AZURE_COSMOSDB_RECOMMENDATION_FAIL,
        payload: data,
    };
}

export function getAzureDBServerRecommendationsRequest(data) {
    return {
        type: types.AZURE_DBSERVER_RECOMMENDATION_REQUEST,
        payload: data,
    };
}

export function getAzureDBServerRecommendationsSuccess(data) {
    return {
        type: types.AZURE_DBSERVER_RECOMMENDATION_SUCCESS,
        payload: data,
    };
}

export function getAzureDBServerRecommendationsFailure(data) {
    return {
        type: types.AZURE_DBSERVER_RECOMMENDATION_FAIL,
        payload: data,
    };
}

export function getAzureFunctionAppRecommendationsRequest(data) {
    return {
        type: types.AZURE_FUNCTION_APP_RECOMMENDATION_REQUEST,
        payload: data,
    };
}

export function getAzureFunctionAppRecommendationsSuccess(data) {
    return {
        type: types.AZURE_FUNCTION_APP_RECOMMENDATION_SUCCESS,
        payload: data,
    };
}

export function getAzureFunctionAppRecommendationsFailure(data) {
    return {
        type: types.AZURE_FUNCTION_APP_RECOMMENDATION_FAIL,
        payload: data,
    };
}

export function getAzureIdleVMRecommendationsRequest(data) {
    return {
        type: types.AZURE_IDLE_VM_RECOMMENDATION_REQUEST,
        payload: data,
    };
}

export function getAzureIdleVMRecommendationsSuccess(data) {
    return {
        type: types.AZURE_IDLE_VM_RECOMMENDATION_SUCCESS,
        payload: data,
    };
}

export function getAzureIdleVMRecommendationsFailure(data) {
    return {
        type: types.AZURE_IDLE_VM_RECOMMENDATION_FAIL,
        payload: data,
    };
}

export function getAzureSQLManagedInstanceRecommendationsRequest(data) {
    return {
        type: types.AZURE_SQL_MANAGED_INSTANCE_RECOMMENDATION_REQUEST,
        payload: data,
    };
}

export function getAzureSQLManagedInstanceRecommendationsSuccess(data) {
    return {
        type: types.AZURE_SQL_MANAGED_INSTANCE_RECOMMENDATION_SUCCESS,
        payload: data,
    };
}

export function getAzureSQLManagedInstanceRecommendationsFailure(data) {
    return {
        type: types.AZURE_SQL_MANAGED_INSTANCE_RECOMMENDATION_FAIL,
        payload: data,
    };
}

export function getAzureStoppedVMAllRecommendationsRequest(data) {
    return {
        type: types.AZURE_STOPPED_VM_RECOMMENDATION_ALL_REQUEST,
        payload: data,
    };
}

export function getAzureStoppedVMAllRecommendationsSuccess(data) {
    return {
        type: types.AZURE_STOPPED_VM_RECOMMENDATION_ALL_SUCCESS,
        payload: data,
    };
}

export function getAzureStoppedVMAllRecommendationsFailure(data) {
    return {
        type: types.AZURE_STOPPED_VM_RECOMMENDATION_ALL_FAIL,
        payload: data,
    };
}

export function getAzureStorageRecommendationsRequest(data) {
    return {
        type: types.AZURE_STORAGE_RECOMMENDATION_REQUEST,
        payload: data,
    };
}

export function getAzureStorageRecommendationsSuccess(data) {
    return {
        type: types.AZURE_STORAGE_RECOMMENDATION_SUCCESS,
        payload: data,
    };
}

export function getAzureStorageRecommendationsFailure(data) {
    return {
        type: types.AZURE_STORAGE_RECOMMENDATION_FAIL,
        payload: data,
    };
}

export function getAzureVMMigrateRecommendationsRequest(data) {
    return {
        type: types.AZURE_VM_MIGRATE_RECOMMENDATION_REQUEST,
        payload: data,
    };
}

export function getAzureVMMigrateRecommendationsSuccess(data) {
    return {
        type: types.AZURE_VM_MIGRATE_RECOMMENDATION_SUCCESS,
        payload: data,
    };
}

export function getAzureVMMigrateRecommendationsFailure(data) {
    return {
        type: types.AZURE_VM_MIGRATE_RECOMMENDATION_FAIL,
        payload: data,
    };
}

export function getAzureVMRightsizingRecommendationsRequest(data) {
    return {
        type: types.AZURE_VM_RIGHTSIZING_RECOMMENDATION_REQUEST,
        payload: data,
    };
}

export function getAzureVMRightsizingRecommendationsSuccess(data) {
    return {
        type: types.AZURE_VM_RIGHTSIZING_RECOMMENDATION_SUCCESS,
        payload: data,
    };
}

export function getAzureVMRightsizingRecommendationsFailure(data) {
    return {
        type: types.AZURE_VM_RIGHTSIZING_RECOMMENDATION_FAIL,
        payload: data,
    };
}

export function getAWSEBSRecommendationsRequest(data) {
    return {
        type: types.AWS_EBS_RECOMMENDATION_REQUEST,
        payload: data,
    };
}

export function getAWSEBSRecommendationsSuccess(data) {
    return {
        type: types.AWS_EBS_RECOMMENDATION_SUCCESS,
        payload: data,
    };
}

export function getAWSEBSRecommendationsFailure(data) {
    return {
        type: types.AWS_EBS_RECOMMENDATION_FAILURE,
        payload: data,
    };
}

export function getAWSTagsSuccess(data) {
    return {
        type: types.AWS_TAGS_SUCCESS,
        payload: data,
    };
}

export function getAWSTagsFailure(data) {
    return {
        type: types.AWS_TAGS_FAILURE,
        payload: data,
    };
}

export function getAWSCostCategorySuccess(data) {
    return {
        type: types.AWS_COST_CATEGORY_SUCCESS,
        payload: data,
    };
}

export function getAWSCostCategoryFailure(data) {
    return {
        type: types.AWS_COST_CATEGORY_FAILURE,
        payload: data,
    };
}
