import React, { useEffect, useState } from "react";
import Format from "../../components/NumberFormat";
import { Paper, Grid, Divider, Collapse, IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp, PlayArrow } from "@mui/icons-material";
import Azure_logo from "../../images/Azure_logo.svg";
import active_directory from "../../images/active_directory.svg";
import key from "../../images/newCloudTrakrIcons/AzureSubscriptions.png";
import Colors from "../../styles/color";
import DateRange from "components/DateRange";
import moment from "moment";
import StartEndDate from "components/StartEndDate";
import management_group from "../../images/management_group.svg";
import ContainerCardWithDateRange from "../ContainerCardWithDateRange";
import {isNumeric} from "../../util/Miscellaneous";
import {makeStyles} from "MakeWithStyles";

const useStyles = makeStyles()((theme) => ({
    paper: {
        padding: 0,
    },
}));
//charts?.SpendByAccounts?.[period]?.dataSet;
export default function ({somerefs = [], tenantLogo, currency, data }) {
    const {classes} = useStyles();
    // const [totalExpand, setTotalExpand] = useState(true);
    // const [period, setPeriod] = useState("1M");
    // const [accountList, setAccountList] = useState([]);

    // useEffect(() => {
    //     //console.log(data);
    //     let list = data?.[period]?.dataSet;
    //     setAccountList(list);
    // }, [data, period]);

    return (
        <ContainerCardWithDateRange title={"Spend by Tenants and Subscriptions"} titleIcon={tenantLogo}
                                    someref={somerefs?.[0]}
                                    showDateRange={true}
                                    collapsible={true}
                                    datePeriod={({period})=>({
                                        start:"__",
                                        end: "__",
                                        rawStart: moment(data?.[period]?.startDate, "YYYYMMDD"),
                                        rawEnd: moment(data?.[period]?.endDate, "YYYYMMDD")
                                    })}
                                    totalCost={({period})=>(
                                        currency +
                                        Format.formatNumber(
                                            (data?.[period]?.dataSet
                                            && data?.[period]?.dataSet.length) ? (
                                                data?.[period]?.dataSet.map(a=>isNumeric(a.value)?parseFloat(a.value):null)?.reduce((a,b)=>a+b, 0)
                                            ):null
                                        )
                                    )}
                                    datePeriodEnabled={true}
                                    showDatePeriod={(period)=>!!(data?.[period]?.dataSet && data?.[period]?.dataSet.length)}
        >
            {({period}) =>
                <div style={{flex: 1, overflowY: "scroll"}} elevation={3}>
                    {" "}
                    {data?.[period]?.dataSet && data?.[period]?.dataSet.length ? (
                        data?.[period]?.dataSet.map((item, index) => (
                            <div className={classes.paper}>
                                <div
                                    style={{
                                        padding: 12,
                                        backgroundColor: Colors.tableHeader,
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        // marginTop: 16,
                                    }}
                                >
                                    <div
                                        style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            display: "flex",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <img
                                                src={tenantLogo}
                                                alt="tenantLogo"
                                                style={{
                                                    width: 20,
                                                    height: 20,
                                                    marginRight: 8,
                                                }}
                                            />
                                            <span>{item.label}</span>
                                        </div>
                                        <div style={{fontWeight: "bold"}}>
                                            {currency}{Format.formatNumber(item.value)}
                                        </div>
                                    </div>
                                    <span>{item.tenantId}</span>
                                </div>
                                <div
                                    className="overflow-auto"
                                    style={{
                                        padding: 12,
                                        // maxHeight: 430
                                    }}
                                >
                                    {item.dataSet && item.dataSet.length ? (
                                        item.dataSet?.map((item) => (
                                            <div style={{padding: 0}}>
                                                <div
                                                    style={{
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div style={{display: "flex"}}>
                                                        <img
                                                            src={key}
                                                            alt="key"
                                                            style={{
                                                                width: 20,
                                                                height: 20,
                                                                marginRight: 8,
                                                            }}
                                                        />
                                                        <span>{item.label}</span>
                                                    </div>
                                                    <div>
                                                        {" "}
                                                        {currency}
                                                        {Format.formatNumber(item.value)}
                                                    </div>
                                                </div>
                                                <span>{item.subscriptionId}</span>
                                                <Divider light style={{margin: 4}}/>
                                            </div>
                                        ))
                                    ) : (
                                        <span>No data found</span>
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <h6 style={{textAlign: "center"}}>No data found</h6>
                    )}
                </div>
            }
        </ContainerCardWithDateRange>
    );
}
