import * as types from "../actions/types";

const userState = {
    azureServicesData: null,
    error: null,
    loading: false,
    azureServicesSubscriptionData:null,
    azureTenantData: null
};

function azureServicesReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.AZURE_SERVICES_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AZURE_SERVICES_SUCCESS: {
            return {
                ...state,
                azureServicesData: payload.data,
                loading: false,
            };
        }
        case types.AZURE_SERVICES_FAILURE: {
            return {
                ...state,
                loading: false,
                azureServicesData: null,
                error: payload,
            };
        }

        case types.AZURE_TENANT_SERVICES_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AZURE_TENANT_SERVICES_SUCCESS: {
            return {
                ...state,
                azureTenantData: payload.data,
                loading: false,
            };
        }
        case types.AZURE_TENANT_SERVICES_FAILURE: {
            return {
                ...state,
                loading: false,
                azureTenantData: null,
                error: payload,
            };
        }

        case types.AZURE_SUBSCRIPTION_SERVICES_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AZURE_SUBSCRIPTION_SERVICES_SUCCESS: {
            return {
                ...state,
                azureServicesSubscriptionData: payload.data,
                loading: false,
            };
        }
        case types.AZURE_SUBSCRIPTION_SERVICES_FAILURE: {
            return {
                ...state,
                loading: false,
                azureServicesSubscriptionData: null,
                error: payload,
            };
        }

       
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                azureServicesDataData: null,
                error: null,
                loading: false,
                azureServicesSubscriptionData:null
            };
        }

        default:
            return state;
    }
}
export default azureServicesReducer;
