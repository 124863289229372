import * as types from "../actions/types";

const userState = {
  cloudProviderType: null,
  budgets: null,
  error: null,
};

function getBudgetsReducer(state = userState, { type, payload }) {
  switch (type) {
      case "SWITCH_TEAM_CLEAR": {
          return {
              ...state,
              cloudProviderType: null,
              budgets: null,
              error: null,
          };
      }
      case types.GET_BUDGETS_REQUEST: {
          return {
              ...state,
              loading: true,
          };
      }
      case types.GET_BUDGETS_SUCCESS: {
          return {
              ...state,
              budgets: payload.data,
              loading: false,
          };
      }
      case types.GET_BUDGETS_FAILURE: {
          return {
              ...state,
              loading: false,
              budgets: null,
              error: payload,
          };
      }

      default:
          return state;
  }
}
export default getBudgetsReducer;
