import React, { useState, useEffect } from "react";
import { similarObject } from "./ConversionFunc";
import Format from "components/NumberFormat";
import "./style.css";
import S3BucketTable from "./S3BucketTable";
import S3SummaryCards from "./S3SummaryCards";
import S3Icon from "../../images/newCloudTrakrIcons/AWSS3.png";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import moment from "moment";

export default function AllS3BucketList({ data, customerId,currency }) {
    const [list, setList] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [totalCost, setTotalCost] = useState(null);
    const [sum, setSum] = useState(null);

    // useEffect(() => {
    //     const allAsync = async() => {
    //         var s3sum = [];
    //         if (data && data.length > 0) {
    //             const obj = [];
    //             data.map((e) => e.subscriptions.map((i) => i.billing.map((j) => obj.push(j))));
    //             const val = await similarObject(obj);
    //             const { result, total } = val;
    //             setList(result);
    //             setTotalCost(total);
    //             const start = data[0].subscriptions[0].startDate;
    //             const end = data[0].subscriptions[0].endDate;
    //             const curr = data[0].subscriptions[0].currency;
    //             setStartDate(start);
    //             setEndDate(end);
    //             setCurrency(curr);
    //             s3sum.push({ name: "Total cost", value: `${Format.formatCurrencySymbol(currency)} ${Format.formatNumber(total)}` });
    //             s3sum.push({ name: "Total buckets", value: result.length });
    //             s3sum.push({ name: "Total object", value: result.length });
    //             setSum(s3sum);
    //         }
    //     };
    //     allAsync();
    // }, [data]);
    return (
        <div>
            <ContainerCardWithDateRange
                title={"S3"}
                titleIcon={S3Icon}
                showDateRange={true}
                collapsible={true}
                defaultPeriod={"1M"}
                // ExtraHeaderComponent={({ period }) =>
                // data != null && data?.[period]?.resources?.length ? (
                //     <div style={{ marginTop: 4 }}>
                //                 <span>{moment(data?.[period]?.startDate, "YYYYMMDD").format("MMM DD, YYYY")}</span> - <span>{moment(data?.[period]?.endDate, "YYYYMMDD").format("MMM DD, YYYY")}</span>
                //         </div>
                //     ) : null
                // }
                // MiddleComponent={({ period }) =>
                // data?.[period]?.totalCost && (
                //         <span className="totalCost">
                //             Total: {Format.formatCurrencySymbol(data?.currency)}
                //             {Format.formatNumber(data?.[period]?.totalCost)}
                //         </span>
                //     )
                // }
                datePeriod={({ period }) => ({
                    start:"__",
                    end: "__",
                    rawStart: moment(data?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(data?.[period]?.endDate, "YYYYMMDD"),
                })}
                totalCost={({ period }) => Format.formatCurrencySymbol(data?.currency) + Format.formatNumber(data?.[period]?.totalCost)}
                datePeriodEnabled={true}
                showDatePeriod={(period) => !!data != null && data?.[period]?.resources?.length}
               
            >
                {({ period }) => (
                    <>
                        {/* {list && sum && <S3SummaryCards summary={sum} />} */}
                        {data && <S3BucketTable list={data?.[period]?.resources} data={data} currency={Format.formatCurrencySymbol(data?.currency)}  customerId={customerId} />}
                    </>
                )}
            </ContainerCardWithDateRange>
        </div>
    );
}
