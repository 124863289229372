import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getAWSDynamoDBSuccess, getAWSDynamoDBFailure, getAWSDynamoDBAccSuccess, getAWSDynamoDBAccFailure } from "../actions/actions";
const { REACT_APP_AWS_DYNAMODB, REACT_APP_AWS_DYNAMODB_ACCOUNTS } = process.env;

function* getAwsDynamoDBData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AWS_DYNAMODB, {
            params: { customerId: payload.customerId}
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAWSDynamoDBFailure(statusCode));
            } else {
                yield put(getAWSDynamoDBSuccess(res.data));
            }
        } else {
            yield put(getAWSDynamoDBFailure(res));
        }
    } catch (error) {
        yield put(getAWSDynamoDBFailure(error));
    }
}

function* getAwsDynamoDBAccountData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AWS_DYNAMODB_ACCOUNTS, {
            params: { 
            customerId: payload.customerId,
            tenantId: payload.tenantId,
            subscriptionId: payload.subscriptionId
            }
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            console.log("payload", res.data);
            if (statusCode !== 200) {
                yield put(getAWSDynamoDBAccFailure(statusCode));
            } else {
                yield put(getAWSDynamoDBAccSuccess(res.data));
            }
        } else {
            yield put(getAWSDynamoDBAccFailure(res));
        }
    } catch (error) {
        yield put(getAWSDynamoDBAccFailure(error));
    }
}

export default function* awsDynamoDBSaga() {
    yield takeLatest(types.AWS_DYNAMODB_REQUEST, getAwsDynamoDBData);
    yield takeLatest(types.AWS_DYNAMODB_ACC_REQUEST, getAwsDynamoDBAccountData);
}
