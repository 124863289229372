import './style.css';

export default function Dots({dotRadius = '50%', count = 5, height = 40, width = 40, dotType = 'circle' || 'square' || 'ellipse'}){
    return (
        <div className={'body_div'}>
            {new Array(count).fill(
                <custom_div style={{
                    height, width,
                    borderRadius: dotType === 'square'?0:dotRadius
                }}/>)
            }
            {/*<custom_div style={{height, width}}></custom_div>*/}
            {/*<custom_div style={{height, width}}></custom_div>*/}
            {/*<custom_div style={{height, width}}></custom_div>*/}
            {/*<custom_div style={{height, width}}></custom_div>*/}
        </div>
    )
}


