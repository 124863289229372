import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getAzureVmStoppedRecommendationSuccess, getAzureVmStoppedRecommendationFailure,
       getAzureTenantVmStoppedRecommendationSuccess,getAzureTeanatVmStoppedRecommendationFailure } from "../actions/actions";
const { REACT_APP_GET_AZURE_STOPPED_VM_RECOMMENDATION, REACT_APP_GET_AZURE_TENANT_STOPPED_VM_RECOMMENDATION} = process.env;

function* getazureStoppedVmRecommendationSagaData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_GET_AZURE_STOPPED_VM_RECOMMENDATION, {
            params: {
                customerId: payload.customerId,
            },
        });

        const { statusCode } = res.data;
        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureVmStoppedRecommendationFailure(statusCode));
            } else {
                yield put(getAzureVmStoppedRecommendationSuccess(res.data));
            }
        } else {
            yield put(getAzureVmStoppedRecommendationFailure(res));
        }
    } catch (error) {
        yield put(getAzureVmStoppedRecommendationFailure(error));
    }
}


function* getAzureStoppedVmRecommendationTenantSagaData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_GET_AZURE_TENANT_STOPPED_VM_RECOMMENDATION, {
            params: {
                customerId: payload.customerId,
                tenantId: payload.tenantId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureTeanatVmStoppedRecommendationFailure(statusCode));
            } else {
                yield put(getAzureTenantVmStoppedRecommendationSuccess(res.data));
            }
        } else {
            yield put(getAzureTeanatVmStoppedRecommendationFailure(res));
        }
    } catch (error) {
        yield put(getAzureTeanatVmStoppedRecommendationFailure(error));
    }
}


export default function* azureStoppedVmRecommendationSaga() {
    yield takeLatest(types.AZURE_STOPPED_VM_RECOMMENDATION_REQUEST, getazureStoppedVmRecommendationSagaData);
    yield takeLatest(types.AZURE_STOPPED_VM_RECOMMENDATION_TENANT_REQUEST, getAzureStoppedVmRecommendationTenantSagaData);
}
