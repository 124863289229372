import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getAzureReservationSuccess, getAzureReservationFailure, getAzureReservationSubFailure, getAzureReservationSubSuccess } from "../actions/actions";
const { REACT_APP_AZURE_RESERVATION, REACT_APP_AZURE_SUB_RESERVATION } = process.env;

function* getAzureReservationData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AZURE_RESERVATION, {
            params: {
                customerId: payload.customerId,
                tenantId: payload.tenantId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureReservationFailure(statusCode));
            } else {
                yield put(getAzureReservationSuccess(res.data));
            }
        } else {
            yield put(getAzureReservationFailure(res));
        }
    } catch (error) {
        yield put(getAzureReservationFailure(error));
    }
}

function* getAzureReservationSubData({ payload }) {

    try {
        const res = yield call(axios.get, REACT_APP_AZURE_SUB_RESERVATION, {
            params: {
                customerId: payload.customerId,
                tenantId: payload.tenantId,
                subscriptionId: payload.subscriptionId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureReservationSubFailure(statusCode));
            } else {
                yield put(getAzureReservationSubSuccess(res.data));
            }
        } else {
            yield put(getAzureReservationSubFailure(res));
        }
    } catch (error) {
        yield put(getAzureReservationSubFailure(error));
    }
}

export default function* azureReservationSaga() {
    yield takeLatest(types.AZURE_RESERVATION_REQUEST, getAzureReservationData);
    yield takeLatest(types.AZURE_RESERVATION_SUB_REQUEST, getAzureReservationSubData);
}
