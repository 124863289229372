import React from 'react';
import {TableSortLabel, TableCell} from "@mui/material";
import {TABLE_SORT} from "../util/AppConstants";

const TableSortHeader = (props) => {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, align } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    // return  null;

    return (
        <>
            {headCells.map((headCell) => (
                <TableCell
                    className={classes?.sortCell}
                    style={{padding: 5, margin: 0}}
                    key={headCell.id}
                    align={align || headCell?.align || (headCell.numeric ? 'right' : 'left')}
                    // padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order == TABLE_SORT.ORDER_ASC ?'asc':'desc' : false}
                >
                    <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order == TABLE_SORT.ORDER_ASC ?'asc':'desc' : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                    >
                        {headCell.label}
                        {
                            orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                    {/*{order === 'desc' ? 'sorted descending' : 'sorted ascending'}*/}
                                </span>
                                ) :
                                null
                        }
                    </TableSortLabel>
                </TableCell>
            ))}
        </>
    )
};

export default TableSortHeader;
