import { takeLatest, call, put, takeEvery } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getAllComputeSuccess, getAllComputeFailure, } from "../actions/actions";
const { REACT_APP_ALL_CLOUD_COMPUTE,  } = process.env;

function* allComputeData({ payload }) {

    try {
        const res = yield call(axios.post, REACT_APP_ALL_CLOUD_COMPUTE, {
            customerId: payload.customerId,
            dataType: payload.dataType,
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {

            if (statusCode !== 200) {
                yield put(getAllComputeFailure(statusCode));
            } else {
                yield put(getAllComputeSuccess({ data: res.data, dataType: payload.dataType }));

            }
        } else {
            yield put(getAllComputeFailure(res));
        }
    } catch (error) {
        yield put(getAllComputeFailure(error));
    }
}


export default function* allComputeSaga() {
    yield takeEvery(types.ALL_COMPUTE_REQUEST, allComputeData);
   
}
