import React, {useState, useEffect, useRef} from "react";
import "./style.css";
import Format from "components/NumberFormat";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import moment from "moment";
// import {similarObject} from "../listS3bucket/ConversionFunc";
import RecommendationsTable from "./RecommendationTable";
import RecommendationsSummaryCards from "./RecommendationSummaryCards";
import aws_recommendation from "../../images/aws_recommendation.svg";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import { CircularProgress } from "@mui/material";
import IconButton from "@mui/material/Icon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {  makeStyles } from "MakeWithStyles";
import AmazonRDS from "../../images/awsAccounts/Amazon-RDS.svg";
import RDSIcon from "../../images/RDS.svg";
import AwsLogo from "../../images/aws_dark.svg";
import downarrow from '../../images/downarrow.png'
import info_icon from '../../images/info_icon.svg'
import DynamoDBIcon from "../../images/DynamoDB.svg";
import Button from "@mui/material/Button";
import recommendation_icon from "../../images/recommended.png";
import {OverlayTrigger, Tooltip,Popover} from "react-bootstrap";
import Badge from "@mui/material/Badge";
import {CancelRounded, Help} from "@mui/icons-material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TablePaginationActions from "../../components/TablePaginationActions";
import TableSearch from "../../components/TableSearch";
import Color from "../../styles/color";
import Box from "@mui/material/Box";
import {bytesToSize}  from "../../util/Miscellaneous";
const AWS_TERMINATE_INSTANCE_HELP = "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/terminating-instances.html#new-console";

const useStyles = makeStyles()((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        //marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
        
    },
}));

export default function AWSRDSRecommendation({ title, data, view, subscriptionId, lastUpdated, currency, cards, loading, collapseRef }) {
    const {classes} = useStyles();
    const [totalExpand, setTotalExpand] = useState(true);
    const [selected, setSelected] = React.useState([]);
    const [progress, setProgress] = useState(0);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [list, setList] = useState(null);
    const [search, setSearch] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);
    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.resource,
                f?.currentDBInstanceType,
                f?.linkedAWSAccountId,
                f?.Engine,
                f?.vCPU,
                f?.memory,
                f?.region,
                f?.DBInstanceStatus,
                f?.yearlyPotentialSavings,
            ], search);
        }
        return true;
    }
   
    return(
        <div>

            <ContainerCardWithDateRange someref={collapseRef}
                title={title}
                titleIcon={AwsLogo}
                showDateRange={false}
                collapsible={true}
                HeaderTitleComponent={()=>(
                    <div>
                        <img src={RDSIcon} height={25} style={{marginLeft: 8, marginRight: 8}} />
                        <span>
                            {title}
                        </span>
                    </div>
                )}
                ExtraHeaderComponent={({ period }) =>
                    data != null && Object.keys(data)?.length ? (
                        <div style={{ marginTop: 4 }}>
                            <span style={{ fontSize: 14 }}>Last updated {moment(lastUpdated).fromNow(true)} ago</span>
                        </div>
                    ) : null
                }
                CenterComponent={()=>(
                    <div>
                     {cards != null && cards ?<h6 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20}}>{cards?.[1]?.value} {cards?.[1]?.name} </h6>:null}
                  </div>
                    )}
                 >
                {({ period }) => (
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    {!loading ?(
                    <div style={{flex:1}}>
                        {cards && <RecommendationsSummaryCards summary={cards} />}
                        <div className={classes.root}>
                            <Collapse in={totalExpand}>
                                {data != null && data?.rdsRecommendations?.length ? (
                                    <Paper className={classes.paper}>
                                     <TableSearch onSearch={setSearch} />
                                      <TableContainer style={{maxHeight:450}}>
                                            <Table className={classes.table} stickyHeader aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                                <AwsRDSRecommendationHead view={view} subscriptionId={subscriptionId} classes={classes} numSelected={selected.length} rowCount={data?.rdsRecommendations?.length} />
                                                <TableBody>
                                                    {data?.rdsRecommendations
                                                     ?.filter(filteredTable)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                        return <MyTableRow date={data} view={view} subscriptionId={subscriptionId} row={row} index={index} list={list} setList={setList} currency={currency} />;
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            ActionsComponent={TablePaginationActions}
                                            rowsPerPageOptions={[10, 25, 50, 75]}
                                            component="div"
                                            count={data?.rdsRecommendations?.filter(filteredTable)?.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                ) : (
                                    <h6 style={{ textAlign: "center" }}>No data found</h6>
                                )}
                            </Collapse>
                        </div>
                    </div>
                    ):<CircularProgress thickness={2} size={60} />
                     }
                     </div>
                )}
            </ContainerCardWithDateRange>
        </div>
    );
}

const useStyles22 = makeStyles()((theme) => ({
    root: {
        backgroundColor: Color.tableHeader,
        padding: theme.spacing(0.5),
    },

    tableRow: {
        fontWeight: "bold",
        backgroundColor: Color.tableHeader,
    },
}));

function AwsRDSRecommendationHead({ view }) {

    const {classes} = useStyles22();

    return (
        <TableHead className={classes.root}>
            <TableRow>
                <TableCell className={classes.tableRow}>DB identifier</TableCell>
                <TableCell className={classes.tableRow}>Instance type</TableCell>
                {view == "all" ? <TableCell className={classes.tableRow}>AWS Account</TableCell> : null}
                <TableCell className={classes.tableRow}>Engine</TableCell>
                {/* {/* <TableCell className={classes.tableRow}>CPU</TableCell> */}
                <TableCell className={classes.tableRow}>AZ</TableCell>
                <TableCell className={classes.tableRow}>Region</TableCell>
                <TableCell className={classes.tableRow}>DB status</TableCell>
                <TableCell className={classes.tableRow}>Potential Savings</TableCell>
            </TableRow>
        </TableHead>
    );
}

function generateURL(data){
    if(data){
    const BASE_PATH = ".console.aws.amazon.com/rds/home?region=";
    const AWS_REGION = data?.region;
    const AWS_DATABASE = "#database:id="
    const AWS_Database=data?.resource
    const CLUSTER = ";is-cluster=false"

    const FINAL_URL = "https://" + AWS_REGION + BASE_PATH + AWS_REGION + AWS_DATABASE +AWS_Database+ CLUSTER;
    window.open(FINAL_URL, "_blank");
    }
}

function MyTableRow({ date, row, view, index, list, setList, currency }) {
    const [open, setOpen] = useState(true);

    return (
        <React.Fragment>
            <TableRow hover key={row.date}>
                <TableCell>
                    <>
                        <IconButton
                            style={{ cursor: "pointer" }}
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                                setOpen(!open);
                                setList(index);
                            }}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>{" "}
                        {row?.resource}
                    </>
                </TableCell>
                <TableCell>{row?.currentDBInstanceType}</TableCell>
                {view == "all" ? <TableCell>{row?.linkedAWSAccountId}</TableCell> : null}
                <TableCell>{row?.Engine}</TableCell>
                {/* {/* <TableCell>{row?.vCPU}</TableCell> */}
                <TableCell>{row?.multiAZ ?'Multi':'Single'}</TableCell> 
                <TableCell>{row?.region}</TableCell>
                <TableCell>{row?.DBInstanceStatus}</TableCell>
                <TableCell>
                <div style={{border: "1px solid #338307",padding:2}}>
                   
                    {Format.formatCurrencySymbol(currency)}
                    {Format.formatNumber(Math.max(...row?.recommendations?.map((m)=>(
                       (parseFloat(m?.yearlyPotentialSavings)/12)
                    ))))+ " /mon"}
                    <br />
                     {Format.formatCurrencySymbol(currency)}
                    {Format.formatNumber(Math.max(...row?.recommendations?.map((m)=>(
                        m?.yearlyPotentialSavings
                    ))))+ " /yr"}
                    
                    </div>
                </TableCell>
            </TableRow>
            <ExpandLavelData date={date} data={row} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

export function ExpandLavelData({ date, data, open, select, set, currency }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2.5}>
                            <Htmltable date={date} data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const useStyles33 = makeStyles()((theme) => ({
    backgroundStyle: {border: "1px solid #338307",margin:2,padding:2
    }
}));

export function Htmltable({date, data, currency }) {


    const {classes} = useStyles33();
    return (
        <>
            <div className="overflow-auto">
                {"reservations" in data && data?.reservations?.length ? (
                    <>
                        <b>Reservations</b>
                        <table id="customers">
                            <tr>
                                <th>Current price</th>
                                <th>Offering type</th>
                                <th>Duration</th>
                                <th>Start date</th>
                                <th>End date</th>
                            </tr>

                            {data &&
                                data?.reservations
                                    ?.sort((a, b) => {
                                        return parseFloat(b?.fixedPrice || 0) - parseFloat(a?.fixedPrice || 0);
                                    })
                                    .map((m) => (
                                        <tr>
                                            <td>
                                                {Format.formatCurrencySymbol(m?.currencyCode)}
                                                {Format.formatNumber(m?.fixedPrice)}
                                            </td>
                                            <td>{m?.offeringType}</td>
                                            <td>{m?.duration}</td>

                                            <td>{moment(m?.startDate, "YYYYMMDD").format("MMM DD, YYYY")}</td>
                                            <td>{m?.duration == "1yr" ? moment(m?.startDate, "YYYYMMDD").add(1, "y").subtract(1, "d").format("MMM DD, YYYY") : moment(m?.startDate, "YYYYMMDD").add(3, "y").subtract(1, "d").format("MMM DD, YYYY")}</td>
                                        </tr>
                                    ))}
                        </table>

                        <br />
                    </>
                ) : null}
                {"recommendations" in data && data?.recommendations?.length ? (
                    <> 
                       <div style={{display: "flex", flexdirection:'row',marginTop:4,alignItems:'center'}}>
                        <b>Recommendations</b> 
                        
                        <td className={classes.backgroundStyle} style={{marginLeft:4}}>
                        <img
                        src={downarrow}
                        style={{
                            width: 20,
                            height: 20,
                            marginRight: 4,
                        }} />
                            <span>{data?.recommendations?.[0]?.recommendationShortDescription} </span>
                        </td>
                       </div>
                       <div style={{display: "flex", flexdirection:'row',marginTop:8}}>
                          <div style={{display: "flex", flexdirection:'row',marginTop:8}}> 
                        <b>Metrics</b>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Popover id="popover-contained">
                                    <Popover.Body>
                                            <b>Based on the last 30 days ({moment(date?.startDate, "YYYYMMDD").format("MMM DD, YYYY")}-{moment(date?.endDate, "YYYYMMDD").format("MMM DD, YYYY")}) usage</b>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <img
                            src={info_icon}
                            style={{
                                width: 20,
                                height: 20,
                                marginLeft: 4,
                                marginRight: 4,
                            }} />
                        </OverlayTrigger>
                        </div>
                        
                       <table id="customers">
                            <tr>
                                <th>Avg. CPU Utilization</th>
                                <th>Avg. Freeable Memory</th>
                                <th>Avg. Free Storage Space</th>
                                <th>Avg. Read IOPS</th>
                                <th>Avg. Write IOPS</th>
                                <th>Avg. Database Connections</th>
                            </tr>
                      
                                        <tr>
                                            <td>
                                                {Format.formatNumber(data?.metrics?.[0].cpuUtilizationAverage)+'%' || null}
                                            </td>
                                            <td>{bytesToSize(data?.metrics?.[1]?.freeableMemoryAverage)||null}</td>
                                            <td>{bytesToSize(data?.metrics?.[2]?.freeStorageSpaceAverage||null)}</td>

                                            <td>{Format.formatNumberInt(data?.metrics?.[4]?.readIOPSAverage||null)}</td>
                                            <td>{Format.formatNumberInt(data?.metrics?.[5]?.writeIOPSAverage||null)}</td>
                                            <td>{Format.formatNumberInt(data?.metrics?.[3]?.databaseConnectionsAverage||null)}</td>
                                        </tr>
                                    
                        </table>

                       </div>
                       <br/>
                        <table id="customers">
                            <tr>
                                <th>From instance</th>
                                <th>To instance</th>
                                {/* <th style={{ whiteSpace: "nowrap" }}>To configuration</th> */}
                                <th>Current price</th>
                                {/* <th>Reserved price</th> */}
                                <th style={{ whiteSpace: "nowrap" }}>On-Demand price</th>
                                <th>Potential savings</th>
                                {/* <th>
                                    <span style={{ whiteSpace: "nowrap" }}>
                                        Savings over <br />
                                        On-Demand
                                    </span>
                                </th> */}
                                <th>
                                    {" "}
                                    Action
                                    {/* <>
                                      <OverlayTrigger placement="left"
                                        overlay={<Tooltip id="button-tooltip">How to terminate RDS instance?</Tooltip>}>
                                        <Badge badgeContent={0} color="secondary">
                                        <Help 
                                        onClick={()=>window.open(AWS_TERMINATE_INSTANCE_HELP, "_blank")} 
                                        style={{cursor: "pointer", fontSize:20, color: "#303" }} />
                                        </Badge>
                                        </OverlayTrigger>
                                    </> */}
                                </th>
                            </tr>

                            {data &&
                                data?.recommendations
                                    ?.sort((a, b) => {
                                        return parseFloat(b?.yearlyPotentialSavings || 0) - parseFloat(a?.yearlyPotentialSavings || 0);
                                    })
                                    .map((m) => (
                                        <tr style={{ borderColor: m?.isBestRecommendation == true ? Color?.lightGreen : null, border: m?.isBestRecommendation == true ? "2px solid #338307" : null }}>
                                            <td>
                                                
                                                    <span style={{ whiteSpace: "nowrap" }}>
                                                        {" "}
                                                        {data?.currentDBInstanceType} <br />
                                                        {m?.fromInstanceType=='reserved'?'Reserved':m?.fromInstanceType}
                                                    </span>
                                             <br />
                                                    <span style={{ whiteSpace: "nowrap" }}>
                                                    {data?.vCPU} CPU, {data?.memory} mem
                                                    </span>
                                               
                                            </td>
                                            <td style={{ alignItems: "center",whiteSpace: "nowrap" }}>
                                                {" "}
                                                {/* {m?.isBestRecommendation == true ? <img style={{ height: 16, marginRight: 4 }} src={recommendation_icon} /> : null} */}
                                                {m?.toInstanceType == "On-Demand" ? (
                                      
                                            <span style={{ whiteSpace: "nowrap" }}>
                                                        {m?.dbInstance}
                                                        <br />
                                                        On-Demand
                                                    </span>
                                                ) : (
                                                    <span style={{ whiteSpace: "nowrap" }}>
                                                        {m?.dbInstance}
                                                        <br />
                                                        Reserved
                                                    </span>
                                                )}
                                                <br />
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                {m?.vCPU} CPU, {m?.memory} mem
                                                </span>
                                            </td>
                                            {/* <td style={{ whiteSpace: "nowrap" }}>
                                                {m?.vCPU} CPU, <br /> {m?.memory} mem
                                            </td> */}
                                           
                                            <td>
                                                <div>
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                {Format.formatCurrencySymbol(currency)}
                                                    {Format.formatNumber(
                                                        (data?.reservations?.[0]?.duration == "3yr")?
                                                            parseFloat(m?.currentDBInstancePrice / 3 / 12):
                                                            parseFloat(m?.currentDBInstancePrice / 1 / 12)
                                                    ) + " /mon"}
                                                </span>
                                                </div>
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                {Format.formatCurrencySymbol(currency)}
                                                {Format.formatNumber(m?.currentDBInstancePrice)}
                                                {data?.reservations?.[0]?.duration == "3yr" ? " /3yrs" : " /yr"}
                                                </span>
                                            </td>
                                            {/* <td style={{ whiteSpace: "nowrap" }}>
                                                {m?.toInstanceType == "reservedInstance" && m?.termLeaseLength == "3yr" ? (
                                                    Format.formatCurrencySymbol(currency) + Format.formatNumber(m?.threeYearlyReservedInstancePrice) + " /" + m?.termLeaseLength
                                                ) : m?.toInstanceType == "reservedInstance" && m?.termLeaseLength == "1yr" ? (
                                                    Format.formatCurrencySymbol(currency) + Format.formatNumber(m?.yearlyReservedInstancePrice) + " /" + m?.termLeaseLength
                                                ) : (
                                                    <span style={{ color: "gray", fontSize: 12 }}>N/A</span>
                                                )}
                                            </td> */}
                                            <td style={{ whiteSpace: "nowrap" }}>
                                                {m?.toInstanceType == "reservedInstance" ?
                                                    <span style={{ color: "gray", fontSize: 12 }}>N/A</span> :
                                                    <>
                                                        <div>
                                                            {Format.formatCurrencySymbol(currency) + Format.formatNumber(parseFloat(m?.yearlyOnDemandPrice)/12) + " /mon"}
                                                        </div>
                                                        {Format.formatCurrencySymbol(currency) + Format.formatNumber(m?.yearlyOnDemandPrice) + " /yr"}
                                                    </>}
                                            </td>
                                            <td style={{ whiteSpace: "nowrap" }}>

                                                {Format.formatCurrencySymbol(currency)}
                                                {Format.formatNumber(parseFloat(m?.yearlyPotentialSavings)/12) + " /mon"}
                                                <br />
                                                {Format.formatCurrencySymbol(currency)}
                                                {Format.formatNumber(m?.yearlyPotentialSavings) + " /yr"}
                                               
                                            </td>
                                            {/* <td style={{ whiteSpace: "nowrap" }}>{m?.toInstanceType == "reservedInstance" ? m?.calculatedPrice?.savingsOverOnDemandPercent?.USD?.toFixed(2) + "%" : <span style={{ color: "gray", fontSize: 12 }}>N/A</span>}</td> */}
                                            <td align="left">
                                                <Button 
                                                onClick={()=>{generateURL(data)}}
                                                 variant="contained" style={{ fontSize: 11, borderRadius: 15, backgroundColor: "#3f50b5", color: "#FFF", textTransform: "initial", whiteSpace: "nowrap" }}>
                                                    Fix in AWS
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                        </table>
                        <br />
                      
                    </>
                ) : null}
            </div>
        </>
    );
}
