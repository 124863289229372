import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getGCPProjectSuccess, getGCPProjectFailure, getGCPProjectAccountFailure, getGCPProjectAccountSuccess } from "../actions/actions";
const { REACT_APP_GCP_PROJECT, REACT_APP_GCP_PROJECT_ACCOUNT } = process.env;

function* getProjectData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_GCP_PROJECT, {
            params: {
                customerId: payload.customerId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getGCPProjectFailure(statusCode));
            } else {
                yield put(getGCPProjectSuccess(res.data));
            }
        } else {
            yield put(getGCPProjectFailure(res));
        }
    } catch (error) {
        yield put(getGCPProjectFailure(error));
    }
}

function* getProjectAccountData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_GCP_PROJECT_ACCOUNT, {
            params: {
                customerId: payload.customerId,
                organizationId: payload.organizationId,
                billingAccountId: payload.billingAccountId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getGCPProjectAccountFailure(statusCode));
            } else {
                yield put(getGCPProjectAccountSuccess(res.data));
            }
        } else {
            yield put(getGCPProjectAccountFailure(res));
        }
    } catch (error) {
        yield put(getGCPProjectAccountFailure(error));
    }
}

export default function* gcpProjectSaga() {
    yield takeLatest(types.GCP_PROJECT_REQUEST, getProjectData);
    yield takeLatest(types.GCP_PROJECT_ACCOUNT_REQUEST, getProjectAccountData);
}
