import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import { makeStyles, withStyles } from "MakeWithStyles";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button, Paper } from "@mui/material";
import GetWindowDimension from "components/HOC/GetWindowDimension";
import {TIMERS} from "../../util/AppConstants";

const initial_delay = TIMERS.UPPER_CHART_INTERVAL;

const useStyles = makeStyles()((theme) => ({
  margin: {
    margin: theme.spacing(0.3),
    borderRadius: 0,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

function SlideShow(props) {
  const { names, children, view, width, height, setNextRef, setPrevRef, showingIndex, arrowsHandled = false, pausePlayRef = null } = props;
  const [index, setIndex] = useState(0);
  const [autoplay, setAutoplay] = useState(true);
  const [chartIndex, setChartIndex] = useState(0);
  const {classes} = useStyles();
  const timeoutRef = useRef(null);

  // function resetTimeout() {
  //   if (timeoutRef.current) {
  //     clearTimeout(timeoutRef.current);
  //   }
  // }

  // useEffect(() => {
  //   resetTimeout();
  //   if (autoplay) {
  //     timeoutRef.current = setTimeout(
  //       () =>
  //         setIndex((prevIndex) =>
  //           prevIndex === children?.length - 1 ? 0 : prevIndex + 1
  //         ),
  //       props?.delay || initial_delay
  //     );
  //   }
  //   return () => {
  //     resetTimeout();
  //   };
  // }, [index, autoplay]);

  useEffect(()=>{
      if(showingIndex < chartIndex){
          onPrev();
      } else if(showingIndex > chartIndex){
          onNext();
      }
      setChartIndex(showingIndex);
      // setNextRef(()=>onNext());
      // setPrevRef(()=>onPrev());
  }, [showingIndex])

    function onNext(){
      setIndex(index === children.length - 1 ? 0 : index + 1)
    }

    function onPrev(){
        setIndex(index === 0 ? children.length - 1 : index - 1)
    }
  return (
    <div className="slideshow">
      <Paper elevation={0} className="paperBottom">
        <div
          className="slideshowSlider"
          style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
          // onMouseOver={(i) => setAutoplay(false)}
          // onMouseOut={() => setAutoplay(true)}
        >
          {children &&
            children?.map((item, index) => (
              <div className="slide" key={index}>
                {item}
              </div>
            ))}
        </div>

        <div
          style={{

            display: arrowsHandled?"none":"flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              marginLeft: 30,
              position: "absolute",
              top: view == "All" ? "40%" : "50%",
            }}
            onClick={() =>
              setIndex(index === 0 ? children.length - 1 : index - 1)
            }
          >
            <ArrowBackIosIcon fontSize="large" color="primary" />
          </div>
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              marginRight: view === "All" ? 30 : 50,
              position: "absolute",
              right: "0%",
              top: view == "All" ? "40%" : "50%",
            }}
            onClick={() =>
              setIndex(index === children.length - 1 ? 0 : index + 1)
            }
          >
            <ArrowForwardIosIcon fontSize="large" color="primary" />
          </div>
        </div>
      </Paper>

      <div className="slideshowDots" style={{ alignItems:'center' }}>
        <div className={'pause-play-icon'}>
          <span type="button" onClick={() => setAutoplay(!autoplay)} ref={pausePlayRef} data-playstate={autoplay?'playing':'paused'}>
            {autoplay ? (
              <PauseIcon style={{ fontSize: 15 }} color="primary" />
            ) : (
              <PlayArrowIcon style={{ fontSize: 15 }} />
            )}
          </span>
        </div>
          <div style={{display: "flex", width: '100%'}}>
            <div style={{
                display: 'block',
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                maxWidth: 'calc(100vw - 165px)',
                userSelect: "none",
                margin: '0 auto'
            }}>
          {names &&
            names?.map((item, idx) => (
              <Button
                size="small"
                style={{
                  outline: "none",
                  textTransform: "none",
                    whiteSpace: "nowrap",
                  backgroundColor:
                    // index == idx ? "rgba(63, 81, 181, 0.86)" : null,
                    index == idx ? "rgba(60, 5, 114, 0.755)" : null,
                  fontWeight: index == idx ? 400 : null,
                  color: index == idx ? "white" : null,
                }}
                key={idx}
                className={classes.margin}
                onClick={() => {
                  setIndex(idx);
                }}
              >
                {item}
              </Button>
            ))}
        </div>
          </div>
      </div>
    </div>
  );
}
export default GetWindowDimension(SlideShow);
