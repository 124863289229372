import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getAllServicesSuccess, getAllServicesFailure} from "../actions/actions";
const { REACT_APP_ALL_CLOUD_SERVICES } = process.env;

function* getAllServicesData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_ALL_CLOUD_SERVICES, {
            params: { customerId: payload.customerId },
           
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAllServicesFailure(statusCode));
            } else {
                yield put(getAllServicesSuccess(res.data));
            }
        } else {
            yield put(getAllServicesFailure(res));
        }
    } catch (error) {
        yield put(getAllServicesFailure(error));
    }
}


export default function* allServicesSaga() {
    yield takeLatest(types.ALL_SERVICES_REQUEST, getAllServicesData);
}
