import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "MakeWithStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/Icon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Format from "components/NumberFormat";
import moment from "moment";
import Color from "../../styles/color";
import { LinearProgress } from "@mui/material";
import { getGCPServicesRequest } from "../../redux/actions/actions";

import Cost from "../../components/Cost";
import gcp_billing from "../../images/gcp_billing.svg";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import DateRange from "../../components/DateRange";
import gcp_project from "../../images/gcp_project.svg";
import resource_group from "../../images/resource_group.svg";
import big_query from "../../images/gcpResources/gcp_bigquery.svg";
import ComputeIcon from "../../images/Virtual-Machine.svg";
import ContainerCardWithDateRange from "../../components/ContainerCardWithDateRange";
import TablePaginationActions from "../../components/TablePaginationActions";

const useStyles = makeStyles()((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
}));

export default function GCPServicesHome({ currency, billingAccount }) {
    const dispatch = useDispatch();
    const {classes} = useStyles();
    const [progress, setProgress] = useState(0);
    const [selected, setSelected] = React.useState([]);
    const [list, setList] = useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [showingDetails, setShowingDetails] = React.useState(null);
    const [total, setTotal] = useState("");
    const [period, setPeriod] = useState("1M");

    const userData = useSelector((state) => ({
        id: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
    }));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    let GcpServicesData = useSelector((state) => state?.gcpServicesReducer?.ServicesData?.data);

    let loading = useSelector((state) => state?.gcpServicesReducer?.loading);

    useEffect(() => {
        const customerId = userData.id;
        if(GcpServicesData == null){
        dispatch(
            getGCPServicesRequest({
                customerId,
            })
        );
        }
    }, [userData.id]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return loading ? (
        <LinearProgress variant="determinate" value={progress} />
    ) : (
        <div>
            <div className={classes.root}>
                <ContainerCardWithDateRange title={'GCP Services'} defaultPeriod={"1M"} titleIcon={resource_group}
                                            ExtraHeaderComponent={({period})=>(
                                                GcpServicesData != null && GcpServicesData?.SpendByServices?.[period]?.services?.length ? (
                                                    <div style={{ marginTop: 4 }}>
                                                        <span>{moment(GcpServicesData?.SpendByServices?.[period]?.startDate, "YYYYMMDD").format("MMM DD, YYYY")}</span> -{" "}
                                                        <span>{moment(GcpServicesData?.SpendByServices?.[period]?.endDate, "YYYYMMDD").format("MMM DD, YYYY")}</span>
                                                    </div>
                                                ) : null
                                            )}
                                            MiddleComponent={({period})=>(
                                                <span style={{display: "flex", justifyContent: "flex-end"}}>
                                                    Total: {currency}{GcpServicesData?.SpendByServices?.[period]?.totalCost?
                                                    Format.formatNumber(GcpServicesData?.SpendByServices?.[period]?.totalCost):
                                                    "__.__"}
                                                </span>
                                            )}
                                            collapsible={false}
                >
                    {({period})=>(
                        GcpServicesData != null && GcpServicesData?.SpendByServices?.[period]?.services?.length ? (
                            <Paper className={classes.paper}>
                                <TableContainer>
                                    <Table className={classes.table} aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                        <GCPBillingHead classes={classes} currency={currency} />
                                        <TableBody>
                                            {(GcpServicesData?.SpendByServices?.[period]?.services).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                return <MyTableRow row={row} index={index} list={list} setList={setList} currency={currency} />;
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    ActionsComponent={TablePaginationActions}
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={GcpServicesData?.SpendByServices?.[period]?.services?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                            </Paper>
                        ) : (
                            <span style={{ display: "flex", justifyContent: "center", fontSize: 20 }}>No data found</span>
                        )
                    )}
                </ContainerCardWithDateRange>
            </div>
        </div>
    );
}

const useStyles22 = makeStyles()((theme) => ({
    root: {
        backgroundColor: Color.tableHeader,
        padding: theme.spacing(0.5),
    },

    tableRow: {
        fontWeight: "bold",
        fontSize:14,
        fontWeight:'bold'
    },
}));

function GCPBillingHead({ totalCost, currency = "" }) {

    const {classes} = useStyles22();

    return (
        <TableHead className={classes.root}>
            <TableRow>
                <TableCell></TableCell>

                <TableCell className={classes.tableRow}>Service</TableCell>
                <TableCell className={classes.tableRow}>Location</TableCell>
                <TableCell className={classes.tableRow}>Project Id</TableCell>
                <TableCell className={classes.tableRow}>Billing account Id</TableCell>
                <TableCell className={classes.tableRow}>Billing account name</TableCell>
                <TableCell className={classes.tableRow}>Organization Id</TableCell>
                <TableCell className={classes.tableRow}>Organization name</TableCell>

                <TableCell className={classes.tableRow}>
                    Cost
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

function MyTableRow({ row, index, list, setList, currency }) {
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState("");

    useEffect(() => {
        if (row) {
            setDate(moment(row.date, "YYYYMMDD").format("MMM DD, YYYY"));
        }
    }, [row]);

    return (
        <React.Fragment>
            <TableRow hover key={row.date}>
                <TableCell>
                    <IconButton
                        style={{ cursor: "pointer" }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {" "}
                    {row?.service == "Compute Engine" && <img src={ComputeIcon} height={20} style={{ marginRight: 4 }} />}
                    {row?.service == "Qig Query" && <img src={big_query} height={20} style={{ marginRight: 4 }} />}
                    {row?.service}
                </TableCell>
                <TableCell>{row?.location}</TableCell>
                <TableCell>{row?.projectId}</TableCell>
                <TableCell>{row?.billingAccountId}</TableCell>
                <TableCell>{row?.billingAccountName}</TableCell>
                <TableCell>{row?.organizationId}</TableCell>
                <TableCell>{row?.organizationName}</TableCell>

                <TableCell>
                    {currency}
                    {Format.formatNumber(Number(row.cost))}
                </TableCell>
            </TableRow>
            <ExpandLavelData data={row} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

export function ExpandLavelData({ data, open, select, set, currency }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2.5}>
                            <Htmltable data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <div style={{ maxHeight: 300 }}>
            <table className="center" id="customers">
                <tr>
                    <th>Sku Id</th>
                    <th>Sku description</th>
                    <th>Usage amount</th>
                    <th>Usage unit</th>
                    <th>Cost</th>
                </tr>

                {data &&
                    data?.meter
                        ?.sort((a, b) => {
                            return parseFloat(b?.value || 0) - parseFloat(a?.value || 0);
                        })
                        .map((m) => (
                            <tr>
                                <td>{m.skuId}</td>
                                <td>{m.skuDescription}</td>
                                <td>{m.usageAmount}</td>
                                <td>{m.usageUnit}</td>
                                <td>{currency + Format.formatNumber(Number(m?.cost))}</td>
                            </tr>
                        ))}
            </table>
        </div>
    );
}
