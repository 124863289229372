import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "MakeWithStyles";
import { Table, TableBody, TableCell as TCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Divider, LinearProgress } from "@mui/material";
import storage_account_icon from "../../images/resources/Storage_Account.svg";
import function_apps_icon from "../../images/function-apps.png";
import Cost from "../../components/Cost";
import { bytesToSize, convertToInternationalCurrencySystem } from "../../util/Miscellaneous";
import axios from "../../connection/axios";
import { CancelRounded } from "@mui/icons-material";
import { getAwsRDSRequest,getAwsRDSAccountRequest } from "../../redux/actions/actions";
import moment from "moment";
import colors from "styles/color";
import RDSIcon from "../../images/newCloudTrakrIcons/AWSRDS.png";
import AmazonRDS from "../../images/awsAccounts/Amazon-RDS.svg";
import AwsELC from "../../images/awsAccounts/Amazon-ElastiCache_light-bg.svg";
import AwsEC2 from "../../images/awsAccounts/Amazon-EC2.svg";
import AmazonS3 from "../../images/awsAccounts/Amazon-Simple-_Storage-Service-S3.svg";
import AmazonRoute53 from "../../images/awsAccounts/Amazon-Route-53.svg";
import AmazonCloudFront from "../../images/awsAccounts/Amazon-CloudFront_light-bg.svg";
import AmazonApiGateway from "../../images/awsAccounts/Amazon-API-Gateway.svg";
import AWSSecretmanager from "../../images/awsAccounts/AWS-Secrets-Manager.svg";
import AWSConfig from "../../images/awsAccounts/AWS-Config.svg";
import AmazonCloudWatch from "../../images/awsAccounts/Amazon-CloudWatch_Alarm_light-bg.svg";
import LinkText from "components/LinkText";

import SlideShow from "../../components/CustomSlider/SlideShow";
import ReactD3BarChart from "../../components/D3Charts/ReactD3BarChart";
import { commonConversion } from "../../util/DateRangeFormatter";
import Format from "components/NumberFormat";
import LineChart from "pages/Charts/LineChart";
import TablePagination from "@mui/material/TablePagination";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import TablePaginationActions from "../../components/TablePaginationActions";
import DetailsDialogBox from "../../components/DetailsDialogBox";
import { index } from "d3-array";
import {OverlayTrigger, Tooltip,Popover} from "react-bootstrap";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TableSearch from "../../components/TableSearch";
import Collapse from "@mui/material/Collapse";

import IconButton from "@mui/material/Icon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const { REACT_APP_AWS_RDS_DETAILS, REACT_APP_AWS_RDS_METRIC } = process.env;

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        //marginBottom: 5,
    },
}));

function TableCell(props) {
    return (
        <TCell {...props} style={{ padding: 0,paddingRight:8  }}>
            {props.children}
        </TCell>
    );
}

export default function AWSRDS(props) {
    const dispatch = useDispatch();
    const { customerId, subscriptionId, tenantId, currency } = props;
    const {classes} = useStyles();
    const [data, setData] = useState(null);
    // const [loading, setLoading] = useState(true);
    // const customerId = useSelector((state) => state.userDetailsReducer.id);
    // const loading = useSelector(
    //   (state) => state.azureRecommendationReducer.loading
    // );
    const [showingFunction, setShowingFunction] = useState(null);
    const [showingMetrics, setShowingMetrics] = useState(null);
    const [metricsData, setMetricsData] = useState([]);
    const [chart, setChart] = useState([]);
    const [meterChart, setMeterChart] = useState([]);
    const [functionsList, setFunctionsList] = useState([]);
    const [showMetricSpecific, setShowMetricSpecific] = useState([]);
    const [metricTotals, setMetricTotals] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [progress, setProgress] = useState(0);
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [openDetails, setOpenDetails] = useState(false);

    let awsRDS = useSelector((state) =>subscriptionId == "all"? state?.awsRDSReducer?.awsRDSData:state?.awsRDSReducer?.awsRDSAccountData);
    let loading = useSelector((state) => subscriptionId == "all" ? state?.awsRDSReducer?.loading : state?.awsRDSReducer?.awsAccountLoading );

    const handleClose = () => {
        setOpenDetails(false);
    };
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (customerId) {      
            if (awsRDS == null && !tenantId && subscriptionId == "all") {
                dispatch(
                    getAwsRDSRequest({
                        customerId,
                    })
                );
        } else if (subscriptionId && tenantId) {     
            dispatch(
                getAwsRDSAccountRequest({
                    customerId,
                    subscriptionId,
                    tenantId,
                })
            );
        }
    }
    }, [customerId, subscriptionId, tenantId]);

    function showFunctionDetails(e) {
        setShowingFunction(e)
        setDetailsLoading(true)
        
        axios
            .post(REACT_APP_AWS_RDS_DETAILS, {
                customerId,
                region: e?.location,
                tenantId: e?.payerAccountId,
                subscriptionId:e?.usageAccountId,
                dbName: e?.resourceId,
            })
            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200 && response.data) {
                        const type = Object.keys(response?.data)?.[0] == "DBCluster" ? "DBCluster" : Object.keys(response?.data)?.[0] == "DBInstance" ? "DBInstance" : null;
                        if(type == "DBCluster")  
                            {
                                // setShowingFunction({ ...e, type, ...response.data?.DBCluster?.[0] });
                                getFunctionMetricData({...e, type, ...response.data?.DBCluster?.[0]});
                             }else if(type == "DBInstance"){
                                // setShowingFunction({ ...e, type, ...response.data?.DBInstance?.[0]});
                                getFunctionMetricData({...e, type, ...response.data?.DBInstance?.[0]});
                             }else{
                                setMetricsData(null)
                             }                
                    } 
                } 
            })
            .catch((e) => {})
            .finally(() => setDetailsLoading(false));
    }

    function getFunctionMetricData(e) {
        setShowingFunction({...e})
        setDetailsLoading(true)
        axios
            .post(REACT_APP_AWS_RDS_METRIC, {
                    customerId,
                    region: e?.location,
                    tenantId: e?.payerAccountId,
                    subscriptionId:e?.usageAccountId,
                    dbName: e?.resourceId,
                    startDate:e?.startDate,
                    endDate:e?.endDate,
                    dbType:e?.type,
            })
            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200 && response.data) {
                        //setShowingMetrics(response?.data?.[0]);
                        const final = [];
                        for (var o in response?.data) {
                            const data = response?.data?.[o];
                            const timeseries = data.Timestamps.map((t, i) => ({
                                date: new Date(t),
                                value: parseInt(parseInt(data?.Values[i])?.toFixed(2)),
                            }));
                            const name = data.Label;
                            const unit = data.Id;
                            var metricData = { timeseries, name,};
                            final.push({ name, metricData,unit: unit == "metricdata" ? "Percent" : unit});
                        }
                        setMetricsData(final);
                        setMetricTotals(final.map((r, i) => r?.metricData?.timeseries.map((r) => r.value).reduce((a, b) => a + b)));
                    } 
                } 
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => setDetailsLoading(false));
    }

    // useEffect(() => {
    //     if (showingMetrics) {
    //         var result = [];
    //         for (var obj in showingMetrics) {
    //             const selected = showingMetrics[obj];

    //             const name = selected.totalData.value?.[0].name?.localizedValue;
    //             const unit = selected.totalData.value?.[0]?.unit;

    //             var metricData = selected.totalData.value?.[0].timeseries?.[0]?.data?.[0];
    //             var timeseries = selected.metrics.value?.[0].timeseries?.[0]?.data;

    //             var dataKey = new Set(Object.keys(metricData));
    //             dataKey.delete("timeStamp");
    //             dataKey = dataKey.keys().next().value;

    //             var dataKeyT = new Set(Object.keys(timeseries?.[0]));
    //             dataKeyT.delete("timeStamp");
    //             dataKeyT = dataKeyT.keys().next().value;

    //             timeseries = timeseries.map(({ timeStamp, ...rest }) => {
    //                 let date = new Date(timeStamp);
    //                 date = date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth()+1) : (date.getMonth()+1)) + "-" + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
    //                 return { date, value: rest[dataKeyT] };
    //             });

    //             timeseries = sumDuplicates(timeseries, "date", "value").map(({ date, ...rest }) => ({ ...rest, date: new Date(date) }));
    //             metricData = { timeseries, unit, total: metricData[dataKey] };
    //             result.push({ name, unit, metricData });
    //         }
    //         setMetricsData(result);
    //         setMetricTotals(result.map((r, i) => r?.metricData?.timeseries.map((r) => r.value).reduce((a, b) => a + b)));
    //     }
    // }, [showingMetrics]);

    const [search, setSearch] = useState(null);
    const [list, setList] = useState(null);

    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.resourceId,
                f?.meters?.[0]?.productFamily,
                f?.serviceName,
                f?.serviceCode,
                f?.usageAccountId,
                f?.usageAccountName,
                f?.payerAccountId,
                f?.location,
            ], search);
        }
        return true;
    }

    return loading ? (
        <div className={classes.root}>
            <LinearProgress variant="determinate" value={progress} />
        </div>
    ) : (
        <div>
            <div elevation={10} style={{ padding: 10 }}>
                <ContainerCardWithDateRange
                    title={"RDS"}
                    titleIcon={RDSIcon}
                    showDateRange={true}
                    collapsible={true}
                    defaultPeriod={"1M"}
                    datePeriod={({ period }) => ({
                        start: "__",
                        end: "__",
                        rawStart: moment(awsRDS?.[period]?.startDate, "YYYYMMDD"),
                        rawEnd: moment(awsRDS?.[period]?.endDate, "YYYYMMDD"),
                    })}
                    totalCost={({ period }) => currency + Format.formatNumber(awsRDS?.[period]?.totalCost)}
                    datePeriodEnabled={true}
                    showDatePeriod={(period) => !!awsRDS != null && awsRDS?.[period]?.resources?.length}
                    onPeriodChange={() => handleChangeRowsPerPage({ target: { value: 10 } })}
                >
                    {({ period }) => (
                        <>
                            {!loading && awsRDS?.[period]?.resources?.length ? (
                                <div>
                                    <Box display={!loading && awsRDS?.[period]?.resources?.length ? "flex" : "none"}>
                                        <Box flex={1}>
                                            <TableSearch onSearch={setSearch} />
                                            <TableContainer style={{ height: 350 }} component={Paper}>
                                                <Table className={classes.table} aria-label="simple table">
                                                    <TableHead style={{ backgroundColor: "#cfdac8",padding:8 }}>
                                                        <TableRow>
                                                            <TableCell/>
                                                            <TableCell>
                                                                <b>DB instance name</b>
                                                            </TableCell>
                                                            <TableCell>
                                                                <b>Service</b>
                                                            </TableCell>
                                                            <TableCell>
                                                                <b>Usage account</b>
                                                            </TableCell>
                                                            <TableCell>
                                                                <b>Payer account</b>
                                                            </TableCell>
                                                            <TableCell>
                                                                <b>Location</b>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <b>Cost</b>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody style={{ padding: 0 }}>
                                                        {awsRDS?.[period]?.resources
                                                            ?.filter(filteredTable)
                                                            ?.sort((r1, r2) => r2.cost - r1.cost)

                                                            // ?.filter((g) => {
                                                            //     if (g.resourceId.trim() || g?.meters?.[0]?.productFamily.toString().toLowerCase() != "storage snapshot") {
                                                            //         return g;
                                                            //     }
                                                            // })
                                                            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            ?.map((row, index) => (
                                                                <MyTableRow awsRDS={awsRDS} period={period} row={row} index={index} list={list} setList={setList} currency={currency} setOpenDetails={setOpenDetails} showFunctionDetails={()=>showFunctionDetails(row)}  />
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    </Box>
                                    <TablePagination
                                        ActionsComponent={TablePaginationActions}
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={
                                            awsRDS?.[period]?.resources?.filter(filteredTable)?.filter((g) => {
                                                if (g.resourceId.trim() || g?.meters?.[0]?.productFamily.toString().toLowerCase() != "storage snapshot") {
                                                    return g;
                                                }
                                            })?.length
                                        }
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </div>
                            ) : (
                                <Typography style={{ display: "flex", justifyContent: "center" }}>No data found</Typography>
                            )}
                            <DetailsDialogBox
                                open={openDetails}
                                data={showingFunction}
                                title={"RDS"}
                                detailsData={showingFunction}
                                chartData={metricsData}
                                handleClose={() => {
                                    handleClose();
                                }}
                                currency={currency}
                                loading={detailsLoading}
                                metricTotals={metricTotals}
                                rawStart={moment(awsRDS?.[period]?.startDate, "YYYYMMDD")}
                                rawEnd={moment(awsRDS?.[period]?.endDate, "YYYYMMDD")}
                            />
                        </>
                    )}
                </ContainerCardWithDateRange>
            </div>
        </div>
    );
}
export function MyTableRow(props) {
    const { awsRDS, period, row, index, list, setList, currency, showFunctionDetails,setOpenDetails } = props;
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <TableRow key={index + row?.serviceCode + row?.cost}>
                <TableCell>
                    <IconButton
                        style={{ cursor: "pointer" }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                        {row.serviceName == "Amazon Relational Database Service" && <img src={RDSIcon} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.serviceName == "Elastic Load Balancer" && <img src={AwsELC} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.serviceName == "Amazon Elastic Compute Cloud" && <img src={AwsEC2} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.serviceName == "Amazon Simple Storage" && <img src={AmazonS3} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.serviceName == "Amazon Route 53" && <img src={AmazonRoute53} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.serviceName == "Amazon Cloud Front" && <img src={AmazonCloudFront} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.serviceName == "Amazon Api Gateway" && <img src={AmazonApiGateway} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.serviceName == "AWS Secrets Manager" && <img src={AWSSecretmanager} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.serviceName == "AWS Config" && <img src={AWSConfig} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.serviceName == "Amazon CloudWatch" && <img src={AmazonCloudWatch} width={16} height={16} style={{ marginRight: 4 }} />}

                        <a
                            href={"#"}
                            onClick={() => {
                                showFunctionDetails({
                                    ...row,
                                    startDate: awsRDS?.[period]?.startDate,
                                    endDate: awsRDS?.[period]?.endDate,
                                    payerAccountId: row?.payerAccountId || awsRDS?.payerAccountId,
                                    usageAccountId: row?.usageAccountId || awsRDS?.usageAccountId,
                                });
                                setOpenDetails(true);
                            }}
                        >
                            {row?.resourceId || <span style={{ color: colors.gray }}> {row?.meters?.[0]?.productFamily} </span>}
                        </a>
                    </div>
                </TableCell>

                <TableCell>{row?.serviceCode}</TableCell>
                <TableCell>
                    {"usageAccountName" in row ? (
                        <OverlayTrigger placement="right" overlay={<Popover id="popover-contained">{"usageAccountName" in row && <Popover.Body style={{ fontSize: 11 }}>{row?.usageAccountName}</Popover.Body>}</Popover>}>
                            <span style={{ whiteSpace: "nowrap" }}>{row?.usageAccountId || row.usageAccountName}</span>
                        </OverlayTrigger>
                    ) : (
                        row?.usageAccountId || awsRDS?.usageAccountId
                    )}
                </TableCell>
                {/*{row?.usageAccountId || awsRDS?.usageAccountId}</TableCell>*/}
                <TableCell>{row?.payerAccountId || awsRDS?.payerAccountId}</TableCell>

                <TableCell>{row?.location}</TableCell>
                <TableCell align="right">
                    {currency}
                    {Format.formatNumber(Number(row.cost))}
                </TableCell>
            </TableRow>
            <ExpandLavelData index={index} data={row} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

export function ExpandLavelData({ data, open, select, set, currency }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                       <Box margin={2.5}>
                            <Htmltable data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <div style={{ maxHeight: 300 }} className="overflow-auto">
            <table id="customers">
                 {data?.meters && Object.keys(data?.meters)?.length ? (
                    <tr>
                        <th>Operation</th>
                        <th>Product family</th>
                        <th>Usage type</th>
                        <th>Description</th>                      
                        <th>Total</th>
                    </tr>
                ):null}

                {data &&
                    data?.meters
                        ?.sort((a, b) => {
                            return parseFloat(b?.cost || 0) - parseFloat(a?.cost || 0);
                        })
                        .map((m) =>
                            Object.keys(m)?.length ? (
                                <tr>
                                    <td>{m?.operation||'-'}</td>
                                    <td>{m?.productFamily||'-'}</td>
                                    <td>{m.LineItemUsageType||'-'}</td>
                                    <td>{m.lineItemDescription||'-'}</td>
                                    <td>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                                </tr>
                            ) : null
                        )}
            </table>
        </div>
    );
}



function sumDuplicates(data, checkKey, valueKey) {
    var result = [];
    data.forEach(function (a) {
        if (!this[a[checkKey]]) {
            const data = {};
            data[checkKey] = a[checkKey];
            data[valueKey] = 0;
            // this[a.date] = { date: a.date, value: 0 };
            this[a[checkKey]] = data;
            result.push(this[a[checkKey]]);
        }
        this[a[checkKey]][valueKey] += a[valueKey];
    }, Object.create(null));
    return result;
}
