import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getAwsEC2RecommendationSuccess, getAwsEC2RecommendationFailure,getAwsAccountEC2RecommendationFailure,getAwsAccountEC2RecommendationSuccess} from "../actions/actions";
const { REACT_APP_AWS_EC2_RECOMMENDATION,REACT_APP_AWS_EC2_ACCOUNT_RECOMMENDATION } = process.env;

function* getawsEC2RecommendationSagaData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AWS_EC2_RECOMMENDATION, {
            params: {
                customerId: payload.customerId,             
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAwsEC2RecommendationFailure(statusCode));
            } else {
                yield put(getAwsEC2RecommendationSuccess(res.data));
            }
        } else {
            yield put(getAwsEC2RecommendationFailure(res));
        }
    } catch (error) {
        yield put(getAwsEC2RecommendationFailure(error));
    }
}

function* getawsEC2RecommendationAccountSagaData({ payload }) {
    try {
        const res = yield call(axios.post, REACT_APP_AWS_EC2_ACCOUNT_RECOMMENDATION, {     
                customerId: payload.customerId,
                tenantId:payload.tenantId,
                subscriptionId:payload.subscriptionId
           
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAwsAccountEC2RecommendationFailure(statusCode));
            } else {
                yield put(getAwsAccountEC2RecommendationSuccess(res.data));
            }
        } else {
            yield put(getAwsAccountEC2RecommendationFailure(res));
        }
    } catch (error) {
        yield put(getAwsAccountEC2RecommendationFailure(error));
    }
}


export default function* awsEC2RecommendationSaga() {
    yield takeLatest(types.AWS_EC2_RECOMMENDATION_REQUEST, getawsEC2RecommendationSagaData);
    yield takeLatest(types.AWS_EC2_ACCOUNT_RECOMMENDATION_REQUEST, getawsEC2RecommendationAccountSagaData);

}
