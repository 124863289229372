import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Collapse,
    IconButton,
    MenuList,
    Paper,
    Popper,
    MenuItem,
    Grow
} from "@mui/material";
import DateRange from "./DateRange";
import {KeyboardArrowDown, KeyboardArrowUp, Print} from "@mui/icons-material";
import moment from "moment";
import colors from "../styles/color";
import {useReactToPrint} from "react-to-print";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DownloadCSVIcon from "../images/download_csv.svg";

const DATE_FORMAT = "MMM DD, YYYY";

export default function ContainerCardWithDateRange({defaultPeriod = null,CenterComponent = ()=>null, title, titleIcon,titleIconHeight = 25, titleStyle = {},
                                                     collapsible = true, CustomHeader = null,
                                                     children: ChildComponent, showDateRange = true,
                                                     dataAvailable = false, ExtraHeaderComponent = ()=>null,
                                                       MiddleComponent = ()=>null, datePeriod = ()=>{}, totalCost = ()=>{},
                                                       showDatePeriod= ()=>{}, datePeriodEnabled = false,
                                                       onlyTotal = false, onPeriodChange = () => {},
    HeaderTitleComponent = null, DateRangeValues=null, DateRangeProps = {},
     printable = true, onSaveAsCSV = ()=>{}, saveAsCSV = false, myref = {current: {}}, someref = null, id = null
                                                   }){
    const [period, setPeriod] = useState("1M");
    const [showing, setShowing] = useState(true);
    const [collapseClickable, setCollapseClickable] = useState(true);
    const [printDropOpened, setPrintDropOpened] = useState(false);
    const containerRef = useRef();
    const printPopperRef = useRef(null);

    function onDateChange(e){
        setPeriod(e);
        onPeriodChange(e);
    }

    const onPrint = useReactToPrint({
        content: () => containerRef.current,
    });

    const onCollapse=(s)=>setShowing(s || !showing);

    useEffect(()=>{
        myref.current.onCollapse = () => onCollapse();
        myref.current.collapsed = !showing;
    }, [showing]);

    // const callee = ()=>{
    //     if(someref?.how && someref?.what){
    //         if(someref.what in someref.where){}
    //         else {
    //             debugger
    //             const g = someref.where;
    //             g[someref.what] = this;
    //             someref.how(g)
    //         }
    //     }
    //
    // }

    useEffect(()=>{
        if(someref?.how && someref?.what){
            if(someref.what in someref.where){}
            else {
                const g = someref.where;
                g[someref.what] = onCollapse;
                someref.how(g)
            }
        }
    }, [someref])

    return (
        <Paper elevation={10} ref={containerRef} id={id}>
          <div>
            <div
                className={"accordian_item1"}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                    cursor: collapsible?'pointer':'',
                    // border: '1px solid'
                }}
                onClick={()=>collapseClickable && collapsible && onCollapse()}
            >
              <h5 style={{fontWeight: "bold", marginTop: 6, paddingLeft: 20,flex:0.4 }}>
                  {HeaderTitleComponent?
                      <div style={{display: 'flex', alignItems: 'center',whiteSpace: "nowrap" }}>
                          <img src={titleIcon} height={titleIconHeight} style={{marginRight:4}}/>
                          {HeaderTitleComponent ? HeaderTitleComponent(period, setCollapseClickable) : title}
                      </div>:
                      <div style={{display: 'flex', alignItems: 'center',whiteSpace: "nowrap" }}>                           
                        <img src={titleIcon} height={titleIconHeight} style={{marginRight:4}}/> {title}
                        </div>
                  }
              </h5>
              
              <div style={{ 
                  display: "flex", 
                  alignItems: "center",
                  flex:0.6,
                  flexDirection: "row",
                  justifyContent: "space-between"
                  }}>

                  <div>
                  {!showing && CenterComponent()}
                  </div>

                  <div style={{ 
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  }}>

                  {datePeriodEnabled && showDatePeriod(period)?
                      <div style={{fontSize: 12, marginRight: 20}}>
                      {datePeriod && totalCost && <div style={{
                          marginTop: 4,
                          display: 'flex',
                          alignItems: 'center',
                          border: "2px solid #338307",
                          borderRadius: 5,
                          fontSize: 13,
                      }}>
                            <span style={{paddingLeft: 6, paddingRight: 6}}>
                                <span>
                                    {
                                        moment(datePeriod({period})?.rawStart).isValid()?
                                            moment(datePeriod({period})?.rawStart).format(DATE_FORMAT):
                                            datePeriod({period})?.start
                                    }
                                </span>
                                -
                                <span>
                                    {
                                        moment(datePeriod({period})?.rawEnd).isValid()?
                                            moment(datePeriod({period})?.rawEnd).format(DATE_FORMAT):
                                            datePeriod({period})?.end
                                    }
                                </span>
                            </span>
                          <span className="total" style={{
                              margin: 0,
                              paddingLeft: 5,
                              paddingRight: 5,
                              borderRadius: 2,
                              backgroundColor: '#338307',
                              color: 'white'
                          }}>
                                <b>{totalCost({period})}</b>
                            </span>
                      </div>
                      }
                  </div>:
                      onlyTotal && totalCost({period}) && <TotalCostComponent totalCost={totalCost} period={period} />
                  }
                     <div style={{fontSize: 12, marginRight: 20}}>{ExtraHeaderComponent({period: period})}
                     </div>

                {showDateRange && <div onMouseOver={()=>setCollapseClickable(false)} onMouseOut={()=>setCollapseClickable(true)} style={{display: "flex", alignItems: "center", justifyContent: "center", marginRight: 10}}>
                                              {/* <span style={{
                                                    marginRight: 4,
                                                    fontSize: 12,
                                                    color: "#616161",
                                                    }}>
                                                  Last
                                              </span> */}
                  <DateRange height={22} padding={1} fontSize={12} onSelectionChanged={onDateChange}
                             defaultSelected={defaultPeriod || period} rangeValues={DateRangeValues} {...DateRangeProps}/>
                </div>}
                  {printable &&
                  <>
                      <ButtonGroup onMouseOver={()=>setCollapseClickable(false)} onMouseOut={()=>setCollapseClickable(true)} size={"small"} variant="outlined" aria-label="primary button group" ref={printPopperRef}>
                          <Button size='small' style={{outline: 'none'}} onClick={onPrint}><Print style={{color: '#838383'}} /></Button>
                          {saveAsCSV?<Button
                              size="small"
                              aria-controls={printDropOpened ? 'split-button-menu' : undefined}
                              aria-expanded={printDropOpened ? 'true' : undefined}
                              aria-label="select merge strategy"
                              aria-haspopup="menu"
                              // onClick={() => setPrintDropOpened(true)}
                              onClick={onSaveAsCSV}
                              style={{outline: 'none', padding: 0}}>
                              <img src={DownloadCSVIcon} height={20} style={{color: '#838383'}}/>
                          </Button>:null}
                      </ButtonGroup>
                      {/*<Popper*/}
                      {/*    open={printDropOpened}*/}
                      {/*    anchorEl={printPopperRef.current}*/}
                      {/*    role={undefined}*/}
                      {/*    transition*/}
                      {/*    disablePortal*/}
                      {/*>*/}
                      {/*    {({ TransitionProps, placement }) => (*/}
                      {/*        <Grow*/}
                      {/*            {...TransitionProps}*/}
                      {/*            style={{*/}
                      {/*                transformOrigin:*/}
                      {/*                    placement === 'bottom' ? 'center top' : 'center bottom',*/}
                      {/*            }}*/}
                      {/*        >*/}
                      {/*            <Paper>*/}
                      {/*                <ClickAwayListener onClickAway={(event)=> {*/}
                      {/*                    if (printPopperRef.current && printPopperRef.current.contains(event.target)) {*/}
                      {/*                        return;*/}
                      {/*                    }*/}
                      {/*                    setPrintDropOpened(false)*/}
                      {/*                }}>*/}
                      {/*                    <MenuList id="split-button-menu">*/}
                      {/*                        <MenuItem onClick={onSaveAsCSV}>*/}
                      {/*                            Save as CSV*/}
                      {/*                        </MenuItem>*/}
                      {/*                    </MenuList>*/}
                      {/*                </ClickAwayListener>*/}
                      {/*            </Paper>*/}
                      {/*        </Grow>*/}
                      {/*    )}*/}
                      {/*</Popper>*/}

                  </>
                  }
                  {collapsible && <IconButton onClick={onCollapse} style={{outline: "none"}}>
                      {showing ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                  </IconButton>}
                 
                
                
                  </div>
             
              </div>
           
            </div>

          </div>
          <div>
            <Collapse in={!collapsible || showing}>
              <div style={{ padding: 10, paddingTop:0  }}>
                  <b>{MiddleComponent({period: period})}</b>
                  {ChildComponent({period: period})}
                {/*<ChildComponent period={period} />*/}
              </div>
            </Collapse>
          </div>
        </Paper>
    );
};

function TotalCostComponent({totalCost, period}){
    return (
        <span className="total" style={{
            margin: 0,
            paddingLeft: 5,
            paddingRight: 5,
            borderRadius: 2,
            backgroundColor: '#338307',
            color: 'white'
        }}>
            <b>{totalCost({period})}</b>
        </span>
    )
}
