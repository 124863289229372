import dateUtil from "./DateUtil";
import moment from "moment";

let rawData = [];

export function thisMonthSpend(rawData = []) {
    if (rawData) {
        // ThisMonthSpend
        let dateRangeThisMonthSpend = dateUtil.startDateOfMonth();
        let billingThisMonthSpendArray = rawData.filter((dateObj) => {
            if (dateObj[1] && dateObj[1] >= moment(dateRangeThisMonthSpend).format("YYYYMMDD")) {
                return dateObj;
            }
        });
        let data = commonConversion(billingThisMonthSpendArray);

        return {
            data: data,
        };
    }
}

export function lastMonthSpend(rawData = []) {
    if (rawData) {
        // LastMonthSpend
        let lastMonthStartDate = dateUtil.lastMonthStartDate();
        let lastMonthEndDate = dateUtil.lastMonthEndDate();
        let billingLastMonthSpendArray = rawData.filter((dateObj) => {
            if (dateObj[1] >= moment(lastMonthStartDate).format("YYYYMMDD") && dateObj[1] <= moment(lastMonthEndDate).format("YYYYMMDD")) {
                return dateObj;
            }
        });
        let data = commonConversion(billingLastMonthSpendArray);

        return {
            data: data,
        };
    }
}

export function oneDSpend(rawData = []) {
    if (rawData) {
        // 1D
        let dateRange1D = dateUtil.currentDate();
        let billing1DArray = rawData.filter((dateObj) => {
            if (dateObj[1] && dateObj[1] >= moment(dateRange1D).format("YYYYMMDD")) {
                return dateObj;
            }
        });
        let data = commonConversion(billing1DArray);
        return {
            data: data,
        };
    }
}

export function oneWSpend(rawData = []) {
    if (rawData) {
        // 1W
        let dateRange1W = dateUtil.oneWeekDateRange();
        let billing1WArray = rawData.filter((dateObj) => {
            if (dateObj[1] && dateObj[1] >= moment(dateRange1W).format("YYYYMMDD")) {
                return dateObj;
            }
        });
        let data = commonConversion(billing1WArray);
        return {
            data: data,
        };
    }
}

export function oneMSpend(rawData = []) {
    if (rawData) {
        // 1M
        let dateRange1M = dateUtil.oneMonthDateRange();
        let billing1MArray = rawData.filter((dateObj) => {
            if (dateObj[1] && dateObj[1] >= moment(dateRange1M).format("YYYYMMDD")) {
                return dateObj;
            }
        });
        let data = commonConversion(billing1MArray);

        return {
            data: data,
        };
    }
}

export function threeMSpend(rawData = []) {
    if (rawData) {
        // 3M
        let dateRange3M = dateUtil.threeMonthDateRange();
        let billing3MArray = rawData.filter((dateObj) => {
            if (dateObj[1] && dateObj[1] >= moment(dateRange3M).format("YYYYMMDD")) {
                return dateObj;
            }
        });
        let data = commonConversion(billing3MArray);

        return {
            data: data,
        };
    }
}

export function sixMSpend(rawData = []) {
    if (rawData) {
        // 6M
        let dateRange6M = dateUtil.sixMonthDateRange();
        let billing6MArray = rawData.filter((dateObj) => {
            if (dateObj[1] && dateObj[1] >= moment(dateRange6M).format("YYYYMMDD")) {
                return dateObj;
            }
        });
        let data = commonConversion(billing6MArray);

        return {
            data: data,
        };
    }
}

export function oneYSpend(rawData = []) {
    if (rawData) {
        // 1Y
        let dateRange1Y = dateUtil.oneYearDateRange();
        let billing1YArray = rawData.filter((dateObj) => {
            if (dateObj[1] && dateObj[1] >= moment(dateRange1Y).format("YYYYMMDD")) {
                return dateObj;
            }
        });
        let data = commonConversion(billing1YArray);
        return {
            data: data,
        };
    }
}

export function commonConversion(billingData = []) {
    if (billingData) {
        let totalCost = 0;
        let resourceGroupDateRangeArray = [];
        let resourceGroup = [];
        if (billingData && billingData.length > 0) {
            for (let value of billingData) {
                resourceGroupDateRangeArray.push(value[1]);
                let reqCurrency = value[9];
                totalCost = String(Number(totalCost) + Number(value[0]));
                let resourceTypeValue = value[4];
                let location = value[6];
                let resourceGroupName = value[4];
                let date = value[1];
                let reservationId = value[2];

                let reservationName = value[3];
                let subscriptionName = value[7];
                let subscriptionId = value[4];
                let resourceName = value[4] && value[4].split("/").slice(8).join("/") ? value[4].split("/").slice(8).join("/") : "";

                if (value[4] && value[4].includes("/providers/microsoft.capacity/")) {
                    let splitReservation = value[4].split("/providers/").slice(1).join("/");
                    if (splitReservation) {
                        let resource = splitReservation.split("/").slice(0);
                        resourceName = resource[2];
                        resourceTypeValue = resource[3];
                        resourceGroupName = resource[0] + "/" + resource[1];
                    }
                }

                let resourceGroupIndex = resourceGroup.findIndex(
                    (objRes) =>
                        //   objRes.date == date &&
                        objRes.reservationId == reservationId && objRes.reservationName == reservationName
                );
                if (resourceGroupIndex != -1) {
                    resourceGroup[resourceGroupIndex].cost = String(Number(resourceGroup[resourceGroupIndex].cost) + Number(value[0]));
                    let resourceIndex = resourceGroup[resourceGroupIndex].resources.findIndex(
                        (objRes) => objRes.resourceName == resourceName && objRes.resourceType == resourceTypeValue && objRes.location == location && objRes.subscriptionName == subscriptionName
                    );
                    if (resourceIndex != -1) {
                        resourceGroup[resourceGroupIndex].resources[resourceIndex].cost = String(Number(resourceGroup[resourceGroupIndex].resources[resourceIndex].cost) + Number(value[0]));
                    } else {
                        resourceGroup[resourceGroupIndex].resources.push({
                            resourceName: resourceName,
                            resourceType: resourceTypeValue,
                            location: location,
                            subscriptionName: subscriptionName,
                            subscriptionId: subscriptionId,
                            cost: String(value[0]),
                        });
                    }
                } else {
                    resourceGroup.push({
                        date: date,
                        reservationName: reservationName,
                        reservationId: reservationId,
                        cost: String(value[0]),
                        resources: [
                            {
                                resourceName: resourceName,
                                resourceType: resourceTypeValue,
                                location: location,
                                subscriptionName: subscriptionName,
                                subscriptionId: subscriptionId,
                                cost: String(value[0]),
                            },
                        ],
                    });
                }
            }

            if (resourceGroup && resourceGroup.length > 0) {
                resourceGroup.sort((a, b) => parseFloat(Number(b.cost)) - parseFloat(Number(a.cost)));
                for (let resourcegroupseObj of resourceGroup) {
                    if (resourcegroupseObj && resourcegroupseObj.resourceGroupName) {
                        resourcegroupseObj.resources.sort((a, b) => parseFloat(Number(b.cost)) - parseFloat(Number(a.cost)));
                        for (let resourcesObj of resourcegroupseObj.resources) {
                            if (resourcesObj && resourcesObj.services.length > 0) {
                                resourcesObj.services.sort((a, b) => parseFloat(Number(b.cost)) - parseFloat(Number(a.cost)));
                                for (let meterObj of resourcesObj.services) {
                                    if (meterObj && meterObj.meter.length > 0) {
                                        meterObj.meter.sort((a, b) => parseFloat(Number(b.cost)) - parseFloat(Number(a.cost)));
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        // setReservationData(resourceGroup);
        return {
            startDate: String(Math.min(...resourceGroupDateRangeArray)),
            endDate: String(Math.max(...resourceGroupDateRangeArray)),
            totalCost: String(totalCost),
            resourceGroup: resourceGroup,
        };
    }
}
