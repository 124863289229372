import React from "react";
import Button from "react-bootstrap/Button";
import {Link, useNavigate} from "react-router-dom";
import RoutesList from "./RoutesList";
export default function(){
    const navigate = useNavigate();

  return (
      <div>
          <div style={{display: "flex", justifyContent: "space-between", padding: 80}}>
              <div>
                  <h1 style={{fontSize: '6em', fontWeight: 700, marginBottom: 20}}>404</h1>
                  <h2 style={{fontSize:'2em', marginBottom: 50}}>Were you looking for something?</h2>
                  <>
                      {/*<Link to={RoutesList.ROOT} >*/}
                          <Button onClick={()=>navigate(RoutesList.APP)}>Back to app</Button>
                      {/*</Link>*/}
                  </>
              </div>
              <div>
                  <img
                      src="https://uploads-ssl.webflow.com/624dc8f687c6692321cbf6e2/624dc8f687c669038acbf7d6_Group%20105.png"
                      loading="lazy" sizes="(max-width: 479px) 90vw, 400px"
                      srcSet="https://uploads-ssl.webflow.com/624dc8f687c6692321cbf6e2/624dc8f687c669038acbf7d6_Group%2520105-p-500.png 500w, https://uploads-ssl.webflow.com/624dc8f687c6692321cbf6e2/624dc8f687c669038acbf7d6_Group%20105.png 600w"
                      alt="" className="_404-robot" />
              </div>
          </div>
      </div>
  );
}
