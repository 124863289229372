import * as types from "../actions/types";

const userState = {
  data: null,
  error: null,
  loading: false,
  reservationSubData: null,
};

function azureReservationReducer(state = userState, { type, payload }) {
  switch (type) {
      case "SWITCH_TEAM_CLEAR": {
          return {
              ...state,
              data: null,
              error: null,
              loading: false,
              reservationSubData: null,
          };
      }
      case types.AZURE_RESERVATION_REQUEST: {
          return {
              ...state,
              loading: true,
          };
      }
      case types.AZURE_RESERVATION_SUCCESS: {
          return {
              ...state,
              data: payload.data,

              loading: false,
          };
      }
      case types.AZURE_RESERVATION_FAILURE: {
          return {
              ...state,
              loading: false,
              data: null,
              error: payload,
          };
      }

      //---------------Azure Reservation Subscription level-------------------------

      case types.AZURE_RESERVATION_SUB_REQUEST: {
          return {
              ...state,
              loading: true,
          };
      }
      case types.AZURE_RESERVATION_SUB_SUCCESS: {
          return {
              ...state,
              reservationSubData: payload.data,
              loading: false,
          };
      }
      case types.AZURE_RESERVATION_SUB_FAILURE: {
          return {
              ...state,
              loading: false,
              reservationSubData: null,
              error: payload,
          };
      }

      default:
          return state;
  }
}
export default azureReservationReducer;
