const RoutesList = {
  NOT_FOUND: "/*",
  ROOT: "/",
  LANDING: "/landing",
  APP: "/app",
  ALL: "/app/all",
  AZURE_HOME: "/app/azure",
  AWS_HOME: "/app/aws",
  GCP_HOME: "/app/gcp",
  BUDGETS: "/app/budgets",
  REPORTS: "/app/reports",
  ACCOUNTS: "/app/accounts",
  SETTINGS: "/app/settings",

  AWS_CONNECT: "/app/aws/accounts",
  GCP_CONNECT: "/app/gcp/connect",

  DASHBOARD: "/dashboard",
  GCP_LIST: "/dashboard/gcp/accountlist",

  // AWS_ACCOUNT: "/dashboard/aws/accounts",
  // AWS_CONNECT:   "/dashboard/aws/connect",
  // AWS_CONNECT: "/dashboard/aws/accounts#connect",

  AWS_LIST: "/dashboard/aws/list",
  // AZURE_HOME:    "/dashboard/azure",
  AZURE_CONNECT: "/dashboard/azure/add",
  AZURE_LIST: "/dashboard/azure/list",
  CHARTS: "/dashboard/charts",

  LINK_ACCOUNTS: "/dashboard/linkAccounts",
  TRIAL_EXPIRED: "/dashboard/disabled",
  // RECOMMENDATIONS: "/dashboard/recommendations"
};

export default RoutesList;
