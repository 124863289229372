import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./fonts/Poppins-Regular.otf";
import "./fonts/Poppins-SemiBold.otf";
import "./fonts/SFCompactDisplay-Regular.otf";
import "./fonts/SFProText-Regular.ttf";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./util/table2csv";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./MsalReactAuth";
import { MsalProvider } from "@azure/msal-react";

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(<React.StrictMode>
    <MsalProvider instance={msalInstance}>
        <App />
    </MsalProvider>
</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
