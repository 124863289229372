import React, { useState, useEffect } from "react";
import { Card, CircularProgress, Collapse, Grid, IconButton, LinearProgress, Paper } from "@mui/material";
import { Viewer } from "@react-pdf-viewer/core";
import Button from "@mui/material/Button";
import Invoice_Icon from "../../images/invoice/Service-Endpoint-Policy.svg";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSelector, useDispatch } from "react-redux";
import { getInvoicesRequest } from "../../redux/actions/actions";
import { Alert, AlertTitle } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import CachedIcon from "@mui/icons-material/Cached";
import "@react-pdf-viewer/core/lib/styles/index.css";
//import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "./style.css";
import axios from "../../connection/axios";
import InvoiceTable from "./InvoiceTable";
import colors from "styles/color";
import Format from "components/NumberFormat";
import { Receipt } from "@mui/icons-material";
import {makeStyles} from "MakeWithStyles";
const { REACT_APP_UPLOAD_INVOICE } = process.env;

const headCells = [
  {
    id: "provider",
    numeric: false,
    disablePadding: true,
    label: "Provider",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Invoice date",
  },
  {
    id: "invoiceId",
    numeric: true,
    disablePadding: false,
    label: "Invoice Id",
  },
  {
    id: "billingPeriod",
    numeric: true,
    disablePadding: false,
    label: "Billing period",
  },
  {
    id: "usageAmount",
    numeric: true,
    disablePadding: false,
    label: "Usage amount",
  },
  {
    id: "freeCredit",
    numeric: true,
    disablePadding: false,
    label: "Free credit",
  },
  {
    id: "totalAmount",
    numeric: true,
    disablePadding: false,
    label: "Total amount",
  },

    {
        id: "subscription",
        numeric: true,
        disablePadding: false,
        label: "Subscription",
    },
    {
        id: "download",
        numeric: true,
        disablePadding: false,
        label: "Download",
    },
];

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
    },
    root2: {
        maxWidth: 500,
        minHeight: 200,
        backgroundColor: "#e9ecef",
        "&:hover": {
            background: "#fff",
        },
    },
    root3: {
        root: {
            width: "100%",
            "& > * + *": {
                marginTop: theme.spacing(2),
            },
        },
    },
    paper: {
        padding: theme.spacing(2),
    },
    btnStyle: {
        background: "linear-gradient(45deg, #3f51b5 50%, #3f51b5 100%)",
        border: 0,
        borderRadius: 3,
        boxShadow: "0 3px 5px 2px rgba(63, 81, 181, .3)",
        color: "white",
        height: 40,
        padding: "0 40px",
        textTransform: "none",
    },
}));

export default function Invoices() {
    const {classes} = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [pdfFile, setPdfFile] = useState(null);
    const [pdfName, setPdfName] = useState("");
    const [pdfFileError, setPdfFileError] = useState("");
    const [viewPdf, setViewPdf] = useState(null);

    const [msg, setMsg] = useState("");
    const [msgStatus, setMsgStatus] = useState("");

    const customerId = useSelector((state) => (state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId));
    const role = useSelector((state) => state.userDetailsReducer.role);
    const Validinvoices = useSelector((state) => state.invoiceReducer.invoices);

    const loadingI = useSelector((state) => state.invoiceReducer.loadingI);

    function reloadInvoice() {
        if (customerId) {
            dispatch(getInvoicesRequest(customerId));
        }
    }

    useEffect(() => {
        if (Validinvoices == null) {
            dispatch(getInvoicesRequest(customerId));
        }
    }, [customerId]);

    const fileType = ["application/pdf"];
    const handlePdfFileChange = (e) => {
        let selectedFile = e.target.files[0];
        setPdfName(selectedFile.name);
        if (selectedFile) {
            if (selectedFile && fileType.includes(selectedFile.type)) {
                let reader = new FileReader();
                reader.readAsDataURL(selectedFile);
                reader.onloadend = (e) => {
                    setPdfFile(e.target.result);
                    setPdfFileError("");
                };
            } else {
                setPdfFile(null);
                setPdfFileError("Please select valid pdf file");
            }
        }
    };

    const handlePdfFileSubmit = (e) => {
        e.preventDefault();

        if (customerId && pdfFile !== null) {
            setLoading(true);
            axios
                .post(REACT_APP_UPLOAD_INVOICE, {
                    customerId: customerId,
                    pdfName: pdfName,
                    base64: pdfFile.split(",")[1],
                })
                .then((res) => {
                    const {
                        data: { statusCode, successMessage },
                    } = res;
                    if ("statusCode" in res.data) {
                        if (statusCode === 200) {
                            setOpen(true);
                            setMsgStatus("Success");
                            setMsg(successMessage);
                        } else {
                            setOpen(true);
                            setMsgStatus("Error");
                            setMsg(res.data?.errorMessage);
                        }
                    } else {
                        setMsg("");
                    }
                })
                .finally(() => setLoading(false))
                .catch((error) => {
                    console.error(error);
                });
            setViewPdf(pdfFile);
        } else {
            setViewPdf(null);
        }
    };
    return (
        <div style={{ backgroundColor: colors.background }}>
            {loadingI ? <LinearProgress /> : null}

            <div style={{ padding: 20 }}>
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex" }}>
                            <Receipt style={{ marginRight: 10 }} />
                            <h5 className="header">
                                Upload invoices <span style={{ fontSize: 16 }}>(preview)</span>
                            </h5>
                        </div>

                        <div className={classes.root3} style={{ width: 400 }}>
                            <Collapse in={open}>
                                <Alert
                                    severity={msgStatus === "Success" ? "success" : "error"}
                                    variant="filled"
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setOpen(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                >
                                    <AlertTitle>{msgStatus}</AlertTitle>
                                    {msg} —{" "}
                                    <strong onClick={reloadInvoice} style={{ cursor: "pointer" }}>
                                        Reload!
                                        <CachedIcon />
                                    </strong>
                                </Alert>
                            </Collapse>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                        className={'connection_buttons'}
                    >
                        <div style={{ padding: 40, width: "33%" }}>
                            <p
                                style={{
                                    textAlign: "justify",
                                    textJustify: "inter-word",
                                }}
                            >
                                We automatically process your invoices and extract the data using industry-leading <strong style={{}}>artificial intelligance(AI) platform</strong>. We use Azure and AWS services to process your data securely with best
                                in class security services.
                            </p>
                        </div>
                        <div style={{ alignSelf: "center", width: "33%" }}>
                            <form>
                                <Card className={classes.root2} elevation={3}>
                                    <div
                                        style={{
                                            border: "8px dashed rgba(0, 0, 0, .7)",
                                            height: 200,
                                        }}
                                    >
                                        <input
                                            type="file"
                                            onChange={handlePdfFileChange}
                                            style={{
                                                width: 390,
                                                height: 190,
                                                position: "absolute",
                                                opacity: 0,
                                                cursor: "pointer",
                                            }}
                                            disabled={role == "Owner" || role == null ? false : true}
                                        />
                                        <div
                                            style={{
                                                flexDirection: "column",
                                                display: "flex",
                                                marginTop: 30,
                                            }}
                                        >
                                            <span style={{ textAlign: "center" }}>
                                                <CloudUploadIcon
                                                    style={{
                                                        color: "rgba(73, 80, 87, .6)",
                                                        width: 70,
                                                        height: 70,
                                                    }}
                                                />
                                            </span>
                                            <span
                                                style={{
                                                    textAlign: "center",
                                                    color: "gray",
                                                    fontSize: 18,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                CLICK TO BROWSE OR DROP FILE HERE
                                            </span>
                                            <span
                                                style={{
                                                    textAlign: "center",
                                                    marginTop: 10,
                                                    color: "#4d4d4d",
                                                }}
                                            >
                                                {pdfName === "" ? (
                                                    <span> No file selected</span>
                                                ) : (
                                                    <span
                                                        style={{
                                                            color: pdfFileError == "" ? "green" : "red",
                                                        }}
                                                    >
                                                        {pdfName}
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </Card>
                            </form>
                        </div>
                        <div style={{ padding: 40, width: "33%" }}>
                            <p
                                style={{
                                    textAlign: "justify",
                                    textJustify: "inter-word",
                                }}
                            >
                                Upload your
                                <strong style={{}}> Azure, AWS, GCP, Atlassian, Office 365, Google </strong>
                                and other invoices. Your invoices summary and line items will be extracted in less than one hour.
                            </p>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            marginTop: 10,
                        }}
                        className={'connection_buttons'}
                    >
                        {pdfFileError && <span className="error-msg">{pdfFileError}</span>}
                        <Button style={{ width: 400 }} onClick={handlePdfFileSubmit} color="primary" variant="contained" startIcon={<CloudUploadIcon />} classname={classes.btnStyle} disabled={role == "Owner" || role == null ? false : true}>
                            {loading ? <CircularProgress size={20} color="secondary" /> : "upload"}
                        </Button>
                        <div style={{ marginTop: 10 }}>
                            <p
                                style={{
                                    textAlign: "justify",
                                    textJustify: "inter-word",
                                    fontSize: 12,
                                }}
                            >
                                <span>* </span>Currently we support only <strong>Azure </strong>
                                and
                                <strong> AWS</strong> pay-as-you-go invoices.
                            </p>
                        </div>
                    </div>

                    <div>{Validinvoices && Validinvoices.length > 0 ? <InvoiceTable data={Validinvoices} currency={Validinvoices[0].currency.toUpperCase() || "USD"} column={headCells} customerId={customerId} /> : null}</div>

                    {viewPdf && (
                        <>
                            <h4>View PDF</h4>
                            <div className="pdf-container">
                                {/* show pdf conditionally (if we have one)  */}
                                {viewPdf && (
                                    <Viewer
                                        fileUrl={viewPdf}
                                        // plugins={[defaultLayoutPluginInstance]}
                                    />
                                )}

                                {/* if we dont have pdf or viewPdf state is null */}
                                {!viewPdf && <>No pdf file selected</>}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
