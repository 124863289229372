import React, { useState, useEffect } from "react";
import "./style.css";
import Format from "components/NumberFormat";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import moment from "moment";
import {
    Backdrop,
    CircularProgress, Divider,
    Fade,
    Modal,
    Paper,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from "@mui/material";
import RecommendationsSummaryCards from "./RecommendationSummaryCards";
import aws_recommendation from "../../images/aws_recommendation.svg";
import Ec2Icon from "../../images/EC2.svg";

import AwsLogo from "../../images/aws_dark.svg";
import { makeStyles, withStyles } from "MakeWithStyles";
import TableSearch from "../../components/TableSearch";
import Box from "@mui/material/Box";
import TooltipMUI from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import {CancelRounded, RemoveCircleOutline} from "@mui/icons-material";
import Cost from "../../components/Cost";
import MatricObjectData from "./MatricObjectData";
import MatricLevelChart from "./MatricLevelChart";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "../../components/TablePaginationActions";
import color from "../../styles/color";
import Alert from "@mui/lab/Alert";

export default function AWSUnattachedEBS({ volumeLinkEnabled = false, title, data, customerId, tenantId, subscriptionId, lastUpdated, currency, cards, type, loading, collapseRef }) {
    const [list, setList] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [totalCost, setTotalCost] = useState(0.003);
    const [sum, setSum] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    function onVolumeClick(row){
        if(row){
            setModalData(row);
            setModalOpen(true);
        }
    }

    return (
        <div>
                <ContainerCardWithDateRange
                    someref={collapseRef}
                    title={title}
                    titleIcon={AwsLogo}
                    showDateRange={false}
                    collapsible={true}
                    HeaderTitleComponent={()=>(
                        <div>
                            <img src={Ec2Icon} height={25} style={{marginLeft: 8, marginRight: 8}} />
                            <span>
                            {title}
                        </span>
                        </div>
                    )}
                    CenterComponent={()=>(
                        <div>
                         {cards != null && cards ?<h6 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20}}>{cards?.[1]?.value} {cards?.[1]?.name} </h6>:null}
                      </div>
                        )}
                    ExtraHeaderComponent={({ period }) =>
                        data != null ? (
                            <div style={{ marginTop: 4 }}>
                                <span style={{fontSize: 14}}>Last updated {moment(lastUpdated).fromNow(true)} ago</span>
                            </div>
                        ) : null
                    }
                >
                    {({ period }) => (
                         <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                          {!loading ?
                        <div style={{flex:1}}>
                           {(data && Object.keys(data).length) ? (
                             <>
                            {cards && <RecommendationsSummaryCards summary={cards}/>}
                            <RecommendationsTable volumeLinkEnabled={volumeLinkEnabled} onVolumeClick={onVolumeClick}
                                                  type={type} subscriptionId={subscriptionId} list={data?.volumes}
                                                  currency={currency} customerId={customerId} dateCreated={data?.dateCreated} />
                            </>
                            ) : (
                                <h6 style={{ textAlign: "center" }}>No data found</h6>
                            )}
                            </div>
                   :
                   <CircularProgress thickness={2} size={60} />
                            }
                   </div>
              )}
                </ContainerCardWithDateRange>
            <VolumeModal currency={currency} data={modalData} open={modalOpen} onClose={()=>setModalOpen(false)} />
        </div>
    );
}

const useStyles = makeStyles()((theme) => ({
    root1: {
        flexGrow: 1,
        marginBottom: 10,
    },
    paper1: {
        height: 250,
        width: 262,
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#eeeeee",
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper3: {
        //width: "99%",
        // height: 680,
        //  backgroundColor: "#eee",
        //  boxShadow: theme.shadows[5],
        padding: theme.spacing(2),
    },
}));

const StyledTableCell = withStyles(TableCell,(theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
        padding: theme.spacing(1.2),
    },
    body: {
        fontSize: 14,
    }
}));

const StyledTableRow = withStyles(TableRow,(theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}));

function VolumeModal({open, onClose, data: data2, currency}){
    const classes = useStyles();
    const [data, setData] = useState(null);

    useEffect(()=>{
        let myd = [];
        if(data2){
            myd = data2?.map(d=>({id: d?.volumeId, size: d?.size + " " +d?.unit,
                days: parseInt(parseFloat(d.totalCost) / parseFloat(d.avgCostPerDay)), cost: Format.formatNumber(d.avgCostPerDay),LineItemDescription:d?.LineItemDescription}))
        }
        setData(myd);
    }, [data2]);

    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                className={classes.modal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div
                        className={classes.paper3}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Paper elevation={3}>
                            <TableContainer className={classes.container}>
                                <Table
                                    className={classes.table}
                                    stickyHeader
                                    aria-label="sticky table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell
                                                align="left"
                                                style={{ fontSize: 16, fontWeight: "bold" }}
                                            >
                                                Volume id

                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="center"
                                                style={{ fontSize: 16, fontWeight: "bold" }}
                                            >
                                                Description
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="center"
                                                style={{ fontSize: 16, fontWeight: "bold" }}
                                            >
                                                Size of the volume
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="left"
                                                style={{ fontSize: 16, fontWeight: "bold" }}
                                            >
                                                # Days stopped
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="left"
                                                style={{ fontSize: 16, fontWeight: "bold" }}
                                            >
                                                Cost
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {data?.map(r=>(
                                            <StyledTableRow>
                                                <StyledTableCell style={{ }} align="left">
                                                    {r?.id}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ }} align="left">
                                                    {r?.LineItemDescription}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ }} align="left">
                                                    {r?.size}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ }} align="left">
                                                    {r?.days}{r?.days > 1 ? " days" : " day"}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ }} align="left">
                                                    {Format.formatCurrencySymbol(currency) + r?.cost} {" /day"}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}



const AWS_TERMINATE_INSTANCE_HELP = "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/terminating-instances.html#new-console";
const useStylesRT = makeStyles()((theme) => ({
    backgroundStyle: {border: "1px solid #338307",margin:2,padding:2,fontSize:14
    }
}));

function RecommendationsTable(props) {
    const { dateCreated = null, list: list2, currency, customerId, subscriptionId, type, onVolumeClick = ()=>{}, volumeLinkEnabled = false } = props;
    const classes = useRowStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [showingDetails, setShowingDetails] = useState(null);
    const [search, setSearch] = useState(null);
    const [list, setList] = useState([]);

    const [selectionModel, setSelectionModel] = useState([]);
    const [approvedSavingsTotal, setApprovedSavingsTotal] = useState(0);

    const [approvedSavingsModal, setApprovedSavingsModal] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const showS3Details = (detail) => {
        setShowingDetails(null);
        if (detail) {
            setShowingDetails(detail);
        }
    };

    const matricbg = useStylesRT();

    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.volumeId,
                f?.volumeType,
            ], search);
        }
        return true;
    }

    useEffect(()=>{
        if(list2 && list2.length){
            const DAYS_PERIOD = 30;
            const list = list2.map(row=>{
                if(type=='stopped') {
                    row.totalSavingsMonths = row?.volumes?.map(r => r.avgCostPerDay || 0)?.reduce((a, b) => a + b, 0) * DAYS_PERIOD;
                    row.totalSavingsDaysString = (row?.numberOfDaysStopped)?(Format.formatNumber(Number(row?.volumes?.map(v => parseFloat(v.totalCost) || 0).reduce((a, b) => a + b, 0))) +  "/" +  row?.numberOfDaysStopped + ((parseInt(row?.numberOfDaysStopped) > 1 ? " days" : " day"))):null;
                } else {
                    row.totalSavingsMonths = (parseFloat(row.avgCost) + row?.volumes?.map(r=>parseFloat(r.avgCost)||0)?.reduce((a,b)=>a+b, 0)) * DAYS_PERIOD
                }
                return row;
            })
            setList(list);
        }
    }, [list2]);

    useEffect(()=>{
        if(!selectionModel.length) return
        const data = selectionModel.map(s=>list.find(g=>(g?.ec2Name || g?.instanceId)==s));
        const approvedSavingsTotal = data.map(d=>parseFloat(d.totalSavingsMonths)).reduce((a,b)=>a+b, 0);
        setApprovedSavingsTotal(approvedSavingsTotal);
    }, [selectionModel]);

    const onSelect = (id, all = false) => {
        if(all){
            if(selectionModel.length === list?.length) {
                setSelectionModel([]);
            } else {
                const selected = list.map(row => row?.instanceId || row?.ec2Name);
                setSelectionModel(selected);
            }
        } else {
            if(selectionModel.indexOf(id) != -1){
                setSelectionModel(selectionModel.filter(i=>i!==id));
            } else {
                const model = Object.assign([], selectionModel);
                model.push(id);
                setSelectionModel(model);
            }
        }
    }

    return (
        <div>
            <ApprovedSavings open={approvedSavingsModal} currency={currency} onClose={()=>setApprovedSavingsModal(false)}
                             data={selectionModel.map(s=>list.find(g=>(g?.ec2Name || g?.instanceId)==s))}
                             onCancel={()=>{
                                 setSelectionModel([]);
                                 setApprovedSavingsModal(false);
                             }}
                             type={type}
                             onRemove={(index, id)=>{
                                 const final = selectionModel.filter(s => s != id);
                                 if(final.length){
                                     setSelectionModel(final);
                                 } else {
                                     setSelectionModel([]);
                                     setApprovedSavingsModal(false);
                                 }
                             }}
                             total={approvedSavingsTotal}
                             currency={Format.formatCurrencySymbol(currency)}
            />
            <div className={classes.root}>
                {list != null && list?.length ? (
                    <div style={{padding: 0, margin: 0}}>
                        <Paper elevation={3}>
                            <Box display={"flex"} flex={1}>
                                <Box flex={showingDetails ? 0.3 : 1}>
                                    <div style={{display:'flex', alignItems:'center',justifyContent:'space-evenly'}}>
                                        <div style={{flex: 4,display:'flex', alignItems:'center'}}>
                                            <TableSearch onSearch={setSearch} filterWidth={false}
                                                         containerStyle={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', flex: 1}}
                                                         RightSideComponent={()=><Alert style={{marginLeft: 10, width: '70%'}} severity="warning">Action: Delete unattached volumes (take a snapshot if you need the data)</Alert>}/>

                                        </div>
                                        <Box flex={1} display={'flex'} justifyContent={'flex-end'} paddingRight={'10px'}>
                                            <TooltipMUI disableHoverListener={selectionModel.length>0}
                                                        disableFocusListener={selectionModel.length>0}
                                                        disableTouchListener={selectionModel.length>0}
                                                        title="Please select any recommendations">
                                                <span>
                                                    <Button onClick={()=>setApprovedSavingsModal(true)}
                                                            disabled={selectionModel.length==0}
                                                            variant="contained"
                                                            color="primary"
                                                            style={{textTransform: "none"}}
                                                        // style={{ backgroundColor: "#3f50b5", color: "#FFF", textTransform: "initial" }}>
                                                    >
                                                        {
                                                            selectionModel.length>0?
                                                                `Approve Savings (${Format.formatCurrencySymbol(currency) + Format.formatNumber(approvedSavingsTotal)})`:
                                                                "Approve Savings"
                                                        }
                                                    </Button>
                                                </span>
                                            </TooltipMUI>
                                        </Box>
                                    </div>
                                    <TableContainer className={classes.container}>
                                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {/*<StyledTableCell align="left">*/}
                                                    {/*    <Checkbox onClick={()=>onSelect(null, true)} color={"primary"} checked={selectionModel.length === list.length} />*/}
                                                    {/*</StyledTableCell>*/}
                                                    <StyledTableCell align="left">
                                                        Volume Id
                                                    </StyledTableCell>

                                                    {showingDetails ? null : (
                                                        <>

                                                            <StyledTableCell align="left">
                                                                Potential Savings / mon
                                                            </StyledTableCell>

                                                            <StyledTableCell align="left">
                                                                Type
                                                            </StyledTableCell>

                                                            <StyledTableCell align="left">
                                                                Size
                                                            </StyledTableCell>

                                                            <StyledTableCell align="left">
                                                                IOPS
                                                            </StyledTableCell>

                                                            <StyledTableCell align="left">
                                                                Throughput
                                                            </StyledTableCell>

                                                            <StyledTableCell align="left">
                                                                Created
                                                            </StyledTableCell>

                                                            {/*<StyledTableCell align="center">*/}

                                                            {/*    <div style={{*/}
                                                            {/*        display: 'flex',*/}
                                                            {/*        flexDirection: 'row',*/}
                                                            {/*        justifyContent: 'center',*/}
                                                            {/*        alignItems: "center"*/}
                                                            {/*    }}>*/}
                                                            {/*        Action{" "}*/}
                                                            {/*        <OverlayTrigger placement="left"*/}
                                                            {/*                        overlay={<Tooltip*/}
                                                            {/*                            id="button-tooltip">How to*/}
                                                            {/*                            terminate EC2*/}
                                                            {/*                            instance?</Tooltip>}>*/}
                                                            {/*            <Badge badgeContent={0} color="secondary">*/}
                                                            {/*                <Help*/}
                                                            {/*                    onClick={() => window.open(AWS_TERMINATE_INSTANCE_HELP, "_blank")}*/}
                                                            {/*                    style={{*/}
                                                            {/*                        marginLeft: 5,*/}
                                                            {/*                        cursor: "pointer",*/}
                                                            {/*                        fontSize: 15,*/}
                                                            {/*                        color: "#303"*/}
                                                            {/*                    }}/>*/}
                                                            {/*            </Badge>*/}
                                                            {/*        </OverlayTrigger>*/}
                                                            {/*    </div>*/}
                                                            {/*</StyledTableCell>*/}

                                                        </>
                                                    )}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(list || [])?.sort((a, b) => parseFloat(b?.storageCostPerDay) - parseFloat(a?.storageCostPerDay))?.filter(filteredTable)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, i) => (
                                                    <StyledTableRow hover className={classes.root} key={row.resourceId}
                                                        // style={{ cursor: "pointer" }}
                                                                    onClick={() => setShowingDetails(row.details)}>
                                                        {/*<TableCell align="left">*/}
                                                        {/*    <Checkbox onClick={()=>(row?.instanceId || row?.ec2Name)} color={"primary"} checked={[].indexOf(row?.instanceId || row?.ec2Name) !== -1} />*/}
                                                        {/*</TableCell>*/}
                                                        <TableCell align="left">
                                                            {row?.volumeId}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {currency}{Format.formatNumber(Number(row?.storageCostPerDay)*30)+ " /mon"}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {row?.volumeType}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {sizeInBytes("GiB", parseInt(row?.size)*1024*1024*1024)}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {row?.iops || "-"}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {sizeInBytes("MiB", parseInt(row?.throughput)*1024*1024)}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {moment(dateCreated, "YYYYMMDD").format("YYYY/MM/DD")}
                                                        </TableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                            <TablePagination ActionsComponent={TablePaginationActions}
                                             rowsPerPageOptions={[10, 25, 50, 75]} component="div"
                                             count={list?.filter(filteredTable)?.length} rowsPerPage={rowsPerPage}
                                             page={page} onChangePage={handleChangePage}
                                             onChangeRowsPerPage={handleChangeRowsPerPage}/>
                        </Paper>
                    </div>
                ) : (
                    <h6 style={{display: "flex", justifyContent: "center"}}>No data found</h6>
                )}
            </div>
        </div>
    );
}

function Metertable({ data, currency }) {
    return (
        <div>
            <Typography style={{ fontWeight: "bold" }}>Meter data</Typography>
            <div style={{ maxHeight: 300 }} class="overflow-auto">
                <table id="customers">
                    <tr>
                        <th>Operation</th>
                        <th>Product family</th>
                        <th>Usage type</th>
                        <th>Description</th>
                        <th>Cost</th>
                    </tr>

                    {data &&
                        data.meter.map((m) => (
                            <tr>
                                <td>{m.Operation}</td>
                                <td>{m.ProductFamily}</td>
                                <td>{m.LineItemUsageType}</td>
                                <td>{m.LineItemDescription}</td>
                                <td>{Format.formatCurrencySymbol(currency) + Format.formatNumber(Number(m["cost"]))}</td>
                            </tr>
                        ))}
                </table>
            </div>
        </div>
    );
}

// const StyledTableCell = withStyles((theme) => ({
//     head: {
//         backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
//         color: theme.palette.common.black,
//         whiteSpace:'nowrap',
//         fontSize:12
//     },
//     body: {
//         fontSize: 14,
//     },
//     root: {
//         padding: theme.spacing(1),
//
//     },
// }))(TableCell);

const useRowStyles = makeStyles()((theme) => ({
    root: {
        "& > *": {
            padding: theme.spacing(1.5),
        },
    },
    table: {
        // minWidth: 700,
    },
    container: {
        maxHeight: 450,
    },
    link: {
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

export function ApprovedSavings({open, onClose, data, currency, onApprove, onCancel, onRemove, total, type = null}){
    const classes = useStyles();

    function getTotalVolumeSize(e, output_unit){
        function sizeInBytes(input_unit, size, to = null, new_size){
            if(!((size?.toString() || false) || (new_size?.toString() || false))) return null;
            var newSize = size;
            if(to){
                size = 1;
            }
            switch(to || input_unit){
                case "KB":  newSize = size*1000;
                    break;
                case "MB":  newSize = size*1000*1000;
                    break;
                case "GB":  newSize = size*1000*1000*1000;
                    break;
                case "TB":  newSize = size*1000*1000*1000*1000;
                    break;
                case "PB":  newSize = size*1000*1000*1000*1000*1000;
                    break;
                case "KiB": newSize = size*1024;
                    break;
                case "MiB": newSize = size*1024*1024;
                    break;
                case "GiB": newSize = size*1024*1024*1024;
                    break;
                case "TiB": newSize = size*1024*1024*1024*1024;
                    break;
                case "PiB": newSize = size*1024*1024*1024*1024*1024;
                    break;
            };
            return to != null?new_size/newSize:newSize;
        }
        function bytesToSize(bytes, decimals = 2) {
            if (bytes) {
                if (bytes === 0) return "0 Bytes";

                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

                const i = Math.floor(Math.log(bytes) / Math.log(k));

                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
            } else {
                return "-";
            }
        }
        var newSize = e?.volumes?.map(v=>sizeInBytes(v?.unit, v?.size, null))
            ?.filter(f=>f!=null)
            ?.reduce((a,b)=>a+b, null);
        newSize=bytesToSize(newSize);
        return newSize || "--";
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                className={classes.modal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div
                        className={classes.paper3}
                        style={{
                            height: '100%',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Paper elevation={3} style={{width: '70%'}}>
                            <br/>
                            <div style={{display: "flex", alignItems: "center",justifyContent: "center"}}>
                                <div style={{ padding: 10 }}>
                                    <Paper style={{width: 200,  background: `linear-gradient(to right, ${color.tableHeader}, #eef2f3)`, padding: 10, textAlign: "center" }} elevation={3}>
                                        <h5 className="carouselValue">{data.length}</h5>
                                        <span style={{fontSize:14}}>Instance selected</span>
                                    </Paper>
                                </div>
                                <div style={{ padding: 10 }}>
                                    <Paper style={{width: 200, background: `linear-gradient(to right, ${color.tableHeader}, #eef2f3)`, padding: 10, textAlign: "center" }} elevation={3}>
                                        <h5 className="carouselValue">{currency + Format.formatNumber(total)}</h5>
                                        <span style={{fontSize:14}}>Approved Savings</span>
                                    </Paper>
                                </div>
                            </div>
                            <br/>
                            {type==='idle' ?
                                <div style={{display:'flex', marginLeft: 10}}>
                                    <b>Metrics:</b>

                                    <td style={{marginLeft:4}}>
                                        <span style={{border: "1px solid #338307",margin:2,padding:2,fontSize:14}}> {" Avg. CPU Utilization <5% " || null}</span>{" "}Last 30 days
                                    </td>
                                    <br/>
                                    <br/>
                                </div>
                                :null}
                            <TableContainer className={classes.container} style={{height: 300}}>
                                <Table
                                    className={classes.table}
                                    stickyHeader
                                    aria-label="sticky table"
                                    style={{height: 'max-content'}}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell/>
                                            <TableCell>
                                                EC2 instance id
                                            </TableCell>
                                            {type === 'stopped' && <TableCell>
                                                Total EBS vol. size
                                            </TableCell>}
                                            {type === 'rightsizing' && <TableCell>
                                                Curr. Instance Type
                                            </TableCell>}
                                            {type === 'rightsizing' && <TableCell>
                                                Recomm. Instance Type
                                            </TableCell>}
                                            {type === 'rightsizing' && <TableCell>
                                                Curr. CPU Util.
                                            </TableCell>}
                                            {type === 'rightsizing' && <TableCell>
                                                Projected CPU Util.
                                            </TableCell>}
                                            {type === 'stopped' && <TableCell>
                                                # of days stopped
                                            </TableCell>}
                                            <TableCell>
                                                Potential Savings
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((row,i)=>(
                                            <TableRow>
                                                <TableCell>
                                                    <RemoveCircleOutline onClick={()=>onRemove(i, row?.ec2Name || row?.instanceId)} color={'secondary'} style={{cursor: 'pointer'}} />
                                                </TableCell>
                                                <TableCell>
                                                    {row?.ec2Name || row?.instanceId || row?.instanceName}
                                                </TableCell>
                                                {type === 'stopped' && <TableCell>
                                                    {getTotalVolumeSize(row)}
                                                </TableCell>}
                                                {type === 'stopped' && <TableCell>
                                                    {
                                                        ((row?.numberOfDaysStopped?.toString()) +
                                                            (parseInt(row?.numberOfDaysStopped) > 1? " days":" day" ))
                                                    }
                                                </TableCell>}
                                                {type === 'rightsizing' && <TableCell>
                                                    {
                                                        row?.currentInstanceType
                                                    }
                                                </TableCell>}
                                                {type === 'rightsizing' && <TableCell>
                                                    {
                                                        row?.recommendedInstanceType
                                                    }
                                                </TableCell>}
                                                {type === 'rightsizing' && <TableCell>
                                                    {
                                                        Format.formatNumber(row.currentInstanceTypeCPUUtilInPercent) + " %"
                                                    }
                                                </TableCell>}
                                                {type === 'rightsizing' && <TableCell>
                                                    {
                                                        Format.formatNumber(row.projectedCPUUtilInPercent) + " %"
                                                    }
                                                </TableCell>}
                                                <TableCell>
                                                    {currency + Format.formatNumber(Number(row?.totalSavingsMonths || row?.potentialSavings).toFixed(2)) + " /mon"}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div style={{padding: 10, display: "flex", justifyContent: "flex-end"}}>
                                <Button onClick={onApprove} variant="contained" color="primary">
                                    Approve
                                </Button>

                                <Button onClick={onCancel} style={{marginLeft: 10}} variant="contained" color="secondary">
                                    Cancel
                                </Button>
                            </div>
                        </Paper>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}


/**
 *
 *             <div style={{ height: 0, width: '100%' }}>
 *             <DataGrid rows={
 *                 list
 *                     ?.sort((a,b)=>b.totalSavingsMonths - a.totalSavingsMonths)?.filter(filteredTable)
 *                     .map((r,i)=>expandRow22({
 *                     subscriptionId: subscriptionId,
 *                     row:r,
 *                     index:i,
 *                     currency:currency,
 *                     mode: 'data', type}))
 *             } pagination
 *                       // components={{Toolbar: GridToolbarExport}}
 *                       columns={[
 *                           {id: 'i',name:" ", width: 22,  renderCell: ()=><img src={Ec2Icon} height={20} />},
 *                           {id: 'id',name: "EC2 instance", },
 *                           {width: 150, id: 'totalSavings',name: "Potential Savings",
 *                               renderCell: (a,b,c)=>{
 *                                 let val = a.formattedValue.split('&');
 *                                 return (
 *                                     <span>
 *                                          {val[0]}
 *                                         <br/>
 *                                         <span style={{fontSize: 11, color: "#7f7f7f"}}>
 *                                         {val[1]}
 *                                         </span>
 *                                     </span>
 *                                 )
 *                               },
 *                               cellClassName: 'line-heighted-table-cell'
 *                           },
 *                           {width: 150, id: 'daysStopped',name: "# days stopped", hide: type === 'idle'},
 *                           {width: 180, id: 'ebsvol',name: "# EBS vol. attached",
 *                           renderCell: (r)=> {
 *                               return (
 *                                   <LinkText onClick={() => onVolumeClick(r.row?.volumes)}
 *                                             role={volumeLinkEnabled && (r.row?.volumes?.length > 1) ? 'Owner' : 'other'}>
 *                                       {
 *                                           (r.row?.volumes?.length?.toString()) +
 *                                           (r.row?.volumes?.length > 1 ? " volumes" : " volume")
 *                                           || "-"
 *                                       }
 *                                   </LinkText>
 *                               )
 *                           }
 *                           },
 *                           {width: 160, id: 'ebsSize',name: "Total EBS vol. size", hide: type === 'idle'},
 *                           {width: 150, id: 'ebsSavings',name: "EBS vol. Savings"},
 *                           {width: 150, id: 'ec2Savings',name: "EC2 Savings", hide: type === 'stopped'},
 *                           {width: 100, id: 'location',name: "Location"},
 *                           {width: 140, id: 'account',name: "Account"}
 *                       ].map(({id, name, width, ...rest})=>({
 *                           ...rest,
 *                           field: id,
 *                           headerName: name,
 *                           width: width || 200,
 *                           editable: false,
 *                           sortable: false,
 *                           filterable: false,
 *                           valueOptions: null,
 *                           hideSortIcons: true,
 *                           hidePreferences: true,
 *                           // headerClassName: 'data-grid-header'
 *                       }))}
 *                       disableColumnMenu={true}
 *                       pageSize={10}
 *                       checkboxSelection
 *                       disableSelectionOnClick
 *                       onSelectionModelChange={(newSelectionModel,y,u,i,h) => {
 *                           setSelectionModel(newSelectionModel);
 *                       }}
 *                       selectionModel={selectionModel}
 *             />
 *             </div>
 */

function sizeInBytes(input_unit, size, to = null, new_size){

    // function bytesToSize(bytes, decimals = 2) {
    const bytes = size;
    const decimals = 2;

        if (bytes) {
            if (bytes === 0) return "0 Bytes";

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
        } else {
            return "-";
        }
    // }


    if(!((size?.toString() || false) || (new_size?.toString() || false))) return null;
    var newSize = size;
    if(to){
        size = 1;
    }
    switch(to || input_unit){
        case "KiB": newSize = size*1024;
            break;
        case "MiB": newSize = size*1024*1024;
            break;
        case "GiB": newSize = size*1024*1024*1024;
            break;
        case "TiB": newSize = size*1024*1024*1024*1024;
            break;
        case "PiB": newSize = size*1024*1024*1024*1024*1024;
            break;
    };
    return to != null?new_size/newSize:newSize;
}
