import * as types from "../actions/types";

const userState = {
    cloudProvider: null,
    year: null,
    month: null,
    dateCreated: null,
    recommendations: null,
    azureAllRecommendation: null,
    resources: null,
    tenantResources:null,
    subresources: null,
    resourceGroups: null,
    tenantResourceGroups:null,
    subresourceGroups: null,
    category: null,
    // details: null,
    loading: false,
    error: null,
    subStoppedVMRecommendationsData : null,
    subStoppedVMRecommendationsLoading : false,

    /** Latest **/

    stoppedVMRecommendationsData: null,
    stoppedVMRecommendationsLoading: false,

    cosmosDBRecommendationsData: null,
    cosmosDBRecommendationsLoading: false,

    dbServerRecommendationsData: null,
    dbServerRecommendationsLoading: false,

    functionAppRecommendationsData: null,
    functionAppRecommendationsLoading: false,

    idleVMRecommendationsData: null,
    idleVMRecommendationsLoading: false,

    sqlManagedInstanceData: null,
    sqlManagedInstanceLoading: false,

    stoppedVMAllRecommendationsData: null,
    stoppedVMAllRecommendationsLoading: false,

    storageRecommendationsData: null,
    storageRecommendationsLoading: false,

    vmMigrateRecommendationsData: null,
    vmMigrateRecommendationsLoading: false,

    vmRightsizingRecommendationsData: null,
    vmRightsizingRecommendationsLoading: false,

};

function azureRecommendationReducer(state = userState, { type, payload }) {
    switch (type) {
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                cloudProvider: null,
                year: null,
                month: null,
                dateCreated: null,
                recommendations: null,
                azureAllRecommendation: null,
                resources: null,
                subresources: null,
                resourceGroups: null,
                subresourceGroups: null,
                category: null,
                // details: null,
                loading: false,
                error: null,
            };
        }
        case types.AZURE_RECOMMENDATION_REQUEST: {
            return {
                ...state,
                // recommendations: null,
                azureAllRecommendation: null,
                loading: true,
            };
        }
        case types.AZURE_RECOMMENDATION_SUCCESS: {
            const {
                cloudProvider,
                year,
                month,
                dateCreated,
                recommendations: { value },
            } = payload.data;
            return {
                ...state,
                cloudProvider: cloudProvider,
                year: year,
                month: month,
                dateCreated: dateCreated,
                recommendations: value,
                loading: false,
            };
        }
        case types.AZURE_RECOMMENDATION_FAIL: {
            return {
                ...state,
                loading: false,
                recommendations: null,
                error: payload,
            };
        }
        case types.AZURE_HOME_RECOMMENDATION_REQUEST: {
            return {
                ...state,
                recommendations: null,
                // azureAllRecommendation: null,
                loading: true,
            };
        }
        case types.AZURE_HOME_RECOMMENDATION_SUCCESS: {
            const {
                dateCreated,
                recommandations: { summary, category, value },
            } = payload.data;
            return {
                ...state,
                azureAllRecommendation: summary,
                dateCreated: dateCreated,
                category: category,
                recommendations: value,
                loading: false,
            };
        }
        case types.AZURE_HOME_RECOMMENDATION_FAILED: {
            return {
                ...state,
                error: payload,
                azureAllRecommendation: null,
                loading: false,
            };
        }

        case types.AZURE_RESOURCES_REQUEST: {
            return {
                ...state,
                resources: null,
                // resourceGroups: null,
                subresources: null,
                loading: true,
            };
        }
        case types.AZURE_RESOURCES_SUCCESS: {
            const {
                data: { ThisMonthSpend, LastMonthSpend, SpendByResources },
            } = payload.data;
            if ("subscriptionId" in payload.data) {
                return {
                    ...state,
                    subresources: {
                        ThisMonthSpend: ThisMonthSpend,
                        lastMonthSpend: LastMonthSpend,
                        SpendByResources: SpendByResources,
                    },
                    loading: false,
                };
            } else {
                return {
                    ...state,
                    resources: {
                        ThisMonthSpend: ThisMonthSpend,
                        lastMonthSpend: LastMonthSpend,
                        SpendByResources: SpendByResources,
                    },
                    loading: false,
                };
            }
        }
        case types.AZURE_RESOURCES_FAIL: {
            return {
                ...state,
                error: payload,
                resources: null,
                loading: false,
            };
        }
        //----------------- Azure Tenant Resources ----------------------------------------------------
        case types.AZURE_TENANT_RESOURCES_REQUEST: {
            return {
                ...state,
                tenantResources:null,
                loading: true,
            };
        }
        case types.AZURE_TENANT_RESOURCES_SUCCESS: {
            const {
                data: { ThisMonthSpend, LastMonthSpend, SpendByResources },
            } = payload.data;
         
                return {
                    ...state,
                    tenantResources: {
                        ThisMonthSpend: ThisMonthSpend,
                        lastMonthSpend: LastMonthSpend,
                        SpendByResources: SpendByResources,
                    },
                    loading: false,
                };
            
        }
        case types.AZURE_TENANT_RESOURCES_FAIL: {
            return {
                ...state,
                error: payload,
                tenantResources: null,
                loading: false,
            };
        }
        //------------------------------Azure Resource Groups------------------------------------------------------
        case types.AZURE_RESOURCE_GROUPS_REQUEST: {
            return {
                ...state,
                resourceGroups: null,
                // resources: null,
                subresourceGroups: null,
                loading: true,
            };
        }
        case types.AZURE_RESOURCE_GROUPS_SUCCESS: {
            const {
                data: { ThisMonthSpend, LastMonthSpend, SpendByResourceGroup },
            } = payload.data;
            if ("subscriptionId" in payload.data) {
                return {
                    ...state,
                    subresourceGroups: {
                        ThisMonthSpend: ThisMonthSpend,
                        lastMonthSpend: LastMonthSpend,
                        SpendByResourceGroup: SpendByResourceGroup,
                    },
                    loading: false,
                };
            } else {
                return {
                    ...state,
                    resourceGroups: {
                        ThisMonthSpend: ThisMonthSpend,
                        lastMonthSpend: LastMonthSpend,
                        SpendByResourceGroup: SpendByResourceGroup,
                    },
                    loading: false,
                };
            }
        }
        case types.AZURE_RESOURCE_GROUPS_FAIL: {
            return {
                ...state,
                error: payload,
                resourceGroups: null,
                loading: false,
            };
        }
        //--------------------- Azure Tenant Resource groups----------------------------------------
        case types.AZURE_TENANT_RESOURCE_GROUPS_REQUEST: {
            return {
                ...state,
                tenantResourceGroups:null,
                loading: true,
            };
        }
        case types.AZURE_TENANT_RESOURCE_GROUPS_SUCCESS: {
            const {
                data: { ThisMonthSpend, LastMonthSpend, SpendByResourceGroup },
            } = payload.data;
         
                return {
                    ...state,
                    tenantResourceGroups: {
                        ThisMonthSpend: ThisMonthSpend,
                        lastMonthSpend: LastMonthSpend,
                        SpendByResourceGroup: SpendByResourceGroup,
                    },
                    loading: false,
                };
            
        }
        case types.AZURE_TENANT_RESOURCE_GROUPS_FAIL: {
            return {
                ...state,
                error: payload,
                tenantResourceGroups: null,
                loading: false,
            };
        }
        //------------------------------------------------------------------------------------------

        case types.AZURE_SUB_STOPPED_VM_RECOMMENDATION_REQUEST: {
            return {
                ...state,
                subStoppedVMRecommendationsData: null,
                subStoppedVMRecommendationsLoading: true,
            };
        }
        case types.AZURE_SUB_STOPPED_VM_RECOMMENDATION_SUCCESS: {
            return {
                ...state,
                subStoppedVMRecommendationsData: payload?.data,
                subStoppedVMRecommendationsLoading: false,
            };
        }
        case types.AZURE_SUB_STOPPED_VM_RECOMMENDATION_FAILED: {
            return {
                ...state,
                subStoppedVMRecommendationsData: null,
                subStoppedVMRecommendationsLoading: false,
            };
        }

        /** Latest **/
        case types.AZURE_COSMOSDB_RECOMMENDATION_SUCCESS: {
            return {
                ...state,
                cosmosDBRecommendationsData: null,
                cosmosDBRecommendationsLoading: false,
            };
        }


        case types.AZURE_COSMOSDB_RECOMMENDATION_FAIL: {
            return {
                ...state,
                cosmosDBRecommendationsData: null,
                cosmosDBRecommendationsLoading: false,
            };
        }


        case types.AZURE_DBSERVER_RECOMMENDATION_SUCCESS: {
            return {
                ...state,
                cosmosDBRecommendationsData: null,
                cosmosDBRecommendationsLoading: false,
            };
        }


        case types.AZURE_DBSERVER_RECOMMENDATION_FAIL: {
            return {
                ...state,
                cosmosDBRecommendationsData: null,
                cosmosDBRecommendationsLoading: false,
            };
        }


        case types.AZURE_FUNCTION_APP_RECOMMENDATION_SUCCESS: {
            return {
                ...state,
                cosmosDBRecommendationsData: null,
                cosmosDBRecommendationsLoading: false,
            };
        }


        case types.AZURE_FUNCTION_APP_RECOMMENDATION_FAIL: {
            return {
                ...state,
                cosmosDBRecommendationsData: null,
                cosmosDBRecommendationsLoading: false,
            };
        }


        case types.AZURE_IDLE_VM_RECOMMENDATION_REQUEST: {
            return {
                ...state,
                idleVMRecommendationsData: null,
                idleVMRecommendationsLoading: true,
            };
        }


        case types.AZURE_IDLE_VM_RECOMMENDATION_SUCCESS: {
            return {
                ...state,
                idleVMRecommendationsData: (payload?.data?.data)?payload?.data: null,
                idleVMRecommendationsLoading: false,
            };
        }


        case types.AZURE_IDLE_VM_RECOMMENDATION_FAIL: {
            return {
                ...state,
                idleVMRecommendationsData: null,
                idleVMRecommendationsLoading: false,
            };
        }


        case types.AZURE_SQL_MANAGED_INSTANCE_RECOMMENDATION_SUCCESS: {
            return {
                ...state,
                cosmosDBRecommendationsData: null,
                cosmosDBRecommendationsLoading: false,
            };
        }


        case types.AZURE_SQL_MANAGED_INSTANCE_RECOMMENDATION_FAIL: {
            return {
                ...state,
                cosmosDBRecommendationsData: null,
                cosmosDBRecommendationsLoading: false,
            };
        }


        case types.AZURE_STOPPED_VM_RECOMMENDATION_ALL_REQUEST: {
            return {
                ...state,
                stoppedVMRecommendationsData: payload?.data,
                stoppedVMRecommendationsLoading: true,
            };
        }


        case types.AZURE_STOPPED_VM_RECOMMENDATION_ALL_SUCCESS: {
            return {
                ...state,
                stoppedVMRecommendationsData: payload?.data,
                stoppedVMRecommendationsLoading: false,
            };
        }


        case types.AZURE_STOPPED_VM_RECOMMENDATION_ALL_FAIL: {
            return {
                ...state,
                stoppedVMRecommendationsData: null,
                stoppedVMRecommendationsLoading: false,
            };
        }


        case types.AZURE_STORAGE_RECOMMENDATION_SUCCESS: {
            return {
                ...state,
                cosmosDBRecommendationsData: null,
                cosmosDBRecommendationsLoading: false,
            };
        }


        case types.AZURE_STORAGE_RECOMMENDATION_FAIL: {
            return {
                ...state,
                cosmosDBRecommendationsData: null,
                cosmosDBRecommendationsLoading: false,
            };
        }


        case types.AZURE_VM_MIGRATE_RECOMMENDATION_SUCCESS: {
            return {
                ...state,
                cosmosDBRecommendationsData: null,
                cosmosDBRecommendationsLoading: false,
            };
        }


        case types.AZURE_VM_MIGRATE_RECOMMENDATION_FAIL: {
            return {
                ...state,
                cosmosDBRecommendationsData: null,
                cosmosDBRecommendationsLoading: false,
            };
        }


        case types.AZURE_VM_RIGHTSIZING_RECOMMENDATION_REQUEST: {
            return {
                ...state,
                vmRightsizingRecommendationsData: null,
                vmRightsizingRecommendationsLoading: true,
            };
        }


        case types.AZURE_VM_RIGHTSIZING_RECOMMENDATION_SUCCESS: {
            return {
                ...state,
                vmRightsizingRecommendationsData: payload?.data,
                vmRightsizingRecommendationsLoading: false,
            };
        }


        case types.AZURE_VM_RIGHTSIZING_RECOMMENDATION_FAIL: {
            return {
                ...state,
                vmRightsizingRecommendationsData: null,
                vmRightsizingRecommendationsLoading: false,
            };
        }

        default:
            return state;
    }
}
export default azureRecommendationReducer;
