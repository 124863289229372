import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getAzureManagementSuccess, getAzureManagementFailure, getAzureTenantManagementSuccess, getAzureTenantManagementFailure } from "../actions/actions";
const { REACT_APP_AZURE_MANAGEMENT_GROUP, REACT_APP_AZURE_TENANT_MGT_GROUP } = process.env;

function* getAzureManagementData({ payload }) {
    if (typeof payload === "object") {
        try {
            const res = yield call(axios.get, REACT_APP_AZURE_TENANT_MGT_GROUP, {
                params: {
                    customerId: payload.customerId,
                    tenantId: payload.tenantId,
                },
            });

            const { statusCode } = res.data;

            if ("statusCode" in res.data) {
                if (statusCode !== 200) {
                    yield put(getAzureManagementFailure(statusCode));
                } else {
                    yield put(getAzureManagementSuccess(res.data));
                }
            } else {
                yield put(getAzureManagementFailure(res));
            }
        } catch (error) {
            yield put(getAzureManagementFailure(error));
        }
    } else {
        try {
            const res = yield call(axios.get, REACT_APP_AZURE_MANAGEMENT_GROUP, {
                params: {
                    customerId: payload,
                },
            });

            const { statusCode } = res.data;

            if ("statusCode" in res.data) {
                if (statusCode !== 200) {
                    yield put(getAzureManagementFailure(statusCode));
                } else {
                    yield put(getAzureManagementSuccess(res.data));
                }
            } else {
                yield put(getAzureManagementFailure(res));
            }
        } catch (error) {
            yield put(getAzureManagementFailure(error));
        }
    }
}

export default function* azureManagementGroupSaga() {
    yield takeLatest(types.GET_AZURE_MANAGEMENT_REQUEST, getAzureManagementData);
    //yield takeLatest(types.GET_AZURE_TENANT_MANAGEMENT_REQUEST, getAzureTenantManagementData);
}
