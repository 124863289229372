import React from 'react';
import CostAnalysisIcon from "../../images/cost_analysis.svg";
import Format from "../../components/NumberFormat";
import {Button, ButtonGroup, Collapse, IconButton, Paper, Typography} from "@mui/material";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import LineProgress from "../../components/LineProgress";
import {CustomEnhancedTable} from "../../components/tab/AzureTenantTab";

function AzureActualAmortized({amortizedDatePeriod, currency, totalAmortizeCost, actualCostData, amortizedCostData,
                                  setShowDataFor, showDataFor, amortizedTableView, actualAmortizedRawData,
                                  loadingAmortized, setAmortizedTableView}) {
    return (
        <div>
            <Paper elevation={10} style={{ marginTop: 15 }}>
                <div>
                    <div
                        className={"accordian_item1"}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <h5 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20 }}>
                            <img src={CostAnalysisIcon} height={25} /> Actual Cost and Amortized Cost
                        </h5>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ marginRight: 4 }}>
                                <span style={{ marginRight: 10 }}>{amortizedDatePeriod}</span>
                                <b>
                                    {Format.formatCurrencySymbol(currency || "USD")}
                                    {Format.formatNumber(Number(totalAmortizeCost).toFixed(2))}
                                </b>
                            </div>
                            <div>
                                <Paper elevation={0}>
                                    <ButtonGroup style={{ outline: "none" }}>
                                        <Button
                                            onClick={() => setShowDataFor("actual")}
                                            style={{
                                                outline: "none",
                                                color: showDataFor !== "actual" ? "rgba(63, 81, 181, 0.86)" : "white",
                                                backgroundColor: showDataFor === "actual" ? "rgba(63, 81, 181, 0.86)" : "white",
                                            }}
                                        >
                                            <Typography>Actual</Typography>
                                        </Button>
                                        <Button
                                            onClick={() => setShowDataFor("amortized")}
                                            style={{
                                                outline: "none",
                                                color: showDataFor !== "amortized" ? "rgba(63, 81, 181, 0.86)" : "white",
                                                backgroundColor: showDataFor === "amortized" ? "rgba(63, 81, 181, 0.86)" : "white",
                                            }}
                                        >
                                            <Typography>Amortized</Typography>
                                        </Button>
                                    </ButtonGroup>
                                </Paper>
                            </div>
                            {/* {totalSpendChartView &&
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <span style={{
                                                    marginRight: 4, fontSize: 12, color: '#616161'
                                                }}>
                                                    Last
                                                </span>
                                            <DateRange height={22} padding={1} fontSize={12}
                                                       onSelectionChanged={setPeriod}
                                                       defaultSelected={periodSpendByTotal}
                                            />
                                        </div>  } */}
                            <IconButton onClick={() => setAmortizedTableView(!amortizedTableView)} style={{ outline: "none" }}>
                                {amortizedTableView ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                        </div>
                    </div>
                </div>
                <Collapse in={amortizedTableView}>
                    <div style={{ padding: 10 }}>
                        {loadingAmortized ? (
                            <LineProgress />
                        ) : actualAmortizedRawData && (actualCostData?.length || amortizedCostData?.length) ? (
                            <CustomEnhancedTable
                                currency={Format.formatCurrencySymbol(currency)}
                                rows={showDataFor == "actual" ? actualCostData : amortizedCostData}
                                headCells={["Name", "Resource type", "Resource group", "Location", "Cost"]}
                            />
                        ) : (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <span style={{}}>No data found </span>
                            </div>
                        )}
                    </div>
                </Collapse>
            </Paper>
        </div>
    );
}

export default AzureActualAmortized;
