import React, { useEffect, useRef, useState } from "react";
import { makeStyles, withStyles } from "MakeWithStyles";
import { lighten } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AzureResources from "pages/DashboardAccountsHome/AzureResources";
import AzureServices from "pages/DashboardAccountsHome/AzureServices";
import AzureRecommendation from "pages/recommendations/AzureRecommendation";
import AzureServerlessHome from "components/Serverless/AzureServerlessHome";
import AzureReservationsSubscription from "pages/DashboardAccountsHome/AzureReservationSubscription";
import active_directory from "../../images/active_directory.svg";
import serverless_icon from "../../images/newCloudTrakrIcons/AzureServerless.png";
import resource_group from "../../images/resource_group.svg";
import subscritpion_icon from "../../images/key.svg";
import recommendation_icon from "../../images/newCloudTrakrIcons/AzureRecommendations.png";
import calendar_icon from "../../images/calendar_icon.png";
import Overview from "../../images/overview.svg";
import azure_reservations from "../../images/newCloudTrakrIcons/AzureReservations.png";
import ComputeIcon from "../../images/newCloudTrakrIcons/AzureCompute.png";
import Azure_cosmosDB_icon from "../../images/newCloudTrakrIcons/AzureCosmosDB.png";

import storage_account_icon from "../../images/newCloudTrakrIcons/AzureStorage.png";
import Function from "../../images/resources/Function_App.svg";
import CosmosDB from "../../images/resources/Azure_Cosmos_DB.svg";
import AppServices from "../../images/resources/Application_Service.svg";
import AppCenter from "../../images/resources/Platform_Service.svg";
import Adb2c from "../../images/resources/Azure_Active_Directory_B2C.svg";
import LogAnalytics from "../../images/resources/Application_Insights.svg";
import ApplicationGateway from "../../images/resources/Application_Gateway.svg";
import AzureAppCervices from "../../images/resources/API_Management_Service.svg";
import ContainerRegistry from "../../images/resources/Container_Registry.svg";
import PublicIpAddress from "../../images/resources/Public_IPAddress.svg";
import IotHub from "../../images/resources/IoT_Hub.svg";
import KeyValut from "../../images/resources/Key_Vault.svg";
import Disk from "../../images/resources/Managed_Disk.svg";
import Redis from "../../images/resources/Azure_Cache_for_Redis.svg";
import Profile from "../../images/resources/CDN_Profile.svg";
import NameSpace from "../../images/resources/namespaces.svg";
import LoadBalance from "../../images/resources/Load_Balancer.svg";
import VirtualMachine from "../../images/resources/Virtual_Machine.svg";
import WorkSpaces from "../../images/resources/Workspace.svg";
import WorkFlow from "../../images/resources/workflows.svg";
import ServerFarms from "../../images/resources/serverfarms.svg";
import VirtualNetwork from "../../images/resources/Virtual_Network.svg";
import VirtualMachineScalable from "../../images/resources/Virtual_Machine_Scale_Set.svg";
import ResourceGroup from "../../images/resources/Resource_Group.svg";
import LogicApps from "../../images/resources/LogicApps.svg";
import NotificationHubs from "../../images/resources/NotificationHubs.svg";
import billing_icon from "../../images/newCloudTrakrIcons/AzureBilling.png";

import colors from "styles/color";

import {
    LinearProgress,
    Link,
    Paper,
    Grid,
    TextField,
    Divider,
    IconButton,
    Collapse,
    ButtonGroup,
    Button,
    Typography,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    Toolbar,
    TableSortLabel,
    TableContainer,
    TableBody,
    FormControlLabel,
    Switch,
    Table,
    TablePagination,
    CircularProgress,
} from "@mui/material";
import AzureSubscriptionsList from "components/Azure/AzureSubscriptionsList";
import AzureResourceGroupList from "components/Azure/AzureResourceGroupList";
import Format from "components/NumberFormat";
import YearTableData from "../YearTableData";
import axios from "../../connection/axios";
import Azure_key from "../../images/key.svg";
//import YearCalendarData from "components/YearCalendarData";
import { useSelector, useDispatch } from "react-redux";

import YearCalendarData, { CalendarDataProcessor, Months } from "../YearCalendarData";
import { ArrowRight, CalendarToday, KeyboardArrowDown, KeyboardArrowUp, Menu, PauseCircleFilled, PlayCircleFilled, Print, ThumbUp } from "@mui/icons-material";
import moment from "moment";
import { OverlayComponent } from "./AzureHomeTab";
import ReactD3PieChart from "components/D3Charts/ReactD3GridPieChart";
import ReactD3BarChart from "components/D3Charts/ReactD3GridChart";
import SlideShow from "components/CustomSlider/SlideShow";
import AzureStorage from "../../pages/DashboardAccountsHome/AzureStorage";
import DashboardIcon from "../../images/newCloudTrakrIcons/MultiCloudIcon.png";
import AzureTagsIcon from "../../images/newCloudTrakrIcons/AzureTags.png";
import Calendar_Spend_Icon from "../../images/calendar_months.svg";
import AzureCompute from "pages/DashboardAccountsHome/AzureCompute";
import { getAzureCCKSubRequest } from "../../redux/actions/actions";
import { generateGroupedChartData } from "../../pages/Charts/DashboardTabs";
import Total_Spend_Icon from "../../images/sigma_icon.svg";
import DateRange from "../DateRange";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GroupedBarChart from "../D3Charts/GroupedBarChart";
import StackedBarChart2 from "../../pages/Charts/StackedBarChart2";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import function_apps_icon from "../../images/newCloudTrakrIcons/AzureFunction.png";
import azure_service from "../../images/newCloudTrakrIcons/AzureServices.png";
import AzureFunctions from "../../pages/DashboardAccountsHome/AzureFunctions";
import AzureCosmosDB from "pages/DashboardAccountsHome/AzureCosmosDB";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import Color from "../../styles/color";
import LineProgress from "../../components/LineProgress";
import CostAnalysisIcon from "../../images/cost_analysis.svg";
import { SET_CLOUD_ACTIVE_TAB } from "../../redux/actions/types";
import NO_DATA_AVAILABLE from "../NO_DATA_AVAILABLE";
import TablePaginationActions from "../TablePaginationActions";
import { TIMERS } from "../../util/AppConstants";
import ResourceGroups from "pages/Resource&Resource_groups/ResourceGroups";
import Resources from "pages/Resource&Resource_groups/Resources";
import ResourceGroupsIcon from "../../images/newCloudTrakrIcons/AzureResourceGroups.png";
import AzureResourcesIcon from "../../images/newCloudTrakrIcons/AzureResources.png";
import { useReactToPrint } from "react-to-print";
import AzureManagementGroup from "../../pages/DashboardAccountsHome/AzureManagementGroup";
import AzureTags from "../../pages/DashboardAccountsHome/AzureTags";
import CalendarLoader from "../loaders/CalendarLoader";
import { getMonthsFromNow } from "../../util/DateFormatter";
import { isNumeric } from "../../util/Miscellaneous";
import ContainerCardWithDateRange from "../ContainerCardWithDateRange";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BussinessUnit } from "../../pages/Charts/DashboardChart";
import Total_Spend_Icon_New from "../../images/spend_icon.png";
import AzureHomeBilling from "./AzureHomeBilling";
import azure_location from "../../images/azure_location.svg";
import ArrowDown from "../../images/double-chevron-down.svg";
import MountUnmount from "../MountUnmount";
import ArrowUp from "../../images/double-chevron-up.svg";
const { REACT_APP_AZURE_SUBSCRIPTION_1Y_TABLE_DATA, REACT_APP_AZURE_ACTUAL_AMORTIZED } = process.env;

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        // backgroundColor: "#f4f9f9",
        //padding: theme.spacing(-20),
    },
    paper: {
        padding: theme.spacing(2),
    },
    tab: {
        fontWeight: "bold",
        textTransform: "none",
    },
}));

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

export default function AzureSubscriptionTab(props) {
    const dispatch = useDispatch();

    const { charts, currency, customerId, TAB_SET, activeTab } = props;
    const { selectedSubscription, subscriptionServerless, rec } = props;
    const [resTab, setresTab] = useState(false);
    const {classes} = useStyles();
    // const [value, setValue] = React.useState(TAB_SET.DASHBOARD);
    const [parentWidth, setParentWidth] = useState(null);
    const [yearTableData, setYearTableData] = useState([]);
    const [monthWiseData, setMonthWiseData] = useState(null);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState(null);
    const [showDataBy, setShowDataBy] = useState("calendar");
    const [overlayData, setOverlayData] = useState(null);
    const [ResourceGroup, setResourceGroup] = useState(null);
    const [computeData, setComputeData] = useState(null);
    const [containersData, setContainersData] = useState(null);
    const [kubernetesData, setKubernetesData] = useState(null);

    const [azureMonthWiseChartData, setAzureMonthWiseChartData] = useState([]);
    const [othersMonthWiseChartData, setOthersMonthWiseChartData] = useState([]);
    const [spendByMonthsChartView, setSpendByMonthsChartView] = useState(true);
    const [chartShowingIndex, setChartShowingIndex] = useState(0);
    const [chartTop5ShowingIndex, setChartTop5ShowingIndex] = useState(0);

    const [totalSpendChartView, setTotalSpendChartView] = useState(true);
    const [amortizedTableView, setAmortizedTableView] = useState(true);
    const [period, setPeriod] = useState("1M");
    const [showDataFor, setShowDataFor] = useState("actual");
    const [actualCostData, setActualCostData] = useState([]);
    const [amortizedCostData, setAmortizedCostData] = useState([]);
    const [actualAmortizedRawData, setActualAmortizedRawData] = useState(null);
    const [loadingAmortized, setLoadingAmortized] = useState(false);
    const [amortizedDatePeriod, setAmortizedDatePeriod] = useState("");
    const [totalAmortizeCost, setTotalAmortizeCost] = useState("");
    const [loadingCalendarTableData, setLoadingCalendarTableData] = useState(false);

    const [spendByMonthAccountData, setSpendByMonthAccountData] = useState(null);
    const [spendByMonthResourceGroupData, setSpendByMonthResourceGroupData] = useState(null);
    const [spendByMonthResourcesData, setSpendByMonthResourcesData] = useState(null);
    const [spendByMonthServicesData, setSpendByMonthServicesData] = useState(null);
    const [ytdSearch, setYTDSearch] = useState(null);
    const [progress, setProgress] = useState(0);

    const [dashboardComponentsRef, setDashboardComponentsRef] = useState({});
    const [dashboardComponentsCollapsed, setDashboardComponentsCollapsed] = useState(false);

    useEffect(() => {
        const RGChartData = [];
        charts?.TopFiveResourceGroups?.[period]?.dataSet?.map((i) => {
            RGChartData.push({
                label: i.label == "" ? "unassigned" : i.label,
                value: i.value,
            });
        });
        setResourceGroup(RGChartData);
    }, [period, charts]);

    useEffect(() => {
        if (customerId) {
            const tenantId = selectedSubscription?.tenantId;
            const subscriptionId = selectedSubscription?.value;

            let dataType = "compute";
            dispatch(
                getAzureCCKSubRequest({
                    customerId,
                    tenantId,
                    subscriptionId,
                    dataType,
                })
            );

            dataType = "containers";
            dispatch(
                getAzureCCKSubRequest({
                    customerId,
                    tenantId,
                    subscriptionId,
                    dataType,
                })
            );

            dataType = "kubernetes";
            dispatch(
                getAzureCCKSubRequest({
                    customerId,
                    tenantId,
                    subscriptionId,
                    dataType,
                })
            );

            getAmortizedData();
        }
    }, [customerId]);

    let azureCompute = useSelector((state) => state?.azureCCKReducer?.azureCCKSubData?.data?.SpendByCompute);
    let azureContainers = useSelector((state) => state?.azureCCKReducer?.azureContainersSubData?.data?.SpendByContainers);
    let azureKubernetes = useSelector((state) => state?.azureCCKReducer?.azureKubernetesSubData?.data?.SpendBykubernetes);
    let tagsList = useSelector((state) => state.azureTagsReducer.subscriptionTagsList);

    useEffect(() => {
        getChartData(period, azureCompute, azureContainers, azureKubernetes);
    }, [period, azureCompute, azureContainers, azureKubernetes]);

    async function getChartData() {
        const ComputeChartData = [];
        const ContainersChartData = [];
        const KubernetesChartData = [];

        azureCompute?.[period]?.resources?.map((i) => {
            ComputeChartData.push({
                label: i.resourceName == "" ? "unassigned" : i.resourceName,
                value: Number(i.cost),
            });
        });
        if (ComputeChartData && ComputeChartData.length > 0) {
            setComputeData(await filterArraySameItemName(ComputeChartData));
        }

        azureContainers?.[period]?.resources?.map((i) => {
            ContainersChartData.push({
                label: i.resourceName == "" ? "unassigned" : i.resourceName,
                value: Number(i.cost),
            });
        });
        if (ContainersChartData && ContainersChartData.length > 0) {
            setContainersData(await filterArraySameItemName(ContainersChartData));
        }

        azureKubernetes?.[period]?.resources?.map((i) => {
            KubernetesChartData.push({
                label: i.resourceName == "" ? "unassigned" : i.resourceName,
                value: Number(i.cost),
            });
        });
        if (KubernetesChartData && KubernetesChartData.length > 0) {
            setKubernetesData(await filterArraySameItemName(KubernetesChartData));
        }
    }

    //-----------------Converteer function -------------------------

    async function filterArraySameItemName(obj) {
        let holder = {};
        obj.forEach(function (d) {
            if (holder.hasOwnProperty(d.label)) {
                holder[d.label] = holder[d.label] + d.value;
            } else {
                holder[d.label] = d.value;
            }
        });

        var obj2 = [];

        for (var prop in holder) {
            obj2.push({ label: prop, value: String(holder[prop]) });
        }
        return obj2.sort((a, b) => Number(b.value) - Number(a.value)).slice(0, 5);
    }

    const handleChange = (event, newValue) => {
        props?.setActiveTabSub(newValue);
    };

    function rootLoaded() {
        setTimeout(() => {
            const e = window.$("#root_dashboard");
            try {
                setParentWidth(e.width());
            } catch (e) {}
        }, 2000);
    }

    async function onMonthSelect(m, y = null) {
        if (monthWiseData == null || !monthWiseData) {
            await get1YearTableData(selectedSubscription.tenantId, selectedSubscription.value, "daily").then(() => {
                setCurrentMonth(m);
            });
            setCurrentMonth(m);
        } else {
            setCurrentMonth(m);
        }

        if (y) setCurrentYear(y);
    }

    async function get1YearTableData(tenantId, subscriptionId, period = "monthly") {
        setLoadingCalendarTableData(true);
        return await axios
            .get(REACT_APP_AZURE_SUBSCRIPTION_1Y_TABLE_DATA, {
                params: {
                    customerId,
                    view: period,
                    subscriptionId,
                    tenantId,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                // console.log({response})
                if (period === "daily") {
                    setMonthWiseData(response?.data?.data?.SpendByDaily);
                } else {
                    setYearTableData(response?.data?.data?.SpendByMonth);
                }
            })
            .catch((e) => {})
            .finally(() => setLoadingCalendarTableData(false));
    }

    useEffect(() => {
        if (customerId) {
            get1YearTableData(selectedSubscription?.tenantId, selectedSubscription?.value).then(() => get1YearTableData(selectedSubscription?.tenantId, selectedSubscription?.value, "daily"));
        }
        props?.setTabSelect(null);
    }, [selectedSubscription, customerId]);

    useEffect(() => {
        if (!monthWiseData) return;
        setCurrentYear(new Date().getFullYear());
        setCurrentMonth(Months[new Date().getMonth()]);
    }, [monthWiseData, activeTab]);

    useEffect(() => {
        rootLoaded();
    }, [activeTab]);

    const [buffer, setBuffer] = React.useState(10);
    const progressRef = React.useRef(() => {});
    React.useEffect(() => {
        progressRef.current = () => {
            if (progress > 100) {
                setProgress(0);
                setBuffer(10);
            } else {
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setProgress(progress + diff);
                setBuffer(progress + diff + diff2);
            }
        };
    });

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    function getOverlayData(d, m, y) {
        let data = null;
        let date = null;
        if (d && m && y) {
            data = monthWiseData
                .find((data) => data.year == y)
                ?.months?.find((data) => data.month == m)
                ?.dates.find((data) => data.date == y.toString() + m.toString() + d.toString());
            date = moment(data?.date || 0, "YYYYMMDD").format("MMM D, YYYY");
        } else if (m && y) {
            data = yearTableData.find((data) => data.month == y.toString() + m.toString());
            // debugger
            date = moment(data?.startDate || 0, "YYYYMMDD").format("MMM D, YYYY") + " - " + moment(data?.endDate || 0, "YYYYMMDD").format("MMM D, YYYY");
        }
        return { data, date };
    }

    useEffect(() => {
        if (currentMonth) {
            let month = Months.indexOf(currentMonth) + 1;
            month = month < 10 ? "0" + month : month;
            // console.log({currentMonth, currentYear});
            // debugger
            const { data, date } = getOverlayData(null, month, currentYear);
            setOverlayData({ cost: data?.cost, data, date });
        }
    }, [currentMonth, currentYear]);

    useEffect(() => {
        if (yearTableData) generateGroupedChartData({ azure: { SpendByMonth: yearTableData } }, setAzureMonthWiseChartData, () => {});
    }, [yearTableData]);

    useEffect(() => {
        if (yearTableData)
            generateGroupedChartData(
                { azure: { SpendByMonth: yearTableData } },
                (data) => {
                    setOthersMonthWiseChartData(
                        data?.map((t) => ({
                            ...t,
                            values: t.values.map((v) => ({
                                ...v,
                                value: {
                                    resourceGroups: v.value?.resourceGroups?.sort((a, b) => parseFloat(b.cost) - parseFloat(a.cost))?.slice(0, 5),
                                    resources: v.value?.resources?.map((a)=>{
                                        a.resourceId = a?.resourceId.replace(/\//g,"");
                                        return a;
                                    })?.sort((a, b) => parseFloat(b.cost) - parseFloat(a.cost))?.slice(0, 5),
                                },
                            })),
                        })) || []
                    );
                },
                () => {},
                ["resourceGroups", "resources"]
            );
    }, [yearTableData]);

    function getAmortizedData() {
        setLoadingAmortized(true);
        axios
            .get(REACT_APP_AZURE_ACTUAL_AMORTIZED, {
                params: {
                    customerId,
                    subscriptionId: selectedSubscription?.value,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                setActualAmortizedRawData(response.data);
            })
            .catch((e) => {})
            .finally(() => setLoadingAmortized(false));
    }

    function getCurrentMonthToDate() {
        const data = (showDataFor == "actual" ? actualCostData : amortizedCostData) || [];
        var date1 = moment(new Date(data?.[0]?.date?.[0]));
        // var date2 = new Date(data?.[data.length]?.date);
        // setAmortizedDatePeriod(moment(date1).format("MMM, YYYY") + "-" + moment(date2).format("MMM D, YYYY"));
        setAmortizedDatePeriod(date1.isValid() ? date1.format("MMMM YYYY") : "");

        setTotalAmortizeCost(data.map((d) => d.cost).reduce((a, b) => a + b, 0));
    }

    useEffect(() => {
        getCurrentMonthToDate();
    }, [actualCostData, amortizedCostData, showDataFor]);

    useEffect(() => {
        if (actualAmortizedRawData) {
            setActualCostData(transformCostData(actualAmortizedRawData?.actualCost?.[0]?.properties?.rows));
            setAmortizedCostData(transformCostData(actualAmortizedRawData?.amortizedCostData?.[0]?.properties?.rows));
        }
    }, [actualAmortizedRawData]);

    const billingComponentRef = useRef();

    const onPrint = useReactToPrint({
        content: () => billingComponentRef.current,
    });

    useEffect(() => {
        setSpendByMonthResourceGroupData(null);
        if (yearTableData && yearTableData?.length) {
            const accounts = {};
            const finalData = [];

            yearTableData?.forEach((monthWise) => {
                monthWise?.resourceGroups?.forEach((subWise) => {
                    if (subWise.resourceGroupName in accounts) {
                        accounts[subWise.resourceGroupName].list.push({ month: monthWise.month, cost: subWise.cost });
                    } else {
                        accounts[subWise.resourceGroupName] = { list: [] };
                        accounts[subWise.resourceGroupName].list.push({ month: monthWise.month, cost: subWise.cost });
                    }
                });
            });

            Object.keys(accounts).forEach((key) => {
                const account = accounts[key].list;

                const newData = getMonthsFromNow()
                    .reverse()
                    .map((m, i) => {
                        const time = moment(m, "MMM YYYY");
                        const found = account.findIndex((d) => {
                            const int = moment(d?.month, "YYYYMM");
                            return time.isSame(int);
                        });
                        if (found > -1) {
                            return account?.[found];
                        } else {
                            return { month: parseInt(moment(m).format("YYYYMM")), cost: "_" };
                        }
                    });

                finalData.push({ subscriptionId: key, name: accounts[key].name, list: newData });
            });

            let otherRow = [];
            if (finalData && finalData.length) {
                const totalData = finalData.map((f) => f.list.map((l) => (isNumeric(l.cost) ? parseFloat(l.cost) : 0)));
                const finalTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                for (var col = 0; col < totalData?.[0]?.length || 0; col++) {
                    totalData.forEach((row) => {
                        finalTotal[col] += row[col];
                    });
                }

                otherRow.push({
                    subscriptionId: "All Resource groups",
                    list: finalData?.[0]?.list?.map((f, i) => ({ month: f.month, cost: "" + finalTotal[i] })),
                });
            }

            setSpendByMonthResourceGroupData({ list: finalData, other: otherRow });
        }
    }, [yearTableData]);

    useEffect(() => {
        setSpendByMonthResourcesData(null);
        if (yearTableData && yearTableData?.length) {
            const accounts = {};
            const finalData = [];

            yearTableData?.forEach((monthWise) => {
                monthWise?.resources?.forEach((subWise) => {
                    if (subWise.resourceId in accounts) {
                        accounts[subWise.resourceId].list.push({ month: monthWise.month, cost: subWise.cost });
                    } else {
                        accounts[subWise.resourceId] = { list: [] };
                        accounts[subWise.resourceId].list.push({ month: monthWise.month, cost: subWise.cost });
                    }
                });
            });

            Object.keys(accounts).forEach((key) => {
                const account = accounts[key].list;

                const newData = getMonthsFromNow()
                    .reverse()
                    .map((m, i) => {
                        const time = moment(m, "MMM YYYY");
                        const found = account.findIndex((d) => {
                            const int = moment(d?.month, "YYYYMM");
                            return time.isSame(int);
                        });
                        if (found > -1) {
                            return account?.[found];
                        } else {
                            return { month: parseInt(moment(m).format("YYYYMM")), cost: "_" };
                        }
                    });

                finalData.push({ subscriptionId: key, name: accounts[key].name, list: newData });
            });

            let otherRow = [];
            if (finalData && finalData.length) {
                const totalData = finalData.map((f) => f.list.map((l) => (isNumeric(l.cost) ? parseFloat(l.cost) : 0)));
                const finalTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                for (var col = 0; col < totalData?.[0]?.length || 0; col++) {
                    totalData.forEach((row) => {
                        finalTotal[col] += row[col];
                    });
                }

                otherRow.push({
                    subscriptionId: "All Resources",
                    list: finalData?.[0]?.list?.map((f, i) => ({ month: f.month, cost: "" + finalTotal[i] })),
                });
            }

            setSpendByMonthResourcesData({ list: finalData, other: otherRow });
        }
    }, [yearTableData]);

    useEffect(() => {
        setSpendByMonthServicesData(null);
        if (yearTableData && yearTableData?.length) {
            const accounts = {};
            const finalData = [];

            yearTableData?.forEach((monthWise) => {
                monthWise.services.forEach((subWise) => {
                    if (subWise.service in accounts) {
                        accounts[subWise.service].list.push({ month: monthWise.month, cost: subWise.cost });
                    } else {
                        accounts[subWise.service] = { list: [] };
                        accounts[subWise.service].list.push({ month: monthWise.month, cost: subWise.cost });
                    }
                });
            });

            Object.keys(accounts).forEach((key) => {
                const account = accounts[key].list;

                const newData = getMonthsFromNow()
                    .reverse()
                    .map((m, i) => {
                        const time = moment(m, "MMM YYYY");
                        const found = account.findIndex((d) => {
                            const int = moment(d?.month, "YYYYMM");
                            return time.isSame(int);
                        });
                        if (found > -1) {
                            return account?.[found];
                        } else {
                            return { month: parseInt(moment(m).format("YYYYMM")), cost: "_" };
                        }
                    });

                finalData.push({ subscriptionId: key, name: accounts[key].name, list: newData });
            });

            let otherRow = [];
            if (finalData && finalData.length) {
                const totalData = finalData.map((f) => f.list.map((l) => (isNumeric(l.cost) ? parseFloat(l.cost) : 0)));
                const finalTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                for (var col = 0; col < totalData?.[0]?.length || 0; col++) {
                    totalData.forEach((row) => {
                        finalTotal[col] += row[col];
                    });
                }

                otherRow.push({
                    subscriptionId: "All Services",
                    list: finalData?.[0]?.list?.map((f, i) => ({ month: f.month, cost: "" + finalTotal[i] })),
                });
            }

            setSpendByMonthServicesData({ list: finalData, other: otherRow });
        }
    }, [yearTableData]);

    const pausePlayTotalRef = useRef(null);
    const pausePlaySpendRef = useRef(null);
    const pausePlaySlideRef = useRef(null);

    function collapseCards() {
        const h = dashboardComponentsRef;
        for(const rg in h){
            h[rg](dashboardComponentsCollapsed);
        }
        setDashboardComponentsCollapsed(!dashboardComponentsCollapsed);
    }

    return (
        <div className={classes.root}>
            <TabContext value={activeTab}>
                <TabList onChange={handleChange} style={{ maxWidth: "calc(100vw - 80px)" }} textColor="primary" indicatorColor="primary" variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={billing_icon} />
                                Dashboard
                            </div>
                        }
                        value={TAB_SET.BILLING}
                        className={classes.tab}
                    />
                    {/*<Tab*/}
                    {/*    style={styles.tab}*/}
                    {/*    label={*/}
                    {/*        <div style={styles.labelContainer}>*/}
                    {/*            <img style={styles.icon} src={DashboardIcon} />*/}
                    {/*            Dashboard*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*    value={TAB_SET.DASHBOARD}*/}
                    {/*    className={classes.tab}*/}
                    {/*    onClick={() => setresTab(false)}*/}
                    {/*/>*/}
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                  <img style={styles.icon} src={recommendation_icon} />
                                Recommendations
                            </div>
                        }
                        value={TAB_SET.RECOMMENDATIONS}
                        className={classes.tab}
                    />
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={azure_service} />
                                Services
                            </div>
                        }
                        value={TAB_SET.SERVICES}
                        className={classes.tab}
                        onClick={() => setresTab(true)}
                        {...a11yProps(TAB_SET.SERVICES)}
                    />
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={ResourceGroupsIcon} />
                                Resource groups
                            </div>
                        }
                        value={TAB_SET.RESOURCE_GROUPS}
                        className={classes.tab}
                        onClick={() => setresTab(true)}
                    />
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={AzureResourcesIcon} />
                                Resources
                            </div>
                        }
                        value={TAB_SET.RESOURCES}
                        className={classes.tab}
                        onClick={() => setresTab(true)}
                    />

                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={ComputeIcon} />
                                Compute
                            </div>
                        }
                        value={TAB_SET.COMPUTE}
                        className={classes.tab}
                        {...a11yProps(TAB_SET.COMPUTE)}
                    />
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={storage_account_icon} />
                                Storage
                            </div>
                        }
                        value={TAB_SET.STORAGE}
                        className={classes.tab}
                        onClick={() => setresTab(true)}
                        {...a11yProps(TAB_SET.STORAGE)}
                    />
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={Azure_cosmosDB_icon} />
                                CosmosDB
                            </div>
                        }
                        value={TAB_SET.COSMOS_DB}
                        className={classes.tab}
                        onClick={() => setresTab(true)}
                        {...a11yProps(TAB_SET.COSMOS_DB)}
                    />
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={function_apps_icon} />
                                Function Apps
                            </div>
                        }
                        value={TAB_SET.FUNCTION_APPS}
                        className={classes.tab}
                        onClick={() => setresTab(true)}
                        {...a11yProps(TAB_SET.FUNCTION_APPS)}
                    />

                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={serverless_icon} />
                                Serverless
                            </div>
                        }
                        value={TAB_SET.SERVERLESS}
                        className={classes.tab}
                    />

                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={azure_reservations} />
                                Reservations
                            </div>
                        }
                        value={TAB_SET.RESERVATIONS}
                        className={classes.tab}
                    />
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={AzureTagsIcon} />
                                Tags
                            </div>
                        }
                        value={TAB_SET.TAGS}
                        className={classes.tab}
                        onClick={() => setresTab(false)}
                    />
                </TabList>
                <Divider />
                <div style={{ marginTop: 0 }}></div>
                <TabPanel value={"dep"+TAB_SET.DASHBOARD}>
                    <div style={{}} onLoad={rootLoaded} id={"root_dashboard"}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <ContainerCardWithDateRange
                                    showDateRange={true}
                                    title={"Total spend"}
                                    titleIcon={Total_Spend_Icon_New}
                                    HeaderTitleComponent={(e, setCollapseClickable) => (
                                        <>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                                <span>Total spend</span>
                                                <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)}  onMouseOut={() => setCollapseClickable(true)} className={'pause-play-icon'}>
                                                    <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                        <div onClick={() => pausePlayTotalRef?.current?.click()}>
                                                            {pausePlayTotalRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    onPeriodChange={setPeriod}
                                    // saveAsCSV={true}
                                    // onSaveAsCSV={onSaveAsCSV}
                                >
                                    {({ period }) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                position: "relative",
                                            }}
                                        >
                                            <div style={{ position: "absolute", zIndex: 1 }}>
                                                <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                    <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                            <div style={{ padding: 10 }}>
                                                <SlideShow names={["Top 5 Services", "Top 5 Locations", "Top 5 Resource groups"]} arrowsHandled={true} showingIndex={chartTop5ShowingIndex} pausePlayRef={pausePlayTotalRef}>
                                                    <div>
                                                        {charts?.TopFiveServices?.[period]?.dataSet ? (
                                                            <ReactD3BarChart
                                                                title={"Top 5 Services"}
                                                                mouseEffect={true}
                                                                cost={charts?.TopFiveServices?.[period]?.totalCost}
                                                                currency={currency}
                                                                data={charts?.TopFiveServices?.[period]?.dataSet.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                            />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>No data found for Top 5 Services</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {charts?.TopFiveLocations?.[period]?.dataSet ? (
                                                            <ReactD3BarChart
                                                                title={"Top 5 Locations"}
                                                                mouseEffect={true}
                                                                cost={charts?.TopFiveLocations?.[period]?.totalCost}
                                                                currency={currency}
                                                                data={charts?.TopFiveLocations?.[period]?.dataSet.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                            />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>No data found for Top 5 Locations</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {ResourceGroup && ResourceGroup.length ? (
                                                            <ReactD3BarChart
                                                                mouseEffect={true}
                                                                title={"Top 5 ResourceGroups"}
                                                                cost={charts?.TopFiveResourceGroups?.[period]?.totalCost}
                                                                currency={currency}
                                                                data={ResourceGroup.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                            />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>No data found for Top 5 ResourceGroups</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>
                                                </SlideShow>
                                            </div>
                                            <div style={{ position: "absolute", right: 1 }}>
                                                <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                    <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    )}
                                </ContainerCardWithDateRange>
                            </Grid>
                            <Grid item xs={6}>
                                <ContainerCardWithDateRange
                                    showDateRange={true}
                                    title={"Total spend"}
                                    titleIcon={Total_Spend_Icon_New}
                                    HeaderTitleComponent={(e, setCollapseClickable) => (
                                        <>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                                <span>Total spend</span>
                                                <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)}  onMouseOut={() => setCollapseClickable(true)} className={'pause-play-icon'}>
                                                    <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                        <div onClick={() => pausePlaySlideRef?.current?.click()}>
                                                            {pausePlaySlideRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    onPeriodChange={setPeriod}
                                    // saveAsCSV={true}
                                    // onSaveAsCSV={onSaveAsCSV}
                                >
                                    {({ period }) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                position: "relative",
                                            }}
                                        >
                                            <div style={{ position: "absolute", zIndex: 1 }}>
                                                <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                    <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                            <div style={{ padding: 10 }}>
                                                <SlideShow names={["Spend By Category", "Spend by Compute", "Spend by Containers", "Spend by Kubernetes"]} arrowsHandled={true} showingIndex={chartShowingIndex} pausePlayRef={pausePlaySlideRef}>
                                                    <div>
                                                        {charts?.SpendByCategory?.[period]?.dataSet ? (
                                                            <ReactD3BarChart
                                                                mouseEffect={true}
                                                                title={"Spend By Category"}
                                                                cost={charts?.SpendByCategory?.[period]?.totalCost}
                                                                currency={currency}
                                                                data={charts?.SpendByCategory?.[period]?.dataSet.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                            />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>No data found for Spend By Category</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {azureCompute?.[period]?.resources?.length && computeData ? (
                                                            <ReactD3BarChart mouseEffect={true} title={"Spend by Compute"} cost={azureCompute?.[period]?.totalCost} currency={currency} data={computeData.sort((a, b) => b.value - a.value)} />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>{"No Compute found for " + period}</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {azureContainers?.[period]?.resources?.length && containersData ? (
                                                            <ReactD3BarChart mouseEffect={true} title={"Spend by Containers"} cost={azureContainers?.[period]?.totalCost} currency={currency} data={containersData.sort((a, b) => b.value - a.value)} />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>{"No Containers found for " + period}</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {azureKubernetes?.[period]?.resources?.length && kubernetesData ? (
                                                            <ReactD3BarChart mouseEffect={true} title={"Spend by Kubernetes"} cost={azureKubernetes?.[period]?.totalCost} currency={currency} data={kubernetesData.sort((a, b) => b.value - a.value)} />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>{"No Kubernetes found for " + period}</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>
                                                </SlideShow>
                                            </div>
                                            <div style={{ position: "absolute", right: 1 }}>
                                                <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                    <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    )}
                                </ContainerCardWithDateRange>
                            </Grid>
                        </Grid>
                        <br />
                       
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <ContainerCardWithDateRange showDateRange={false} title={"Calendar"} titleIcon={calendar_icon}>
                                        {() => (
                                            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                            {loadingCalendarTableData ? (
                                               <CircularProgress justifyContent={'center'} thickness={2} size={60} />
                                                ) : (
                                            <YearCalendarData
                                                style={{ flex: 1 }}
                                                currency={Format.formatCurrencySymbol(currency || "USD")}
                                                year={currentYear}
                                                onNext={() => setCurrentYear(currentYear + 1)}
                                                onPrev={() => setCurrentYear(currentYear - 1)}
                                                yearData={CalendarDataProcessor(yearTableData, "year")}
                                                monthData={CalendarDataProcessor(monthWiseData, "month")?.[currentYear]?.[currentMonth?.toString()?.substring(0, 3)] || null}
                                                onMonthSelect={onMonthSelect}
                                                month={currentMonth}
                                                renderOverlay={(d, m, y, cost) => {
                                                    const { data, date } = getOverlayData(d, m, y);

                                                    if (data)
                                                        return setOverlayData({
                                                            data,
                                                            date,
                                                            cost: data?.cost,
                                                        });
                                                    return null;
                                                }}
                                                calendarWidth={"100%"}
                                            />
                                            )}
                                            </div>
                                        )}
                                    </ContainerCardWithDateRange>
                                </Grid>
                                <Grid item xs={6}>
                                   
                                        <ContainerCardWithDateRange showDateRange={false} title={"Calendar details"} titleIcon={calendar_icon}>
                                            {() => (
                                                 <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                 {!!!overlayData ? (
                                                     <CircularProgress thickness={2} size={60} />
                                                      ) : (
                                                         overlayData && overlayData.data && (
                                                <OverlayComponent
                                                    cost={overlayData?.cost}
                                                    data={overlayData?.data}
                                                    date={overlayData?.date}
                                                    currency={Format.formatCurrencySymbol(currency || "USD")}
                                                    dataKeys={[
                                                        {
                                                            key: "subscriptions",
                                                            title: "Top Azure Subscriptions",
                                                            subKey: "subscriptionName",
                                                            subKey2: "subscriptionId",
                                                            icon: subscritpion_icon,
                                                            onMouseOver: "subscriptionId",
                                                        },
                                                        {
                                                            key: "locations",
                                                            title: "Top Locations",
                                                            subKey: "location",
                                                            icon: azure_location,
                                                        },
                                                        {
                                                            key: "services",
                                                            title: "Top Services",
                                                            subKey: "service",
                                                            icon: azure_service,
                                                        },
                                                        {
                                                            key: "resourceGroups",
                                                            title: "Top Resource Groups",
                                                            subKey: "resourceGroupName",
                                                            icon: resource_group,
                                                        },
                                                        {
                                                            key: "resources",
                                                            title: "Top Resources",
                                                            subKey: "resourceId",
                                                            icon: resource_group,
                                                        },
                                                    ].filter((f) => ["subscriptions", "locations", "services"].indexOf(f.key) === -1)}
                                                    onClick={() => {
                                                        // props?.setActiveTab(TAB_SET.RESOURCE_GROUPS_RESOURCES);
                                                    }}
                                                    maxWidth={"100%"}
                                                    maxHeight={700}
                                                />
                                                ))}
                                                </div>
                                            )}
                                        </ContainerCardWithDateRange>
                                    
                                </Grid>
                            </Grid>
                       
                        <div style={{ marginTop: 10 }}>
                            <ContainerCardWithDateRange
                                showDateRange={false}
                                title={"Spend by months"}
                                titleIcon={calendar_icon}
                                HeaderTitleComponent={(e, setCollapseClickable) => (
                                    <>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                            <span>Spend by months</span>
                                            <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)}  onMouseOut={() => setCollapseClickable(true)} className={'pause-play-icon'}>
                                                <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                    <div onClick={() => pausePlaySpendRef?.current?.click()}>
                                                        {pausePlaySpendRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </>
                                )}
                                // saveAsCSV={true}
                                // onSaveAsCSV={onSaveAsCSV}
                            >
                                {() => (
                                    <div style={{}}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: 10,
                                                paddingTop: 0,
                                                //  justifyContent: 'center'
                                            }}
                                        >
                                            <div style={{ position: "absolute", zIndex: 10, marginLeft: 5 }}>
                                                <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                    <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                            <SlideShow
                                                pausePlayRef={pausePlaySpendRef}
                                                delay={TIMERS.LOWER_CHART_INTERVAL}
                                                showingIndex={chartShowingIndex}
                                                names={["Spend by Azure", "Top 5 Resource Groups", "Top 5 Resources"]}
                                                view="All"
                                                arrowsHandled={true}
                                            >
                                                {loadingCalendarTableData ? (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <CircularProgress thickness={2} size={80} />
                                                    </div>
                                                ) : (
                                                    <GroupedBarChart
                                                        maxWidth={null}
                                                        currency={Format.formatCurrencySymbol(currency)}
                                                        totalCost={null}
                                                        // mouseEffect={true}
                                                        data={azureMonthWiseChartData}
                                                        cloudCount={1}
                                                    />
                                                )}
                                                <StackedBarChart2
                                                    maxWidth={null}
                                                    currency={Format.formatCurrencySymbol(currency)}
                                                    totalCost={null}
                                                    keyStack={"resourceGroups"}
                                                    keyField={"resourceGroupName"}
                                                    valueKey={"cost"}
                                                    // mouseEffect={true}
                                                    data={othersMonthWiseChartData}
                                                    cloudCount={1}
                                                />
                                                <StackedBarChart2
                                                    maxWidth={null}
                                                    currency={Format.formatCurrencySymbol(currency)}
                                                    totalCost={null}
                                                    keyStack={"resources"}
                                                    keyField={"resourceId"}
                                                    valueKey={"cost"}
                                                    // mouseEffect={true}
                                                    data={othersMonthWiseChartData}
                                                    cloudCount={1}
                                                />
                                            </SlideShow>
                                            <div style={{ position: "absolute", right: 30, zIndex: 10 }}>
                                                <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                    <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </ContainerCardWithDateRange>
                        </div>

                        <div style={{ marginTop: 20 }}>
                            {yearTableData && yearTableData.length ? (
                                <div style={{ justifyContent: "space-between", display: "flex" }}>
                                    <div>
                                        <h5 style={{ fontWeight: "bold" }}>
                                            {" "}
                                            <img src={Calendar_Spend_Icon} height={25} /> Spend by months{" "}
                                        </h5>
                                    </div>
                                    {/*<div>*/}
                                    {/*    <h6>*/}
                                    {/*        <img src={Azure_key} height={20} /> {selectedSubscription?.title} ({selectedSubscription?.value})*/}
                                    {/*    </h6>*/}
                                    {/*</div>*/}
                                    <div></div>
                                </div>
                            ) : null}
                            <Paper elevation={3}>
                                <div style={{ overflow: "hidden" }}>
                                    {parentWidth && (
                                        <YearTableData
                                            width={parentWidth}
                                            hideLegend={true}
                                            data={{
                                                // azure: yearTableData?.map((c) => c.cost),
                                                azure: yearTableData,
                                            }}
                                            months={yearTableData?.map((m) => m.month)}
                                            currency={Format.formatCurrencySymbol(currency || "USD")}
                                        />
                                    )}
                                </div>
                            </Paper>
                        </div>
                        <Paper elevation={10} style={{ marginTop: 15 }}>
                            <div>
                                <div
                                    className={"accordian_item1"}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <h5 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20 }}>
                                        <img src={CostAnalysisIcon} height={25} /> Actual Cost and Amortized Cost
                                    </h5>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ marginRight: 4 }}>
                                            <span style={{ marginRight: 10 }}>{amortizedDatePeriod}</span>
                                            <b>
                                                {Format.formatCurrencySymbol(currency || "USD")}
                                                {Format.formatNumber(Number(totalAmortizeCost).toFixed(2))}
                                            </b>
                                        </div>
                                        <div>
                                            <Paper elevation={0}>
                                                <ButtonGroup style={{ outline: "none" }}>
                                                    <Button
                                                        onClick={() => setShowDataFor("actual")}
                                                        style={{
                                                            outline: "none",
                                                            color: showDataFor !== "actual" ? "rgba(63, 81, 181, 0.86)" : "white",
                                                            backgroundColor: showDataFor === "actual" ? "rgba(63, 81, 181, 0.86)" : "white",
                                                        }}
                                                    >
                                                        <Typography>Actual</Typography>
                                                    </Button>
                                                    <Button
                                                        onClick={() => setShowDataFor("amortized")}
                                                        style={{
                                                            outline: "none",
                                                            color: showDataFor !== "amortized" ? "rgba(63, 81, 181, 0.86)" : "white",
                                                            backgroundColor: showDataFor === "amortized" ? "rgba(63, 81, 181, 0.86)" : "white",
                                                        }}
                                                    >
                                                        <Typography>Amortized</Typography>
                                                    </Button>
                                                </ButtonGroup>
                                            </Paper>
                                        </div>
                                        {/* {totalSpendChartView &&
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <span style={{
                                                    marginRight: 4, fontSize: 12, color: '#616161'
                                                }}>
                                                    Last
                                                </span>
                                            <DateRange height={22} padding={1} fontSize={12}
                                                       onSelectionChanged={setPeriod}
                                                       defaultSelected={periodSpendByTotal}
                                            />
                                        </div>  } */}
                                        <IconButton onClick={() => setAmortizedTableView(!amortizedTableView)} style={{ outline: "none" }}>
                                            {amortizedTableView ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                            <Collapse in={amortizedTableView}>
                                <div style={{ padding: 10 }}>
                                    {loadingAmortized ? (
                                        <LineProgress />
                                    ) : actualAmortizedRawData && (actualCostData?.length || amortizedCostData?.length) ? (
                                        <CustomEnhancedTable
                                            currency={Format.formatCurrencySymbol(currency)}
                                            rows={showDataFor == "actual" ? actualCostData : amortizedCostData}
                                            headCells={["Name", "Resource type", "Resource group", "Location", "Cost"]}
                                        />
                                    ) : (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <span style={{}}>No data found </span>
                                        </div>
                                    )}
                                </div>
                            </Collapse>
                        </Paper>
                        <div style={{ marginTop: 30, marginBottom: 20 }}>
                            <AzureResources tab={resTab} showData={rec} period={period} currency={Format.formatCurrencySymbol(currency)} customerId={customerId} />
                        </div>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {selectedSubscription.title == "All" ? <AzureSubscriptionsList tenantLogo={active_directory} currency={Format.formatCurrencySymbol(currency)} accountList={charts?.SpendByAccounts?.[period]?.dataSet || 0} /> : null}
                            </Grid>

                            {/* --------------------------------------Serverless List---------------------------------------- */}
                        </Grid>
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.TAGS}>
                    <div>
                        <AzureTags
                            tab={resTab}
                            tenantId={selectedSubscription.tenantId}
                            subscriptionId={selectedSubscription.value}
                            // showData={rec}
                            // tenantId={rec.tenantId}
                            period={period}
                            currency={Format.formatCurrencySymbol(currency)}
                            progress={progress}
                            customerId={customerId}
                            tagsList={tagsList}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.SERVICES}>
                    <div>
                        <AzureServices
                            parentWidth={parentWidth}
                            loadingCalendarTableData={loadingCalendarTableData}
                            spendByMonthServicesData={spendByMonthServicesData}
                            tab={resTab}
                            showData={rec}
                            period={period}
                            currency={Format.formatCurrencySymbol(currency)}
                            progress={progress}
                            customerId={customerId}
                            subscriptionId={selectedSubscription?.value}
                        />
                    </div>
                </TabPanel>

                <TabPanel value={TAB_SET.RESOURCE_GROUPS_RESOURCES}>
                    <div>
                        <AzureResources tab={resTab} showData={rec} period={period} currency={Format.formatCurrencySymbol(currency)} progress={progress} customerId={customerId} />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.RESOURCE_GROUPS}>
                    <div>
                        <ResourceGroups
                            parentWidth={parentWidth}
                            loadingCalendarTableData={loadingCalendarTableData}
                            spendByMonthResourceGroupData={spendByMonthResourceGroupData}
                            showData={rec}
                            currency={Format.formatCurrencySymbol(currency)}
                            customerId={customerId}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.RESOURCES}>
                    <div>
                        <Resources
                            parentWidth={parentWidth}
                            loadingCalendarTableData={loadingCalendarTableData}
                            spendByMonthResourcesData={spendByMonthResourcesData}
                            showData={rec}
                            currency={Format.formatCurrencySymbol(currency)}
                            customerId={customerId}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.RECOMMENDATIONS}>
                    <div>
                        <AzureRecommendation currency={Format.formatCurrencySymbol(currency)} level={'subscription'} tenantId={rec.tenantId} subscriptionId={rec.subscriptionId} customerId={customerId} progress={progress} />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.STORAGE}>
                    <div>
                        <AzureStorage
                            tab={resTab}
                            showData={rec}
                            period={period}
                            currency={Format.formatCurrencySymbol(currency)}
                            progress={progress}
                            tenantId={selectedSubscription.tenantId}
                            subscriptionId={selectedSubscription.value}
                            subscriptionList={props.subscriptionList}
                            customerId={customerId}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.SERVERLESS}>
                    <div>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <AzureServerlessHome
                                    serverlessTotalCost={subscriptionServerless?.SpendByServerlessServices?.[period]?.totalCost || 0}
                                    tenantId={selectedSubscription.tenantId}
                                    currency={Format.formatCurrencySymbol(currency)}
                                    serverlessList={subscriptionServerless?.SpendByServerlessServices?.[period]?.dataSet || 0}
                                    subscriptionId={selectedSubscription.value}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.BILLING} onLoad={rootLoaded} id={"root_dashboard"}>
                    <MountUnmount onUnmount={()=> {
                            setDashboardComponentsRef({});
                            setDashboardComponentsCollapsed(false);
                        }} />
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <span onClick={collapseCards} className={'link-button'}>{dashboardComponentsCollapsed?"Expand All":"Collapse All"}
                                <img src={!dashboardComponentsCollapsed?ArrowUp:ArrowDown} height={20} />
                            </span>
                    </div>
                    <div style={{ maxWidth: "calc(100vw - 120px)" }} ref={billingComponentRef}>
                        <AzureHomeBilling
                            somerefs={[
                                {how: setDashboardComponentsRef, what:'billing1', where: dashboardComponentsRef},
                            ]}
                            yearTableData={yearTableData}
                            currency={currency}
                            calendarLoading={loadingCalendarTableData}
                            monthWiseData={monthWiseData}
                            get1YearTableData={get1YearTableData}
                            csv_table_id={"azuresubscriptionbillingtable1"}
                            csv_filename={"azure_subscription_billing.csv"}
                            excludeOverlayKeys={["subscriptions", "locations", "services"]}
                        />
                    </div>
                    <br/>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ContainerCardWithDateRange
                                someref={
                                    {how: setDashboardComponentsRef, what:'billing2', where: dashboardComponentsRef}
                                }
                                showDateRange={true}
                                title={"Total spend"}
                                titleIcon={Total_Spend_Icon_New}
                                HeaderTitleComponent={(e, setCollapseClickable) => (
                                    <>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                            <span>Total spend</span>
                                            <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)}  onMouseOut={() => setCollapseClickable(true)} className={'pause-play-icon'}>
                                                <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                    <div onClick={() => pausePlayTotalRef?.current?.click()}>
                                                        {pausePlayTotalRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </>
                                )}
                                onPeriodChange={setPeriod}
                                // saveAsCSV={true}
                                // onSaveAsCSV={onSaveAsCSV}
                            >
                                {({ period }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            position: "relative",
                                        }}
                                    >
                                        <div style={{ position: "absolute", zIndex: 1 }}>
                                            <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                            </IconButton>
                                        </div>
                                        <div style={{ padding: 10 }}>
                                            <SlideShow names={["Top 5 Services", "Top 5 Locations", "Top 5 Resource groups"]} arrowsHandled={true} showingIndex={chartTop5ShowingIndex} pausePlayRef={pausePlayTotalRef}>
                                                <div>
                                                    {charts?.TopFiveServices?.[period]?.dataSet ? (
                                                        <ReactD3BarChart
                                                            title={"Top 5 Services"}
                                                            mouseEffect={true}
                                                            cost={charts?.TopFiveServices?.[period]?.totalCost}
                                                            currency={currency}
                                                            data={charts?.TopFiveServices?.[period]?.dataSet.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                        />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>No data found for Top 5 Services</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>
                                                <div>
                                                    {charts?.TopFiveLocations?.[period]?.dataSet ? (
                                                        <ReactD3BarChart
                                                            title={"Top 5 Locations"}
                                                            mouseEffect={true}
                                                            cost={charts?.TopFiveLocations?.[period]?.totalCost}
                                                            currency={currency}
                                                            data={charts?.TopFiveLocations?.[period]?.dataSet.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                        />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>No data found for Top 5 Locations</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>
                                                <div>
                                                    {ResourceGroup && ResourceGroup.length ? (
                                                        <ReactD3BarChart
                                                            mouseEffect={true}
                                                            title={"Top 5 ResourceGroups"}
                                                            cost={charts?.TopFiveResourceGroups?.[period]?.totalCost}
                                                            currency={currency}
                                                            data={ResourceGroup.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                        />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>No data found for Top 5 ResourceGroups</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>
                                            </SlideShow>
                                        </div>
                                        <div style={{ position: "absolute", right: 1 }}>
                                            <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                            </IconButton>
                                        </div>
                                    </div>
                                )}
                            </ContainerCardWithDateRange>
                        </Grid>
                        <Grid item xs={6}>
                            <ContainerCardWithDateRange
                                someref={
                                    {how: setDashboardComponentsRef, what:'billing3', where: dashboardComponentsRef}
                                }
                                showDateRange={true}
                                title={"Total spend"}
                                titleIcon={Total_Spend_Icon_New}
                                HeaderTitleComponent={(e, setCollapseClickable) => (
                                    <>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                            <span>Total spend</span>
                                            <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)}  onMouseOut={() => setCollapseClickable(true)} className={'pause-play-icon'}>
                                                <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                    <div onClick={() => pausePlaySlideRef?.current?.click()}>
                                                        {pausePlaySlideRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </>
                                )}
                                onPeriodChange={setPeriod}
                                // saveAsCSV={true}
                                // onSaveAsCSV={onSaveAsCSV}
                            >
                                {({ period }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            position: "relative",
                                        }}
                                    >
                                        <div style={{ position: "absolute", zIndex: 1 }}>
                                            <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                            </IconButton>
                                        </div>
                                        <div style={{ padding: 10 }}>
                                            <SlideShow names={["Spend By Category", "Spend by Compute", "Spend by Containers", "Spend by Kubernetes"]} arrowsHandled={true} showingIndex={chartShowingIndex} pausePlayRef={pausePlaySlideRef}>
                                                <div>
                                                    {charts?.SpendByCategory?.[period]?.dataSet ? (
                                                        <ReactD3BarChart
                                                            mouseEffect={true}
                                                            title={"Spend By Category"}
                                                            cost={charts?.SpendByCategory?.[period]?.totalCost}
                                                            currency={currency}
                                                            data={charts?.SpendByCategory?.[period]?.dataSet.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                        />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>No data found for Spend By Category</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>
                                                <div>
                                                    {azureCompute?.[period]?.resources?.length && computeData ? (
                                                        <ReactD3BarChart mouseEffect={true} title={"Spend by Compute"} cost={azureCompute?.[period]?.totalCost} currency={currency} data={computeData.sort((a, b) => b.value - a.value)} />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>{"No Compute found for " + period}</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>
                                                <div>
                                                    {azureContainers?.[period]?.resources?.length && containersData ? (
                                                        <ReactD3BarChart mouseEffect={true} title={"Spend by Containers"} cost={azureContainers?.[period]?.totalCost} currency={currency} data={containersData.sort((a, b) => b.value - a.value)} />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>{"No Containers found for " + period}</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>
                                                <div>
                                                    {azureKubernetes?.[period]?.resources?.length && kubernetesData ? (
                                                        <ReactD3BarChart mouseEffect={true} title={"Spend by Kubernetes"} cost={azureKubernetes?.[period]?.totalCost} currency={currency} data={kubernetesData.sort((a, b) => b.value - a.value)} />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>{"No Kubernetes found for " + period}</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>
                                            </SlideShow>
                                        </div>
                                        <div style={{ position: "absolute", right: 1 }}>
                                            <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                            </IconButton>
                                        </div>
                                    </div>
                                )}
                            </ContainerCardWithDateRange>
                        </Grid>
                    </Grid>
                    <br />
                    <div style={{ marginTop: 10 }}>
                        <ContainerCardWithDateRange
                            someref={
                                {how: setDashboardComponentsRef, what:'billing4', where: dashboardComponentsRef}
                            }
                            showDateRange={false}
                            title={"Spend by months"}
                            titleIcon={calendar_icon}
                            HeaderTitleComponent={(e, setCollapseClickable) => (
                                <>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                        <span>Spend by months</span>
                                        <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)}  onMouseOut={() => setCollapseClickable(true)} className={'pause-play-icon'}>
                                            <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                <div onClick={() => pausePlaySpendRef?.current?.click()}>
                                                    {pausePlaySpendRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </>
                            )}
                            // saveAsCSV={true}
                            // onSaveAsCSV={onSaveAsCSV}
                        >
                            {() => (
                                <div style={{}}>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: 10,
                                            paddingTop: 0,
                                            //  justifyContent: 'center'
                                        }}
                                    >
                                        <div style={{ position: "absolute", zIndex: 10, marginLeft: 5 }}>
                                            <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                            </IconButton>
                                        </div>
                                        <SlideShow
                                            pausePlayRef={pausePlaySpendRef}
                                            delay={TIMERS.LOWER_CHART_INTERVAL}
                                            showingIndex={chartShowingIndex}
                                            names={["Spend by Azure", "Top 5 Resource Groups", "Top 5 Resources"]}
                                            view="All"
                                            arrowsHandled={true}
                                        >
                                            {loadingCalendarTableData ? (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <CircularProgress thickness={2} size={80} />
                                                </div>
                                            ) : (
                                                <GroupedBarChart
                                                    maxWidth={null}
                                                    currency={Format.formatCurrencySymbol(currency)}
                                                    totalCost={null}
                                                    // mouseEffect={true}
                                                    data={azureMonthWiseChartData}
                                                    cloudCount={1}
                                                />
                                            )}
                                            <StackedBarChart2
                                                maxWidth={null}
                                                currency={Format.formatCurrencySymbol(currency)}
                                                totalCost={null}
                                                keyStack={"resourceGroups"}
                                                keyField={"resourceGroupName"}
                                                valueKey={"cost"}
                                                // mouseEffect={true}
                                                data={othersMonthWiseChartData}
                                                cloudCount={1}
                                            />
                                            <StackedBarChart2
                                                maxWidth={null}
                                                currency={Format.formatCurrencySymbol(currency)}
                                                totalCost={null}
                                                keyStack={"resources"}
                                                keyField={"resourceId"}
                                                valueKey={"cost"}
                                                // mouseEffect={true}
                                                data={othersMonthWiseChartData}
                                                cloudCount={1}
                                            />
                                        </SlideShow>
                                        <div style={{ position: "absolute", right: 30, zIndex: 10 }}>
                                            <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </ContainerCardWithDateRange>
                    </div>
                    <br/>
                    <Paper elevation={10} style={{ marginTop: 15 }}>
                        <div>
                            <div
                                className={"accordian_item1"}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <h5 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20 }}>
                                    <img src={CostAnalysisIcon} height={25} /> Actual Cost and Amortized Cost
                                </h5>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ marginRight: 4 }}>
                                        <span style={{ marginRight: 10 }}>{amortizedDatePeriod}</span>
                                        <b>
                                            {Format.formatCurrencySymbol(currency || "USD")}
                                            {Format.formatNumber(Number(totalAmortizeCost).toFixed(2))}
                                        </b>
                                    </div>
                                    <div>
                                        <Paper elevation={0}>
                                            <ButtonGroup style={{ outline: "none" }}>
                                                <Button
                                                    onClick={() => setShowDataFor("actual")}
                                                    style={{
                                                        outline: "none",
                                                        color: showDataFor !== "actual" ? "rgba(63, 81, 181, 0.86)" : "white",
                                                        backgroundColor: showDataFor === "actual" ? "rgba(63, 81, 181, 0.86)" : "white",
                                                    }}
                                                >
                                                    <Typography>Actual</Typography>
                                                </Button>
                                                <Button
                                                    onClick={() => setShowDataFor("amortized")}
                                                    style={{
                                                        outline: "none",
                                                        color: showDataFor !== "amortized" ? "rgba(63, 81, 181, 0.86)" : "white",
                                                        backgroundColor: showDataFor === "amortized" ? "rgba(63, 81, 181, 0.86)" : "white",
                                                    }}
                                                >
                                                    <Typography>Amortized</Typography>
                                                </Button>
                                            </ButtonGroup>
                                        </Paper>
                                    </div>
                                    <IconButton onClick={() => setAmortizedTableView(!amortizedTableView)} style={{ outline: "none" }}>
                                        {amortizedTableView ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        <Collapse in={amortizedTableView}>
                            <div style={{ padding: 10 }}>
                                {loadingAmortized ? (
                                    <LineProgress />
                                ) : actualAmortizedRawData && (actualCostData?.length || amortizedCostData?.length) ? (
                                    <CustomEnhancedTable
                                        currency={Format.formatCurrencySymbol(currency)}
                                        rows={showDataFor == "actual" ? actualCostData : amortizedCostData}
                                        headCells={["Name", "Resource type", "Resource group", "Location", "Cost"]}
                                    />
                                ) : (
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <span style={{}}>No data found </span>
                                    </div>
                                )}
                            </div>
                        </Collapse>
                    </Paper>
                    <div style={{ marginTop: 30, marginBottom: 20 }}>
                        <AzureResources somerefs={[
                            {how: setDashboardComponentsRef, what:'billing5', where: dashboardComponentsRef},
                        ]} tab={resTab} showData={rec} period={period} currency={Format.formatCurrencySymbol(currency)} customerId={customerId} />
                    </div>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {selectedSubscription.title == "All" ? <AzureSubscriptionsList somerefs={[
                                {how: setDashboardComponentsRef, what:'billing6', where: dashboardComponentsRef},
                            ]} tenantLogo={active_directory} currency={Format.formatCurrencySymbol(currency)} accountList={charts?.SpendByAccounts?.[period]?.dataSet || 0} /> : null}
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={TAB_SET.RESERVATIONS}>
                    <div>
                        <AzureReservationsSubscription tab={resTab} showData={rec} period={period} tenantId={rec.tenantId} subscriptionId={rec.subscriptionId} currency={Format.formatCurrencySymbol(currency)} progress={progress} />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.COMPUTE}>
                    <div>
                        <AzureCompute
                            tab={resTab}
                            showData={rec}
                            period={period}
                            currency={Format.formatCurrencySymbol(currency)}
                            progress={progress}
                            tenantId={selectedSubscription.tenantId}
                            subscriptionId={selectedSubscription.value}
                            subscriptionList={props.subscriptionList}
                            customerId={customerId}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.FUNCTION_APPS}>
                    <div>
                        <AzureFunctions
                            tab={resTab}
                            showData={rec}
                            period={period}
                            tenantId={selectedSubscription.tenantId}
                            curr={currency}
                            currency={Format.formatCurrencySymbol(currency)}
                            progress={progress}
                            subscriptionId={selectedSubscription.value}
                            customerId={customerId}
                        />
                    </div>
                </TabPanel>

                <TabPanel value={TAB_SET.COSMOS_DB}>
                    <div>
                        <AzureCosmosDB
                            tab={resTab}
                            showData={rec}
                            period={period}
                            currency={Format.formatCurrencySymbol(currency)}
                            progress={progress}
                            tenantId={selectedSubscription.tenantId}
                            subscriptionId={selectedSubscription.value}
                            subscriptionList={props.subscriptionList}
                            customerId={customerId}
                        />
                    </div>
                </TabPanel>
            </TabContext>
        </div>
    );
}

const styles = {
    tab: {
        outline: "none",
    },
    labelContainer: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        marginBottom: -10,
        marginLeft: -10,
    },

    icon: { width: 18, height: 18, marginRight: 4 },
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead style={{ backgroundColor: Color.tableHeader }}>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell> */}
                {props.headCells.map((headCell) => (
                    <TableCell style={{ fontWeight: "bold" }} key={headCell} sortDirection={orderBy === headCell ? order : false}>
                        {/* <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        > */}
                        {headCell}
                        {/* {orderBy === headCell ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null} */}
                        {/* </TableSortLabel> */}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

// EnhancedTableHead.propTypes = {
//     classes: PropTypes.object.isRequired,
//     numSelected: PropTypes.number.isRequired,
//     onRequestSort: PropTypes.func.isRequired,
//     onSelectAllClick: PropTypes.func.isRequired,
//     order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//     orderBy: PropTypes.string.isRequired,
//     rowCount: PropTypes.number.isRequired,
// };

const useToolbarStyles = makeStyles()((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === "light"
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    title: {
        flex: "1 1 100%",
    },
}));

// const EnhancedTableToolbar = (props) => {
//     const {classes} = useToolbarStyles();
//     const { numSelected } = props;

//     return (
//         <Toolbar
//             className={clsx(classes.root, {
//                 [classes.highlight]: numSelected > 0,
//             })}
//         >
//             {/* {numSelected > 0 ? (
//                 <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
//                     {numSelected} selected
//                 </Typography>
//             ) : (
//                 <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
//                     Nutrition
//                 </Typography>
//             )} */}

//             {/* {numSelected > 0 ? (
//                 <Tooltip title="Delete">
//                     <IconButton aria-label="delete">
//                         <DeleteIcon />
//                     </IconButton>
//                 </Tooltip>
//             ) : (
//                 <Tooltip title="Filter list">
//                     <IconButton aria-label="filter list">
//                         <FilterListIcon />
//                     </IconButton>
//                 </Tooltip>
//             )} */}
//         </Toolbar>
//     );
// };

const useStyles2 = makeStyles()((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

export function CustomEnhancedTable({ rows = [], headCells = [], currency }) {
    const {classes} = useStyles2();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const dense = true;
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table className={classes.table} aria-labelledby="tableTitle" size={dense ? "small" : "medium"} aria-label="enhanced table">
                        <EnhancedTableHead classes={classes} numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={rows.length} headCells={headCells} />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    console.log("row", row.resourceType[0].split("/")[0]);
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            // onClick={(event) => handleClick(event, row.name)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.name}
                                            selected={isItemSelected}
                                        >
                                            {/* <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell> */}
                                            <TableCell component="th" id={labelId} scope="row" padding="none" style={{ paddingLeft: 20 }}>
                                                {row.resourceType[0].split("/")[0] == "microsoft.azureactivedirectory" && <img src={Adb2c} width={40} height={35} />}
                                                {row.resourceType[0].split("/")[0] == "workspaces" && <img src={WorkSpaces} width={25} height={20} style={{ marginRight: 5, marginLeft: 8 }} />}
                                                {row.resourceType[0].split("/")[0] == "serverfarms" && <img src={ServerFarms} width={25} height={20} style={{ marginRight: 5, marginLeft: 10 }} />}
                                                {row.resourceType[0].split("/")[0] == "loadbalancers" && <img src={LoadBalance} width={40} height={35} />}
                                                {row.resourceType[0].split("/")[0] == "publicipaddresses" && <img src={PublicIpAddress} width={40} height={35} />}
                                                {row.resourceType[0].split("/")[0] == "redis" && <img src={Redis} width={40} height={35} />}
                                                {row.resourceType[0].split("/")[0] == "disks" && <img src={Disk} width={35} height={25} style={{ marginRight: 5, marginLeft: 4 }} />}
                                                {row.resourceType[0].split("/")[0] == "namespaces" && <img src={NameSpace} width={25} height={20} style={{ marginRight: 5, marginLeft: 10 }} />}
                                                {row.resourceType[0].split("/")[0] == "iothubs" && <img src={IotHub} width={40} height={35} />}
                                                {row.resourceType[0].split("/")[0] == "applicationgateways" && <img src={ApplicationGateway} width={40} height={35} />}
                                                {row.resourceType[0].split("/")[0] == "profiles" && <img src={Profile} width={40} height={35} />}
                                                {row.resourceType[0].split("/")[0] == "vaults" && <img src={KeyValut} width={40} height={35} />}
                                                {row.resourceType[0].split("/")[0] == "microsoft.operationalinsights" && <img src={VirtualMachineScalable} width={40} height={35} />}
                                                {row.resourceType[0].split("/")[0] == "virtualmachines" && <img src={VirtualMachine} width={40} height={35} />}
                                                {row.resourceType[0].split("/")[0] == "workflows" && <img src={WorkFlow} width={20} height={20} style={{ marginRight: 5, marginLeft: 10 }} />}
                                                {row.resourceType[0].split("/")[0] == "microsoft.storage" && <img src={Storage} width={40} height={35} />}
                                                {row.resourceType[0].split("/")[0] == "applicationinsights" && <img src={LogAnalytics} width={40} height={35} />}
                                                {row.resourceType[0].split("/")[0] == "appservice" && <img src={AppServices} width={40} height={35} />}
                                                {row.resourceType[0].split("/")[0] == "microsoft.apimanagement" && <img src={AzureAppCervices} width={40} height={35} />}
                                                {row.resourceType[0].split("/")[0] == "microsoft.documentdb" && <img src={CosmosDB} width={40} height={35} />}
                                                {row.resourceType[0].split("/")[0] == "microsoft.insights" && <img src={Function} width={40} height={35} />}
                                                {row.resourceType[0].split("/")[0] == "microsoft.web" && <img src={Function} width={40} height={35} />}
                                                {row.resourceType[0].split("/")[0] == "microsoft.logic" && <img src={LogicApps} width={20} height={20} style={{ marginRight: 5, marginLeft: 10 }} />}
                                                {row.resourceType[0].split("/")[0] == "microsoft.notificationhubs" && <img src={NotificationHubs} width={20} height={20} style={{ marginRight: 5, marginLeft: 10 }} />}

                                                {row.name}
                                            </TableCell>
                                            <TableCell>{row.resourceType.join(", ")}</TableCell>
                                            <TableCell>{row.resourceGroup.join(", ")}</TableCell>
                                            <TableCell>{row.location.join(", ")}</TableCell>
                                            <TableCell>
                                                {currency}
                                                {Format.formatNumber(row.cost)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 75]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </Paper>
        </div>
    );
}

function transformCostData(rawData) {
    var raw = rawData;
    const final = [];
    if (raw && raw.length) {
        raw = raw.map(([cost, date, s, resourceId, location, resourceGroup]) => {
            var name = resourceId.split("providers").reverse()[0];
            var resourceType = name.split("/")?.[1] + "/" + name.split("/")?.[2];
            name = name.split("/").reverse()[0];
            return { cost, date: +new Date(date), location, resourceGroup, name, resourceType };
        });
        raw = raw.filter((a) => new Date(a.date).getMonth() === new Date().getMonth() && new Date(a.date).getFullYear() === new Date().getFullYear());
        var resources = Array.from(new Set(raw.map((a) => a.name)));
        resources.map((name) => {
            var data = raw.filter((a) => a.name === name);
            data = data.sort((d, e) => d.date - e.date);
            var date = [data?.[0].date, data?.[data.length - 1].date];
            final.push({
                name,
                date,
                cost: data.map((d) => d.cost).reduce((a, b) => a + b, 0),
                location: Array.from(new Set(data.map((d) => d.location))),
                resourceGroup: Array.from(new Set(data.map((d) => d.resourceGroup))),
                resourceType: Array.from(new Set(data.map((d) => d.resourceType))),
            });
        });
    }
    return final.sort((a, b) => b.cost - a.cost);
}
