import React, { useState, useEffect } from "react";
//import "./style.css";
import { makeStyles, withStyles } from "MakeWithStyles";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Format from "components/NumberFormat";
import colors from "styles/color";

import aws_org from "../../images/aws_org.svg";
import aws_sub from "../../images/aws_sub.svg";
import AmazonRDS from "../../images/awsAccounts/Amazon-RDS.svg";
import AwsELC from "../../images/awsAccounts/Amazon-ElastiCache_light-bg.svg";
import AwsEC2 from "../../images/awsAccounts/Amazon-EC2.svg";
import AmazonS3 from "../../images/awsAccounts/Amazon-Simple-_Storage-Service-S3.svg";
import AmazonRoute53 from "../../images/awsAccounts/Amazon-Route-53.svg";
import AmazonCloudFront from "../../images/awsAccounts/Amazon-CloudFront_light-bg.svg";
import AmazonApiGateway from "../../images/awsAccounts/Amazon-API-Gateway.svg";
import AWSSecretmanager from "../../images/awsAccounts/AWS-Secrets-Manager.svg";
import AWSConfig from "../../images/awsAccounts/AWS-Config.svg";
import AmazonCloudWatch from "../../images/awsAccounts/Amazon-CloudWatch_Alarm_light-bg.svg";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "../TablePaginationActions";
import TableSearch from "../TableSearch";
import {OverlayTrigger, Popover} from "react-bootstrap";

const StyledTableCell = withStyles(TableCell,(theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: theme.spacing(1.5),
    },
}));
const StyledTableRow = withStyles(TableRow,(theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            // backgroundColor: theme.palette.action.hover,
        },
    },
}));
const useRowStyles = makeStyles()({
    root: {
        "& > *": {
            borderBottom: "unset",
            padding: 3.5,
        },
    },
    table: {
        minWidth: 700,
    },
    container: {
        maxHeight: 440,
    },
    container1: {
        maxHeight: 400,
    },
});

export default function AwsSpendingTable({ tableRow, data, currency }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const {classes} = useRowStyles();
    const [search, setSearch] = useState(null);

    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.usageAccountId,
                f?.payerAccountId,
                f?.usageAccountName,
            ], search);
        }
        return true;
    }

    return (
        <Paper elevation={0}>
            <TableSearch onSearch={setSearch} />
            <TableContainer style={{ height: 350 }}className={classes.container}>
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell />
                            <StyledTableCell align="inherit" style={{ fontSize: 17, fontWeight: "bold" }}>
                                Linked account
                            </StyledTableCell>
                            <StyledTableCell align="center" style={{ fontSize: 17, fontWeight: "bold" }}>
                                Management (Payer) account
                            </StyledTableCell>

                            <StyledTableCell align="center" style={{ fontSize: 17, fontWeight: "bold" }}>
                                Cost
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableRow
                            ?.filter(filteredTable)
                            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((r, i) => (
                            <ExpandRowRG row={r} index={i} data={data} currency={currency} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={tableRow?.filter(filteredTable)?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
        </Paper>
    );
}

function ExpandRowRG(props) {
    const { row, index, data, currency } = props;
    const [open, setOpen] = useState(false);
    const [list, setList] = useState(null);
    const {classes} = useRowStyles();

    return (
        <React.Fragment>
            <StyledTableRow hover className={classes.root} key={row.resourceId}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell component="th" scope="row" size="small" padding="none">
                    {<img src={aws_org} width={18} height={18} style={{ marginRight: 5 }} />}
                   
                    {"usageAccountName" in row ?
                        <OverlayTrigger placement="right" overlay={<Popover id="popover-contained">{"usageAccountName" in row &&
                         <Popover.Body style={{ fontSize: 11 }}>{row?.usageAccountName}</Popover.Body>}</Popover>}>
                           <span style={{whiteSpace: 'nowrap'}}>
                            {row.usageAccountId}
                        </span>
                        </OverlayTrigger>
                        :row?.usageAccountId||data?.usageAccountId}

                </TableCell>
                <TableCell align="center">{row.payerAccountId}</TableCell>

                <TableCell align="center">{currency + Format.formatNumber(Number(row.cost).toFixed(2))}</TableCell>
            </StyledTableRow>
            <ExpandLavelDataRG data={data} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}
function ExpandLavelDataRG({ data, open, select, currency }) {
    const [resource, setResource] = useState(null);

    useEffect(() => {
        if (select != null) {
            let value = data.accounts[select];
            setResource(value);
        }
    }, [select, data]);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={4}>{resource && <ExpandableTableAws tableRow={resource.services} data={resource} currency={currency} />}</Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function ExpandableTableAws({ tableRow, data, currency }) {

    const {classes} = useRowStyles();
    const [search, setSearch] = useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.resourceId,
                f?.serviceCode,
                f?.serviceName,
                f?.location,
                f?.usageAccountId,
                f?.usageAccountName,
            ], search);
        }
        return true;
    }

    return (
        <Paper elevation={0}>
            <TableSearch onSearch={setSearch} />
            <TableContainer className={classes.container}>
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell />
                            <StyledTableCell align="inherit" style={{ fontSize: 17, fontWeight: "bold" }}>
                                Resource name
                            </StyledTableCell>
                          
                            <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                Service 
                            </StyledTableCell>

                            <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                Location
                            </StyledTableCell>
                            <StyledTableCell align="center" style={{ fontSize: 17, fontWeight: "bold" }}>
                                Cost
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableRow
                            ?.filter(filteredTable)
                            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((r, i) => (
                                <ExpandRow row={r} index={i} data={data} currency={currency} table={"accounts"} />
                            ))}
                        {/*{tableRow?.map((r, i) => (*/}
                        {/*    */}
                        {/*))}*/}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 75]}
                component="div"
                count={tableRow?.filter(filteredTable)?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
        </Paper>
    );
}

export function ExpandRow(props) {
    const { view, row, index, data, currency, table } = props;
    const [open, setOpen] = useState(false);
    const [list, setList] = useState(null);
    const {classes} = useRowStyles();

    // useEffect(() => {
    //  console.log("index == ",index);
    // }, [index])

    return (
        <React.Fragment>
            <StyledTableRow hover className={classes.root} key={row.resourceId}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell>
                    <div>
                        {row.serviceCode == "AmazonRDS" && <img src={AmazonRDS} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.serviceCode == "AWSELB" && <img src={AwsELC} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.serviceCode == "AmazonEC2" && <img src={AwsEC2} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.serviceCode == "AmazonS3" && <img src={AmazonS3} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.serviceCode == "AmazonRoute53" && <img src={AmazonRoute53} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.serviceCode == "AmazonCloudFront" && <img src={AmazonCloudFront} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.serviceCode == "AmazonApiGateway" && <img src={AmazonApiGateway} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.serviceCode == "AWSSecretsManager" && <img src={AWSSecretmanager} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.serviceCode == "AWSConfig" && <img src={AWSConfig} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.serviceCode == "AmazonCloudWatch" && <img src={AmazonCloudWatch} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceId || <span style={{ color: colors.gray }}>{row?.meter?.[0]?.productFamily}</span>}
                    </div>
                </TableCell>

                <TableCell align="left">{"serviceName" in row ? row.serviceName : row.serviceCode}</TableCell>

                <TableCell align="left">{row.location}</TableCell>

                {view == "all" ? (
                    <TableCell align="left">
                         {"usageAccountName" in row ?
                        <OverlayTrigger placement="right" overlay={<Popover id="popover-contained">{"usageAccountName" in row &&
                         <Popover.Body style={{ fontSize: 11 }}>{row?.usageAccountName}</Popover.Body>}</Popover>}>
                           <span style={{whiteSpace: 'nowrap'}}>
                            {row.usageAccountId}
                        </span>
                        </OverlayTrigger>
                        :row?.usageAccountId}
                    </TableCell>
                ) : null}
                <TableCell align="center">{currency + Format.formatNumber(Number(row["cost"]).toFixed(2))}</TableCell>
            </StyledTableRow>
            <ExpandLavelData data={row} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

export function ExpandLavelData({ data, open, select, set, currency }) {
    const [resource, setResource] = useState(null);

    // useEffect(() => {
    //     if (select != null) {
    //         let value = data.services[select];
    //         setResource(value);
    //     }
    // }, [select, data]);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2.5}>{data && <Htmltable data={data} currency={currency} />}</Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <div style={{ maxHeight: 300 }} className="overflow-auto">
            <table id="customers">
                <tr>
                    <th>Operation</th>
                    <th>Product family</th>
                    <th>Usage type</th>
                    <th>Description</th>
                    <th>Cost</th>
                </tr>

                {data &&
                    data.meter.map((m) => (
                        <tr>
                            <td>{m.operation}</td>
                            <td>{m.productFamily}</td>
                            <td>{m.lineItemUsageType}</td>
                            <td>{m.lineItemDescription}</td>
                            <td>{currency + Format.formatNumber(Number(m["cost"]))}</td>
                        </tr>
                    ))}
            </table>
        </div>
    );
}
