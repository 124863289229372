import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getBudgetsSuccess, getBudgetsFailure } from "../actions/actions";
const { REACT_APP_GETBUDGETS } = process.env;

function* getBudgetsData({ payload }) {
  const { customerId, all } = payload;
  try {
    const res = yield call(axios.get, REACT_APP_GETBUDGETS, {
      params: {
        customerID: customerId,
        budgetLevel: all,
      },
    });

    const { status } = res;
    //  yield put(getBudgetsSuccess(res.data));
    if ("status" in res) {
      if (status !== 200) {
        yield put(getBudgetsFailure(status));
      } else {
        yield put(getBudgetsSuccess(res));
      }
    } else {
      yield put(getBudgetsFailure(res));
    }
  } catch (error) {
    // console.log("error", error);
    yield put(getBudgetsFailure(error));
  }
}

export default function* getBudgetSaga() {
  yield takeLatest(types.GET_BUDGETS_REQUEST, getBudgetsData);
}
