import * as types from "../actions/types";

const userState = {
    AWSCCKdata: null,
    AWSContainersData: null,
    AWSKubernetesData: null,
    error: null,
    AWSCCKSubData: null,
    AWSContainersSubData: null,
    AWSKubernetesSubData: null,

    AWSCCKloading:false,
    AWSContainersloading:false,
    AWSKubernetesloading:false,
};

function AWSCCKReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.AWS_CCK_REQUEST: {
            return {
                ...state,
                AWSCCKloading:true,
                AWSContainersloading:true,
                AWSKubernetesloading:true,
            };
        }
        case types.AWS_CCK_SUCCESS: {
            const data = {};

            if (payload.dataType == "compute") {
                data["AWSCCKdata"] = payload.data.data;
                data["AWSCCKloading"] = false
            } else if (payload.dataType == "containers") {
                data["AWSContainersData"] = payload.data.data;
                data["AWSContainersloading"] = false
            } else if (payload.dataType == "kubernetes") {
                data["AWSKubernetesData"] = payload.data.data;
                data["AWSKubernetesloading"] = false
            }

            return {
                ...state,
                ...data,
            };
        }
        case types.AWS_CCK_FAILURE: {
            return {
                ...state,
                AWSCCKloading:false,
                AWSContainersloading:false,
                AWSKubernetesloading:false, 
                AWSCCKdata: null,
                AWSContainersData: null,
                AWSKubernetesData: null,
                error: payload,
            };
        }

        // ---------------AWS CCK Subscription level-------------------------

        case types.AWS_CCK_SUB_REQUEST: {
            return {
                ...state,
                AWSCCKloading:true,
                AWSContainersloading:true,
                AWSKubernetesloading:true,
            };
        }
        case types.AWS_CCK_SUB_SUCCESS: {
            const data = {};

            if (payload.dataType == "compute") {
                data["AWSCCKSubData"] = payload.data.data;
                data["AWSCCKloading"] = false

            } else if (payload.dataType == "containers") {
                data["AWSContainersSubData"] = payload.data.data;
                data["AWSContainersloading"] = false

            } else if (payload.dataType == "kubernetes") {
                data["AWSKubernetesSubData"] = payload.data.data;
                data["AWSKubernetesloading"] = false

            }
            return {
                ...state,
                ...data,
                loading: false,
            };
        }
        case types.AWS_CCK_SUB_FAILURE: {
            return {
                ...state,
                AWSCCKloading:false,
                AWSContainersloading:false,
                AWSKubernetesloading:false, 
                AWSCCKSubData: null,
                AWSContainersSubData: null,
                AWSKubernetesSubData: null,
                error: payload,
            };
        }
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                AWSCCKdata: null,
                AWSContainersData: null,
                AWSKubernetesData: null,
                error: null,
                AWSCCKSubData: null,
                AWSContainersSubData: null,
                AWSKubernetesSubData: null,
            };
        }

        default:
            return state;
    }
}
export default AWSCCKReducer;
