import React, { createRef, useEffect, useRef, useState } from "react";
import ConnectAWSAccount from "./aws/ConnectAWSAccount";
import ListAWSAccount from "./aws/ListAWSAccount";
import { Button, Carousel } from "react-bootstrap";

const { REACT_APP_AWS_TENANTS } = process.env;

export default function AWSAccount() {
  const [nowShowing, setNowShowing] = useState(0);
  const [shouldReload, setShouldReload] = useState(false);
  const [editData, setEditData] = useState(null);
  const [connectionMode, setConnectionMode] = useState("connect");

  function onConnectionSuccess() {
    setNowShowing(0);
    setShouldReload(!shouldReload);
  }

  function onConnect() {
    setEditData(null);
    setConnectionMode("connect");
    setNowShowing(1);
  }

  function onEdit(e) {
    setEditData(e);
    setConnectionMode("edit");
    setNowShowing(1);
  }

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#connect") {
      onConnect();
    }
  }, []);
  return (
    <div>
      <Carousel
        className={"awsCarousel"}
        activeIndex={nowShowing}
        keyboard={false}
        touch={false}
        interval={null}
        fade={true}
        controls={false}
        indicators={false}
      >
        <Carousel.Item>
          <ListAWSAccount
            onEdit={onEdit}
            onConnect={onConnect}
            shouldReload={shouldReload}
            hasHeader={true}
          />
        </Carousel.Item>
        <Carousel.Item>
          <ConnectAWSAccount
            onConnectionSuccess={onConnectionSuccess}
            onBack={() => setNowShowing(0)}
            editData={editData}
            connectionMode={connectionMode}
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
