import { takeLatest, takeEvery, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import {
  azureRecommendationSuccess,
  azureRecommendationFail,
  getAzureResourcesSuccess,
  getAzureResourcesFailure,
  getAzureResourceGroupsSuccess,
  getAzureResourceGroupsFailure,
  getAzureTenantResourcesSuccess,
  getAzureTenantResourcesFailure,
  getAzureTenantResourceGroupsSuccess,
  getAzureTenantResourceGroupsFailure,
  getAzureCosmosDBRecommendationsFailure,
  getAzureCosmosDBRecommendationsSuccess,
  getAzureDBServerRecommendationsFailure,
  getAzureDBServerRecommendationsSuccess,
  getAzureFunctionAppRecommendationsFailure,
  getAzureFunctionAppRecommendationsSuccess,
  getAzureIdleVMRecommendationsFailure,
  getAzureIdleVMRecommendationsSuccess,
  getAzureSQLManagedInstanceRecommendationsFailure,
  getAzureSQLManagedInstanceRecommendationsSuccess,
  getAzureStoppedVMAllRecommendationsRequest,
  getAzureStoppedVMAllRecommendationsFailure,
  getAzureStoppedVMAllRecommendationsSuccess,
  getAzureStorageRecommendationsFailure,
  getAzureStorageRecommendationsSuccess,
  getAzureVMMigrateRecommendationsFailure,
  getAzureVMMigrateRecommendationsSuccess,
  getAzureVMRightsizingRecommendationsFailure,
  getAzureVMRightsizingRecommendationsSuccess
} from "../actions/actions";
const {
  REACT_APP_AZURE_RECOMMENDATION,
  REACT_APP_AZURE_ALL_RECOMMENDATION,
  REACT_APP_AZURE_RESOURCES_ALL,
  REACT_APP_AZURE_RESOURCES_SUBSCRIPTION,
  REACT_APP_AZURE_RESOURCE_GROUPS_ALL,
  REACT_APP_AZURE_RESOURCE_GROUPS_SUBSCRIPTION,
  REACT_APP_AZURE_SUBSCRIPTION_STOPPED_VM_RECOMMENDATIONS,
  REACT_APP_AZURE_TENANT_RESOURCES,
  REACT_APP_AZURE_TENANT_RESOURCE_GROUP,



    REACT_APP_AZURE_COSMOSDB_RECOMMENDATION,
    REACT_APP_AZURE_DBSERVER_RECOMMENDATION,
    REACT_APP_AZURE_FUNCTION_APP_RECOMMENDATION,
    REACT_APP_AZURE_IDLE_VM_RECOMMENDATION,
    REACT_APP_AZURE_SQL_MANAGED_INSTANCE_RECOMMENDATION,
    REACT_APP_AZURE_STOPPED_VM_RECOMMENDATION,
    REACT_APP_AZURE_STORAGE_RECOMMENDATION,
    REACT_APP_AZURE_VM_MIGRATE_RECOMMENDATION,
    REACT_APP_AZURE_VM_RIGHTSIZING_RECOMMENDATION,
    REACT_APP_AZURE_TENANT_VM_RIGHTSIZING_RECOMMENDATION,
    REACT_APP_AZURE_SUB_VM_RIGHTSIZING_RECOMMENDATION,

  REACT_APP_NEW_SUBSCRIPTION_KEY,
  REACT_APP_NEW_SUBSCRIPTION_KEY_2,

  REACT_APP_AZURE_TENANT_IDLE_VM_RECOMMENDATION,
  REACT_APP_AZURE_SUBSCRIPTION_IDLE_VM_RECOMMENDATION
} = process.env;
function* getAzureRecommendationData({ payload }) {
  try {
    const res = yield call(axios.get, REACT_APP_AZURE_RECOMMENDATION, {
      params: {
        customerId: payload.customerId,
        tenantId: payload.tenantId,
        subscriptionId: payload.subscriptionId,
      },
    });

    const {
      data: { statusCode },
    } = res;
    if ("statusCode" in res.data) {
      if (statusCode !== 200) {
        yield put(azureRecommendationFail(statusCode));
      } else {
        yield put(azureRecommendationSuccess(res.data));
      }
    } else {
      yield put(azureRecommendationFail(res.data));
    }
  } catch (error) {
    yield put(azureRecommendationFail(error));
  }
}

function* getAzureHomeRecommendationData({ payload }) {
  try {
    const res = yield call(axios.get, REACT_APP_AZURE_ALL_RECOMMENDATION, {
      params: {
        customerId: payload,
      },
    });

    const {
      data: { statusCode },
    } = res;
    if ("statusCode" in res.data) {
      if (statusCode !== 200) {
        yield put({
          type: types.AZURE_HOME_RECOMMENDATION_FAILED,
          payload: statusCode,
        });
      } else {
        yield put({
          type: types.AZURE_HOME_RECOMMENDATION_SUCCESS,
          payload: res.data,
        });
      }
    } else {
      yield put({
        type: types.AZURE_HOME_RECOMMENDATION_FAILED,
        payload: res.data,
      });
    }
  } catch (error) {
    yield put({ type: types.AZURE_HOME_RECOMMENDATION_FAILED, payload: error });
  }
}

function* getAzureResourcesData({ payload }) {
  if (typeof payload === "object") {
    try {
      const res = yield call(
        axios.get,
        REACT_APP_AZURE_RESOURCES_SUBSCRIPTION,
        {
          params: {
            customerId: payload.customerId,
            tenantId: payload.tenantId,
            subscriptionId: payload.subscriptionId,
          },
        }
      );

      const {
        data: { statusCode },
      } = res;
      if ("statusCode" in res.data) {
        if (statusCode !== 200) {
          yield put(getAzureResourcesFailure(statusCode));
        } else {
          yield put(getAzureResourcesSuccess(res.data));
        }
      } else {
        yield put(getAzureResourcesFailure(res.data));
      }
    } catch (error) {
      yield put(getAzureResourcesFailure(error));
    }
  } else {
    try {
      const res = yield call(axios.get, REACT_APP_AZURE_RESOURCES_ALL, {
        params: {
          customerId: payload,
        },
      });

      const {
        data: { statusCode },
      } = res;
      if ("statusCode" in res.data) {
        if (statusCode !== 200) {
          yield put(getAzureResourcesFailure(statusCode));
        } else {
          yield put(getAzureResourcesSuccess(res.data));
        }
      } else {
        yield put(getAzureResourcesFailure(res.data));
      }
    } catch (error) {
      yield put(getAzureResourcesFailure(error));
    }
  }
}

function* getAzureResourceGroupsData({ payload }) {
  if (typeof payload === "object") {
    try {
      const res = yield call(
        axios.get,
        REACT_APP_AZURE_RESOURCE_GROUPS_SUBSCRIPTION,
        {
          params: {
            customerId: payload.customerId,
            tenantId: payload.tenantId,
            subscriptionId: payload.subscriptionId,
          },
        }
      );

      const {
        data: { statusCode },
      } = res;
      if ("statusCode" in res.data) {
        if (statusCode !== 200) {
          yield put(getAzureResourceGroupsFailure(statusCode));
        } else {
          yield put(getAzureResourceGroupsSuccess(res.data));
        }
      } else {
        yield put(getAzureResourceGroupsFailure(res.data));
      }
    } catch (error) {
      yield put(getAzureResourceGroupsFailure(error));
    }
  } else {
    try {
      const res = yield call(axios.get, REACT_APP_AZURE_RESOURCE_GROUPS_ALL, {
        params: {
          customerId: payload,
        },
      });

      const {
        data: { statusCode },
      } = res;
      if ("statusCode" in res.data) {
        if (statusCode !== 200) {
          yield put(getAzureResourceGroupsFailure(statusCode));
        } else {
          yield put(getAzureResourceGroupsSuccess(res.data));
        }
      } else {
        yield put(getAzureResourceGroupsFailure(res.data));
      }
    } catch (error) {
      yield put(getAzureResourceGroupsFailure(error));
    }
  }
}

function* getAzureTenantResourcesData({ payload }){
 // console.log("tenat resources",payload);
  try {
    const res = yield call(
      axios.get,
      REACT_APP_AZURE_TENANT_RESOURCES,
      {
        params: {
          customerId: payload.customerId,
          tenantId: payload.tenantId,
        },
      }
    );

    const {
      data: { statusCode },
    } = res;
    if ("statusCode" in res.data) {
      if (statusCode !== 200) {
        yield put(getAzureTenantResourcesFailure(statusCode));
      } else {
        yield put(getAzureTenantResourcesSuccess(res.data));
      }
    } else {
      yield put(getAzureTenantResourcesFailure(res.data));
    }
  } catch (error) {
    yield put(getAzureTenantResourcesFailure(error));
  }
}
function* getAzureTenantResourceGroupsData({ payload }){
  try {
    const res = yield call(
      axios.get,
      REACT_APP_AZURE_TENANT_RESOURCE_GROUP,
      {
        params: {
          customerId: payload.customerId,
          tenantId: payload.tenantId,
        },
      }
    );

    const {
      data: { statusCode },
    } = res;
    if ("statusCode" in res.data) {
      if (statusCode !== 200) {
        yield put(getAzureTenantResourceGroupsFailure(statusCode));
      } else {
        yield put(getAzureTenantResourceGroupsSuccess(res.data));
      }
    } else {
      yield put(getAzureTenantResourceGroupsFailure(res.data));
    }
  } catch (error) {
    yield put(getAzureTenantResourceGroupsFailure(error));
  }
}


function* getAzureSubVMRecommendation({ payload }) {
  try {
    const res = yield call(axios.get, REACT_APP_AZURE_SUBSCRIPTION_STOPPED_VM_RECOMMENDATIONS, {
      params: {
        customerId: payload?.customerId,
        tenantId: payload?.tenantId,
        subscriptionId: payload?.subscriptionId
      },
    });

    const {
      data: { statusCode },
    } = res;
    if ("statusCode" in res.data) {
      if (statusCode !== 200) {
        yield put({
          type: types.AZURE_SUB_STOPPED_VM_RECOMMENDATION_FAILED,
          payload: statusCode,
        });
      } else {
        yield put({
          type: types.AZURE_SUB_STOPPED_VM_RECOMMENDATION_SUCCESS,
          payload: res.data,
        });
      }
    } else {
      yield put({
        type: types.AZURE_SUB_STOPPED_VM_RECOMMENDATION_FAILED,
        payload: res.data,
      });
    }
  } catch (error) {
    yield put({ type: types.AZURE_SUB_STOPPED_VM_RECOMMENDATION_FAILED, payload: error });
  }
}

export default function* azureRecommendationSaga() {
  yield takeLatest(
    types.AZURE_RECOMMENDATION_REQUEST,
    getAzureRecommendationData
  );
  yield takeLatest(
    types.AZURE_HOME_RECOMMENDATION_REQUEST,
    getAzureHomeRecommendationData
  );
  yield takeLatest(
      types.AZURE_RESOURCES_REQUEST,
      getAzureResourcesData
  );
  yield takeLatest(
    types.AZURE_RESOURCE_GROUPS_REQUEST,
    getAzureResourceGroupsData
  );
  //--------------- Tenant Resource & RG------------------------
  yield takeLatest(
    types.AZURE_TENANT_RESOURCES_REQUEST,
    getAzureTenantResourcesData
);
yield takeLatest(
  types.AZURE_TENANT_RESOURCE_GROUPS_REQUEST,
  getAzureTenantResourceGroupsData
);
  //------------------------------------------------------------
  yield takeLatest(
    types.AZURE_SUB_STOPPED_VM_RECOMMENDATION_REQUEST,
    getAzureSubVMRecommendation
  );

  /** Latest **/
  yield takeLatest(
      types.AZURE_COSMOSDB_RECOMMENDATION_REQUEST,
      getAzureCosmosDBRecommendations
    );
  yield takeLatest(
      types.AZURE_DBSERVER_RECOMMENDATION_REQUEST,
      getAzureDBServerRecommendations
    );
  yield takeLatest(
      types.AZURE_FUNCTION_APP_RECOMMENDATION_REQUEST,
      getAzureFunctionAppRecommendations
    );
  yield takeLatest(
      types.AZURE_IDLE_VM_RECOMMENDATION_REQUEST,
      getAzureIdleVMRecommendations
    );
  yield takeLatest(
      types.AZURE_SQL_MANAGED_INSTANCE_RECOMMENDATION_REQUEST,
      getAzureSQLManagedInstanceRecommendations
    );
  yield takeLatest(
      types.AZURE_STOPPED_VM_RECOMMENDATION_ALL_REQUEST,
      getAzureStoppedVMAllRecommendations
    );
  yield takeLatest(
      types.AZURE_STORAGE_RECOMMENDATION_REQUEST,
      getAzureStorageRecommendations
    );
  yield takeLatest(
      types.AZURE_VM_MIGRATE_RECOMMENDATION_REQUEST,
      getAzureVMMigrateRecommendations
    );
  yield takeLatest(
      types.AZURE_VM_RIGHTSIZING_RECOMMENDATION_REQUEST,
      getAzureVMRightsizingRecommendations
    );
}




/** ============================================================================================================== **/

function* getAzureCosmosDBRecommendations({ payload }) {
    try {
      const res = yield call(axios.get, REACT_APP_AZURE_COSMOSDB_RECOMMENDATION, {
        headers: {
          "Ocp-Apim-Subscription-Key": REACT_APP_NEW_SUBSCRIPTION_KEY,
        },
        params: {
          customerId: payload,
        },
      });

      const {
        data: { statusCode },
      } = res;
      if ("statusCode" in res.data) {
        if (statusCode !== 200) {
          yield put(getAzureCosmosDBRecommendationsFailure(statusCode));
        } else {
          yield put(getAzureCosmosDBRecommendationsSuccess(res.data));
        }
      } else {
        yield put(getAzureCosmosDBRecommendationsFailure(res.data));
      }
    } catch (error) {
      yield put(getAzureCosmosDBRecommendationsFailure(error));
    }
}

function* getAzureDBServerRecommendations({ payload }) {
    try {
      const res = yield call(axios.get, REACT_APP_AZURE_DBSERVER_RECOMMENDATION, {
        headers: {
          "Ocp-Apim-Subscription-Key": REACT_APP_NEW_SUBSCRIPTION_KEY,
        },
        params: {
          customerId: payload,
        },
      });

      const {
        data: { statusCode },
      } = res;
      if ("statusCode" in res.data) {
        if (statusCode !== 200) {
          yield put(getAzureDBServerRecommendationsFailure(statusCode));
        } else {
          yield put(getAzureDBServerRecommendationsSuccess(res.data));
        }
      } else {
        yield put(getAzureDBServerRecommendationsFailure(res.data));
      }
    } catch (error) {
      yield put(getAzureDBServerRecommendationsFailure(error));
    }
}

function* getAzureFunctionAppRecommendations({ payload }) {
    try {
      const res = yield call(axios.get, REACT_APP_AZURE_FUNCTION_APP_RECOMMENDATION, {
        headers: {
          "Ocp-Apim-Subscription-Key": REACT_APP_NEW_SUBSCRIPTION_KEY,
        },
        params: {
          customerId: payload,
        },
      });

      const {
        data: { statusCode },
      } = res;
      if ("statusCode" in res.data) {
        if (statusCode !== 200) {
          yield put(getAzureFunctionAppRecommendationsFailure(statusCode));
        } else {
          yield put(getAzureFunctionAppRecommendationsSuccess(res.data));
        }
      } else {
        yield put(getAzureFunctionAppRecommendationsFailure(res.data));
      }
    } catch (error) {
      yield put(getAzureFunctionAppRecommendationsFailure(error));
    }
}

function* getAzureIdleVMRecommendations({ payload }) {
    try {
      const res = yield call(axios.get,
          payload.level == 'tenant'?
          REACT_APP_AZURE_TENANT_IDLE_VM_RECOMMENDATION:
              payload.level == 'subscription'?
          REACT_APP_AZURE_SUBSCRIPTION_IDLE_VM_RECOMMENDATION:
          REACT_APP_AZURE_IDLE_VM_RECOMMENDATION, {
        headers: {
          "Ocp-Apim-Subscription-Key": REACT_APP_NEW_SUBSCRIPTION_KEY_2,
        },
        params: {
          ...payload,
        },
      });

      const {
        data: { statusCode },
      } = res;
      if ("statusCode" in res.data) {
        if (statusCode !== 200) {
          yield put(getAzureIdleVMRecommendationsFailure(statusCode));
        } else {
          yield put(getAzureIdleVMRecommendationsSuccess(res.data));
        }
      } else {
        yield put(getAzureIdleVMRecommendationsFailure(res.data));
      }
    } catch (error) {
      yield put(getAzureIdleVMRecommendationsFailure(error));
    }
}

function* getAzureSQLManagedInstanceRecommendations({ payload }) {
    try {
      const res = yield call(axios.get, REACT_APP_AZURE_SQL_MANAGED_INSTANCE_RECOMMENDATION, {
        headers: {
          "Ocp-Apim-Subscription-Key": REACT_APP_NEW_SUBSCRIPTION_KEY,
        },
        params: {
          customerId: payload,
        },
      });

      const {
        data: { statusCode },
      } = res;
      if ("statusCode" in res.data) {
        if (statusCode !== 200) {
          yield put(getAzureSQLManagedInstanceRecommendationsFailure(statusCode));
        } else {
          yield put(getAzureSQLManagedInstanceRecommendationsSuccess(res.data));
        }
      } else {
        yield put(getAzureSQLManagedInstanceRecommendationsFailure(res.data));
      }
    } catch (error) {
      yield put(getAzureSQLManagedInstanceRecommendationsFailure(error));
    }
}

function* getAzureStoppedVMAllRecommendations({ payload }) {
    try {
      const res = yield call(axios.get, REACT_APP_AZURE_STOPPED_VM_RECOMMENDATION, {
        headers: {
          "Ocp-Apim-Subscription-Key": REACT_APP_NEW_SUBSCRIPTION_KEY_2,
        },
        params: {
          ...payload
        },
      });

      const {
        data: { statusCode },
      } = res;
      if ("statusCode" in res.data) {
        if (statusCode !== 200) {
          yield put(getAzureStoppedVMAllRecommendationsFailure(statusCode));
        } else {
          yield put(getAzureStoppedVMAllRecommendationsSuccess(res.data));
        }
      } else {
        yield put(getAzureStoppedVMAllRecommendationsFailure(res.data));
      }
    } catch (error) {
      yield put(getAzureStoppedVMAllRecommendationsFailure(error));
    }
}

function* getAzureStorageRecommendations({ payload }) {
    try {
      const res = yield call(axios.get, REACT_APP_AZURE_STORAGE_RECOMMENDATION, {
        headers: {
          "Ocp-Apim-Subscription-Key": REACT_APP_NEW_SUBSCRIPTION_KEY,
        },
        params: {
          customerId: payload,
        },
      });

      const {
        data: { statusCode },
      } = res;
      if ("statusCode" in res.data) {
        if (statusCode !== 200) {
          yield put(getAzureStorageRecommendationsFailure(statusCode));
        } else {
          yield put(getAzureStorageRecommendationsSuccess(res.data));
        }
      } else {
        yield put(getAzureStorageRecommendationsFailure(res.data));
      }
    } catch (error) {
      yield put(getAzureStorageRecommendationsFailure(error));
    }
}

function* getAzureVMMigrateRecommendations({ payload }) {
    try {
      const res = yield call(axios.get, REACT_APP_AZURE_VM_MIGRATE_RECOMMENDATION, {
        headers: {
          "Ocp-Apim-Subscription-Key": REACT_APP_NEW_SUBSCRIPTION_KEY,
        },
        params: {
          customerId: payload,
        },
      });

      const {
        data: { statusCode },
      } = res;
      if ("statusCode" in res.data) {
        if (statusCode !== 200) {
          yield put(getAzureVMMigrateRecommendationsFailure(statusCode));
        } else {
          yield put(getAzureVMMigrateRecommendationsSuccess(res.data));
        }
      } else {
        yield put(getAzureVMMigrateRecommendationsFailure(res.data));
      }
    } catch (error) {
      yield put(getAzureVMMigrateRecommendationsFailure(error));
    }
}

function* getAzureVMRightsizingRecommendations({ payload }) {
    try {
      const res = yield call(axios.get,
          payload.level == 'tenant'?
              REACT_APP_AZURE_TENANT_VM_RIGHTSIZING_RECOMMENDATION:
              payload.level == 'subscription'?
                  REACT_APP_AZURE_SUB_VM_RIGHTSIZING_RECOMMENDATION:
          REACT_APP_AZURE_VM_RIGHTSIZING_RECOMMENDATION, {
        headers: {
          "Ocp-Apim-Subscription-Key": REACT_APP_NEW_SUBSCRIPTION_KEY_2,
        },
        params: {
          ...payload,
        },
      });

      const {
        data: { statusCode },
      } = res;
      if ("statusCode" in res.data) {
        if (statusCode !== 200) {
          yield put(getAzureVMRightsizingRecommendationsFailure(statusCode));
        } else {
          yield put(getAzureVMRightsizingRecommendationsSuccess(res.data));
        }
      } else {
        yield put(getAzureVMRightsizingRecommendationsFailure(res.data));
      }
    } catch (error) {
      yield put(getAzureVMRightsizingRecommendationsFailure(error));
    }
}
