import React, {useState, useEffect, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import { LinearProgress } from "@mui/material";
import {
    getAWSRecommendationsRequest,
    getAwsRDSRecommendationRequest,
    getAwsAccountRDSRecommendationRequest,
    getAwsAccountEC2RecommendationRequest,
    getAwsEC2RecommendationRequest,
    getAwsRecommendationSummaryRequest,
    getAwsAccountRecommendationSummaryRequest,
    getAwsAccountDynamoDbRecommendationRequest,
    getAwsAccountS3RecommendationRequest,
    getAwsDynamoDbRecommendationRequest, getAWSEBSRecommendationsRequest
} from "redux/actions/actions";
import NO_DATA_AVAILABLE from "components/NO_DATA_AVAILABLE";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import AWSAccountRecommendations from "./AWSAccountRecommendations";
import AWSRDSRecommendation from "./AWSRDSRecommendation";
import AWSEC2Recommendation from './AWSEC2Recommendations'
import AWSDynamoDBRecommendation from "./AWSDynamoDBRecommendations";
import AWSS3Recommendations from "./AWSS3Recommendations";
import {Stop} from "@mui/icons-material";
import Format from "../../components/NumberFormat";
import RecommendationsCard from "components/RecommendationsCard";
import DynamoDBIcon from "../../images/DynamoDB.svg";
import RDSIcon from "../../images/RDS.svg";
import aws_recommendation from "../../images/aws_recommendation.svg";
import Ec2Icon from "../../images/EC2.svg";
import S3Icon from "../../images/awsAccounts/Amazon-Simple-_Storage-Service-S3.svg";
import Button from "@mui/material/Button";
import ArrowUp from '../../images/double-chevron-up.svg'
import ArrowDown from '../../images/double-chevron-down.svg'
import AWSRightsizingEC2Recommendation from "./AWSRightSizingEC2Recommendation";
import axios from "../../connection/axios";
import MountUnmount from "../../components/MountUnmount";
import AWSUnattachedEBS from "./AWSUnattachedEBS";

const { REACT_APP_GET_AWS_RIGHTSIZING_EC2_RECOMMENDATION, REACT_APP_NEW_SUBSCRIPTION_KEY} = process.env;

const DAYS_PERIOD = 30;

export default function AWSRecommendations(props) {
    const dispatch = useDispatch();
    const [stoppedSumCard, setStoppedSumCard] = useState(null);
    const [idleSumCard, setIdleSumCard] = useState(null);
    const [stoppedSumAccountCard, setStoppedSumAccountCard] = useState(null);
    const [idleSumAccountCard, setIdleSumAccountCard] = useState(null);
    const [awsRdsRecommendationCard, setAwsRdsRecommendationCard] = useState(null);
    const [awsDynamoDBRecommendationCard, setAwsDynamoDBRecommendationCard] = useState(null);
    const [awsS3RecommendationCard, setAwsS3RecommendationCard] = useState(null);

    const [awsEC2RecommendationCard, setAwsEC2RecommendationCard] = useState(null);
    const [summaryData, setSummaryData] = useState(null);

    const [rightsizingCards, setRightsizingCards] = useState(null);
    const [rightsizingData, setRightsizingData] = useState(null);
    const [rightsizingLoading, setRightsizingLoading] = useState(false);

    const [unattachedEBSCards, setUnattachedEBSCards] = useState(null);

    const { customerId,cloud, subscriptionId, tenantId, currency, extraRef = null } = props;
    const {
        StoppedEC2All,
        StoppedEC2Account,
        stoppedLoading,
        IdleEC2All,
        IdleEC2Account,
        idleLoading,
        AwsRDSRecommendationData,
        AwsDynamoDBRecommendationData,
        AwsS3RecommendationData,
        awsRecommenadationSummary,
        AwsRDSRecommendationLoading,
        AwsEC2RecommendationData,
        AwsEC2RecommendationLoading,
        awsRecommendationSummaryLoading,
        AwsDynamoDBRecommendationLoading,
        AwsS3RecommendationLoading,

        awsEBSRecommendationLoading,
        awsEBSRecommendationData

    } = useSelector((state) => ({
        StoppedEC2All: state.awsLambdaReducer.stoppedEC2recommendationData,
        StoppedEC2Account: state.awsLambdaReducer.stoppedEC2recommendationAccountData,
        stoppedLoading: state.awsLambdaReducer.stoppedEC2recommendationLoading,
        IdleEC2All: state.awsLambdaReducer.idleEC2recommendationData,
        IdleEC2Account: state.awsLambdaReducer.idleEC2recommendationAccountData,
        idleLoading: state.awsLambdaReducer.idleEC2recommendationLoading,
        AwsRDSRecommendationData: subscriptionId=='all'? state.awsRDSRecommendationReducer.awsRDSRecommendationData:state.awsRDSRecommendationReducer.awsRDSAccountRecommendationData,
        AwsRDSRecommendationLoading:subscriptionId=='all'? state.awsRDSRecommendationReducer.loading: state.awsRDSRecommendationReducer.awsRDSAccountLoading,
        AwsEC2RecommendationData: subscriptionId=='all'? state.awsEC2RecommendationReducer.awsEC2RecommendationData:state.awsEC2RecommendationReducer.awsEC2AccountRecommendationData,
        AwsEC2RecommendationLoading:subscriptionId=='all'? state.awsEC2RecommendationReducer.loading: state.awsEC2RecommendationReducer.awsEC2AccountLoading,
        awsRecommenadationSummary:subscriptionId== "all" ? state?.awsRecommeandationSummaryReducer?.awsRecommendationSummaryData:state?.awsRecommeandationSummaryReducer?.awsRecommendationSummaryAccountData,
        awsRecommendationSummaryLoading:subscriptionId== "all" ? state?.awsRecommeandationSummaryReducer?.awsRecommendationSummaryLoading:state?.awsRecommeandationSummaryReducer?.awsAccountRecommendationSummaryLoading,
        AwsDynamoDBRecommendationData:subscriptionId=='all'? state.awsDynamoDBRecommendationReducer.awsDynamoDBRecommendationData:state.awsDynamoDBRecommendationReducer.awsDynamoDBAccountRecommendationData,
        AwsS3RecommendationData:state?.awsS3RecommendationReducer?.awsS3RecommendationAccount,
        AwsS3RecommendationLoading:state?.awsS3RecommendationReducer?.loading,
        AwsDynamoDBRecommendationLoading:subscriptionId=='all'? state.awsDynamoDBRecommendationReducer.loading: state.awsRDSRecommendationReducer.awsRDSAccountLoading,

        awsEBSRecommendationLoading:state.awsEBSRecommendationReducer.awsEBSRecommendationLoading,
        awsEBSRecommendationData:state.awsEBSRecommendationReducer.awsEBSRecommendationData?.[0],

    }));
    const [dashboardComponentsRef, setDashboardComponentsRef] = useState({});
    const [dashboardComponentsCollapsed, setDashboardComponentsCollapsed] = useState(false);

    const collapseRef1 = useRef({});
    const collapseRef2 = useRef({});
    const collapseRef3 = useRef({});
    const collapseRef4 = useRef({});
    const collapseRef5 = useRef({});
    const collapseRef6 = useRef({});
    const collapseRef7 = useRef({});

    useEffect(() => {
        setStoppedSumCard(null);
        setIdleSumCard(null);
        setStoppedSumAccountCard(null);
        setIdleSumAccountCard(null);
        if (customerId) { 
        if ((AwsRDSRecommendationData == null || StoppedEC2All == null || IdleEC2All == null) && !tenantId && subscriptionId == "all") {
            const obj = { customerId: customerId };
            dispatch(getAWSRecommendationsRequest(obj).stopped);
            dispatch(getAWSRecommendationsRequest(obj).idle);
            dispatch(getAwsRDSRecommendationRequest(obj))
            dispatch(getAwsEC2RecommendationRequest(obj))
            dispatch(getAwsRecommendationSummaryRequest(obj))
            dispatch(getAwsDynamoDbRecommendationRequest(obj))

            dispatch(getAWSEBSRecommendationsRequest(obj));

        } else if (subscriptionId && tenantId) {
            const obj = { customerId: customerId, tenantId: tenantId, subscriptionId: subscriptionId };
            dispatch(getAWSRecommendationsRequest(obj).stopped);
            dispatch(getAWSRecommendationsRequest(obj).idle);
            dispatch(getAwsAccountRDSRecommendationRequest(obj))
            dispatch(getAwsAccountEC2RecommendationRequest(obj))
            dispatch(getAwsAccountRecommendationSummaryRequest(obj))
            dispatch(getAwsAccountDynamoDbRecommendationRequest(obj))
            dispatch(getAwsAccountS3RecommendationRequest(obj))

        }
    }
    }, [customerId, subscriptionId, tenantId]);

    useEffect(()=>{
        setStoppedSumAccountCard(null);
        if(StoppedEC2Account?.data?.length){
            const sumCards = [];
            sumCards.push({ name: "Stopped EC2 instances", value: StoppedEC2Account?.data?.length });
            sumCards.push({
                name: "Potential Savings /month",
                value: currency +
                    Format.formatNumber(StoppedEC2Account?.data?.map(r=>r?.volumes?.map(v=>v.avgCostPerDay||0))?.flat(1)?.reduce((a,b)=>a+b, 0) * 30)
            });
            // sumCards.push({ name: "Total Saved",
            //     value: currency + "0.00"
            // });
            setStoppedSumAccountCard(sumCards);
        }
    }, [StoppedEC2Account]);

    useEffect(()=>{
        setIdleSumAccountCard(null);
        if(IdleEC2Account?.data?.length){
            const sumCards = [];
            sumCards.push({ name: "Idle EC2 instances", value: IdleEC2Account?.data?.length });
            sumCards.push({
                name: "Potential Savings /month",
                value: currency +
                    Format.formatNumber(IdleEC2Account?.data?.map(r=>
                        parseFloat(r.avgCost) + r.volumes?.map(v=>parseFloat(v.avgCost)||0).reduce((a,b)=>a+b, 0)
                    )?.reduce((a,b)=>a+b, 0) * DAYS_PERIOD)
            });
            // sumCards.push({ name: "Total Saved",
            //     value: currency + "0.00"
            // });
            setIdleSumAccountCard(sumCards);
        }
    }, [IdleEC2Account]);

    useEffect(()=>{
        setStoppedSumCard(null);
        if(StoppedEC2All?.data?.length){
            const sumCards = [];
            sumCards.push({ name: "Stopped EC2 instances", value: StoppedEC2All?.data?.length });
            sumCards.push({
                name: "Potential Savings /month",
                value: currency +
                    Format.formatNumber(StoppedEC2All?.data?.map(r=>r.volumes?.map(v=>v.avgCostPerDay||0))?.flat(1)?.reduce((a,b)=>a+b, 0) * 30)
            });
            // sumCards.push({ name: "Total Saved",
            //     value: currency + "0.00"
            // });
            setStoppedSumCard(sumCards);
        }
    }, [StoppedEC2All]);

    useEffect(()=>{
        setUnattachedEBSCards(null);
        if(awsEBSRecommendationData?.volumes?.length){
            const sumCards = [];
            sumCards.push({ name: "Unattached EBS volumes", value: awsEBSRecommendationData?.volumes?.length });
            sumCards.push({
                name: "Potential Savings /month",
                value: currency +
                    Format.formatNumber(awsEBSRecommendationData?.volumes?.map(r=>r.storageCostPerDay)?.reduce((a,b)=>a+b, 0) * 30)
            });
            // sumCards.push({ name: "Total Saved",
            //     value: currency + "0.00"
            // });
            setUnattachedEBSCards(sumCards);
        }
    }, [awsEBSRecommendationData]);

    useEffect(()=>{
        setIdleSumCard(null);
        if(IdleEC2All?.data?.length){
            const sumCards = [];
            sumCards.push({ name: "Idle EC2 instances", value: IdleEC2All?.data?.length });
            sumCards.push({
                name: "Potential Savings /month",
                value: currency +
                    Format.formatNumber(IdleEC2All?.data?.map(r=>
                        parseFloat(r.avgCost) + r.volumes?.map(v=>parseFloat(v.avgCost)||0).reduce((a,b)=>a+b, 0)
                    )?.reduce((a,b)=>a+b, 0) * DAYS_PERIOD)
            });
            // sumCards.push({ name: "Total Saved",
            //     value: currency + "0.00"
            // });
            setIdleSumCard(sumCards);
        }
    }, [IdleEC2All]);

    useEffect(()=>{
        setAwsRdsRecommendationCard(null);
        if(AwsRDSRecommendationData?.rdsRecommendations?.length){
            const sumCards = [];
            sumCards.push({ name: AwsRDSRecommendationData?.rdsRecommendations?.length>1 ? "RDS instances":"RDS instance", value: AwsRDSRecommendationData?.rdsRecommendations?.length });
            sumCards.push({
                name: "Potential Savings /month",
                value: currency +
                 Format.formatNumber(
                    AwsRDSRecommendationData?.rdsRecommendations?.map(r=>
                        Math.max(
                            ...r.recommendations?.map(v=>parseFloat(v.yearlyPotentialSavings/12)||0)
                        )
                    )?.reduce((a,b)=>a+b, 0)
                 )
                   });
            
            setAwsRdsRecommendationCard(sumCards);
        }
    }, [AwsRDSRecommendationData,subscriptionId]);

    useEffect(()=>{
        setAwsEC2RecommendationCard(null);
        if(AwsEC2RecommendationData?.data?.length){
            const sumCards = [];
            sumCards.push({ name: AwsEC2RecommendationData?.data?.length>1 ? "EC2 instances":"EC2 instance", value: AwsEC2RecommendationData?.data?.length });
            sumCards.push({
                name: "Potential Savings /month",
                value: currency +
                 Format.formatNumber(
                    AwsEC2RecommendationData?.data?.map(r=>r?.recommendations?.length>0 &&
                        Math.max(
                            ...r?.recommendations?.map(v=>parseFloat(v.monthlySavings)||0)
                        )
                    )?.reduce((a,b)=>a+b, 0)
                 )
                   });
            
                   setAwsEC2RecommendationCard(sumCards);
        }
    }, [AwsEC2RecommendationData,subscriptionId]);

    useEffect(()=>{
        setAwsS3RecommendationCard(null);
        if(AwsS3RecommendationData?.data?.length){
            const sumCards = [];
            sumCards.push({ name: AwsS3RecommendationData?.data?.length>1 ? "S3 Buckets":"S3 Bucket", value: AwsS3RecommendationData?.data?.length });
            sumCards.push({
                name: "Potential Savings /month",
                value: currency +
                Format.formatNumber(
                    AwsS3RecommendationData?.data?.map(r=>parseFloat(r?.monthlySavings||0))?.reduce((a,b)=>a+b, 0))
                   });
            
                   setAwsS3RecommendationCard(sumCards);
        }
    }, [AwsS3RecommendationData]);
    
    useEffect(()=>{
        setAwsDynamoDBRecommendationCard(null);
        if(AwsDynamoDBRecommendationData?.dynamoDBRecommendations?.length){
            const sumCards = [];
            sumCards.push({ name: AwsDynamoDBRecommendationData?.dynamoDBRecommendations?.length>1 ? "DynamoDB Tables":"DynamoDB Table", value: AwsDynamoDBRecommendationData?.dynamoDBRecommendations?.length });
            sumCards.push({
                name: "Potential Savings /month",
                value: currency +
                 Format.formatNumber(
                    AwsDynamoDBRecommendationData?.dynamoDBRecommendations?.map(r=>
                        Math.max(
                            ...r.recommendations?.map(v=>parseFloat(v.yearlyPotentialSavings/12)||0)
                       )
                    )?.reduce((a,b)=>a+b, 0)
                 )
                   });
            
                   setAwsDynamoDBRecommendationCard(sumCards);
        }
    }, [AwsDynamoDBRecommendationData,subscriptionId]);

     useEffect(() => {
         const accountAsync = async () => {
             var data = [];
             if (awsRecommenadationSummary?.recommendationsSummary && Object.keys(awsRecommenadationSummary?.recommendationsSummary).length > 0) {
                 data.push({
                     title: "Total Potential Savings /mon",
                     cost: currency + Format.formatNumber(awsRecommenadationSummary?.recommendationsSummary?.summaryPotentialSavingsPerMonth),
                 });
             }
             if (awsRecommenadationSummary && Object.keys(awsRecommenadationSummary).length > 0) {
                 if (awsRecommenadationSummary?.stoppedEC2Recommendations && Object.keys(awsRecommenadationSummary?.stoppedEC2Recommendations).length > 0) {
                     data.push({
                         title: awsRecommenadationSummary?.stoppedEC2Recommendations?.totalStoppedEC2Instance > 1 ? "Stopped EC2 instances" : "Stopped EC2 instance",
                         value: awsRecommenadationSummary?.stoppedEC2Recommendations?.totalStoppedEC2Instance,
                         costTitle: 'Potential savings /mon',
                         cost: currency + Format.formatNumber(awsRecommenadationSummary?.stoppedEC2Recommendations?.totalStoppedEC2PotentialSavingsPerMonth),
                         icon: Ec2Icon
                     });
                 }
                 if (awsRecommenadationSummary?.idleEC2Recommendations && Object.keys(awsRecommenadationSummary?.idleEC2Recommendations).length > 0) {
                     data.push({
                         title: awsRecommenadationSummary?.idleEC2Recommendations?.totalIdleEC2Instance > 1 ? "Idle EC2 instances" : "Idle EC2 instance",
                         value: awsRecommenadationSummary?.idleEC2Recommendations?.totalIdleEC2Instance,
                         costTitle: 'Potential savings /mon',
                         cost: currency + Format.formatNumber(awsRecommenadationSummary?.idleEC2Recommendations?.totalIdleEC2PotentialSavingsPerMonth),
                         icon: Ec2Icon
                     });
                 }
                 if (awsRecommenadationSummary?.resizingRecommendations && Object.keys(awsRecommenadationSummary?.resizingRecommendations).length > 0) {
                     data.push({
                         title: awsRecommenadationSummary?.resizingRecommendations?.totalResizingInstance > 1 ? "Rightsizing EC2" : "Rightsizing EC2 instance",
                         value: awsRecommenadationSummary?.resizingRecommendations?.totalResizingInstance,
                         costTitle: 'Potential savings /mon',
                         cost: currency + Format.formatNumber(awsRecommenadationSummary?.resizingRecommendations?.totalResizingPotentialSavingsPerMonth),
                         icon: Ec2Icon
                     });
                 }
                 // if (rightsizingData?.resizingRecommendations && Object.keys(rightsizingData?.resizingRecommendations).length > 0) {
                 //     data.push({
                 //         title: rightsizingData?.resizingRecommendations?.length > 1 ? "Rightsizing EC2" : "Rightsizing EC2 instance",
                 //         value: rightsizingData?.resizingRecommendations?.length,
                 //         costTitle: 'Potential savings /mon',
                 //         cost: currency + Format.formatNumber(rightsizingData?.resizingRecommendations?.map(r=>parseFloat(r.potentialSavings)).reduce((a,b)=>a+b, 0)),
                 //         icon: Ec2Icon
                 //     });
                 // }

                 if (awsRecommenadationSummary?.rdsRecommendations && Object.keys(awsRecommenadationSummary?.rdsRecommendations).length > 0) {
                     data.push({
                         title: awsRecommenadationSummary?.rdsRecommendations?.totalRDSInstance > 1 ? "RDS instances" : " RDS instance",
                         value: awsRecommenadationSummary?.rdsRecommendations?.totalRDSInstance,
                         costTitle: 'Potential savings /mon',
                         cost: currency + Format.formatNumber(awsRecommenadationSummary?.rdsRecommendations?.totalRDSPotentialSavingsPerMonth),
                         icon: RDSIcon
                     });
                 }
                 if (awsRecommenadationSummary && Object.keys(awsRecommenadationSummary?.dynamoDBRecommendations).length > 0) {
                     data.push({
                         title: awsRecommenadationSummary?.dynamoDBRecommendations?.totalDynamoDB > 1 ? "DynamoDB tables" : "DynamoDB table",
                         value: awsRecommenadationSummary?.dynamoDBRecommendations?.totalDynamoDB,
                         costTitle: 'Potential savings /mon',
                         cost: currency + Format.formatNumber(awsRecommenadationSummary?.dynamoDBRecommendations?.totalDynamoDBPotentialSavingsPerMonth),
                         icon: DynamoDBIcon
                     });
                 }
                 if (awsRecommenadationSummary?.migrateToLatestEC2EC2Recommendations && Object.keys(awsRecommenadationSummary?.migrateToLatestEC2EC2Recommendations).length > 0) {
                     data.push({
                         title: awsRecommenadationSummary?.migrateToLatestEC2EC2Recommendations?.totalMigrateToLatestEC2EC2Instance > 1 ? "Prev.Gen. EC2 instances" : "Prev.Gen. EC2 instance",
                         value: awsRecommenadationSummary?.migrateToLatestEC2EC2Recommendations?.totalMigrateToLatestEC2EC2Instance,
                         costTitle: 'Potential savings /mon',
                         cost: currency + Format.formatNumber(awsRecommenadationSummary?.migrateToLatestEC2EC2Recommendations?.totalMigrateToLatestEC2EC2PotentialSavingsPerMonth),
                         icon: Ec2Icon
                     });
                 }

                 setSummaryData(data);
             }
         };
         accountAsync();
    }, [rightsizingData, awsRecommenadationSummary,AwsRDSRecommendationData,AwsDynamoDBRecommendationData,subscriptionId]);

     var collapsed = collapseRef1?.current?.collapsed &&
                       collapseRef2?.current?.collapsed &&
                       collapseRef3?.current?.collapsed &&
                       collapseRef4?.current?.collapsed &&
                       collapseRef5?.current?.collapsed ;

     if(extraRef){
         collapsed = collapsed && extraRef?.current?.collapsed;
     }

     if(collapseRef6?.current && collapseRef6.current.collapsed !== undefined){
         collapsed = collapsed && collapseRef6?.current?.collapsed
     }

    function onCollapseToggle(){
        const refs = [collapseRef1,collapseRef2,collapseRef3,collapseRef4,collapseRef5, collapseRef7];

        if(collapseRef6?.current && collapseRef6.current.collapsed !== undefined)
            refs.push(collapseRef6)

        if(extraRef)
            refs.push(extraRef)

        if(!collapsed){
            refs.forEach(ref=> {
                if(!(ref?.current?.collapsed))
                    ref?.current?.onCollapse();
            })
        } else {
            refs.forEach(ref=> {
                if(ref?.current?.collapsed)
                    ref?.current?.onCollapse();
            })
        }
    }

    useEffect(()=>{
        if(customerId)
            setRightsizingLoading(true)
        axios.get(REACT_APP_GET_AWS_RIGHTSIZING_EC2_RECOMMENDATION, {
            params: {customerId},
            headers: {
                "Ocp-Apim-Subscription-Key": REACT_APP_NEW_SUBSCRIPTION_KEY,
            },
        })
            .then(d=>d.data)
            .then(d=>(d?.data)?setRightsizingData(d?.data):setRightsizingData(null))
            .finally(()=>setRightsizingLoading(false));
    }, [customerId]);

    useEffect(()=>{
        setRightsizingCards(null);
        if(rightsizingData?.resizingRecommendations?.length){
            const sumCards = [];
            sumCards.push({ name: rightsizingData?.resizingRecommendations?.length>1 ? "EC2 instances":"EC2 instance", value: rightsizingData?.resizingRecommendations?.length });
            sumCards.push({
                name: "Potential Savings /month",
                value: currency +
                    Format.formatNumber(
                        rightsizingData?.resizingRecommendations?.map(r=>
                            // Math.max(
                            //     ...r.recommendations?.map(v=>parseFloat(v.yearlyPotentialSavings/12)||0)
                            // )
                            parseFloat(r?.potentialSavings)
                        )?.reduce((a,b)=>a+b, 0)
                    )
            });

            setRightsizingCards(sumCards);
        }
    }, [rightsizingData]);

    function collapseCards() {
        const h = dashboardComponentsRef;
        for(const rg in h){
            h[rg](dashboardComponentsCollapsed);
        }
        setDashboardComponentsCollapsed(!dashboardComponentsCollapsed);
    }

    return (
        <div>
            {subscriptionId == "all" ? (
                    <>
                    {!awsRecommendationSummaryLoading ? 
                     <div>
                     {summaryData &&  <RecommendationsCard summary={summaryData} />}
                     </div>
                    :
                    <LinearProgress/>}
                        <br/>
                        {true?<div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <span onClick={collapseCards} className={'link-button'}>{dashboardComponentsCollapsed?"Expand All":"Collapse All"}
                    <img src={!dashboardComponentsCollapsed?ArrowUp:ArrowDown} height={20} />
                            </span>
                        </div>:null}
                        <MountUnmount onUnmount={()=> {
                            setDashboardComponentsRef({});
                            setDashboardComponentsCollapsed(false);
                        }} />
                        <AWSAccountRecommendations data={StoppedEC2All?.data}
                                                   title={'Stopped EC2 Recommendations'}
                                                   cards={stoppedSumCard}
                                                   customerId={customerId}
                                                   subscriptionId={StoppedEC2All?.subscriptionId}
                                                   lastUpdated={StoppedEC2All?.dateCreated}
                                                   currency={StoppedEC2All?.currency}
                                                   type={'stopped'}
                                                   loading={stoppedLoading}
                                                   collapseRef={{how: setDashboardComponentsRef, what:'recomm1', where: dashboardComponentsRef}}
                                                   volumeLinkEnabled={true}
                        />
                        <br/>
                        <AWSAccountRecommendations data={IdleEC2All?.data}
                                                   title={'Idle EC2 Recommendations'}
                                                   cards={idleSumCard}
                                                   customerId={customerId}
                                                   subscriptionId={IdleEC2All?.subscriptionId}
                                                   lastUpdated={IdleEC2All?.dateCreated}
                                                   currency={IdleEC2All?.currency}
                                                   type={'idle'}
                                                   loading={idleLoading}
                                                   collapseRef={{how: setDashboardComponentsRef, what:'recomm2', where: dashboardComponentsRef}}
                        />
                        <br/>
                        <AWSRightsizingEC2Recommendation
                                              title={'Rightsizing EC2 Recommendations'}
                                              view={'all'}
                                              customerId={customerId}
                                              lastUpdated={rightsizingData?.dateCreated}
                                              currency={currency}
                                              loading={rightsizingLoading}
                                              data={rightsizingData}
                                              cards={rightsizingCards}
                                              collapseRef={{how: setDashboardComponentsRef, what:'recomm3', where: dashboardComponentsRef}}
                        />
                        <br/>
                        <AWSUnattachedEBS
                            data={awsEBSRecommendationData}
                            title={'Unattached EBS Volumes Recommendations'}
                            cards={unattachedEBSCards}
                            customerId={customerId}
                            lastUpdated={awsEBSRecommendationData?.dateCreated}
                            currency={currency}
                            loading={awsEBSRecommendationLoading}
                            collapseRef={{how: setDashboardComponentsRef, what:'unattachedEBS', where: dashboardComponentsRef}}
                            volumeLinkEnabled={true}
                        />
                        <br/>
                        <AWSEC2Recommendation data={AwsEC2RecommendationData}
                                           title={'Migrate previous to latest generation EC2 Recommendations'}
                                           view={'account'}
                                           cards={awsEC2RecommendationCard}
                                           customerId={customerId}
                                           lastUpdated={AwsEC2RecommendationData?.dateCreated}
                                           currency={AwsEC2RecommendationData?.currency}
                                           loading={AwsEC2RecommendationLoading}
                                              collapseRef={{how: setDashboardComponentsRef, what:'recomm4', where: dashboardComponentsRef}}
                          />
                          <br/>
                     <AWSRDSRecommendation data={AwsRDSRecommendationData}
                                           title={'RDS Recommendations'}
                                           view={'all'}
                                           cards={awsRdsRecommendationCard}
                                           customerId={customerId}
                                           lastUpdated={AwsRDSRecommendationData?.dateCreated}
                                           currency={AwsRDSRecommendationData?.currency}
                                           loading={AwsRDSRecommendationLoading}
                                           collapseRef={{how: setDashboardComponentsRef, what:'recomm5', where: dashboardComponentsRef}}
                      />
                      <br/>

                      <AWSDynamoDBRecommendation data={AwsDynamoDBRecommendationData}
                                                 title={'DynamoDB Recommendations'}
                                                 view={'all'}
                                                 cards={awsDynamoDBRecommendationCard}
                                                 customerId={customerId}
                                                 lastUpdated={AwsDynamoDBRecommendationData?.dateCreated}
                                                 currency={AwsDynamoDBRecommendationData?.currency}
                                                 loading={AwsDynamoDBRecommendationLoading}
                                                 collapseRef={{how: setDashboardComponentsRef, what:'recomm6', where: dashboardComponentsRef}}
                      />
                        <br/>
                        <AWSS3Recommendations data={null}
                                              title={'S3 Recommendations'}
                                              view={'account'}
                                              cards={awsS3RecommendationCard}
                                              customerId={customerId}
                                              lastUpdated={AwsS3RecommendationData?.dateCreated}
                                              currency={AwsS3RecommendationData?.currency}
                                              loading={AwsS3RecommendationLoading}
                                              collapseRef={{how: setDashboardComponentsRef, what:'recomm7', where: dashboardComponentsRef}}
                        />

                    </>

            ) : (
                <>
                {!awsRecommendationSummaryLoading ?
                     <div>
                     {summaryData &&  <RecommendationsCard summary={summaryData}/>}
                     </div>
                    :
                    <LinearProgress/>}
                  <br/>
                    {true?<div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <span onClick={collapseCards} className={'link-button'}>{dashboardComponentsCollapsed?"Expand All":"Collapse All"}
                    <img src={!dashboardComponentsCollapsed?ArrowUp:ArrowDown} height={20} />
                            </span>
                    </div>:null}
                    <MountUnmount onUnmount={()=> {
                        setDashboardComponentsRef({});
                        setDashboardComponentsCollapsed(false);
                    }} />
                    <AWSAccountRecommendations data={StoppedEC2Account?.data}
                                               title={'Stopped EC2 Recommendations'}
                                               cards={stoppedSumAccountCard}
                                               customerId={customerId}
                                               subscriptionId={StoppedEC2Account?.subscriptionId}
                                               tenantId={StoppedEC2Account?.tenantId}
                                               lastUpdated={StoppedEC2Account?.dateCreated}
                                               currency={StoppedEC2Account?.currency}
                                               type={'stopped'}
                                               collapseRef={{how: setDashboardComponentsRef, what:'recomm1', where: dashboardComponentsRef}}
                                               volumeLinkEnabled={true}
                    />
                    <br/>
                    <AWSAccountRecommendations data={IdleEC2Account?.data}
                                               title={'Idle EC2 Recommendations'}
                                               cards={idleSumAccountCard}
                                               customerId={customerId}
                                               subscriptionId={IdleEC2Account?.subscriptionId}
                                               tenantId={IdleEC2Account?.tenantId}
                                               lastUpdated={IdleEC2Account?.dateCreated}
                                               currency={IdleEC2Account?.currency}
                                               type={'idle'}
                                               collapseRef={{how: setDashboardComponentsRef, what:'recomm2', where: dashboardComponentsRef}}
                    />
                    <br/>
                    <AWSRightsizingEC2Recommendation title={'Rightsizing EC2 Recommendations'}
                                                     view={'all'}
                                                     customerId={customerId}
                                                     lastUpdated={rightsizingData?.dateCreated}
                                                     currency={currency}
                                                     collapseRef={{how: setDashboardComponentsRef, what:'recomm3', where: dashboardComponentsRef}}
                                                     loading={rightsizingLoading}
                                                     data={rightsizingData}
                                                     cards={rightsizingCards}
                    />
                    <br/>
                    <AWSEC2Recommendation data={AwsEC2RecommendationData}
                                          title={'Migrate previous to latest generation EC2 Recommendations'}
                                          view={'account'}
                                          cards={awsEC2RecommendationCard}
                                          customerId={customerId}
                                          lastUpdated={AwsEC2RecommendationData?.dateCreated}
                                          currency={AwsEC2RecommendationData?.currency}
                                          loading={AwsEC2RecommendationLoading}
                                          collapseRef={{how: setDashboardComponentsRef, what:'recomm4', where: dashboardComponentsRef}}
                    />
                    <br/>
                <AWSRDSRecommendation data={AwsRDSRecommendationData}
                                           title={'RDS Recommendations'}
                                           view={'account'}
                                           cards={awsRdsRecommendationCard}
                                           customerId={customerId}
                                           lastUpdated={AwsRDSRecommendationData?.dateCreated}
                                           currency={AwsRDSRecommendationData?.currency}
                                           loading={AwsRDSRecommendationLoading}
                                      collapseRef={{how: setDashboardComponentsRef, what:'recomm5', where: dashboardComponentsRef}}
                />
                <br/>

                <AWSDynamoDBRecommendation data={AwsDynamoDBRecommendationData}
                                           title={'DynamoDB Recommendations'}
                                           view={'account'}
                                           cards={awsDynamoDBRecommendationCard}
                                           customerId={customerId}
                                           lastUpdated={AwsDynamoDBRecommendationData?.dateCreated}
                                           currency={AwsDynamoDBRecommendationData?.currency}
                                           loading={AwsDynamoDBRecommendationLoading}
                                           collapseRef={{how: setDashboardComponentsRef, what:'recomm6', where: dashboardComponentsRef}}
                />
                    <br/>
                    <AWSS3Recommendations data={AwsS3RecommendationData}
                                          title={'S3 Recommendations'}
                                          view={'account'}
                                          cards={awsS3RecommendationCard}
                                          customerId={customerId}
                                          lastUpdated={AwsS3RecommendationData?.dateCreated}
                                          currency={AwsS3RecommendationData?.currency}
                                          loading={AwsS3RecommendationLoading}
                                          collapseRef={{how: setDashboardComponentsRef, what:'recomm7', where: dashboardComponentsRef}}
                    />

                </>
            ) }
        </div>
    );
}
