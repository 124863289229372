import Gcp_logo from "../images/GCP_logo.svg";
import Azure_logo from "../images/Azure_logo.svg";
import {convertToInternationalCurrencySystem} from "../util/Miscellaneous";

export class Currency {
  static _currency = "USD";
  static get currency() {
    return this._currency;
  }

  static set currency(value) {
    this._currency = value;
  }
}

const Format = {
  FallBackCurrency: "USD",

  formatNumber: function (x = 0, slice = true) {
    // if(Number.isNaN(x)) return 0;
    return (parseFloat(x) > 99999 && slice)?
        convertToInternationalCurrencySystem(parseInt(x)):
        Number(x)
            .toFixed(2)
            .toString()
            .replace(Currency.currency == "INR"?
                /\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g :
                /\B(?=(\d{3})+(?!\d))/g, ",");

    return Number(x)
      .toFixed(2)
      .toString()
      .replace(Currency.currency == "INR"?
              /\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g :
              /\B(?=(\d{3})+(?!\d))/g, ",");
  },

  formatNumberInt(x = 0) {
    return (parseInt(x) > 99999)?
        convertToInternationalCurrencySystem(parseInt(x)):
        Number(x)
            .toFixed(0)
            .toString()
            .replace(Currency.currency == "INR"?
                /\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g :
                /\B(?=(\d{3})+(?!\d))/g, ",");
    
    return Number(x)
      .toFixed(0)
      .toString()
        .replace(Currency.currency == "INR"?
            /\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g :
            /\B(?=(\d{3})+(?!\d))/g, ",");
  },

  //replace(/(\d)(?=(\d{3})+(?!\d))/g, '$0.00,');

  formatCurrencySymbol: function (currency) {
    return currency_symbols[currency];
  },
  getIcons: function (name) {
    return allIcons[name];
  },

  removeByAttr: function (arr, attr, value) {
    var i = arr.length;
    while (i--) {
      if (
        arr[i] &&
        arr[i].hasOwnProperty(attr) &&
        arguments.length > 2 &&
        arr[i][attr] === value
      ) {
        arr.splice(i, 1);
      }
    }
    return arr;
  },

  checkSC: (str) => {
    return specialChar.test(str);
  },

  getSC: (str) => {
    let v = specialChar.exec(str);
    return v[0];
  },
};

export default Format;

export const specialChar = /[`/!@#$%^&*()_+\-=\[\]{};':"\\|,<>\?~]/;

const currency_symbols = {
  USD: "$", // US Dollar
  EUR: "€", // Euro
  AUD: "A$", // Australian dollar
  SGD: "S$", // Singapur dollar
  JPY: "¥", // Japanese Yen
  KRW: "₩", // South Korean Won
  INR: "₹", // Indian Rupee
  CRC: "₡", // Costa Rican Colón
  CNY: "¥", // China currency
  GBP: "£", // British Pound Sterling
  ILS: "₪", // Israeli New Sheqel
  NGN: "₦", // Nigerian Naira
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  PYG: "₲", // Paraguayan Guarani
  THB: "฿", // Thai Baht
  UAH: "₴", // Ukrainian Hryvnia
  VND: "₫", // Vietnamese Dong
};

var short_string = {
  USD: "en-US",
  INR: "en-IN",
  EUR: "en-150",
  JPY: "ja-JP",
  GBP: "en-VG",
};

const allIcons = {
  Azure: <img src={Azure_logo} height={20} />,
  GCP: <img src={Gcp_logo} height={20} />,
};
// var format = new Intl.NumberFormat('en-US', {
//   style: 'currency',
//   currency: currency,
// }).format(amount);
