import * as types from "../actions/types";

const userState = {
    azureCCKdata: null,
    azureContainersData: null,
    azureKubernetesData: null,
    azureCCKTenantData: null,
    azureContainersTenantData: null,
    azureKubernetesTenantData: null,
    azureCCKSubData: null,
    azureContainersSubData: null,
    azureKubernetesSubData: null,
    error: null,
    loading: false,
};

function azureCCKReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.AZURE_CCK_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AZURE_CCK_SUCCESS: {
            const data = {};

            if (payload.dataType == "compute") {
                data["azureCCKdata"] = payload.data.data;
            } else if (payload.dataType == "containers") {
                data["azureContainersData"] = payload.data.data;
            } else if (payload.dataType == "kubernetes") {
                data["azureKubernetesData"] = payload.data.data;
            }

            return {
                ...state,
                ...data,
                loading: false,
            };
        }
        case types.AZURE_CCK_FAILURE: {
            return {
                ...state,
                loading: false,
                azureCCKdata: null,
                azureContainersData: null,
                azureKubernetesData: null,
                error: payload,
            };
        }
         // ---------------Azure CCK Tenant level-------------------------

         case types.AZURE_CCK_TENANT_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AZURE_CCK_TENANT_SUCCESS: {
            const data = {};

            if (payload.dataType == "compute") {
                data["azureCCKTenantData"] = payload.data.data;
            } else if (payload.dataType == "containers") {
                data["azureContainersTenantData"] = payload.data.data;
            } else if (payload.dataType == "kubernetes") {
                data["azureKubernetesTenantData"] = payload.data.data;
            }
            return {
                ...state,
                ...data,
                loading: false,
            };
        }
        case types.AZURE_CCK_TENANT_FAILURE: {
            return {
                ...state,
                loading: false,
                azureCCKTenantData: null,
                azureContainersTenantData: null,
                azureKubernetesTenantData: null,
                error: payload,
            };
        }

        // ---------------Azure CCK Subscription level-------------------------

        case types.AZURE_CCK_SUB_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AZURE_CCK_SUB_SUCCESS: {
            const data = {};

            if (payload.dataType == "compute") {
                data["azureCCKSubData"] = payload.data.data;
            } else if (payload.dataType == "containers") {
                data["azureContainersSubData"] = payload.data.data;
            } else if (payload.dataType == "kubernetes") {
                data["azureKubernetesSubData"] = payload.data.data;
            }
            return {
                ...state,
                ...data,
                loading: false,
            };
        }
        case types.AZURE_CCK_SUB_FAILURE: {
            return {
                ...state,
                loading: false,
                azureCCKSubData: null,
                azureContainersSubData: null,
                azureKubernetesSubData: null,
                error: payload,
            };
        }
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                azureCCKdata: null,
                azureContainersData: null,
                azureKubernetesData: null,
                azureCCKSubData: null,
                azureContainersSubData: null,
                azureKubernetesSubData: null,
                azureCCKTenantData: null,
                azureContainersTenantData: null,
                azureKubernetesTenantData: null,
                error: null,
                loading: false,
            };
        }

        default:
            return state;
    }
}
export default azureCCKReducer;
