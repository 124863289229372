import React from "react";
import PropTypes from "prop-types";
import Format from "./NumberFormat";
import { useSelector } from "react-redux";

Cost.propTypes = {
  children: PropTypes.number,
};

/**
 * @returns {JSX.Element}
 * @requires {Number}
 *
 * Use:
 * `<Cost>{cost}</Cost>`
 */

function Cost(props) {
  if (!props.children) return null;
  return <>{Format.formatNumber(parseFloat(props.children)) || null}</>;
}

export default Cost;

/**
 * Use:
 * `<Currency/>`
 */
export function Currency() {
  const currency = useSelector((state) => state.allHomeReducer.currency);
  return <span>{Format.formatCurrencySymbol(currency)}</span>;
}
