import React, { useState } from "react";

import { Paper, Grid, Divider, Card, IconButton, Collapse } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp, PlayArrow, ThumbUp } from "@mui/icons-material";
import gcp_billing from "../../images/gcp_billing.svg";

import DateRange from "../DateRange";
import Format from "../../components/NumberFormat";
import gcp_project from "../../images/gcp_project.svg";
import ContainerCardWithDateRange from "../ContainerCardWithDateRange";
import {makeStyles} from "MakeWithStyles";

const useStyles = makeStyles()((theme) => ({
    paper: {
        padding: 0,
    },
    btnStyle: {
        background: "linear-gradient(45deg, #3f51b5 50%, #3f51b5 100%)",
        border: 0,
        borderRadius: 3,
        boxShadow: "0 3px 5px 2px rgba(63, 81, 181, .3)",
        color: "white",
        height: 40,
        padding: "0 40px",
        textTransform: "none",
    },
}));

export default function GCPBillingAccountList({ currency, billingAccountName, accountList = [] }) {
    const {classes} = useStyles();
    const [period, setPeriod] = useState("1M");
    const [orgView, setOrgView] = useState(true);

    return (
        <div>
            <ContainerCardWithDateRange title={'Spend by Billing account'} defaultPeriod={"1M"} titleIcon={gcp_billing}>
                {({period})=>(
                    <Paper elevation={1}>
                    <div
                        style={{
                            padding: 16,
                            backgroundColor: "#cfdac8",
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4,
                        }}
                    >
                        <div
                            style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                display: "flex",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <img
                                    src={gcp_billing}
                                    alt="tenantLogo"
                                    style={{
                                        width: 20,
                                        height: 20,
                                        marginRight: 8,
                                    }}
                                />
                                <span style={{fontWeight: "bold"}}>{billingAccountName}</span>
                            </div>
                            {accountList?.[period]?.dataSet && accountList?.[period]?.dataSet.length && (
                                <div style={{fontWeight: "bold"}}>
                                    {" "}
                                    {currency}
                                    {Format.formatNumber(accountList?.[period]?.totalCost)}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="overflow-auto" style={{padding: 16, maxHeight: 450}}>
                {accountList?.[period]?.dataSet && accountList?.[period]?.dataSet.length ? (
                    accountList?.[period]?.dataSet?.map((item) => (
                    <div style={{padding: 8}}>
                    <div
                    style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                }}
                    >
                    <div style={{alignItems: "center", display: "flex"}}> {item.label}</div>

                    <div style={{alignItems: "center", display: "flex"}}>
                {" "}
                {currency}
                {Format.formatNumber(item.value)}
                    </div>
                    </div>

                    <Divider light style={{marginTop: 8}} />
                    </div>
                    ))
                    ) : (
                    <span style={{display: 'flex', justifyContent: "center"}}>No Projects found</span>
                    )}
                    </div>
                    </Paper>
                )}
            </ContainerCardWithDateRange>
        </div>
    );
}
