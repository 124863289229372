import * as types from "../actions/types";

const userState = {
    allFunctionsData: null,
    error: null,
    loading: false,
};

function allFunctionsReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.ALL_FUNCTIONS_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.ALL_FUNCTIONS_SUCCESS: {
            return {
                ...state,
                allFunctionsData: payload.data,
                loading: false,
            };
        }
        case types.ALL_FUNCTIONS_FAILURE: {
            return {
                ...state,
                loading: false,
                allFunctionsData: null,
                error: payload,
            };
        }

        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                allFunctionsData: null,
                error: null,
                loading: false,
            };
        }

        default:
            return state;
    }
}
export default allFunctionsReducer;
