import * as types from "../actions/types";

const userState = {
    awsRDSData: null,
    error: null,
    loading: false,
    awsRDSLoading: false,
    awsRDSAccountData:null,
    awsAccountLoading:false
};

function awsRDSReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.AWS_RDS_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AWS_RDS_SUCCESS: {
            return {
                ...state,
                awsRDSData: payload.data,
                loading: false,
            };
        }
        case types.AWS_RDS_FAILURE: {
            return {
                ...state,
                loading: false,
                awsRDSData: null,
                error: payload,
            };
        }

        case types.AWS_RDS_ACCOUNT_REQUEST: {
            return {
                ...state,
                awsAccountLoading: true,
            };
        }
        case types.AWS_RDS_ACCOUNT_SUCCESS: {
            return {
                ...state,
                awsRDSAccountData: payload.data,
                awsAccountLoading: false,
            };
        }
        case types.AWS_RDS_ACCOUNT_FAILURE: {
            return {
                ...state,
                awsAccountLoading: false,
                awsRDSAccountData: null,
                error: payload,
            };
        }

       
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                awsRDSData: null,
                error: null,
                loading: false,
                awsRDSLoading: false,
                awsRDSAccountData:null
            };
        }

        default:
            return state;
    }
}
export default awsRDSReducer;
