import React, { useEffect, useRef, useState } from "react";
import axios from "../../connection/axios";
import { useSelector, useDispatch } from "react-redux";
import { accountRequest, getSubscriptionsRequest, awsServerlessRequest, getAwsOrgSpendingRequest,getAwsRecommendationSummaryRequest,getAwsAccountRecommendationSummaryRequest } from "../../redux/actions/actions";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AWS_LOGO from "../../images/aws_dark.svg";
import DateRange from "../../components/DateRange";
import Format from "../../components/NumberFormat";
import Color from "../../styles/color";
import { Card, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, LinearProgress, Link, Typography, TextField } from "@mui/material";
import BreadCrumbs from "../../components/BreadCrumbs";
import "../Charts/style.css";
import CostHeader, { CostHeaderDataComponent } from "../../components/CostHeader";
import ConenctAccountSkipPage from "pages/ConnectAccountSkipPage";
import { Autocomplete } from "@mui/lab";
import AwsHomeTab from "components/tab/AwsHomeTab";
import key from "../../images/key.svg";
import org from "../../images/aws_org.svg";
import ConnectionError from "../../components/ConnectionError";
import { SET_CLOUD_ACTIVE_TAB_REQUEST } from "../../redux/actions/types";
import DashboardLoader from "components/loaders/DashboardLoader";

import ReactPlayer from "react-player";
import Carousel from "react-bootstrap/Carousel";

import backgroundImage from "../../images/bg1.jpg";
import backgroundImage1 from "../../images/bg2.jpg";
import backgroundImage2 from "../../images/bg3.jpg";
import backgroundImage3 from "../../images/bg4.jpg";

import {makeStyles} from "MakeWithStyles";
import RoutesList from "../../routes/RoutesList";

const {
    REACT_APP_AWS_HOME,
    REACT_APP_AWS_SUMMARY,
    REACT_APP_AWS_HOME_SUMMARY_OVERVIEW,
    REACT_APP_AWS_ACCOUNT_SUMMARY_OVERVIEW,
    REACT_APP_AWS_SUBSCRIPTION_KEY
} = process.env;

const DEFAULT_SUBSCRIPTION = { tenantId: null, title: "All", value: "all" };
const introVideo = process.env.REACT_APP_CLOUDTRAKR_INTRO;

const useStyles = makeStyles()((theme) => ({
    table: {
        minWidth: 650,
    },
    root: {
        width: "fit-content",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        "& svg": {
            margin: theme.spacing(1.5),
        },
        "& hr": {
            margin: theme.spacing(0, 0.5),
        },
    },
    root1: {
        flexGrow: 1,
        width: "100%",
    },
    paper: {
        padding: theme.spacing(2),
    },
}));

const TAB_SET = {
    DASHBOARD: "DASHBOARD",
    MANAGEMENT_GROUPS: "MANAGEMENT_GROUPS",
    SUBSCRIPTIONS: "SUBSCRIPTIONS",
    RESOURCE_GROUPS_RESOURCES: "RESOURCE_GROUPS_RESOURCES",
    BILLING: "BILLING",
    RECOMMENDATIONS: "RECOMMENDATIONS",
    RESERVATIONS: "RESERVATIONS",
    COMPUTE: "COMPUTE",
    STORAGE: "STORAGE",
    FUNCTION_APPS: "FUNCTION_APPS",
    COSMOS_DB: "COSMOS_DB",
    SERVERLESS: "SERVERLESS",
    ORGANIZATIONS: "ORGANIZATIONS",
    ACCOUNTS: "ACCOUNTS",
    DYNAMO_DB: "DYNAMO_DB",
    LAMBDA: "LAMBDA",
    S3BUCKET:"S3BUCKET",
    SERVICES: "SERVICES",
    RDS:"RDS",
    RESOURCES:"RESOURCES"
};

export default function AWSHome() {
    const dispatch = useDispatch();
    const [isSticky, setSticky] = useState(false);
    // const [activeTab, setActiveTab] = useState(TAB_SET.DASHBOARD);

    const ref = useRef(null);
    const ref2 = useRef(null);
    const handleScroll = () => {
        if (ref.current) {
            setSticky(ref.current.getBoundingClientRect().top <= 120);
        }
        if (ref2.current) {
            setSticky(ref2.current.getBoundingClientRect().top <= 150);
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", () => handleScroll);
        };
    }, []);
    /** Sticky Header Config ends here **/
    const [tabSelect, setTabSelect] = useState(null);
    const [activeTabSub, setActiveTabSub] = useState(TAB_SET.DASHBOARD);

    const [accountListResponse, setAccountListResponse] = useState([]);
    const [accountList, setAccountList] = useState(null);
    const [spendByAccounts, setSpendByAccounts] = useState(null);
    const [spendBySubscription2, setSpendBySubscription2] = useState(null);
    const [spendBySubscription, setSpendBySubscription] = useState(null);
    const [period, setPeriod] = useState("1M");
    const [loading, setLoading] = useState(false);
    const [charts, setCharts] = useState(null);
    const [thisMonthSpend, setThisMonthSpend] = useState(null);
    const [lastMonthSpend, setLastMonthSpend] = useState(null);
    const [lastMonthDate, setLastMonthDate] = useState(null)
    const [lastMonthVariation, setLastMonthVariation] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [progress, setProgress] = useState(0);
    const {classes} = useStyles("USD");
    const [topFiveServerlessServices, setTopFiveServerlessServices] = useState([]);
    const [selectedSubscription, setSubscription] = useState(DEFAULT_SUBSCRIPTION);
    const [subscriptionList, setSubscriptionList] = useState([DEFAULT_SUBSCRIPTION]);
    const [serverlessList, setServerlessList] = useState([]);
    const [serverlessTotalValue, setServerlessTotalValue] = useState([]);
    const [parentWidth, setParentWidth] = useState([]);
    const [payerAccountsCount, setPayerAccountsCount] = useState(null);
    const [usageAccountsCount, setUsageAccountsCount] = useState(null);
    const [resourcesCount, setResourcesCount] = useState(null);
    const [servicesCount, setServicesCount] = useState(null);

    const { customerId, awsAccountStatus, loadingA, subscriptions, role,awsRecommenadationSummary } = useSelector((state) => ({
        customerId: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
        awsAccountStatus: state.accountStatusReducer.awsAccountStatus,
        loadingA: state.accountStatusReducer.loading,
        subscriptions: state.allHomeReducer.allSubscriptions?.aws,
        role: state.userDetailsReducer.role,
        awsRecommenadationSummary:selectedSubscription.title == "All" ? state?.awsRecommeandationSummaryReducer?.awsRecommendationSummaryData?.recommendationsSummary:state?.awsRecommeandationSummaryReducer?.awsRecommendationSummaryAccountData?.recommendationsSummary
    }));

    const subscriptionServerless = useSelector((state) => state.awsServerlessReducer?.charts);
    const serverlessSubName = useSelector((state) => state.awsServerlessReducer.subscriptionName);
    const awsOrgSpending = useSelector((state) => state.awsServerlessReducer.awsOrgSpending);
    const orgLoading = useSelector((state) => state.awsServerlessReducer.loading);
    const activeTab = useSelector((state) => state.appStateReducer.awsActiveTab);

    const AWSConnectionError = useSelector((state) => ({
        connectionError: state.connectionErrorReducer?.awsConnections?.[0]?.aws?.tenant,
    }));
    useEffect(() => {
        if (customerId) {
            if (selectedSubscription.title === "All")
            getAWSHome();
            setPayerAccountsCount(null);
            setResourcesCount(null);
            setServicesCount(null);
            getAWSHomeSummaryOverview();
        }
    }, [customerId, selectedSubscription]);

    function getAWSHomeSummaryOverview() {
        let extras = {};
        let API = REACT_APP_AWS_HOME_SUMMARY_OVERVIEW;
        if (selectedSubscription.title !== "All") {
            API = REACT_APP_AWS_ACCOUNT_SUMMARY_OVERVIEW;
            extras = {
                tenantId: selectedSubscription?.tenantId,
                subscriptionId: selectedSubscription?.value,
            };
        }
        axios
            .get(API, {
                params: {
                    customerId,
                    ...extras,
                },
                headers: {
                    "Ocp-Apim-Subscription-Key": REACT_APP_AWS_SUBSCRIPTION_KEY,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                if (response?.data?.data) {
                    setPayerAccountsCount(response.data.data?.SpendByPayerAccounts?.totalPayerAccount);
                    setUsageAccountsCount(response.data.data?.SpendByUsageAccounts?.totalUsageAccount);
                    setResourcesCount(response.data.data?.SpendByResources?.totalResources);
                    setServicesCount(response.data.data?.SpendByServices?.totalServices);
                }
            })
            .catch((e) => console.log(e));
    }

    function getAWSHome() {
        setLoading(true);
        axios
            .get(REACT_APP_AWS_HOME, {
                params: {
                    customerId,
                },
            })
            .then((response) => response.data)
            .then((responseJson) => {
                awsDataSetter(responseJson);
            })
            .finally(() => setLoading(false))
            .catch((error) => {
                console.error(error);
            });
    }

    function AccountStatus(id) {
        if (id) {
            dispatch(accountRequest(id));
        }
    }

    async function awsDataSetter(responseJson) {
        if ("statusCode" in responseJson) {
            if (responseJson.statusCode == 200) {
                const chartsData = responseJson.data?.charts;
                const { LastMonthSpend, AWSCloudSpending, SpendByAccounts, TopFiveServices, TopFiveLocations, SpendByCategory, TopFiveServerlessServices, SpendBySubscription = null } = chartsData;

                setThisMonthSpend(responseJson?.data?.charts?.ThisMonthSpend);
                setSpendByAccounts(responseJson?.data?.charts?.SpendByAccounts);
                setSpendBySubscription2(SpendBySubscription);
                setSpendBySubscription(SpendBySubscription);
                // setAccountList(spendByAccounts);
                setAccountList(responseJson?.data?.charts?.SpendByAccounts);
                setTopFiveServerlessServices(TopFiveServerlessServices);
                setLastMonthDate(LastMonthSpend)
                setLastMonthSpend(LastMonthSpend?.dataSet?.[0]?.value || 0);
                setLastMonthVariation(LastMonthSpend?.dataSet?.[1]?.value || 0);
                setCharts({
                    AWSCloudSpending,
                    SpendByAccounts,
                    TopFiveServices,
                    TopFiveLocations,
                    SpendByCategory,
                });
                setCurrency(responseJson?.currency || "USD");
                setServerlessList(TopFiveServerlessServices?.[period]);
                setServerlessTotalValue(TopFiveServerlessServices?.[period].totalCost);
            } else {
                nullifyData();
                // Toast.show(responseJson.msg);
            }
        } else {
            nullifyData();
            //  Toast.show('Something went wrong');
        }
    }

    function nullifyData() {
        setThisMonthSpend(null);
        setLastMonthSpend(null);
        setLastMonthVariation(null);

        setSpendByAccounts(null);
        setSpendBySubscription2(null);
        setSpendBySubscription(null);
        setAccountList(null);
        setTopFiveServerlessServices(null);
        setCharts(null);
        // setCurrency                     (null);
        setServerlessList(null);
        setServerlessTotalValue(null);
    }

    useEffect(() => {
        if (awsAccountStatus == null) {
            AccountStatus(customerId);
        }
        let obj = { customerId, view: "organizations" };
        if (awsOrgSpending === null) {
            dispatch(getAwsOrgSpendingRequest(obj));
        }
    }, [awsAccountStatus, customerId, awsOrgSpending]);

    useEffect(() => {
        if (customerId) {
            dispatch(getSubscriptionsRequest(customerId));
            if (selectedSubscription.title == "All") {
            dispatch(getAwsRecommendationSummaryRequest({customerId}))
            }else{
                dispatch(getAwsAccountRecommendationSummaryRequest({customerId,subscriptionId:selectedSubscription.value, tenantId:selectedSubscription.tenantId}))
            }
        }
    }, [customerId,selectedSubscription]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (subscriptions && subscriptions?.constructor.name == "Array") {
            const list = subscriptions
                .map((t) =>
                    t.subscriptions.map((s) => ({
                        tenantId: t.tenantId,
                        title: s?.displayName || s?.subName || s?.subscriptionId,
                        value: s.subscriptionId,
                    }))
                )
                .flat(1);
            setSubscriptionList((s) => [DEFAULT_SUBSCRIPTION, ...list]);
        }
    }, [subscriptions]);

    function onSubscriptionChange(subscription) {
        setSubscription(subscription);
        if (subscription.title != "All") {
            getSubscriptionData(subscription.value, subscription.tenantId);
            const { value, tenantId } = subscription;
            const customerId1 = customerId;
            const sub_value = { customerId1, tenantId, value };
            if (activeTab === TAB_SET.ORGANIZATIONS) handleTabSelection(TAB_SET.DASHBOARD);
            dispatch(awsServerlessRequest(sub_value));
        } else {
            getAWSHome();
        }
    }

    async function getSubscriptionData(value, tenantId) {
        setLoading(true);
        await axios
            .get(REACT_APP_AWS_SUMMARY, {
                params: {
                    customerId,
                    tenantId,
                    subscriptionId: value,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                awsDataSetter(response);
            })
            .finally(() => setLoading(false))
            .catch((error) => {
                console.error(error);
            });
    }

    function handleTabSelection(tab) {
        dispatch({
            type: SET_CLOUD_ACTIVE_TAB_REQUEST,
            payload: { cloud: "awsActiveTab", tab },
        });
    }

    return !loadingA ? (
        awsAccountStatus ? (
            <div
                style={{
                    backgroundColor: Color.background,
                    // paddingLeft: 10,
                    // paddingRight: 10,
                    minHeight: "95vh",
                }}
            >
                {loading ? (
                    <div className={classes.root1}>
                        {/* <LinearProgress variant="determinate" value={progress} /> */}
                        <DashboardLoader/>
                    </div>
                ) : charts == null && selectedSubscription.title == "All" ? (
                    <div>
                    <Paper elevation={3}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: 25,
                                flexDirection: "column",
                            }}
                        >
                            <h6>
                                AWS Account added successfully <DoneAllIcon style={{ color: "green" }} />
                            </h6>
                            <h5 style={{ color: "steelblue" }}>We are collecting and analyzing your data. Usually cloud providers takes less than 24 hours to provide data from your cloud accounts. Please check back CloudTrakr portal in 24 hours to see the deep visibility and optimization for your cloud spend.</h5>
                        </div>
                    </Paper>
                    <Paper elevation={3} style={{marginTop:8}}>
                        <div style={{display: "flex", flexDirection: "row",justifyContent: "space-between",padding:8 }}>
                        <ReactPlayer
                        style={{ margin: 8}}
                            width={"80%"}
                            height={"300px"}
                            url={introVideo}
                            controls
                            playing
                            muted
                            config={{
                                file: {
                                    attributes: {
                                        autoPlay: true,
                                        muted: true,
                                    },
                                },
                            }}
                        />
                        <div style={{ margin: 8, width: "80%" }}>
                            <Carousel controls={false} pause={"hover"}>
                                <Carousel.Item>
                                    <img style={{ height: 300, width: 300 }} className="d-block w-100" src={backgroundImage} alt="Second slide" />

                                    <Carousel.Caption>
                                        <h3>SPEND LESS DO MORE.</h3>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img style={{ height: 300, width: 300 }} className="d-block w-100" src={backgroundImage1} alt="Second slide" />

                                    <Carousel.Caption>
                                        <h3>VISUALIZE</h3>
                                        <p>Visualize cost breakdowns of each cloud.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img style={{ height: 300, width: 300 }} className="d-block w-100" src={backgroundImage2} alt="Third slide" />

                                    <Carousel.Caption>
                                        <h3>SET</h3>
                                        <p>Set budget and keep your expenses in check.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img style={{ height: 300, width: 300 }} className="d-block w-100" src={backgroundImage3} alt="Fourth slide" />

                                    <Carousel.Caption>
                                        <h3>GET</h3>
                                        <p>Get customized cost, security and performance recommendations.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                    </Paper>
                    </div>
                ) : (
                    <div className={classes.root1}>
                        {AWSConnectionError?.connectionError &&
                            AWSConnectionError?.connectionError?.map((r, i) => (
                                <ConnectionError row={r} date={r.lastModified} cloud={r.accountType} subscription={r.tenantId} status={r.status} errorDetail={r?.errors?.[0]?.message} />
                            ))}
                        <div style={{ paddingTop: 0.5, paddingLeft: 10, paddingRight: 10, }}>
                            <div
                                style={{
                                    paddingTop: 4,
                                    // backgroundColor: "white",
                                }}
                            >
                                {selectedSubscription.title == "All" ? (
                                    <BreadCrumbs parent={"Home"} child={"AWS"} parentLink={RoutesList.ALL} hasChild={true} hasSubChild={false} />
                                ) : (
                                    <BreadCrumbs parent={"Home"}
                                                 child={"AWS"}
                                                 subChild={selectedSubscription?.tenantId}
                                                 parentLink={RoutesList.ALL}
                                                 subchildLink={RoutesList.AWS_HOME}
                                                 hasChild={true}
                                                 hasSubChild={true}
                                                 hasthirdChild={true}
                                                 thirdchild={selectedSubscription?.title}
                                    />
                                )}
                                <div
                                    style={{
                                        flexDirection: "row",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        // alignItems: 'center'
                                    }}
                                >
                                    <div>
                                        <CostHeader
                                            logoSize={65}
                                            cloudName={"AWS"}
                                            currency={Format.formatCurrencySymbol(currency)}
                                            cost={thisMonthSpend?.totalCost || 0}
                                            cloudIcon={AWS_LOGO}
                                            lastMonthSpend={lastMonthSpend}
                                            // headerFontSize={'5ex'}
                                            color={"#000"}
                                            ExtraComponent={() => false}
                                            date={thisMonthSpend?.endDate || 0}
                                            startDate={thisMonthSpend?.startDate|| 0}
                                            lastMonthDate={lastMonthDate?.endDate}
                                            hasPotentialSavings={true}
                                            potentialSavings={awsRecommenadationSummary?.summaryPotentialSavingsPerMonth}
                                        >
                                            {usageAccountsCount != null && usageAccountsCount != undefined && selectedSubscription == DEFAULT_SUBSCRIPTION ? <CostHeaderDataComponent data={usageAccountsCount} title={"Accounts"} /> : null}
                                            {resourcesCount != null && resourcesCount != undefined ? <CostHeaderDataComponent data={resourcesCount} title={"Resources"} /> : null}
                                            {servicesCount != null && servicesCount != undefined && selectedSubscription !== DEFAULT_SUBSCRIPTION ? <CostHeaderDataComponent data={servicesCount} title={"Services"} /> : null}
                                            {awsRecommenadationSummary != null && awsRecommenadationSummary != undefined ? <> <CostHeaderDataComponent data={awsRecommenadationSummary?.summaryTotalInstance} title={"Instances"} /> </>: null}

                                            </CostHeader>
                                    </div>
                                    <div
                                        style={{
                                            marginTop: -25,
                                            flex: 0.25,
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >


                                        <div style={{}} className={`sticky-wrapper${isSticky ? " sticky-aws-daterange" : ""}`} ref={ref2}>
                                            <div className={"sticky-inner-aws-daterange"} style={{ marginTop: 10, backgroundColor: "white" }}>
                                                <Autocomplete
                                                    clearIndicator={true}
                                                    noOptionsText={'No account found'}
                                                    disableClearable={true}
                                                    title={"Accounts"}
                                                    value={selectedSubscription}
                                                    options={subscriptionList}
                                                    groupBy={(option) => option.tenantId}
                                                    renderOption={(state, option) => {
                                                        if (!option.title || option.value == "all") {
                                                            return <span onClick={() => onSubscriptionChange({ title: option.title, tenantId: option?.tenantId, value: option?.value })}
                                                                         className={'dropdown-subscription'}
                                                                         style={{paddingLeft: 15,  textTransform: "capitalize" }}>{option?.value || ""}</span>;
                                                        } else {
                                                            return (
                                                                <div className={'dropdown-subscription'}
                                                                     style={{paddingLeft: 30}}
                                                                     onClick={() => onSubscriptionChange({ title: option.title, tenantId: option?.tenantId, value: option?.value })}
                                                                >
                                                                    <span>{option?.title || ""}</span>
                                                                    <span style={{ fontSize: 10 }}>{option?.value || ""}</span>
                                                                </div>
                                                            );
                                                        }
                                                    }}
                                                    getOptionLabel={(option) => {
                                                        return option.title + "//" + option.value + "//" + option.tenantId;
                                                    }}
                                                    style={{ width: 370 }}
                                                    size={"small"}
                                                    onChange={(e, value) => onSubscriptionChange(value)}
                                                    renderInput={(params) => {
                                                        // console.log("ouy",params)
                                                        return (
                                                            <TextField
                                                                {...params}
                                                                inputProps={{...params.inputProps, value: params.inputProps.value?.split("//")?.[0]}}
                                                                label={
                                                                    <>
                                                                        <img src={AWS_LOGO} height={25} /> Accounts
                                                                    </>
                                                                }
                                                                variant="outlined"
                                                            />
                                                        );
                                                    }}
                                                />
                                                {selectedSubscription.title != "All" ? (
                                                    <div>
                                                        <img
                                                            src={org}
                                                            alt="account"
                                                            style={{
                                                                width: 20,
                                                                height: 20,
                                                                marginLeft: 8,
                                                            }}
                                                        />
                                                        <span style={{ fontSize: 12 }}>{selectedSubscription.value}</span>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<br />*/}
                        {/* -----------------------------------------------------------Tabed View------------------------------------------------------- */}

                        <AwsHomeTab
                            TAB_SET={TAB_SET}
                            activeTab={activeTab}
                            setActiveTab={handleTabSelection}
                            setTabSelect={setTabSelect}
                            charts={charts}
                            currency={currency}
                            //period={period}
                            selectedSubscription={selectedSubscription}
                            accountList={accountList}
                            spendBySubscription={spendBySubscription}
                            // serverlessList={serverlessList}
                            // serverlessTotalValue={serverlessTotalValue}
                            // subscriptionServerless={subscriptionServerless}
                            customerId={customerId}
                            parentWidth={parentWidth}
                            setParentWidth={setParentWidth}
                            awsOrgSpending={awsOrgSpending}
                            orgloading={orgLoading}
                        />
                    </div>
                )}
            </div>
        ) : (
            <ConenctAccountSkipPage hasAWS={true} hasGCP={false} hasAzure={false} cloudName={"your AWS"} role={role} />
        )
    ) : (
        <div className={classes.root}>
            {/* <LinearProgress variant="determinate" value={progress} /> */}
            <DashboardLoader/>
        </div>
    );
}

export function GridChart({ classes, charts, period, currency, title, data = null, ChartCategory, chartData }) {
    return <div className="chart-align">{data ? <ChartCategory mouseEffect={false} cost={data?.totalCost || 0} currency={currency} data={chartData} title={title} /> : <h4>No data found</h4>}</div>;
}
