import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import {getAwsAccountS3RecommendationSuccess,getAwsAccountS3RecommendationFailure } from "../actions/actions";
const {REACT_APP_GET_AWS_S3_ACCOUNT_RECOMMENDATION } = process.env;

function* getAwsAccountS3RecommendationData({ payload }) {
    try {
        const res = yield call(axios.post, REACT_APP_GET_AWS_S3_ACCOUNT_RECOMMENDATION, {
            
                customerId: payload.customerId,
                tenantId: payload.tenantId,
                subscriptionId: payload.subscriptionId,
        
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAwsAccountS3RecommendationFailure(statusCode));
            } else {
                yield put(getAwsAccountS3RecommendationSuccess(res.data));
            }
        } else {
            yield put(getAwsAccountS3RecommendationFailure(res));
        }
    } catch (error) {
        yield put(getAwsAccountS3RecommendationFailure(error));
    }
}

export default function* awsS3RecommendationSaga() {
        yield takeLatest(types.AWS_ACCOUNT_S3RECOMMENDATION_REQUEST, getAwsAccountS3RecommendationData);
}
