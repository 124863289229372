import * as types from "../actions/types";

const userState = {
    cosmosDBSubData: null,
    loading: false,
    error: null,
    cosmosTableData: null,
    cosmosTableLoading: null,
    cosmos3MMetric: null,
    cosmos3MMetricLoading: null,
};

function azureCosmosDBSubReducer(state = userState, { type, payload }) {
    switch (type) {
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                cosmosDBSubData: null,
                loading: false,
                error: null,
            };
        }
        case types.AZURE_COSMOSDB_SUB_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AZURE_COSMOSDB_SUB_SUCCESS: {
            return {
                ...state,
                cosmosDBSubData: payload.data,
                loading: false,
            };
        }
        case types.AZURE_COSMOSDB_SUB_FAILURE: {
            return {
                ...state,
                loading: false,
                cosmosDBSubData: null,
                error: payload,
            };
        }

        case types.AZURE_COSMOSDB_SUB_1Y_TABLE_DATA_REQUEST: {
            return {
                ...state,
                cosmosTableLoading: true,
                cosmosTableData: null
            };
        }
        case types.AZURE_COSMOSDB_SUB_1Y_TABLE_DATA_SUCCESS: {
            return {
                ...state,
                cosmosTableData: payload.data,
                cosmosTableLoading: false,
            };
        }
        case types.AZURE_COSMOSDB_SUB_1Y_TABLE_DATA_FAILURE: {
            return {
                ...state,
                cosmosTableLoading: false,
                cosmosTableData: null,
                error: payload,
            };
        }

        case types.AZURE_COSMOSDB_SUB_3M_METRIC_REQUEST: {
            return {
                ...state,
                cosmos3MMetricLoading: true,
                cosmos3MMetric: null
            };
        }
        case types.AZURE_COSMOSDB_SUB_3M_METRIC_SUCCESS: {
            return {
                ...state,
                cosmos3MMetric: payload.data,
                cosmos3MMetricLoading: false,
            };
        }
        case types.AZURE_COSMOSDB_SUB_3M_METRIC_FAILURE: {
            return {
                ...state,
                cosmos3MMetricLoading: false,
                cosmos3MMetric: null,
                error: payload,
            };
        }

        default:
            return state;
    }
}
export default azureCosmosDBSubReducer;
