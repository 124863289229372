import * as types from "../actions/types";

const userState = {
    awsTags: {
        MTD: null, //This month
        YTD: null,  //This year
        LastMonthSpend: null,
        MonthBeforeLastMonthSpend: null,
        LastThreeMonthSpend: null,
        LastTwelveMonthSpend: null,
        LastTwelveMonthSpendWithMTD: null,

        ThisQuarter: null, //This year quarter
        LastQuarter: null,
        loading: false
    },
    awsCostCategory: {
        MTD: null, //This month
        YTD: null,  //This year
        LastMonthSpend: null,
        MonthBeforeLastMonthSpend: null,
        LastThreeMonthSpend: null,
        LastTwelveMonthSpend: null,
        LastTwelveMonthSpendWithMTD: null,

        ThisQuarter: null, //This year quarter
        LastQuarter: null,
        loading: false
    },
    awsTagsLoading: false,
    awsCostCategoryLoading: false,
};

const mapper = {
    'MTD': 'MTD',
    'YTD': 'YTD',
    'LM': 'LastMonthSpend',
    'MBLM': 'MonthBeforeLastMonthSpend',
    'L3S': 'LastThreeMonthSpend',
    'L12S': 'LastTwelveMonthSpend',
    'L12SMTD': 'LastTwelveMonthSpendWithMTD',
    'TQ': 'ThisQuarter',
    'LQ': 'LastQuarter',
}

function awsTagsAndCostCategoryReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.AWS_TAGS_REQUEST: {
            return {
                ...state,
                awsTagsLoading: true
            };
        }
        case types.AWS_TAGS_SUCCESS: {
            const data = state;
            data['awsTags'][mapper[payload.period]] = payload.data;

            return {
                ...data,
                awsTagsLoading: false
            };
        }
        case types.AWS_TAGS_FAILURE: {
            return {
                ...state,
                awsTagsLoading: false
            };
        }

        case types.AWS_COST_CATEGORY_REQUEST: {
            return {
                ...state,
                awsCostCategoryLoading: true
            };
        }
        case types.AWS_COST_CATEGORY_SUCCESS: {
            const data = state;
            data['awsCostCategory'][mapper[payload.period]] = payload.data;

            return {
                ...data,
                awsCostCategoryLoading: false
            };
        }
        case types.AWS_COST_CATEGORY_FAILURE: {
            return {
                ...state,
                awsCostCategoryLoading: false
            };
        }

        default:
            return state;
    }
}
export default awsTagsAndCostCategoryReducer;
