import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "MakeWithStyles";
import { LinearProgress, Paper, Collapse, IconButton, CircularProgress } from "@mui/material";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import Format from "components/NumberFormat";
import moment from "moment";
import HtmlECTable from "components/table/HtmlECTable";
import ResourceGroupsIcon from "../../images/newCloudTrakrIcons/AzureResourceGroups.png";
import { getAzureResourceGroupsRequest, getAzureTenantResourceGroupsRequest } from "redux/actions/actions";
import TableSearch from "../../components/TableSearch";
import TableMonthlySpend from "../AllClouds/TableMonthlySpend";
import NO_DATA_AVAILABLE from "../../components/NO_DATA_AVAILABLE";

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        //marginBottom: 5,
    },
}));

export default function ResourceGroups({ showData, currency, customerId, spendByMonthResourceGroupData, loadingCalendarTableData, parentWidth }) {
    const {classes} = useStyles();
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [ytdSearch, setYTDSearch] = useState(null);

    const loading = useSelector((state) => state.azureRecommendationReducer.loading);
    let resourceGroups = useSelector((state) => state.azureRecommendationReducer.resourceGroups);
    let tenantResourceGroups = useSelector((state) => state.azureRecommendationReducer.tenantResourceGroups);
    let subresourceGroups = useSelector((state) => state.azureRecommendationReducer.subresourceGroups);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    useEffect(() => {
        if ("all" in showData) {
            if (showData.all != null) {
                if (resourceGroups == null) {
                    dispatch(getAzureResourceGroupsRequest(customerId));
                }
            } else {
                const { tenantId, subscriptionId } = showData;
                dispatch(
                    getAzureResourceGroupsRequest({
                        customerId,
                        tenantId,
                        subscriptionId,
                    })
                );
            }
        } else {
            let obj = { customerId: customerId, tenantId: showData.tenantId };
            dispatch(getAzureTenantResourceGroupsRequest(obj));
        }
    }, [customerId, showData]);
    return loading ? (
        <div className={classes.root}>
            <LinearProgress />
        </div>
    ) : (
        <div>
            <ContainerCardWithDateRange
                title={"Spend by Resource groups"}
                titleIcon={ResourceGroupsIcon}
                // titleIconHeight={45}
                defaultPeriod={"1M"}
                showDateRange={true}
                collapsible={true}
                datePeriod={({ period }) => ({
                    start: "__",
                    end: "__",
                    rawStart:
                        "all" in showData
                            ? showData.all != null
                                ? moment(resourceGroups?.SpendByResourceGroup[period]?.startDate, "YYYYMMDD")
                                : subresourceGroups && moment(subresourceGroups?.SpendByResourceGroup[period]?.startDate, "YYYYMMDD")
                            : moment(tenantResourceGroups?.SpendByResourceGroup[period]?.startDate, "YYYYMMDD"),
                    rawEnd:
                        "all" in showData
                            ? showData.all != null
                                ? moment(resourceGroups?.SpendByResourceGroup[period]?.endDate, "YYYYMMDD")
                                : subresourceGroups && moment(subresourceGroups?.SpendByResourceGroup[period]?.endDate, "YYYYMMDD")
                            : moment(tenantResourceGroups?.SpendByResourceGroup[period]?.endDate, "YYYYMMDD"),
                })}
                totalCost={({ period }) =>
                    "all" in showData
                        ? showData.all != null
                            ? currency + Format.formatNumber(resourceGroups?.SpendByResourceGroup[period]?.totalCost)
                            : currency + Format.formatNumber(subresourceGroups?.SpendByResourceGroup[period]?.totalCost)
                        : currency + Format.formatNumber(tenantResourceGroups?.SpendByResourceGroup[period]?.totalCost)
                }
                datePeriodEnabled={true}
                showDatePeriod={(period) =>
                    "all" in showData
                        ? showData.all != null
                            ? !!resourceGroups != null && resourceGroups?.SpendByResourceGroup[period]?.resourceGroup?.length
                            : !!subresourceGroups != null && subresourceGroups?.SpendByResourceGroup[period]?.resourceGroup?.length
                        : !!tenantResourceGroups != null && tenantResourceGroups?.SpendByResourceGroup[period]?.resourceGroup?.length
                }
                onPeriodChange={() => handleChangeRowsPerPage({ target: { value: 10 } })}
            >
                {({ period }) => (
                    <>
                        {"all" in showData ? (
                            showData.all != null ? (
                                <div>
                                    {resourceGroups != null ? (
                                        resourceGroups && "resourceGroup" in resourceGroups?.SpendByResourceGroup[period] ? (
                                            <HtmlECTable
                                                tableRow={resourceGroups?.SpendByResourceGroup[period]?.resourceGroup}
                                                data={resourceGroups?.SpendByResourceGroup[period]}
                                                currency={currency}
                                            />
                                        ) : (
                                            <div style={{ textAlign: "center" }}>No data found</div>
                                        )
                                    ) : null}
                                </div>
                            ) : (
                                <div>
                                    {subresourceGroups != null ? (
                                        subresourceGroups && "resourceGroup" in subresourceGroups?.SpendByResourceGroup[period] ? (
                                            <HtmlECTable
                                                tableRow={subresourceGroups?.SpendByResourceGroup[period]?.resourceGroup}
                                                data={subresourceGroups?.SpendByResourceGroup[period]}
                                                currency={currency}
                                            />
                                        ) : (
                                            <div style={{ textAlign: "center" }}>No data found</div>
                                        )
                                    ) : null}
                                </div>
                            )
                        ) : (
                            <div>
                                {tenantResourceGroups != null ? (
                                    tenantResourceGroups && "resourceGroup" in tenantResourceGroups?.SpendByResourceGroup[period] ? (
                                        <HtmlECTable
                                            tableRow={tenantResourceGroups?.SpendByResourceGroup[period]?.resourceGroup}
                                            data={tenantResourceGroups?.SpendByResourceGroup[period]}
                                            currency={currency}
                                        />
                                    ) : (
                                        <div style={{ textAlign: "center" }}>No data found</div>
                                    )
                                ) : null}
                            </div>
                        )}
                    </>
                )}
            </ContainerCardWithDateRange>
            <br />
            <div style={{}}>
                <ContainerCardWithDateRange title={"Spend by months"} titleIcon={ResourceGroupsIcon} showDateRange={false} collapsible={true}>
                    {() => (
                        <>
                            {loadingCalendarTableData || spendByMonthResourceGroupData ? <TableSearch onSearch={setYTDSearch} /> : null}
                            {loadingCalendarTableData || spendByMonthResourceGroupData ? (
                                <TableMonthlySpend
                                    loading={loadingCalendarTableData}
                                    cols={14}
                                    subscriptionNameAsOverlay={false}
                                    subscriptionLevel={true}
                                    tableLabel={"Resource group"}
                                    width={parentWidth}
                                    data={spendByMonthResourceGroupData?.list?.filter((s) => {
                                        if (ytdSearch && ytdSearch.toString().length) {
                                            return !!TableSearch.doesItIncludes([s.cloud, s.subscriptionId, s.name], ytdSearch);
                                        }
                                        return true;
                                    })}
                                    staticRows={spendByMonthResourceGroupData?.other}
                                    currency={currency}
                                    showCloud={false}
                                />
                            ) : (
                                <NO_DATA_AVAILABLE height={"auto"} />
                            )}
                        </>
                    )}
                </ContainerCardWithDateRange>
            </div>
        </div>
    );
}
