import * as types from "../actions/types";

const userState = {
    awsRecommendationSummaryData: null,
    error: null,
    awsRecommendationSummaryLoading: false,
    awsAccountRecommendationSummaryLoading: false,
    awsRecommendationSummaryAccountData:null,
};

function awsRecommeandationSummaryReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.AWS_RECOMMENDATION_SUMMARY_REQUEST: {
            return {
                ...state,
                awsRecommendationSummaryLoading: true,
            };
        }
        case types.AWS_RECOMMENDATION_SUMMARY_SUCCESS: {
            return {
                ...state,
                awsRecommendationSummaryData: payload.data,
                awsRecommendationSummaryLoading: false,
            };
        }
        case types.AWS_RECOMMENDATION_SUMMARY_FAILURE: {
            return {
                ...state,
                awsRecommendationSummaryLoading: false,
                awsRecommendationSummaryData: null,
                error: payload,
            };
        }

        case types.AWS_ACCOUNT_RECOMMENDATION_SUMMARY_REQUEST: {
            return {
                ...state,
                awsAccountRecommendationSummaryLoading: true,
            };
        }
        
        case types.AWS_ACCOUNT_RECOMMENDATION_SUMMARY_SUCCESS: {
            return {
                ...state,
                awsRecommendationSummaryAccountData: payload.data,
                awsAccountRecommendationSummaryLoading: false,
            };
        }

        case types.AWS_ACCOUNT_RECOMMENDATION_SUMMARY_FAILURE: {
            return {
                ...state,
                awsAccountRecommendationSummaryLoading: false,
                awsRecommendationSummaryAccountData: null,
                error: payload,
            };
        }
       
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                awsRecommendationSummaryData: null,
                error: null,
                awsRecommendationSummaryLoading: false,
                awsAccountRecommendationSummaryLoading: false,
                awsRecommendationSummaryAccountData:null
            };
        }

        default:
            return state;
    }
}
export default awsRecommeandationSummaryReducer;
