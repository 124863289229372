import React, { useEffect, useRef, useState } from "react";
import {
  select, selectAll,
  scaleBand,
  axisBottom,
  stack,
  max,
  scaleLinear,
  axisLeft,
    extent,
  stackOrderAscending, sum,
  timeFormat
} from "d3";
import * as d3 from 'd3';
import GetWindowDimension from "../../components/HOC/GetWindowDimension";
import { tooltipVB } from "../../components/D3Charts/ReactDrillDownd3BarChart";
import moment from "moment";
import {bytesToSize, convertToInternationalCurrencySystem} from "../../util/Miscellaneous";

function LineChart({ data, height, width, currency, unit, onMouseOverData}) {
  const svgRef = useRef();
  const [total, setTotal] = useState(0)
  const margin = {
    top: 40,
    // right: 190,
    right: 0,
    bottom: 60,
    // left: 80,
    left: 5,
  }
let chartHeight = height - 10;

  function drawSVG() {
    select(svgRef.current).selectAll("g").remove();
    select(svgRef.current).selectAll("text").remove();
    select(svgRef.current).selectAll("path").remove();
    console.log(unit)
    // const data = []
  

    // const minDate = new Date(result[0]);
    // const maxDate = new Date(result[result.length - 1]);

    const x = d3
      // .scaleTime()
      .scaleUtc()
      // .scaleLinear()
      // .domain([minDate, maxDate])
      .domain(extent(data, d => d.date))
      .range([margin.left, width - margin.right])

    const maxValue = max(data || [], d => d.value);
    const y = scaleLinear()
      .domain([0, maxValue*1.2])
        .nice()
      .range([chartHeight - margin.bottom, margin.top]);

    const xAxis = (g) => g.attr("transform", `translate(0,${chartHeight - margin.bottom })`).style("color", "black")
      .call(axisBottom(x).ticks(10).tickFormat((d, i) =>{
        return moment(d).format("MM/DD/YYYY")
      }))

    const yAxis = g => g
      .attr("transform", `translate(${margin.left},0)`)
      .call(axisLeft(y).ticks(8).tickFormat((d,i)=> {
        return {
          "Count":convertToInternationalCurrencySystem(d),
          "Milliseconds":convertToInternationalCurrencySystem(d),
          "Bytes":bytesToSize(d),
          "Percent": d + "%"
        }[unit]
      }))
      .call(g => g.select(".domain"))
      .call(g => g.select(".tick:last-of-type text").clone()
        .attr("x", 3)
        .attr("text-anchor", "start")
        .attr("font-weight", "bold")
        // .text(data.y)
      )

    const svg = select(svgRef.current)
        // .append("g")
        .attr("viewBox", [0, 0, width, chartHeight]).append("g");

    var line;
    if(data.length == 1){
      line = d3.line()
          .defined(d => !isNaN(d.value))
          .x((d, i) => i==0?margin.left * 2:i==2?width - margin.right *2:x(d.date))
          .y(d => y(d.value))
          .curve(d3.curveMonotoneX)(new Array(3).fill(...data));
    } else {
      line = d3.line()
          .defined(d => !isNaN(d.value))
          .x(d => x(d.date))
          .y(d => y(d.value))
          .curve(d3.curveMonotoneX)(data);
    }

    svg.append("g").attr("class", "x_axes").call(xAxis) .selectAll("text")
    .style("text-anchor", "end")
    .attr("dx", "-.8em")
    .attr("dy", ".15em")
    .attr("transform", "rotate(-30)");

    svg.append("g").attr("class", "y_axes").call(yAxis);

    svg.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#0078d4")
      .attr("stroke-width", 4)
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("d", line)
      .on("mouseover", function (e, j) {
        select(this).attr("stroke-width", 6);
        svg.selectAll('.point_circle').attr("r", 7);
      })
      .on("mouseout", function (e, y, r, ee,) {
        select(this).attr("stroke-width", 4);
        svg.selectAll('.point_circle').attr("r", 0);
      });

    const circleContainer = svg.append('g');
    data.forEach((d,i)=>{
      circleContainer.append("circle")
      .attr("class", "point_circle")
      .attr("index", i)
      .attr("cx", x(d.date))
      .attr("cy", y(d.value))
      .attr("fill", "#0078d4")

      circleContainer.append('rect')
      .attr("x", x(d.date))
      .attr("y", 0)
      // .attr('width', (x(d.date) + (x(data?.[i+1]?.date || null) || 0))/2)
      .attr('width', 20)
      .attr('height', '100%')
      .attr("data", d.value)
      .style("border", "1px solid")
      .attr('fill', '#ffffff00')
      .on("mouseover", function () {
        const g = select(this).attr("data");
        svg.selectAll(".point_circle[index='"+ i +"']").attr("r", 8)
        onMouseOverData(g)
      })
      .on("mouseout", function () {
        svg.selectAll(".point_circle[index='"+ i +"']").attr("r", 0)
        onMouseOverData(null);
      })
    })

    svg.selectAll(".x_axes > g > text").style("font-size", "16px").style("font-weight", "bold");
    svg.selectAll(".y_axes > g > text").style("font-size", "18px").style("font-weight","bold");
  }

  function drawNoData() {
    select(svgRef.current).select("g").remove();
    select(svgRef.current).append("g")
      .attr("transform", `translate(${(width - margin.right - margin.left) / 2}, ${chartHeight / 4})`)
      .style("display", "flex")
      .style("align-items", "center")
      .style("justify-content", "center")
      .append('text')
      .text("No data found")
  }

  useEffect(() => {
    if (data && data.length) {
      drawSVG();
    }
    else drawNoData();
  }, [data, height, width]);

  return (
    <svg ref={svgRef} width={"100%"} height={chartHeight / 2} />
  );
}

export default GetWindowDimension(LineChart);
