import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import {getAwsAccountDynamoDbRecommendationFailure,getAwsAccountDynamoDbRecommendationSuccess,getAwsDynamoDbRecommendationFailure,getAwsDynamoDbRecommendationSuccess} from "../actions/actions";
const {REACT_APP_GET_AWS_ACCOUNT_DYNAMODB_RECOMMENDATION,REACT_APP_GET_AWS_DYNAMODB_RECOMMENDATION } = process.env;

function* getawsEC2RecommendationSagaData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_GET_AWS_DYNAMODB_RECOMMENDATION, {  
            params: {   
                customerId: payload.customerId,            
            },
           
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAwsDynamoDbRecommendationFailure(statusCode));
            } else {
                yield put(getAwsDynamoDbRecommendationSuccess(res.data));
            }
        } else {
            yield put(getAwsDynamoDbRecommendationFailure(res));
        }
    } catch (error) {
        yield put(getAwsDynamoDbRecommendationFailure(error));
    }
}
function* getawsEC2RecommendationAccountSagaData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_GET_AWS_ACCOUNT_DYNAMODB_RECOMMENDATION, {  
            params: {   
                customerId: payload.customerId,
                tenantId:payload.tenantId,
                subscriptionId:payload.subscriptionId
            },
           
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAwsAccountDynamoDbRecommendationFailure(statusCode));
            } else {
                yield put(getAwsAccountDynamoDbRecommendationSuccess(res.data));
            }
        } else {
            yield put(getAwsAccountDynamoDbRecommendationFailure(res));
        }
    } catch (error) {
        yield put(getAwsAccountDynamoDbRecommendationFailure(error));
    }
}


export default function* awsDynamoDBRecommendationSaga() {
    yield takeLatest(types.AWS_ACCOUNT_RECOMMENDATION_DYNAMODB_REQUEST, getawsEC2RecommendationAccountSagaData);
    yield takeLatest(types.AWS_RECOMMENDATION_DYNAMODB_REQUEST, getawsEC2RecommendationSagaData);

}
