import React, { useState, useEffect } from "react";
import "../../pages/DashboardAccountsHome/awsOrgData/style.css";
import { makeStyles, withStyles } from "MakeWithStyles";
import { Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, IconButton, Collapse, Box } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Format from "components/NumberFormat";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import aws_org from "../../images/newCloudTrakrIcons/AWSOrganizations.png";
import { Divider } from "@mui/material";
import AwsSpendingTable, { ExpandableTableAws } from "./AwsSpendingTable";
import AwsServicesTable from "./AwsServicesTable";
import DateRange from "components/DateRange";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "../TablePaginationActions";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import gcp_billing from "../../images/gcp_billing.svg";
import moment from "moment";

const StyledTableCell = withStyles(TableCell,(theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: theme.spacing(1.5),
    },
}));
const StyledTableRow = withStyles(TableRow,(theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            // backgroundColor: theme.palette.action.hover,
        },
    },
}));
const useRowStyles = makeStyles()({
    root: {
        "& > *": {
            borderBottom: "unset",
            padding: 3.5,
        },
    },
    table: {
        minWidth: 100,
    },
    container: {
        maxHeight: 440,
    },
    container1: {
        maxHeight: 400,
    },
});

export function AwsOrgTotal(data, period) {
    let rootTotal = [];
    const org = [];
    const dateData = (data.Account?.[0]?.billing?.[0]?.data?.SpendByAccounts?.[period]) || null;
    if (data && data != null) {
        //  let Accountname = [];

        data.Account.map((i) => org.push(i));
        data.OrgUnits.map((i) => org.push(i));
        if ("Account" in data) {
            if (data.Account.length > 0) {
                data.Account.map((i, j) => {
                    i.billing.map((i, j) => {
                        rootTotal.push(i.data?.SpendByAccounts[period]?.totalCost || 0);
                    });
                });
            } else {
                rootTotal.push(0);
            }
        }
        if ("OrgUnits" in data) {
            data.OrgUnits.map((i, j) => {
                if (i.Account.length > 0) {
                    i.Account.map((i) => {
                        i.billing.map((i, j) => {
                            rootTotal.push(i.data?.SpendByAccounts[period]?.totalCost || 0);
                        });
                    });
                } else {
                    rootTotal.push(0);
                }
            });
        }
    }
    return { rootTotal, org, startDate: dateData?dateData?.startDate:null, endDate: dateData?dateData?.endDate:null };
}

export default function AwsOrgTable({ data, currency, show }) {
    const {classes} = useRowStyles();
    const [root, setRoot] = useState({
        name: "",
        rootId: "",
        // aname: "",
    });
    const [rootTotal, setRootTotal] = useState();
    const [datePeriod, setPeriod] = useState("1M");
    const [orgData, setOrgData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [startEndDate, setStartEndDate] = useState({});

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    useEffect(() => {
        let d = AwsOrgTotal(data, datePeriod);
        setRoot({
            name: data.Name,
            rootId: data.Id,
            // aname: Accountname,
        });

        setOrgData(d.org);
        setRootTotal(d.rootTotal.reduce((a, b) => Number(a) + Number(b), 0));
        setStartEndDate({
            startDate: d?.startDate,
            endDate: d?.endDate
        })
    }, [datePeriod, data]);
    return (
        <ContainerCardWithDateRange title={'AWS Organizations'} defaultPeriod={"1M"} titleIcon={aws_org}
                                    // ExtraHeaderComponent={({period})=>(
                                    //     billingAccount != null && billingAccount?.SpendByProjects?.[period]?.dataSet?.length ? (
                                    //         <div style={{ marginTop: 4 }}>
                                    //             <span>{moment(billingAccount?.SpendByProjects?.[period]?.startDate, "YYYYMMDD").format("MMM DD, YYYY")}</span> -{" "}
                                    //             <span>{moment(billingAccount?.SpendByProjects?.[period]?.endDate, "YYYYMMDD").format("MMM DD, YYYY")}</span>
                                    //         </div>
                                    //     ) : null
                                    // )}
                                    // MiddleComponent={({period})=>(
                                    //     <span style={{display: "flex", justifyContent: "flex-end"}}>
                                    //            Total: {currency}{!isNaN(Format.formatNumber(Number((billingAccount?.SpendByProjects?.[period]?.totalCost)).toFixed(2)))?
                                    //         Format.formatNumber(Number(billingAccount?.SpendByProjects?.[period]?.totalCost).toFixed(2)):
                                    //         "__.__"}
                                    //         </span>
                                    // )}
                                    collapsible={true}
                                    datePeriod={({period})=>({
                                        rawStart: moment(startEndDate?.startDate, "YYYYMMDD"),
                                        rawEnd: moment(startEndDate?.endDate, "YYYYMMDD"),
                                        start: "__",
                                        end: "__"
                                    })}
                                    totalCost={({period})=>(
                                        currency + Format.formatNumber(rootTotal)
                                        // Format.formatNumber(allContainers?.[period]?.totalCost)
                                    )}
                                    datePeriodEnabled={true}
                                    showDatePeriod={(period)=>true
                                        // !!allContainers != null && allContainers?.[period]?.resources?.length
                                    }
                                    onPeriodChange={(period) => {
                                        setPeriod(period)
                                        handleChangeRowsPerPage({target: {value: 10}})
                                    }}
        >
            {({period})=>(
            rootTotal && rootTotal > 0 ? (
                    <Paper elevation={0}>
                        <TableContainer className={classes.container}>
                            <Table className={classes.table} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell />
                                        <StyledTableCell align="left">
                                            <div style={{ marginRight: 200 }}>
                                                <span className="name">{root.name}</span> :<span className="root1">{root.rootId}</span>
                                            </div>
                                        </StyledTableCell>

                                        <StyledTableCell align="center">
                                            {currency}
                                            <span className="name">{rootTotal && Format.formatNumber(rootTotal)}</span>
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>{data && orgData && orgData ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((i, j) => <ExpandRowOrg row1={i} currency={currency} period={period} />)}</TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={orgData?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                    </Paper>
            ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>No data found</div>
            ))}
        </ContainerCardWithDateRange>
    );
}

export function IndividualAwsOrg(row1, period) {
    let c = [];
    let chartData = [];
    if (row1 && "billing" in row1) {
        row1.billing.map((i, j) => {
            c.push(i.data.SpendByAccounts[period].totalCost || 0);
        });
        chartData.push({
            label: row1.Name,
            value: c.reduce((a, b) => Number(a) + Number(b), 0).toFixed(2),
        });
        let cData = chartData[0];
        return { cData, c };
    } else {
        row1.Account.map((i, j) => {
            if (row1.Account.length > 0) {
                if (i.billing.length > 0) {
                    i.billing.map((i) => c.push(i.data?.SpendByAccounts[period].totalCost || 0));
                } else {
                    c.push(0.0);
                }
            } else {
                c.push(0.0);
            }
        });
        chartData.push({
            label: row1.Name,
            value: c.reduce((a, b) => Number(a) + Number(b), 0).toFixed(2),
        });

        let cData = chartData[0];
        return { cData, c };
    }
}

function ExpandRowOrg(props) {
    const { row1, currency, period } = props;
    const [open, setOpen] = useState(false);
    const [list, setList] = useState(null);
    const [cost, setCost] = useState([]);
    const {classes} = useRowStyles();

    useEffect(() => {
        let Cd = IndividualAwsOrg(row1, period);
        setCost(Cd.c);
    }, [row1, period]);

    return (
        <React.Fragment>
            <StyledTableRow hover className={classes.root} key={row1.Name}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        edge="end"
                        size="small"
                        style={{ marginRight: -20 }}
                        onClick={() => {
                            setOpen(!open);
                            setList(row1.Name);
                        }}
                    >
                        {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                    </IconButton>
                </TableCell>

                <TableCell component="th" scope="row" size="small" padding="none">
                    <div style={{}}>
                        <div>
                            {"Arn" in row1 ? <FolderOutlinedIcon /> : <img style={{ width: 24, height: 24, marginRight: 4 }} src={aws_org} />}
                            <span style={{ marginLeft: 3 }}>{row1.Name} </span>
                        </div>

                        <span className="id">{row1.Id}</span>
                    </div>
                </TableCell>

                <TableCell align="center">{currency + cost.reduce((a, b) => Format.formatNumber(Number(Number(a) + Number(b)).toFixed(2)), 0)}</TableCell>
            </StyledTableRow>

            <ExpandLavelDataRG data={row1} open={open} select={list} currency={currency} period={period} />
        </React.Fragment>
    );
}

function ExpandLavelDataRG({ data, open, select, currency, period }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={4}>
                            {data && "billing" in data ? (
                                data.billing.map((i, j) =>
                                    Object.keys(i.data?.SpendByAccounts[period]).length > 0 ? (
                                        <AwsSpendingTable tableRow={i.data?.SpendByAccounts[period]?.accounts} data={i.data?.SpendByAccounts[period]} currency={currency} />
                                    ) : (
                                        <div style={{ display: "flex", justifyContent: "center" }}>No data for {period}</div>
                                    )
                                )
                            ) : data && "OrgUnits" in data && "Account" in data ? (
                                data.OrgUnits.length > 0 || data.Account.length > 0 ? (
                                    <AwsOrgTable data={data} period={period} currency={currency} show={false} />
                                ) : (
                                    <div style={{ display: "flex", justifyContent: "center" }}>No data for {period}</div>
                                )
                            ) : null}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
