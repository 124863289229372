import * as types from "../actions/types";

const userState = {
    azureStorageData: null,
    error: null,
    loading: false,
    azureStorageTenantData:null,
    azureStorageSubscriptionData:null,
};

function azureStorageReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.AZURE_STORAGE_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AZURE_STORAGE_SUCCESS: {
            return {
                ...state,
                azureStorageData: payload.data,
                loading: false,
            };
        }
        case types.AZURE_STORAGE_FAILURE: {
            return {
                ...state,
                loading: false,
                azureStorageData: null,
                error: payload,
            };
        }
        case types.AZURE_STORAGE_TENANT_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AZURE_STORAGE_TENANT_SUCCESS: {
            return {
                ...state,
                azureStorageTenantData: payload.data,
                loading: false,
            };
        }
        case types.AZURE_STORAGE_TENANT_FAILURE: {
            return {
                ...state,
                loading: false,
                azureStorageTenantData: null,
                error: payload,
            };
        }
        case types.AZURE_STORAGE_ACCOUNT_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AZURE_STORAGE_ACCOUNT_SUCCESS: {
            return {
                ...state,
                azureStorageSubscriptionData: payload.data,
                loading: false,
            };
        }
        case types.AZURE_STORAGE_ACCOUNT_FAILURE: {
            return {
                ...state,
                loading: false,
                azureStorageSubscriptionData: null,
                error: payload,
            };
        }

       
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                azureStorageDataData: null,
                error: null,
                loading: false,
                azureStorageSubscriptionData:null
            };
        }

        default:
            return state;
    }
}
export default azureStorageReducer;
