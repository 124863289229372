import * as types from "../actions/types";

const userState = {
    awsDynamoDBRecommendationData:null,
    awsDynamoDBAccountRecommendationData:null,
    error: null,
    loading: false,
    
};

function awsDynamoDBRecommendationReducer(state = userState, { type, payload }) {
    switch (type) {
       
        case types.AWS_RECOMMENDATION_DYNAMODB_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AWS_RECOMMENDATION_DYNAMODB_SUCCESS: {
            return {
                ...state,
                awsDynamoDBRecommendationData: payload.data,
                loading: false,
            };
        }
        case types.AWS_RECOMMENDATION_DYNAMODB_FAILURE: {
            return {
                ...state,
                loading: false,
                awsDynamoDBRecommendationData: null,
                error: payload,
            };
        }
        case types.AWS_ACCOUNT_RECOMMENDATION_DYNAMODB_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AWS_ACCOUNT_RECOMMENDATION_DYNAMODB_SUCCESS: {
            return {
                ...state,
                awsDynamoDBAccountRecommendationData: payload.data,
                loading: false,
            };
        }
        case types.AWS_ACCOUNT_RECOMMENDATION_DYNAMODB_FAILURE: {
            return {
                ...state,
                loading: false,
                awsDynamoDBAccountRecommendationData: null,
                error: payload,
            };
        }
       
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                awsDynamoDBAccountRecommendationData:null,
                awsDynamoDBRecommendationData:null,
                error: null,
                loading: false,
                
            };
        }

        default:
            return state;
    }
}
export default awsDynamoDBRecommendationReducer;
