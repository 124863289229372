import * as types from "../actions/types";

const userState = {
    ServicesData: null,
    ServicesAccountData: null,
    ServicesProjectAccountData: null,
    error: null,
    loading: false,
    ServicesAccountLoading: false,
    ServicesProjectAccountLoading: false,
};

function gcpServicesReducer(state = userState, { type, payload }) {
    switch (type) {
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                ServicesData: null,
                ServicesAccountData: null,
                ServicesProjectAccountData: null,
                error: null,
                loading: false,
                ServicesAccountLoading: false,
                ServicesProjectAccountLoading: false,
            };
        }
        case types.GCP_SERVICES_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.GCP_SERVICES_SUCCESS: {
            return {
                ...state,
                ServicesData: payload.data,
                loading: false,
            };
        }
        case types.GCP_SERVICES_FAILURE: {
            return {
                ...state,
                loading: false,
                ServicesData: null,
                error: payload,
            };
        }

        case types.GCP_SERVICES_ACCOUNT_REQUEST: {
            return {
                ...state,
                ServicesAccountLoading: true,
            };
        }
        case types.GCP_SERVICES_ACCOUNT_SUCCESS: {
            return {
                ...state,
                ServicesAccountData: payload.data,
                ServicesAccountLoading: false,
            };
        }
        case types.GCP_SERVICES_ACCOUNT_FAILURE: {
            return {
                ...state,
                ServicesAccountLoading: false,
                ServicesAccountData: null,
                error: payload,
            };
        }

        case types.GCP_SERVICES_PROJECT_ACCOUNT_REQUEST: {
            return {
                ...state,
                ServicesProjectAccountLoading: true,
            };
        }
        case types.GCP_SERVICES_PROJECT_ACCOUNT_SUCCESS: {
            return {
                ...state,
                ServicesProjectAccountData: payload.data,
                ServicesProjectAccountLoading: false,
            };
        }
        case types.GCP_SERVICES_PROJECT_ACCOUNT_FAILURE: {
            return {
                ...state,
                ServicesProjectAccountLoading: false,
                ServicesProjectAccountData: null,
                error: payload,
            };
        }

        default:
            return state;
    }
}
export default gcpServicesReducer;
