import React, {useEffect, useRef, useState} from 'react';
import {Box, Paper, Typography} from "@mui/material";
import moment from "moment";
import BlueButton from "../components/BlueButton";
import MaterialBlueButton from "../components/MaterialBlueButton";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useNavigate} from "react-router-dom";
import RoutesList from "../routes/RoutesList";
import Button from "@mui/material/Button";
import copyicons from "../images/copy.svg";
import {CUSTOMER_STATUS} from "../util/AppConstants";
import axios from '../connection/axios';
import {Alert} from "@mui/lab";
import ReactPlayer from "react-player";
import Carousel from "react-bootstrap/Carousel";
import backgroundImage from "../images/bg1.jpg";
import backgroundImage1 from "../images/bg2.jpg";
import backgroundImage2 from "../images/bg3.jpg";
import backgroundImage3 from "../images/bg4.jpg";

const {REACT_APP_GET_USER_MANAGEMENT, REACT_APP_GET_INVITED_TEAMS} = process.env;
const introVideo = process.env.REACT_APP_CLOUDTRAKR_INTRO;

function TrialExpired(props) {
    const { paymentStatus } = props;
    const [subscriptionStart, setSubscriptionStart] = useState("");
    const [subscriptionEnd, setSubscriptionEnd] = useState("");
    const [copySuccess, setCopySuccess] = useState("");
    const location = useLocation();
    const mybutton = useRef();
    const textAreaRef = useRef();
    const dispatch = useDispatch();
const navigate  = useNavigate();

    const { name, email, customerId, customerIdLocal, subscriptionInfo, companyName, companyLogo, customerStatus } = useSelector((state) => ({
        name: state.userDetailsReducer.name,
        email: state.userDetailsReducer.email,
        customerId: state.userDetailsReducer.id,
        customerIdLocal: state.userDetailsReducer.customerId,
        subscriptionInfo: state.userDetailsReducer.paymentData,
        companyLogo: state.userDetailsReducer.companyLogo,
        companyName: state.userDetailsReducer.companyName,
        customerStatus: state.userDetailsReducer.customerStatus,
    }));

    const userData = useSelector((state) => ({
        name: state.userDetailsReducer.name,
        email: state.userDetailsReducer.email,
        id: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
        companyName: state.userDetailsReducer.companyName,
        companyLogo: state.userDetailsReducer.companyLogo,
    }));


    useEffect(() => {
        if (subscriptionInfo) {
            setSubscriptionStart(moment.unix(subscriptionInfo?.current_term_start || 0).format("MMMM DD, YYYY"));
            // setSubscriptionEnd(
            //     moment
            //         .unix(subscriptionInfo?.trial_end_date || 0)
            //         .format("MMMM DD, YYYY")
            //         .toString()
            // );
            setSubscriptionEnd(
                moment(subscriptionInfo?.trial_end_date || 0)
                    .format("MMMM D, YYYY")
                    .toString()
            );
        }
    }, [subscriptionInfo]);

    useEffect(()=>{
        mybutton.current?.click();
    }, [location, mybutton]);

    function copyToClipboard(e) {
        textAreaRef.current.select();
        document.execCommand("copy");
        e.target.focus();
        setCopySuccess(" Copied !");
    }

    useEffect(()=>{
        if(customerStatus === CUSTOMER_STATUS.ACTIVE){
            navigate(RoutesList.ALL);
            window.location.reload()
        }
    }, [customerStatus]);

    // function onSubscribe(){
    //     dispatch({
    //         type: PRICING_MODEL_SHOW,
    //         payload: true
    //     })
    // }
    // if(showPricingModal){
    //     return (
    //             <Pricing />
    //     )
    // }

    if(false) {
        return (
            <Box padding={1} className={'connection_buttons'}>
                <Link to={RoutesList.TRIAL_EXPIRED} ref={mybutton}
                      style={{position: 'absolute', top: -9999, left: -9999}}>
                    <Button>
                        <span>.</span>
                    </Button>
                </Link>
                <Box flex={1} display={'flex'} justifyContent={'center'}>
                    <Box flex={3} padding={4} paddingBottom={0} display={'flex'} flexDirection={'column'} >
                        <Typography variant={"h4"}>
                            Your free trial has ended on {subscriptionEnd}
                        </Typography>

                        <Typography variant={"h5"} style={{marginTop : 10}}>
                            But it doesn’t have to be over.
                        </Typography>
                        <Typography variant={"h6"} style={{marginTop : 15}}>
                            On average, organizations waste 37% of their cloud spending. CloudTrakr helps you to setup connecting your cloud accounts in 30 minutes and we collect billing, usage data and notify you with actionable insights.
                        </Typography>
                        <Typography style={{marginTop : 15, fontSize: 18}}>
                            <span style={{cursor: 'pointer', color: 'blue', textDecoration: 'underline'}}
                                  // onClick={onSubscribe}
                            >Subscribe</span> to one of our subscription plans that meets your requirements to see exclusive benefits.
                        </Typography>
                        <Typography style={{marginTop : 15, marginLeft : 24, fontSize: 18}}>
                            <ul>
                                <li><Typography>Save at least 20% of your cloud spending from our spending visibility and actionable recommendations</Typography></li>
                                <li><Typography>Automated budget alerts, weekly and monthly reports</Typography></li>
                                <li><Typography>Pre-built Multi-cloud, Azure, AWS and GCP dashboards</Typography></li>
                                <li><Typography>Ease of use, easy navigation and easy understanding the complex spending</Typography></li>
                                <li><Typography>Create team to share your dashboards to your team members</Typography></li>
                                <li><Typography>Free On-the-go iOS and Android mobile apps to view your real-time spending</Typography></li>
                            </ul>
                        </Typography>
                    </Box>
                    <Box flex={2}>
                        <div style={{ justifyContent: "center", alignItems:'center', alignSelf: "center", display: "flex", flexDirection: "column" }}>
                        <ReactPlayer
                            width={"80%"}
                            height={"300px"}
                            url={introVideo}
                            controls
                            playing
                            muted
                            config={{
                                file: {
                                    attributes: {
                                        autoPlay: true,
                                        muted: true,
                                    },
                                },
                            }}
                        />
                    </div>
                    </Box>
                </Box>
                <Box  marginLeft={4} >
                    <Typography style={{fontSize: 18}}>
                        Please contact our support and sales team to help you on on-boarding and for any questions to continue the cost savings journey with us.
                    </Typography>
                    <Box display={'flex'} marginTop={5} flex={1} justifyContent={'center'} alignItems={'center'}>
                        <a href={"mailto: sales@cloudtrakr.com?subject=Plan Information"}>
                            <MaterialBlueButton
                                title={"Contact Us"}
                                tooltipRequired={false}
                                // onClick={onConnect}
                            />
                        </a>
                    </Box>
                    <Typography style={{fontSize: 16, marginTop: 10}}>
                        Sincerely
                            <br/>
                        CloudTrakr team
                            <br/>
                        <a href={"mailto: support@cloudtrakr.com"}>support@cloudtrakr.com</a>
                            <br/>
                        <a href={"mailto: sales@cloudtrakr.com"}>sales@cloudtrakr.com</a>
                    </Typography>
                    <TeamMembers userData={userData} custId={customerIdLocal} navigate={navigate} />
                    <br/>
                    <div style={{marginTop: 10}}>
                    <h6>Profile</h6>

                    <Paper elevation={10}>
                        <div style={{padding: 10}}>
                            <div style={{}}>
                                <p>
                                    <b> Name :</b> {name}
                                </p>
                                <p>
                                    {" "}
                                    <b>Email :</b> {email}
                                </p>

                                <div>
                                    <div style={{flexDirection: "row", display: "flex"}}>
                                        <b>Customer Id : </b>
                                        <div style={{marginLeft: 5, marginTop: -3}}>
                                        <textarea
                                            rows="1"
                                            cols="47"
                                            style={{
                                                padding: 5,
                                                border: "none",
                                                overflow: "hidden",
                                                resize: "none",
                                            }}
                                            ref={textAreaRef}
                                            value={customerId}
                                        />
                                        </div>
                                        {document.queryCommandSupported("copy") && (
                                            <div style={{marginLeft: 5}}>
                                                <img
                                                    onClick={copyToClipboard}
                                                    src={copyicons}
                                                    alt="copy"
                                                    style={{
                                                        width: 25,
                                                        height: 25,
                                                    }}
                                                />
                                                {/* </Button> */}
                                                {copySuccess}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <p>
                                    {" "}
                                    <b>Subscription Plan :</b> {subscriptionInfo?.plan_id}
                                </p>

                            </div>
                        </div>
                    </Paper>
                </div>
                </Box>
            </Box>
        );
    }
    if(customerStatus == CUSTOMER_STATUS.INACTIVE) {
        return (
            <Box flex={1} display={'flex'} className={'connection_buttons'}>
                <Link to={RoutesList.TRIAL_EXPIRED} ref={mybutton}
                      style={{position: 'absolute', top: -9999, left: -9999}}>
                    <Button>
                        <span>.</span>
                    </Button>
                </Link>
                <Box padding={3}>
                    <Typography variant={"h3"}>
                        Inactive Account
                    </Typography>
                    {/*<Typography variant={"h6"} style={{marginTop: 10}}>*/}
                    {/*    Thanks for signing up and trying out for CloudTrakr*/}
                    {/*    /!*<i style={{fontSize: 14}}>( by CloudTrakr )</i>*!/*/}
                    {/*    <br/>*/}
                    {/*    <Box marginTop={2}>We are working on your account, please be patient. If you are still seeing*/}
                    {/*        this message after long time feel free to contact us using below button.</Box><br/>*/}
                    {/*</Typography>*/}
                    <a href={"mailto: support@cloudtrakr.com?subject=Inactive Account"}>
                        <MaterialBlueButton
                            title={"Contact Us"}
                            tooltipRequired={false}
                            // onClick={onConnect}
                        />
                    </a>
                    <div style={{marginTop: 20}}>
                        <h6>Profile</h6>

                        <Paper elevation={10}>
                            <div style={{padding: 10}}>
                                <div style={{marginTop: 10}}>
                                    <p>
                                        <b> Name :</b> {name}
                                    </p>
                                    <p>
                                        {" "}
                                        <b>Email :</b> {email}
                                    </p>

                                    <div>
                                        <div style={{flexDirection: "row", display: "flex"}}>
                                            <b>Customer Id : </b>
                                            <div style={{marginLeft: 5, marginTop: -3}}>
                                        <textarea
                                            rows="1"
                                            cols="47"
                                            style={{
                                                padding: 5,
                                                border: "none",
                                                overflow: "hidden",
                                                resize: "none",
                                            }}
                                            ref={textAreaRef}
                                            value={customerId}
                                        />
                                            </div>
                                            {document.queryCommandSupported("copy") && (
                                                <div style={{marginLeft: 5}}>
                                                    <img
                                                        onClick={copyToClipboard}
                                                        src={copyicons}
                                                        alt="copy"
                                                        style={{
                                                            width: 25,
                                                            height: 25,
                                                        }}
                                                    />
                                                    {/* </Button> */}
                                                    {copySuccess}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/*<p>*/}
                                    {/*    {" "}*/}
                                    {/*    <b>Subscription Plan :</b> {subscriptionInfo?.plan_id}*/}
                                    {/*</p>*/}

                                </div>
                            </div>
                        </Paper>
                    </div>
                </Box>
            </Box>
        )
    }

    return null;
}

export default TrialExpired;

function TeamMembers({navigate, custId}){
    const team = useSelector((state) => state.userDetailsReducer.customerId);
    const [teamList, setTeamList] = useState([]);
    const [ownerTeam, setOwnerTeam] = useState(null);
    const dispatch = useDispatch();

    const customerId = useSelector((state) => state.userDetailsReducer.id);


    const handleChangeTeam = (t) => {
        if(t.value == custId) return;
        dispatch({ type: "SWITCH_TEAM_CLEAR" });
        // setDirectory(value);
        dispatch({ type: 'SWITCH_TEAM', payload: { id: t.value, role: t.role } });
        navigate(RoutesList.ALL);
    };

    useEffect(() => {
        // var acceptedTeams = [];
        // acceptedTeams.push({ label: "Default account", value: team, role: "Owner" });
        // if (team) {
        //     setDirectory(team);
        // }
        // setDirectoryError(null);
        // setAddUserFuncError(null);
        // setTeamNameError("");
        if(customerId) {
            const acceptedTeams = [];
            const asyncFunc = async () => {
                // setLoading1(true);
                const data = await axios.get(REACT_APP_GET_USER_MANAGEMENT, {params: {customerId}}).then(d=>d.data);
                const addedTeams = await axios.get(REACT_APP_GET_INVITED_TEAMS, {params: {customerId}});
                // setuserData(data.data);
                if(data){
                    setOwnerTeam(data?.data);
                }
                if (addedTeams && addedTeams.data.statusCode == 200) {
                    let val = addedTeams.data.data;
                    val.invitedTeam.map((i) => acceptedTeams.push({
                        label: i.team_name,
                        value: i.customerId,
                        role: i.role
                    }));
                }
                setTeamList(acceptedTeams);
                // setLoading1(false);
            };
            asyncFunc();
        }
    }, [customerId]);

    if(!(teamList.length > 0)) return null
    return (
        <Alert severity="info" style={{marginTop: 10}}>
            Still you can access to your connected teams.
            <br/>
            Switch to one of these teams:
            <div>
            {teamList.map((t)=>(
                <>
                <><b><span style={{color: t.value==custId?'grey': '#0645ad', cursor: 'pointer'}} onClick={()=>handleChangeTeam(t)}>
                    {t.label}
                </span></b></>
                    <br />
                </>
            ))}
            </div>
        </Alert>
    )
}
