import React, { useEffect, useRef, useState } from "react";
import { Card, Button } from "react-bootstrap/";
import { useSelector } from "react-redux";
import copyicons from "../../images/copy.svg";
import {
    Paper,
    TableContainer,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Table,
    InputLabel,
    Input,
    FormControl,
    FormHelperText,
    Box,
    Typography,
    IconButton,
    CircularProgress,
    Link
} from "@mui/material";
import SettingsIcon from "../../images/settings_icon.svg";
import axios from "../../connection/axios";
import Colors from "../../styles/color";
import moment from "moment";
import {ArrowDropDown, Image, Settings as SettingsIcon2, Edit, Person} from "@mui/icons-material";
import default_logo from "../../images/app_logo.png";
import ms_teams from "../../images/ms_teams.svg";
import slack from "../../images/slack.svg";

import Switch from "react-switch";
import { nullLiteral } from "@babel/types";
import { Form } from "react-bootstrap";
import UserManagement from "./UserManagement";
import MaterialBlueButton from "../../components/MaterialBlueButton";
import RoutesList from "../../routes/RoutesList";
import PricingIcon from "../../images/pricing.png";
import {makeStyles} from "MakeWithStyles";
const {
    REACT_APP_PUT_WHITE_LABELLING,
    REACT_APP_GET_TEAMS_WEBHOOK,
    REACT_APP_ADD_UPDATE_TEAMS_WEBHOOK,
    REACT_APP_TEAMS_WEBHOOK_STATUS,
    REACT_APP_GET_SLACK_WEBHOOK,
    REACT_APP_SLACK_WEBHOOK_STATUS,
    REACT_APP_ADD_UPDATE_SLACK_WEBHOOK,
} = process.env;

export default function Settings() {
    const {classes: classes1} = useStyles1();
    const [subscriptionStart, setSubscriptionStart] = useState("");
    const [subscriptionEnd, setSubscriptionEnd] = useState("");
    const [copySuccess, setCopySuccess] = useState("");
    const [newOrganisationName, setNewOrganisationName] = useState(null);
    const [teamsWebHookData, setTeamsWebHookData] = useState(null);
    const [teamsChannalName, setteamsChannalName] = useState(null);
    const [teamsWebHookURL, setTeamsWebhookURL] = useState(null);

    const [slackWebHookData, setSlackWebHookData] = useState(null);
    const [slackChannalName, setSlackChannalName] = useState(null);
    const [slackWebHookURL, setSlackWebhookURL] = useState(null);

    const [isEditing, setIsEditing] = useState(null);
    const [isSlackEditing, setIsSlackEditing] = useState(null);

    const [newLogoData, setNewLogoData] = useState(null);
    const textAreaRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [slackLoading, setSlackLoading] = useState(false);

    const [channelNameError, setChannelNameError] = useState({ status: false, msg: "" });
    const [webHookError, setWebHookError] = useState({ status: false, msg: "" });
    const { name, email, customerId, subscriptionInfo, companyName, companyLogo } = useSelector((state) => ({
        name: state.userDetailsReducer.name,
        email: state.userDetailsReducer.email,
        customerId: state.userDetailsReducer.id,
        companyLogo: state.userDetailsReducer.companyLogo,
        companyName: state.userDetailsReducer.companyName,
    }));
    const userData = useSelector((state) => ({
        id: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
        role: state.userDetailsReducer.role,
    }));
    useEffect(() => {
        setNewOrganisationName(companyName);
        setNewLogoData(companyLogo);
    }, [companyName, companyLogo]);

    useEffect(() => {
        if (userData.id) {
            getTeamsHook(userData.id);
            getSlackHook(userData.id);
        }
    }, [userData.id]);
    function copyToClipboard(e) {
        textAreaRef.current.select();
        document.execCommand("copy");
        e.target.focus();
        setCopySuccess(" Copied!");
    }
    // async function getSubscriptionURL() {
    //     return await axios
    //         .get(REACT_APP_COSTUNIFY_CUSTOMER_SUBSCRIPTION, {
    //             params: {
    //                 customerId: userData.id,
    //             },
    //         })
    //         .then((response) => response.data)
    //         .then((response) => response.successMessage)
    //         .then((response) => response.portal_session)
    //         .catch((e) => {
    //             // console.log(e)
    //         });
    // }
    // function manageSubscriptions(e) {
    //     e.preventDefault();
    //     const Chargebee = window.Chargebee;
    //     const cbInstance = window.Chargebee.getInstance();
    //     if (Chargebee && cbInstance && customerId) {
    //         cbInstance.setPortalSession(getSubscriptionURL);
    //         var cbPortal = cbInstance.createChargebeePortal();
    //         cbPortal.open();
    //         // cbPortal.openSection({
    //         //   sectionType: Chargebee.getPortalSections().MANAGE_SUBSCRIPTIONS
    //         // });
    //     }
    // }
    useEffect(() => {
        if (subscriptionInfo) {
            setSubscriptionStart(moment.unix(subscriptionInfo?.current_term_start || 0).format("MMMM DD, YYYY"));
            setSubscriptionEnd(
                moment
                    .unix(subscriptionInfo?.next_billing_at || 0)
                    .format("MMMM DD, YYYY")
                    .toString()
            );
        }
    }, [subscriptionInfo]);
    function uploadOrganisationData() {
        if (newOrganisationName && newOrganisationName?.trim() && newLogoData) {
            axios
                .post(REACT_APP_PUT_WHITE_LABELLING, {
                    customerId: userData.id,
                    companyName: newOrganisationName,
                    companyLogo: newLogoData?.toString(),
                })
                .then((response) => response.data)
                .then((response) => {
                    console.log(response);
                })
                .catch((e) => console.log(e))
                .finally(() => window.location.reload());
        }
    }
    function teamsHookStatusUpdate(status) {
        setLoading(true);

        axios
            .post(REACT_APP_TEAMS_WEBHOOK_STATUS, {
                customerId: userData.id,
                status: status,
            })
            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200) {
                        getTeamsHook(userData.id);
                    }
                }
            })
            .catch((e) => console.log(e))
            .finally(() => setLoading(false));
    }

    async function getTeamsHook(customerId) {
        // debugger;
        setLoading(true);
        return await axios
            .get(REACT_APP_GET_TEAMS_WEBHOOK, {
                params: {
                    customerId,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200) {
                        setTeamsWebHookData(response?.data);
                    } else {
                        setTeamsWebHookData(response.statusCode);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoading(false));
    }

    function slackHookStatusUpdate(status) {
        setSlackLoading(true);

        axios
            .post(REACT_APP_SLACK_WEBHOOK_STATUS, {
                customerId: userData.id,
                status: status,
            })
            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200) {
                        getSlackHook(userData.id);
                    }
                }
            })
            .catch((e) => console.log(e))
            .finally(() => setSlackLoading(false));
    }

    async function getSlackHook(customerId) {
        // debugger;
        setSlackLoading(true);
        return await axios
            .get(REACT_APP_GET_SLACK_WEBHOOK, {
                params: {
                    customerId,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200) {
                        setSlackWebHookData(response?.data);
                    } else {
                        setSlackWebHookData(response.statusCode);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setSlackLoading(false));
    }

    function updateLogo(e) {
        const file = e.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (f) {
            if (f.total > 204800) {
                alert("Image size must be less than 200 KB");
            } else {
                setNewLogoData(reader.result);
            }
        };
        reader.readAsDataURL(file);
    }
    return (
        <div
            style={{
                padding: 10,
                backgroundColor: Colors.background,
                minHeight: "95vh",
            }}
            className={'connection_buttons'}
        >
            <div style={{ display: "flex" }}>
                <img
                    src={SettingsIcon}
                    alt="Settings"
                    style={{
                        width: 26,
                        height: 26,
                        marginRight: 8,
                    }}
                />
                <h5>
                    <b>Settings</b>
                </h5>
            </div>
            <div style={{ marginTop: 20 }}>
                <div style={{display: "flex", alignItems:'center', marginBottom: 5}}>
                    <Person style={{ outline: "none", color: "#404040", fontSize: 24, marginRight: 3 }} color={"#404040"} />
                    <span>Profile</span>
                </div>

                <Paper elevation={10}>
                    <div style={{ padding: 10 }}>
                        <div style={{ marginTop: 10 }}>
                            <p>
                                <b> Name :</b> {name}
                            </p>
                            <p>
                                {" "}
                                <b>Email :</b> {email}
                            </p>

                            <div>
                                <div style={{ flexDirection: "row", display: "flex" }}>
                                    <b>Customer Id : </b>
                                    <div style={{ marginLeft: 5, marginTop: -3 }}>
                                        <textarea
                                            rows="1"
                                            cols="47"
                                            style={{
                                                padding: 5,
                                                border: "none",
                                                overflow: "hidden",
                                                resize: "none",
                                            }}
                                            ref={textAreaRef}
                                            value={customerId}
                                        />
                                    </div>
                                    {document.queryCommandSupported("copy") && (
                                        <div style={{ marginLeft: 5 }}>
                                            <img
                                                onClick={copyToClipboard}
                                                src={copyicons}
                                                alt="copy"
                                                style={{
                                                    width: 25,
                                                    height: 25,
                                                }}
                                            />
                                            {/* </Button> */}
                                            {copySuccess}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Paper>
            </div>
            <div style={{ marginTop: 20 }}>
                {/*<div style={{display: "flex", alignItems:'center', marginBottom: 5}}>*/}
                {/*    <img src={PricingIcon} height={25} style={{ marginRight: 4 }} />*/}
                {/*    <span>Subscription plan</span>*/}
                {/*</div>*/}
                {/*<Paper style={{padding: 10, display: "flex", alignItems: "center", justifyContent: "space-between"}}  elevation={10}>*/}
                {/*    <Box>*/}
                {/*    {(subscriptionInfo?.payment_status)?*/}
                {/*        subscriptionInfo?.payment_status == PAYMENT_STATUS.TRIAL_EXPIRED?*/}
                {/*        <>*/}
                {/*            <span >Your {PLANS[subscriptionInfo?.plan_id]} plan ended on {subscriptionEnd} {"   "}</span>*/}
                {/*            <Link color="inherit" href={RoutesList.TRIAL_EXPIRED} style={{ marginLeft: 10}}>*/}
                {/*                <MaterialBlueButton*/}
                {/*                    title={"Contact Us"}*/}
                {/*                    tooltipRequired={false}*/}
                {/*                    buttonStyle={{cursor: 'pointer'}}*/}
                {/*                    // onClick={onConnect}*/}
                {/*                />*/}
                {/*            </Link>*/}
                {/*        </>:*/}
                {/*            // <span>Your {PLANS[subscriptionInfo?.plan_id]} plan will be ending on {moment(subscriptionInfo?.trial_end_date).format("MMM, DD YYYY")}</span>*/}
                {/*            // <span>You are on {paymentModel == PAYMENT_MODEL.INVOICE?"invoice":PLANS[subscriptionInfo?.plan_id]} plan </span>*/}
                {/*            subscriptionInfo?.plan_id == 'free-trial'?*/}
                {/*                <span>Your {PLANS[subscriptionInfo?.plan_id]} plan ends in {moment(subscriptionInfo?.trial_end_date || 0).fromNow(true)} on {moment(subscriptionInfo?.trial_end_date || 0).format("MMMM D, YYYY")}</span>*/}
                {/*                :<span>You are on {*/}
                {/*                    subscriptionInfo?.plan_id.toString().startsWith('enterprise')?'enterprise':*/}
                {/*                    paymentModel == PAYMENT_MODEL.INVOICE?"invoice":PLANS[subscriptionInfo?.plan_id]} plan </span>*/}
                {/*        :null*/}
                {/*    }*/}
                {/*    </Box>*/}
                {/*    {userData.id == customerId && <Button onClick={manageSubscriptions}*/}
                {/*        // variant="none"*/}
                {/*        // color={"none"}*/}
                {/*             style={{padding: 0, margin: 0, outline: 'none'}}>*/}
                {/*        <Box display={'flex'} padding={0.7}>*/}
                {/*            <SettingsIcon2/>*/}
                {/*            <Typography style={{paddingLeft: 5}}>*/}
                {/*                Manage Subscription*/}
                {/*            </Typography>*/}
                {/*            /!*<ArrowDropDown />*!/*/}
                {/*        </Box>*/}
                {/*    </Button>}*/}
                {/*</Paper>*/}
                {/*<Paper elevation={3}>*/}
                {/** Hiding the subscription info table for future use in Payment **/}
                {/*<TableContainer style={{ marginTop: 10 }} component={Paper} elevation={10}>*/}
                {/*    <Table style={{ minWidth: 650 }} aria-label="simple table">*/}
                {/*        <TableHead style={{ backgroundColor: Colors.tableHeader }}>*/}
                {/*            <TableRow>*/}
                {/*                <TableCell style={{ fontWeight: "bold" }}>Plan Name</TableCell>*/}
                {/*                <TableCell style={{ fontWeight: "bold" }} align={"center"}>*/}
                {/*                    Plan Status*/}
                {/*                </TableCell>*/}
                {/*                <TableCell style={{ fontWeight: "bold" }} align={"center"}>*/}
                {/*                    Active Since*/}
                {/*                </TableCell>*/}
                {/*                <TableCell style={{ fontWeight: "bold" }} align={"center"}>*/}
                {/*                    Next Bill*/}
                {/*                </TableCell>*/}
                {/*                <TableCell style={{ fontWeight: "bold" }} align={"right"}>*/}
                {/*                    Actions*/}
                {/*                </TableCell>*/}
                {/*            </TableRow>*/}
                {/*        </TableHead>*/}
                {/*        <TableBody>*/}
                {/*            {subscriptionInfo ? (*/}
                {/*                <TableRow>*/}
                {/*                    <TableCell component="th" scope="row">*/}
                {/*                        {PLANS[subscriptionInfo?.plan_id]}*/}
                {/*                    </TableCell>*/}
                {/*                    <TableCell align={"center"} component="th" scope="row">*/}
                {/*                        {subscriptionInfo?.status || "unknown"}*/}
                {/*                    </TableCell>*/}
                {/*                    <TableCell align={"center"} component="th" scope="row">*/}
                {/*                        {subscriptionStart}*/}
                {/*                    </TableCell>*/}
                {/*                    <TableCell align={"center"} component="th" scope="row">*/}
                {/*                        {subscriptionEnd}*/}
                {/*                    </TableCell>*/}
                {/*                    <TableCell align={"right"}>*/}
                {/*                        <Button onClick={manageSubscriptions} variant="none" color={"none"} style={{ padding: 0, margin: 0 }}>*/}
                {/*                            <SettingsIcon2 />*/}
                {/*                            <ArrowDropDown />*/}
                {/*                        </Button>*/}
                {/*                    </TableCell>*/}
                {/*                </TableRow>*/}
                {/*            ) : (*/}
                {/*                <TableRow>*/}
                {/*                    <TableCell>No Plan Info Available</TableCell>*/}
                {/*                </TableRow>*/}
                {/*            )}*/}
                {/*        </TableBody>*/}
                {/*    </Table>*/}
                {/*</TableContainer>*/}
                {/*{customerId && subscriptionInfo && ((subscriptionInfo.plan_id === "savings-plus" || subscriptionInfo.plan_id === "annual-savings-plus") || (customerId == process.env.REACT_APP_EXCEPTION_CUSTOMER_SPECIAL_C1)) && <UserManagement customerId={customerId} logInEmail={email} />}*/}
                {customerId && <UserManagement customerId={customerId} logInEmail={email} userName={name} />}
                <div style={{ marginTop: 20 }}>
                    <div style={{display: "flex", alignItems:'center',}}>
                        <img src={ms_teams} height={26} style={{ marginRight: 4 }} />
                        <span>Microsoft Teams notifications</span>
                    </div>
                    {!loading ? (
                        teamsWebHookData != 1003 ? (
                            !isEditing ? (
                                <div>
                                    <TableContainer style={{ marginTop: 10 }} component={Paper} elevation={10}>
                                        <Table style={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead style={{ backgroundColor: Colors.tableHeader }}>
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: "bold" }}> Channel name</TableCell>
                                                    <TableCell style={{ fontWeight: "bold" }} align={"center"}>
                                                        Date created
                                                    </TableCell>
                                                    <TableCell style={{ fontWeight: "bold" }} align={"center"}>
                                                        Last modified
                                                    </TableCell>
                                                    <TableCell style={{ fontWeight: "bold" }} align={"center"}>
                                                        Status
                                                    </TableCell>{" "}
                                                    <TableCell style={{ fontWeight: "bold" }} align={"center"}>
                                                        Enable/Disable
                                                    </TableCell>
                                                    <TableCell style={{ fontWeight: "bold" }} align={"right"}>
                                                        Edit
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <img src={ms_teams} height={26} style={{ marginRight: 4 }} />
                                                        {teamsWebHookData?.teams_channel_name}
                                                    </TableCell>

                                                    <TableCell align={"center"} component="th" scope="row">
                                                        {moment(teamsWebHookData?.date_created).format("MMM DD, YYYY")}
                                                    </TableCell>
                                                    <TableCell align={"center"} component="th" scope="row">
                                                        {moment(teamsWebHookData?.last_modified).format("MMM DD, YYYY")}
                                                    </TableCell>

                                                    <TableCell align={"center"} component="th" scope="row">
                                                        {teamsWebHookData?.status}
                                                    </TableCell>

                                                    <TableCell align={"center"} component="th" scope="row">
                                                        <Switch
                                                            height={26}
                                                            checked={teamsWebHookData?.status === "active"}
                                                            onChange={() => {
                                                                {
                                                                    teamsWebHookData?.status === "active" ? teamsHookStatusUpdate("inactive") : teamsHookStatusUpdate("active");
                                                                }
                                                            }}
                                                            disabled={userData.role == "Owner" || userData.role == null ? false : true}
                                                        />
                                                    </TableCell>

                                                    <TableCell align={"right"}>
                                                        <Button variant="none" color={"none"} style={{ padding: 0, marginLeft: 10 }} disabled={userData.role == "Owner" || userData.role == null ? false : true}>
                                                            <Edit
                                                                onClick={() => {
                                                                    setteamsChannalName(teamsWebHookData?.teams_channel_name);
                                                                    setTeamsWebhookURL(teamsWebHookData?.teams_webhook_URL);

                                                                    setIsEditing(true);
                                                                }}
                                                            />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            ) : (
                                <ConnectForm
                                    teamsChannalName={teamsChannalName}
                                    teamsWebHookURL={teamsWebHookURL}
                                    channelNameError={channelNameError}
                                    setteamsChannalName={setteamsChannalName}
                                    setChannelNameError={setChannelNameError}
                                    webHookError={webHookError}
                                    setTeamsWebhookURL={setTeamsWebhookURL}
                                    setWebHookError={setWebHookError}
                                    onConnect={onConnect}
                                    mode={"update"}
                                    setIsEditing={setIsEditing}
                                />
                            )
                        ) : (
                            <ConnectForm
                                teamsChannalName={teamsChannalName}
                                teamsWebHookURL={teamsWebHookURL}
                                channelNameError={channelNameError}
                                setteamsChannalName={setteamsChannalName}
                                setChannelNameError={setChannelNameError}
                                webHookError={webHookError}
                                setTeamsWebhookURL={setTeamsWebhookURL}
                                setWebHookError={setWebHookError}
                                onConnect={onConnect}
                                setIsEditing={setIsEditing}
                                teamsWebHookData={teamsWebHookData}
                            />
                        )
                    ) : (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress />
                        </div>
                    )}
                </div>

                <div style={{ marginTop: 20 }}>
                    <div style={{display: "flex", alignItems:'center',}}>
                        <img src={slack} height={26} style={{ marginRight: 4 }} />
                        <span>Slack notifications</span>
                    </div>
                    {!slackLoading ? (
                        slackWebHookData != 1003 ? (
                            !isSlackEditing ? (
                                <div>
                                    <TableContainer style={{ marginTop: 10 }} component={Paper} elevation={10}>
                                        <Table style={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead style={{ backgroundColor: Colors.tableHeader }}>
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: "bold" }}> Channel name</TableCell>
                                                    <TableCell style={{ fontWeight: "bold" }} align={"center"}>
                                                        Date created
                                                    </TableCell>
                                                    <TableCell style={{ fontWeight: "bold" }} align={"center"}>
                                                        Last modified
                                                    </TableCell>
                                                    <TableCell style={{ fontWeight: "bold" }} align={"center"}>
                                                        Status
                                                    </TableCell>{" "}
                                                    <TableCell style={{ fontWeight: "bold" }} align={"center"}>
                                                        Enable/Disable
                                                    </TableCell>
                                                    <TableCell style={{ fontWeight: "bold" }} align={"right"}>
                                                        Edit
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <img src={slack} height={26} style={{ marginRight: 4 }} />
                                                        {slackWebHookData?.slack_channel_name}
                                                    </TableCell>

                                                    <TableCell align={"center"} component="th" scope="row">
                                                        {moment(slackWebHookData?.date_created).format("MMM DD, YYYY")}
                                                    </TableCell>
                                                    <TableCell align={"center"} component="th" scope="row">
                                                        {moment(slackWebHookData?.last_modified).format("MMM DD, YYYY")}
                                                    </TableCell>

                                                    <TableCell align={"center"} component="th" scope="row">
                                                        {slackWebHookData?.status}
                                                    </TableCell>

                                                    <TableCell align={"center"} component="th" scope="row">
                                                        <Switch
                                                            height={26}
                                                            checked={slackWebHookData?.status === "active"}
                                                            onChange={() => {
                                                                {
                                                                    slackWebHookData?.status === "active" ? slackHookStatusUpdate("inactive") : slackHookStatusUpdate("active");
                                                                }
                                                            }}
                                                            disabled={userData.role == "Owner" || userData.role == null ? false : true}
                                                        />
                                                    </TableCell>

                                                    <TableCell align={"right"}>
                                                        <Button variant="none" color={"none"} style={{ padding: 0, marginLeft: 10 }} disabled={userData.role == "Owner" || userData.role == null ? false : true}>
                                                            <Edit
                                                                onClick={() => {
                                                                    setSlackChannalName(slackWebHookData?.slack_channel_name);
                                                                    setSlackWebhookURL(slackWebHookData?.slack_webhook_URL);

                                                                    setIsSlackEditing(true);
                                                                }}
                                                            />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            ) : (
                                <SlackConnectForm
                                    slackChannalName={slackChannalName}
                                    slackWebHookURL={slackWebHookURL}
                                    channelNameError={channelNameError}
                                    setSlackChannalName={setSlackChannalName}
                                    setChannelNameError={setChannelNameError}
                                    webHookError={webHookError}
                                    setSlackWebhookURL={setSlackWebhookURL}
                                    setWebHookError={setWebHookError}
                                    onSlackConnect={onSlackConnect}
                                    mode={"update"}
                                    setIsSlackEditing={setIsSlackEditing}
                                />
                            )
                        ) : (
                            <SlackConnectForm
                                slackChannalName={slackChannalName}
                                slackWebHookURL={slackWebHookURL}
                                channelNameError={channelNameError}
                                setSlackChannalName={setSlackChannalName}
                                setChannelNameError={setChannelNameError}
                                webHookError={webHookError}
                                setSlackWebhookURL={setSlackWebhookURL}
                                setWebHookError={setWebHookError}
                                onSlackConnect={onSlackConnect}
                                setIsSlackEditing={setIsSlackEditing}
                                slackWebHookData={slackWebHookData}
                            />
                        )
                    ) : (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress />
                        </div>
                    )}
                </div>
            </div>
            {/*{subscriptionInfo && (subscriptionInfo.plan_id === "savings-plus" || subscriptionInfo.plan_id === "annual-savings-plus") ? (*/}
                <div style={{ marginTop: 20}} className={'connection_buttons'}>
                    <span>Update name and logo</span>
                    <Paper style={{ padding: 20, marginTop: 10 }}>
                        <FormControl>
                            <Box flexDirection={"row"} display={"flex"} alignItems={"center"}>
                                <Typography>
                                    <b>Organization Name: </b>
                                </Typography>
                                <Input
                                    id="my-input"
                                    aria-describedby="my-helper-text"
                                    style={{ marginLeft: 10 }}
                                    value={newOrganisationName != null ? newOrganisationName : "CloudTrakr"}
                                    onChange={(e) => setNewOrganisationName(e.currentTarget.value)}
                                    disabled={userData.role == "Owner" || userData.role == null ? false : true}
                                />
                            </Box>
                            <Box flexDirection={"row"} display={"flex"} style={{ marginTop: 20 }}>
                                <Typography style={{ marginRight: 10 }}>
                                    <b>Organization Logo: </b>
                                </Typography>
                                <Box display={"flex"} flexDirection={"column"}>
                                    <Box style={{ padding: 0, margin: 0 }}>
                                        <input accept="image/*" multiple type="file" style={{ display: "none" }} id={"logo_file_change"} onChange={updateLogo} disabled={userData.role == "Owner" || userData.role == null ? false : true} />
                                        <label htmlFor={"logo_file_change"} className={"costunify_overlay"}>
                                            <Box id={"costunify_app_logo_hover"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                                <Image height={20} style={{ color: "#FFF" }} color={"#FFF"} fontSize={"large"} />
                                            </Box>
                                            <img src={newLogoData || default_logo} id={"costunify_app_logo"} />
                                        </label>
                                    </Box>

                                    <Typography style={{ color: "#888888" }}>
                                        Supported formats (.jpeg | .gif | .png)
                                        <br />
                                        Max file size 200 KB
                                    </Typography>
                                </Box>
                            </Box>
                            <Button onClick={uploadOrganisationData} style={{ marginTop: 5 }} disabled={userData.role == "Owner" || userData.role == null ? false : true}>
                                Update
                            </Button>
                        </FormControl>
                    </Paper>
                </div>
            {/*) : null}*/}
        </div>
    );

    function validateFields() {
        var noErrors = true;
        if (!teamsChannalName?.trim()) {
            setChannelNameError({
                status: true,
                msg: "Please enter Teams channel name",
            });
            noErrors = false;
        }

        if (!teamsWebHookURL?.trim()) {
            setWebHookError({ status: true, msg: "Please enter the WebHook URL" });
            noErrors = false;
        }
        return noErrors;
    }
    function validateSlackFields() {
        var noErrors = true;
        if (!slackChannalName?.trim()) {
            setChannelNameError({
                status: true,
                msg: "Please enter Slack channel name",
            });
            noErrors = false;
        }

        if (!slackWebHookURL?.trim()) {
            setWebHookError({ status: true, msg: "Please enter the WebHook URL" });
            noErrors = false;
        }
        return noErrors;
    }
    async function onConnect(e) {
        e.preventDefault();
        if (validateFields()) {
            setLoading(true);
            await axios
                .post(REACT_APP_ADD_UPDATE_TEAMS_WEBHOOK, {
                    customerId: userData.id,
                    teamsChannelName: teamsChannalName.trim(),
                    teamsWebhookURL: teamsWebHookURL.trim(),
                })
                .then((response) => response.data)
                .then((response) => {
                    if ("statusCode" in response) {
                        if (response.statusCode == 200) {
                            getTeamsHook(userData.id);
                        } else {
                        }
                    }
                })
                .catch((e) => {
                    // console.log(e)
                })
                .finally(() => {
                    setLoading(false);
                    setIsEditing(false);
                    setTeamsWebhookURL(null);
                    setteamsChannalName(null);
                });
        }
        e.preventDefault();
    }

    async function onSlackConnect(e) {
        e.preventDefault();
        if (validateSlackFields()) {
            setSlackLoading(true);
            await axios
                .post(REACT_APP_ADD_UPDATE_SLACK_WEBHOOK, {
                    customerId: userData.id,
                    slackChannelName: slackChannalName.trim(),
                    slackWebhookURL: slackWebHookURL.trim(),
                })
                .then((response) => response.data)
                .then((response) => {
                    if ("statusCode" in response) {
                        if (response.statusCode == 200) {
                            getSlackHook(userData.id);
                        } else {
                        }
                    }
                })
                .catch((e) => {
                    // console.log(e)
                })
                .finally(() => {
                    setSlackLoading(false);
                    setIsSlackEditing(false);
                    setSlackWebhookURL(null);
                    setSlackChannalName(null);
                });
        }
        e.preventDefault();
    }
}
const useStyles1 = makeStyles()({
    btnStyle: {
        background: "linear-gradient(45deg, #3f51b5 50%, #3f51b5 100%)",
        border: 0,
        borderRadius: 2,
        boxShadow: "0 2px 4px 2px rgba(63, 81, 181, .3)",
        color: "white",
        height: 30,
        padding: "0 10px",
        textTransform: "none",
    },
});

function TextField({ value, hasError, label, placeholder, onInput, errorMessage, disabled = false }) {
    return (
        <Form.Group controlId="formBasicEmail">
            <Form.Label className={disabled ? "disabled-field" : ""}>{label}</Form.Label>
            <Form.Control value={value} disabled={disabled} placeholder={placeholder} onChange={(event) => onInput(event.target.value)} />
            {hasError ? <Form.Text style={{ color: "red" }}>{errorMessage}</Form.Text> : null}
        </Form.Group>
    );
}

function ConnectForm({ teamsChannalName, teamsWebHookURL, channelNameError, setteamsChannalName, setChannelNameError, webHookError, setTeamsWebhookURL, setWebHookError, onConnect, mode = "connect", setIsEditing, teamsWebHookData }) {
    return (
        <Paper style={{ padding: 20, marginTop: 10 }} elevation={10}>
            <div>
                <Form style={{ flex: 1, marginBottom: 10 }} onSubmit={(e) => e.preventDefault()}>
                    {teamsWebHookData != 1003 && (
                        <span onClick={() => setIsEditing(false)} style={{ justifyContent: "flex-end", display: "flex" }}>
                            Cancel
                        </span>
                    )}
                    <TextField
                        value={teamsChannalName}
                        label={"Channel Name *"}
                        hasError={channelNameError.status}
                        errorMessage={channelNameError.msg}
                        onInput={(v) => {
                            setteamsChannalName(v);
                            setChannelNameError({ status: false, msg: "" });
                        }}
                        placeholder={"Enter Channel name"}
                    />
                    <TextField
                        value={teamsWebHookURL}
                        label={"Teams WebHook URL *"}
                        hasError={webHookError.status}
                        errorMessage={webHookError.msg}
                        onInput={(v) => {
                            setTeamsWebhookURL(v);
                            setWebHookError({ status: false, msg: "" });
                        }}
                        placeholder={"Enter Teams WebHook URL"}
                    />
                    <Button onClick={onConnect} variant={"primary"} type="submit">
                        {mode == "connect" ? "Connect" : "Update"}
                    </Button>
                </Form>
            </div>
        </Paper>
    );
}

function SlackConnectForm({ slackChannalName, slackWebHookURL, channelNameError, setSlackChannalName, setChannelNameError, webHookError, setSlackWebhookURL, setWebHookError, onSlackConnect, mode = "connect", setIsSlackEditing, slackWebHookData }) {
    return (
        <Paper style={{ padding: 20, marginTop: 10 }} elevation={10}>
            <div>
                <Form style={{ flex: 1, marginBottom: 10 }} onSubmit={(e) => e.preventDefault()}>
                    {slackWebHookData != 1003 && (
                        <span onClick={() => setIsSlackEditing(false)} style={{ justifyContent: "flex-end", display: "flex" }}>
                            Cancel
                        </span>
                    )}
                    <TextField
                        value={slackChannalName}
                        label={"Channel Name *"}
                        hasError={channelNameError.status}
                        errorMessage={channelNameError.msg}
                        onInput={(v) => {
                            setSlackChannalName(v);
                            setChannelNameError({ status: false, msg: "" });
                        }}
                        placeholder={"Enter Channel name"}
                    />
                    <TextField
                        value={slackWebHookURL}
                        label={"Slack WebHook URL *"}
                        hasError={webHookError.status}
                        errorMessage={webHookError.msg}
                        onInput={(v) => {
                            setSlackWebhookURL(v);
                            setWebHookError({ status: false, msg: "" });
                        }}
                        placeholder={"Enter Slack WebHook URL"}
                    />
                    <Button onClick={onSlackConnect} variant={"primary"} type="submit">
                        {mode == "connect" ? "Connect" : "Update"}
                    </Button>
                </Form>
            </div>
        </Paper>
    );
}
