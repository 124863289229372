import React, {useEffect, useRef, useState} from "react";
import {
  select, selectAll,
  scaleBand,
  axisBottom,
  stack,
  max,
  scaleLinear,
  axisLeft,
  stackOrderAscending, sum
} from "d3";
import GetWindowDimension from "../../components/HOC/GetWindowDimension";
import {tooltipVB} from "../../components/D3Charts/ReactDrillDownd3BarChart";
import Format from "../../components/NumberFormat";
import ColorStyles from "../../styles/color";
import {convertToInternationalCurrencySystem} from "../../util/Miscellaneous";

function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

const colors = [
    "#31BEF3",
    "#19A979",
    "#FBD63D",
    "#08619D",
    "#E8743B",
    "#194350",
    "#7b113a",
    "#301b3f",

    "#ffd700",
"#ffb14e",
"#fa8775",
"#ea5f94",
"#cd34b5",
"#9d02d7",
"#0000ff",
"#0095eb",
"#a57900",
"#00a28e",
"#86c100",
"#bc2762",
"#be0000"

    // "#153e25",
    // "#ffb500",
    // "#941421",
    // "#ca0088",
    // "#00a65e",
    // "#c29496",
    // "#00a999",
    // "#3d130d",
    // "#7d482a",
    // "#005228",
    // "#00c585",
    // "#e36822",
    // "#ed1941",
    // "#2400a2",
    // "#87d1d1",
    // "#9a1006",
    // "#e73060",
    // "#0066af",
    // "#e41518",
    // "#04aa9e"
];

function StackedBarChart2({ data : data2, keyStack, keyField, valueKey, height, width, currency }) {
  var keys = [];
  const svgRef = useRef();
  const legendRef = useRef();
  const [exceptionKeys, setExceptionKeys] = useState({});
  const [exceptionKeysLength, setExceptionKeysLength] = useState(0);

  const margin = {
      top: 40,
      right:  190,
      bottom: 10,
      left: 80,
  }

  function addMissingKeys(data, keys){
      const final = {};
      keys.map(k=>{
          final[k] = 0
      });
      return data.map(d=>({...final, ...d, ...exceptionKeys}));
  }

  function drawSVG(){
          select(svgRef.current).select("g").remove();
          select(legendRef.current).select("svg").remove();
          if(!(data2 && data2.length)) return null;
          const layerData = data2.map(d=>{
              const result = {};
              d.values[0].value[keyStack]?.forEach(r=>result[r[keyField]] = parseFloat(r[valueKey] || 0));
              return {
                  dataLabel: d.dataLabel,
                  ...result
              }
          });

          function drawLegend(){

              legendElement = legendElement
                  .append('g')
                  .attr("class", "legend__container")
                  .attr("transform", `translate(10,10)`)
                  .selectAll("g.legend__element")
                  .data(colorPairsByKey)
                  .enter()
                  .append("g")
                  // .attr("transform", function (d, i) {
                  //     return `translate(${(i % colorPairsByKey.length/2) * 100},${Math.floor(i / colorPairsByKey.length/2) * 25})`;
                  // })
                  .style("cursor", "pointer")
                  .on("click", (_,{key})=>{
                      var exceptionKeys2 = exceptionKeys;
                      if(key in exceptionKeys){
                          delete exceptionKeys2[key];
                      } else {
                          exceptionKeys2[key] = 0;
                      }
                      setExceptionKeys(exceptionKeys2);
                      setExceptionKeysLength(Object.keys(exceptionKeys2).length);
                  });

              legendElement
                  .append("text")
                  .attr("x", 10)
                  .attr("font-size", "12px")
                  .text(({key})=>key || "unassigned")
                  .attr("fill", ({key})=>(key && (key?.toString()?.trim())?"":ColorStyles.gray));

              legendElement
                  .append("circle")
                  .attr("stroke", ({value})=>value)
                  .attr("stroke-width", 2)
                  .attr("cx", 0)
                  .attr("cy", -3)
                  .attr("r", 5)
                  .attr("fill", ({key, value})=>(key in exceptionKeys)?"#FFF":value);


              var padding = 10;
              var lastIndex = 0;
              const nextRow =  [0];
              const yValuesArray = new Array(colorPairsByKey.length);

              function advanceSumCalculate(){
                  var sum = 0;
                  for(var j = 0; j < colorPairsByKey.slice(lastIndex).length; j++){
                      const newWidth = legendElement.nodes()[j + lastIndex].getBBox().width;
                      if(sum + newWidth + padding >chartWidth){
                          nextRow.push(j + lastIndex);
                          const k = lastIndex;
                          lastIndex += j;
                          j = colorPairsByKey.slice(k).length;
                          advanceSumCalculate();
                      } else {
                          sum += newWidth;
                      }
                  }
              }
              advanceSumCalculate();
              nextRow.forEach((n,i)=>{
                  const index1 = nextRow[i];
                  const index2 = nextRow[i+1] || nextRow[yValuesArray.length - 1];
                  yValuesArray[index2] = i * 15;
                  yValuesArray.fill(i * 15, index1, index2);
              });

              lastIndex = 0;
              legendElement.attr('transform', function(d,i) {
                  var total = 0;
                  sum(colorPairsByKey.map(k=>k.key),
                      (e,j) => {
                        if(j + nextRow?.[lastIndex] < i ){
                            const newWidth = legendElement.nodes()[j + (nextRow?.[lastIndex] || 0)].getBBox().width;
                            total += newWidth;
                        } else {
                            total += 0;
                        }
                          if(chartWidth < total){
                              total = 0;
                          }
                      }
                  )
                  const minus = yValuesArray[i] / 15 ;
                   if((yValuesArray[i] - (yValuesArray[i-1] || 0))> 0) {
                       total = 0;
                       lastIndex++;
                  }

                  const totalPadding = padding * i;
                  // debugger
                  return "translate("+ ( total + (padding * ((i - nextRow[lastIndex]) ))) + `,${yValuesArray[i]})`;
              })
                  .attr("class", (_,o)=>"element#"+o)
          }

          function drawBars(){
              const mainBar = svg.
              append('g')
              .attr("id", "stacked_bars_container")
                  .selectAll(".layer")
                  .data(layers)
                  .join("g")
                  .attr("class", "layer")
                  .attr("fill", layer=> colorPairs[layer.key])
                  // .attr("transform", `translate(0, ${legendElement.node().getBBox().height})`);
              const subBar = mainBar
                  .selectAll("rect")
                  .data(layer => {
                      return layer
                  })
                  .join("rect")
                  .attr("x", sequence=>xScale(sequence.data.dataLabel))
                  .attr("width", xScale.bandwidth())
                  .attr("y", sequence => {
                      return yScale(sequence[1])
                  })
                  .attr("height", sequence => {
                      return yScale(sequence[0]) - yScale(sequence[1])
                  })
                  .on("mousemove", (d, i, j) => {
                      select(this)
                          .attr("stroke", "none")
                          .style("cursor", "pointer")
                          .attr("height", (d, i) => {
                              return chartHeight - yScale(d.value)
                          })
                          .attr("y", (d) => yScale(d.value) - 10)
                          .attr("width", xScale.bandwidth() + 5)
                          .attr("x", (s) => xScale(s.label) - 2)
                          .transition()
                          .duration(200);

                      svg.select('#stacked_bars_container')
                        .attr("class", "faded")

                      // const s = select(this).attr("fill")
                      const activeElement = colorPairsByKey.find(c=>c.value===d.currentTarget.parentElement.getAttribute("fill"));
                      tooltipVB
                          .html(
                              `<div>
                                    <div style="display: flex; align-items: center; justify-content: center">
                                        <span style="background-color: ${activeElement?.value};  width: 15px; height: 15px; border: 1px solid white"></span>
                                        <span style="margin-left: 5px">${activeElement?.key || "unassigned"}</span>
                                    </div>    
                                    ${currency + Format.formatNumber(i[1] - i[0])}
                              </div>`
                          )
                          .style("left", d.pageX - 50 + "px")
                          .style("top", d.pageY - 100 + "px")
                          .style("display", "inline-block");
                  })
                  .on("mouseout", ()=>{
                    svg.select('#stacked_bars_container')
                    .attr("class", "")
                      tooltipVB.style("display", "none")
                    });
                var counter = 0
              finalLayerData.forEach(f=>{
                  let total = 0;
                  var render = 0;
                  for(var key in f){
                    if(key != 'dataLabel') total += parseFloat((f?.[key]) || 0);
                  }
                  mainBar.append('text')
                  .style("font-Size","14px")
                  .attr("class", ()=>{
                      render++;
                      if(render > 1){
                          return "duplicate_label"
                      } else {
                          return "top_cost_container"
                      }
                  })
                  .attr("x", xScale((f?.dataLabel) || null))
                  .attr("y", yScale(total) - 5)
                      .text(currency +
                          ((parseFloat(total) > 99999)?
                              convertToInternationalCurrencySystem(parseInt(total)):
                              Format.formatNumberInt(parseFloat(total))))
              })

              selectAll('.duplicate_label').remove();
          }

          const temp = new Set(layerData.map(Object.keys).flat(1));
          temp.delete('dataLabel');
          const keys = Array.from(temp);
          const finalLayerData = addMissingKeys(layerData, keys);
          const chart = select(svgRef.current);
          const legend = select(legendRef.current);

          const chartWidth = width - margin.left - margin.right;
          const chartHeight = (height /2) - (margin.top) - (margin.bottom);

          if(keys.length > colors.length){
            for(var i =0; i<(keys.length - colors.length); i++){
                var newColor = colors[0];
                while((colors.find(c=>c===newColor))!=undefined){
                    newColor = getRandomColor();
                }
                colors.push(newColor);
            }
          }
          // stacks / layers
          const stackGenerator = stack()
              .keys(keys)
              .order(stackOrderAscending);
          const colorPairs = {};
          const colorPairsByKey = [];
          keys.map((k,i)=>{
              colorPairs[k] = colors[i];
              colorPairsByKey[i] = {key: k, value: colors[i]};
          })
          const layers = stackGenerator(finalLayerData);
          const extent = [
              0,
              max(layers, layer => {
                  return max(layer, sequence => sequence[1])
              }) + 50
          ];

          // scales
          const xScale = scaleBand()
              .domain(layerData.map(d => d.dataLabel))
              .range([0, chartWidth])
              .padding(0.1);

          const yScale = scaleLinear()
              .domain(extent)
              .range([chartHeight, 0]);

          const svg = chart.append("g")
              .attr("transform", `translate(${margin.left}, ${margin.top - 10})`)
         let legendElement = legend.append('svg')
             .attr("width", "100%")
             // .style("display", "block")
             // svg.append("g")
      // legend
      //        .attr("width", chartWidth*.9)
      //        .append("g")
      //        .attr("height", chartHeight*0.1 )
             // .attr("transform", `translate(${margin.left}, 0)`)

          drawLegend();

          drawBars();

          const xAxis = axisBottom(xScale);
          svg.append("g")
              // .select(".x-axis")
              .attr("transform", `translate(0, ${chartHeight})`)
              .call(xAxis)
              // .selectAll('text')
              // .remove()

          const yAxis = axisLeft(yScale)
              .ticks(5)
              .tickFormat((d, i) => currency + Format.formatNumberInt(d));
              svg?.append("g")?.attr("class", "y_axis")?.call(yAxis);
              legend?.select('svg')
              ?.attr("height", legendElement?.node()?.parentElement?.getBBox()?.height)
              ?.attr("transform", `translate(${svg.select('.y_axis')?.node()?.getBBox()?.width}, ${legendElement?.node()?.getBBox()?.height})`)
              // .attr("y", legendElement.node().getBBox().height);
          // legendElement.axttr("transform", ()=> {
              // debugger
              // return `translate(0,0)`;
              // return `translate(${svg.select('.y_axis').node().getBBox().width}, ${legendElement.node().getBBox().height})`
          // })
          svg.selectAll('.top_cost_container')
             .attr("x", (a,b,c,d)=>{
                return c[b].getBBox().x + ((xScale.bandwidth() - c[b].getBBox().width) / 2);
             })
  }

  function drawNoData(){
      select(svgRef.current).select("g").remove();
      select(legendRef.current).select("svg").remove();
      select(svgRef.current).append("g")
          .attr("transform", `translate(${(width - margin.right -margin.left)/ 2}, ${height / 4})`)
          .style("display", "flex")
          .style("align-items", "center")
          .style("justify-content", "center")
          .append('text').text("No data found")
  }

  useEffect(() => {
      if(data2 && data2.length) drawSVG();
      else drawNoData();
      },[data2, height, width, exceptionKeysLength]);

  return (
      <>
          <div ref={legendRef}>

          </div>
        <svg ref={svgRef} width={"100%"} height={height / 2} />
      </>
  );
}

export default GetWindowDimension(StackedBarChart2);
