import React, { useEffect, useState } from "react";
import { CircularProgress, Paper} from "@mui/material";
import axios from "connection/axios";
import "./style.css";
import colors from "styles/color";
import { convertToInternationalCurrencySystem } from "util/Miscellaneous";
const { REACT_APP_AWS_S3_MATRIC_OBJECT_DATA } = process.env;

export default function MatricObjectData(props) {
    const { customerId, tenantId, bucketName, bucketRegion } = props;
    const [numberOfObjects, setNumberOfObjects] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setNumberOfObjects(null);
        return axios
            .post(REACT_APP_AWS_S3_MATRIC_OBJECT_DATA, {
                customerId,
                bucketName,
                tenantId,
                bucketRegion,
            })
            .then((response) => response.data)
            .then((response) => {
                if (response?.data) {
                    if (response?.statusCode == 200) {
                        const val = response?.data?.MetricDataResults[0]?.Values[0];
                        setNumberOfObjects(val);
                    } else {
                        setNumberOfObjects(response?.errorMessage);
                    }
                }
            })
            .finally(() => setLoading(false));
    }, [customerId, tenantId, bucketName, bucketRegion]);
    return (
        <div className="centerDiv">
            <Paper style={{ width: 250, height: 80, padding: 10, textAlign: "center", background: `linear-gradient(to right, ${colors.tableHeader}, #eef2f3)` }} elevation={3}>
                <h4 className="carouselValue">{!loading ? convertToInternationalCurrencySystem(numberOfObjects) : <CircularProgress size={28} color="secondary" />}</h4>
                <span className="carouselText">Number of objects</span>
            </Paper>
        </div>
    );
}
