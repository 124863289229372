import * as types from "../actions/types";

const userState = {
  ThisMonthSpend: null,
  LastMonthSpend: null,
  AllCloudSpending: null,
  SpendByServerless: null,
  TopFiveServerlessServices: null,
  TopFiveServices: null,
  TopFiveLocations: null,
  SpendByCategory: null,
  SpendByAccounts: null,
  currency: "",
  loading: false,
  error: null,
};

function allGcpReducer(state = userState, { type, payload }) {
  switch (type) {
      case types.ALL_GCP_LOADING: {
          return {
              ...state,
              loading: true,
          };
      }
      case types.ALL_GCP_SUCCESS: {
          const {
              currency,
              charts: { ThisMonthSpend, LastMonthSpend, AllCloudSpending, SpendByServerless, TopFiveServerlessServices, TopFiveServices, TopFiveLocations, SpendByCategory, SpendByAccounts },
          } = payload.data;
          return {
              ...state,
              currency: currency,
              ThisMonthSpend: ThisMonthSpend,
              LastMonthSpend: LastMonthSpend,
              AllCloudSpending: AllCloudSpending,
              SpendByServerless: SpendByServerless,
              TopFiveServerlessServices: TopFiveServerlessServices,
              TopFiveServices: TopFiveServices,
              TopFiveLocations: TopFiveLocations,
              SpendByCategory: SpendByCategory,
              SpendByAccounts: SpendByAccounts,
              loading: false,
          };
      }
      case types.ALL_GCP_FAIL: {
          return {
              ...state,
              loading: false,
              error: payload,
          };
      }
      case "SWITCH_TEAM_CLEAR": {
          return {
              ...state,
              ThisMonthSpend: null,
              LastMonthSpend: null,
              AllCloudSpending: null,
              SpendByServerless: null,
              TopFiveServerlessServices: null,
              TopFiveServices: null,
              TopFiveLocations: null,
              SpendByCategory: null,
              SpendByAccounts: null,
              currency: "",
          };
      }

      default:
          return state;
  }
}
export default allGcpReducer;
