import * as types from "../actions/types";

const initialState = {
  teamsList: [],
};

function connectedTeamsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.GET_CONNECTED_TEAMS_LIST_SUCCESS: {
      return {
        ...state,
        teamsList: payload,
      };
    }

    default:
      return state;
  }
}
export default connectedTeamsReducer;
