import {makeStyles, withStyles} from "MakeWithStyles";
import {
  Button,
  Fade,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell as TCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getMonthsFromNow } from "../util/DateFormatter";
import AZURE_LOGO from "../images/Azure_logo.svg";
import AWS_LOGO from "../images/aws_dark.svg";
import GCP_LOGO from "../images/GCP_logo.svg";
import COMBINED_CLOUD_LOGO from '../images/allCloudLogo.svg';
import Format from "./NumberFormat";
import moment from "moment";
import YearCalendarData from "./YearCalendarData";
import Cost, { Currency } from "./Cost";

function TableCell(props) {
  const {extras = {}} = props;
  return (
      <TCell {...props} style={{ ...(props.style?props.style:{}), padding: 5, ...extras }}>
        {props.children}
      </TCell>
  );
}

const CLOUD_ICONS = {
  azure: AZURE_LOGO,
  aws: AWS_LOGO,
  gcp: GCP_LOGO,
  total: COMBINED_CLOUD_LOGO,
};
const useStyles2 = makeStyles()((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  head: {
    backgroundColor: "",
    minWidth: "50px",
  },
  tableContainer: {
    maxHeight: "400px",
  },
  cell: {
    minWidth: "100px",
  },
}));

const StickyTableCell = withStyles(TableCell, (theme) => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 2,
  },
  body: {
    // backgroundColor: "#ddd",
    backgroundColor: "",
    minWidth: "50px",
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1,
  },
}));

const StyledTableCell = withStyles(TableCell,(theme) => ({
  head: {
    border: "1px solid #98989833",
    borderTopWidth: "0px",
    borderLeftWidth: "0px",
  },
  body: {
    border: "1px solid #98989833",
    borderTopWidth: "0px",
    borderBottomWidth: "0px",
    borderLeftWidth: "0px",
    fontSize: 14,
  },
}));

export default function YearTableData({
  hideLegend = false,
  width = null,
  data = { aws: [], azure: [], gcp: [] },
  months = [],
  currency,
  showTotal = false,
  onMouseOver = () => {},
  renderOverlay = () => null,
  onMonthPress = () => null,
  selectedYear = "",
  selectedMonth = "",
  selectedCloud = "",
  tableId=null,
    // getAsCSV= () => {}
}) {
  const [monthsList, setMonthsList] = useState([]);
  const {classes} = useStyles2();
  const [allCloudMonthWiseTotal, setAllCloudMonthWiseTotal] = useState([]);
  let [yearWiseTotal, setYearWiseTotal] = useState(null);
  let [finalData, setFinalData] = useState({});
  useEffect(() => {
    const totals = new Array();
    setYearWiseTotal({
      aws: finalData?.aws?.reduce((a, b) => (a || 0) + (b || 0), 0),
      gcp: finalData?.gcp?.reduce((a, b) => (a || 0) + (b || 0), 0),
      azure: finalData?.azure?.reduce((a, b) => (a || 0) + (b || 0), 0),
      total: finalData?.total?.reduce((a, b) => (a || 0) + (b || 0), 0),
    });
    // for (var i = 0; i < 13; i++) {
    //   const total =
    //     (finalData?.aws?.[i] || 0) +
    //     (finalData?.azure?.[i] || 0) +
    //     (finalData?.gcp?.[i] || 0);
    //   totals.push(total);
    // }
    setAllCloudMonthWiseTotal(totals);
  }, [finalData]);

  useEffect(() => {
    let finalData = {};
    if (data && Object.keys(data)) {
      Object.keys(data).forEach((cloud) => {
        if (data?.[cloud]?.length) {
          const costs = getMonthsFromNow()
            .reverse()
            .map((m, i) => {
              const time = moment(m, "MMM YYYY");
              const found = data?.[cloud].findIndex((d) => {
                const int = moment(d?.month, "YYYYMM");
                return time.isSame(int);
              });
              if (found >= 0) {
                return data?.[cloud]?.[found]?.cost;
              } else {
                return null;
              }
            });
          // data?.[cloud].map(d=>{
          //     const month = moment(d.month.substring(0, 4) + " " + d.month.substring(5));
          //
          // })
          finalData[cloud] = costs.map((d) => parseFloat(d || 0));
          // for(let i=0; i<13; i++){
          //     finalData[cloud] = [...(data?.[cloud] || []), ...new Array((13 - (data?.[cloud]?.length || 0))).fill("")];
          // }
        }
      });
      setFinalData(finalData);
    }
  }, [data]);

  // useEffect(()=>{
  //   if(finalData && Object.keys(finalData)) {
  //     const monthsList = getMonthsFromNow(months)
  //         .reverse()
  //         .map((o, i) => (o));
  //     getAsCSV([
  //       ['Cloud', ...monthsList, 'Total'],
  //       ...Object.keys(finalData).map(f => ([
  //         f,
  //         ...finalData[f].map(f => f? currency + parseFloat(f).toFixed(2):""),
  //         parseFloat(finalData[f].reduce((a, b) => a + b, 0))?.toFixed(2)
  //       ]))
  //     ])
  //   }
  //
  // }, [finalData])

  useEffect(() => {
    setMonthsList(getMonthsFromNow(months, true, true).reverse());
  }, [months]);

  if (width && months && months.length) {
    return (
      <TableContainer component={Paper} style={{ width }}>
        <Table size={'small'}
          bordered
          stickyHeader
          className={classes.table}
          aria-label="simple table"
          id={tableId}
               style={{border: '1px solid #4051b54f'}}
        >
          <>
            <TableRow style={{backgroundColor: '#4051b54f'}}>
              {!hideLegend && (
                <StickyTableCell>
                  <TableCell
                    style={{
                      borderBottomWidth: 0,
                      padding: 4,
                      fontWeight: "bold",
                    }}
                  >
                    Cloud
                  </TableCell>
                </StickyTableCell>
              )}
              {getMonthsFromNow(months)
                .reverse()
                .map((o, i) => (
                  <TableCell
                    align={"center"}
                    className={classes.head}
                    numeric
                    style={{
                      padding: 0,
                      backgroundColor: i % 2 ? "" : "",
                      borderRightWidth: 1,
                      fontWeight: "bold",
                    }}
                  >
                    {o}
                  </TableCell>
                ))}
              <TableCell
                align={"center"}
                className={classes.head}
                numeric
                style={{
                  minWidth: 90,
                  // backgroundColor: "#3f51b5",
                  // color: "white",
                  fontWeight: "bold",
                }}
              >
                Total
              </TableCell>
              {/*{*/}
              {/*  getMonthsFromNow("'20", "'21").map(month=>(<StickyTableCell align="right">{month}</StickyTableCell>*/}
              {/*    ))}*/}
            </TableRow>
          </>
          <TableBody>
            {Object.keys(finalData).map((row,i) =>
              finalData?.[row]?.length ? (
                <TableRow key={row} style={{backgroundColor: (i%2!=0)?'#eceefe':''}}>
                  {!hideLegend && (
                    <StickyTableCell bordered border extras={{padding: 0, border: '1px 1px 1px 1px solid black'}} style={{ padding: 0,
                      // border: '0px 0px 0px 1px solid'
                    }}>
                      <StyledTableCell
                        component="th"
                        numeric
                        style={{
                          // borderRight: ,
                          display: "flex",
                          alignItems: "center",

                          justifyContent: "center",
                          backgroundColor: row === "total" ? "" : "",
                          color: row === "total" ? "" : "",
                        }}
                        extras={{padding: 0}}
                        // extras = {row === "total"?{backgroundColor: ''}:{}}
                      >
                        {/*{row === "total" ? (*/}
                        {/*  <span>Total</span>*/}
                        {/*) : (*/}
                          <div>
                            <img
                              height={24}
                              src={CLOUD_ICONS[row]}
                              //   style={{ marginLeft: 16 }}
                            />
                          </div>
                        {/*)}*/}
                        {/*{row.substring(0, 1).toUpperCase() + row.substring(1)}*/}
                      </StyledTableCell>
                    </StickyTableCell>
                  )}
                  {finalData?.[row.toLowerCase()]?.map((o, i) => (
                    <>
                      <CostCell
                        style={classes.head}
                        year={monthsList?.[i]?.year}
                        month={monthsList?.[i]?.month}
                        onMonthPress={onMonthPress}
                        renderOverlay={renderOverlay}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                        row={row}
                        cloud={row?.toLowerCase() || ""}
                        selectedCloud={selectedCloud}
                      >
                        {o && o.toString()?.trim() ? (
                          <span style={{fontSize: 10, padding: 0, margin: 0}}>
                            {currency || <Currency />}
                            <Cost>{o}</Cost>
                          </span>
                        ) : (
                          "-"
                        )}
                      </CostCell>
                    </>
                  ))}
                  <StickyTableCell
                    align="center"
                    className={classes.head}
                    numeric
                    style={{
                      backgroundColor: row === "total" ? "" : "",
                      color: row === "total" ? "" : "",
                    }}
                  >
                    {currency || <Currency />}
                    <Cost>
                      {yearWiseTotal?.[row.toLowerCase()]?.toFixed(2)}
                    </Cost>
                  </StickyTableCell>
                </TableRow>
              ) : null
            )}
            {/*{false && showTotal ? (*/}
            {/*  <TableRow>*/}
            {/*    <StickyTableCell*/}
            {/*      style={{ backgroundColor: "#3f51b5", padding: 4 }}*/}
            {/*    >*/}
            {/*      <StyledTableCell*/}
            {/*        component="th"*/}
            {/*        numeric*/}
            {/*        style={{*/}
            {/*          borderRight: 0,*/}
            {/*          color: "white",*/}
            {/*          display: "flex",*/}
            {/*          justifyContent: "center",*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        Total*/}
            {/*      </StyledTableCell>*/}
            {/*    </StickyTableCell>*/}
            {/*    {allCloudMonthWiseTotal?.map((o) => (*/}
            {/*      <StyledTableCell*/}
            {/*        style={{ backgroundColor: "#90a0ff", color: "white" }}*/}
            {/*        align="center"*/}
            {/*        className={classes.head}*/}
            {/*        numeric*/}
            {/*      >*/}
            {/*        {currency || <Currency/>}*/}
            {/*        <Cost>{o}</Cost>*/}
            {/*      </StyledTableCell>*/}
            {/*    ))}*/}
            {/*    <StyledTableCell*/}
            {/*      style={{ backgroundColor: "#3f51b5", color: "white" }}*/}
            {/*      align="center"*/}
            {/*      className={classes.head}*/}
            {/*      numeric*/}
            {/*    >*/}
            {/*      {currency || <Currency/>}*/}
            {/*      <Cost>*/}
            {/*        {allCloudMonthWiseTotal*/}
            {/*            .reduce((a, b) => a + b, 0)*/}
            {/*            ?.toFixed(2)}*/}
            {/*      </Cost>*/}
            {/*    </StyledTableCell>*/}
            {/*  </TableRow>*/}
            {/*) : null}*/}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return null;
}

function CostCell({
  row = "",
  children,
  style,
  year,
  month,
  renderOverlay,
  onMonthPress,
  selectedMonth = null,
  selectedYear = null,
  cloud,
  selectedCloud,
}) {
  // const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
  // const [showPopover, setShowPopover] = useState(false);
  // const showingPopover = Boolean(popoverAnchorEl);
  // const popover_id = showingPopover ? "messages-popup"+children : undefined;

  return (
    <>
      <StyledTableCell
        style={{
          // border: '1px solid',
          backgroundColor: row === "total" ? "" : "",
          padding: 0, fontSize: 5, margin: 0
         }}
        align="center"
        className={style}
        numeric
        // onMouseOver={(e)=>setPopoverAnchorEl(e.currentTarget)}
        // onMouseOut={(e)=>setPopoverAnchorEl(null)}
      >
        <Button
          onClick={() => onMonthPress(month, parseInt(year), cloud)}
          variant={
            selectedMonth === month && selectedYear === year
              ? selectedCloud === cloud
                ? "contained"
                : "outlined"
              : "outlined"

            // row == "total"
            //   ? selectedMonth === month && selectedYear === year
            //     ? "contained"
            //     : "outlined"
            //   : ""
          }
          color={"primary" || 0}
          style={{
            cursor: "pointer", fontSize: 10,
            padding: 5 ,
            margin: 0,
            lineHeight: 1.5,
            // height: 10,
            // color: row == "total" ? "white" : "",
            // borderColor: row == "total" ? "white" : "",
            outline: "none",
          }}
        >
          {children}
        </Button>
      </StyledTableCell>
      {/*<Popper style={{zIndex: 100}} placement="right-start" id={popover_id}*/}
      {/*        open={showingPopover} anchorEl={popoverAnchorEl} transition>*/}
      {/*  {({ TransitionProps }) => (*/}
      {/*      <Fade {...TransitionProps} timeout={500}>*/}
      {/*        <Paper elevation={3}>*/}
      {/*          {renderOverlay(month, parseInt(year))}*/}
      {/*        </Paper>*/}
      {/*      </Fade>*/}
      {/*  )}*/}
      {/*</Popper>*/}
    </>
  );
}
