import * as types from "../actions/types";

const initialState = {
    tagsList: [],
    subscriptionTagsList: [],
    loading: false
};

function azureTagsReducer(state = initialState, { type, payload }) {
    switch (type) {
        case types.AZURE_TAGS_REQUEST: {
            if(payload.tenantId && payload.subscriptionId){
                return {
                    ...state,
                    loading: true,
                    subscriptionTagsList: []
                };
            } else {
                return {
                    ...state,
                    loading: true,
                    tagsList: []
                };
            }
        }
        case types.AZURE_TAGS_SUCCESS: {
            if(payload.tenantId && payload.subscriptionId){
                return {
                    ...state,
                    loading: false,
                    subscriptionTagsList: payload?.data
                };
            } else {
                return {
                    ...state,
                    loading: false,
                    tagsList: payload?.data
                };
            }
        }
        case types.AZURE_TAGS_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case "SWITCH_TEAM_CLEAR": {
            return {
                ...initialState
            };
        }

        default:
            return state;
    }
}
export default azureTagsReducer;
