import React, {useState, useEffect, useRef} from "react";
import { makeStyles, withStyles } from "MakeWithStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import azure_reservations from "../../images/newCloudTrakrIcons/AzureReservations.png";
import IconButton from "@mui/material/Icon";
import IconButton2 from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Format from "components/NumberFormat";
import moment from "moment";
import Color from "../../styles/color";
import { getAzureReservationRequest } from "../../redux/actions/actions";
import { LinearProgress } from "@mui/material";
import Cost from "../../components/Cost";
import DateRangeformatter, { commonConversion, oneDSpend, oneWSpend, oneMSpend, threeMSpend, sixMSpend, oneYSpend, thisMonthSpend, lastMonthSpend } from "../../util/DateRangeFormatter";

import DateRange from "../../components/DateRange";
import { getCurrentMonth, getCurrentYear, getMonthForDate } from "../../util/DateFormatter";

import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import dateUtil from "../../util/DateUtil";
import StartEndDate from "components/StartEndDate";
import TablePaginationActions from "../../components/TablePaginationActions";
import TableSearch from "../../components/TableSearch";
import {Print} from "@mui/icons-material";
import {useReactToPrint} from "react-to-print";

const useStyles = makeStyles()((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
}));

export default function AzureReservation({ currency }) {
    const dispatch = useDispatch();
    const {classes} = useStyles();
    const [progress, setProgress] = useState(0);
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [reservationData, setReservationData] = useState([]);
    const [list, setList] = useState(null);
    const [total, setTotal] = useState("");
    const [period, setPeriod] = useState("1M");
    const [search, setSearch] = useState(null);

    const userData = useSelector((state) => ({
        id: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
        subscriptions: state.allHomeReducer.allSubscriptions?.azure,
        allSub: state.allHomeReducer.allSubscriptions,
    }));

    let azureReservation = useSelector((state) => state?.azureReservationReducer?.data);

    let loading = useSelector((state) => state?.azureReservationReducer?.loading);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (userData.allSub !== null) {
            if (userData.allSub) {
                const tenantId = userData.allSub?.azure?.[0]?.tenantId;
                const customerId = userData.id;

                if (azureReservation == null) {
                    dispatch(
                        getAzureReservationRequest({
                            customerId,
                            tenantId,
                        })
                    );
                }
            }
        }
    }, [userData.id, userData.allSub]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (azureReservation !== null) {
            let allArray = azureReservation?.[0]?.rows;

            // const { data } = sixMSpend(allArray);

            const { data } = period == "1D" ? oneDSpend(allArray) : period == "1W" ? oneWSpend(allArray) : period == "1M" ? oneMSpend(allArray) : period == "3M" ? threeMSpend(allArray) : period == "6M" ? sixMSpend(allArray) : oneYSpend(allArray);
            console.log("threeM", period, data);
            setReservationData(data);
        }
    }, [azureReservation, period]);

    function onClick(period) {
        setSelected(period);
        setPeriod(period);
    }

    const componentRef = useRef();
    const onPrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.reservationName,
                f?.reservationId,
                f?.cost,
                
            ], search);
        }
        return true;
    }

    return loading ? (
        <LinearProgress variant="determinate" value={progress} />
    ) : (
        <div className={classes.root}>
            <Paper elevation={10} style={{ padding: 10 }} ref={componentRef}>
                <div
                    style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <div style={{ flexDirection: "row", display: "flex" }}>
                        <img src={azure_reservations} height={24} style={{ marginRight: 5 }} />

                        <h5>
                            {" "}
                            <b>Azure Reservations </b>
                        </h5>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {azureReservation && reservationData != null && reservationData?.resourceGroup?.length ? <StartEndDate startDate={reservationData?.startDate} endDate={reservationData?.endDate} /> : null}

                        <span
                            style={{
                                margin: 4,
                                fontSize: 12,
                                color: "#616161",
                            }}
                        >
                            Last
                        </span>
                        <DateRange height={22} padding={1} fontSize={12} onSelectionChanged={setPeriod} defaultSelected={period} />
                        <IconButton2 onClick={onPrint} style={{outline: "none"}}>
                            <Print />
                        </IconButton2>
                    </div>
                </div>
                {azureReservation && reservationData != null && reservationData?.resourceGroup?.length ? (
                    <Paper className={classes.paper}>
                        <h6 style={{ textAlign: "right" }}>
                            <b>
                                Total: {currency}
                                <Cost>{reservationData.totalCost}</Cost>
                            </b>
                        </h6>
                        <TableSearch onSearch={setSearch} />
                        <TableContainer>
                            <Table className={classes.table} aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                <AzureReservationHead classes={classes} numSelected={selected.length} rowCount={reservationData.length} azureReservation={azureReservation} reservationData={reservationData} />
                                <TableBody>
                                    {reservationData?.resourceGroup?.filter(filteredTable)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        return <MyTableRow row={row} index={index} list={list} setList={setList} currency={currency} />;
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            ActionsComponent={TablePaginationActions}
                            rowsPerPageOptions={[10, 25, 50, 75]}
                            component="div"
                            count={reservationData?.resourceGroup?.filter(filteredTable)?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                ) : (
                    <h6 style={{ textAlign: "center" }}>No data found</h6>
                )}
            </Paper>
        </div>
    );
}

const useStyles22 = makeStyles()((theme) => ({
    root: {
        backgroundColor: Color.tableHeader,
        padding: theme.spacing(0.5),
    },

    tableRow: {
        fontWeight: "bold",
    },
}));

function AzureReservationHead({ totalCost, currency = "", azureReservation, reservationData }) {

    const {classes} = useStyles22();

    return (
        <TableHead className={classes.root}>
            <TableRow>
                <TableCell></TableCell>
                {/* <TableCell className={classes.tableRow}>Date</TableCell> */}
                <TableCell className={classes.tableRow}>Reservation name</TableCell>
                <TableCell className={classes.tableRow}>Reservation Id</TableCell>
                <TableCell align="right" className={classes.tableRow}>
                    Cost
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

function MyTableRow({ row, index, list, setList, currency }) {
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState("");

    useEffect(() => {
        if (row) {
            setDate(moment(row.date, "YYYYMMDD").format("MMM DD, YYYY"));
        }
    }, [row]);

    return (
        <React.Fragment>
            <TableRow hover key={row.date}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {/* <TableCell component="th" scope="row" padding="none">
          {date}
        </TableCell> */}
                <TableCell>{row.reservationName}</TableCell>
                <TableCell>{row.reservationId}</TableCell>

                <TableCell align="right">
                    {currency}
                    {Format.formatNumber(Number(row.cost))}
                </TableCell>
            </TableRow>
            <ExpandLavelData data={row} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

export function ExpandLavelData({ data, open, select, set, currency }) {
    //const [resource, setResource] = useState(null);

    //   useEffect(() => {
    //     if (select != null) {
    //       let value = data.resources[select];
    //       setResource(value);
    //     }
    //   }, [select, data]);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2.5}>
                            <Htmltable data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <div style={{ maxHeight: 300 }} className="overflow-auto">
            <table id="customers">
                <tr>
                    <th>Resource</th>
                    <th>Type</th>
                    <th>Location</th>
                    <th>Resource group</th>
                    <th>Subscription</th>
                    <th>Total</th>
                </tr>

                {data &&
                    data?.resources
                        ?.sort((a, b) => {
                            return parseFloat(b?.cost || 0) - parseFloat(a?.cost || 0);
                        })
                        .map((m) => (
                            <tr>
                                <td>{m?.resourceName}</td>
                                <td>{m.resourceType.split("/")[5]}</td>
                                <td>{m.location}</td>
                                <td>{m.resourceType.split("/")[4]}</td>
                                <td>{m.subscriptionName}</td>
                                <td>{currency + Format.formatNumber(Number(m.cost))}</td>
                            </tr>
                        ))}
            </table>
        </div>
    );
}
