import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getInvoicesFailure, getInvoicesSuccess } from "../actions/actions";
const { REACT_APP_GETINVOICE_JSON } = process.env;

function* getInvoicesData({ payload }) {
  try {
    const res = yield call(axios.get, REACT_APP_GETINVOICE_JSON, {
      params: {
        customerId: payload,
      },
    });

    const { statusCode } = res.data;

    //  yield put(getBudgetsSuccess(res.data));
    if ("statusCode" in res.data) {
      if (statusCode !== 200) {
        yield put(getInvoicesFailure(statusCode));
      } else {
        yield put(getInvoicesSuccess(res.data));
      }
    } else {
      yield put(getInvoicesFailure(res));
    }
  } catch (error) {
    // console.log("error", error);
    yield put(getInvoicesFailure(error));
  }
}

export default function* getInvoiceSaga() {
  yield takeLatest(types.GET_INVOICE_JSON_REQUEST, getInvoicesData);
}
