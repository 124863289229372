import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "MakeWithStyles";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Azure_cosmosDB_icon from "../images/newCloudTrakrIcons/AzureCosmosDB.png";
import Cost from "../components/Cost";
import LineChart from "pages/Charts/LineChart";
import { bytesToSiz2e } from "../util/Miscellaneous";
import { bytesToSize, convertToInternationalCurrencySystem } from "../util/Miscellaneous";
import function_apps_icon from "../images/newCloudTrakrIcons/AzureFunction.png";
import storage_account_icon from "../images/resources/Storage_Account.svg";
import dynamoDBIcon from "../images/DynamoDB.svg";
import RDSIcon from "../images/RDS.svg";

import Format from "components/NumberFormat";
import moment from "moment";
import { Table, TableBody, TableCell as TCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Divider, CircularProgress } from "@mui/material";
import { CancelRounded } from "@mui/icons-material";
import AWSDynamoDB from "pages/DashboardAccountsHome/AWSDynamoDB";

function DetailsDialogBox(props) {
    const { title, handleClose, data = null, detailsData = null, metricData = null, loading, metricTotals, chartData, open, currency, rawStart, rawEnd } = props;
    const [showMetricSpecific, setShowMetricSpecific] = useState([]);
    const DATE_FORMAT = "MMM DD, YYYY";

    const useStyles = makeStyles()((theme) => ({
        table: {
            minWidth: 750,
        },
        modelStyle: {
            height: 600,
        },
    }));

    const {classes} = useStyles();

    function onClose() {
        handleClose();
    }

    return (
        <Dialog onClose={onClose} fullWidth={true} maxWidth={"xl"} open={open} onClose={handleClose} open={open}>
            <DialogTitle style={{ backgroundColor: "#cfdac8", padding: 6 }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Typography component={"h5"} variant={"h5"}>
                        {title == "Azure cosmosDB" ? (
                            <>
                                {" "}
                                <img src={Azure_cosmosDB_icon} height={30} /> {data?.resourceId}{" "}
                            </>
                        ) : title == "Azure functions" ? (
                            <>
                                {" "}
                                <img src={function_apps_icon} height={30} /> {data?.resourceId}{" "}
                            </>
                        ) : title == "Azure storage" ? (
                            <>
                                {" "}
                                <img src={storage_account_icon} height={30} /> {data?.storage_account_name}{" "}
                            </>
                        ) : title == "DynamoDB" ? (
                            <>
                                {" "}
                                <img src={dynamoDBIcon} height={30} /> {data?.resourceId}({data?.serviceName})
                            </>
                        ) : title =='RDS' ? (
                            <>
                             <img src={RDSIcon} height={30} /> 
                             {data?.resourceId}
                            {/* {data?.type == "cluster" ? data?.DBClusterIdentifier : data?.type == "instance" ? data?.DBInstanceIdentifier : null} */}
                            </>
                        ): null}
                    </Typography>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                border: "2px solid #338307",
                                borderRadius: 5,
                                fontSize: 13,
                                marginRight: 20,
                            }}
                        >
                            <span style={{ paddingLeft: 6, paddingRight: 6 }}>
                                <span>{moment(rawStart).isValid() ? moment(rawStart).format(DATE_FORMAT) : "__"}</span>-<span>{moment(rawEnd).isValid() ? moment(rawEnd).format(DATE_FORMAT) : "__"}</span>
                            </span>
                        </div>
                        <Typography component={"h5"} variant={"h5"}>
                            {currency}
                            <Cost>{title == "Azure storage" ? data?.billing?.[0]?.cost : data?.cost}</Cost>
                        </Typography>

                        <CancelRounded onClick={onClose} />
                    </div>
                </div>
            </DialogTitle>
            <DialogContent className={classes.modelStyle}>
                {!loading ? (
                    <Box>
                        <b>{title} account details</b>

                        {detailsData ? (
                            <Box flexDirection={"row"} display={"flex"}>
                                {title == "Azure cosmosDB" ? (
                                    <AzureCosmosDB title={title} detailsData={detailsData} metricData={metricData} />
                                ) : title == "Azure functions" ? (
                                    <AzureFunctions title={title} detailsData={detailsData} />
                                ) : title == "Azure storage" ? (
                                    <AzureStorage title={title} detailsData={detailsData} metricData={metricData} />
                                ) : title == "DynamoDB" ? (
                                    <AwsDynamoDB title={title} detailsData={detailsData} metricData={metricData} />
                                ): title == "RDS" ? (
                                    <AwsRDS title={title} detailsData={detailsData} metricData={metricData} />
                                ) : null}
                            </Box>
                        ) : (
                            <Typography style={{ display: "flex", justifyContent: "center" }}>No data found</Typography>
                        )}
                        <Divider />
                        <br />
                        <Paper elevation={3} padding={8}>
                            {chartData && chartData.length
                                ? chartData.map((m, i) => (
                                      <>
                                          <Box padding={2}>
                                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                  {" "}
                                                  <b style={{ marginRight: 4 }}>{m.key === "throttledRequests" ? "Throttled Requests" : m.name}</b>{" "}
                                                  {m?.metricData?.timeseries ? (
                                                      <b>
                                                          {
                                                              {
                                                                  Count: convertToInternationalCurrencySystem(showMetricSpecific?.[i] || metricTotals[i]),
                                                                  Bytes: bytesToSize(showMetricSpecific?.[i] || metricTotals[i]),
                                                                  Percent: (showMetricSpecific?.[i] || metricTotals[i]) + "%",
                                                              }[m?.unit]
                                                          }
                                                      </b>
                                                  ) : null}{" "}
                                              </div>

                                              <LineChart
                                                  onMouseOverData={(data) => {
                                                      let d = showMetricSpecific;
                                                      d[i] = data;
                                                      setShowMetricSpecific(d);
                                                  }}
                                                  data={m?.metricData?.timeseries}
                                                  currency={currency}
                                                  currencyRequired={false}
                                                  unit={m?.unit}
                                              />
                                          </Box>

                                          <hr />
                                      </>
                                  ))
                                : null}
                        </Paper>
                    </Box>
                ) : (
                    <Box marginTop={"15%"} display={"flex"} flexDirection={"column"} flex={1} justifyContent={"center"} alignItems={"center"}>
                        <CircularProgress size={150} thickness={1} />
                        <Typography variant={"h5"} style={{ marginTop: 20, top: "50%" }}>
                            Loading
                        </Typography>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
}
function resolveDateTime(dateTime) {
    const date = moment(dateTime);
    return date.format("LL") + " at " + date.format("LTS");
}

function AzureFunctions({ detailsData }) {
    return (
        <>
            <Box flex={1}>
                <Typography>
                    <b>Location</b>
                </Typography>
                <Typography>{detailsData?.location || "-"}</Typography>
                <Typography style={{ marginTop: 10 }}>
                    <b>Resource Group</b>
                </Typography>
                <Typography>{detailsData?.properties?.resourceGroup}</Typography>
            </Box>
            <Box flex={1}>
                <Typography style={{ marginTop: 10 }}>
                    <b>Subscription Id</b>
                </Typography>
                <Typography>{detailsData?.subscriptionId}</Typography>
                <Typography style={{ marginTop: 10 }}>
                    <b>Subscription Name</b>
                </Typography>
                <Typography>{detailsData?.subscriptionName}</Typography>
                <Typography style={{ marginTop: 10 }}>
                    <b>Status</b>
                </Typography>
                <Typography>{detailsData?.properties?.state}</Typography>
            </Box>
        </>
    );
}

function AzureStorage({ detailsData }) {
    return (
        <>
            <Box flex={1} style={{ padding: 10 }} flexDirection={"row"} display={"flex"}>
                <Box flex={1}>
                    <Typography>
                        <b>Access Tier</b>
                    </Typography>
                    <Typography>{detailsData?.access_tier || "-"}</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Account Kind</b>
                    </Typography>
                    <Typography>{detailsData?.account_kind}</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Location</b>
                    </Typography>
                    <Typography>{detailsData?.location}</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Performance</b>
                    </Typography>
                    <Typography>{detailsData?.performance}</Typography>
                </Box>
                <Box flex={1}>
                    <Typography>
                        <b>Replication</b>
                    </Typography>
                    <Typography>{detailsData?.replication}</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Resource group</b>
                    </Typography>
                    <Typography>{detailsData?.resource_group}</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Status</b>
                    </Typography>
                    <Typography>{detailsData?.status}</Typography>
                    {/* <Typography style={{ marginTop: 10 }}>
                                                            <b>Capacity</b>
                                                        </Typography> */}
                    {/* <Typography>{bytesToSize(detailsData?.capacity.average)}</Typography> */}
                </Box>
            </Box>
        </>
    );
}

function AzureCosmosDB({ title, detailsData, metricData }) {
    function TableCell(props) {
        return (
            <TCell {...props} style={{ padding: 5 }}>
                {props.children}
            </TCell>
        );
    }
    return (
        <Box flex={1}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Box flex={1}>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Resource group</b>
                    </Typography>
                    <Typography>{detailsData?.id.split("/")[4]}</Typography>

                    <Typography style={{ marginTop: 10 }}>
                        <b>Backup policy</b>
                    </Typography>
                    <Typography>{detailsData?.properties?.backupPolicy?.type}</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Subscription Id</b>
                    </Typography>
                    <Typography>{detailsData?.id.split("/")[2]}</Typography>
                </Box>
                <Box flex={1}>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Read Locations</b>
                    </Typography>
                    <Typography>{detailsData?.properties?.readLocations?.[0]?.locationName}</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Write Locations</b>
                        <Typography>{detailsData?.properties?.writeLocations?.[0]?.locationName}</Typography>
                    </Typography>
                </Box>
            </div>
            <br />

            <div>
                <b>{title} account metrics by collection</b>

                {metricData?.dataUsage?.length > 0 ? (
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead style={{ backgroundColor: "#cfdac8" }}>
                                <TableRow>
                                    <TableCell>
                                        <b>Collection</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>Data Usage</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody style={{ padding: 0 }}>
                                {metricData?.dataUsage?.map((row, l) => (
                                    <TableRow key={row?.name}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {row?.name}
                                        </TableCell>
                                        <TableCell>{row?.unit == "Bytes" ? bytesToSiz2e(row?.average) : "--"}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography style={{ display: "flex", justifyContent: "center" }}>No data found</Typography>
                )}
            </div>
        </Box>
    );
}

function AwsDynamoDB({ detailsData }) {
    return (
        <>
            <Box style={{ padding: 10 }} flexDirection={"row"} flex={1}>
                <Box flex={1}>
                    <Typography>
                        <b>Table Id</b>
                    </Typography>
                    <Typography>{detailsData?.TableId || "-"}</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Table Arn</b>
                    </Typography>
                    <Typography>{detailsData?.TableArn || "-"}</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Primary Partition Key</b>
                    </Typography>
                    <Typography>
                        {detailsData?.AttributeDefinitions?.[0]?.AttributeName} ({{ S: "String" }[detailsData?.AttributeDefinitions?.[0]?.AttributeType]})
                    </Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Size</b>
                    </Typography>
                    <Typography>{bytesToSize(detailsData?.TableSizeBytes)}</Typography>
                    {/*<Typography style={{marginTop: 10}}><b>Cost</b></Typography>*/}
                    {/*<Typography>{currency}<Cost>{showingDetails?.billing.map(b=>parseFloat(b.cost)).reduce((a,b)=>a+b, 0).toFixed(2)}</Cost></Typography>*/}
                </Box>
                <Box flex={1}>
                    <Typography>
                        <b>Status</b>
                    </Typography>
                    <Typography>{detailsData?.TableStatus || "-"}</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Created Date Time</b>
                    </Typography>
                    <Typography>{detailsData?.CreationDateTime ? resolveDateTime(detailsData?.CreationDateTime) : "-"}</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Location</b>
                    </Typography>
                    <Typography>{detailsData?.location || "-"}</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Item Count</b>
                    </Typography>
                </Box>
            </Box>
        </>
    );
}

function AwsRDS({detailsData}) {
    return (
        <>
            <Box flex={1}>              
                        <Box style={{ padding: 10 }} flexDirection={"row"} display={"flex"}>
                            <Box flex={1}>
                                <Typography>
                                    <b>DB Identifier</b>
                                </Typography>
                                <Typography>{detailsData?.type == "DBInstance" ? detailsData?.DBInstanceIdentifier : detailsData?.DatabaseName || '--'}</Typography>
                                <Typography style={{ marginTop: 10 }}>
                                    <b>Role</b>
                                </Typography>
                                <Typography>{detailsData?.type == "DBInstance" ? detailsData?.DBName : detailsData?.DBClusterIdentifier|| '--'}</Typography>
                                <Typography style={{ marginTop: 10 }}>
                                    <b>Status</b>
                                </Typography>
                                <Typography>{detailsData?.type == "DBInstance" ? detailsData?.DBInstanceStatus : detailsData?.Status|| '--'}</Typography>
                                <Typography style={{ marginTop: 10 }}>
                                    <b>Zone</b>
                                </Typography>
                                <Typography>{detailsData?.AvailabilityZone||'--'}</Typography>
                                <Typography style={{ marginTop: 10 }}>
                                <b>Allocated storage</b>
                            </Typography>
                            <Typography>{detailsData?.AllocatedStorage||'--'}</Typography>
                            </Box>
                            <Box flex={1}>
                                <Typography style={{ marginTop: 10 }}>
                                    <b>Master username Id</b>
                                </Typography>
                                <Typography>{detailsData?.MasterUsername||'--'}</Typography>
                                <Typography style={{ marginTop: 10 }}>
                                    <b>DBInstance class</b>{" "}
                                </Typography>
                                <Typography>{detailsData?.DBInstanceClass||'--'}</Typography>
                                <Typography style={{ marginTop: 10 }}>
                                    <b>Engine</b>
                                </Typography>
                                <Typography>{detailsData?.Engine||'--'}</Typography>
                                <Typography style={{ marginTop: 10 }}>
                                    <b>Time</b>
                                </Typography>
                                <Typography>{detailsData?.InstanceCreateTime||'--'}</Typography>
                                <Typography style={{ marginTop: 10 }}>
                                    <b>Engine version</b>
                                </Typography>
                                <Typography>{detailsData?.EngineVersion||'--'}</Typography>
                               
                            </Box>
                        </Box>          
            </Box>
        </>
    );
}

export default DetailsDialogBox;
