import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, Dialog, IconButton, Typography, DialogContent, Paper } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MuiDialogTitle from "@mui/material/DialogTitle";
import { makeStyles, withStyles } from "MakeWithStyles";
import CloseIcon from "@mui/icons-material/Close";
import Azure_logo from "../images/Azure_logo.svg";
import AWS_LOGO from "../images/aws_dark.svg";
import GCP_ICON from "../images/GCP_logo.svg";
import { Link } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import axios from "../connection/axios";
import ReactPlayer from "react-player";
import azureThumbnail from "../images/azureThumbnail.png";
import awsThumbnail from "../images/awsThubnail.png";
import gcpThumbnail from "../images/gcpThumbnail.png";
import RoutesList from "../routes/RoutesList";

const {REACT_APP_MSAL_AZURE_CONNECTION} =process.env;

const azureConnectionVideo = process.env.REACT_APP_VIDEO_AZURE_SETUP;
const awsConnectionVideo = process.env.REACT_APP_VIDEO_AWS_SETUP;
const gcpConnectionVideo = process.env.REACT_APP_VIDEO_GCP_SETUP;

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

function Dialogtitle2(props){
    const { children, classes, onClose, ...other } = props;
    // const classes = withStyles.getClasses(props);

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
}

const DialogTitle = withStyles(Dialogtitle2, styles);

export default function ConenctAccountSkipPage({ hasAWS, hasGCP, hasAzure, cloudName, role }) {
    const {classes} = useStyles();
    const [maxWidth, setMaxWidth] = useState("xl");
    const [open, setOpen] = useState(false);
    const [azure, setAzure] = useState(false);
    const [aws, setAws] = useState(false);
    const [gcp, setGcp] = useState(false);
    const [loading, setLoading] = useState(false);
    const userData = useSelector((state) => ({
        id: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
    }));
    const handleClickOpen = (cloud) => {
        if (cloud == "azure") {
            setAzure(true);
        } else if (cloud == "aws") {
            setAws(true);
        } else {
            setGcp(true);
        }
        setOpen(true);
    };

    const handleClose = () => {
        setAzure(false);
        setAws(false);
        setGcp(false);
        setOpen(false);
    };

    return (
        <div className={'connection_buttons'}>
            <div style={{ minHeight: "94.5vh", background: "linear-gradient(to right, #ffffff 20%, #4b6cb7 100%)" }}>
                <span style={{ marginLeft: 16 }} className={classes.textStyle}>
                    Get started by connecting {cloudName} cloud {hasAzure && hasAWS && hasGCP ? "accounts" : "account"}
                </span>
                <div>
                    <div className={classes.cloudContainerStyle}>
                        {hasAzure && (
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Paper elevation={3} className={classes.cardStyle}>
                                    <img className={classes.cloudlogo} src={Azure_logo} height={60} />
                                    <span className={classes.cloudTextStyle}>Azure</span>
                                </Paper>
                                {/* <Link to="/dashboard/azure/add" style={{ pointerEvents: role == "Owner" || role == null ? "initial" : "none" }}> */}
                                    <Button onClick={deviceFlowApi} style={{ width: 180, marginTop: 8 }} color="primary" variant="contained" disabled={role == "Owner" || role == null ? false : true} className={'connection_buttons'}>
                                        Connect
                                    </Button>
                                {/* </Link> */}
                                <div>
                                    <OverlayTrigger placement="left" overlay={<Tooltip id="button-tooltip">Click to open help file</Tooltip>}>
                                        <a target="_blank" href="https://docs.cloudtrakr.com/SetupAzureConnection/">
                                            <HelpOutlineIcon />
                                        </a>
                                    </OverlayTrigger>

                                    {/* <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">How to Setup Azure Connection</Tooltip>}>
                                        <IconButton onClick={() => handleClickOpen("azure")} style={{ outline: "none" }}>
                                            <span style={{ fontSize: 18 }}>Play</span> <PlayCircleOutlineIcon />
                                        </IconButton>
                                    </OverlayTrigger> */}
                                </div>
                            </div>
                        )}
                        {hasAWS && (
                            <div style={{ alignItems: "center", display: "flex", flexDirection: "column", marginLeft: 16 }}>
                                <Paper elevation={3} className={classes.cardStyle}>
                                    <img className={classes.cloudlogo} src={AWS_LOGO} height={60} />
                                    <span className={classes.cloudTextStyle}>AWS</span>
                                </Paper>
                                <Link to={RoutesList.AWS_CONNECT} style={{ pointerEvents: role == "Owner" || role == null ? "initial" : "none" }} className={'connection_buttons'}>
                                    <Button style={{ marginTop: 8, width: 180 }} color="primary" variant="contained" disabled={role == "Owner" || role == null ? false : true} className={'connection_buttons'}>
                                        Connect
                                    </Button>
                                </Link>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <OverlayTrigger placement="left" overlay={<Tooltip id="button-tooltip">Click to open help file</Tooltip>}>
                                        <a target="_blank" href="https://docs.cloudtrakr.com/SetupAwsConnection/">
                                            <HelpOutlineIcon />
                                        </a>
                                    </OverlayTrigger>

                                    {/* <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">How to Setup AWS Connection</Tooltip>}>
                                        <IconButton onClick={() => handleClickOpen("aws")} style={{ outline: "none" }}>
                                            <span style={{ fontSize: 18 }}>Play</span> <PlayCircleOutlineIcon />
                                        </IconButton>
                                    </OverlayTrigger> */}
                                </div>
                            </div>
                        )}
                        {hasGCP && (
                            <div style={{ alignItems: "center", display: "flex", flexDirection: "column", marginLeft: 16 }}>
                                <Paper elevation={3} className={classes.cardStyle}>
                                    <img className={classes.cloudlogo} src={GCP_ICON} height={60} />
                                    <span className={classes.cloudTextStyle}>GCP</span>
                                </Paper>
                                <Link to={RoutesList.GCP_CONNECT} style={{ pointerEvents: role == "Owner" || role == null ? "initial" : "none" }} className={'connection_buttons'}>
                                    <Button style={{ marginTop: 8, width: 180 }} color="primary" variant="contained" disabled={role == "Owner" || role == null ? false : true} className={'connection_buttons'}>
                                        Connect
                                    </Button>
                                </Link>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <OverlayTrigger placement="left" overlay={<Tooltip id="button-tooltip">Click to open help file</Tooltip>}>
                                        <a target="_blank" href="https://docs.cloudtrakr.com/SetupGcpConnection/">
                                            <HelpOutlineIcon />
                                        </a>
                                    </OverlayTrigger>

                                    {/* <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">How to Setup GCP Connection</Tooltip>}>
                                        <IconButton onClick={() => handleClickOpen("gcp")} style={{ outline: "none" }}>
                                            <span style={{ fontSize: 18 }}>Play</span> <PlayCircleOutlineIcon />
                                        </IconButton>
                                    </OverlayTrigger> */}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={classes.cloudContainerStyle}>
                        {hasAzure && <ReactPlayer style={{ padding: 8 }} width={"60%"} height={"250px"} url={azureConnectionVideo} controls playing muted light={azureThumbnail} onClick={() => handleClickOpen("azure")} />}
                        {hasAWS && <ReactPlayer style={{ padding: 8 }} width={"60%"} height={"250px"} url={awsConnectionVideo} controls playing muted light={awsThumbnail} onClick={() => handleClickOpen("aws")} />}
                        {hasGCP && <ReactPlayer style={{ padding: 8 }} width={"60%"} height={"250px"} url={gcpConnectionVideo} controls playing muted light={gcpThumbnail} onClick={() => handleClickOpen("gcp")} />}
                    </div>
                </div>
            </div>
            <Paper>
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={maxWidth}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        {azure ? "Setup Azure Connection" : aws ? "Setup AWS Connection" : gcp ? "Setup GCP Connection" : null}
                    </DialogTitle>
                    <DialogContent dividers>
                        <ReactPlayer
                            url={azure ? azureConnectionVideo : aws ? awsConnectionVideo : gcpConnectionVideo}
                            controls
                            playing
                            config={{
                                file: {
                                    attributes: {
                                        autoPlay: true,
                                        muted: true,
                                    },
                                },
                            }}
                        />
                    </DialogContent>
                </Dialog>
            </Paper>
        </div>
    );

    function deviceFlowApi() {
        setLoading(true);
        axios
            .get(REACT_APP_MSAL_AZURE_CONNECTION, {
                params: {
                    customerID: userData.id,
                },
            })
            .then((response) => response.data)
            .then((responseJson) => {
                if ("response" in responseJson) {
                    window.open(responseJson.response.url, "_self");
                }

                return responseJson;
            })
            .finally(() => setLoading(false))
            .catch((error) => {
                console.error(error);
            });
    }
}
const useStyles = makeStyles()((theme) => ({
    containerStyle: {
        flexDirection: "row",
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
    },

    textStyle: {
        fontWeight: "bold",
        fontSize: 20,
    },
    cloudTextStyle: {
        fontSize: 26,
        fontWeight: "bold",
    },
    logoStyle: {
        width: 40,
        height: 40,
    },
    cloudlogo: {
        width: 80,
        height: 80,
    },

    cloudContainerStyle: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-around",
        marginTop: "2%",
    },
    cardStyle: {
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 30,
        paddingBottom: 30,
        backgroundColor: "#eeeeee",
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
    },
}));
