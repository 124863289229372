import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import {
  awsServerlessSuccess,
  awsServerlessFail,
  getAwsAccountSpendingSuccess,
  getAwsAccountSpendingFailure,
  getAwsSubSpendingSuccess,
  getAwsOrgSpendingSuccess,
  getAwsOrgSpendingFailure,
  getAwsSubSpendingFailure,
} from "../actions/actions";
const {
  REACT_APP_AWS_SERVERLESS,
  REACT_APP_AWS_ACCOUNT_SPENDING,
  REACT_APP_AWS_ALL_SPENDING,
  REACT_APP_AWS_ORG_SPENDING,

  REACT_APP_AWS_ACCOUNTS_DATA_HOME_LEVEL_SLICED,
  REACT_APP_AWS_SERVICES_DATA_HOME_LEVEL_SLICED,
  REACT_APP_AWS_RESOURCES_DATA_HOME_LEVEL_SLICED,

  REACT_APP_AWS_ACCOUNTS_DATA_SUB_LEVEL_SLICED,
  REACT_APP_AWS_SERVICES_DATA_SUB_LEVEL_SLICED,
  REACT_APP_AWS_RESOURCES_DATA_SUB_LEVEL_SLICED,
  REACT_APP_AWS_SUBSCRIPTION_KEY
} = process.env;

function* getAwsServerlessData({ payload }) {
  const { customerId1, tenantId, value } = payload;
  try {
    const res = yield call(axios.get, REACT_APP_AWS_SERVERLESS, {
      params: {
        customerId: customerId1,
        tenantId: tenantId,
        subscriptionId: value,
      },
    });

    const {
      data: { statusCode },
    } = res;
    if ("statusCode" in res.data) {
      if (statusCode !== 200) {
        yield put(awsServerlessFail(statusCode));
      } else {
        yield put(awsServerlessSuccess(res.data));
      }
    } else {
      yield put(awsServerlessFail(res.data));
    }
  } catch (error) {
    yield put(awsServerlessFail(error));
  }
}

function* getAwsAccountSpendingData({ payload }) {
  const { customerId, view, dataType } = payload;
  try {
    const res = yield call(axios.get,
        dataType == 'accounts'?
            REACT_APP_AWS_ACCOUNTS_DATA_HOME_LEVEL_SLICED:
            REACT_APP_AWS_RESOURCES_DATA_HOME_LEVEL_SLICED, {
      params: {
        customerId: customerId,
        view,
      },
          headers: {
            "Ocp-Apim-Subscription-Key": REACT_APP_AWS_SUBSCRIPTION_KEY,
          },
    });

    const {
      data: { statusCode },
    } = res;
    if ("statusCode" in res.data) {
      if (statusCode !== 200) {
        yield put(getAwsAccountSpendingFailure(statusCode));
      } else {
        yield put(getAwsAccountSpendingSuccess({data: res.data, view, dataType}));
      }
    } else {
      yield put(getAwsAccountSpendingFailure(res.data));
    }
  } catch (error) {
    yield put(getAwsAccountSpendingFailure(error));
  }
}

function* getAwsSubSpendingData({ payload }) {
  //console.log("id == ", payload);
  const { customerId, tenantId, value, view, dataType } = payload;
  try {
    const res = yield call(axios.get,
        dataType == 'accounts'?
            REACT_APP_AWS_ACCOUNTS_DATA_SUB_LEVEL_SLICED:
            REACT_APP_AWS_RESOURCES_DATA_SUB_LEVEL_SLICED,
        {
      params: {
        customerId: customerId,
        tenantId: tenantId,
        subscriptionId: value,
        view: view,
      },
          headers: {
            "Ocp-Apim-Subscription-Key": REACT_APP_AWS_SUBSCRIPTION_KEY,
          },
    });

    const {
      data: { statusCode },
    } = res;
    if ("statusCode" in res.data) {
      if (statusCode !== 200) {
        yield put(getAwsSubSpendingFailure(statusCode));
      } else {
        yield put(getAwsSubSpendingSuccess({data: res.data, view, dataType}));
      }
    } else {
      yield put(getAwsSubSpendingFailure(res.data)); //
    }
  } catch (error) {
    yield put(getAwsSubSpendingFailure(error)); // handle
  }
}

function* getAwsOrgSpendingData({ payload }) {
  const { customerId, tenantId, view } = payload;
  try {
    const res =
      view != "organizations"
        ? yield call(axios.get, REACT_APP_AWS_ORG_SPENDING, {
            params: {
              customerId: customerId,
              tenantId,
              view: view,
            },
            headers: {
              "Ocp-Apim-Subscription-Key": REACT_APP_AWS_SUBSCRIPTION_KEY,
            },
          })
        : yield call(axios.get, REACT_APP_AWS_ORG_SPENDING, {
            params: {
              customerId: customerId,
              //tenantId: "817626142201",
              view: view,
            },
            headers: {
              "Ocp-Apim-Subscription-Key": REACT_APP_AWS_SUBSCRIPTION_KEY,
            },
          });

    const {
      data: { statusCode },
    } = res;
    if ("statusCode" in res.data) {
      if (statusCode !== 200) {
        yield put(getAwsOrgSpendingFailure(statusCode));
      } else {
        yield put(getAwsOrgSpendingSuccess(res.data));
      }
    } else {
      yield put(getAwsOrgSpendingFailure(res.data));
    }
  } catch (error) {
    yield put(getAwsOrgSpendingFailure(error));
  }
}

export default function* awsServerlessSaga() {
  yield takeLatest(types.AWS_SERVERLESS_REQUEST, getAwsServerlessData);
  yield takeLatest(
    types.GET_AWS_ACCOUNT_SPENDING_REQUEST,
    getAwsAccountSpendingData
  );
  yield takeLatest(types.GET_AWS_SUB_SPENDING_REQUEST, getAwsSubSpendingData);
  yield takeLatest(types.GET_AWS_ORG_SPENDING_REQUEST, getAwsOrgSpendingData);
}
