import React, {useEffect} from "react";
import {Routes as Switch, Route, redirect, Navigate, Outlet} from "react-router-dom";
import Budgets from "../pages/Budgets/Budgets";
import GetStarted from "../pages/GetStarted";
import LinearProgress from '../components/LineProgress';
import Dashboard from "../pages/Dashboard";
import AddAzureAccount from "../pages/Accounts/AddAzureAccount";
// import AddGcpAccount from "../pages/Accounts/AddGcpAccount";
import ConnectGCPAccount from "../pages/Accounts/GCP/ConnectGCPAccount";
import LinkAccounts from "../pages/Accounts/NewLinkAccount";
import ConnectAWSAccount from "../pages/Accounts/aws/ConnectAWSAccount";
import ListAWSAccount from "../pages/Accounts/aws/ListAWSAccount";
import DashboardChart from "../pages/Charts/DashboardChart";
import Settings from "../pages/Settings/Settings";
import AzureHome from "../pages/DashboardAccountsHome/AzureHome";
import GcpHome from "pages/DashboardAccountsHome/GcpHome";
import ListGCPAccount from "pages/Accounts/GCP/ListGCPAccount";
import RoutesList from "./RoutesList";
import ConenctAccountSkipPage from "../pages/ConnectAccountSkipPage";
import isUserAgentPhone from "../util/MobileCheck";
import CostUnify from "../images/app_logo.png";
import Google_Store from "../images/google_store.png";
import Apple_Store from "../images/apple_store.svg";
import Reports from "../pages/reports/Reports";
import Invoices from "pages/invoice/Invoices";
import {useDispatch, useSelector} from "react-redux";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import * as types from "../redux/actions/types";
import Axios from "../connection/axios";
import AWSHome from "../pages/DashboardAccountsHome/AWSHome";
import NotFound404 from "./NotFound404";
// import RecommendationsMain from "../pages/RecommendationsMain";
export default function Routes() {

    // if (isUserAgentPhone()) {
    //     return (
    //         <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
    //             <div
    //                 style={{
    //                     backgroundColor: "#098bed4a",
    //                     display: "flex",
    //                     paddingTop: 50,
    //                     paddingBottom: 50,
    //                     flexDirection: "column",
    //                     alignItems: "center",
    //                     justifyContent: "center",
    //                 }}
    //             >
    //                 {/*<div>*/}
    //                 <div
    //                     style={{
    //                         display: "flex",
    //                         justifyContent: "center",
    //                         alignItems: "flex-start",
    //                     }}
    //                 >
    //                     <img src={CloudTrakr} height={30} style={{ marginTop: 4, marginRight: 5 }} />
    //                     <h2>CloudTrakr</h2>
    //                 </div>
    //                 <div
    //                     style={{
    //                         display: "flex",
    //                         justifyContent: "center",
    //                         alignItems: "center",
    //                     }}
    //                 >
    //                     {/*<div style={{border: '1px solid'}}>*/}
    //                     <h6 style={{ padding: 0, margin: 0 }}>from</h6>
    //                     {/*</div>*/}
    //                     <img src={CloudTrakr} height={25} style={{ marginLeft: 2 }} />
    //                 </div>
    //                 {/*</div>*/}
    //             </div>
    //             <div style={{ padding: 20, textAlign: "center" }}>
    //                 <span style={{ textAlign: "center" }}>For better experience on mobile please use our app available on these platforms</span>
    //             </div>
    //             <div
    //                 style={{
    //                     display: "flex",
    //                     alignItems: "center",
    //                     justifyContent: "center",
    //                     flexDirection: "column",
    //                 }}
    //             >
    //                 <span style={{ fontWeight: "bold" }}>For Android</span>
    //                 <a href="https://play.google.com/store/apps/details?id=com.cloudcostmanagementapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
    //                     <img src={Google_Store} height={100} />
    //                 </a>
    //             </div>
    //             <div
    //                 style={{
    //                     display: "flex",
    //                     alignItems: "center",
    //                     justifyContent: "center",
    //                     flexDirection: "column",
    //                 }}
    //             >
    //                 <span style={{ marginBottom: 10, fontWeight: "bold" }}>For iOS</span>
    //                 <a href="https://apps.apple.com/app/id1519053095">
    //                     <img src={Apple_Store} height={75} />
    //                 </a>
    //             </div>
    //         </div>
    //     );
    // }


        // return (
        //     <Switch>
        //             <MyRoute path={"/"} element={GetStarted} />
        //             <MyRoute path={"/app"} element={Dashboard} />
        //     </Switch>
        // )
    return (
        <Switch>
            {/*<Route exact element={<RootLoading />}>*/}
                <Route path={RoutesList.ROOT} exact element={<GetStarted />} >
                    <Route path={RoutesList.NOT_FOUND} element={<NotFound404 />} />
                </Route>
                <Route element={<ProtectedRoutes />}>
                    <Route path={RoutesList.APP} element={<Dashboard />} >
                        <Route
                            path={RoutesList.APP + "/"}
                            element={<Navigate to={RoutesList.ALL} replace />}
                        />
                        <Route path={RoutesList.ALL} element={<DashboardChart />}  />
                        <Route path={RoutesList.AZURE_HOME} element={<AzureHome />}  />
                        <Route path={RoutesList.AWS_HOME} element={<AWSHome />} />
                        <Route path={RoutesList.GCP_HOME} element={<GcpHome />} />
                        <Route path={RoutesList.BUDGETS} element={<Budgets />} />
                        <Route path={RoutesList.REPORTS} element={<Reports />} />
                        <Route path={RoutesList.SETTINGS} element={<Settings />}  />
                        <Route path={RoutesList.ACCOUNTS} element={<LinkAccounts />}  />
                        <Route path={RoutesList.AWS_CONNECT} element={<ConnectAWSAccount />}  />
                        <Route path={RoutesList.GCP_CONNECT} element={<ConnectGCPAccount />}  />

                        {/*<Route path="/dashboard/azure/add" element={<AddAzureAccount />}  />*/}
                        {/*<Route path="/dashboard/aws/list" element={<ListAWSAccount />}  />*/}
                        {/*<Route path={RoutesList.GCP_LIST} element={<ListGCPAccount />}  />*/}
                        {/*<Route path="/dashboard/invoice" element={<Invoices />}  />*/}
                    </Route>
                </Route>
            {/*</Route>*/}
        </Switch>
    );
    {/*<Route path="/register" element={<GetStarted />} />*/}
}

function RootLoading({}){
    const dispatch = useDispatch();
    const {inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const customerId = useSelector((state) => state.userDetailsReducer.id);
    const teamId = localStorage.getItem("team");
    const role = localStorage.getItem("role");

    // const handleSwitchTeam = (data) => {
    //     const live = data.invitedTeam.find((item) => item.customerId === teamId);
    //     if (live != undefined) {
    //         dispatch({ type: types.SWITCH_TEAM, payload: { id: teamId, role: role } });
    //     } else {
    //         dispatch({ type: types.SWITCH_TEAM, payload: { id: null, role: null } });
    //     }
    // };

    // useEffect(async () => {
    //     if (teamId != null && role != null) {
    //         if (customerId) {
    //             const addedTeams = await Axios.get("REACT_APP_GET_INVITED_TEAMS", {
    //                 params: { customerId },
    //             });
    //             if (addedTeams && addedTeams.data.statusCode == 200) {
    //                 let val = addedTeams.data.data;
    //                 handleSwitchTeam(val);
    //             }
    //         }
    //     }
    // }, [customerId, teamId, role]);

    if (inProgress === "none") {
        if (isAuthenticated){
            return <Navigate replace to={RoutesList.APP} />;
        } else {
            return <Outlet />
        }
    }
}

function ProtectedRoutes({element: Element, ...rest}){
    const dispatch = useDispatch();
    const {inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const customerId = useSelector((state) => state.userDetailsReducer.id);
    const teamId = localStorage.getItem("team");
    const role = localStorage.getItem("role");

    // const handleSwitchTeam = (data) => {
    //     const live = data.invitedTeam.find((item) => item.customerId === teamId);
    //     if (live != undefined) {
    //         dispatch({ type: types.SWITCH_TEAM, payload: { id: teamId, role: role } });
    //     } else {
    //         dispatch({ type: types.SWITCH_TEAM, payload: { id: null, role: null } });
    //     }
    // };

    // useEffect(async () => {
    //     if (teamId != null && role != null) {
    //         if (customerId) {
    //             const addedTeams = await Axios.get("REACT_APP_GET_INVITED_TEAMS", {
    //                 params: { customerId },
    //             });
    //             if (addedTeams && addedTeams.data.statusCode == 200) {
    //                 let val = addedTeams.data.data;
    //                 handleSwitchTeam(val);
    //             }
    //         }
    //     }
    // }, [customerId, teamId, role]);

    if (inProgress === "none") {
        if (!isAuthenticated){
            return <Navigate replace to="/" />;
        } else {
            return <Outlet />
        }
        // if (!isAuthenticated) {
        //     // redirect('/app');
        //     return <Navigate to="/dashboard/charts" replace />;
        // }
    }
}
