import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { RecTotal, RecTotalText } from "./style";

function RecommendBar({ high, medium, low, IR }) {
  const [bar, setBar] = useState({
    highWidh: null,
    mediumWidth: null,
    lowWidth: null,
  });

  function CalculateBar(h, m, l) {
    let sum = h + m + l;

    setBar({
      highWidh: (h / sum) * 220,
      mediumWidth: (m / sum) * 220,
      lowWidth: (l / sum) * 220,
    });
  }
  useEffect(() => {
    CalculateBar(high, medium, low);
  }, [high, medium, low]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: 220,
          }}
        >
          <div
            style={{
              display: "flex",
              width: 220,
              height: 8,
              marginTop: 5,
            }}
          >
            <div style={{ width: bar.highWidh, background: "#C85250" }} />
            <div style={{ width: bar.mediumWidth, background: "#FAD02C" }} />
            <div style={{ width: bar.lowWidth, background: "#41729F" }} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            <div style={{}}>
              <h6 style={{ color: "#C85250", fontSize: 12 }}>High</h6>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <b>{high}</b>
              </div>
            </div>

            <div style={{}}>
              <h6 style={{ color: "#FAD02C", fontSize: 12 }}>Medium</h6>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <b>{medium}</b>
              </div>
            </div>

            <div style={{}}>
              <h6 style={{ color: "#41729F", fontSize: 12 }}>Low</h6>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <b>{low}</b>
              </div>
            </div>
          </div>

          <Divider />
        </div>
      </div>
      <RecTotal IR>
        <h6>{IR}</h6>
        <RecTotalText>
          <b>Impacted resources</b>
        </RecTotalText>
      </RecTotal>
    </div>
  );
}

export default RecommendBar;
