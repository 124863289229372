import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import {
  getAWSReservationSuccess,
  getAWSReservationFailure,
  getAWSAccountReservationSuccess,
  getAWSAccountReservationFailure,
} from "../actions/actions";
const {
  REACT_APP_AWS_RESERVATION,
  REACT_APP_AWS_ACCOUNT_RESERVATION,
  REACT_APP_AWS_SUBSCRIPTION_KEY
} = process.env;

function* getAwsReservationData({ payload }) {
  try {
    const res = yield call(axios.get, REACT_APP_AWS_RESERVATION, {
      params: {
        customerId: payload.customerId,
      },
      headers: {
        "Ocp-Apim-Subscription-Key": REACT_APP_AWS_SUBSCRIPTION_KEY,
      },
    });

    const { statusCode } = res.data;

    if ("statusCode" in res.data) {
      if (statusCode !== 200) {
        yield put(getAWSReservationFailure(statusCode));
      } else {
        yield put(getAWSReservationSuccess(res.data));
      }
    } else {
      yield put(getAWSReservationFailure(res));
    }
  } catch (error) {
    yield put(getAWSReservationFailure(error));
  }
}

function* getAwsAccountReservationData({ payload }) {
  try {
    const res = yield call(axios.get, REACT_APP_AWS_ACCOUNT_RESERVATION, {
      params: {
        customerId: payload.customerId,
        tenantId: payload.tenantId,
        subscriptionId: payload.subscriptionId,
      },
      headers: {
        "Ocp-Apim-Subscription-Key": REACT_APP_AWS_SUBSCRIPTION_KEY,
      },
    });

    const { statusCode } = res.data;

    if ("statusCode" in res.data) {
      if (statusCode !== 200) {
        yield put(getAWSAccountReservationFailure(statusCode));
      } else {
        yield put(getAWSAccountReservationSuccess(res.data));
      }
    } else {
      yield put(getAWSAccountReservationFailure(res));
    }
  } catch (error) {
    yield put(getAWSAccountReservationFailure(error));
  }
}

export default function* awsReservationSaga() {
  yield takeLatest(types.GET_AWS_RESERVATION_REQUEST, getAwsReservationData);
  yield takeLatest(
    types.GET_AWS_ACCOUNT_RESERVATION_REQUEST,
    getAwsAccountReservationData
  );
}
