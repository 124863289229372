import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import GetWindowDimension from "../HOC/GetWindowDimension";
import { tooltipVB } from "./ReactDrillDownd3BarChart";
import Format from "../NumberFormat";
import {select} from "d3";
import {convertToInternationalCurrencySystem, isNumeric} from "../../util/Miscellaneous";

// import "../DrillDownD3Chart/style.css";

function GroupedBarChart(props) {
    const { title, totalCost, currency, data, width, height, cloudCount, largeFont = false } = props;
    const svgRef = useRef();
    var colorScale = d3
        .scaleOrdinal()
        .range([
            "#31BEF3",
            "#19A979",
            "#FBD63D",
            "#08619D",
            "#E8743B",
            "steelblue",
        ]);
    const margin = { top: 40, right: 90, bottom: 40, left: 70 };

    const chartWidth = (width*.9) - margin.left - margin.right;
    const chartHeight = height / 2 - margin.top - margin.bottom;

    function groupedBarChart(svg) {

        svg.selectAll("g").remove();
        svg.selectAll("text").remove();

        var dataLabelNames = data.map(function (d) {
            return d.dataLabel;
        });
        var legendNames = data[0].values.map(function (d) {
            return d.legend;
        });

        var x0 = d3
            .scaleBand()
            .domain(dataLabelNames)
            .rangeRound([0, chartWidth])
            .paddingInner(0.1);

        var x1 = d3
            .scaleBand()
            .domain(legendNames)
            .rangeRound([0, x0.bandwidth()])
            .padding(0.05);

        var y = d3
            .scaleLinear()
            .domain([
                0,
                d3.max(data, function (dataLabel) {
                    return d3.max(dataLabel.values, function (d) {
                        if(d.value=="-"){
                            return 0;
                        } else {
                            return Number(d.value);
                        }
                    });
                })*1.3,
            ])
            .rangeRound([chartHeight, 0]);

        const chart = svg
            .append("g")
            .attr("width", chartWidth)
            .attr("height", chartHeight)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        chart
            .append("g")
            .attr("transform", `translate(0, ${chartHeight})`)
            .call(d3.axisBottom(x0).tickSizeOuter(0));

        chart.append("g").call(
            d3
                .axisLeft(y)
                .ticks(4)
                .tickFormat((d, i) => currency + Format.formatNumberInt(d))
        );

        chart
            .select(".y")
            .transition()
            .duration(500)
            .delay(1300)
            .style("opacity", "1");

        // var tooltip = d3.select("body").append("div").attr("class", "toolTip");

        var slice = chart
            .selectAll()
            .data(data)
            .enter()
            .append("g")
            .attr("class", "g")
            .attr("transform", function (d) {
                return "translate(" + x0(d.dataLabel) + ",0)";
            });

        slice
            .selectAll("rect")
            .data(function (d) {
                return d.values;
            })
            .enter()
            .append("rect")
            .attr("width", x1.bandwidth())
            .attr("x", function (d) {
                return x1(d.legend);
            })
            .style("fill", function (d) {
                return colorScale(d.legend);
            })
            .attr("y", function (d) {
                return y(0);
            })
            .attr("height", function (d) {
                return chartHeight - y(0);
            })
            .on("mousemove", function (d, i) {
                d3.select(this).style("fill", d3.rgb(colorScale(i.legend)).darker(2));
                tooltipVB
                    .style("left", d.pageX - 50 + "px")
                    .style("top", d.pageY - 70 + "px")
                    .style("display", "inline-block")
                    .html(i.legend + "<br>" + currency + Format.formatNumber(i.value));
            })
            .on("mouseout", function (d, i) {
                d3.select(this).style("fill", colorScale(i.legend));
                tooltipVB.style("display", "none");
            });

        slice
            .selectAll("rect")
            .transition()
            .delay(function (d) {
                return Math.random() * 1000;
            })
            .duration(1000)
            .attr("y", function (d) {
                return y(d.value);
            })
            .attr("height", function (d) {
                if(isNumeric(String(d.value))){
                    return chartHeight - y(d.value);
                } else {
                    return chartHeight - y(0);
                }
            });

        slice
            .selectAll()
            .data(function (d) {
                return d.values;
            })
            .enter()
            .append("text")
            .attr("class", "val") // add class to text label
            .attr("text-anchor", "middle")
            .attr("x", function (d, i) {
                return x1(d.legend) + x1.bandwidth() / 2;
            })
            .attr("dx", 0)
            .attr("y", function (d, i) {
                return y(d.value);
            })
            .attr("dy", (d) => (d.value < y(d.value) ? -2 :largeFont == true ? -2: -5))
            // .style("font-Size", (d) => (d.value > 10000 ? "10px" : x1.bandwidth()*.25 + "px"))
            // .style("transform", "rotateZ(-45deg)")
            .style("font-Size", (d) => {
                if(cloudCount == 1){
                    return "11px";
                } else if(cloudCount == 2) {
                    return largeFont == true ?"12px":"14px";
                } else {
                    return largeFont == true ? "12px" : "14px";
                }
            })
            .text((d) => d.value==="-"?null:(currency +
                ((parseFloat(d.value) > 99999)?
                    convertToInternationalCurrencySystem(parseInt(d.value)):
                    Format.formatNumberInt(parseFloat(d.value)) || null)))

        //------------------------ legend --------------------------------
        var legend = chart
            .append("g")
            .attr("class", "legend")
            .selectAll(".legend")
            .data(
                data[0].values.map(function (d) {
                    return d.legend;
                })
            )
            .enter()
            .append("g")
            .attr("transform", function (d, i) {
                return "translate(0," + i * 20 + ")";
            })
            .style("opacity", "0");

        legend
            .append("rect")
            .attr("x", chartWidth)
            .attr("y", -8)
            .attr("width", 18)
            .attr("height", 18)
            .style("fill", function (d) {
                return colorScale(d);
            });

        legend
            .append("text")
            .attr("x", chartWidth + margin.left / 2)
            .attr("dy", ".35em")
            .style("text-anchor", "start")
            .text(function (d) {
                return d;
            });

        legend
            .transition()
            .duration(500)
            .delay(function (d, i) {
                return 1300 + 100 * i;
            })
            .style("opacity", "1");

        svg
            .append("text")
            .attr("class", "title")
            .attr("x", 2.5 * margin.left)
            .attr("y", margin.top / 2)
            .attr("text-anchor", "middle")
            .style("font-Family", "Poppins_SemiBold")
            .text(title);

        // svg
        //     .append("text")
        //     .attr("class", "totalCost")
        //     .attr("x", chartWidth / 2 + 2 * margin.top)
        //     .attr("y", 25)
        //     .attr("text-anchor", "middle")
        //     .style("font-Family", "Poppins_SemiBold")
        //     .text(currency + totalCost);
    }
    function drawNoData(){
        select(svgRef.current).select("g").remove();
        select(svgRef.current).append("g")
            .attr("transform", `translate(${(width - margin.right -margin.left)/ 2}, ${height / 4})`)
            .style("display", "flex")
            .style("align-items", "center")
            .style("justify-content", "center")
            .append('text').text("No data found")
    }

    useEffect(() => {
        const svg = d3.select(svgRef.current);
        if(data && data.length) groupedBarChart(svg);
        else drawNoData();
    }, [data, width, height]);
    return <svg ref={svgRef} width={'100%'} height={height / 2} />;
}

export default GetWindowDimension(GroupedBarChart);
