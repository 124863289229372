import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "MakeWithStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/Icon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Format from "components/NumberFormat";
import moment from "moment";
import Color from "../../styles/color";
import { LinearProgress } from "@mui/material";
import Cost from "../../components/Cost";
import gcp_billing from "../../images/gcp_billing.svg";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import DateRange from "../../components/DateRange";
import gcp_project from "../../images/gcp_project.svg";
import resource_group from "../../images/resource_group.svg";
import ContainerCardWithDateRange from "../../components/ContainerCardWithDateRange";
import TablePaginationActions from "../../components/TablePaginationActions";

const useStyles = makeStyles()((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
}));

export default function GCPProjectTabProject({ currency, billingAccount, loading }) {
    const dispatch = useDispatch();
    const {classes} = useStyles();
    const [progress, setProgress] = useState(0);
    const [selected, setSelected] = React.useState([]);
    const [list, setList] = useState(null);
    const [total, setTotal] = useState("");
    const [period, setPeriod] = useState("1M");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const userData = useSelector((state) => ({
        id: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
    }));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    console.log("projecta", billingAccount);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return loading ? (
        <LinearProgress variant="determinate" value={progress} />
    ) : (
        <div>
            <div className={classes.root}>
                <ContainerCardWithDateRange title={'GCP Project'} defaultPeriod={"1M"} titleIcon={gcp_project}
                                            ExtraHeaderComponent={({period})=>(
                                                billingAccount != null && billingAccount?.SpendByProject?.[period]?.dataSet?.length ? (
                                                    <div style={{ marginTop: 4 }}>
                                                        <span>{moment(billingAccount?.SpendByProject?.[period]?.startDate, "YYYYMMDD").format("MMM DD, YYYY")}</span> -{" "}
                                                        <span>{moment(billingAccount?.SpendByProject?.[period]?.endDate, "YYYYMMDD").format("MMM DD, YYYY")}</span>
                                                    </div>
                                                ) : null
                                            )} collapsible={false}
                                            MiddleComponent={({period})=>(
                                                <span style={{display: 'flex', justifyContent: "flex-end"}}>Total: {currency}
                                                    {billingAccount?.SpendByProject?.[period]?.totalCost?Format.formatNumber(billingAccount?.SpendByProject?.[period]?.totalCost):"__.__"}
                                                </span>
                                            )}
                >
                    {({period})=>(
                        billingAccount != null && billingAccount?.SpendByProject?.[period]?.dataSet?.length ? (
                            <Paper className={classes.paper}>
                                <TableContainer>
                                    <Table className={classes.table} aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                        <GCPBillingHead classes={classes} numSelected={selected.length} rowCount={billingAccount.length} currency={currency} />
                                        <TableBody>
                                            {(billingAccount?.SpendByProject?.[period]?.dataSet).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                return <MyTableRow row={row} index={index} list={list} setList={setList} currency={currency} />;
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    ActionsComponent={TablePaginationActions}
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={billingAccount?.SpendByProject?.[period]?.dataSet?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                            </Paper>
                        ) : (
                            <span style={{ display: "flex", justifyContent: "center", fontSize: 20 }}>No data found</span>
                        )
                    )}
                </ContainerCardWithDateRange>
            </div>
        </div>
    );
}

const useStyles22 = makeStyles()((theme) => ({
    root: {
        backgroundColor: Color.tableHeader,
        padding: theme.spacing(0.5),
    },

    tableRow: {
        fontWeight: "bold",
    },
}));

function GCPBillingHead({ totalCost, currency = "" }) {

    const {classes} = useStyles22();

    return (
        <TableHead className={classes.root}>
            <TableRow>
                <TableCell className={classes.tableRow}>Project Id</TableCell> <TableCell className={classes.tableRow}>Billing account Id</TableCell> <TableCell className={classes.tableRow}>Billing account name</TableCell>
                <TableCell className={classes.tableRow}> Organization Id</TableCell>
                <TableCell className={classes.tableRow}> Organization name</TableCell>
                <TableCell className={classes.tableRow}>
                    Cost
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

function MyTableRow({ row, currency, orgData }) {
    return (
        <React.Fragment>
            <TableRow hover key={row.date}>
                <TableCell>
                    {" "}
                    <img src={gcp_project} width={18} height={18} style={{ marginRight: 5 }} />
                    {row.projectId}
                </TableCell>
                <TableCell> {row?.billingAccountId}</TableCell>

                <TableCell>{row?.billingAccountName}</TableCell>
                <TableCell>{row?.organizationId}</TableCell>
                <TableCell>{row?.organizationName}</TableCell>

                <TableCell>
                    {currency}
                    {Format.formatNumber(Number(row.value))}
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
