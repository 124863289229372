import * as types from "../actions/types";

const userState = {
    cosmosDBData: null,
    cosmosDBDetailsData: null,
    loading: false,
    metricsLoding: false,
    chartLoading: false,
    cosmosDBChartData: null,
    error: null,
};

function azureCosmosDBMetricsReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.AZURE_COSMOSDB_METRICS_REQUEST: {
            return {
                ...state,
                metricsLoding: true,
            };
        }
        case types.AZURE_COSMOSDB_METRICS_SUCCESS: {
            return {
                ...state,
                cosmosDBData: payload.data,
                metricsLoding: false,
            };
        }
        case types.AZURE_COSMOSDB_METRICS_FAILURE: {
            return {
                ...state,
                metricsLoding: false,
                cosmosDBData: null,
                error: payload,
            };
        }

        // ----------------------Azure CosmosDB Details--------------------------

        case types.AZURE_COSMOSDB_DETAILS_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AZURE_COSMOSDB_DETAILS_SUCCESS: {
            return {
                ...state,
                cosmosDBDetailsData: payload.data,
                loading: false,
            };
        }
        case types.AZURE_COSMOSDB_DETAILS_FAILURE: {
            return {
                ...state,
                loading: false,
                cosmosDBDetailsData: null,
                error: payload,
            };
        }

        //-----------------------Azure CosmosDB Charts------------

        case types.AZURE_COSMOSDB_CHARTDATA_REQUEST: {
            return {
                ...state,
                chartLoading: true,
            };
        }
        case types.AZURE_COSMOSDB_CHARTDATA_SUCCESS: {
            return {
                ...state,
                cosmosDBChartData: payload.data,
                chartLoading: false,
            };
        }
        case types.AZURE_COSMOSDB_CHARTDATA_FAILURE: {
            return {
                ...state,
                chartLoading: false,
                cosmosDBChartData: null,
                error: payload,
            };
        }
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                cosmosDBData: null,
                cosmosDBDetailsData: null,
                loading: false,
                metricsLoding: false,
                chartLoading: false,
                cosmosDBChartData: null,
                error: null,
            };
        }

        default:
            return state;
    }
}
export default azureCosmosDBMetricsReducer;
