import React from 'react';
import {TableSortLabel, TableCell, TextField} from "@mui/material";
import {TABLE_SORT} from "../util/AppConstants";

const TableSearch = (props) => {
    const { RightSideComponent = ()=>null, containerStyle = {},filterWidth = false } = props;
    function onEnter(e){
        if(e.currentTarget.value?.trim()?.length>2)
            props?.onSearch(e.currentTarget.value?.trim());
        else
            props?.onSearch(null);
    }

    return (
        <div style={{padding: 10, ...containerStyle}}>
            <TextField onChange={onEnter} style={{ width: filterWidth ? '180%' : '30%' }}
                       inputProps={{ maxLength: 25 }} fullWidth={true} size={'small'} id="outlined-basic" label="Filter" variant="outlined" />
            {RightSideComponent()}
        </div>
    )
};

TableSearch.doesItIncludes = (list, term)=>{
    let found = false;
    list.every(l=>{
        found = (l || null)?.toLowerCase()?.includes(term?.toLowerCase());
        return !found
    })
    return found;
}

export default TableSearch;
