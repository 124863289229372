import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import {
  getReportNameSuccess,
  getReportNameFailure,
  getReportArraySuccess,
  getReportArrayFailure,
} from "../actions/actions";
const {
  REACT_APP_REPORTS_PDF_NAME,
  REACT_APP_REPORTS_BUFFER_ARRAY,
} = process.env;

function* getReportsNameData({ payload }) {
  // console.log("pay pay == ", payload);
  try {
    const res = yield call(axios.get, REACT_APP_REPORTS_PDF_NAME, {
      params: { customerId: payload },
    });

    const {
      data: { statusCode },
    } = res;

    if ("statusCode" in res.data) {
      if (statusCode != 200) {
        yield put(getReportNameFailure(statusCode));
      } else {
        yield put(getReportNameSuccess(res.data));
      }
    } else {
      yield put(getReportNameFailure(res.data));
    }
  } catch (error) {
    yield put(getReportNameFailure(error));
  }
}
function* getReportsArrayData({ payload }) {
  // console.log("payload payoad == ", payload);
  try {
    const res = yield call(axios.get, REACT_APP_REPORTS_BUFFER_ARRAY, {
      params: {
        customerId: payload.customerId,
        type: payload.type,
        fileName: payload.fileName,
      },
    });

    const {
      data: { statusCode },
    } = res;
    if ("statusCode" in res.data) {
      if (statusCode != 200) {
        yield put(getReportArrayFailure(statusCode));
      } else {
        yield put(getReportArraySuccess(res.data));
      }
    } else {
      yield put(getReportArrayFailure(res.data));
    }
  } catch (error) {
    yield put(getReportArrayFailure(error));
  }
}

export default function* reportsSaga() {
  yield takeLatest(types.GET_REPORTS_NAME_REQUEST, getReportsNameData);
  yield takeLatest(types.GET_REPORTS_ARRAY_REQUEST, getReportsArrayData);
}
