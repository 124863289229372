import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "MakeWithStyles";
import { Table, TableBody, TableCell as TCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Divider, LinearProgress } from "@mui/material";
import storage_account_icon from "../../images/resources/Storage_Account.svg";
import function_apps_icon from "../../images/function-apps.png";
import allCloudFunctions_icon from "../../images/allCloudFunctions.png";
import lambdaFunctionIcon from "../../images/lambdaFucntion.png";
import Collapse from "@mui/material/Collapse";
import Cost from "../../components/Cost";
import { ALPHA_NUM_SORTER, bytesToSize, convertToInternationalCurrencySystem } from "../../util/Miscellaneous";
import axios from "../../connection/axios";
import { CancelRounded } from "@mui/icons-material";
import { getAllFunctionsRequest } from "../../redux/actions/actions";
import SlideShow from "../../components/CustomSlider/SlideShow";
import ReactD3BarChart from "../../components/D3Charts/ReactD3BarChart";
import { commonConversion } from "../../util/DateRangeFormatter";
import Format from "components/NumberFormat";
import LineChart from "pages/Charts/LineChart";
import TablePagination from "@mui/material/TablePagination";
import { lambdaCommonConversion } from "../../util/LambdaFunctionFormatter";
import LinkText from "components/LinkText";
import CarousalCard from "components/CarousalCard";
import moment from "moment";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import { Tooltip, OverlayTrigger, Popover } from "react-bootstrap";
import colors from "styles/color";

import IconButton from "@mui/material/Icon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import AzureLogo from "../../images/Azure_logo.svg";
import AwsLogo from "../../images/aws_dark.svg";
import TablePaginationActions from "../../components/TablePaginationActions";
import { TABLE_SORT } from "../../util/AppConstants";
import TableSortHeader from "../../components/TableSortHeader";
import TableSearch from "../../components/TableSearch";
const { REACT_APP_GET_AZURE_FUNCTIONS, REACT_APP_GET_AZURE_FUNCTION_DETAILS, REACT_APP_GET_AZURE_FUNCTIONS_APP_INSIGHTS_METRICS, REACT_APP_AWS_LAMBDA_METRICS, REACT_APP_AWS_LAMBDA_DETAILS } = process.env;

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        //marginBottom: 5,
    },
}));

function TableCell(props) {
    return (
        <TCell {...props} style={{ padding: 0,paddingRight:8 }}>
            {props.children}
        </TCell>
    );
}

const TABLE_SORT_DEFAULT_COLUMN = "cost";

export default function AllCloudFunctions({ currency, curr = "USD", tab, progress, customerId }) {
    const dispatch = useDispatch();
    const {classes} = useStyles();
    const [data, setData] = useState(null);
    const [list, setList] = useState(null);

    // const [loading, setLoading] = useState(true);
    // const customerId = useSelector((state) => state.userDetailsReducer.id);
    // const loading = useSelector(
    //   (state) => state.azureRecommendationReducer.loading
    // );
    const [showingFunction, setShowingFunction] = useState(null);
    const [showingMetrics, setShowingMetrics] = useState(null);
    const [metricsData, setMetricsData] = useState([]);
    const [chart, setChart] = useState([]);
    const [meterChart, setMeterChart] = useState([]);
    const [functionsList, setFunctionsList] = useState([]);
    const [showMetricSpecific, setShowMetricSpecific] = useState([]);
    const [metricTotals, setMetricTotals] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [lambdaData, setLambdaData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [totalCost, setTotalCost] = useState("");

    const [cosmosDBLoader, setCosmosDBLoader] = useState(false);
    const [metricsLoding, setMetricsLoding] = useState(false);

    let allArray = useSelector((state) => state?.allFunctionsReducer?.allFunctionsData);

    let loading = useSelector((state) => state?.allFunctionsReducer?.loading);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (allArray == null) {
            dispatch(
                getAllFunctionsRequest({
                    customerId,
                })
            );
        }
    }, [customerId]);

    // useEffect(() => {
    //     if (awsLambda != null) {
    //         let allArray = [];

    //         if (awsLambda?.length > 0) {
    //             awsLambda &&
    //                 awsLambda?.map((d) => {
    //                     d.subscriptions?.map((s) => {
    //                         allArray.push(...s.billing);
    //                     });
    //                 });
    //         }

    //         const { services, startDate, endDate, totalCost } = lambdaCommonConversion(allArray);

    //         setLambdaData(services);
    //         setStartDate(startDate);
    //         setEndDate(endDate);
    //         setTotalCost(totalCost);
    //     }

    //     if (azureFunctions != null) {
    //         var functionApps = azureFunctions?.azureFunctions?.map((a) => a.rows).flat(1) || [];
    //         functionApps = functionApps.map((d) => {
    //             return {
    //                 location: d[3],
    //                 currency: d[5],
    //                 date: d[1],
    //                 subscriptionId: d[2].split("/")[2],
    //                 subscriptionName: d[4],
    //                 resourceGroup: d[2].split("/")[4],
    //                 functionApp: d[2].split("/")[8],
    //                 cost: d[0],
    //             };
    //         });
    //         const s = new Set(functionApps.map((d) => d.functionApp));

    //         setFunctionsList(
    //             Array.from(s).map((k) => {
    //                 const data = functionApps.filter((d) => d.functionApp === k);
    //                 return { ...data?.[0], cost: data.map((d) => parseFloat(d.cost)).reduce((a, b) => a + b, 0) };
    //             })
    //         );
    //     }
    // }, [awsLambda, azureFunctions]);

    // useEffect(() => {
    //     let allFunctionArray = [];
    //     if (functionsList && lambdaData) {
    //         if (functionsList != null && functionsList?.length > 0) {
    //             for (let azureDB of functionsList) {
    //                 allFunctionArray.push({
    //                     cloudProvider: "azure",
    //                     resourceId: azureDB?.functionApp,
    //                     serviceName: azureDB?.resourceGroup,
    //                     location: azureDB?.location,
    //                     subscriptionId: azureDB?.subscriptionId,
    //                     cost: azureDB?.cost,
    //                     resourceGroup: azureDB?.resourceGroup,
    //                     subscriptionName: azureDB?.subscriptionName,
    //                 });
    //             }
    //         }
    //         if (lambdaData != null && lambdaData?.length > 0) {
    //             for (let awsDB of lambdaData) {
    //                 allFunctionArray.push({ cloudProvider: "aws", resourceId: awsDB?.resourceId, serviceName: awsDB?.serviceName, location: awsDB?.location, subscriptionId: awsDB?.payerAccountId, cost: awsDB?.cost });
    //             }
    //         }
    //         console.log("allFunctionArray", allFunctionArray);
    //         setAllArray(allFunctionArray);
    //     }
    // }, [functionsList, lambdaData]);

    function resolveDateTime(dateTime) {
        const date = moment(dateTime);
        return date.format("LL") + " at " + date.format("LTS");
    }

    function showFunctionDetails(e) {
        setCosmosDBLoader(true);

        axios
            .get(REACT_APP_GET_AZURE_FUNCTION_DETAILS, {
                params: {
                    customerId,
                    subscriptionId: e.subscriptionId,
                    resourceGroups: e.serviceName,
                    functionAppName: e.resourceId,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200 && response.data) {
                        setShowingFunction({ ...e, ...response.data?.functionAppDetails?.[0] });
                        getFunctionMetricData(e);
                    } else {
                        setShowingFunction(null);
                    }
                } else {
                }
            })
            .catch((e) => {})
            .finally(() => setCosmosDBLoader(false));
    }

    function getFunctionMetricData(e) {
        setMetricsLoding(true);

        axios
            .get(REACT_APP_GET_AZURE_FUNCTIONS_APP_INSIGHTS_METRICS, {
                params: {
                    customerId,
                    subscriptionId: e.subscriptionId,
                    resourceGroups: e.serviceName,
                    functionAppName: e.resourceId,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200 && response.data) {
                        setShowingMetrics(response?.data?.metrics);
                        var result = [];
                        for (var obj in response?.data?.metrics) {
                            const selected = response?.data?.metrics[obj];

                            const name = selected.totalData.value?.[0].name?.localizedValue;
                            const unit = selected.totalData.value?.[0]?.unit;

                            var metricData = selected.totalData.value?.[0].timeseries?.[0]?.data?.[0];
                            var timeseries = selected.metrics.value?.[0].timeseries?.[0]?.data;

                            var dataKey = new Set(Object.keys(metricData));
                            dataKey.delete("timeStamp");
                            dataKey = dataKey.keys().next().value;

                            var dataKeyT = new Set(Object.keys(timeseries?.[0]));
                            dataKeyT.delete("timeStamp");
                            dataKeyT = dataKeyT.keys().next().value;

                            timeseries = timeseries.map(({ timeStamp, ...rest }) => {
                                let date = new Date(timeStamp);
                                date = date.getFullYear() + "-" + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-" + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
                                return { date, value: rest[dataKeyT] };
                            });

                            timeseries = sumDuplicates(timeseries, "date", "value").map(({ date, ...rest }) => ({ ...rest, date: new Date(date) }));
                            metricData = { timeseries, unit, total: metricData[dataKey] };
                            result.push({ name, unit, metricData });
                        }
                        setMetricsData(result);
                        setMetricTotals(result.map((r, i) => r?.metricData?.timeseries.map((r) => r.value).reduce((a, b) => a + b)));
                    } else {
                    }
                } else {
                }
            })
            .catch((e) => {})
            .finally(() => setMetricsLoding(false));
    }

    function showAwsFunctionDetails(e) {
        setCosmosDBLoader(true);

        axios
            .post(REACT_APP_AWS_LAMBDA_DETAILS, {
                customerId,
                functionName: e.resourceId,
                tenantId: e.subscriptionId,
                region: e.location,
            })
            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200 && response.data) {
                        setShowingFunction({ ...e, ...response.data });
                        getAwsFunctionMetricData(e);
                    } else {
                        setShowingFunction(null);
                    }
                } else {
                }
            })
            .catch((e) => {})
            .finally(() => setCosmosDBLoader(false));
    }

    function getAwsFunctionMetricData(e) {
        setMetricsLoding(true);

        axios
            .post(REACT_APP_AWS_LAMBDA_METRICS, {
                customerId,
                functionName: e.resourceId,
                tenantId: e.subscriptionId,
                region: e.location,
            })
            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200 && response.data) {
                        const final = [];
                        for (var o in response?.data) {
                            const data = response?.data?.[o]?.[0];
                            const timeseries = data.Timestamps.map((t, i) => ({
                                date: new Date(t),
                                value: data?.Values[i],
                            }));
                            const label = data.Label.match(/[A-Z][a-z]+/g).join(" ");
                            const unit = data.Id.match(/[A-Z][a-z]+/g).join(" ");
                            final.push({ label, timeseries, unit: unit === "Sum" ? "Count" : unit });
                        }
                        // setShowingMetrics(final);
                        setMetricsData(final);

                        setMetricTotals(final.map((r, i) => (r?.timeseries.map((r) => r.value).reduce((a, b) => a + b) / 1).toFixed(2)));
                        // setShowingMetrics(response?.data?.metrics);
                    } else {
                    }
                } else {
                }
            })
            .catch((e) => {})
            .finally(() => setMetricsLoding(false));
    }

    const [datePeriod, setDatePeriod] = React.useState(null);

    const [order, setOrder] = React.useState(TABLE_SORT.ORDER_ASC);
    const [orderBy, setOrderBy] = React.useState(TABLE_SORT_DEFAULT_COLUMN);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === TABLE_SORT.ORDER_ASC;
        setOrder(isAsc ? TABLE_SORT.ORDER_DESC : TABLE_SORT.ORDER_ASC);
        setOrderBy(property);
    };

    const [search, setSearch] = useState(null);

    const filterTable = (f) => {
        if (search && search.toString().length) {
            return !!TableSearch.doesItIncludes(
                [
                    f?.storage_account_name,
                    f?.resourceId,
                    f?.meters?.[0]?.productFamily,
                    f?.cloudProvider,
                    f?.usageAccountName,
                    f?.usageAccountId,
                    f?.subscriptionId,
                    f?.subscriptionName,
                    f?.resourceType,
                    f?.serviceName,
                    f?.location,
                    f?.Region,
                    f?.account_kind,
                    "AmazonS3",
                ],
                search
            );
        }
        return true;
    };

    return loading ? (
        <div className={classes.root}>
            <LinearProgress variant="determinate" value={progress} />
        </div>
    ) : (
        <div>
            <ContainerCardWithDateRange
                title={"Serverless Functions"}
                titleIcon={allCloudFunctions_icon}
                defaultPeriod={"1M"}
                showDateRange={true}
                collapsible={true}
                datePeriod={({ period }) => ({
                    rawStart: moment(allArray?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(allArray?.[period]?.endDate, "YYYYMMDD"),
                    start: "__",
                    end: "__",
                })}
                totalCost={({ period }) => currency + Format.formatNumber(allArray?.[period]?.totalCost)}
                datePeriodEnabled={true}
                showDatePeriod={(period) => !!allArray != null && allArray?.[period]?.resources?.length}
                onPeriodChange={(period) => {
                    setDatePeriod(period);
                    handleChangeRowsPerPage({ target: { value: 10 } });
                }}
            >
                {({ period }) => (
                    <div elevation={10} style={{ padding: 10 }}>
                        {allArray && allArray?.[period]?.resources?.length ? (
                            <>
                                <CarousalCard
                                    cardTitle1={"Total cost"}
                                    cardTitle2={"Total Resources"}
                                    cost={allArray && allArray?.[period]?.resources?.map((t) => parseFloat(t.cost)).reduce((a, b) => a + b, 0)}
                                    res={allArray?.[period]?.resources?.length}
                                    currency={currency}
                                />

                                <TableSearch
                                    onSearch={(e) => {
                                        handleChangeRowsPerPage({ target: { value: 10 } });
                                        setSearch(e);
                                    }}
                                />
                                <TableContainer style={{ height: 350 }} component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead style={{ backgroundColor: "#cfdac8" }}>
                                            <TableRow>
                                                <TableSortHeader
                                                    classes={classes}
                                                    numSelected={
                                                        1
                                                        // ||
                                                        //selected.length
                                                    }
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={handleRequestSort}
                                                    rowCount={allArray?.[datePeriod]?.resources?.length}
                                                    headCells={[
                                                        {},
                                                        { numeric: 0, id: "id", label: "Function name" },
                                                        { numeric: 0, id: "account", label: "Cloud Account/Subscription" },
                                                        { enabled: !showingFunction, numeric: 0, id: "type", label: "Resource type" },
                                                        { enabled: !showingFunction, numeric: 0, id: "location", label: "Location" },
                                                        { enabled: !showingFunction, numeric: 1, id: "cost", label: "Cost" },
                                                    ]}
                                                />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody style={{ padding: 0 }}>
                                            {allArray &&
                                                allArray?.[period]?.resources
                                                    ?.filter(filterTable)
                                                    ?.sort((r1, r2) => {
                                                        const fi = {
                                                            cost: parseFloat(r2.cost) - parseFloat(r1.cost),
                                                            id: ALPHA_NUM_SORTER.compare(
                                                                r1?.cloudProvider == "azure" ? r1?.resourceId : r1?.resourceId || r1?.meters?.[0]?.productFamily,
                                                                r2?.cloudProvider == "azure" ? r2?.resourceId : r2?.resourceId || r2?.meters?.[0]?.productFamily
                                                            ),
                                                            account: ALPHA_NUM_SORTER.compare(r1?.usageAccountId || r1?.subscriptionId, r2?.usageAccountId || r2?.subscriptionId),
                                                            type: ALPHA_NUM_SORTER.compare(r1?.serviceName || r1?.resourceType, r2?.serviceName || r2?.resourceType),
                                                            location: ALPHA_NUM_SORTER.compare(r1?.location, r2?.location),
                                                        }[orderBy];
                                                        return fi * order;
                                                    })
                                                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    ?.map((row, index) => {
                                                        return <MyTableRow row={row} index={index} list={list} setList={setList} currency={currency} />;
                                                    })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <TablePagination
                                    ActionsComponent={TablePaginationActions}
                                    rowsPerPageOptions={[10, 25, 50, 75]}
                                    component="div"
                                    count={allArray?.[period]?.resources?.filter(filterTable)?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </>
                        ) : (
                            <Typography style={{ display: "flex", justifyContent: "center" }}>No data found</Typography>
                        )}
                    </div>
                )}
            </ContainerCardWithDateRange>
        </div>
    );
}

function MyTableRow({ row, index, list, setList, currency }) {
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState("");

    useEffect(() => {
        if (row) {
            setDate(moment(row.date, "YYYYMMDD").format("MMM DD, YYYY"));
        }
    }, [row]);

    return (
        <React.Fragment>
            <TableRow hover key={row?.serviceName + row?.resourceId + row?.cost + index}>
            <TableCell>
                    <IconButton
                        style={{ cursor: "pointer" }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th">
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                        {row?.cloudProvider == "azure" ? (
                            <>
                                <img src={function_apps_icon} height={24} style={{ marginRight: 4 }} />

                                <span
                                // onClick={() => {
                                //     showFunctionDetails(row);
                                // }}
                                >
                                    {row?.resourceId}
                                </span>
                            </>
                        ) : (
                            <>
                                <img src={lambdaFunctionIcon} height={24} style={{ marginRight: 4 }} />{" "}
                                <span
                                // onClick={() => {
                                //     showAwsFunctionDetails(row);
                                // }}
                                >
                                    {row?.resourceId || <span style={{ color: colors.gray }}> {row?.meters?.[0]?.productFamily}</span>}
                                </span>
                            </>
                        )}
                    </div>
                </TableCell>
                <TableCell align="left">
                    {row?.cloudProvider == "azure" ? (
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Popover id="popover-contained">
                                    <Popover.Body style={{ fontSize: 11 }}>{row?.subscriptionId}</Popover.Body>
                                </Popover>
                            }
                        >
                            <div>
                                <img src={AzureLogo} height={22} /> {row?.subscriptionName}
                            </div>
                        </OverlayTrigger>
                    ) : (
                        <OverlayTrigger placement="bottom" overlay={<Popover id="popover-contained">{"usageAccountName" in row && <Popover.Body style={{ fontSize: 11 }}>{row?.usageAccountId}</Popover.Body>}</Popover>}>
                            <div>
                                <img src={AwsLogo} height={22} /> {row?.usageAccountId || row?.usageAccountName}{" "}
                            </div>
                        </OverlayTrigger>
                    )}
                </TableCell>
                
                        {/* <TableCell align="left">{row?.cloudProvider == "azure" ? row?.subscriptionId+ " ("+row?.subscriptionName+")" : row?.usageAccountId + " ("+row?.usageAccountName+")"}</TableCell> */}
                        <TableCell align="left">{row?.cloudProvider == "azure" ? row?.resourceType : row?.serviceCode}</TableCell>
                        {/* <TableCell align="right">{row?.subscriptionId}</TableCell> */}
                        <TableCell align="left">{row?.location}</TableCell>
                        <TableCell align="right">
                            {currency}
                            {parseFloat(row?.cost).toFixed(2)}
                        </TableCell>
                    
            </TableRow>
            <ExpandLavelData data={row} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

export function ExpandLavelData({ data, open, select, set, currency }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2.5}>
                            <Htmltable data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <div style={{ maxHeight: 300 }} className="overflow-auto">
            <table id="customers">
                {(data?.meters && Object.keys(data?.meters?.[0])?.length > 3) || data?.cloudProvider == "aws" ? (
                    <tr>
                        <th>Operation</th>
                        <th>Product family</th>
                        <th>Description</th>
                        <th>Usage Type</th>
                        <th>Total</th>
                    </tr>
                ) : (
                    <tr>
                        <th>Service name</th>
                        <th>Meter name</th>
                        <th>Total</th>
                    </tr>
                )}

                {data?.cloudProvider == "aws"
                    ? data &&
                      data?.meters
                          ?.sort((a, b) => {
                              return parseFloat(b?.cost || 0) - parseFloat(a?.cost || 0);
                          })
                          .map((m) =>
                              Object.keys(m)?.length > 3 ? (
                                  <tr>
                                      <td>{m?.operation||'-'}</td>
                                      <td>{m?.productFamily||'-'}</td>
                                      <td>{m.lineItemDescription||'-'}</td>
                                      <td>{m.lineItemDescription||'-'}</td>

                                      <td>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                                  </tr>
                              ) : (
                                  <tr>
                                      <td>{m.serviceName||'-'}</td>
                                      <td>{m?.meterName||'-'}</td>

                                      <td>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                                  </tr>
                              )
                          )
                    : data &&
                      data?.billing?.map((a) =>
                          a?.meters
                              ?.sort((a, b) => {
                                  return parseFloat(b?.cost || 0) - parseFloat(a?.cost || 0);
                              })
                              .map((m) =>
                                  Object.keys(m)?.length > 3 ? (
                                      <tr>
                                          <td>{m?.operation||'-'}</td>
                                          <td>{m?.productFamily||'-'}</td>
                                          <td>{m.lineItemDescription||'-'}</td>

                                          <td>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                                      </tr>
                                  ) : (
                                      <tr>
                                          <td>{m.serviceName||'-'}</td>
                                          <td>{m?.meterName||'-'}</td>

                                          <td>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                                      </tr>
                                  )
                              )
                      )}
            </table>
        </div>
    );
}

function sumDuplicates(data, checkKey, valueKey) {
    var result = [];
    data.forEach(function (a) {
        if (!this[a[checkKey]]) {
            const data = {};
            data[checkKey] = a[checkKey];
            data[valueKey] = 0;
            // this[a.date] = { date: a.date, value: 0 };
            this[a[checkKey]] = data;
            result.push(this[a[checkKey]]);
        }
        this[a[checkKey]][valueKey] += a[valueKey];
    }, Object.create(null));
    return result;
}
