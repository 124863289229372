import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "MakeWithStyles";
import { Table, TableBody, TableCell as TCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Divider, LinearProgress } from "@mui/material";
import storage_account_icon from "../../images/resources/Storage_Account.svg";
import Cost from "../../components/Cost";
import {ALPHA_NUM_SORTER, bytesToSize} from "../../util/Miscellaneous";
import axios from "../../connection/axios";
import { CancelRounded } from "@mui/icons-material";
import SlideShow from "../../components/CustomSlider/SlideShow";
import ReactD3BarChart from "../../components/D3Charts/ReactD3BarChart";
import TablePagination from "@mui/material/TablePagination";
import allCloudStorage_icon from '../../images/allCloudStorage.png'
import {getAWSS3BucketRequest,getAllStorageRequest} from "../../redux/actions/actions";
import {similarObject} from "../listS3bucket/ConversionFunc";
import AzureLogo from "../../images/Azure_logo.svg";
import AwsLogo from "../../images/aws_dark.svg";
import CarousalCard from "../../components/CarousalCard";
import S3Icon from "../../images/awsAccounts/Amazon-Simple-_Storage-Service-S3.svg";
import { Tooltip, OverlayTrigger,Popover } from "react-bootstrap";
import LinkText from "../../components/LinkText";
import Format from "../../components/NumberFormat";
import MatricObjectData from "../listS3bucket/MatricObjectData";
import MatricLevelChart from "../listS3bucket/MatricLevelChart";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import moment from "moment";
import TablePaginationActions from "../../components/TablePaginationActions";
import colors from "styles/color";
import TableSortHeader from "../../components/TableSortHeader";
import {TABLE_SORT} from "../../util/AppConstants";
import TableSearch from "../../components/TableSearch";
import Collapse from "@mui/material/Collapse";

import IconButton from "@mui/material/Icon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const { REACT_APP_AZURE_STORAGE_ACCOUNTS } = process.env;

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        //marginBottom: 5,
    },
}));

function TableCell(props) {
    return (
        <TCell {...props} style={{  padding: 0,paddingRight:8 }}>
            {props.children}
        </TCell>
    );
}

const TABLE_SORT_DEFAULT_COLUMN = 'cost';

export default function AllCloudStorage({ showData, period, currency, curr = "USD", tab, progress, tenantId = null, subscriptionId = null, subscriptionList,customerId }) {
    const dispatch = useDispatch();
    const {classes} = useStyles();
    const [data, setData] = useState(null);
    // const [loading, setLoading] = useState(true);
    // const customerId = useSelector((state) => state.userDetailsReducer.id);
    // const loading = useSelector(
    //   (state) => state.azureRecommendationReducer.loading
    // );
    const [showingStorage, setShowStorage] = useState(null);
    const [chart, setChart] = useState([]);
    const [meterChart, setMeterChart] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [showingDetailsS3, setShowingDetailsS3] = React.useState(null);
    const [showingDetailsStorage, setShowingDetailsStorage] = React.useState(null);
    // const { S3BucketAll, S3BucketAccount } = useSelector((state) => ({ S3BucketAll: state.awsLambdaReducer.S3BucketAll, S3BucketAccount: state.awsLambdaReducer.S3BucketAccount, loading: state.awsLambdaReducer.loading }));
    const [list, setList] = useState(null);

    
    let allArray = useSelector((state) => state?.allStorageReducer?.allStorageData);

    let loading = useSelector((state) => state?.allStorageReducer?.loading);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function getS3List(){
        dispatch(getAWSS3BucketRequest({customerId}));
    }

    useEffect(() => {
        if (allArray ==null) {
        dispatch(
            getAllStorageRequest({
                customerId,
            })
        );

        }
    }, [customerId]);

    // useEffect(() => {
    //     if (customerId)
    //         getS3List();
    //         axios
    //             .get(REACT_APP_AZURE_STORAGE_ACCOUNTS, {
    //                 params: {
    //                     customerId,
    //                 },
    //             })
    //             .then((response) => response.data)
    //             .then((response) => {
    //                 if (subscriptionId && tenantId) {
    //                     const data = response.storageaccounts_subs.find((s) => s.subscription_id == subscriptionId && s.tenant_id == tenantId);
    //                     if (data) {
    //                         setData(
    //                             data?.storage_accounts?.map((d) => ({
    //                                 ...d,
    //                                 subscription_id: data?.subscription_id,
    //                                 tenant_id: data?.tenant_id,
    //                                 cloudProvider: 'azure'
    //                             }))
    //                         );

    //                         // top5StorageAccountCharts(response?.storageaccounts_subs);
    //                     }
    //                 } else {
    //                     // debugger
    //                     setData(
    //                         response?.storageaccounts_subs
    //                             ?.map((s) =>
    //                                 s.storage_accounts.map((j) => ({
    //                                     ...j,
    //                                     subscription_id: s.subscription_id,
    //                                     tenant_id: s.tenant_id,
    //                                     cloudProvider: 'azure'
    //                                 }))
    //                             )
    //                             .flat(1)
    //                     );

    //                     top5StorageAccountCharts(response?.storageaccounts_subs);
    //                 }
    //                 // console.log("founddata0", response?.storageaccounts_subs);
    //             })
    //             .finally(() => setLoading(false));
    // }, [customerId, subscriptionId, tenantId]);

    function top5StorageAccountCharts(data1) {
        let chartData = [];
        {
            data1 &&
                data1?.map((data, row) => {
                    data.storage_accounts &&
                        data.storage_accounts?.map((i, j) => {
                            i?.billing &&
                                i?.billing.map((row, l) => {
                                    row?.cost > 1 &&
                                        chartData.push({
                                            label: i?.storage_account_name,
                                            value: row?.cost,
                                        });
                                });
                        });
                });
        }
        setChart(chartData);
    }

    function meterStorageAccountCharts(row) {
        let chartData = [];
        {
            row?.billing.map(
                (i, j) =>
                    i?.meter &&
                    i?.meter.map(
                        (row, l) =>
                            row?.cost > 1 &&
                            chartData.push({
                                label: row?.meterName,
                                value: row?.cost,
                            })
                    )
            );
        }
        setMeterChart(chartData);
    }

    // useEffect(async ()=>{
    //     if(S3BucketAll && S3BucketAll.length && data) {
    //         var t = data;
    //         var obj = [];
    //         S3BucketAll.filter((i) => i.subscriptions).map((e) => e.subscriptions.map((i) => i.billing.map((j) => obj.push(j))));
    //         var val = await similarObject(obj);
    //         val = val.result.map(({BlendedCost: cost, ...rest})=>({...rest, cloudProvider: 'aws', cost: parseFloat(cost), billing: [{cost}]}));
    //         t.push(...val);
    //         setData(t);
    //         console.log('allStorage',t);
    //     }
    // }, [data,S3BucketAll, S3BucketAccount]);

    const showS3Details = (detail) => {
        setShowingDetailsStorage(null);
        setShowingDetailsS3(null);
        if (detail) {
            setShowingDetailsS3(detail);
        }
    };

    const showStorageDetails = (detail) => {
        setShowingDetailsS3(null);
        setShowingDetailsStorage(null);
        if (detail) {
            setShowingDetailsStorage(detail);
        }
    };

    const [datePeriod, setDatePeriod] = React.useState(null);


    const [order, setOrder] = React.useState(TABLE_SORT.ORDER_ASC);
    const [orderBy, setOrderBy] = React.useState(TABLE_SORT_DEFAULT_COLUMN);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === TABLE_SORT.ORDER_ASC;
        setOrder(isAsc ? TABLE_SORT.ORDER_DESC : TABLE_SORT.ORDER_ASC);
        setOrderBy(property);
    };

    const [search, setSearch] = useState(null);

    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.storage_account_name,
                f?.resourceId,
                f?.meters?.[0]?.productFamily,
                f?.cloudProvider,
                f?.usageAccountName,
                f?.usageAccountId,
                f?.subscriptionId,
                f?.subscriptionId,
                f?.resourceType,
                f?.serviceName,
                f?.location,
                f?.Region,
                f?.account_kind,
                "AmazonS3"
            ], search);
        }
        return true;
    }

    return loading ? (
        <div className={classes.root}>
            <LinearProgress variant="determinate" value={progress} />
        </div>
    ) : (
        <div>
              <ContainerCardWithDateRange
                title={"Storage accounts"}
                titleIcon={allCloudStorage_icon}
                defaultPeriod={"1M"}
                showDateRange={true}
                // ExtraHeaderComponent={({ period }) =>
                //     allArray != null && allArray?.[period]?.resources?.length ? (
                //         <div style={{ marginTop: 4 }}>
                //             <span>{moment(allArray?.[period]?.startDate, "YYYYMMDD").format("MMM DD, YYYY")}</span> - <span>{moment(allArray?.[period]?.endDate, "YYYYMMDD").format("MMM DD, YYYY")}</span>
                //         </div>
                //     ) : null
                // }
                collapsible={true}
                // MiddleComponent={({ period }) =>
                //     allArray?.[period]?.totalCost && (
                //         <span style={{ display: "flex", justifyContent: "flex-end" }}>
                //             Total: {currency}
                //             {Format.formatNumber(allArray?.[period]?.totalCost)}
                //         </span>
                //     )
                // }
                datePeriod={({period})=>({
                    start:"__",
                    end: "__",
                    rawStart: moment(allArray?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(allArray?.[period]?.endDate, "YYYYMMDD")
                })}
                totalCost={({period})=>(
                    currency +
                    Format.formatNumber(allArray?.[period]?.totalCost)
                )}
                datePeriodEnabled={true}
                showDatePeriod={(period)=>!!allArray != null && allArray?.[period]?.resources?.length}
                onPeriodChange={(period)=> {
                    setDatePeriod(period)
                    handleChangeRowsPerPage({target: {value: 10}})
                }}
            >
                {({ period }) => (
                     <div elevation={10} style={{ padding: 10 }}>
                
                     {allArray && allArray?.[period]?.resources?.length ? (
              <>
                <CarousalCard cardTitle1={'Total cost'} cardTitle2={"Total Resources"} cost={allArray &&  allArray?.[period]?.totalCost} res={allArray?.[period]?.resources?.length} currency={currency} />

               
                        <TableSearch onSearch={setSearch} />
                        <TableContainer style={{ height: 350 }}component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead style={{ backgroundColor: "#cfdac8" }}>
                                    <TableRow>
                                        <TableSortHeader
                                            classes={classes}
                                            numSelected={1
                                                // ||
                                                //selected.length
                                            }
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                            rowCount={allArray?.[datePeriod]?.resources?.length}
                                            headCells={[
                                                {},
                                                { numeric:0, id:'id', label: 'Name'},
                                                { numeric:0, id:'account', label: 'Cloud Account/Subscription'},
                                                { enabled: !(showingDetailsStorage || showingDetailsS3), numeric:0, id:'type', label: 'Storage type'},
                                                { enabled: !(showingDetailsStorage || showingDetailsS3), numeric:0, id:'location', label: 'Location'},
                                                { enabled: !(showingDetailsStorage || showingDetailsS3), numeric:1, id:'cost', label: 'Cost'},
                                            ]}
                                        />
                                        {/*<TableCell>*/}
                                        {/*    <b>Name</b>*/}
                                        {/*</TableCell>*/}
                                        {/*<TableCell align="left">*/}
                                        {/*    <b>Cloud Account/Subscription</b>*/}
                                        {/*</TableCell>*/}


                                        {/*{(showingDetailsStorage || showingDetailsS3) ? null : (*/}
                                        {/*    <>*/}
                                        {/*       */}
                                        {/*        <TableCell align="left">*/}
                                        {/*            <b>Storage type</b>*/}
                                        {/*        </TableCell>*/}
                                        {/*        /!*<TableCell align="right">*!/*/}
                                        {/*        /!*    <b>Resource group</b>*!/*/}
                                        {/*        /!*</TableCell>*!/*/}
                                        {/*        /!*<TableCell align="right">*!/*/}
                                        {/*        /!*    <b>Capacity</b>*!/*/}
                                        {/*        /!*</TableCell>*!/*/}
                                        {/*        <TableCell align="left">*/}
                                        {/*            <b>Location</b>*/}
                                        {/*        </TableCell>*/}
                                        {/*        <TableCell align="right">*/}
                                        {/*            <b>Cost</b>*/}
                                        {/*        </TableCell>*/}
                                        {/*    </>*/}
                                        {/*)}*/}
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ padding: 0 }}>
                                    {allArray?.[period]?.resources
                                        ?.filter(filteredTable)
                                        ?.sort((r1, r2) => {
                                            const fi =  ({
                                                cost:      (
                                                    ((r2?.cloudProvider == "azure")?
                                                    r2.billing
                                                        ?.map((b) => parseFloat(b.cost))
                                                        ?.reduce((a, b) => a + b, 0):
                                                            parseFloat(r2?.cost)
                                                    ) -
                                                    ((r1?.cloudProvider == "azure")?
                                                        r1.billing
                                                        ?.map((b) => parseFloat(b.cost))
                                                        ?.reduce((a, b) => a + b, 0):
                                                            parseFloat(r1?.cost)
                                                    )
                                                ),
                                                id:         ALPHA_NUM_SORTER.compare(
                                                    (r1?.cloudProvider == "azure")?r1?.storage_account_name: (r1?.resourceId || r1?.meters?.[0]?.productFamily),
                                                    (r2?.cloudProvider == "azure")?r2?.storage_account_name: (r2?.resourceId || r2?.meters?.[0]?.productFamily)
                                                ),
                                                account:   ALPHA_NUM_SORTER.compare(
                                                    (r1?.cloudProvider == "azure")?r1?.subscriptionName:(r1?.usageAccountName||r1?.usageAccountId),
                                                    (r2?.cloudProvider == "azure")?r2?.subscriptionName:(r2?.usageAccountName||r2?.usageAccountId),
                                                ),
                                                type:      ALPHA_NUM_SORTER.compare(
                                                    r1?.account_kind || "AmazonS3",
                                                    r2?.account_kind || "AmazonS3",
                                                ),
                                                location:  ALPHA_NUM_SORTER.compare((r1?.location || r1?.Region),(r2?.location || r2?.Region)),
                                            }[orderBy]);
                                            return fi * order;
                                        })

                                        // ?.sort((r1, r2) => ))
                                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        ?.map((row, index) => {
                                            return <MyTableRow row={row} index={index} list={list} setList={setList} currency={currency} />;
                                      
                                                })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                   
                    <Box flex={(showingDetailsStorage || showingDetailsS3) ? 0.75 : 0}>
                        {showingDetailsStorage ? (
                            <StorageDetails data={showingDetailsStorage} setData={()=>setShowingDetailsStorage(null)} classes={classes} currency={currency} />
                            ) : showingDetailsS3 ? (
                                <S3Details customerId={customerId} data={showingDetailsS3} setData={()=>setShowingDetailsS3(null)} classes={classes} currency={currency} />
                            ): null }
                    </Box>
                
                <TablePagination ActionsComponent={TablePaginationActions}
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={allArray?.[period]?.resources?.filter(filteredTable)?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                    </>
                                      ) : (
                                        <Typography style={{ display: "flex", justifyContent: "center" }}>No data found</Typography>
                                    )}

            </div>
             )}
             </ContainerCardWithDateRange>
        </div>
    );
}

function MyTableRow({ row, index, list, setList, currency }) {
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState("");

    useEffect(() => {
        if (row) {
            setDate(moment(row.date, "YYYYMMDD").format("MMM DD, YYYY"));
        }
    }, [row]);

    return (
        <React.Fragment>
           <TableRow key={row?.storage_account_name||row?.resourceId +row?.cost + index}>
           <TableCell>
                    <IconButton
                        style={{ cursor: "pointer" }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                                                <TableCell component="th" scope="row" style={{ display: "flex", alignItems: "center" }}>
                                                    <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                                                        {row?.cloudProvider == "azure" ? (
                                                            <>
                                                                <img src={storage_account_icon} height={26} style={{ marginRight: 4 }} />

                                                                <span
                                                                    // onClick={() => {
                                                                    //     showStorageDetails(row)
                                                                    // }}
                                                                >
                                                                    {row?.storage_account_name}
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <img src={S3Icon} height={24} style={{ marginRight: 4 }} />{" "}
                                                                <span
                                                                    // onClick={() => {
                                                                    //     showS3Details(row);
                                                                    // }}
                                                                >
                                                                               {row?.resourceId || <span style={{ color: colors.gray }}> {row?.meters?.[0]?.productFamily}</span>}
                                                                </span>
                                                            </>
                                                        )}
                                                    </div>
                                                    {/*<img src={storage_account_icon} height={30} />{" "}*/}
                                                    {/*<a*/}
                                                    {/*    href={"#"}*/}
                                                    {/*    onClick={() => {*/}
                                                    {/*        setShowStorage(row);*/}
                                                    {/*        meterStorageAccountCharts(row);*/}
                                                    {/*    }}*/}
                                                    {/*>*/}
                                                    {/*    {row?.storage_account_name || row?.ResourceId}*/}
                                                    {/*</a>*/}
                                                </TableCell>
                                                <TableCell align="left">
                                                                        {row?.cloudProvider == "azure" ? (
                                                                            <OverlayTrigger
                                                                                placement="bottom"
                                                                                overlay={
                                                                                    <Popover id="popover-contained">

                                                                                        <Popover.Body style={{fontSize:11}}>
                                                                                            {row?.subscriptionId}
                                                                                        </Popover.Body>
                                                                                    </Popover>
                                                                                }
                                                                            >
                                                                                <div>
                                                                                <img src={AzureLogo} height={22} /> {row?.subscriptionName}

                                                                                </div>
                                                                            </OverlayTrigger>
                                                                        ) : (
                                                                            <OverlayTrigger
                                                                                placement="bottom"
                                                                                overlay={
                                                                                    <Popover id="popover-contained">
                                                                                          {"usageAccountName" in row &&
                                                                                        <Popover.Body style={{fontSize:11}}>
                                                                                            {row?.usageAccountName}
                                                                                        </Popover.Body>}
                                                                                    </Popover>
                                                                                }
                                                                            >
                                                                                <div>
                                                                                <img src={AwsLogo} height={22} /> {row?.usageAccountId||row?.usageAccountName}{" "}
                                                                                </div>
                                                                            </OverlayTrigger>
                                                                        )}
                                                                    </TableCell>
                                                
                                                        {/* <TableCell align="left">{row?.cloudProvider == "azure" ? row?.subscriptionId+ " ("+row?.subscriptionName+")" : row?.usageAccountId + " ("+row?.usageAccountName+")"}</TableCell> */}

                                                        <TableCell align="left">{row?.account_kind || "AmazonS3"}</TableCell>
                                                        {/*<TableCell align="right">{row?.resource_group}</TableCell>*/}
                                                        {/*<TableCell align="right">{row?.capacity?.average ? bytesToSize(row?.capacity?.average) : "--"}</TableCell>*/}
                                                        <TableCell align="left">{row?.location || row?.Region}</TableCell>
                                                        <TableCell align="right">
                                                            {currency}
                                                            {row?.cloudProvider == "azure" ?
                                                            row?.billing
                                                                ?.map((b) => parseFloat(b.cost))
                                                                ?.reduce((a, b) => a + b, 0)
                                                                .toFixed(2): Format.formatNumber(Number(row?.cost).toFixed(2))}
                                                        </TableCell>
                                                   
                                            </TableRow>
            <ExpandLavelData data={row} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

export function ExpandLavelData({ data, open, select, set, currency }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                       <Box margin={2.5}>
                            <Htmltable data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <div style={{ maxHeight: 300 }} className="overflow-auto">
            <table id="customers">
                 {data?.meters && Object.keys(data?.meters?.[0])?.length > 3 || data?.cloudProvider=='aws' ? (
                    <tr>
                        <th>Operation</th>
                        <th>Product family</th>
                        <th>Description</th>
                        <th>Usage Type</th>
                        <th>Total</th>
                    </tr>
                ) : (
                    <tr>
                        <th>Service name</th>
                        <th>Meter name</th>
                        <th>Total</th>
                    </tr>
                )}

                {data?.cloudProvider=='aws' ? data &&
                    data?.meters
                        ?.sort((a, b) => {
                            return parseFloat(b?.cost || 0) - parseFloat(a?.cost || 0);
                        })
                        .map((m) =>
                            Object.keys(m)?.length > 3 ? (
                                <tr>
                                    <td>{m?.operation||'-'}</td>
                                    <td>{m?.productFamily||'-'}</td>
                                    <td>{m.lineItemDescription||'-'}</td>
                                    <td>{m.lineItemUsageType||'-'}</td>

                                    <td>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                                </tr>
                            ) : (
                                <tr>
                                    <td>{m.serviceName||'-'}</td>
                                    <td>{m?.meterName||'-'}</td>

                                    <td>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                                </tr>
                            )
                        ):
                        data && data?.billing?.map((a)=>a?.meter
                        ?.sort((a, b) => {
                            return parseFloat(b?.cost || 0) - parseFloat(a?.cost || 0);
                        })
                        .map((m) =>
                            Object.keys(m)?.length > 3 ? (
                                <tr>
                                    <td>{m?.operation||'-'}</td>
                                    <td>{m?.productFamily||'-'}</td>
                                    <td>{m.lineItemDescription||'-'}</td>

                                    <td>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                                </tr>
                            ) : (
                                <tr>
                                    <td>{m.serviceName||'-'}</td>
                                    <td>{m?.meterName||'-'}</td>

                                    <td>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                                </tr>
                            )
                        )
                        )}
            </table>
        </div>
    );
}

function StorageDetails({data, currency, setData, classes}){
    return (
        <Paper elevation={5} style={{ padding: 10 }}>
            <Box display={"flex"} alignItems={"center"}>
                <Box display={"flex"} flex={1} justifyContent={"space-between"} style={{ paddingRight: 10 }} alignItems={"center"}>
                    <Typography component={"h4"} variant={"h4"}>
                        <img src={storage_account_icon} height={40} /> {data?.storage_account_name}
                    </Typography>
                    <Typography component={"h5"} variant={"h5"}>
                        {currency}
                        <Cost>
                            {data?.billing
                                .map((b) => parseFloat(b.cost))
                                .reduce((a, b) => a + b, 0)
                                .toFixed(2)}
                        </Cost>
                    </Typography>
                </Box>
                <CancelRounded onClick={() => setData(null)} />
            </Box>
            <Divider />

            <Box style={{ padding: 10 }} flexDirection={"row"} display={"flex"}>
                <Box flex={1}>
                    <Typography>
                        <b>Access Tier</b>
                    </Typography>
                    <Typography>{data?.access_tier || "-"}</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Account Kind</b>
                    </Typography>
                    <Typography>{data?.account_kind}</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Location</b>
                    </Typography>
                    <Typography>{data?.location}</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Performance</b>
                    </Typography>
                    <Typography>{data?.performance}</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Subscription Id</b>
                    </Typography>
                    <Typography>{data?.subscription_id}</Typography>
                </Box>
                <Box flex={1}>
                    <Typography>
                        <b>Replication</b>
                    </Typography>
                    <Typography>{data?.replication}</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Resource group</b>
                    </Typography>
                    <Typography>{data?.resource_group}</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Status</b>
                    </Typography>
                    <Typography>{data?.status}</Typography>
                    <Typography style={{ marginTop: 10 }}>
                        <b>Capacity</b>
                    </Typography>
                    <Typography>{bytesToSize(data?.capacity.average)}</Typography>
                    {/*<Typography style={{marginTop: 10}}><b>Cost</b></Typography>*/}
                    {/*<Typography>{currency}<Cost>{(showingDetailsStorage || showingDetailsS3)?.billing.map(b=>parseFloat(b.cost)).reduce((a,b)=>a+b, 0).toFixed(2)}</Cost></Typography>*/}
                </Box>
            </Box>
            <Divider />

            <Box>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead style={{ backgroundColor: "#cfdac8" }}>
                            <TableRow>
                                <TableCell>
                                    <b>Service</b>
                                </TableCell>
                                <TableCell>
                                    <b>Meter</b>
                                </TableCell>
                                <TableCell>
                                    <b>Cost</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ padding: 0 }}>
                            {data?.billing.map(
                                (i, j) =>
                                    i?.meter &&
                                    i?.meter.map((row, l) => (
                                        <TableRow key={row?.meterName}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {row?.serviceName}
                                            </TableCell>
                                            <TableCell>{row?.meterName}</TableCell>
                                            <TableCell>{currency + Number(row?.cost).toFixed(2)}</TableCell>
                                        </TableRow>
                                    ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

        </Paper>
    )
}

function S3Details({classes, currency, data, setData, customerId}){
    return (
        <Paper style={{ flex: 1, padding: 5 }} elevation={3}>
            <Box display={"flex"} alignItems={"center"}>
                <Box display={"flex"} flex={1} justifyContent={"space-between"} style={{ paddingRight: 10 }} alignItems={"center"}>
                    <Typography component={"h4"} variant={"h4"}>
                        <img src={S3Icon} height={30} /> {data?.ResourceId}
                    </Typography>
                    <Typography component={"h5"} variant={"h5"}>
                        {currency}
                        {data?.cost ? <Cost>{data?.cost}</Cost> : "__.__"}
                    </Typography>
                </Box>
                <CancelRounded onClick={() => setData(null)} />
            </Box>
            <Divider />
            <Box padding={1}>
                <MatricObjectData customerId={customerId} bucketName={data?.ResourceId} tenantId={data?.UsageAccountId} bucketRegion={data?.Region} />
            </Box>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: 10 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
                        <Typography>
                            <b>Linked account:</b>
                        </Typography>
                        <Typography className="typoMargin">
                            <b>PricingTerm:</b>
                        </Typography>
                        <Typography className="typoMargin">
                            <b>PricingUnit:</b>
                        </Typography>
                        <Typography className="typoMargin">
                            <b>LineItemType:</b>
                        </Typography>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
                        <Typography>{data?.UsageAccountId || "-"}</Typography>
                        <Typography className="typoMargin">{data?.PricingTerm || "-"}</Typography>
                        <Typography className="typoMargin">{data?.PricingUnit || "-"}</Typography>
                        <Typography className="typoMargin">{data?.LineItemType || "-"}</Typography>
                    </div>
                </div>
                <div style={{ display: "flex", marginRight: 20 }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography>
                            <b>ResourceTags:</b>
                        </Typography>

                        <Typography className="typoMargin">
                            <b>Region:</b>
                        </Typography>

                        <Typography className="typoMargin">
                            <b>ProductName:</b>
                        </Typography>

                        <Typography className="typoMargin">
                            <b>BillType:</b>
                        </Typography>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
                        <Typography>{data?.ResourceTags || "-"}</Typography>
                        <Typography className="typoMargin">{data?.Region}</Typography>
                        <Typography className="typoMargin">{data?.ProductName}</Typography>
                        <Typography className="typoMargin">{data?.BillType || "-"}</Typography>
                    </div>
                </div>
            </div>
            <Divider />
            <Box padding={1}>{data?.meterLevel && <Metertable data={data?.meterLevel} currency={currency} />}</Box>
            <Divider />
            <Box padding={2}>
                <MatricLevelChart customerId={customerId} bucketName={data?.ResourceId} tenantId={data?.UsageAccountId} bucketRegion={data?.Region} />
            </Box>
        </Paper>
    )
}

function Metertable({ data, currency }) {
    return (
        <div>
            <Typography style={{ fontWeight: "bold" }}>Meter data</Typography>
            <div style={{ maxHeight: 300 }} className="overflow-auto">
                <table id="customers">
                    <tr>
                        <th>Operation</th>
                        <th>Product family</th>
                        <th>Usage type</th>
                        <th>Description</th>
                        <th>Cost</th>
                    </tr>

                    {data &&
                    data.meter.map((m) => (
                        <tr>
                            <td>{m.Operation}</td>
                            <td>{m.ProductFamily}</td>
                            <td>{m.LineItemUsageType}</td>
                            <td>{m.LineItemDescription}</td>
                            <td>{currency + Format.formatNumber(Number(m["cost"]))}</td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    );
}
