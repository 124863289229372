import * as types from "../actions/types";

const userState = {
    allAccountData: null,
    error: null,
    loading: false,
};

function allAccountReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.ALL_ACCOUNT_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.ALL_ACCOUNT_SUCCESS: {
            return {
                ...state,
                allAccountData: payload.data,
                loading: false,
            };
        }
        case types.ALL_ACCOUNT_FAILURE: {
            return {
                ...state,
                loading: false,
                allAccountData: null,
                error: payload,
            };
        }

        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                allAccountData: null,
                error: null,
                loading: false,
            };
        }

        default:
            return state;
    }
}
export default allAccountReducer;
