import React, { useState, useEffect } from "react";
import {  makeStyles } from "MakeWithStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/Icon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Format from "components/NumberFormat";
import moment from "moment";
import Color from "../../styles/color";
import { getAllComputeRequest } from "../../redux/actions/actions";
import { LinearProgress } from "@mui/material";
import Cost from "../../components/Cost";
import ComputeIcon from "../../images/Virtual-Machine.svg";
import KubernetesIcon from "../../images/Kubernetes-Services.svg";
import ContainersIcon from "../../images/Container-Instances.svg";
import { Tooltip, OverlayTrigger, Popover } from "react-bootstrap";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import CarousalCard from "components/CarousalCard";
import AmazonRDS from "../../images/awsAccounts/Amazon-RDS.svg";
import AwsELC from "../../images/awsAccounts/Amazon-ElastiCache_light-bg.svg";
import AwsEC2 from "../../images/awsAccounts/Amazon-EC2.svg";
import AmazonS3 from "../../images/awsAccounts/Amazon-Simple-_Storage-Service-S3.svg";
import AmazonRoute53 from "../../images/awsAccounts/Amazon-Route-53.svg";
import AmazonCloudFront from "../../images/awsAccounts/Amazon-CloudFront_light-bg.svg";
import AmazonApiGateway from "../../images/awsAccounts/Amazon-API-Gateway.svg";
import AWSSecretmanager from "../../images/awsAccounts/AWS-Secrets-Manager.svg";
import AWSConfig from "../../images/awsAccounts/AWS-Config.svg";
import AmazonCloudWatch from "../../images/awsAccounts/Amazon-CloudWatch_Alarm_light-bg.svg";

import Storage from "../../images/resources/Storage_Account.svg";
import Function from "../../images/resources/Function_App.svg";
import CosmosDB from "../../images/resources/Azure_Cosmos_DB.svg";
import AppServices from "../../images/resources/Application_Service.svg";
import AppCenter from "../../images/resources/Platform_Service.svg";
import Adb2c from "../../images/resources/Azure_Active_Directory_B2C.svg";
import LogAnalytics from "../../images/resources/Application_Insights.svg";
import ApplicationGateway from "../../images/resources/Application_Gateway.svg";
import AzureAppCervices from "../../images/resources/API_Management_Service.svg";
import ContainerRegistry from "../../images/resources/Container_Registry.svg";
import PublicIpAddress from "../../images/resources/Public_IPAddress.svg";
import IotHub from "../../images/resources/IoT_Hub.svg";
import KeyValut from "../../images/resources/Key_Vault.svg";
import Disk from "../../images/resources/Managed_Disk.svg";
import Redis from "../../images/resources/Azure_Cache_for_Redis.svg";
import Profile from "../../images/resources/CDN_Profile.svg";
import NameSpace from "../../images/resources/namespaces.svg";
import LoadBalance from "../../images/resources/Load_Balancer.svg";
import VirtualMachine from "../../images/resources/Virtual_Machine.svg";
import WorkSpaces from "../../images/resources/Workspace.svg";
import WorkFlow from "../../images/resources/workflows.svg";
import ServerFarms from "../../images/resources/serverfarms.svg";
import VirtualNetwork from "../../images/resources/Virtual_Network.svg";
import VirtualMachineScalable from "../../images/resources/Virtual_Machine_Scale_Set.svg";
import ResourceGroup from "../../images/resources/Resource_Group.svg";

import all_container_icon from "../../images/all_container.png";
import all_kubernetes_icon from "../../images/all_kubernetes.png";
import all_cloudCompute_icon from "../../images/allCloudCompute.png";

import AzureLogo from "../../images/Azure_logo.svg";
import AwsLogo from "../../images/aws_dark.svg";
import TablePaginationActions from "../../components/TablePaginationActions";
import {TABLE_SORT} from "../../util/AppConstants";
import TableSortHeader from "../../components/TableSortHeader";
import {ALPHA_NUM_SORTER} from "../../util/Miscellaneous";
import TableSearch from "../../components/TableSearch";
import Ec2Icon from "../../images/EC2.svg";
import EBSIcon from "../../images/AWS_EBS_icon.svg";
const useStyles = makeStyles()((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
}));

const TABLE_SORT_DEFAULT_COLUMN = 'cost';

export default function AllCloudCompute({ period, currency, customerId }) {
    const dispatch = useDispatch();
    const {classes} = useStyles();
    const [progress, setProgress] = useState(0);
    const [selected, setSelected] = React.useState([]);
    const [list, setList] = useState(null);
    const [total, setTotal] = useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    let allCompute = useSelector((state) => state?.allComputeReducer?.allComputeData?.data?.SpendByCompute);
    let allContainers = useSelector((state) => state?.allComputeReducer?.allContainerData?.data?.SpendByContainers);
    let allKubernetes = useSelector((state) => state?.allComputeReducer?.allKubernetesData?.data?.SpendByKubernetes);

    let allCCKloading = useSelector((state) => state?.allComputeReducer?.allCCKloading);
    let allContainersloading = useSelector((state) => state?.allComputeReducer?.allContainersloading);
    let allKubernetesloading = useSelector((state) => state?.allComputeReducer?.allKubernetesloading);

    useEffect(() => {
        if (allCompute == null) {
            let dataType = "compute";
            dispatch(
                getAllComputeRequest({
                    customerId,
                    dataType,
                })
            );
        }
        if (allContainers == null) {
            let dataType = "containers";
            dispatch(
                getAllComputeRequest({
                    customerId,
                    dataType,
                })
            );
        }
        if (allKubernetes == null) {
            let dataType = "kubernetes";
            dispatch(
                getAllComputeRequest({
                    customerId,
                    dataType,
                })
            );
        }
    }, [customerId]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const [datePeriod, setDatePeriod] = React.useState(null);


    const [order, setOrder] = React.useState(TABLE_SORT.ORDER_ASC);
    const [orderBy, setOrderBy] = React.useState(TABLE_SORT_DEFAULT_COLUMN);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === TABLE_SORT.ORDER_ASC;
        setOrder(isAsc ? TABLE_SORT.ORDER_DESC : TABLE_SORT.ORDER_ASC);
        setOrderBy(property);
    };

    
    const [ec2Data,setEc2Data] = React.useState([]);
    const [ebsData,setEBSData] = React.useState([]);
    const [searchEc2, setSearchEc2] = useState(null);
    const [searchEbs, setSearchEbs] = useState(null);
    const [searchContainer, setSearchContainer] = useState(null);
    const [searchKubernetes, setSearchKubernetes] = useState(null);

    useEffect(()=>{
        let vol={}; 
        let i_data={};
        if(!allCompute) return
        Object.keys(allCompute).forEach(key=>{
            const data = allCompute[key];
            vol[key] = Object.assign({},data);
            i_data[key] = Object.assign({},data);
            vol[key]['resources'] = [];
            i_data[key]['resources'] = [];
            data?.resources?.forEach(r=>r.resourceName?.toString().startsWith('i-')||r.resourceType?.toString().endsWith('virtualMachines')||r.resourceType?.toString().endsWith('virtualMachineScaleSets')?i_data[key].resources.push(r):vol[key].resources.push(r))
            vol[key]['totalCost'] = vol[key].resources.map((r)=>parseFloat(parseFloat(r.cost).toPrecision(4))).reduce((a,b)=>(a)+(b), 0)
            i_data[key]['totalCost'] = i_data[key].resources.map((r)=>parseFloat(parseFloat(r.cost).toPrecision(4))).reduce((a,b)=>(a)+(b), 0)
        })   
          setEBSData(vol);
          setEc2Data(i_data);
        }, [allCompute]);


        const filterTableEc2 = (f)=> {
            if(searchEc2 && searchEc2?.toString().length){
                return !!TableSearch.doesItIncludes([
                    f?.resourceId,
                    f?.resourceName,
                    f?.cloudProvider,
                    f?.resourceType,
                    f?.location
                ], searchEc2);
            }
            return true;
        }
        const filterTableEbs = (f)=> {
            if(searchEbs && searchEbs?.toString().length){
                return !!TableSearch.doesItIncludes([
                    f?.resourceId,
                    f?.resourceName,
                    f?.cloudProvider,
                    f?.resourceType,
                    f?.location
                ], searchEbs);
            }
            return true;
        }
    const filterTableContainer = (f)=> {
        if(searchContainer && searchContainer.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.resourceName,
                f?.cloudProvider,
                f?.resourceType,
                f?.location
            ], searchContainer);
        }
        return true;
    }
    const filterTableKubernetes = (f)=> {
        if(searchKubernetes && searchKubernetes.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.resourceName,
                f?.cloudProvider,
                f?.resourceType,
                f?.location
            ], searchKubernetes);
        }
        return true;
    }

    return allCCKloading || allContainersloading || allKubernetesloading ? (
        <LinearProgress variant="determinate" value={progress} />
    ) : (
        <div>
            {/* <ContainerCardWithDateRange
                title={"Spend by Compute"}
                titleIcon={all_cloudCompute_icon}
                showDateRange={true}
                // ExtraHeaderComponent={({ period }) =>
                //     allCompute != null && allCompute?.[period]?.resources?.length ? (
                //         <div style={{ marginTop: 4 }}>
                //             <span>{moment(allCompute?.[period]?.startDate, "YYYYMMDD").format("MMM DD, YYYY")}</span> - <span>{moment(allCompute?.[period]?.endDate, "YYYYMMDD").format("MMM DD, YYYY")}</span>
                //         </div>
                //     ) : null
                // }
                collapsible={true}
                // MiddleComponent={({ period }) =>
                //     allCompute?.[period]?.totalCost && (
                //         <span style={{ display: "flex", justifyContent: "flex-end" }}>
                //             Total: {currency}
                //             {Format.formatNumber(allCompute?.[period]?.totalCost)}
                //         </span>
                //     )
                // }

                datePeriod={({ period }) => ({
                    rawStart: moment(allCompute?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(allCompute?.[period]?.endDate, "YYYYMMDD"),
                    start: "__",
                    end: "__",
                })}
                totalCost={({ period }) => currency + Format.formatNumber(
                    allCompute?.[period]?.resources?.filter(filterTable)
                        ?.filter(g=> {
                            if(g.resourceName.trim() && !g.resourceName.toString().toLowerCase().includes("vol") && !g.resourceName.toString().toLowerCase().includes("snap") && !g.resourceName.toString().toLowerCase().includes("nat")) {
                                return g;
                            }
                        })?.reduce((a,b)=>a+parseFloat(b.cost),0)
                )}
                datePeriodEnabled={true}
                showDatePeriod={(period)=>!!allCompute != null && allCompute?.[period]?.resources?.length}
                onPeriodChange={(period)=> {
                    setDatePeriod(period)
                    handleChangeRowsPerPage({target: {value: 10}})
                }}
            >
                {({ period }) => (
                    <div className={classes.root}  style={{ padding: 10 }}>
                        {allCompute != null && allCompute?.[period]?.resources?.length ? (
                            <Paper className={classes.paper}>
                                <CarousalCard cardTitle1={'Total cost'} cardTitle2={"Total Resources"}
                                              cost={allCompute?.[period]?.resources?.filter(filterTable)
                                                  ?.filter(g=> {
                                                      if(g.resourceName.trim() && !g.resourceName.toString().toLowerCase().includes("vol") && !g.resourceName.toString().toLowerCase().includes("snap") && !g.resourceName.toString().toLowerCase().includes("nat")) {
                                                          return g;
                                                      }
                                                  })?.reduce((a,b)=>a+parseFloat(b.cost),0)}
                                              res={allCompute?.[period]?.resources?.filter(filterTable)
                                                  ?.filter(g=> {
                                                      if(g.resourceName.trim() && !g.resourceName.toString().toLowerCase().includes("vol") && !g.resourceName.toString().toLowerCase().includes("snap") && !g.resourceName.toString().toLowerCase().includes("nat")) {
                                                          return g;
                                                      }
                                                  })?.length}

                                              currency={currency} />
                                <TableSearch onSearch={(e)=> {
                                    handleChangeRowsPerPage({ target: { value: 10 } });
                                    setSearchCompute(e)
                                }} />
                                <TableContainer style={{maxHeight:450}}>
                                    <Table className={classes.table} aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                        <AllComputeHead classes={classes} numSelected={selected.length}
                                                        totalCost={allCompute?.[period]?.totalCost} currency={currency}
                                                        order={order} orderBy={orderBy}
                                                        handleRequestSort={handleRequestSort}
                                                        enabled={true}
                                                        rowCount={allCompute?.[period]?.resources?.length}
                                        />
                                        <TableBody>
                                            {allCompute?.[period]?.resources
                                                ?.filter(filterTable)
                                                ?.sort((r1, r2) => {
                                                    const fi =  ({
                                                        cost:      (parseFloat(r2.cost) - parseFloat(r1.cost)),
                                                        id:        ALPHA_NUM_SORTER.compare(r1?.resourceName, r2?.resourceName),
                                                        account:   ALPHA_NUM_SORTER.compare(r1.cloudProvider, r2.cloudProvider),
                                                        type:      ALPHA_NUM_SORTER.compare(r1?.resourceType, r2?.resourceType),
                                                        location:  ALPHA_NUM_SORTER.compare(r1?.location,r2?.location),
                                                    }[orderBy]);
                                                    return fi * order;
                                                })?.filter(g=> {
                                                    if(g.resourceName.trim() && !g.resourceName.toString().toLowerCase().includes("vol") && !g.resourceName.toString().toLowerCase().includes("snap") && !g.resourceName.toString().toLowerCase().includes("nat")) {
                                                        return g;
                                                    }
                                                })
                                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                return <MyTableRow key={row?.subscriptionName + row?.subscriptionId + index} row={row} index={index} list={list} setList={setList} currency={currency} />;
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 75]}
                                    component="div"
                                    count={allCompute?.[period]?.resources?.filter(filterTable)
                                        ?.filter(g=> {
                                            if(g.resourceName.trim() && !g.resourceName.toString().toLowerCase().includes("vol") && !g.resourceName.toString().toLowerCase().includes("snap") && !g.resourceName.toString().toLowerCase().includes("nat")) {
                                                return g;
                                            }
                                    })?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </Paper>
                        ) : (
                            <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                        )}
                    </div>
                )}
            </ContainerCardWithDateRange>
            <br />
            {/* ----------------------------------------AWS containers-------------------------------------------------- */}

                 <ContainerCardWithDateRange
                    title={"Spend by EC2 /VM"}
                    titleIcon={Ec2Icon}
                    showDateRange={true}
                    datePeriod={({period})=>({
                        start:"__",
                        end: "__",
                        rawStart: moment(allCompute?.[period]?.startDate, "YYYYMMDD"),
                        rawEnd: moment(allCompute?.[period]?.endDate, "YYYYMMDD")
                    })}
                    totalCost={({period})=>(
                        currency + Format.formatNumber(ec2Data?.[period]?.totalCost)
                    )}
                    datePeriodEnabled={true}
                    showDatePeriod={(period)=>!!(ec2Data != null && ec2Data?.[period]?.resources?.length)}
                    collapsible={true}
                >
                    {({ period }) => (
                        <div className={classes.root}>
                            {ec2Data?.[period] != null && ec2Data?.[period]?.resources?.length ? (
                                <Paper className={classes.paper}>
                                    <CarousalCard cardTitle1={'Total cost'} cardTitle2={"Total Resources"} cost={ec2Data?.[period]?.totalCost} res={ec2Data?.[period]?.resources?.length} currency={currency} />

                                    <TableSearch onSearch={setSearchEc2} />
                                    <TableContainer style={{ height: 350 }}>
                                    <Table className={classes.table} aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                            <AllComputeHead classes={classes} numSelected={selected.length} rowCount={ec2Data.length} totalCost={ec2Data?.[period]?.totalCost} currency={currency} />
                                            <TableBody>
                                                {ec2Data?.[period]?.resources
                                                    ?.filter(filterTableEc2)
                                                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                    return <MyTableRow spendByEC2={true} row={row} index={index} list={list} setList={setList} currency={currency} />;
                                                })}
                                            </TableBody>
                                    </Table>
                                    </TableContainer>
                                    <TablePagination 
                                        ActionsComponent={TablePaginationActions}
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={ec2Data?.[period]?.resources?.filter(filterTableEc2)?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </Paper>
                            ) : (
                                <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                            )}
                        </div>
                    )}
                </ContainerCardWithDateRange>
                <br />
                <ContainerCardWithDateRange
                    title={"Spend by EBS /Disk"}
                    titleIcon={EBSIcon}
                    showDateRange={true}
                    datePeriod={({period})=>({
                        start:"__",
                        end: "__",
                        rawStart: moment(allCompute?.[period]?.startDate, "YYYYMMDD"),
                        rawEnd: moment(allCompute?.[period]?.endDate, "YYYYMMDD")
                    })}
                    totalCost={({period})=>(
                        currency + Format.formatNumber(ebsData?.[period]?.totalCost)
                    )}
                    datePeriodEnabled={true}
                    showDatePeriod={(period)=>!!(ebsData != null && ebsData?.[period]?.resources?.length)}
                    collapsible={true}
                >
                    {({ period }) => (
                        <div className={classes.root}>
                            {ebsData?.[period] != null && ebsData?.[period]?.resources?.length ? (
                                <Paper className={classes.paper}>
                                   <CarousalCard cardTitle1={'Total cost'} cardTitle2={"Total Resources"} cost={ebsData?.[period]?.totalCost} res={ebsData?.[period]?.resources?.length} currency={currency} />

                                    <TableSearch onSearch={setSearchEbs} />
                                    <TableContainer style={{ height: 350 }}>
                                        <Table className={classes.table} aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                            <AllComputeHead classes={classes} numSelected={selected.length} rowCount={ebsData.length} totalCost={ebsData?.[period]?.totalCost} currency={currency} />
                                            <TableBody>
                                                {ebsData?.[period]?.resources
                                                    ?.filter(filterTableEbs)
                                                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                    return <MyTableRow row={row} index={index} list={list} setList={setList} currency={currency} />;
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination ActionsComponent={TablePaginationActions}
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={ebsData?.[period]?.resources?.filter(filterTableEbs)?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </Paper>
                            ) : (
                                <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                            )}
                        </div>
                    )}
                </ContainerCardWithDateRange>
                <br />
                <ContainerCardWithDateRange
                    title={"Spend by Containers"}
                    titleIcon={all_container_icon}
                    showDateRange={true}
                    // ExtraHeaderComponent={({ period }) =>
                    //     allContainers != null && allContainers?.[period]?.resources?.length ? (
                    //         <div style={{ marginTop: 4 }}>
                    //             <span>{moment(allContainers?.[period]?.startDate, "YYYYMMDD").format("MMM DD, YYYY")}</span> - <span>{moment(allContainers?.[period]?.endDate, "YYYYMMDD").format("MMM DD, YYYY")}</span>
                    //         </div>
                    //     ) : null
                    // }
                    collapsible={true}
                    // MiddleComponent={({ period }) =>
                    //     allContainers?.[period]?.totalCost && (
                    //         <span style={{ display: "flex", justifyContent: "flex-end" }}>
                    //             Total: {currency}
                    //             {Format.formatNumber(allContainers?.[period]?.totalCost)}
                    //         </span>
                    //     )
                    // }
                    datePeriod={({ period }) => ({
                        start:"__",
                        end: "__",
                        rawStart: moment(allContainers?.[period]?.startDate, "YYYYMMDD"),
                        rawEnd: moment(allContainers?.[period]?.endDate, "YYYYMMDD"),
                    })}
                    totalCost={({ period }) => currency + Format.formatNumber(allContainers?.[period]?.totalCost)}
                    datePeriodEnabled={true}
                    showDatePeriod={(period) => !!allContainers != null && allContainers?.[period]?.resources?.length}
                >
                    {({ period }) => (
                        <div className={classes.root}>
                            {allContainers && allContainers?.[period]?.resources?.length ? (
                                <Paper className={classes.paper}>
                                <CarousalCard cardTitle1={'Total cost'} cardTitle2={"Total Resources"} cost={allContainers?.[period]?.totalCost} res={allContainers?.[period]?.resources?.length} currency={currency} />
                                <TableSearch onSearch={(e)=> {
                                        handleChangeRowsPerPage({ target: { value: 10 } });
                                        setSearchContainer(e)
                                    }} />
                                    <TableContainer style={{ height: 350 }}>
                                        <Table className={classes.table} aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                            <AllComputeHead classes={classes} numSelected={selected.length} rowCount={allContainers.length} totalCost={allContainers?.[period]?.totalCost} currency={currency} />
                                            <TableBody>
                                                {allContainers?.[period]?.resources
                                                ?.filter(filterTableContainer)
                                                    ?.sort((r1, r2) => {
                                                        const fi =  ({
                                                            cost:      (parseFloat(r2.cost) - parseFloat(r1.cost)),
                                                            id:        ALPHA_NUM_SORTER.compare(r1?.resourceName, r2?.resourceName),
                                                            account:   ALPHA_NUM_SORTER.compare(r1.cloudProvider, r2.cloudProvider),
                                                            type:      ALPHA_NUM_SORTER.compare(r1?.resourceType, r2?.resourceType),
                                                            location:  ALPHA_NUM_SORTER.compare(r1?.location,r2?.location),
                                                        }[orderBy]);
                                                        return fi * order;
                                                    })?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                    return <MyTableRow row={row} index={index} list={list} setList={setList} currency={currency} />;
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={allContainers?.[period]?.resources?.filter(filterTableContainer)?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </Paper>
                            ) : (
                                <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                            )}
                        </div>
                    )}
                </ContainerCardWithDateRange>
                <br /> 

            {/* ------------------------------------------------AWS Kubernetes-------------------------------------------- */}
            <ContainerCardWithDateRange
                title={"Spend by Kubernetes"}
                titleIcon={all_kubernetes_icon}
                showDateRange={true}
                // ExtraHeaderComponent={({ period }) =>
                //     allKubernetes != null && allKubernetes?.[period]?.resources?.length ? (
                //         <div style={{ marginTop: 4 }}>
                //             <span>{moment(allKubernetes?.[period]?.startDate, "YYYYMMDD").format("MMM DD, YYYY")}</span> - <span>{moment(allKubernetes?.[period]?.endDate, "YYYYMMDD").format("MMM DD, YYYY")}</span>
                //         </div>
                //     ) : null
                // }
                collapsible={true}
                // MiddleComponent={({ period }) =>
                //     allKubernetes?.[period]?.totalCost && (
                //         <span style={{ display: "flex", justifyContent: "flex-end" }}>
                //             Total: {currency}
                //             {Format.formatNumber(allKubernetes?.[period]?.totalCost)}
                //         </span>
                //     )
                // }
                datePeriod={({ period }) => ({
                    start:"__",
                    end: "__",
                    rawStart: moment(allKubernetes?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(allKubernetes?.[period]?.endDate, "YYYYMMDD"),
                })}
                totalCost={({ period }) => currency + Format.formatNumber(allKubernetes?.[period]?.totalCost)}
                datePeriodEnabled={true}
                showDatePeriod={(period) => !!allKubernetes != null && allKubernetes?.[period]?.resources?.length}
            >
                {({ period }) => (
                    <div className={classes.root}>
                        {allKubernetes && allKubernetes?.[period]?.resources?.length ? (
                            <Paper className={classes.paper}>
                             <CarousalCard cardTitle1={'Total cost'} cardTitle2={"Total Resources"} cost={allKubernetes?.[period]?.totalCost} res={allKubernetes?.[period]?.resources?.length} currency={currency} />
                             <TableSearch onSearch={(e)=> {
                                    handleChangeRowsPerPage({ target: { value: 10 } });
                                    setSearchKubernetes(e)
                                }} />
                                <TableContainer style={{ height: 350 }}>
                                    <Table className={classes.table} aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                        <AllComputeHead classes={classes} numSelected={selected.length} rowCount={allKubernetes.length} totalCost={allKubernetes?.[period]?.totalCost} currency={currency} />
                                        <TableBody>
                                            {allKubernetes?.[period]?.resources?.filter(filterTableKubernetes)
                                                ?.sort((r1, r2) => {
                                                    const fi =  ({
                                                        cost:      (parseFloat(r2.cost) - parseFloat(r1.cost)),
                                                        id:        ALPHA_NUM_SORTER.compare(r1?.resourceName, r2?.resourceName),
                                                        account:   ALPHA_NUM_SORTER.compare(r1.cloudProvider, r2.cloudProvider),
                                                        type:      ALPHA_NUM_SORTER.compare(r1?.resourceType, r2?.resourceType),
                                                        location:  ALPHA_NUM_SORTER.compare(r1?.location,r2?.location),
                                                    }[orderBy]);
                                                    return fi * order;
                                                })?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                return <MyTableRow row={row} index={index} list={list} setList={setList} currency={currency} />;
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 75]}
                                    component="div"
                                    count={allKubernetes?.[period]?.resources?.filter(filterTableKubernetes)?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </Paper>
                        ) : (
                            <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                        )}
                    </div>
                )}
            </ContainerCardWithDateRange>
        </div>
    );
}

const useStyles22 = makeStyles()((theme) => ({
    root: {
        backgroundColor: Color.tableHeader,
        padding: theme.spacing(0.5),
    },

    tableRow: {
        fontWeight: "bold",

    },
}));

function AllComputeHead({ totalCost, currency = "", order, orderBy, handleRequestSort, rowCount, enabled }) {

    const {classes} = useStyles22();

    return (
        <TableHead className={classes.root}>
            <TableRow>
                <TableCell></TableCell>
            <TableSortHeader
                classes={classes}
                numSelected={1
                    // ||
                    //selected.length
                }
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rowCount}
                headCells={[
                    { numeric:0, id:'id', label: 'Resource name'},
                    { numeric:0, id:'account', label: 'Cloud/Account/Subscription'},
                    { enabled: !enabled, numeric:0, id:'type', label: 'Resource type'},
                    { enabled: !enabled, numeric:0, id:'location', label: 'Location'},
                    { enabled: !enabled, numeric:1, id:'cost', label: 'Cost'},
                ]}
            />
                {/*<TableCell className={classes.tableRow}>Resource name</TableCell>*/}
                {/*<TableCell className={classes.tableRow}>Cloud</TableCell>*/}
                {/*<TableCell className={classes.tableRow}>Resource Type</TableCell>*/}
                {/*<TableCell className={classes.tableRow}>Location</TableCell>*/}
                {/*<TableCell className={classes.tableRow}>Cost</TableCell>*/}
            </TableRow>
        </TableHead>
    );
}

function MyTableRow({ row, index, list, setList, currency }) {
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState("");

    useEffect(() => {
        if (row) {
            setDate(moment(row.date, "YYYYMMDD").format("MMM DD, YYYY"));
        }
    }, [row]);

    return (
        <React.Fragment>
            <TableRow hover key={row.date}>
                <TableCell>
                    <IconButton
                        style={{ cursor: "pointer" }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {/* <TableCell component="th" scope="row" padding="none">
          {date}
        </TableCell> */}

                <TableCell align="left">
                    <div>
                        {" "}
                        {row.resourceType == "Relational Database Services" && <img src={AmazonRDS} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType == "Elastic Load Balancer" && <img src={AwsELC} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType == "Amazon Elastic Compute Cloud" && <img src={AwsEC2} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType == "Amazon Simple Storage" && <img src={AmazonS3} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType == "Amazon Route 53" && <img src={AmazonRoute53} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType == "Amazon Cloud Front" && <img src={AmazonCloudFront} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType == "Amazon Api Gateway" && <img src={AmazonApiGateway} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType == "AWS Secrets Manager" && <img src={AWSSecretmanager} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType == "AWS Config" && <img src={AWSConfig} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType == "Amazon CloudWatch" && <img src={AmazonCloudWatch} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType.split("/")[1] == "b2cdirectories" && <img src={Adb2c} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType.split("/")[1] == "workspaces" && <img src={WorkSpaces} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType.split("/")[1] == "serverfarms" && <img src={ServerFarms} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType.split("/")[1] == "loadbalancers" && <img src={LoadBalance} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType.split("/")[1] == "publicipaddresses" && <img src={PublicIpAddress} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType.split("/")[1] == "redis" && <img src={Redis} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType.split("/")[1] == "disks" && <img src={Disk} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType.split("/")[1] == "namespaces" && <img src={NameSpace} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType.split("/")[1] == "iothubs" && <img src={IotHub} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType.split("/")[1] == "applicationgateways" && <img src={ApplicationGateway} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType.split("/")[1] == "profiles" && <img src={Profile} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType.split("/")[1] == "vaults" && <img src={KeyValut} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType.split("/")[1] == "virtualmachinescalesets" && <img src={VirtualMachineScalable} width={30} height={30} style={{ marginRight: 4 }} />}
                        {row?.resourceType.split("/")[1] == "virtualmachines" && <img src={VirtualMachine} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row.resourceType.split("/")[1] == "workflows" && <img src={WorkFlow} width={16} height={16} style={{ marginRight: 4 }} />}
                        {row?.resourceName}
                    </div>
                </TableCell>

                {/* <TableCell>
                    <div>
                        {row.cloudProvider == "azure" && <div> <img src={AzureLogo}  height={22} style={{ marginRight: 4 }} /> {row?.subscriptionId} </div>
                        {row.cloudProvider == "aws" && <div> <img src={AwsLogo}  height={22} style={{ marginRight: 4 }} /> {row?.subscriptionId} </div>
                        
                    </div>
                </TableCell> */}
                <TableCell align="left" style={{ whiteSpace: "nowrap" }}>
                    {row?.cloudProvider == "azure" ? (
                        <OverlayTrigger placement="bottom" overlay={<Popover id="popover-contained">{"subscriptionName" in row && <Popover.Body style={{ fontSize: 11 }}>{row?.subscriptionId}</Popover.Body>}</Popover>}>
                            <div>
                                <img src={AzureLogo} height={22} /> {row?.subscriptionName || row?.subscriptionId}
                            </div>
                        </OverlayTrigger>
                    ) : (
                        <OverlayTrigger placement="bottom" overlay={<Popover id="popover-contained">{"subscriptionName" in row && <Popover.Body style={{ fontSize: 11 }}>{row?.subscriptionId}</Popover.Body>}</Popover>}>
                            <div>
                                <img src={AwsLogo} height={22} /> {row?.subscriptionName || row?.subscriptionId}{" "}
                            </div>
                        </OverlayTrigger>
                    )}
                </TableCell>

                <TableCell align="left">{row?.resourceType== "Amazon Elastic Compute Cloud" ? 'Amazon EC2':row?.resourceType?.split("/")[1]} </TableCell>
                <TableCell align="left" style={{ whiteSpace: "nowrap" }}>{row?.location}</TableCell>

                <TableCell align="right">
                    {currency}
                    {Format.formatNumber(Number(row.cost))}
                </TableCell>
            </TableRow>
            <ExpandLavelData data={row} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}


export function ExpandLavelData({ data, open, select, set, currency }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2.5}>
                            <Htmltable data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <div style={{ maxHeight: 300 }} className="overflow-auto">
            <table id="customers">
                {Object.keys(data?.meters?.[0])?.length > 3 ? (
                    <tr>
                        <th>Operation</th>
                        <th>Product family</th>
                        <th>Description</th>
                        <th>Usage Type</th>
                        <th>Total</th>
                    </tr>
                ) : (
                    <tr>
                        <th>Service name</th>
                        <th>Meter name</th>
                        <th>Total</th>
                    </tr>
                )}

                {data &&
                    data?.meters
                        ?.sort((a, b) => {
                            return parseFloat(b?.cost || 0) - parseFloat(a?.cost || 0);
                        })
                        .map((m) =>
                            Object.keys(m)?.length > 3 ? (
                                <tr>
                                    <td>{m?.operation||'-'}</td>
                                    <td>{m?.productFamily||'-'}</td>
                                    <td>{m.lineItemDescription||'-'}</td>
                                    <td>{m.lineItemUsageType||'-'}</td>

                                    <td>{currency + Format.formatNumber(Number(m?.cost||0))}</td>
                                </tr>
                            ) : (
                                <tr>
                                    <td>{m.serviceName||'-'}</td>
                                    <td>{m?.meterName||'-'}</td>

                                    <td>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                                </tr>
                            )
                        )}
            </table>
        </div>
    );
}
