import moment from "moment";

// const { moment } = require("../config/requireConfig").requireConfig;
let lastMonthStartDate = moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
let lastMonthEndDate = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");
let startDateOfMonth = moment().startOf("month").format("YYYY-MM-DD");
let endDateOfMonth = moment().endOf("month").format("YYYY-MM-DD");
let currentDate = moment(new Date()).format("YYYY-MM-DD");
let yesterdayDateRange = moment(new Date()).subtract(1, "days").format("YYYY-MM-DD");
let oneWeekDateRange = moment(new Date()).subtract(1, "weeks").format("YYYY-MM-DD");
let oneMonthDateRange = moment(new Date()).subtract(1, "months").format("YYYY-MM-DD");
let threeMonthDateRange = moment(new Date()).subtract(3, "months").format("YYYY-MM-DD");
let sixMonthDateRange = moment(new Date()).subtract(6, "months").format("YYYY-MM-DD");
let oneYearDateRange = moment(new Date()).subtract(1, "years").format("YYYY");
let lastTwoYearRange = [moment(new Date()).format("YYYY"), oneYearDateRange];

const exports = {
    lastTwoYearRange: () => [...new Set(lastTwoYearRange)],

    lastMonthStartDate: () => lastMonthStartDate,

    lastMonthEndDate: () => lastMonthEndDate,

    startDateOfMonth: () => startDateOfMonth,

    endDateOfMonth: () => endDateOfMonth,

    currentDate: () => currentDate,

    yesterdayDateRange: () => yesterdayDateRange,

    oneWeekDateRange: () => oneWeekDateRange,

    oneMonthDateRange: () => oneMonthDateRange,

    threeMonthDateRange: () => threeMonthDateRange,

    sixMonthDateRange: () => sixMonthDateRange,

    oneYearDateRange: () => oneYearDateRange,

    dateCreated: () => new Date().getTime(),

    lastModified: () => new Date().getTime(),
};

export default exports;
