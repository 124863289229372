import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import active_directory from "../../images/active_directory.svg";
import Refresh from "../../images/refresh.svg";
import key from "../../images/key.svg";
import error from "../../images/error.svg";
import inactiveIMG from "../../images/inactive.svg";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { getConnectionErrorRequest } from "../../redux/actions/actions";
import ConnectionError from "../../components/ConnectionError";

import {
    Button,
    Paper,
    Grid,
    LinearProgress,
    TableBody,
    //   Table,
    //   ListGroup,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Collapse,
    Box,
    Typography,
    Divider,
    TextField,
    CircularProgress,
} from "@mui/material";
import Alert from "@mui/lab/Alert";
import { Card, ListGroup, Modal, Toast, Table, Tooltip, OverlayTrigger, Popover, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import RoutesList from "../../routes/RoutesList";
import axios from "../../connection/axios";
import SubRowTable from "../../components/SubRowTable";
import Azure_logo from "../../images/Azure_logo.svg";
import AWS_LOGO from "../../images/aws_dark.svg";
import GCP_ICON from "../../images/GCP_logo.svg";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import Colors from "../../styles/color";
import VerfiedImage from "../../images/verified.svg";
import { json } from "d3-fetch";
import IconButton from "@mui/material/IconButton";
import gcp_billing from "../../images/gcp_billing.svg";
import gcp_project from "../../images/gcp_project.svg";
import ListAWSAccount from "../../pages/Accounts/aws/ListAWSAccount";
import { Dialog, DialogContent } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MuiDialogTitle from "@mui/material/DialogTitle";
import ReactPlayer from "react-player";
import { makeStyles, withStyles } from "MakeWithStyles";
import CloseIcon from "@mui/icons-material/Close";
import color from "../../styles/color";
import colors from "../../styles/color";
import { EditSharp } from "@mui/icons-material";
import LinkText from "../../components/LinkText";
import Snackbar from "@mui/material/Snackbar";

// dialog box
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

function Dialogtitle2(props){
    const { children, classes, onClose, ...other } = props;
    // const classes = withStyles.getClasses(props);

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
}

const DialogTitle = withStyles(Dialogtitle2, styles);

const { REACT_APP_LINKED_ACCOUNTS, REACT_APP_MSAL_AZURE_CONNECTION, REACT_APP_ENABLE_DISABLE_SUBSCRIPTION, REACT_APP_ADD_AND_VERIFY, REACT_APP_GCP_EDIT_ACCOUNT } = process.env;

const azureConnectionVideo = process.env.REACT_APP_VIDEO_AZURE_SETUP;
const awsConnectionVideo = process.env.REACT_APP_VIDEO_AWS_SETUP;
const gcpConnectionVideo = process.env.REACT_APP_VIDEO_GCP_SETUP;

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        padding: 10,
        background: Colors.background,
        minHeight: "95vh",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    table: {
        minWidth: 650,
    },
    btnStyle: {
        background: "linear-gradient(45deg, #3f51b5 50%, #3f51b5 100%)",
        border: 0,
        borderRadius: 3,
        boxShadow: "0 3px 5px 2px rgba(63, 81, 181, .3)",
        color: "white",
        height: 40,
        padding: "0 40px",
        textTransform: "none",
    },
    tabPanel: {
        padding: theme.spacing(-10),
    },
    labelContainer: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
    },

    tab: {
        fontWeight: "bold",
        textTransform: "none",
        outline: "none",
    },
}));

export default function NewLinkAccounts() {
    const {classes} = useStyles();
    // dialog box
    const [maxWidth, setMaxWidth] = useState("xl");
    const [opens, setOpens] = useState(false);
    const [azure, setAzure] = useState(false);
    const [aws, setAws] = useState(false);
    const [gcp, setGcp] = useState(false);

    const handleClickOpen = (cloud) => {
        if (cloud == "azure") {
            setAzure(true);
        } else if (cloud == "aws") {
            setAws(true);
        } else {
            setGcp(true);
        }
        setOpens(true);
    };

    const handleCloses = () => {
        setAzure(false);
        setAws(false);
        setGcp(false);
        setOpens(false);
    };

    const dispatch = useDispatch();

    const { customerId, name, email, role } = useSelector((state) => ({
        customerId: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
        email: state.userDetailsReducer.email,
        name: state.userDetailsReducer.name,
        role: state.userDetailsReducer.role,
    }));
    const [awsAccounts, setAwsAccounts] = useState([]);
    const [azureAccounts, setAzureAccounts] = useState([]);
    const [gcpAccounts, setGcpAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const [modelData, setModelData] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [open, setOpen] = React.useState(false);
    const [value, setTabValue] = React.useState("1");
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const AzureConnectionError = useSelector((state) => ({
        connectionError: state.connectionErrorReducer?.connections?.[0]?.azure?.tenant,
    }));

    const AWSConnectionError = useSelector((state) => ({
        connectionError: state.connectionErrorReducer?.connections?.[0]?.aws?.tenant,
    }));

    const GCPConnectionError = useSelector((state) => ({
        connectionError: state.connectionErrorReducer?.connections?.[0]?.gcp?.tenant,
    }));

    async function getLinkedAccounts() {
        setLoading(true);

        customerId &&
            (await axios
                .get(REACT_APP_LINKED_ACCOUNTS, {
                    params: { customerId },
                })
                .then((response) => response.data)
                .then((response) => {
                    // alert(JSON.stringify(response?.response?.aws))
                    setAwsAccounts(response?.response?.aws);
                    setAzureAccounts(response?.response?.azure);
                    setGcpAccounts(response?.response?.gcp);
                })
                .finally(() => setLoading(false))
                .catch((e) => {
                    // console.log(e);
                }));
    }

    useEffect(() => {
        const hash = window.location.hash;
        if (hash === "#aws") {
            setTabValue("2");
        } else if (hash === "#gcp") {
            setTabValue("3");
        }
    }, []);

    useEffect(() => {
        dispatch(getConnectionErrorRequest(customerId));
        getLinkedAccounts();
    }, [customerId]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div>
            {loading ? (
                <div>
                    <LinearProgress variant="determinate" value={progress} />
                </div>
            ) : (
                <div className={classes.root}>
                    {AzureConnectionError?.connectionError &&
                        AzureConnectionError?.connectionError?.map((r, i) => <ConnectionError account={"linked"} row={r} cloud={r.accountType} tenantName={r.tenantName} subscription={r.tenantId} status={r.status} errorDetail={r} />)}
                    {AWSConnectionError?.connectionError &&
                        AWSConnectionError?.connectionError?.map((r, i) => <ConnectionError account={"linked"} row={r} cloud={r.accountType} subscription={r.tenantId} status={r.status} errorDetail={r?.errors?.[0]?.message} />)}
                    {GCPConnectionError?.connectionError &&
                        GCPConnectionError?.connectionError?.map((r, i) => <ConnectionError account={"linked"} row={r} cloud={r.accountType} subscription={r.tenantId} status={r.status} errorDetail={r?.errors?.errors?.[0]?.message} />)}
                    <Grid container spacing={1} className={'connection_buttons'}>
                        <Grid item xs={6}>
                            <div
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <AccountBalanceOutlinedIcon
                                    style={{
                                        marginTop: -8,
                                        marginRight: 4,
                                        width: 26,
                                        height: 26,
                                    }}
                                />
                                <h4>Accounts</h4>
                            </div>
                        </Grid>

                        <Grid item xs={2}>
                            {/* <Link to={RoutesList.AZURE_CONNECT} style={{ pointerEvents: role == "Owner" || role == null ? "initial" : "none" }}> */}
                            <Button
                                onClick={deviceFlowApi}
                                style={{
                                    backgroundColor: "white",
                                    fontWeight: "bold",
                                    textDecoration: "none",
                                    textTransform: "none",
                                }}
                                variant="outlined"
                                disabled={role == "Owner" || role == null ? false : true}
                            >
                                <img
                                    style={{
                                        padding: 6,
                                        borderRadius: 2,
                                    }}
                                    src={Azure_logo}
                                    height={28}
                                />
                                Connect Azure
                            </Button>

                            <div style={{ display: "flex", alignItems: "center", marginLeft: "50px" }}>
                                <OverlayTrigger placement="left" overlay={<Tooltip id="button-tooltip">Click to open help file</Tooltip>}>
                                    <a target="_blank" href="https://docs.cloudtrakr.com/SetupAzureConnection/">
                                        <HelpOutlineIcon />
                                    </a>
                                </OverlayTrigger>
                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">How to Setup Azure Connection</Tooltip>}>
                                    <IconButton onClick={() => handleClickOpen("azure")} style={{ outline: "none" }}>
                                        <PlayCircleOutlineIcon />
                                    </IconButton>
                                </OverlayTrigger>
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <Link to={RoutesList.AWS_CONNECT} style={{ pointerEvents: role == "Owner" || role == null ? "initial" : "none" }} className={'connection_buttons'}>
                                <Button
                                    style={{
                                        backgroundColor: "white",
                                        fontWeight: "bold",
                                        textDecoration: "none",
                                        textTransform: "none",
                                    }}
                                    variant="outlined"
                                    disabled={role == "Owner" || role == null ? false : true}
                                    className={'connection_buttons'}
                                >
                                    <img
                                        style={{
                                            padding: 6,
                                            borderRadius: 2,
                                            width: 28,
                                            height: 28,
                                        }}
                                        src={AWS_LOGO}
                                    />
                                    Connect AWS
                                </Button>
                            </Link>
                            <div style={{ display: "flex", alignItems: "center", marginLeft: "50px" }}>
                                <OverlayTrigger placement="left" overlay={<Tooltip id="button-tooltip">Click to open help file</Tooltip>}>
                                    <a target="_blank" href="https://docs.cloudtrakr.com/SetupAwsConnection/">
                                        <HelpOutlineIcon />
                                    </a>
                                </OverlayTrigger>
                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">How to Setup AWS Connection</Tooltip>}>
                                    <IconButton onClick={() => handleClickOpen("aws")} style={{ outline: "none" }}>
                                        <PlayCircleOutlineIcon />
                                    </IconButton>
                                </OverlayTrigger>
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <Link to={RoutesList.GCP_CONNECT} style={{ pointerEvents: role == "Owner" || role == null ? "initial" : "none" }} className={'connection_buttons'}>
                                <Button
                                    style={{
                                        backgroundColor: "white",
                                        fontWeight: "bold",
                                        textDecoration: "none",
                                        textTransform: "none",
                                    }}
                                    variant="outlined"
                                    disabled={role == "Owner" || role == null ? false : true}
                                    className={'connection_buttons'}
                                >
                                    <img
                                        style={{
                                            padding: 6,
                                            borderRadius: 2,
                                        }}
                                        src={GCP_ICON}
                                    />
                                    Connect GCP
                                </Button>
                            </Link>
                            <div style={{ display: "flex", alignItems: "center", marginLeft: "50px" }}>
                                <OverlayTrigger placement="left" overlay={<Tooltip id="button-tooltip">Click to open help file</Tooltip>}>
                                    <a target="_blank" href="https://docs.cloudtrakr.com/SetupGcpConnection/">
                                        <HelpOutlineIcon />
                                    </a>
                                </OverlayTrigger>
                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">How to Setup GCP Connection</Tooltip>}>
                                    <IconButton onClick={() => handleClickOpen("gcp")} style={{ outline: "none" }}>
                                        <PlayCircleOutlineIcon />
                                    </IconButton>
                                </OverlayTrigger>
                            </div>
                        </Grid>
                        <Dialog onClose={handleCloses} aria-labelledby="customized-dialog-title" open={opens} maxWidth={maxWidth}>
                            <DialogTitle id="customized-dialog-title" onClose={handleCloses}>
                                {azure ? "Setup Azure Connection" : aws ? "Setup AWS Connection" : gcp ? "Setup GCP Connection" : null}
                            </DialogTitle>
                            <DialogContent dividers>
                                <ReactPlayer
                                    url={azure ? azureConnectionVideo : aws ? awsConnectionVideo : gcpConnectionVideo}
                                    controls
                                    playing
                                    muted
                                    config={{
                                        file: {
                                            attributes: {
                                                autoPlay: true,
                                                muted: true,
                                            },
                                        },
                                    }}
                                />
                            </DialogContent>
                        </Dialog>
                    </Grid>

                    <TabContext value={value}>
                        <div
                            style={{
                                backgroundColor: Colors.background,
                            }}
                            position="static"
                        >
                            <TabList onChange={handleChange} aria-label="simple tabs example" textColor="primary" indicatorColor="primary">
                                <Tab
                                    style={stylestab.tab}
                                    label={
                                        <div className={classes.labelContainer}>
                                            <img style={stylestab.icon} src={Azure_logo} />
                                            Azure
                                        </div>
                                    }
                                    value="1"
                                    className={classes.tab}
                                />
                                <Tab
                                    style={stylestab.tab}
                                    label={
                                        <div className={classes.labelContainer}>
                                            <img style={stylestab.icon} src={AWS_LOGO} />
                                            AWS
                                        </div>
                                    }
                                    value="2"
                                    className={classes.tab}
                                />
                                <Tab
                                    style={stylestab.tab}
                                    label={
                                        <div className={classes.labelContainer}>
                                            <img style={stylestab.icon} src={GCP_ICON} />
                                            GCP
                                        </div>
                                    }
                                    value="3"
                                    className={classes.tab}
                                />
                            </TabList>
                        </div>
                        <Divider />

                        <TabPanel value="1" className={'connection_buttons'}>
                            <AzureAccountList data={azureAccounts} addAndVerifySubscription={addAndVerifySubscription} setSwitchValue={setSwitchValue} setModelData={setModelData} handleShow={handleShow} role={role} />
                        </TabPanel>
                        <TabPanel value="2" className={'connection_buttons'}>
                            <ListAWSAccount customerId={customerId} role={role} />
                        </TabPanel>
                        <TabPanel value="3" className={'connection_buttons'}>
                            <GcpAccountList data={gcpAccounts} customerId={customerId} onUpdate={getLinkedAccounts} role={role} />
                        </TabPanel>
                    </TabContext>

                    <div> </div>
                </div>
            )}

            <LinkUnlink show={show} data={modelData} handleClose={handleClose} setSwitchValue={setSwitchValue} />
        </div>
    );

    function setSwitchValue(isEnabled, id) {
        isEnabled === true ? enableAndDisableSubscriptions(id, false) : enableAndDisableSubscriptions(id, true);
        handleClose();
    }

    function enableAndDisableSubscriptions(subID, status) {
        setLoading(true);
        axios
            .get(REACT_APP_ENABLE_DISABLE_SUBSCRIPTION, {
                params: {
                    customerID: customerId,
                    subscriptionID: subID,
                    isEnabled: status,
                },
            })
            .then((response) => response.data)
            .then((responseJson) => {
                if ("statusCode" in responseJson) {
                    if (responseJson.statusCode == 200) {
                        getLinkedAccounts();
                    }
                }
                return responseJson;
            })
            .finally(() => setLoading(false))
            .catch((error) => {
                console.error(error);
            });
    }

    function addAndVerifySubscription(tenantID) {
        setLoading(true);
        axios
            .get(REACT_APP_ADD_AND_VERIFY, {
                params: {
                    customerID: customerId,
                    tenantID: tenantID,
                },
            })
            .then((response) => response.data)
            .then((responseJson) => {
                if ("statusCode" in responseJson) {
                    if (responseJson.statusCode == 200) {
                        getLinkedAccounts();
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoading(false));
    }

    function deviceFlowApi() {
        setLoading(true);
        axios
            .get(REACT_APP_MSAL_AZURE_CONNECTION, {
                params: {
                    customerID: customerId,
                },
            })
            .then((response) => response.data)
            .then((responseJson) => {
                if ("response" in responseJson) {
                    window.open(responseJson.response.url, "_self");
                }

                return responseJson;
            })
            .finally(() => setLoading(false))
            .catch((error) => {
                console.error(error);
            });
    }
}

function LinkUnlink({ data, show, handleClose, setSwitchValue }) {
    return (
        <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Unlink Azure Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you want to Unlink the your Azure "{data?.subName}" subscription?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    No
                </Button>
                <Button onClick={() => setSwitchValue(data.isEnabled, data.subscriptionId)} variant="primary">
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const stylestab = {
    tab: {
        outline: "none",
        textDecoration: "none",
    },
    labelContainer: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        marginBottom: -10,
        marginLeft: -15,
    },

    icon: { width: 18, height: 18, marginRight: 4 },
};

const useStyles22 = makeStyles()((theme) => ({
    root: {
        backgroundColor: Colors.tableHeader,
        padding: theme.spacing(0.2),
    },

    tableRow: {
        fontWeight: "normal",
    },
}));

function AzureAccountList({ data = null, addAndVerifySubscription, setSwitchValue, setModelData, handleShow, role }) {

    const {classes} = useStyles22();

    return (
        <div className={'connection_buttons'}>
            {data && data.length ? (
                data.map((item, index) => (
                    <Paper elevation={3} style={{ margin: 8 }}>
                        <div>
                            <div
                                style={{
                                    padding: 8,
                                    backgroundColor: Colors.tableHeader,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>
                                    <img
                                        src={active_directory}
                                        alt="Active Directory"
                                        style={{
                                            width: 25,
                                            height: 25,
                                            marginRight: 4,
                                            padding: 3,
                                        }}
                                    />
                                    {item.tenantName}
                                    {"  "} ({item.tenantId}){"  "}
                                </div>
                                {/* <OverlayTrigger placement="left" overlay={<Tooltip id="button-tooltip">Click to update your subscriptions</Tooltip>}>
                                    <div>
                                        <span style={{ color: role == "Owner" || role == null ? "blue" : "gray", marginRight: 8 }}>Update subscriptions</span>
                                        <img
                                            onClick={() => (role == "Owner" || role == null ? addAndVerifySubscription(item.tenantId) : null)}
                                            src={Refresh}
                                            alt="refresh"
                                            style={{
                                                cursor: role == "Owner" || role == null ? "pointer" : "default",
                                                width: 20,
                                                height: 20,
                                                marginRight: 12,
                                            }}
                                        />
                                    </div>
                                </OverlayTrigger> */}
                            </div>

                            <TableContainer>
                                <Table className={classes.table} aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                    <TableHead className={classes.root}>
                                        <TableRow>
                                            <TableCell className={classes.tableRow}>Subscription name</TableCell>
                                            <TableCell className={classes.tableRow}>Subscription Id</TableCell>
                                            <TableCell className={classes.tableRow}>Status</TableCell>
                                            {/*<TableCell className={classes.tableRow}>Enable/Disable</TableCell>*/}
                                        </TableRow>
                                    </TableHead>
                                    {item.subscriptions && item.subscriptions.length ? (
                                        item.subscriptions?.map((item) => (
                                            <React.Fragment>
                                                <TableRow
                                                    style={{
                                                        backgroundColor: item.status == "unauthorized" ? "#ffcccc" : null,
                                                    }}
                                                    hover
                                                    key={item.subscriptionId}
                                                >
                                                    <TableCell>
                                                        <img
                                                            src={key}
                                                            alt="key"
                                                            style={{
                                                                width: 25,
                                                                height: 25,
                                                                marginRight: 4,
                                                                padding: 3,
                                                            }}
                                                        />
                                                        {item.subName}
                                                    </TableCell>
                                                    <TableCell>{item.subscriptionId}</TableCell>
                                                    <TableCell>
                                                        {" "}
                                                        {item.status == "active" ? (
                                                            <div>
                                                                <span>Active</span>
                                                                <img
                                                                    src={VerfiedImage}
                                                                    alt="VerfiedImage"
                                                                    style={{
                                                                        width: 26,
                                                                        height: 26,
                                                                        marginRight: 4,
                                                                        padding: 3,
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : item.status == "unauthorized" ? (
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={
                                                                    <Popover id="popover-contained">
                                                                        <Popover.Title as="h3">
                                                                            <img
                                                                                src={error}
                                                                                alt="error"
                                                                                style={{
                                                                                    width: 26,
                                                                                    height: 26,
                                                                                    marginRight: 4,
                                                                                    padding: 3,
                                                                                }}
                                                                            />
                                                                            {item?.status}
                                                                        </Popover.Title>
                                                                        <Popover.Body>
                                                                            {typeof item?.error_msg == "object" ? (typeof item?.error_msg?.error_desc == "object" ? item?.error_msg?.error_desc?.message : item?.error_msg?.error_desc) : null}
                                                                        </Popover.Body>
                                                                    </Popover>
                                                                }
                                                            >
                                                                <div>
                                                                    <span>{item.status}</span>
                                                                    <img
                                                                        src={error}
                                                                        alt="error"
                                                                        style={{
                                                                            width: 26,
                                                                            height: 26,
                                                                            marginRight: 4,
                                                                            padding: 3,
                                                                        }}
                                                                    />{" "}
                                                                </div>
                                                            </OverlayTrigger>
                                                        ) : item.status == "inactive" && item.isEnabled == false ? (
                                                            <div>
                                                                <span>{item.status}</span>
                                                                <img
                                                                    src={inactiveIMG}
                                                                    alt="error"
                                                                    style={{
                                                                        width: 28,
                                                                        height: 28,
                                                                        marginRight: 4,
                                                                        padding: 3,
                                                                    }}
                                                                />{" "}
                                                            </div>
                                                        ) : null}
                                                    </TableCell>
                                                    {/*<TableCell>*/}
                                                    {/*    {" "}*/}
                                                    {/*    {item.status !== "unauthorized" ? (*/}
                                                    {/*        <Switch*/}
                                                    {/*            height={26}*/}
                                                    {/*            checked={item.isEnabled === true}*/}
                                                    {/*            onChange={() => {*/}
                                                    {/*                if (item.isEnabled === true) {*/}
                                                    {/*                    setModelData({*/}
                                                    {/*                        isEnabled: item.isEnabled,*/}
                                                    {/*                        subscriptionId: item.subscriptionId,*/}
                                                    {/*                        subName: item.subName,*/}
                                                    {/*                    });*/}
                                                    {/*                    handleShow();*/}
                                                    {/*                } else {*/}
                                                    {/*                    setSwitchValue(item.isEnabled, item.subscriptionId);*/}
                                                    {/*                }*/}
                                                    {/*            }}*/}
                                                    {/*            disabled={role == "Owner" || role == null ? false : true}*/}
                                                    {/*        />*/}
                                                    {/*    ) : (*/}
                                                    {/*        <Switch height={26} disabled />*/}
                                                    {/*    )}*/}
                                                    {/*</TableCell>*/}
                                                </TableRow>
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <span>No Subscription found</span>
                                    )}
                                </Table>
                            </TableContainer>
                        </div>
                    </Paper>
                ))
            ) : (
                <span>No account found</span>
            )}
        </div>
    );
}

const useStyles33 = makeStyles()((theme) => ({
    root: {
        backgroundColor: Colors.tableHeader,
        padding: theme.spacing(0.2),
    },

    tableRow: {
        fontWeight: "normal",
        whiteSpace: "nowrap"
    },
}));

function GcpAccountList({ data = null, customerId, onUpdate, role }) {
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState(null);

    const {classes} = useStyles33();

    return (
        <div className={'connection_buttons'}>
            {data && data.length ? (
                data.map((item, index) => (
                    <Paper elevation={3} style={{ margin: 8 }}>
                        <div>
                            <div style={{ padding: 8, backgroundColor: Colors.tableHeader, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div>
                                    <img
                                        src={gcp_billing}
                                        alt="gcp_billing"
                                        style={{
                                            width: 25,
                                            height: 25,
                                            marginRight: 4,
                                            padding: 3,
                                        }}
                                    />
                                    {item?.organizations?.[0]?.billingAccountName} ({item?.tenantId})
                                </div>
                                <LinkText
                                    onClick={() => {
                                        setFormData(item?.organizations?.[0]);
                                        setShow(true);
                                    }}
                                    role={role}
                                >
                                    <EditSharp style={{ fontSize: 18, marginRight: 4 }} />
                                    Edit
                                </LinkText>
                            </div>
                            <TableContainer>
                                <Table>
                                    <TableHead className={classes.root}>
                                        <TableRow>
                                            <TableCell className={classes.tableRow}>Display name</TableCell>
                                            <TableCell className={classes.tableRow}>Table name</TableCell>
                                            <TableCell className={classes.tableRow}>Organization name</TableCell>
                                            <TableCell className={classes.tableRow}>Organization Id</TableCell>
                                            <TableCell className={classes.tableRow}>Billing account name</TableCell>
                                            <TableCell className={classes.tableRow}>Billing account Id</TableCell>
                                            <TableCell className={classes.tableRow}>Status</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    {item.organizations && item.organizations.length ? (
                                        item.organizations?.map((item) => (
                                            <TableBody>
                                                <React.Fragment>
                                                    <TableRow hover key={item.billingAccountId + item.organizationId}>
                                                        <TableCell className={classes.tableRow}>{item.displayName || "-"}</TableCell>
                                                        <TableCell className={classes.tableRow}>{item.tableName || "-"}</TableCell>
                                                        <TableCell className={classes.tableRow}>{item.organizationName || "-"}</TableCell>
                                                        <TableCell className={classes.tableRow}>{item.organizationId || "-"}</TableCell>
                                                        <TableCell className={classes.tableRow}>{item.billingAccountName || "-"}</TableCell>
                                                        <TableCell className={classes.tableRow}>{item.billingAccountId || "-"}</TableCell>
                                                        <TableCell>
                                                            {item.status == "active" ? (
                                                                <div>
                                                                    <span>Active</span>
                                                                    <img
                                                                        src={VerfiedImage}
                                                                        alt="VerfiedImage"
                                                                        style={{
                                                                            width: 16,
                                                                            height: 16,
                                                                            marginLeft: 4,
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : item.status == "error" ? (
                                                                <div>
                                                                    <img
                                                                        src={error}
                                                                        alt="error"
                                                                        style={{
                                                                            width: 16,
                                                                            height: 16,
                                                                            marginRight: 4,
                                                                        }}
                                                                    />
                                                                    <span>{item.status=='error'&&'Error'}</span>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <img
                                                                        src={error}
                                                                        alt="error"
                                                                        style={{
                                                                            width: 16,
                                                                            height: 16,
                                                                            marginRight: 4,
                                                                        }}
                                                                    />
                                                                    <span>{item?.error_msg?.error?.[0]?.message||null}</span>
                                                                </div>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                    <ExpandLavelData data={item} />
                                                </React.Fragment>
                                            </TableBody>
                                        ))
                                    ) : (
                                        <span>No Subscription found</span>
                                    )}
                                </Table>
                            </TableContainer>
                        </div>
                    </Paper>
                ))
            ) : (
                <span>No account found</span>
            )}
            <EditAccountForm show={show} handleClose={() => setShow(false)} data={formData} customerId={customerId} onUpdateSuccess={onUpdate} />
        </div>
    );
}

export function ExpandLavelData({ data }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell colSpan={7}>
                    <Htmltable data={data} />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data }) {
    return (
        <div style={{ maxHeight: 300, display: "flex", flexDirection: "row", alignItems: "center" }} className="overflow-auto">
            <span>
                {" "}
                <img
                    src={gcp_project}
                    alt="gcp_project"
                    style={{
                        width: 25,
                        height: 25,
                        marginRight: 4,
                        padding: 3,
                    }}
                />
                <b>Projects</b>
            </span>

            {data && data?.projects?.length > 0 ? (
                data?.projects?.map((m) => <span style={{ padding: 6, margin: 4, backgroundColor: Colors?.tableHeader, borderRadius: 4 }}>{m?.id}</span>)
            ) : (
                <span style={{ padding: 6, margin: 4 }}>No Project found</span>
            )}
        </div>
    );
}

function EditAccountForm({ displayName: name, tableId: id, show, handleClose, data, customerId, onUpdateSuccess }) {
    const [displayName, setDisplayName] = useState(null);
    const [tableId, setTableId] = useState(null);
    const [projectId, setProjectId] = useState(null);
    const [datasetId, setDatasetId] = useState(null);

    const [displayNameErr, setDisplayNameErr] = useState(null);
    const [tableIdErr, setTableIdErr] = useState(null);
    const [projectIdErr, setProjectIdErr] = useState(null);
    const [datasetIdErr, setDatasetIdErr] = useState(null);

    const [afterInput, setAfterInput] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(null);

    useEffect(() => {
        if (data) {
            setDisplayName(data?.displayName);
            setTableId(data?.tableName);
            setProjectId(data?.projectId);
            setDatasetId(data?.datasetId);
        }
    }, [data]);

    function displayHandler(e) {
        let item = e.target.value;
        if (!item.trim()) {
            // console.log("refresh");
            setDisplayNameErr(true);
        } else {
            if (!/^[a-zA-Z0-9.\-_\s]{3,25}$/.test(item.trim())) {
                setAfterInput(true);
                setDisplayNameErr(false);
            } else if (!item.trim()) {
                setDisplayNameErr(true);
            } else {
                setAfterInput(false);
                setDisplayNameErr(false);
            }
        }
        setDisplayName(item);
    }

    function tableHandler(e) {
        let item = e.target.value;
        if (item.length < 3) {
            setTableIdErr(true);
        } else {
            setTableIdErr(false);
        }
        setTableId(item);
    }

    async function onUpdate() {
        if (!(projectIdErr && tableIdErr && displayNameErr && datasetIdErr)) {
            const data2 = {
                customerId,
                accountId: data?.accountId,
                projectId,
                datasetId,
                tableName: tableId,
                displayName,
            };
            setLoading(true);
            await axios
                .post(REACT_APP_GCP_EDIT_ACCOUNT, data2)
                .then((response) => response.data)
                .then((response) => {
                    if (response.statusCode == 200) {
                        onUpdateSuccess();
                        handleClose();
                    } else {
                        setShowError(response.errorMessage);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    return (
        <Modal
            show={show}
            onHide={() => {
                setShowError(null);
                handleClose();
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton style={{ backgroundColor: color.tableHeader }}>
                <Modal.Title>Edit GCP Account</Modal.Title>
            </Modal.Header>
            <div style={{ padding: 20 }}>
                <Form style={{ flex: 3, marginBottom: 10 }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <TextField style={{}} type="text" label="Billing Account Id" value={data?.billingAccountId} disabled={true} />
                        <TextField style={{}} type="text" label="Billing Account Name" value={data?.billingAccountName} disabled={true} />
                        <br />
                        <TextField
                            style={{}}
                            type="text"
                            placeholder="Enter Project Id"
                            label="Project Id *"
                            value={projectId}
                            onInput={setProjectId}
                            onChange={(e) => {
                                setProjectIdErr(String(e.target.value).trim() === "");
                                setProjectId(String(e.target.value).trim());
                            }}
                        />
                        {projectIdErr ? <p style={{ color: "red" }}>Please enter Project Id</p> : ""}
                        <br />
                        <TextField
                            style={{}}
                            type="text"
                            placeholder="Enter Dataset Id"
                            label="Dataset Id *"
                            value={datasetId}
                            onInput={setDatasetId}
                            onChange={(e) => {
                                setDatasetIdErr(String(e.target.value).trim() === "");
                                setDatasetId(String(e.target.value).trim());
                            }}
                        />
                        {datasetIdErr ? <p style={{ color: "red" }}>Please enter Dataset Id</p> : ""}
                        <br />
                        <TextField style={{}} type="text" placeholder="Enter Table Id (Example: ProjectId:BigqueryDatasetName.Tablename)" label="Table Id *" value={tableId} onInput={setTableId} onChange={tableHandler} />
                        {tableIdErr ? <p style={{ color: "red" }}>Please enter Table Id</p> : ""}
                        <br />
                        <TextField style={{}} type="text" placeholder="Enter Display Name" label="Display Name *" value={displayName} onInput={setDisplayName} onChange={displayHandler} />
                        {displayNameErr ? <p style={{ color: "red" }}>Please enter Display Name</p> : afterInput ? <p style={{ color: "red" }}>Please enter Valid Display Name</p> : ""}

                        <br />
                        <Button
                            style={{
                                background: "linear-gradient(45deg, #3f51b5 50%, #3f51b5 100%)",
                                border: 0,
                                borderRadius: 3,
                                boxShadow: "0 3px 5px 2px rgba(63, 81, 181, .3)",
                                color: "white",
                                height: 40,
                                padding: "0 40px",
                                textTransform: "none",
                                outline: "none",
                            }}
                            disabled={loading}
                            // className={classes1.btnStyle}
                            onClick={onUpdate}
                        >
                            {loading ? <CircularProgress size={20} color={"white"} style={{ outline: "none" }} /> : "Update"}
                        </Button>
                    </div>
                </Form>
                {showError && (
                    <Alert severity="error" onClose={() => setShowError(null)}>
                        {showError}
                    </Alert>
                )}
            </div>
        </Modal>
    );
}
