import * as types from "../actions/types";

const userState = {
  charts: null,
  homeData:null,
  homeTenantData:null,
  loading: false,
  error: null,
};

function azureServerlessReducer(state = userState, { type, payload }) {
  switch (type) {
      case "SWITCH_TEAM_CLEAR": {
          return {
              ...state,
              loading: false,
              error: null,
              currency: "",
          };
      }
      case types.AZURE_SERVERLESS_REQUEST: {
          return {
              ...state,
              loading: true,
          };
      }
      case types.AZURE_SERVERLESS_SUCCESS: {
          return {
              ...state,
              charts: payload.data,
              loading: false,
          };
      }
      case types.AZURE_SERVERLESS_FAIL: {
          return {
              ...state,
              loading: false,
              error: payload,
          };
      }

      case types.AZURE_SERVERLESS_HOME_REQUEST: {
        return {
            ...state,
            loading: true,
        };
    }
    case types.AZURE_SERVERLESS_HOME_SUCCESS: {
        return {
            ...state,
            homeData: payload.data,
            loading: false,
        };
    }
    case types.AZURE_SERVERLESS_HOME_FAIL: {
        return {
            ...state,
            loading: false,
            error: payload,
        };
    }
    case types.AZURE_SERVERLESS_TENANT_REQUEST: {
        return {
            ...state,
            loading: true,
        };
    }
    case types.AZURE_SERVERLESS_TENANT_SUCCESS: {
        return {
            ...state,
            homeTenantData: payload.data,
            loading: false,
        };
    }
    case types.AZURE_SERVERLESS_TENANT_FAIL: {
        return {
            ...state,
            loading: false,
            error: payload,
        };
    }

      default:
          return state;
  }
}
export default azureServerlessReducer;
