import * as types from "../actions/types";

const userState = {
    allStorageData: null,
    error: null,
    loading: false,
};

function allStorageReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.ALL_STORAGE_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.ALL_STORAGE_SUCCESS: {
            return {
                ...state,
                allStorageData: payload.data,
                loading: false,
            };
        }
        case types.ALL_STORAGE_FAILURE: {
            return {
                ...state,
                loading: false,
                allStorageData: null,
                error: payload,
            };
        }

        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                allStorageData: null,
                error: null,
                loading: false,
            };
        }

        default:
            return state;
    }
}
export default allStorageReducer;
