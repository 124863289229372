import React, { useState, useEffect } from "react";
//import "./style.css";
import { makeStyles, withStyles } from "MakeWithStyles";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "../../components/TablePaginationActions";
import TableSearch from "../../components/TableSearch";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExpandableTable, { ExpandRow } from "components/ExpandableTable";
import Format from "components/NumberFormat";
import ResourceGroups from "../../images/resources/Resource_Group.svg";
import colors from "styles/color";

const StyledTableCell = withStyles(TableCell,(theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
        fontWeight: "bold"
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: theme.spacing(1.2),
    },
}));
const StyledTableRow = withStyles(TableRow,(theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            // backgroundColor: theme.palette.action.hover,
        },
    },
}));
const useRowStyles = makeStyles()({
    root: {
        "& > *": {
            borderBottom: "unset",
            padding: 3.5,
        },
    },
    table: {
        minWidth: 700,
    },
    container: {
        maxHeight: 440,
    },
    container1: {
        maxHeight: 400,
    },
});

export default function HtmlECTable(props) {
    const { tableRow, data, currency } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [search, setSearch] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.resourceGroupName,
                f?.subscriptionName,
                f?.subscriptionId,
                f?.cost,
                
            ], search);
        }
        return true;
    }
    const {classes} = useRowStyles();

    return (
        <Paper elevation={0}>
            <TableContainer className={classes.container}>
            <TableSearch onSearch={setSearch} />
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell />
                            <StyledTableCell align="inherit">
                                Resource group name
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                Subscription name
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                Subscription Id
                            </StyledTableCell>

                            <StyledTableCell align="center">
                                Cost
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableRow?.filter(filteredTable)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((r, i) => (
                            <ExpandRowRG row={r} index={i} data={data} currency={currency} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination ActionsComponent={TablePaginationActions}
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={tableRow?.filter(filteredTable)?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
        </Paper>
    );
}

function ExpandRowRG(props) {
    const { row, index, data, currency } = props;
    const [open, setOpen] = useState(false);
    const [list, setList] = useState(null);
    const {classes} = useRowStyles();

    return (
        <React.Fragment>
            <StyledTableRow hover className={classes.root} key={row.resourceId}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell component="th" scope="row" size="small" padding="none">
                    {<img src={ResourceGroups} width={40} height={35} />}
                    {row?.resourceGroupName == "" ? <span style={{ color: colors.gray }}>{"unassigned"}</span> : row?.resourceGroupName}
                </TableCell>
                <TableCell align="left">{row.subscriptionName}</TableCell>
                <TableCell align="left">{row.subscriptionId}</TableCell>

                <TableCell align="center">{currency + Format.formatNumber(Number(row.cost).toFixed(2))}</TableCell>
            </StyledTableRow>
            <ExpandLavelDataRG data={data} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

function ExpandLavelDataRG({ data, open, select, currency }) {
    const [resource, setResource] = useState(null);

    useEffect(() => {
        if (select != null) {
            let value = data.resourceGroup[select];

            setResource(value);
        }
    }, [select, data]);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={4}>{resource && <ExpandableTable tableRow={resource.resources} data={resource} currency={currency} />}</Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
