import React, { useEffect, useState } from "react";
function GetWindowDimension(Component) {
  return function Dimension(props) {
    const [dimensions, setDimensions] = useState({
      height: window.innerHeight,
      width: window.innerWidth,
    });
    useEffect(() => {
      function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth,
        });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
        window.removeEventListener("resize", handleResize);
      };
    });
    return (
      <div style={{ width: "100%", height: "100%" }}>
        {dimensions && (
          <Component
            width={dimensions.width}
            height={dimensions.height}
            {...props}
          />
        )}
      </div>
    );
  };
}

export default GetWindowDimension;
