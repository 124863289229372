import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import {
    getAzureStorageSuccess,
    getAzureStorageFailure,
    getAzureStorageAccountSuccess,
    getAzureStorageAccountFailure,
    getAzureTagsFailure, getAzureTagsSuccess
} from "../actions/actions";
const { REACT_APP_AZURE_TAGS, REACT_APP_AZURE_SUBSCRIPTION_TAGS, REACT_APP_AZURE_TENANT_TAGS } = process.env;

function* getAzureTags({ payload }) {
    try {
        const res = yield call(axios.get, (payload.tenantId && payload.subscriptionId)?
            REACT_APP_AZURE_SUBSCRIPTION_TAGS:
            payload.tenantId?
            REACT_APP_AZURE_TENANT_TAGS:
            REACT_APP_AZURE_TAGS, {
            params: {
                customerId: payload.customerId,
                tenantId: payload.tenantId,
                subscriptionId: payload.subscriptionId
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureTagsFailure(statusCode));
            } else {
                yield put(getAzureTagsSuccess({subscriptionId: payload?.subscriptionId, tenantId: payload?.tenantId, data: res?.data?.data}));
            }
        } else {
            yield put(getAzureTagsFailure(res));
        }
    } catch (error) {
        yield put(getAzureTagsFailure(error));
    }
}

export default function* azureTagsSaga() {
    yield takeLatest(types.AZURE_TAGS_REQUEST, getAzureTags);
}
