import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "MakeWithStyles";
import { Table, TableBody, TableCell as TCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Divider, LinearProgress } from "@mui/material";
import storage_account_icon from "../../images/resources/Storage_Account.svg";
import function_apps_icon from "../../images/function-apps.png";
import allCloudFunctions_icon from "../../images/allCloudFunctions.png";
import lambdaFunctionIcon from "../../images/lambdaFucntion.png";

import Cost from "../../components/Cost";
import {ALPHA_NUM_SORTER, bytesToSize, convertToInternationalCurrencySystem, isNumeric} from "../../util/Miscellaneous";
import axios from "../../connection/axios";
import { CancelRounded } from "@mui/icons-material";
import { getAllResourcesRequest } from "../../redux/actions/actions";
import SlideShow from "../../components/CustomSlider/SlideShow";
import ReactD3BarChart from "../../components/D3Charts/ReactD3BarChart";
import { commonConversion } from "../../util/DateRangeFormatter";
import Format from "components/NumberFormat";
import LineChart from "pages/Charts/LineChart";
import TablePagination from "@mui/material/TablePagination";
import { lambdaCommonConversion } from "../../util/LambdaFunctionFormatter";
import LinkText from "components/LinkText";
import CarousalCard from "components/CarousalCard";
import moment from "moment";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import { Tooltip, OverlayTrigger, Popover } from "react-bootstrap";
import colors from "styles/color";
import aws_account from "../../images/aws_org.svg";

import AzureLogo from "../../images/Azure_logo.svg";
import AwsLogo from "../../images/aws_dark.svg";
import GcpLogo from "../../images/GCP_logo.svg";
import all_resource from "../../images/All-Resources.svg";

import TablePaginationActions from "../../components/TablePaginationActions";
import { TABLE_SORT } from "../../util/AppConstants";
import TableSortHeader from "../../components/TableSortHeader";
import TableSearch from "../../components/TableSearch";
import {getMonthsFromNow} from "../../util/DateFormatter";
import TableMonthlySpend from "./TableMonthlySpend";

const { REACT_APP_ALL_1Y_TABLE_DATA } = process.env;

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        //marginBottom: 5,
    },
}));

function TableCell(props) {
    return (
        <TCell {...props} style={{ padding: 5 }}>
            {props.children}
        </TCell>
    );
}

const TABLE_SORT_DEFAULT_COLUMN = "cost";

function AllCloudResources({ currency, progress, customerId }) {
    const dispatch = useDispatch();
    const {classes} = useStyles();
    const [data, setData] = useState(null);
    // const [loading, setLoading] = useState(true);
    // const customerId = useSelector((state) => state.userDetailsReducer.id);
    // const loading = useSelector(
    //   (state) => state.azureRecommendationReducer.loading
    // );
    const [showingFunction, setShowingFunction] = useState(null);
    const [showingMetrics, setShowingMetrics] = useState(null);
    const [metricsData, setMetricsData] = useState([]);
    const [chart, setChart] = useState([]);
    const [meterChart, setMeterChart] = useState([]);
    const [functionsList, setFunctionsList] = useState([]);
    const [showMetricSpecific, setShowMetricSpecific] = useState([]);
    const [metricTotals, setMetricTotals] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [lambdaData, setLambdaData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [totalCost, setTotalCost] = useState("");

    const [cosmosDBLoader, setCosmosDBLoader] = useState(false);
    const [metricsLoding, setMetricsLoding] = useState(false);
    const [ytdSearch, setYTDSearch] = useState(null);

    let allArray = useSelector((state) => state?.allResourcesReducer?.allResourcesData);

    let loading = useSelector((state) => state?.allResourcesReducer?.loading);

    const handleChangePage = (event, newPage) => {
        
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (allArray == null) {
            dispatch(
                getAllResourcesRequest({
                    customerId,
                })
            );
        }
    }, [customerId]);

    function resolveDateTime(dateTime) {
        const date = moment(dateTime);
        return date.format("LL") + " at " + date.format("LTS");
    }

    const [datePeriod, setDatePeriod] = React.useState(null);

    const [order, setOrder] = React.useState(TABLE_SORT.ORDER_ASC);
    const [orderBy, setOrderBy] = React.useState(TABLE_SORT_DEFAULT_COLUMN);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === TABLE_SORT.ORDER_ASC;
        setOrder(isAsc ? TABLE_SORT.ORDER_DESC : TABLE_SORT.ORDER_ASC);
        setOrderBy(property);
    };

    const [search, setSearch] = useState(null);


    const [yearTableData, setYearTableData] = useState([]);
    const [parentWidth, setParentWidth] = useState(null);
    const [monthWiseData, setMonthWiseData] = useState(null);
    const [yearWiseData, setYearWiseData] = useState(null);
    const [spendByMonthAccountData, setSpendByMonthAccountData] = useState(null);
    const [yearTableLoading, setYearTableLoading] = useState(false);

    useEffect(() => {
        window.$(document).ready(() => {
            rootLoaded();
            // console.log((document.querySelector('#root_dashboard')));
        });
    }, []);

    window.$(document).ready(rootLoaded);

    function rootLoaded() {
        if (parentWidth) return;
        const e = window.$("#root_dashboard");
        try {
            setParentWidth(e.width());
        } catch (e) {}
    }

    useEffect(
        () => {
            if(customerId)
                getAll1YearMonthlyData().then(async () => await getAll1YearMonthlyData("daily"))
        },
        [customerId]
    );

    async function getAll1YearMonthlyData(period = "monthly") {
        setYearTableLoading(true);
        axios
            .get(REACT_APP_ALL_1Y_TABLE_DATA, {
                params: {
                    customerId,
                    view: period,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                if (period === "daily") {
                    setMonthWiseData(response?.data?.data);
                    return;
                }
                setYearTableData(response?.data?.data || []);
                setYearWiseData(response?.data?.data);
            })
            .catch((e) =>{})
            .finally(()=>setYearTableLoading(false));
    }

    function getMonths() {
        // const months = new Array();
        const azureMonths = yearTableData?.azure?.SpendByMonth?.map((m) => m.month) || [];
        const awsMonths = yearTableData?.aws?.SpendByMonth?.map((m) => m.month) || [];
        const gcpMonths = yearTableData?.gcp?.SpendByMonth?.map((m) => m.month) || [];
        // azureMonths.length
        // if (azureMonths.length >= 10) {
        //     return azureMonths;
        // } else if (awsMonths.length >= 10) {
        //     return awsMonths;
        // } else if (gcpMonths.length >= 10) {
        //     return gcpMonths;
        // } else {
        //     return [];
        // }
        return new Array(13).fill(null);
        // Object.keys(yearTableData).forEach(key=>{
        //   months.push(yearTableData?.[key]?.SpendByMonth?.map(m=>m.month));
        // })
        // console.log(months)
    }

    useEffect(()=>{
        if(yearTableData){
            const accounts = {};
            const finalData = [];

            yearTableData?.all?.SpendByMonth?.forEach(monthWise=>{
                monthWise.resources.forEach((subWise)=>{
                    if(subWise.resourceId in accounts || subWise?.skuId in accounts){
                        accounts[subWise.cloudProvider != 'gcp'?subWise.resourceId:subWise?.skuId].list.push({month: monthWise.month, cost: subWise.cost});
                    } else {
                        accounts[subWise.cloudProvider != 'gcp'?subWise.resourceId:subWise?.skuId] = { cloud :subWise.cloudProvider, list: []};
                        accounts[subWise.cloudProvider != 'gcp'?subWise.resourceId:subWise?.skuId].list.push({month: monthWise.month, cost: subWise.cost});
                    }

                })
            });

            Object.keys(accounts).forEach(key=>{
                const account = accounts[key].list;

                const newData = getMonthsFromNow().reverse().map((m, i) => {
                    const time = moment(m, "MMM YYYY");
                    const found = account.findIndex((d) => {
                        const int = moment(d?.month, "YYYYMM");
                        return time.isSame(int);
                    });
                    if (found > -1) {
                        return account?.[found];
                    } else {
                        return {month: parseInt(moment(m).format("YYYYMM")), cost: "_"};
                    }
                });

                finalData.push({subscriptionId: key.replace(/\//g,""), cloud: accounts[key].cloud, list: newData})
            });
            // const total = [];

            // [
            // [1, 2, 3],
            // [1, 2, 3],
            // [1, 2, 3],
            //     ]
            //
            // [3, 6, 9]

            let otherRow = [];
            if(finalData && finalData.length){
                const totalData = finalData.map(f=>f.list.map(l=>isNumeric(l.cost)?parseFloat(l.cost):'-'));
                const finalTotal = [null,null,null,null,null,null,null,null,null,null,null,null,null];
                for(var col = 0; col < (totalData?.[0]?.length) || 0; col++){
                    totalData.forEach(row=>{
                        if(isNumeric(String(row[col])))
                            finalTotal[col] = (finalTotal[col] || 0) + row[col];
                    })
                }

                otherRow.push({
                    subscriptionId: 'All Resources',
                    cloud: 'all',
                    list: finalData?.[0]?.list?.map((f, i)=>({month: f.month, cost: ""+finalTotal[i]}))
                });
            }

            setSpendByMonthAccountData({list: finalData, other: otherRow});
        }
    }, [yearTableData])

    const filterTable = (f) => {
        if (search && search.toString().length) {
            return !!TableSearch.doesItIncludes(
                [
                    f?.cloudProvider,
                    f?.resourceId,
                    f?.skuId,
                    f?.resourceType,
                    f?.serviceCode,
                    f?.service,
                    f?.subscriptionId,
                    f?.subscriptionName,
                    f?.usageAccountName,
                    f?.usageAccountId,
                    f?.projectId,
                    f?.location,
                ],
                search
            );
        }
        return true;
    }

    return loading ? (
        <div className={classes.root}>
            <LinearProgress variant="determinate" value={progress} />
        </div>
    ) : (
        <div>
            <ContainerCardWithDateRange
                title={"Resources"}
                titleIcon={all_resource}
                titleIconHeight={45}
                defaultPeriod={"1M"}
                showDateRange={true}
                collapsible={true}
                datePeriod={({ period }) => ({
                    rawStart: moment(allArray?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(allArray?.[period]?.endDate, "YYYYMMDD"),
                    start: "__",
                    end: "__",
                })}
                totalCost={({ period }) => currency + Format.formatNumber(allArray?.[period]?.totalCost)}
                datePeriodEnabled={true}
                showDatePeriod={(period) => !!allArray != null && allArray?.[period]?.resources?.length}
                onPeriodChange={(period) => {
                    setDatePeriod(period);
                    handleChangeRowsPerPage({ target: { value: 10 } });
                }}
            >
                {({ period }) => (
                    <div elevation={10} style={{ padding: 10 }}>
                        {allArray && allArray?.[period]?.resources?.length ? (
                            <>
                                <CarousalCard cardTitle1={'Total cost'} cardTitle2={"Total Resources"} cost={allArray && allArray?.[period]?.resources?.map((t) => parseFloat(t.cost)).reduce((a, b) => a + b, 0)} res={allArray?.[period]?.resources?.length} currency={currency} />
                                <Box display={!loading && allArray && allArray?.[period]?.resources?.length ? "flex" : "none"}>
                                    <Box flex={showingFunction ? 0.25 : 1}>
                                        <TableSearch onSearch={(e)=> {
                                            handleChangeRowsPerPage({ target: { value: 10 } });
                                            setSearch(e)
                                        }} />
                                        <TableContainer style={{ height: 350 }} component={Paper}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead style={{ backgroundColor: "#cfdac8" }}>
                                                    <TableRow>
                                                        <TableSortHeader
                                                            classes={classes}
                                                            numSelected={
                                                                1
                                                                // ||
                                                                //selected.length
                                                            }
                                                            order={order}
                                                            orderBy={orderBy}
                                                            onRequestSort={handleRequestSort}
                                                            rowCount={allArray?.[datePeriod]?.resources?.length}
                                                            headCells={[
                                                                { numeric: 0, id: "cloud", label: "Cloud" },
                                                                { numeric: 0, id: "id", label: "Resources" },
                                                                { numeric: 0, id: "type", label: "Resource Type" },
                                                                { numeric: 0, id: "account", label: "Subscription / Account" },
                                                                { numeric: 0, id: "location", label: "Location" },
                                                                { enabled: !showingFunction, numeric: 1, id: "cost", label: "Cost" },
                                                            ]}
                                                        />

                                                        {/*<TableCell>*/}
                                                        {/*    <b>Function name</b>*/}
                                                        {/*</TableCell>*/}
                                                        {/*<TableCell align="left">*/}
                                                        {/*    <b>Cloud Account/Subscription</b>*/}
                                                        {/*</TableCell>*/}

                                                        {/*{showingFunction ? null : (*/}
                                                        {/*    <>*/}

                                                        {/*        <TableCell align="left">*/}
                                                        {/*            <b>Resource type</b>*/}
                                                        {/*        </TableCell>*/}

                                                        {/*        <TableCell align="left">*/}
                                                        {/*            <b>Location</b>*/}
                                                        {/*        </TableCell>*/}
                                                        {/*        <TableCell align="right">*/}
                                                        {/*            <b>Cost</b>*/}
                                                        {/*        </TableCell>*/}
                                                        {/*    </>*/}
                                                        {/*)}*/}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody style={{ padding: 0 }}>
                                                    {allArray &&
                                                        allArray?.[period]?.resources
                                                            ?.filter(filterTable)
                                                            ?.sort((r1, r2) => {
                                                                const fi = {
                                                                    cloud: ALPHA_NUM_SORTER.compare(r1.cloudProvider, r2.cloudProvider),
                                                                    cost: parseFloat(r2.cost) - parseFloat(r1.cost),
                                                                    id: ALPHA_NUM_SORTER.compare(
                                                                        r1?.cloudProvider == "gcp" ? r1.skuId : r1?.resourceId || "unassigned",
                                                                        r2?.cloudProvider == "gcp" ? r2.skuId : r2?.resourceId || "unassigned",
                                                                    ),
                                                                    location: ALPHA_NUM_SORTER.compare(r1?.location, r2?.location,),
                                                                    account: ALPHA_NUM_SORTER.compare(
                                                                        r1?.cloudProvider == "azure"? r1?.subscriptionName : r1?.usageAccountId || r1?.usageAccountName || r1?.projectId,
                                                                        r2?.cloudProvider == "azure"? r2?.subscriptionName : r2?.usageAccountId || r2?.usageAccountName || r2?.projectId,
                                                                    ),
                                                                    type: ALPHA_NUM_SORTER.compare(
                                                                        r1?.cloudProvider == "gcp" ? r1.service :
                                                                            r1?.cloudProvider == "aws"? r1?.serviceCode :
                                                                                r1?.resourceType,
                                                                        r2?.cloudProvider == "gcp" ? r2.service :
                                                                            r2?.cloudProvider == "aws"? r2?.serviceCode :
                                                                                r2?.resourceType,
                                                                    ),
                                                                }[orderBy];
                                                                return fi * order;
                                                            })
                                                            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            ?.map((row, index) => (
                                                                <TableRow hover key={(row?.cloudProvider + row?.tenantId + row?.skuId + index) || row?.payerAccountId || row?.billingAccountId}>
                                                                    <TableCell align="left" component="th">
                                                                        {row?.cloudProvider == "azure" ? <img src={AzureLogo} height={22} /> : row?.cloudProvider == "aws" ? <img src={AwsLogo} height={22} /> : <img src={GcpLogo} height={22} />}
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        {row?.cloudProvider == "azure" ? (

                                                                                <div>{row?.resourceId.replace(/\//g,"")}</div>

                                                                        ) : row?.cloudProvider == "aws" ? (

                                                                                <div>{row?.resourceId || <span style={{ color: colors.gray }}>{"unassigned"}</span>} </div>

                                                                        ) : (

                                                                                <div>{row?.skuId} </div>

                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell align="left" component="th">
                                                                        {row?.cloudProvider == "azure" ? (

                                                                            <div>{row?.resourceType?.split("/")[1] || <span style={{ color: colors.gray }}>{"unassigned"}</span>}</div>

                                                                        ) : row?.cloudProvider == "aws" ? (

                                                                            <div>{row?.serviceCode} </div>

                                                                        ) : (
                                                                            <div>{row?.service} </div>
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        {row?.cloudProvider == "azure" ? (
                                                                            <OverlayTrigger
                                                                                placement="right"
                                                                                overlay={
                                                                                    <Popover id="popover-contained">
                                                                                        <Popover.Body style={{ fontSize: 11 }}>{row?.subscriptionId}</Popover.Body>
                                                                                    </Popover>
                                                                                }
                                                                            >
                                                                                <div>{row?.subscriptionName}</div>
                                                                            </OverlayTrigger>
                                                                        ) : row?.cloudProvider == "aws" ? (
                                                                            <OverlayTrigger
                                                                                placement="right"
                                                                                overlay={<Popover id="popover-contained">{"usageAccountName" in row && <Popover.Body style={{ fontSize: 11 }}>{row?.usageAccountName}</Popover.Body>}</Popover>}
                                                                            >
                                                                                <div>{row?.usageAccountId || row?.usageAccountName} </div>
                                                                            </OverlayTrigger>
                                                                        ) : (

                                                                            <div>{row?.projectId} </div>

                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell align="left" component="th">
                                                                        {row?.location}
                                                                    </TableCell>
                                                                    {!cosmosDBLoader && showingFunction ? (
                                                                        <LinearProgress variant="determinate" value={progress} />
                                                                    ) : (
                                                                        <>
                                                                            <TableCell align="right">
                                                                                {currency}
                                                                                <Cost>{row?.cost}</Cost>
                                                                                
                                                                            </TableCell>
                                                                        </>
                                                                    )}
                                                                </TableRow>
                                                            ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Box>

                                <TablePagination
                                    ActionsComponent={TablePaginationActions}
                                    rowsPerPageOptions={[10, 25, 50, 75]}
                                    component="div"
                                    count={allArray?.[period]?.resources?.filter(filterTable)?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </>
                        ) : (
                            <Typography style={{ display: "flex", justifyContent: "center" }}>No data found</Typography>
                        )}
                    </div>
                )}
            </ContainerCardWithDateRange>
            <div style={{marginTop: 20}} onLoad={rootLoaded} id={"root_dashboard"}>
                <div style={{ }}>
                    <ContainerCardWithDateRange title={"Spend by months"}
                                                titleIcon={all_resource}
                                                titleIconHeight={45}
                                                showDateRange={false}
                                                collapsible={true}>
                        {
                            ()=>parentWidth?(
                                <>
                                    <TableSearch onSearch={setYTDSearch} />
                                <TableMonthlySpend
                                    loading={yearTableLoading}
                                    cols={15}
                                    subscriptionNameAsOverlay={false}
                                    tableLabel={"Resource"}
                                    width={parentWidth}
                                    data={spendByMonthAccountData.list.filter(s=>{
                                            if (ytdSearch && ytdSearch.toString().length) {
                                                return !!TableSearch.doesItIncludes(
                                                    [
                                                        s.cloud,
                                                        s.subscriptionId
                                                    ],
                                                    ytdSearch
                                                );
                                            }
                                            return true;
                                    })}
                                    staticRows={spendByMonthAccountData.other}
                                    currency={currency}
                                    subscriptionLevel={true}        /** Although this is not related to subscription still
                                                                       putted subscriptionLevel as true because to hide
                                                                       subscription details are not coming from api **/
                                />
                                </>
                            ):null
                        }
                    </ContainerCardWithDateRange>
                </div>
            </div>
        </div>
    );
}

export default AllCloudResources;

function sumDuplicates(data, checkKey, valueKey) {
    var result = [];
    data.forEach(function (a) {
        if (!this[a[checkKey]]) {
            const data = {};
            data[checkKey] = a[checkKey];
            data[valueKey] = 0;
            // this[a.date] = { date: a.date, value: 0 };
            this[a[checkKey]] = data;
            result.push(this[a[checkKey]]);
        }
        this[a[checkKey]][valueKey] += a[valueKey];
    }, Object.create(null));
    return result;
}
