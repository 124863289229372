import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "MakeWithStyles";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import gcp_compute from "../../images/gcp_compute.svg";
import resource_group from "../../images/resource_group.svg";
import calendar_icon from "../../images/calendar.svg";
import DashboardIcon from "../../images/newCloudTrakrIcons/MultiCloudIcon.png";

import gcp_project from "../../images/gcp_project.svg";

import PauseIcon from "@mui/icons-material/Pause";
import { KeyboardArrowDown, KeyboardArrowUp, PlayArrow, ThumbUp } from "@mui/icons-material";
import { Paper, Grid, Divider, ButtonGroup, Button, CircularProgress, IconButton, Collapse, Typography } from "@mui/material";
import Format from "components/NumberFormat";
import YearTableData from "../YearTableData";
import axios from "../../connection/axios";
import serverless_icon from "../../images/light.svg";

import YearCalendarData, { CalendarDataProcessor, Months } from "../YearCalendarData";
import Colors from "../../styles/color";
import moment from "moment";
import SlideShow from "components/CustomSlider/SlideShow";
import ReactD3PieChart from "components/D3Charts/ReactD3PieChart";
import ReactD3BarChart from "components/D3Charts/ReactD3BarChart";
import { getMonthsFromNow } from "../../util/DateFormatter";
import Calendar_Spend_Icon from "../../images/calendar_months.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GroupedBarChart from "../D3Charts/GroupedBarChart";
import StackedBarChart2 from "../../pages/Charts/StackedBarChart2";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { generateGroupedChartData} from "../../pages/Charts/DashboardTabs";
import Total_Spend_Icon from "../../images/sigma_icon.svg";
import DateRange from "../DateRange";
import GCPSubscriptions from "../GCP/GCPSubscriptionsList";
import GCPProjectTabHome from "../../pages/DashboardAccountsHome/GCPProjectTabHome";
import GCPServicesProjectHome from "pages/DashboardAccountsHome/GCPServicesProjectHome";
import GCPProjectCompute from "pages/DashboardAccountsHome/GCPProjectCompute";
import GCPProjectStorage from 'pages/DashboardAccountsHome/GCPProjectStorage';
import GCPBillingHome from "../../pages/DashboardAccountsHome/GCPBillingHome";
import GCPServicesHome from "pages/DashboardAccountsHome/GCPServicesHome";
import GCPProjectTabProject from "pages/DashboardAccountsHome/GCPProjectTabProject";
import GCPServerlessHome from "../../components/Serverless/GCPServerlessHome";

import gcp_storage from '../../images/gcp_storage.svg'

import gcp_org from "../../images/gcp_org.svg";
import { OverlayComponent } from "./GcpBillingTab";
import { SKIP_CODE } from "../../util/Miscellaneous";
import NO_DATA_AVAILABLE from "../NO_DATA_AVAILABLE";
import gcp_big_query from "../../images/gcp_bigquery.svg";
import GCPBigQuery from "../../pages/DashboardAccountsHome/GCPBigQuery";
import {TIMERS} from "../../util/AppConstants";
import billing_icon from "../../images/billing_icon.svg";

const { REACT_APP_GCP_PROJECT_1Y_TABLE_DATA } = process.env;

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: Colors.background,
        //padding: theme.spacing(-20),
    },
    paper: {
        padding: theme.spacing(2),
    },
    tab: {
        fontWeight: "bold",
        textTransform: "none",
    },
    tabPanel: {
        padding: theme.spacing(-10),
    },
}));

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

export default function GcpProjectHome(props) {
    const { charts, currency, customerId, selectedSubscription, billingAccount, loading, billingAccountId, organizationId, projectId } = props;
    const { rec, progress } = props;
    const [resTab, setresTab] = useState(false);
    const {classes} = useStyles();
    const [value, setValue] = React.useState("2");
    const [parentWidth, setParentWidth] = useState(null);
    const [yearTableData, setYearTableData] = useState([]);
    const [monthWiseData, setMonthWiseData] = useState(null);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState(null);
    const [showDataBy, setShowDataBy] = useState("calendar");
    const [overlayData, setOverlayData] = useState(null);
    const [ResourceGroup, setResourceGroup] = useState(null);
    const [monthInterval, setMonthInterval] = useState(null);
    const [computeData, setComputeData] = useState(null);
    const [containersData, setContainersData] = useState(null);
    const [kubernetesData, setKubernetesData] = useState(null);
    const [gcpMonthWiseChartData, setGcpMonthWiseChartData] = useState([]);
    const [othersMonthWiseChartData, setOthersMonthWiseChartData] = useState([]);
    const [spendByMonthsChartView, setSpendByMonthsChartView] = useState(true);
    const [chartShowingIndex, setChartShowingIndex] = useState(0);

    const [totalSpendChartView, setTotalSpendChartView] = useState(true);
    const [period, setPeriod] = useState("1M");
    const [allCLoud, setAllCLoud] = useState(null);
    const [top5Services, setTop5Services] = useState(null);
    const [topFiveProjects, setTopFiveProjects] = useState(null);
    const [top5Locations, setTop5Locations] = useState(null);
    const [spendBy5Accounts, setSpendBy5Accounts] = useState(null);

    // useEffect(() => {
    //     const RGChartData = [];
    //     charts?.TopFiveResourceGroups?.[period]?.dataSet?.map((i) => {
    //         RGChartData.push({
    //             label: i.label == "" ? "unassigned" : i.label,
    //             value: i.value,
    //         });
    //     });
    //     setResourceGroup(RGChartData);
    // }, [period, charts]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        props?.setActiveTab(newValue);
    };

    function rootLoaded() {
        const e = window.$("#root_dashboard");
        try {
            setParentWidth(e.width());
        } catch (e) {}
    }

    var loopIndex = 0;
    const months = getMonthsFromNow([], true)?.reverse();

    function onResume() {
        return;
        setMonthInterval(
            setInterval(() => {
                if (loopIndex == months.length) {
                    loopIndex = 0;
                    onMonthNext(months[0]);
                } else {
                    onMonthNext(months[loopIndex]);
                }
                loopIndex++;
            }, TIMERS.CALENDAR_INTERVAL)
        );
    }

    function onPause() {
        clearInterval(monthInterval);
        setMonthInterval(null);
    }

    function onMonthNext(monthYear = "") {
        if (monthYear) {
            const month = monthYear?.split(" ")[0];
            const year = monthYear?.split(" ")[1];
            setCurrentMonth(month);
            setCurrentYear(parseInt(year));
        }
    }

    async function get1YearTableData(period = "monthly") {
        return await axios
            .get(REACT_APP_GCP_PROJECT_1Y_TABLE_DATA, {
                params: {
                    customerId,
                    view: period,
                    billingAccountId,
                    organizationId,
                    projectId,
                    // subscriptionId,
                    // tenantId,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                if (response.statusCode === 1002) {
                    onPause();
                    setMonthWiseData(null);
                    setYearTableData(null);
                    setCurrentYear(null);
                    setCurrentMonth(null);
                    return;
                }
                if (period === "daily") {
                    setMonthWiseData(response?.data?.data?.SpendByDaily || null);
                } else {
                    setYearTableData(response?.data?.data?.SpendByMonth || null);
                }
            })
            .catch((e) => {});
    }

    useEffect(() => {
        if (customerId) {
            get1YearTableData()
                .then(async () => await get1YearTableData("daily"))
                .then(onResume);
        }
        props?.setActiveTab("1");
        props?.setTabSelect("homeTab");
    }, [customerId]);

    useEffect(() => {
        gcpAccountData(period);
    }, [charts?.GCPCloudSpending, charts?.TopFiveServices, charts?.SpendByProjects, charts?.TopFiveLocations, charts?.SpendByAccounts, charts?.SpendByOrgAndBillingAccountWithProject, period]);

    useEffect(() => {
        if (!monthWiseData) return;
        setCurrentYear(new Date().getFullYear());
        setCurrentMonth(Months[new Date().getMonth()]);
    }, [monthWiseData, value]);

    const gcpAccountData = (p) => {
        if (charts?.GCPCloudSpending && Object.keys(charts?.GCPCloudSpending[p]).length !== 0) {
            if (p in charts?.GCPCloudSpending) {
                const { dataSet, totalCost } = charts?.GCPCloudSpending[p];
                let data = dataSet.sort((a, b) => b.value - a.value).slice(0, 5);
                setAllCLoud({ data: data, cost: totalCost });
            } else {
                setAllCLoud(null);
            }
        } else {
            setAllCLoud(null);
        }
        if (charts?.TopFiveServices && Object.keys(charts?.TopFiveServices[p]).length !== 0) {
            if (p in charts?.TopFiveServices) {
                const { dataSet, totalCost } = charts?.TopFiveServices[p];
                let data = dataSet.sort((a, b) => b.value - a.value).slice(0, 5);
                setTop5Services({ data: data, cost: totalCost });
            } else {
                setTop5Services(null);
            }
        } else {
            setTop5Services(null);
        }
        if (charts?.SpendByProject && Object.keys(charts?.SpendByProject[p]).length !== 0) {
            if (p in charts?.SpendByProject) {
                const { dataSet, totalCost } = charts?.SpendByProject[p];
                let data = dataSet.sort((a, b) => b.value - a.value).slice(0, 5);
                setTopFiveProjects({ data: data, cost: totalCost });
            } else {
                setTopFiveProjects(null);
            }
        } else {
            setTopFiveProjects(null);
        }
        if (charts?.TopFiveLocations && Object.keys(charts?.TopFiveLocations[p]).length !== 0) {
            if (p in charts?.TopFiveLocations) {
                const { dataSet, totalCost } = charts?.TopFiveLocations[p];
                let data = dataSet.sort((a, b) => b.value - a.value).slice(0, 5);
                setTop5Locations({ data: data, cost: totalCost });
            } else {
                setTop5Locations(null);
            }
        } else {
            setTop5Locations(null);
        }
        if (charts?.SpendByAccounts && Object.keys(charts?.SpendByAccounts[p]).length !== 0) {
            if (p in charts?.SpendByAccounts) {
                const { dataSet, totalCost } = charts?.SpendByAccounts[p];
                let data = dataSet.sort((a, b) => b.value - a.value).slice(0, 5);
                setSpendBy5Accounts({ data: data, cost: totalCost });
            } else {
                setSpendBy5Accounts(null);
            }
        } else {
            setSpendBy5Accounts(null);
        }
    };

    function getOverlayData(d, m, y) {
        let data = null;
        let date = null;
        if (d && m && y) {
            data = monthWiseData
                .find((data) => data.year == y)
                ?.months?.find((data) => data.month == m)
                ?.dates.find((data) => data.date == y.toString() + m.toString() + d.toString());
            date = moment(data?.date || 0, "YYYYMMDD").format("MMM D, YYYY");
        } else if (m && y) {
            data = yearTableData.find((data) => data.month == y.toString() + m.toString());
            date = moment(data?.startDate || 0, "YYYYMMDD").format("MMM D, YYYY") + " - " + moment(data?.endDate || 0, "YYYYMMDD").format("MMM D, YYYY");
        }
        return { data, date };
    }

    useEffect(() => {
        if (currentMonth && monthWiseData) {
            let month = Months.indexOf(currentMonth) + 1;
            month = month < 10 ? "0" + month : month;

            const { data, date } = getOverlayData(null, month, currentYear);
            setOverlayData({ cost: data?.cost, data, date });
        }
    }, [currentMonth, currentYear]);

    async function onMonthSelect(m, y = null) {
        if (monthWiseData == null || !monthWiseData) {
            await get1YearTableData("daily").then(() => {
                setCurrentMonth(m);
            });
            setCurrentMonth(m);
        } else {
            setCurrentMonth(m);
        }

        if (y) setCurrentYear(y);
    }

    useEffect(() => {
        if (yearTableData) generateGroupedChartData({ gcp: { SpendByMonth: yearTableData } }, setGcpMonthWiseChartData, () => {});
    }, [yearTableData]);

    useEffect(() => {
        if (yearTableData) generateGroupedChartData({ gcp: { SpendByMonth: yearTableData } }, setOthersMonthWiseChartData, () => {}, ["billingAccounts", "services", "resources"]);
    }, [yearTableData]);

    return (
        <div className={classes.root}>
            <TabContext value={value}>
                    <TabList
                        onChange={handleChange}
                        // aria-label="simple tabs example"
                        textColor="primary"
                        indicatorColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        style={{maxWidth: 'calc(100vw - 80px)'}}
                    >
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={{height:26}} src={billing_icon} />
                                    Dashboard

                                </div>
                            }
                            value="2"
                            className={classes.tab}
                            {...a11yProps(6)}
                        />
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={DashboardIcon} />
                                    Dashboard
                                </div>
                            }
                            value="1"
                            className={classes.tab}
                            onClick={() => setresTab(false)}
                            {...a11yProps(1)}
                        />
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={gcp_project} />
                                    Project
                                </div>
                            }
                            value="3"
                            className={classes.tab}
                            {...a11yProps(2)}
                        />

                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={([styles.icon], { width: 20, height: 20, marginRight: 4 })} src={resource_group} />
                                    Services
                                </div>
                            }
                            value="4"
                            className={classes.tab}
                            onClick={() => setresTab(true)}
                            {...a11yProps(3)}
                        />
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={serverless_icon} height={30} />
                                    Serverless
                                </div>
                            }
                            value="5"
                            className={classes.tab}
                            {...a11yProps(5)}
                        />
                        <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={([styles.icon], { width: 20, height: 20, marginRight: 4 })} src={gcp_compute} />
                                Compute
                            </div>
                        }
                        value="6"
                        className={classes.tab}
                        onClick={() => setresTab(true)}
                        {...a11yProps(3)}
                    />
                     <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={([styles.icon], { width: 20, height: 20, marginRight: 4 })} src={gcp_storage} />
                                Storage
                            </div>
                        }
                        value="8"
                        className={classes.tab}
                        onClick={() => setresTab(true)}
                        {...a11yProps(3)}
                    />
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={([styles.icon], { width: 20, height: 20, marginRight: 4 })} src={gcp_big_query} />
                                    Big Query
                                </div>
                            }
                            value="7"
                            className={classes.tab}
                            onClick={() => setresTab(true)}
                            {...a11yProps(7)}
                        />
                    </TabList>
                    <Divider />
                <div style={{ marginTop: 0 }}></div>
                <TabPanel value="1">
                    <div onLoad={rootLoaded} id={"root_dashboard"}>
                        <br />
                        <Paper elevation={10}>
                            <div>
                                <div
                                    className={"accordian_item1"}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <h5 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20 }}>
                                        <img src={Total_Spend_Icon} height={25} /> Total spend
                                    </h5>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {totalSpendChartView && (
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <span
                                                    style={{
                                                        marginRight: 4,
                                                        fontSize: 12,
                                                        color: "#616161",
                                                    }}
                                                >
                                                    Last
                                                </span>
                                                <DateRange height={22} padding={1} fontSize={12} onSelectionChanged={setPeriod} defaultSelected={period} />
                                            </div>
                                        )}
                                        <IconButton onClick={() => setTotalSpendChartView(!totalSpendChartView)} style={{ outline: "none" }}>
                                            {totalSpendChartView ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Collapse in={totalSpendChartView}>
                                    <div style={{ padding: 10 }}>
                                        <SlideShow
                                            names={[
                                                "Spend by Project",
                                                // "Spend by Billing Accounts", "Top 5 Projects",
                                                "Top 5 services",
                                                "Top 5 locations",
                                            ]}
                                        >
                                            <div className="chart-align">
                                                {/*{topFiveProjects != null && currency ? (*/}
                                                    <ReactD3PieChart title={"Spend by Project"} cost={topFiveProjects?.cost} currency={currency} data={topFiveProjects?.data} />
                                                {/*) : (*/}
                                                {/*    <NO_DATA_AVAILABLE>{"No data found for " + period}</NO_DATA_AVAILABLE>*/}
                                                {/*)}*/}
                                            </div>

                                            <div className="chart-align">
                                                {/*{top5Services != null && currency ? (*/}
                                                    <ReactD3BarChart title={"Top 5 services"} cost={top5Services?.cost} currency={currency} mouseEffect={false} data={top5Services?.data} />
                                                {/*) : (*/}
                                                {/*    <NO_DATA_AVAILABLE>{"No data found for " + period}</NO_DATA_AVAILABLE>*/}
                                                {/*)}*/}
                                            </div>

                                            <div className="chart-align">
                                                {/*{top5Locations != null && currency ? (*/}
                                                    <ReactD3BarChart title={"Top 5 locations"} cost={top5Locations?.cost} currency={currency} mouseEffect={false} data={top5Locations?.data} />
                                                {/*) : (*/}
                                                {/*    <NO_DATA_AVAILABLE>{"No data found for " + period}</NO_DATA_AVAILABLE>*/}
                                                {/*)}*/}
                                            </div>
                                        </SlideShow>
                                    </div>
                                </Collapse>
                            </div>
                        </Paper>
                        <div>
                            <Paper elevation={10} style={{ marginTop: 20 }}>
                                <div>
                                    <div
                                        className={"accordian_item1"}
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <h5 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20 }}>
                                            <img src={Calendar_Spend_Icon} height={25} /> Spend by months
                                        </h5>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <IconButton onClick={() => setSpendByMonthsChartView(!spendByMonthsChartView)} style={{ outline: "none" }}>
                                                {spendByMonthsChartView ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div>
                                        {!spendByMonthsChartView && <hr style={{ backgroundColor: "#e4e4e4", padding: 0, margin: 0 }} />}
                                        <Collapse in={spendByMonthsChartView}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: 10,
                                                    paddingTop: 0,
                                                    //  justifyContent: 'center'
                                                }}
                                            >
                                                <div style={{ position: "absolute", zIndex: 10, marginLeft: 5 }}>
                                                    <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                        <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                    </IconButton>
                                                </div>
                                                <SlideShow delay={TIMERS.LOWER_CHART_INTERVAL} showingIndex={chartShowingIndex} names={["Spend by GCP", "Top 5 Services", "Top 5 Resources"]} view="All" arrowsHandled={true}>
                                                    <Paper>
                                                        <GroupedBarChart
                                                            maxWidth={null}
                                                            currency={Format.formatCurrencySymbol(currency)}
                                                            totalCost={null}
                                                            // mouseEffect={true}
                                                            data={gcpMonthWiseChartData}
                                                            cloudCount={1}
                                                        />
                                                    </Paper>
                                                    <Paper>
                                                        <StackedBarChart2
                                                            maxWidth={null}
                                                            currency={Format.formatCurrencySymbol(currency)}
                                                            totalCost={null}
                                                            keyStack={"services"}
                                                            keyField={"service"}
                                                            valueKey={"cost"}
                                                            // mouseEffect={true}
                                                            data={othersMonthWiseChartData}
                                                            cloudCount={1}
                                                        />
                                                    </Paper>
                                                    <Paper>
                                                        <StackedBarChart2
                                                            maxWidth={null}
                                                            currency={Format.formatCurrencySymbol(currency)}
                                                            totalCost={null}
                                                            keyStack={"resources"}
                                                            keyField={"skuId"}
                                                            valueKey={"cost"}
                                                            // mouseEffect={true}
                                                            data={othersMonthWiseChartData}
                                                            cloudCount={1}
                                                        />
                                                    </Paper>
                                                </SlideShow>
                                                <div style={{ position: "absolute", right: 30, zIndex: 10 }}>
                                                    <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                        <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                            </Paper>
                        </div>
                    </div>
                    {/* <GCPSubscriptions tenantLogo={gcp_org} currency={Format.formatCurrencySymbol(currency)} period={period} accountList={charts?.SpendByOrgAndBillingAccountWithProject || 0} /> */}
                </TabPanel>

                <TabPanel value="2" onLoad={rootLoaded} id={"root_dashboard"}>
                    {yearTableData || monthWiseData ? (
                        <div style={{ marginTop: 4,  maxWidth: 'calc(100vw - 120px)', overflow: "auto" }}>
                            <Paper style={{ padding: 5, marginTop: 10 }}>
                                {yearTableData && yearTableData.length ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            // margin: 10,
                                            marginBottom: 0,
                                        }}
                                    >
                                        <h5
                                            style={{
                                                fontWeight: "bold",
                                                marginTop: 6,
                                                marginRight: 4,
                                            }}
                                        >
                                            <img src={Calendar_Spend_Icon} height={25} /> Spend by months
                                        </h5>
                                    </div>
                                ) : null}
                                <hr style={{ padding: 0, margin: 0 }} />
                                <div
                                    style={{
                                        display: "flex",
                                        alignSelf: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <YearTableData
                                        selectedCloud={"gcp"}
                                        hideLegend={true}
                                        width={parentWidth}
                                        data={{
                                            gcp: yearTableData,
                                        }}
                                        months={yearTableData?.map((m) => m.month)}
                                        currency={Format.formatCurrencySymbol(currency || "USD")}
                                        onMonthPress={onMonthSelect}
                                        selectedMonth={currentMonth}
                                        selectedYear={currentYear}
                                    />
                                </div>
                            </Paper>
                            <div
                                style={{
                                    display: "flex",
                                    marginTop: 8,
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div style={{ flex: 0.5 }}>{monthInterval ? <PauseIcon onClick={() => onPause()} /> : <PlayArrow onClick={() => onResume()} />}</div>

                                <YearCalendarData
                                    currency={Format.formatCurrencySymbol(currency || "USD")}
                                    year={currentYear}
                                    onNext={() => setCurrentYear(currentYear + 1)}
                                    onPrev={() => setCurrentYear(currentYear - 1)}
                                    yearData={CalendarDataProcessor(yearTableData, "year")}
                                    monthData={CalendarDataProcessor(monthWiseData, "month")?.[currentYear]?.[currentMonth?.toString()?.substring(0, 3)] || null}
                                    onMonthSelect={onMonthSelect}
                                    month={currentMonth}
                                    renderOverlay={(d, m, y, cost) => {
                                        const { data, date } = getOverlayData(d, m, y);

                                        if (data)
                                            return setOverlayData({
                                                data,
                                                date,
                                                cost: data?.cost,
                                            });
                                        return null;
                                    }}
                                />
                                <div
                                    style={{
                                        marginLeft: 10,
                                        width: 500,
                                        height: 400,
                                        display: "flex",
                                    }}
                                >
                                    {overlayData && overlayData.data && (
                                        <OverlayComponent
                                            cost={overlayData?.cost}
                                            data={overlayData?.data}
                                            date={overlayData?.date}
                                            currency={Format.formatCurrencySymbol(currency || "USD")}
                                            dataKeys={[
                                                {
                                                    key: "services",
                                                    title: "Top Services",
                                                    subKey: "service",
                                                    icon: gcp_compute,
                                                    onClick: () => {
                                                        setValue("4");
                                                        props?.setActiveTab("4");
                                                    },
                                                },
                                                {
                                                    key: "resources",
                                                    title: "Top Resources",
                                                    subKey: "skuId",
                                                    icon: resource_group,
                                                    onClick: () => {
                                                    setValue("4");
                                                    props?.setActiveTab("4");
                                                    },
                                                },
                                            ]}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Typography>No data found</Typography>
                    )}
                    <div>
                        <br />
                        <Paper elevation={10}>
                            <div>
                                <div
                                    className={"accordian_item1"}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <h5 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20 }}>
                                        <img src={Total_Spend_Icon} height={25} /> Total spend
                                    </h5>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {totalSpendChartView && (
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <span
                                                    style={{
                                                        marginRight: 4,
                                                        fontSize: 12,
                                                        color: "#616161",
                                                    }}
                                                >
                                                    Last
                                                </span>
                                                <DateRange height={22} padding={1} fontSize={12} onSelectionChanged={setPeriod} defaultSelected={period} />
                                            </div>
                                        )}
                                        <IconButton onClick={() => setTotalSpendChartView(!totalSpendChartView)} style={{ outline: "none" }}>
                                            {totalSpendChartView ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Collapse in={totalSpendChartView}>
                                    <div style={{ padding: 10 }}>
                                        <SlideShow
                                            names={[
                                                "Spend by Project",
                                                // "Spend by Billing Accounts", "Top 5 Projects",
                                                "Top 5 services",
                                                "Top 5 locations",
                                            ]}
                                        >
                                            <div className="chart-align">
                                                {/*{topFiveProjects != null && currency ? (*/}
                                                <ReactD3PieChart title={"Spend by Project"} cost={topFiveProjects?.cost} currency={currency} data={topFiveProjects?.data} />
                                                {/*) : (*/}
                                                {/*    <NO_DATA_AVAILABLE>{"No data found for " + period}</NO_DATA_AVAILABLE>*/}
                                                {/*)}*/}
                                            </div>

                                            <div className="chart-align">
                                                {/*{top5Services != null && currency ? (*/}
                                                <ReactD3BarChart title={"Top 5 services"} cost={top5Services?.cost} currency={currency} mouseEffect={false} data={top5Services?.data} />
                                                {/*) : (*/}
                                                {/*    <NO_DATA_AVAILABLE>{"No data found for " + period}</NO_DATA_AVAILABLE>*/}
                                                {/*)}*/}
                                            </div>

                                            <div className="chart-align">
                                                {/*{top5Locations != null && currency ? (*/}
                                                <ReactD3BarChart title={"Top 5 locations"} cost={top5Locations?.cost} currency={currency} mouseEffect={false} data={top5Locations?.data} />
                                                {/*) : (*/}
                                                {/*    <NO_DATA_AVAILABLE>{"No data found for " + period}</NO_DATA_AVAILABLE>*/}
                                                {/*)}*/}
                                            </div>
                                        </SlideShow>
                                    </div>
                                </Collapse>
                            </div>
                        </Paper>
                        <div>
                            <Paper elevation={10} style={{ marginTop: 20 }}>
                                <div>
                                    <div
                                        className={"accordian_item1"}
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <h5 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20 }}>
                                            <img src={Calendar_Spend_Icon} height={25} /> Spend by months
                                        </h5>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <IconButton onClick={() => setSpendByMonthsChartView(!spendByMonthsChartView)} style={{ outline: "none" }}>
                                                {spendByMonthsChartView ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div>
                                        {!spendByMonthsChartView && <hr style={{ backgroundColor: "#e4e4e4", padding: 0, margin: 0 }} />}
                                        <Collapse in={spendByMonthsChartView}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: 10,
                                                    paddingTop: 0,
                                                    //  justifyContent: 'center'
                                                }}
                                            >
                                                <div style={{ position: "absolute", zIndex: 10, marginLeft: 5 }}>
                                                    <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                        <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                    </IconButton>
                                                </div>
                                                <SlideShow delay={TIMERS.LOWER_CHART_INTERVAL} showingIndex={chartShowingIndex} names={["Spend by GCP", "Top 5 Services", "Top 5 Resources"]} view="All" arrowsHandled={true}>
                                                    <Paper>
                                                        <GroupedBarChart
                                                            maxWidth={null}
                                                            currency={Format.formatCurrencySymbol(currency)}
                                                            totalCost={null}
                                                            // mouseEffect={true}
                                                            data={gcpMonthWiseChartData}
                                                            cloudCount={1}
                                                        />
                                                    </Paper>
                                                    <Paper>
                                                        <StackedBarChart2
                                                            maxWidth={null}
                                                            currency={Format.formatCurrencySymbol(currency)}
                                                            totalCost={null}
                                                            keyStack={"services"}
                                                            keyField={"service"}
                                                            valueKey={"cost"}
                                                            // mouseEffect={true}
                                                            data={othersMonthWiseChartData}
                                                            cloudCount={1}
                                                        />
                                                    </Paper>
                                                    <Paper>
                                                        <StackedBarChart2
                                                            maxWidth={null}
                                                            currency={Format.formatCurrencySymbol(currency)}
                                                            totalCost={null}
                                                            keyStack={"resources"}
                                                            keyField={"skuId"}
                                                            valueKey={"cost"}
                                                            // mouseEffect={true}
                                                            data={othersMonthWiseChartData}
                                                            cloudCount={1}
                                                        />
                                                    </Paper>
                                                </SlideShow>
                                                <div style={{ position: "absolute", right: 30, zIndex: 10 }}>
                                                    <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                        <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                            </Paper>
                        </div>
                    </div>

                </TabPanel>
                <TabPanel value="3">
                    <div>
                        {" "}
                        <GCPProjectTabProject tab={resTab} showData={rec} currency={Format.formatCurrencySymbol(currency)} selectedSubscription={selectedSubscription} billingAccount={charts} loading={loading} />
                    </div>
                </TabPanel>
                <TabPanel value="4">
                    <div>
                        <GCPServicesProjectHome tab={resTab} showData={rec} currency={Format.formatCurrencySymbol(currency)} selectedSubscription={selectedSubscription} projectId={projectId} loading={loading} />
                    </div>
                </TabPanel>
                <TabPanel value="5">
                    <div>
                        <GCPServerlessHome tab={resTab} showData={rec} currency={Format.formatCurrencySymbol(currency)} selectedSubscription={selectedSubscription} projectId={projectId} loading={loading} />
                    </div>
                </TabPanel>
                <TabPanel value="6">
                    <div> <GCPProjectCompute tab={resTab} showData={rec} currency={Format.formatCurrencySymbol(currency)} selectedSubscription={selectedSubscription} projectId={projectId} loading={loading} customerId={customerId} /> </div>
                </TabPanel>
                <TabPanel value="7">
                    <div>
                        <GCPBigQuery tab={resTab} showData={rec} currency={Format.formatCurrencySymbol(currency)}
                                     customerId={customerId}
                                     projectId={projectId}
                                     billingAccountId={selectedSubscription?.value}
                                     type={'project'} />
                    </div>
                </TabPanel>
                <TabPanel value="8">
                    <div>
                        <GCPProjectStorage tab={resTab} showData={rec} currency={Format.formatCurrencySymbol(currency)} selectedSubscription={selectedSubscription} projectId={projectId}  customerId={customerId} />
                    </div>
                </TabPanel>
                <TabPanel value="9">{/*<div></div>*/}</TabPanel>
                <TabPanel value="10">
                    {/*<div>*/}
                    {/*    /!* <AzureManagementGroup*/}
                    {/*        tab={resTab}*/}
                    {/*        // showData={rec}*/}
                    {/*        // tenantId={rec.tenantId}*/}
                    {/*        period={period}*/}
                    {/*        currency={Format.formatCurrencySymbol(currency)}*/}
                    {/*        progress={progress}*/}
                    {/*    /> *!/*/}
                    {/*</div>*/}
                </TabPanel>
                <TabPanel value="11">
                    {/*<div>*/}
                    {/*    /!* <AzureReservations*/}
                    {/*        tab={resTab}*/}
                    {/*        // showData={rec}*/}
                    {/*        period={period}*/}
                    {/*        currency={Format.formatCurrencySymbol(currency)}*/}
                    {/*        progress={progress}*/}
                    {/*    /> *!/*/}
                    {/*</div>*/}
                </TabPanel>
                <TabPanel value="12">
                    {/*<div>*/}
                    {/*    /!* <AzureCompute*/}
                    {/*        tab={resTab}*/}
                    {/*        // showData={rec}*/}
                    {/*        period={period}*/}
                    {/*        currency={Format.formatCurrencySymbol(currency)}*/}
                    {/*        progress={progress}*/}
                    {/*    /> *!/*/}
                    {/*</div>*/}
                </TabPanel>
                <TabPanel value="13">
                    {/*<div>*/}
                    {/*    /!* <AzureCosmosDB*/}
                    {/*        tab={resTab}*/}
                    {/*        // showData={rec}*/}
                    {/*        period={period}*/}
                    {/*        currency={Format.formatCurrencySymbol(currency)}*/}
                    {/*        progress={progress}*/}
                    {/*    /> *!/*/}
                    {/*</div>*/}
                </TabPanel>
            </TabContext>
        </div>
    );
}

const styles = {
    tab: {
        outline: "none",
    },
    labelContainer: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        marginBottom: -10,
        marginLeft: -15,
    },

    icon: { width: 18, height: 18, marginRight: 4 },
};
