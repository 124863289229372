import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import Routes from "./routes/index";
import { Worker } from "@react-pdf-viewer/core";
// import {
//   createMuiTheme,
//   MuiThemeProvider as ThemeProvider,
// } from "@material-ui/core";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Dashboard} from "@mui/icons-material";
import DashboardChart from "./pages/Charts/DashboardChart";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins_Regular",
  },
    palette: {
        primary: {
            main: '#3f51b5',
            light: '#7986cb',
            dark: '#303f9f'
        }
    }
});

function App() {

  return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
          <ThemeProvider theme={theme}>
              <Provider store={store}>
                  <BrowserRouter forceRefresh={true}>
                      <Routes />
                  </BrowserRouter>
              </Provider>
          </ThemeProvider>
      </Worker>
  );
}

export default App;
