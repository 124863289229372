import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getAzureCosmosDBMetricsSuccess, getAzureCosmosDBMetricsFailure, getAzureCosmosDBDetailsSuccess, getAzureCosmosDBDetailsFailure, getAzureCosmosDBChartDataSuccess, getAzureCosmosDBChartDataFailure } from "../actions/actions";
const { REACT_APP_AZURE_COSMOSDB_METRICS, REACT_APP_AZURE_COSMOSDB_DETAILS, REACT_APP_AZURE_COSMOSDB_CHARTDATA } = process.env;

function* getAzureCosmosDBMetricsData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AZURE_COSMOSDB_METRICS, {
            params: {
                customerId: payload.customerId,
                subscriptionId: payload.subscriptionId,
                resourceGroups: payload.resourceGroupName,
                databaseAccounts: payload.accountName,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureCosmosDBMetricsFailure(statusCode));
            } else {
                yield put(getAzureCosmosDBMetricsSuccess(res.data));
            }
        } else {
            yield put(getAzureCosmosDBMetricsFailure(res));
        }
    } catch (error) {
        yield put(getAzureCosmosDBMetricsFailure(error));
    }
}

function* getAzureCosmosDBDetailsData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AZURE_COSMOSDB_DETAILS, {
            params: {
                customerId: payload.customerId,
                subscriptionId: payload.subscriptionId,
                resourceGroups: payload.resourceGroupName,
                databaseAccounts: payload.accountName,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureCosmosDBDetailsFailure(statusCode));
            } else {
                yield put(getAzureCosmosDBDetailsSuccess(res.data));
            }
        } else {
            yield put(getAzureCosmosDBDetailsFailure(res));
        }
    } catch (error) {
        yield put(getAzureCosmosDBDetailsFailure(error));
    }
}

function* getAzureCosmosDBChartData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AZURE_COSMOSDB_CHARTDATA, {
            params: {
                customerId: payload.customerId,
                subscriptionId: payload.subscriptionId,
                resourceGroups: payload.resourceGroupName,
                databaseAccounts: payload.accountName,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureCosmosDBChartDataFailure(statusCode));
            } else {
                yield put(getAzureCosmosDBChartDataSuccess(res.data));
            }
        } else {
            yield put(getAzureCosmosDBChartDataSuccess(res));
        }
    } catch (error) {
        yield put(getAzureCosmosDBChartDataSuccess(error));
    }
}

export default function* azureCosmosDBMetricsSaga() {
    yield takeLatest(types.AZURE_COSMOSDB_METRICS_REQUEST, getAzureCosmosDBMetricsData);
    yield takeLatest(types.AZURE_COSMOSDB_DETAILS_REQUEST, getAzureCosmosDBDetailsData);
    yield takeLatest(types.AZURE_COSMOSDB_CHARTDATA_REQUEST, getAzureCosmosDBChartData);
}
