import moment from 'moment';

const Months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]

function getLocalTime(date) {
    const x = new Date();
    const offset = x.getTimezoneOffset() / 60;
}

function getTimeDifference(date) {
    const today = new Date();
    const someDate = new Date(date);
    const diff = today.getDate() - someDate.getDate();
    return diff;
}

export function formatTimeDifference(date) {
    let clock = moment(date, 'YYYY-MM-DD').fromNow();
    const diff = getTimeDifference(date);
    if (diff == 0) {
        return 'today';
    } else if (diff == 1) {
        return 'yesterday';
    } else if (date == null) {
        return 'today';
    } else {
        return clock;
    }
}

export function getCurrentMonth(){
    const date = new Date();
    return Months?.[date.getMonth()];
}

export function getLastMonth(){
    const date = new Date();
    return Months?.[date.getMonth()-1];
}
export function getMonthForDate(date){
    return Months?.[moment(date, "YYYYMMDD").month()];
}

export function getCurrentYear(){
    const date = new Date();
    return date.getFullYear();
}

export function getMonthsFromNow(months, fullMonthName = false, asJSON = false){
    const Months2 = [
        "",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    function shortner(str=""){
        return fullMonthName?str:str.substring(0,3);
    }
    // debugger;

    // const startMonth = moment().
    // months.map(m=>{
    //     const momentDate = moment(m.toString().substring(0, 4) + " " + m.toString().substring(4));
    //
    // })
        const date = new Date();

    const monthsArray = [
        ...Months.slice(
            date.getMonth(), 12
        ).map(u=>
            asJSON?
                {month: shortner(u), year: date.getFullYear()-1}:
                shortner(u)+ ` ${date.getFullYear()-1}`
        ),
        ...Months.slice(
            0,date.getMonth()+1
        ).map(u=>
            asJSON?
                {month: shortner(u), year: date.getFullYear()}:
                shortner(u)+ ` ${date.getFullYear()}`
        )
    ];
    return monthsArray || [];
    try {
        return months.map(m=>m.toString().substring(0, 4) + " " + Months2[parseInt(m.toString().substring((4)))].substring(0, 3));
    } catch (e){
        return [];
    }
}

// export function getMonthsFromNow(previousYearPostfix = "", currentYearPostfix = ""){
//     const date = new Date();
//     const monthsArray = [...Months.slice(date.getMonth(), 12).map(u=>u.substr(0,3)+previousYearPostfix), ...Months.slice(0,date.getMonth()+1).map(u=>u.substr(0,3)+currentYearPostfix)];
//     // return [];
//     return monthsArray;
// }
