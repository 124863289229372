import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getAzureServiceSuccess, getAzureServiceFailure,getAzureSubcriptionServiceSuccess,getAzureSubcriptionServiceFailure,
    getAzureTenantServiceSuccess,getAzureTeanatServiceFailure } from "../actions/actions";
const { REACT_APP_GET_AZURE_SERVICES, REACT_APP_GET_AZURE_SUBSCRIPTION_SERVICES,REACT_APP_GET_AZURE_TENANT_SERVICES } = process.env;

function* getAzureServicesSagaData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_GET_AZURE_SERVICES, {
            params: {
                customerId: payload.customerId,
            },
        });

        const { statusCode } = res.data;
        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureServiceFailure(statusCode));
            } else {
                yield put(getAzureServiceSuccess(res.data));
            }
        } else {
            yield put(getAzureServiceFailure(res));
        }
    } catch (error) {
        yield put(getAzureServiceFailure(error));
    }
}


function* getAzureTeanatServicesSagaData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_GET_AZURE_TENANT_SERVICES, {
            params: {
                customerId: payload.customerId,
                tenantId: payload.tenantId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureTeanatServiceFailure(statusCode));
            } else {
                yield put(getAzureTenantServiceSuccess(res.data));
            }
        } else {
            yield put(getAzureTeanatServiceFailure(res));
        }
    } catch (error) {
        yield put(getAzureTeanatServiceFailure(error));
    }
}

function* getAzureSubServicesSagaData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_GET_AZURE_SUBSCRIPTION_SERVICES, {
            params: {
                customerId: payload.customerId,
                tenantId: payload.tenantId,
                subscriptionId: payload.subscriptionId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureSubcriptionServiceFailure(statusCode));
            } else {
                yield put(getAzureSubcriptionServiceSuccess(res.data));
            }
        } else {
            yield put(getAzureSubcriptionServiceFailure(res));
        }
    } catch (error) {
        yield put(getAzureSubcriptionServiceFailure(error));
    }
}

export default function* azureServicesSaga() {
    yield takeLatest(types.AZURE_SERVICES_REQUEST, getAzureServicesSagaData);
    yield takeLatest(types.AZURE_SUBSCRIPTION_SERVICES_REQUEST, getAzureSubServicesSagaData);
    yield takeLatest(types.AZURE_TENANT_SERVICES_REQUEST, getAzureTeanatServicesSagaData);
}
