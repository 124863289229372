import * as types from "../actions/types";

const userState = {
    ComputeData: null,
    ComputeAccountData: null,
    ComputeProjectAccountData: null,
    error: null,
    loading: false,
    ComputeAccountLoading: false,
    ComputeProjectAccountLoading: false,
};

function gcpComputeReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.GCP_COMPUTE_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.GCP_COMPUTE_SUCCESS: {
            return {
                ...state,
                ComputeData: payload.data,
                loading: false,
            };
        }
        case types.GCP_COMPUTE_FAILURE: {
            return {
                ...state,
                loading: false,
                ComputeData: null,
                error: payload,
            };
        }

        case types.GCP_COMPUTE_ACCOUNT_REQUEST: {
            return {
                ...state,
                ComputeAccountLoading: true,
            };
        }
        case types.GCP_COMPUTE_ACCOUNT_SUCCESS: {
            return {
                ...state,
                ComputeAccountData: payload.data,
                ComputeAccountLoading: false,
            };
        }
        case types.GCP_COMPUTE_ACCOUNT_FAILURE: {
            return {
                ...state,
                ComputeAccountLoading: false,
                ComputeAccountData: null,
                error: payload,
            };
        }

        case types.GCP_COMPUTE_PROJECT_ACCOUNT_REQUEST: {
            return {
                ...state,
                ComputeProjectAccountLoading: true,
            };
        }
        case types.GCP_COMPUTE_PROJECT_ACCOUNT_SUCCESS: {
            return {
                ...state,
                ComputeProjectAccountData: payload.data,
                ComputeProjectAccountLoading: false,
            };
        }
        case types.GCP_COMPUTE_PROJECT_ACCOUNT_FAILURE: {
            return {
                ...state,
                ComputeProjectAccountLoading: false,
                ComputeProjectAccountData: null,
                error: payload,
            };
        }

        default:
            return state;
    }
}
export default gcpComputeReducer;
