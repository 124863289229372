import React, { useEffect, useState, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, MenuItem, Box, CircularProgress, Divider, IconButton, Typography, Fade, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { makeStyles, withStyles } from "MakeWithStyles";
import * as types from "../../redux/actions/types";
import { useSelector, useDispatch } from "react-redux";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import axios from "../../connection/axios";
import "./style.css";
import { Edit } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupIcon from '@mui/icons-material/Group';
import colors from "styles/color";
const { REACT_APP_GET_USER_MANAGEMENT, REACT_APP_CREATE_TEAM, REACT_APP_ADD_USERS, REACT_APP_GET_INVITED_TEAMS, REACT_APP_ACCEPT_INVITATION } = process.env;

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles()((theme) => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: "30ch",
        },
    },
    teamId: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: "35ch",
        },
    },
    button: {
        margin: theme.spacing(1),
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    btnStyle: {
        background: "linear-gradient(45deg, #3f51b5 50%, #3f51b5 100%)",
        border: 0,
        borderRadius: 3,
        boxShadow: "0 3px 5px 2px rgba(63, 81, 181, .3)",
        color: "white",
        height: 40,
        padding: "0 40px",
        textTransform: "none",
    },
}));

var acceptedTeams = [];

const roles = [
    {
        label: "Reader",
        enabled: true,
    },
    {
        label: "Contributor",
        enabled: false,
    },
    {
        label: "Owner",
        enabled: false,
    },
];

export default function UserManagement(props) {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const {classes} = useStyles();
    const { customerId, logInEmail, userName } = props;
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [userData, setuserData] = useState(null);
    const [editTeam, seteditTeam] = useState(false);
    const [teamName, setTeamName] = useState("");
    const [myTeam, setmyTeam] = useState("");
    const [teamNameError, setTeamNameError] = useState("");
    const [invitedTeamId, setinvitedTeamId] = useState("");
    const [invitedEmailId, setInvitedEmailId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [action, setAction] = useState("");
    const [userid, setUserId] = useState("");
    const [role, setRole] = useState("Reader");
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [fieldError, setFieldError] = useState({ firstName: "", lastName: "", email: "", teamId: "", invitedEmail: "" });
    const [addUserFuncError, setAddUserFuncError] = useState(null);
    const [directory, setDirectory] = useState();
    const [directoryError, setDirectoryError] = useState(null);

    var emailRegx = /^[a-zA-Z0-9._-]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    var nameRegx = /^[a-zA-Z ]+$/;
    var teamId = /^[a-z0-9-]+$/;

    const team = useSelector((state) => state.userDetailsReducer.customerId);
    const TeamRole = useSelector((state) => state.userDetailsReducer.role);

    const handleChangeTeam = (event) => {
        const { value } = event.target;
        let obj = acceptedTeams.find((i) => i.value === value);
        dispatch({ type: "SWITCH_TEAM_CLEAR" });
        setDirectory(value);
        dispatch({ type: types.SWITCH_TEAM, payload: { id: value, role: obj.role } });
        // navigate("/dashboard/charts");
    };

    const handleChange = (e) => {
        e.preventDefault();
        const { id, value } = e.target;

        switch (id) {
            case "first-name":
                setFirstName(value);
                setFieldError({ firstName: nameRegx.test(value) ? "" : value.length > 0 ? "Name should be in character" : "Field should not be empty" });
                break;
            case "email-filled":
                setEmail(value);
                setFieldError({ email: emailRegx.test(value) ? "" : value.length > 0 ? "Email is not valid!" : "Field should not be empty" });
                break;
            case "invited-email-id":
                setInvitedEmailId(value);
                setFieldError({ invitedEmail: emailRegx.test(value) ? "" : value.length > 0 ? "Email is not valid!" : "Field should not be empty" });
                break;
            case "last-name":
                setLastName(value);
                setFieldError({ lastName: nameRegx.test(value) ? "" : value.length > 0 ? "Name should be in character" : "Field should not be empty" });
                break;
            case "team-id":
                setinvitedTeamId(value.trim());
                setFieldError({ teamId: teamId.test(value.trim()) ? "" : value.length > 0 ? "Invalid team id" : "Field should not be empty" });
                break;
            case "team-name":
                setTeamName(value);
                setTeamNameError(value.length >= 8 ? "" : value.length > 0 ? "Team name should be more than 8 char" : "");
            default:
                break;
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
        setinvitedTeamId("");
    };

    const handleClose = () => {
        setOpen(false);
        setAddUserFuncError(null);
    };

    useEffect(() => {
        acceptedTeams = [];
        if (team) {
            setDirectory(team);
        }
        setDirectoryError(null);
        setAddUserFuncError(null);
        setTeamNameError("");
        const asyncFunc = async () => {
            setLoading1(true);
            const data = await axios.get(REACT_APP_GET_USER_MANAGEMENT, { params: { customerId } });
            const addedTeams = await axios.get(REACT_APP_GET_INVITED_TEAMS, { params: { customerId } });
            let dataTeam = data?.data;
            if(dataTeam && dataTeam.statusCode == 200){
                acceptedTeams.push({ label: `Default account(${dataTeam?.data?.team_name})`, value: customerId, role: "Owner" });
               setmyTeam(dataTeam?.data?.team_name);
            }else{
                acceptedTeams.push({ label: `Default account(${userName})`, value: customerId, role: "Owner" });
                setmyTeam(userName);
            }
            setuserData(data.data);
            if (addedTeams && addedTeams.data.statusCode == 200) {
                let val = addedTeams.data.data;
                val.invitedTeam.map((i) => acceptedTeams.push({ label: i.team_name, value: i.customerId, role: i.role, owner: i.owner, email: i.email, team_id: i.team_id }));
            }
            setLoading1(false);
        };
        asyncFunc();
    }, []);

    async function teamButton(value) {
        setLoading(true);
        if ("team_id" in value && "name" in value && value.name.length > 0) {
            setTeamNameError("");
            setmyTeam("");
            let data = { team_name: value.name };
            let getData = await axios.post(REACT_APP_CREATE_TEAM, data, { params: { customerId } });
            let dataTeam = getData?.data;
            if(dataTeam && dataTeam.statusCode == 200){
               setmyTeam(dataTeam?.data?.team_name);
            }else{
                setmyTeam(userName);
            }
            setuserData(getData.data);
            setLoading(false);
            seteditTeam(false);
        } else {
            setTeamNameError("Required team name is empty");
            setLoading(false);
        }
    }

    async function acceptTeamInvite(value) {
        if ("team_id" in value && value.team_id.length > 0) {
            acceptedTeams = [];
            acceptedTeams.push({ label: `Default account(${myTeam})`, value: customerId, role: "Owner" });
            setLoading2(true);
            let obj = { customerId: customerId, team_id: value.team_id, email: value.email };
            const data = await axios.get(REACT_APP_ACCEPT_INVITATION, { params: obj });
            let val = data.data;
            if (val && val.statusCode == 200) {
                val.data.invitedTeam.map((i) => acceptedTeams.push({ label: i.team_name, value: i.customerId, role: i.role, owner: i.owner, email: i.email, team_id: i.team_id }));
                setDirectoryError({ status: "success", message: val.successMessage });
                dispatch({
                    type: types.GET_CONNECTED_TEAMS_LIST_REQUEST,
                    payload: customerId,
                });
                setTimeout(() => handleClose1(), 3000);
            } else {
                setDirectoryError({ status: "error", message: val.errorMessage });
            }
            setLoading2(false);
        } else {
            setDirectoryError({ status: "error", message: "Input field should not be empty" });
        }
    }

    async function dataFunction(obj, type) {
        setLoading(true);
        let data = await axios.post(REACT_APP_ADD_USERS, obj, { params: { customerId: customerId, action: type } });
        try {
            if (data) {
                setLoading(false);
                return data;
            }
        } catch (error) {
            setLoading(false);
        }
    }

    async function addUserFunc(obj, type) {
        let { firstName, lastName, email } = fieldError;
        try {
            if (obj && type && type == "addUser") {
                if (obj.first_name != "" && obj.last_name != "" && obj.email != "" && obj.role != "") {
                    if ((firstName == "" || firstName == undefined) && (lastName == "" || lastName == undefined) && (email == "" || email == undefined)) {
                        let addData = await dataFunction(obj, type);
                        let val = addData.data;
                        if (val && val.statusCode == 200) {
                            setuserData(addData.data);
                            handleClose();
                        } else {
                            setAddUserFuncError(val.errorMessage);
                        }
                    }
                }
            } else if (obj && type && obj.userId != "" && type == "deleteUser") {
                let deleteData = await dataFunction(obj, type);
                setuserData(deleteData.data);
                handleClose();
            } else {
                if (obj && type && type == "updateUser" && obj.userId != "" && obj.first_name != "" && obj.last_name != "" && obj.role != "") {
                    if ((firstName == "" || firstName == undefined) && (lastName == "" || lastName == undefined)) {
                        let updateData = await dataFunction(obj, type);
                        let val = updateData.data;
                        if (val && val.statusCode == 200) {
                            setuserData(updateData.data);
                            handleClose();
                        } else {
                            setAddUserFuncError(val.errorMessage);
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    async function handleRefresh() {
        if (team != null && TeamRole != null) {
            setLoading1(true);
            const addedTeams = await axios.get(REACT_APP_GET_INVITED_TEAMS, { params: { customerId } });
            if (addedTeams && addedTeams.data.statusCode == 200) {
                const val = addedTeams.data.data;
                const live = val.invitedTeam.find((item) => item.customerId === team);
                if (live != undefined) {
                    dispatch({ type: types.SWITCH_TEAM, payload: { id: teamId, role: role } });
                } else {
                    dispatch({ type: types.SWITCH_TEAM, payload: { id: null, role: null } });
                }
                setLoading1(false);
            } else {
                setLoading1(false);
            }
        }
        try {
            setLoading1(true);
            acceptedTeams = [];
            acceptedTeams.push({ label: `Default account(${myTeam})`, value: customerId, role: "Owner" });
            const data = await axios.get(REACT_APP_GET_USER_MANAGEMENT, { params: { customerId } });
            setuserData(data.data);
            const addedTeams = await axios.get(REACT_APP_GET_INVITED_TEAMS, { params: { customerId } });
            if (addedTeams && addedTeams.data.statusCode == 200) {
                let val = addedTeams.data.data;
                val.invitedTeam.map((i) => acceptedTeams.push({ label: i.team_name, value: i.customerId, role: i.role, owner: i.owner, email: i.email, team_id: i.team_id }));
            }
        } catch (error) {
        } finally {
            setLoading1(false);
        }
    }

    return (
        <div style={{ marginTop: 20 }} className={'connection_buttons'}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <GroupIcon style={{color: '#404040',marginRight: 3}} />
                <span>Team management</span>
                <h6 style={{ marginLeft: 10, border: "1px solid", cursor: "pointer", fontSize: 12, color: !loading1 ? colors.blue : colors.gray }} onClick={handleRefresh}>
                    <RefreshIcon fontSize="small" />
                </h6>
            </div>
            {!loading1 ? (
                <div>
                    <Paper style={{ padding: 20 }} elevation={10}>
                        {userData && userData.statusCode !== 200 ? (
                            <div>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div>
                                        <Box flexDirection={"row"} display={"flex"} alignItems={"center"}>
                                            <TextField
                                                required
                                                id="team-name"
                                                label="Enter team name"
                                                variant="outlined"
                                                value={teamName}
                                                onChange={handleChange}
                                                helperText={<span style={{ color: "red" }}>{teamNameError}</span>}
                                            />
                                            <Button style={{ marginLeft: 20 }} onClick={() => teamButton({ team_id: "", name: teamName })} className={classes.btnStyle}>
                                                {loading ? <CircularProgress size={25} style={{ color: "white" }} /> : "Create Team"}
                                            </Button>
                                        </Box>
                                    </div>
                                    <div style={{ marginLeft: 20 }}>
                                        <Dialog
                                            open={open1}
                                            TransitionComponent={Transition}
                                            keepMounted
                                            onClose={handleClose1}
                                            aria-labelledby="alert-dialog-slide-title"
                                            aria-describedby="alert-dialog-slide-description"
                                        >
                                            <Dialogtitle id="customized-dialog-title" onClose={handleClose1}>
                                                {"Accept invitation"}
                                            </Dialogtitle>
                                            <Fade in={open1}>
                                                <div style={{ maxHeight: 650 }} className="overflow-auto">
                                                    <DialogContent dividers>
                                                        <form className={classes.teamId} noValidate autoComplete="off">
                                                            <Box flexDirection={"column"} display={"flex"} alignItems={"center"}>
                                                                <TextField
                                                                    id="team-id"
                                                                    value={invitedTeamId}
                                                                    label="Enter invited team ID"
                                                                    onChange={handleChange}
                                                                    variant="outlined"
                                                                    helperText={<span style={{ color: "red" }}>{fieldError.teamId}</span>}
                                                                />
                                                                <TextField
                                                                    id="invited-email-id"
                                                                    value={invitedEmailId}
                                                                    label="Enter invited email ID"
                                                                    onChange={handleChange}
                                                                    variant="outlined"
                                                                    helperText={<span style={{ color: "red" }}>{fieldError.invitedEmail}</span>}
                                                                />
                                                            </Box>
                                                        </form>
                                                        {directoryError && (
                                                            <div style={{ textAlign: "center" }}>
                                                                <span style={{ color: directoryError.status == "error" ? "red" : "green" }}>{directoryError.message}</span>
                                                            </div>
                                                        )}
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button style={{ marginLeft: 20 }} onClick={() => acceptTeamInvite({ team_id: invitedTeamId, email: invitedEmailId })} className={classes.btnStyle}>
                                                            {loading2 ? <CircularProgress size={25} style={{ color: "white" }} /> : "Connect"}
                                                        </Button>
                                                    </DialogActions>
                                                </div>
                                            </Fade>
                                        </Dialog>
                                        <div style={{ marginLeft: 20 }}>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                {acceptedTeams && acceptedTeams.length <= 1 ? (
                                                    <div style={{ marginRight: 40, display: "flex", justifyContent: "center" }}>
                                                        <Button
                                                            className={classes.btnStyle}
                                                            onClick={() => {
                                                                handleOpen1();
                                                                setInvitedEmailId(logInEmail);
                                                                setFieldError({ teamId: "", invitedEmail: "" });
                                                                setDirectoryError(null);
                                                            }}
                                                        >
                                                            Accept invitation
                                                        </Button>
                                                    </div>
                                                ) : null}
                                                <form className={classes.teamId} noValidate autoComplete="off">
                                                    <TextField
                                                        id="standard-select-directory-native"
                                                        select
                                                        label="Switch Team"
                                                        value={directory}
                                                        onChange={handleChangeTeam}
                                                        SelectProps={{
                                                            native: true,
                                                        }}
                                                        helperText=""
                                                    >
                                                        {acceptedTeams.map((option) => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </TextField>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {acceptedTeams && acceptedTeams.length > 1 ? (
                                    <div style={{ marginTop: 20 }}>
                                        <div style={{ marginBottom: 4, display: "flex", alignItems: "center" }}>
                                            <h6 style={{ fontWeight: "bold" }}>Access to other teams</h6>
                                            <Button
                                                className={classes.btnStyle}
                                                onClick={() => {
                                                    handleOpen1();
                                                    setInvitedEmailId(logInEmail);
                                                    setFieldError({ teamId: "", invitedEmail: "" });
                                                    setDirectoryError(null);
                                                }}
                                                style={{ marginLeft: 10 }}
                                            >
                                                Accept invitation
                                            </Button>
                                        </div>
                                        <table className="userMng">
                                            <tr>
                                                <th>Team name</th>
                                                <th>Team ID</th>
                                                <th>Role</th>
                                                <th>Owner name</th>
                                                <th>Email address</th>
                                            </tr>
                                            {acceptedTeams.length > 1 ? (
                                                acceptedTeams.map((i, j) =>
                                                    "owner" in i || "email" in i ? (
                                                        <tr index={j}>
                                                            <td>{i.label}</td>
                                                            <td>{i.team_id}</td>
                                                            <td>{i.role}</td>
                                                            <td>{i.owner}</td>
                                                            <td>{i.email}</td>
                                                        </tr>
                                                    ) : null
                                                )
                                            ) : (
                                                <h6 style={{ textAlign: "center" }}>No team added</h6>
                                            )}
                                        </table>
                                    </div>
                                ) : null}
                            </div>
                        ) : (
                            <>
                                <div style={{ marginBottom: 20 }}>
                                    <Dialog open={open1} TransitionComponent={Transition} keepMounted onClose={handleClose1} aria-labelledby="responsive-dialog-title" aria-describedby="alert-dialog-slide-description">
                                        <Dialogtitle id="customized-dialog-title" onClose={handleClose1}>
                                            {"Accept invitation"}
                                        </Dialogtitle>
                                        <Fade in={open1}>
                                            <div style={{ maxHeight: 650 }} className="overflow-auto">
                                                <DialogContent dividers>
                                                    <form className={classes.teamId} noValidate autoComplete="off">
                                                        <Box flexDirection={"column"} display={"flex"} alignItems={"center"}>
                                                            <TextField
                                                                id="team-id"
                                                                value={invitedTeamId}
                                                                label="Enter invited team ID"
                                                                onChange={(e) => setinvitedTeamId(e.target.value.trim())}
                                                                variant="outlined"
                                                                helperText=""
                                                            />
                                                            <TextField
                                                                id="invited-email-id"
                                                                value={invitedEmailId}
                                                                label="Enter invited email ID"
                                                                onChange={handleChange}
                                                                variant="outlined"
                                                                helperText={<span style={{ color: "red" }}>{fieldError.invitedEmail}</span>}
                                                            />
                                                        </Box>
                                                    </form>
                                                    {directoryError && (
                                                        <div style={{ textAlign: "center" }}>
                                                            <span style={{ color: directoryError.status == "error" ? "red" : "green" }}>{directoryError.message}</span>
                                                        </div>
                                                    )}
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button style={{ marginLeft: 20 }} onClick={() => acceptTeamInvite({ team_id: invitedTeamId, email: invitedEmailId })} className={classes.btnStyle}>
                                                        {loading2 ? <CircularProgress size={25} style={{ color: "white" }} /> : "Connect"}
                                                    </Button>
                                                </DialogActions>
                                            </div>
                                        </Fade>
                                    </Dialog>
                                    <div>
                                        <div style={{ display: "flex", justifyContent: "flex-end",marginTop: -10}}>
                                            <Typography style={{ marginTop: 10, fontWeight: "bold" }}>Switch Team : </Typography>
                                            <form className={classes.teamId} noValidate autoComplete="off">
                                                <TextField
                                                    id="standard-select-directory-native"
                                                    select
                                                    label=""
                                                    value={directory}
                                                    onChange={handleChangeTeam}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    helperText=""
                                                >
                                                    {acceptedTeams.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </form>
                                        </div>
                                    </div>
                                    <h6 style={{ fontWeight: "bold" }}>My Team</h6>
                                </div>

                                <div>
                                    <div>
                                        <div style={{ display: "flex", justifyContent: "spacebetween", alignItems: "center" }}>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <h6 style={{fontWeight: "bold",marginRight:4 }}>Team name:</h6>
                                                <h6>{editTeam ? <input type="text" style={{ padding: 5 }} value={teamName} onChange={(e) => setTeamName(e.target.value)} /> : userData && userData.data.team_name}</h6>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center",marginLeft:15 }}>
                                                <h6>
                                                    {editTeam ? (
                                                        <Button
                                                            className={classes.btnStyle}
                                                            onClick={() => {
                                                                teamButton({ team_id: userData.data.team_id, name: teamName });
                                                            }}
                                                        >
                                                            {loading ? <CircularProgress size={25} style={{ color: "white" }} /> : "Update"}
                                                        </Button>
                                                    ) : (
                                                        <IconButton
                                                            aria_label="edit"
                                                            onClick={() => {
                                                                seteditTeam(true);
                                                                setTeamName(userData.data.team_name);
                                                                teamButton({ team_id: userData.data.team_id });
                                                            }}
                                                        >
                                                            <Edit />
                                                        </IconButton>
                                                    )}
                                                </h6>
                                            </div>
                                            </div>

                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <h6 style={{ marginLeft: 15,marginRight:4, fontWeight: "bold" }}>Team ID: </h6>
                                                <h6>{userData && userData.data.team_id}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{ marginBottom: 4, display: "flex", alignItems: "center" }}>
                                                <h6 style={{ fontWeight: "bold" }}>{"Team members"}</h6>
                                                <h6 style={{marginTop:5}}>{`(${ userData && userData.data.team_name})`}</h6>
                                                <Button
                                                    className={classes.btnStyle}
                                                    onClick={() => {
                                                        handleOpen();
                                                        setAction("addUser");
                                                        setFirstName("");
                                                        setLastName("");
                                                        setEmail("");
                                                        setRole("Reader");
                                                        setFieldError({ firstName: "", lastName: "", email: "" });
                                                    }}
                                                    style={{ marginLeft: 10 }}
                                                >
                                                    Invite User
                                                </Button>
                                            </div>

                                            <table className="userMng">
                                                <tr>
                                                    <th>First name</th>
                                                    <th>Last name</th>
                                                    <th>Email address</th>
                                                    <th>Role</th>
                                                    <th>Status</th>
                                                    <th>Actions</th>
                                                </tr>
                                                {userData && userData.data.users.length > 0 ? (
                                                    userData.data.users.map((i, j) => (
                                                        <tr index={j}>
                                                            <td>{i.first_name}</td>
                                                            <td>{i.last_name}</td>
                                                            <td>{i.email}</td>
                                                            <td>{i.role}</td>
                                                            <td style={{ color: i.status == "Invited" ? "blue" : "green" }}>{i.status}</td>
                                                            <td>
                                                                <IconButton
                                                                    aria_label="edit"
                                                                    onClick={() => {
                                                                        setFirstName(i.first_name);
                                                                        setLastName(i.last_name);
                                                                        setRole(i.role);
                                                                        setEmail(i.email);
                                                                        setUserId(i.userId);
                                                                        handleOpen();
                                                                        setAction("updateUser");
                                                                    }}
                                                                >
                                                                    <Edit />
                                                                </IconButton>
                                                                <IconButton
                                                                    aria_label="delete"
                                                                    onClick={() => {
                                                                        setUserId(i.userId);
                                                                        setFirstName(i.first_name);
                                                                        setLastName(i.last_name);
                                                                        handleOpen();
                                                                        setAction("deleteUser");
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <span style={{ textAlign: "center" }}>No user added</span>
                                                )}
                                            </table>
                                        </div>
                                    </div>
                                    <Divider style={{ marginTop: 20,marginBottom:20 }} variant="middle" />

                                    <div style={{ marginTop: 20 }}>
                                        <div style={{ marginBottom: 4, display: "flex", alignItems: "center" }}>
                                            <h6>Access to other teams</h6>
                                            <Button
                                                className={classes.btnStyle}
                                                onClick={() => {
                                                    handleOpen1();
                                                    setInvitedEmailId(logInEmail);
                                                    setFieldError({ teamId: "", invitedEmail: "" });
                                                    setDirectoryError(null);
                                                }}
                                                style={{ marginLeft: 10 }}
                                            >
                                                Accept invitation
                                            </Button>
                                        </div>
                                        {acceptedTeams && acceptedTeams.length > 1 ? (
                                        <table className="userMng">
                                            <tr>
                                                <th>Team name</th>
                                                <th>Team ID</th>
                                                <th>Role</th>
                                                <th>Owner name</th>
                                                <th>Email address</th>
                                            </tr>

                                                {acceptedTeams.map((i, j) =>
                                                    "owner" in i || "email" in i ? (
                                                        <tr index={j}>
                                                            <td>{i.label}</td>
                                                            <td>{i.team_id}</td>
                                                            <td>{i.role}</td>
                                                            <td>{i.owner}</td>
                                                            <td>{i.email}</td>
                                                        </tr>
                                                    ) : null
                                                )}

                                        </table>
                                        ) : (
                                            <h6 style={{ display: "flex", justifyContent: "center" }}>No team added</h6>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </Paper>
                    <div>
                        <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-labelledby="responsive-dialog-title" aria-describedby="alert-dialog-slide-description">
                            <Dialogtitle id="customized-dialog-title" onClose={handleClose}>
                                {action == "addUser" ? "Invite User" : action == "updateUser" ? "Update User" : "Delete User"}
                            </Dialogtitle>
                            <Fade in={open}>
                                <Paper>
                                    <div style={{ maxHeight: 650 }} className="overflow-auto">
                                        {action && (action == "addUser" || action == "updateUser") ? (
                                            <DialogContent dividers>
                                                <form className={classes.root} noValidate autoComplete="off">
                                                    <Box flexDirection={"row"} display={"flex"} alignItems={"center"}>
                                                        <TextField
                                                            id="first-name"
                                                            label="First name"
                                                            value={firstName}
                                                            onChange={handleChange}
                                                            helperText={<span style={{ color: "red" }}>{fieldError.firstName}</span>}
                                                            style={{ marginRight: 20 }}
                                                        />
                                                        <TextField id="last-name" label="Last name" value={lastName} onChange={handleChange} helperText={<span style={{ color: "red" }}>{fieldError.lastName}</span>} />
                                                    </Box>
                                                    <Box flexDirection={"row"} display={"flex"} alignItems={"center"}>
                                                        {action && action == "addUser" ? (
                                                            <TextField
                                                                id="email-filled"
                                                                label="Email"
                                                                value={email}
                                                                onChange={handleChange}
                                                                helperText={<span style={{ color: "red" }}>{fieldError.email}</span>}
                                                                style={{ marginRight: 20 }}
                                                            />
                                                        ) : (
                                                            <TextField disabled id="filled-email" label="Email" value={email} />
                                                        )}
                                                        <TextField id="filled-role" select label="Select role" value={role} onChange={(e) => setRole(e.target.value)}>
                                                            {roles.map((option) => (
                                                                <MenuItem disabled={!option.enabled} key={option.label} value={option.label}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Box>
                                                    {addUserFuncError && <div style={{ textAlign: "center", color: "red" }}>{addUserFuncError}</div>}
                                                </form>
                                            </DialogContent>
                                        ) : (
                                            <div>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-slide-description">
                                                        You want to delete: <b>{firstName + " " + lastName}</b>
                                                    </DialogContentText>
                                                </DialogContent>
                                            </div>
                                        )}
                                        <div style={{ marginTop: 20 }}>
                                            {action && action == "addUser" ? (
                                                <DialogActions>
                                                    <Button
                                                        className={classes.btnStyle}
                                                        onClick={() => {
                                                            let obj = { first_name: firstName, last_name: lastName, email: email, role: role };
                                                            addUserFunc(obj, action);
                                                            // handleClose();
                                                        }}
                                                        endIcon={<PersonAddIcon />}
                                                    >
                                                        {loading ? <CircularProgress size={25} style={{ color: "white" }} /> : "Invite user"}
                                                    </Button>
                                                </DialogActions>
                                            ) : action == "updateUser" ? (
                                                <DialogActions>
                                                    <Button
                                                        className={classes.btnStyle}
                                                        onClick={() => {
                                                            let obj = { userId: userid, first_name: firstName, last_name: lastName, email: email, role: role };
                                                            addUserFunc(obj, action);
                                                        }}
                                                    >
                                                        {loading ? <CircularProgress size={25} style={{ color: "white" }} /> : "Update"}
                                                    </Button>
                                                </DialogActions>
                                            ) : (
                                                <DialogActions>
                                                    <Button onClick={handleClose} color="primary">
                                                        cancel
                                                    </Button>
                                                    <Button
                                                        className={classes.btnStyle}
                                                        onClick={() => {
                                                            addUserFunc({ userId: userid }, action);
                                                        }}
                                                    >
                                                        {loading ? <CircularProgress size={25} style={{ color: "white" }} /> : "Delete"}
                                                    </Button>
                                                </DialogActions>
                                            )}
                                        </div>
                                    </div>
                                </Paper>
                            </Fade>
                        </Dialog>
                    </div>
                </div>
            ) : (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20" }}>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
}

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        background: colors.tableHeader,
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

function Dialogtitle2(props){
    const { children, classes, onClose, ...other } = props;
    // const classes = withStyles.getClasses(props);

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
}

const Dialogtitle = withStyles(Dialogtitle2, styles);
