import * as types from "../actions/types";

const userState = {
    dynamoDBData: null,
    dynamoDBAccountData: null,
    error: null,
    loading: false,
    dyamoDBAccountLoading: false,
};

function awsDynamoDBReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.AWS_DYNAMODB_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AWS_DYNAMODB_SUCCESS: {
            return {
                ...state,
                dynamoDBData: payload.data,
                loading: false,
            };
        }
        case types.AWS_DYNAMODB_FAILURE: {
            return {
                ...state,
                loading: false,
                dynamoDBData: null,
                error: payload,
            };
        }

        case types.AWS_DYNAMODB_ACC_REQUEST: {
            return {
                ...state,
                dyamoDBAccountLoading: true,
            };
        }
        case types.AWS_DYNAMODB_ACC_SUCCESS: {
            return {
                ...state,
                dynamoDBAccountData: payload.data,
                dyamoDBAccountLoading: false,
            };
        }
        case types.AWS_DYNAMODB_ACC_FAILURE: {
            return {
                ...state,
                dyamoDBAccountLoading: false,
                dynamoDBAccountData: null,
                error: payload,
            };
        }
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                dynamoDBData: null,
                dynamoDBAccountData: null,
                error: null,
                loading: false,
                dyamoDBAccountLoading: false,
            };
        }

        default:
            return state;
    }
}
export default awsDynamoDBReducer;
