import React, { useEffect, useState } from "react";
import { Box, CircularProgress, LinearProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import {makeStyles} from "MakeWithStyles";


const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
    },
}));

export default function LineProgress() {
    const {classes} = useStyles();

    const { signingOut } = useSelector((state) => ({
        signingOut: state.userDetailsReducer.signingOut,
    }));


    return !signingOut ? (
        <div className={classes.root}>
            <LinearProgress />
        </div>
    ) : (
        <Box height={window.innerHeight} display={"flex"} flexDirection={"column"} flex={1} justifyContent={"center"} alignItems={"center"}>
            <CircularProgress size={200} thickness={1} />
            <Typography variant={"h4"} style={{ top: "50%" }}>
                Signing Out
            </Typography>
        </Box>
    );
}
