import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getGCPServicesSuccess, getGCPServicesFailure, getGCPServicesAccountFailure, getGCPServicesAccountSuccess, getGCPServicesProjectAccountSuccess, getGCPServicesProjectAccountFailure } from "../actions/actions";
const { REACT_APP_GCP_SERVICES, REACT_APP_GCP_SERVICES_ACCOUNT, REACT_APP_GCP_SERVICES_PROJECT_ACCOUNT } = process.env;

function* getServicesData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_GCP_SERVICES, {
            params: {
                customerId: payload.customerId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getGCPServicesFailure(statusCode));
            } else {
                yield put(getGCPServicesSuccess(res.data));
            }
        } else {
            yield put(getGCPServicesFailure(res));
        }
    } catch (error) {
        yield put(getGCPServicesFailure(error));
    }
}

function* getServicesAccountData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_GCP_SERVICES_ACCOUNT, {
            params: {
                customerId: payload.customerId,
                organizationId: payload.organizationId,
                billingAccountId: payload.billingAccountId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getGCPServicesAccountFailure(statusCode));
            } else {
                yield put(getGCPServicesAccountSuccess(res.data));
            }
        } else {
            yield put(getGCPServicesAccountFailure(res));
        }
    } catch (error) {
        yield put(getGCPServicesAccountFailure(error));
    }
}

function* getServicesProjectAccountData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_GCP_SERVICES_PROJECT_ACCOUNT, {
            params: {
                customerId: payload.customerId,
                organizationId: payload.organizationId,
                billingAccountId: payload.billingAccountId,
                projectId: payload.projectId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getGCPServicesProjectAccountFailure(statusCode));
            } else {
                yield put(getGCPServicesProjectAccountSuccess(res.data));
            }
        } else {
            yield put(getGCPServicesProjectAccountFailure(res));
        }
    } catch (error) {
        yield put(getGCPServicesProjectAccountFailure(error));
    }
}

export default function* gcpServicesSaga() {
    yield takeLatest(types.GCP_SERVICES_REQUEST, getServicesData);
    yield takeLatest(types.GCP_SERVICES_ACCOUNT_REQUEST, getServicesAccountData);
    yield takeLatest(types.GCP_SERVICES_PROJECT_ACCOUNT_REQUEST, getServicesProjectAccountData);
}
