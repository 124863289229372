import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "MakeWithStyles";
import { Table, TableBody,TablePagination, TableCell as TCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Divider, LinearProgress } from "@mui/material";
import { Tooltip, OverlayTrigger, Popover } from "react-bootstrap";

import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/Icon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Format from 'components/NumberFormat';
import colors from 'styles/color';

function TableCell(props) {
    return (
        <TCell {...props} style={{ padding: 0,paddingRight:8  }}>
            {props.children}
        </TCell>
    );
}

const StyledTableRow = withStyles(TableRow,(theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            // backgroundColor: theme.palette.action.hover,
        },
    },
}));
const useRowStyles = makeStyles()((theme) => ({
    root: {
        "& > *": {
            padding: theme.spacing(1.5),
        },
    },
    table: {
        // minWidth: 700,
    },
    container: {
        maxHeight: 600,
    },
    link: {
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

export default function ExpandRow(props) {
    const { row, currency,index, list, setExpandList, showingDetails, data, setShowingDetails, icon } = props;
    const [open, setOpen] = useState(false);
    const {classes} = useRowStyles();

    return (
        <React.Fragment>
            <StyledTableRow hover className={classes.root} key={row.resourceId} style={{ cursor: "pointer" }} onClick={() => setShowingDetails(row.details)}>
            <TableCell>
                  <IconButton
                      style={{ cursor: "pointer" }}
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                          setOpen(!open);
                          setExpandList(index);
                      }}
                  >
                      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
              </TableCell>
                <TableCell>
                    {" "}
                    <img src={icon} height={20} />
                    <span>
                        {" "}
                        {row?.resourceId}
                    </span>
                </TableCell>
              
                        <TableCell>{row?.location}</TableCell>

                        <TableCell>
                        {"usageAccountName" in row ?
                        <OverlayTrigger placement="right" overlay={<Popover id="popover-contained">{"usageAccountName" in row &&
                         <Popover.Body style={{ fontSize: 11 }}>{row?.usageAccountName}</Popover.Body>}</Popover>}>
                           <span style={{whiteSpace: 'nowrap'}}>
                            {row?.usageAccountId || row.usageAccountName}
                        </span>
                        </OverlayTrigger>
                        :row?.usageAccountId || data?.usageAccountId}
                    </TableCell>
                        <TableCell align="right"> {currency + Format.formatNumber(Number(row?.cost).toFixed(2))}</TableCell>
                    
            </StyledTableRow>
            <ExpandLavelData data={row} open={open} select={list} currency={currency} />

        </React.Fragment>
    );
}


export function ExpandLavelData({ data, open, select, set, currency }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                       <Box margin={2.5}>
                            <Htmltable data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <div style={{ maxHeight: 300 }} className="overflow-auto">
            <table id="customers">
                 {data?.meters && Object.keys(data?.meters?.[0])?.length ? (
                    <tr>
                        <th>Operation</th>
                        <th>Product family</th>
                        <th>Usage type</th>
                        <th>Description</th>
                        <th>Total</th>
                    </tr>
                ) : null}

                        {data &&
                    data?.meters
                        ?.sort((a, b) => {
                            return parseFloat(b?.cost || 0) - parseFloat(a?.cost || 0);
                        })
                        .map((m) =>
                            Object.keys(m)?.length ? (
                                <tr>
                                    <td>{m?.operation||'-'}</td>
                                    <td>{m?.productFamily||'-'}</td>
                                    <td>{m.LineItemUsageType||'-'}</td>
                                    <td>{m.lineItemDescription||'-'}</td>

                                    <td>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                                </tr>
                            ) : null
                        )}
            </table>
        </div>
    );
}
