import React, { useEffect } from "react";
import Format from "../../components/NumberFormat";
import { Paper, Grid, Divider, Card } from "@mui/material";
import aws_sub from "../../images/aws_sub.svg";
import active_directory from "../../images/active_directory.svg";
import resource_group from "../../images/resource_group.svg";
import key from "../../images/key.svg";
import aws_account from "../../images/aws_org.svg";
import {makeStyles} from "MakeWithStyles";

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: 0,
  },
}));

export default function AWSOrgList({ tenantLogo, currency, accountList }) {
  const {classes} = useStyles();

  const { label, totalCost, dataSet } = accountList;
  return (
    <div>
      {accountList && accountList.length
        ? accountList.map((item, i) => (
            <Card style={{ marginBottom: 8 }} key={i + item.label}>
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  display: "flex",
                  padding: 12,
                  backgroundColor: "#cfdac8",
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={tenantLogo}
                    alt="aws_sub"
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 8,
                    }}
                  />
                  <span style={{ fontWeight: "bold" }}>{item.label}</span>
                </div>
                <div style={{ fontWeight: "bold" }}>
                  {" "}
                  {currency}
                  {Format.formatNumber(item.value || 0)}
                </div>
              </div>
              {item.dataSet && item.dataSet.length ? (
                item.dataSet?.map((item) => (
                  <div
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      display: "flex",
                      padding: 12,
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <img
                        src={aws_account}
                        alt="aws_org"
                        style={{
                          width: 20,
                          height: 20,
                          marginRight: 8,
                        }}
                      />
                      <span>{item.label}</span>
                    </div>
                    <div>
                      {" "}
                      {currency}
                      {Format.formatNumber(item.value || 0)}
                    </div>
                  </div>
                ))
              ) : (
                <span>No Subscription found</span>
              )}
            </Card>
          ))
        : null}
    </div>
  );
}
