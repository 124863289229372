import * as types from "../actions/types";

const userState = {
  reportsName: null,
  reportsBase64: null,
  loading: false,
  loadingBase: false,
  error: null,
};

function reportsReducer(state = userState, { type, payload }) {
  switch (type) {
      case "SWITCH_TEAM_CLEAR": {
          return {
              ...state,
              reportsName: null,
              reportsBase64: null,
              loading: false,
              loadingBase: false,
              error: null,
          };
      }
      case types.GET_REPORTS_NAME_REQUEST: {
          return {
              ...state,
              loading: true,
          };
      }
      case types.GET_REPORTS_NAME_SUCCESS: {
          return {
              ...state,
              reportsName: payload.data,
              loading: false,
          };
      }
      case types.GET_REPORTS_NAME_FAILURE: {
          return {
              ...state,
              loading: false,
              reportsName: payload,
              error: payload,
          };
      }
      case types.GET_REPORTS_ARRAY_REQUEST: {
          return {
              ...state,
              reportsBase64: null,
              loadingBase: true,
          };
      }
      case types.GET_REPORTS_ARRAY_SUCCESS: {
          return {
              ...state,
              reportsBase64: payload.data,
              loadingBase: false,
          };
      }
      case types.GET_REPORTS_ARRAY_FAILURE: {
          return {
              ...state,
              loadingBase: false,
              // reportsBase64: null,
              error: payload,
          };
      }

      default:
          return state;
  }
}
export default reportsReducer;
