import { Paper } from "@mui/material";
import GetWindowDimension from "components/HOC/GetWindowDimension";
import React, { useEffect, useState } from "react";
import Carousel from "react-simply-carousel";
import color from '../../styles/color';

 function RecommendationsSummaryCards(props) {
    const {summary, width, height} = props;
    const [activeSlide, setActiveSlide] = useState(0);

    return (
        <div style={{ marginBottom: 10, display: "flex", justifyContent: "center" }} elevation={2}>
            {/*<Carousel*/}
            {/*    updateOnItemClick*/}
            {/*    containerProps={{*/}
            {/*        style: {*/}
            {/*            width: "100%",*/}
            {/*            justifyContent: "space-between",*/}
            {/*        },*/}
            {/*    }}*/}
            {/*    activeSlideIndex={activeSlide}*/}
            {/*    activeSlideProps={{*/}
            {/*        style: {*/}
            {/*            // background: "blue",*/}
            {/*        },*/}
            {/*    }}*/}
            {/*    onRequestChange={setActiveSlide}*/}
            {/*    forwardBtnProps={{*/}
            {/*        children: ">",*/}
            {/*        style: {*/}
            {/*            width: 60,*/}
            {/*            height: 60,*/}
            {/*            minWidth: 60,*/}
            {/*            alignSelf: "center",*/}
            {/*            background: "white",*/}
            {/*            border: "none",*/}
            {/*            outline: "none",*/}
            {/*            fontSize: 40,*/}
            {/*        },*/}
            {/*    }}*/}
            {/*    backwardBtnProps={{*/}
            {/*        children: "<",*/}
            {/*        style: {*/}
            {/*            width: 60,*/}
            {/*            height: 60,*/}
            {/*            minWidth: 60,*/}
            {/*            alignSelf: "center",*/}
            {/*            background: "white",*/}
            {/*            border: "none",*/}
            {/*            outline: "none",*/}
            {/*            fontSize: 40,*/}
            {/*        },*/}
            {/*    }}*/}
            {/*    itemsToShow={summary.length > 4 ? 4 : summary.length - 1}*/}
            {/*    speed={400}*/}
            {/*>*/}
                {summary.map((item, index) => (
                    <div style={{ padding: 10 }} key={index}>
                        <Paper style={{ width: width / 6.5, height: height / 10, background: `linear-gradient(to right, ${color.tableHeader}, #eef2f3)`, padding: 10, textAlign: "center" }} elevation={3} key={index}>
                            <h4 className="carouselValue">{item.value}</h4>
                            <h6 className="carouselText" style={{fontSize: '0.9rem', whiteSpace: 'nowrap'}}>{item.name}</h6>
                        </Paper>
                    </div>
                ))}
            {/*</Carousel>*/}
        </div>
    );
}
export default GetWindowDimension(RecommendationsSummaryCards);
