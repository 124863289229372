import * as types from "../actions/types";

const userState = {
    awsEBSRecommendationData: null,
    awsEBSAccountRecommendationData:null,
    error: null,
    loading: false,
    awsEBSRecommendationLoading:false

};

function awsEBSRecommendationReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.AWS_EBS_RECOMMENDATION_REQUEST: {
            return {
                ...state,
                awsEBSRecommendationLoading: true,
            };
        }
        case types.AWS_EBS_RECOMMENDATION_SUCCESS: {
            return {
                ...state,
                awsEBSRecommendationData: payload.data,
                awsEBSRecommendationLoading: false,
            };
        }
        case types.AWS_EBS_RECOMMENDATION_FAILURE: {
            return {
                ...state,
                awsEBSRecommendationLoading: false,
                awsEBSRecommendationData: null,
                error: payload,
            };
        }

        // case "SWITCH_TEAM_CLEAR": {
        //     return {
        //         ...state,
        //         awsEC2RecommendationData: null,
        //         awsEC2AccountRecommendationData:null,
        //         error: null,
        //         loading: false,
        //         awsEC2AccountLoading:false
        //
        //     };
        // }

        default:
            return state;
    }
}
export default awsEBSRecommendationReducer;
