export const CUSTOMER_STATUS = {
    ACTIVE: "active",
    INACTIVE: "inactive"
}

// export const PAYMENT_STATUS = {
//     IN_TRIAL: 'in_trial',
//     TRIAL_EXPIRED: "trial_expired",
//     TRIAL_EXTENDED: "trial_extended",
//     PAID: "paid",
//     INVOICE_UNPAID: "invoice_unpaid",
//     CC_FAILED: 'cc_failed'
// }

// export const PAYMENT_MODEL = {
//     NOT_SETUP: 'not_setup',
//     CREDIT_CARD: 'credit_card',
//     INVOICE: 'invoice'
// }

// export const APP_PLANS = {
//     FREE_TRIAL: 'free_trial',
//     STARTER: 'starter',
//     SAVINGS: 'savings',
//     SAVINGS_PLUS: 'savings_plus',
//     INVOICE: 'invoice'
// }

export const TIMERS = {
    CALENDAR_INTERVAL: 12000,
    UPPER_CHART_INTERVAL: 12000,
    LOWER_CHART_INTERVAL: 20000
}

export const TABLE_SORT = {
    ORDER_ASC: 1,
    ORDER_DESC: -1,
}
