import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getGCPComputeSuccess, getGCPComputeFailure, getGCPComputeAccountFailure, getGCPComputeAccountSuccess, getGCPComputeProjectAccountSuccess, getGCPComputeProjectAccountFailure } from "../actions/actions";
const { REACT_APP_GCP_COMPUTE, REACT_APP_GCP_COMPUTE_ACCOUNT, REACT_APP_GCP_COMPUTE_PROJECT_ACCOUNT } = process.env;

function* getComputeData({ payload }) {
    try {
        const res = yield call(axios.post, REACT_APP_GCP_COMPUTE, {
            customerId: payload.customerId,
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getGCPComputeFailure(statusCode));
            } else {
                yield put(getGCPComputeSuccess(res.data));
            }
        } else {
            yield put(getGCPComputeFailure(res));
        }
    } catch (error) {
        yield put(getGCPComputeFailure(error));
    }
}

function* getComputeAccountData({ payload }) {
    try {
        const res = yield call(axios.post, REACT_APP_GCP_COMPUTE_ACCOUNT, {
            customerId: payload.customerId,
            billingAccountId: payload.billingAccountId,
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getGCPComputeAccountFailure(statusCode));
            } else {
                yield put(getGCPComputeAccountSuccess(res.data));
            }
        } else {
            yield put(getGCPComputeAccountFailure(res));
        }
    } catch (error) {
        yield put(getGCPComputeAccountFailure(error));
    }
}

function* getComputeProjectAccountData({ payload }) {
    try {
        const res = yield call(axios.post, REACT_APP_GCP_COMPUTE_PROJECT_ACCOUNT, {
            customerId: payload.customerId,
            projectId: payload.projectId,
            billingAccountId: payload.billingAccountId,

        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getGCPComputeProjectAccountFailure(statusCode));
            } else {
                yield put(getGCPComputeProjectAccountSuccess(res.data));
            }
        } else {
            yield put(getGCPComputeProjectAccountFailure(res));
        }
    } catch (error) {
        yield put(getGCPComputeProjectAccountFailure(error));
    }
}

export default function* gcpComputeSaga() {
    yield takeLatest(types.GCP_COMPUTE_REQUEST, getComputeData);
    yield takeLatest(types.GCP_COMPUTE_ACCOUNT_REQUEST, getComputeAccountData);
    yield takeLatest(types.GCP_COMPUTE_PROJECT_ACCOUNT_REQUEST, getComputeProjectAccountData);
}
