import * as types from "../actions/types";

const userState = {
    computeOptimizerData: null,
    loading: false,
    error: null,
};

function awsComputeOptimizerReducer(state = userState, { type, payload }) {
    switch (type) {
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                computeOptimizerData: null,
                loading: false,
                error: null,
            };
        }
        case types.AWS_COMPUTE_OPTIMIZER_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AWS_COMPUTE_OPTIMIZER_SUCCESS: {
            return {
                ...state,
                computeOptimizerData: payload.data,
                loading: false,
            };
        }
        case types.AWS_COMPUTE_OPTIMIZER_FAILURE: {
            return {
                ...state,
                loading: false,
                computeOptimizerData: null,
                error: payload,
            };
        }

        default:
            return state;
    }
}
export default awsComputeOptimizerReducer;
