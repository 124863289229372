import React, { useState, useEffect } from "react";
//import "./style.css";
import { makeStyles, withStyles } from "MakeWithStyles";
import { Box, Typography, IconButton, Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, LinearProgress, Divider } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePagination from "@mui/material/TablePagination";
import Format from "components/NumberFormat";
import management_group from "../../images/newCloudTrakrIcons/AzureBusinessUnits.png";
import key from "../../images/key.svg";
import TablePaginationActions from "../TablePaginationActions";

const StyledTableCell = withStyles(TableCell,(theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: theme.spacing(1.5),
    },
}));
const StyledTableRow = withStyles(TableRow,(theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            // backgroundColor: theme.palette.action.hover,
        },
    },
}));
const useRowStyles = makeStyles()({
    root: {
        "& > *": {
            borderBottom: "unset",
            padding: 3.5,
        },
    },
    table: {
        minWidth: 700,
    },
    container: {
        // maxHeight: 440,
    },
    container1: {
        maxHeight: 400,
    },
});
export function MangementTotalCost(data, period) {
    let rootTotal = [];
    let total = 0;
    if (data && data != null) {
        data &&
            data.map((i, j) => {
                if (i?.children?.length > 0) {
                    i?.children &&
                        i?.children.map((k, l) => {
                            total = k?.children?.length + total;

                            k?.children &&
                                k?.children.map((m, n) => {
                                    rootTotal.push(m?.SpendBySubscription?.[period]?.totalCost || 0);
                                });
                        });
                } else {
                    rootTotal.push(0);
                }
            });
    }
    return { rootTotal, total };
}

const DateRange = ["1D", "1W", "1M", "3M", "6M", "1Y"];

function dataFormatter(data) {
    const total = {};
    DateRange.forEach((d) => {
        total[d] = 0;
    });

    function managementResolver(e) {
        if (e.type === "/subscriptions") {
            return subscriptionResolver(e);
        } else if (e?.children) {
            const children = e.children.map((c) => managementResolver(c));
            const subsCount = (JSON.stringify(children).match(/"type\":\"subscription\"/g) || []).length;
            const cost = children
                .map((c) => c.cost)
                .reduce((a, b) => {
                    const total = {};
                    DateRange.forEach((d) => {
                        total[d] = parseFloat(a?.[d] || 0) + parseFloat(b?.[d] || 0);
                    });
                    return total || 0;
                }, 0);

            return {
                name: e.name,
                id: e.properties.displayName,
                children,
                type: "management",
                subsCount,
                cost,
            };
        } else {
            return {
                name: e.name,
                id: e.properties.displayName,
                type: "management",
                subsCount: 0,
                cost: total,
            };
        }
    }

    function subscriptionResolver(e) {
        var cost = {};
        for (var i in e.SpendBySubscription) {
            cost[i] = parseFloat(e.SpendBySubscription[i]?.totalCost || 0);
        }
        return {
            name: e.properties.displayName,
            id: e.name,
            type: "subscription",
            cost: cost || total,
        };
    }
    return data?.map((d) => managementResolver(d));
}

export const AzureManagementGroupFormatter = dataFormatter;

export default function AzureManagementTable({ period, data, currency, headerData, loading, progress, setTotalParent = ()=>{} }) {
    const {classes} = useRowStyles();
    const [total, setTotalCost] = useState();
    const [totalSubscription, setTotalSubscription] = useState();
    const [managementData, setManagementData] = useState([]);
    const [open, setOpen] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    // useEffect(() => {
    // let getData = MangementTotalCost(data, period);
    // setTotalSubscription(getData.total);

    // setTotalCost(getData.rootTotal.reduce((a, b) => Number(a) + Number(b), 0));
    // }, [period, data]);

    useEffect(() => {
        if (data) {
            setManagementData(dataFormatter(data));
        }
    }, [data]);

    useEffect(() => {
        if (managementData && managementData.length) {
            var subscriptions = 0;
            managementData.forEach((m) => {
                subscriptions += m?.subsCount || (m.type === "subscriptions" ? 1 : 0);
            });
            const total = managementData
                .map((m) => m.cost)
                .reduce((a, b) => {
                    const total = {};
                    DateRange.forEach((d) => {
                        total[d] = parseFloat(a?.[d] || 0) + parseFloat(b?.[d] || 0);
                    });
                    return total || 0;
                }, 0);
            setTotalCost(total);
            setTotalParent(total)
            setTotalSubscription(subscriptions);
        }
    }, [managementData]);

    return loading ? (
        <div className={classes.root}>
            <LinearProgress variant="determinate" value={progress} />
        </div>
    ) : (
        <Paper style={{marginBottom:15}}>
            <div
                style={{
                    // border: '1px solid',
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "flex-start",
                    backgroundColor: "#cfdac8",
                    padding: 8,
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                }}
            >
                <div
                    style={{
                        flex: 3,
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "flex-start",
                    }}
                >
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    <Typography style={{ marginLeft: 4, fontWeight: "bold" }}> {headerData?.displayName}</Typography>
                </div>
                <div style={{ flex: 3 }}>
                    <Typography style={{ fontWeight: "bold" }}> {headerData?.tenantId}</Typography>
                </div>
                <div style={{ flex: 2, display: "flex", justifyContent: "flex-end" }}>
                    <Typography style={{ fontWeight: "bold" }}>{totalSubscription && totalSubscription > 1 ? totalSubscription + " subscriptions" : totalSubscription <= 1 ? totalSubscription + " subscription" : null || "--"} </Typography>
                </div>
                <div
                    style={{
                        flex: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: 5,
                    }}
                >
                    <Typography style={{ fontWeight: "bold" }}>{currency + (isNaN(Number(total?.[period]).toFixed(2)) ? "--" : Format.formatNumber(Number(total?.[period]).toFixed(2))) || "--"}</Typography>
                </div>
            </div>
            {managementData
                .sort((a, b) => parseFloat(b?.cost?.[period] || 0) - parseFloat(a?.cost?.[period] || 0))?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((m) => (
                    <ManagementGroup data={m} classes={classes} currency={currency} period={period} collapseControl={open} />
                ))}
                  <TablePagination
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={managementData?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
        </Paper>
    );
}
function ManagementGroup({ data: e, classes, period, currency, collapseControl }) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(collapseControl);
    }, [collapseControl]);

    if (e?.type === "subscription") {
        return <SubscriptionManage data={e} period={period} currency={currency} classes={classes} />;
    } else if (e?.children) {
        const children = e.children
            .sort((a, b) => {
                return parseFloat(b?.cost?.[period] || 0) - parseFloat(a?.cost?.[period] || 0);
            })
            .map((c) => <ManagementGroup data={c} classes={classes} currency={currency} period={period} collapseControl={open} />);
        return (
            <>
                <ManagementComponent currency={currency} period={period} e={e} open={open} setOpen={setOpen} />
                <Collapse in={open} unmountOnExit timeout="auto">
                    {/*<Paper style={{margin: 10, padding: 10}} >*/}
                    <div
                        style={{
                            margin: 15,
                            padding: 10,
                            // border: '1px solid rgb(234 234 234)'
                        }}
                    >
                        {children}
                    </div>
                    {/*</Paper>*/}
                </Collapse>
            </>
        );
    } else {
        return <ManagementComponent currency={currency} period={period} e={e} open={open} setOpen={setOpen} />;
    }
}
function ManagementComponent({ open, setOpen, e, currency, period, children }) {
    return (
        <>
            <div
                style={{
                    // border: '1px solid',
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "flex-start",
                    padding: 4,
                    marginBottom: 2,
                    // flex: 1
                }}
            >
                <div
                    style={{
                        flex: 3,
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "flex-start",
                    }}
                >
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    <Typography>
                        <img src={management_group} height={24} style={{ marginRight: 5 }} />
                        {e.name}
                    </Typography>
                </div>
                <div style={{ flex: 3 }}>
                    <Typography>{e.id}</Typography>
                </div>
                <div
                    style={{
                        flex: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <Typography>{e.subsCount > 1 ? e.subsCount + " subscriptions" : e.subsCount + " subscription"}</Typography>
                </div>
                <div
                    style={{
                        flex: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: 8,
                    }}
                >
                    <Typography>{currency + (isNaN(Number(e?.cost?.[period]).toFixed(2)) ? "--" : Format.formatNumber(Number(e?.cost?.[period]).toFixed(2))) || "--"}</Typography>
                </div>
            </div>
            <Divider />
        </>
    );
}
function SubscriptionManage({ data: e, currency, period, classes }) {
    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    background: "#f3f3f3",
                    padding: 8,
                }}
            >
                <div style={{ display: "flex", flex: 1, justifyContent: "flex-start" }}>
                    <span>
                        <img src={key} height={24} style={{ marginRight: 5 }} />
                        {e.name}
                    </span>
                </div>
                <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                    <span>{e.id}</span>
                </div>
                <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                    <span>{currency + (isNaN(Number(e?.cost?.[period]).toFixed(2)) ? "--" : Format.formatNumber(Number(e?.cost?.[period]).toFixed(2))) || "--"}</span>
                </div>
            </div>
            <Divider />
        </>
    );
}
export function IndividualMgmtCost(row, period) {
    let rootTotal = [];
    let chartData = [];
    let subData = [];
    let total = 0;

    if (row && row != null) {
        if (row?.children?.length > 0) {
            row?.children &&
                row?.children.map((i, j) => {
                    total = i?.children?.length + total;

                    i?.children &&
                        i?.children.map((k, l) => {
                            rootTotal.push(k?.SpendBySubscription?.[period]?.totalCost || 0);
                        });
                    subData.push({
                        label: i?.properties?.displayName,
                        value:
                            i.children &&
                            i.children
                                .map((i) => i.SpendBySubscription?.[period]?.totalCost || 0)
                                .reduce((a, b) => Number(a) + Number(b), 0)
                                .toFixed(2),
                        childData:
                            i.children &&
                            i.children.map((k) => ({
                                label: k?.properties?.displayName,
                                value: Number(k?.SpendBySubscription?.[period]?.totalCost || 0).toFixed(2),
                            })),
                    });
                });
        } else {
            rootTotal.push(0);
        }
        chartData.push({
            label: row?.properties?.displayName,
            value: rootTotal.reduce((a, b) => Number(a) + Number(b), 0).toFixed(2),
            childData: subData,
        });
    }
    let cData = chartData[0];
    return { cData, total, rootTotal };
}
export function ExpandableTableAzure({ tableRow, data, currency, period }) {
    const {classes} = useRowStyles();

    return (
        // <Paper elevation={3}>
        <TableContainer className={classes.container}>
            <Table className={classes.table} stickyHeader aria-label="sticky table">
                <TableBody>{tableRow && tableRow.map((r) => <ExpandRow row={r} data={data} currency={currency} period={period} />)}</TableBody>
            </Table>
        </TableContainer>
        // </Paper>
    );
}
export function ExpandRow(props) {
    const { row, data, currency, period } = props;
    const [open, setOpen] = useState(false);
    const [list, setList] = useState(null);
    const {classes} = useRowStyles();
    const [total, setTotalCost] = useState();
    const [totalSubscription, setTotalSubscription] = useState();

    useEffect(() => {
        let rootTotal = [];
        let total = 0;
        if (row && row != null) {
            if (row?.children?.length > 0) {
                total = row?.children?.length + total;

                row?.children &&
                    row?.children.map((i, j) => {
                        rootTotal.push(i.SpendBySubscription?.[period]?.totalCost || 0);
                    });

                setTotalCost(rootTotal.reduce((a, b) => Number(a) + Number(b), 0));
                setTotalSubscription(total);
            } else {
                rootTotal.push(0);
            }
        }
    }, [period, data]);

    return (
        <React.Fragment>
            <StyledTableRow hover className={classes.root} key={row.resourceId}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(row?.properties?.displayName);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell>
                    {<img src={management_group} height={24} style={{ marginRight: 5 }} />}
                    {row?.properties?.displayName}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell align="center">{totalSubscription && totalSubscription > 1 ? totalSubscription + " subscriptions" : totalSubscription <= 1 ? totalSubscription + " subscription" : null || "--"} </TableCell>

                <TableCell align="center">{currency + (isNaN(Number(total).toFixed(2)) ? "--" : Format.formatNumber(Number(total).toFixed(2))) || "--"}</TableCell>
            </StyledTableRow>
            <ExpandLavelData data={data} open={open} select={list} currency={currency} period={period} />
        </React.Fragment>
    );
}
export function ExpandLavelData({ data, open, select, currency, period }) {
    const [resource, setResource] = useState(null);

    useEffect(() => {
        if (select != null) {
            let value = data.children.find((u) => u?.properties?.displayName === select);
            setResource(value);
        }
    }, [select, data]);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2.5}>{resource && <Htmltable data={resource?.children} currency={currency} period={period} />}</Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
export function Htmltable({ data, currency, period }) {
    return (
        <div style={{ maxHeight: 300 }} className="overflow-auto">
            <table id="customers">
                {data &&
                    data
                        .sort((a, b) => parseFloat(b?.SpendBySubscription?.[period]?.totalCost) - parseFloat(a?.SpendBySubscription?.[period]?.totalCost))
                        .map((m) => (
                            // <table>
                            <tr style={{ alignItems: "center" }}>
                                <td style={{ textAlign: "left", paddingLeft: 60 }}>
                                    {<img src={key} width={18} height={18} style={{ marginRight: 5 }} />}
                                    {m?.properties?.displayName}
                                </td>
                                <td style={{ textAlign: "left" }}>{m.name}</td>

                                <td>{currency + Format.formatNumber(Number(m?.SpendBySubscription?.[period]?.totalCost || 0))}</td>
                            </tr>
                            // </table>
                        ))}
            </table>
        </div>
    );
}
