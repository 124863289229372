import * as types from "../actions/types";

const TAB_SET = {
  DASHBOARD: "DASHBOARD",
  MANAGEMENT_GROUPS: "MANAGEMENT_GROUPS",
  SUBSCRIPTIONS : "SUBSCRIPTIONS",
  RESOURCE_GROUPS_RESOURCES : "RESOURCE_GROUPS_RESOURCES",
  BILLING : "BILLING",
  RECOMMENDATIONS : "RECOMMENDATIONS",
  RESERVATIONS : "RESERVATIONS",
  COMPUTE : "COMPUTE",
  STORAGE : "STORAGE",
  FUNCTION_APPS : "FUNCTION_APPS",
  COSMOS_DB : "COSMOS_DB",
  SERVERLESS : "SERVERLESS",
  ORGANIZATIONS: "ORGANIZATIONS",
  ACCOUNTS: "ACCOUNTS",
  DYNAMO_DB: "DYNAMO_DB",
  LAMBDA: "LAMBDA",
  SERVICES: "SERVICES",
  RESOURCES:"RESOURCES",
  TAGS: "TAGS",
  DATABASE: "DATABASE",
  FUNCTIONS: "FUNCTIONS",
  S3BUCKET:"S3BUCKET",
  RDS:"RDS",
}

const initialState = {
  allActiveTab: TAB_SET.BILLING,
  azureActiveTab: TAB_SET.BILLING,
  awsActiveTab: TAB_SET.BILLING,
  gcpActiveTab: TAB_SET.BILLING
};

function appStateReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_CLOUD_ACTIVE_TAB: {
      const data = {};
      data[payload.cloud] = payload.tab;
      return {
        ...state,
        ...data,
      };
    }

    default:
      return state;
  }
}
export default appStateReducer;
