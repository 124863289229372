import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "MakeWithStyles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { getBudgetSummaryRequest } from "../../redux/actions/actions";
import { useSelector, useDispatch } from "react-redux";
import { Paper } from "@mui/material";
import Format from "../../components/NumberFormat";

const useStyles = makeStyles()((theme) => ({
  button: {
    margin: theme.spacing(0.5),
  },
  table: {
    maxHeight: 100,
  },
}));

export default function BudgetOverview() {
  const {classes} = useStyles();
  //   const getBudgets = useSelector(
  //     (state) => state.getBudgetReducer?.budgets?.result
  //   );

  useEffect(() => {}, []);

  return (
    <div>
      <span>Budget Overview</span>
    </div>
  );
}
