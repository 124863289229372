import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "MakeWithStyles";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import GetAppIcon from "@mui/icons-material/GetApp";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import "./style.css";
import { useSpring, animated } from "@react-spring/web"; // web.cjs is required for IE 11 support
import { useSelector, useDispatch } from "react-redux";
import { getReportArrayRequest } from "redux/actions/actions";
import { Base64 } from "./Reports";
import { CircularProgress } from "@mui/material";

const StyledTableCell = withStyles(TableCell,(theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: theme.spacing(1.5),
    },
}));
// const StyledTableRow = withStyles(TableRow,(theme) => ({
//   root: {
//     "&:nth-of-type(odd)": {
//       backgroundColor: theme.palette.action.hover,
//     },
//   },
// }));

const StyledButton = withStyles(Button,()=>({
    root: {
        "&:hover": {
            color: "black",
            background: "steelblue",
        },
    }
}));

const useStyles = makeStyles()((theme) => ({
    root: {
        width: "100%",
    },
    container: {
        maxHeight: 530,
    },

    // root1: {
    //   flexGrow: 1,
    //   minWidth: 300,
    //   transform: "translateZ(0)",
    //   // The position fixed scoping doesn't work in IE 11.
    //   // Disable this demo to preserve the others.
    //   "@media all and (-ms-high-contrast: none)": {
    //     display: "none",
    //   },
    // },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        width: 800,
        height: 680,
        // backgroundColor: "#000000",
        boxShadow: theme.shadows[5],
        //padding: theme.spacing(2, 4, 3),
    },
}));

export default function ReportList({ column, data, param, customerId }) {
    const {classes} = useStyles();
    const dispatch = useDispatch();
    //const customerId = useSelector((state) => state.userDetailsReducer.id);
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFile('');
    };

    const base64 = useSelector((state) => state.reportsReducer.reportsBase64);
    const loadingPdf = useSelector((state) => state.reportsReducer.loadingBase);

    const getBase64 = (f, t) => {
        let data = { customerId: customerId, type: t, fileName: f };
        dispatch(getReportArrayRequest(data));
    };

    const MonthlyPdfName = (name,type)=>{
        if(type === "monthly"){
            return name.split(',')[0]+"-"+name.split(', ')[1];
        }else {
            return name.replace(/ (?=[^ ]*$)/i, "-");
        }
    }

    return (
        <div>
            <Paper className={classes.root} elevation={3}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {column.map((c) => (
                                    <StyledTableCell key={c.id} align={c.align} style={{ fontSize: 17, fontWeight: "bold" }}>
                                        {c.label}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((r) => (
                                <TableRow hover role="checkbox" tabIndex={-1} key={r.fileName}>
                                    <StyledTableCell align="left">
                                        <span
                                            className="hhover"
                                            onClick={() => {
                                                setFile(MonthlyPdfName("fileNameUi" in r ? r.fileNameUi.split(".")[0] : r.fileNameUi,param.type));
                                                getBase64(r.fileName, param.type);
                                                handleOpen();
                                            }}
                                        >
                                            {"fileNameUi" in r ? r.fileNameUi.split(".")[0] : r.fileNameUi}
                                        </span>
                                    </StyledTableCell>

                                    {/* <StyledTableCell align="left">{r.startDate}</StyledTableCell>
                  <StyledTableCell align="left">{r.endDate}</StyledTableCell> */}
                                    {/* <StyledTableCell align="left">
                  {moment(r.dateCreated, "DD-MM-YYYY").format("DD-MM-YYYY")}
                </StyledTableCell> */}
                                    <StyledTableCell align="center">
                                        <StyledButton
                                            color="primary"
                                            variant="outlined"
                                            onClick={() => {
                                                setFile(MonthlyPdfName("fileNameUi" in r ? r.fileNameUi.split(".")[0] : r.fileNameUi,param.type));
                                                getBase64(r.fileName, param.type);
                                                handleOpen();
                                            }}
                                        >
                                            {<PictureAsPdfIcon />} /{<GetAppIcon />}
                                        </StyledButton>
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    className={classes.modal}
                    closeAfterTransition
                    // BackdropComponent={Backdrop}
                    // BackdropProps={{
                    //     timeout: 500,
                    // }}
                >
                    <Fade in={open}>
                        <div
                            className={classes.paper}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {loadingPdf ? (
                                <div>
                                    <CircularProgress />
                                </div>
                            ) : base64 && base64 != null ? (
                                <Base64 base={base64} name={file} type={param.type}/>
                            ) : null}
                        </div>
                    </Fade>
                </Modal>
            </div>
        </div>
    );
}

export const Fade = React.forwardRef(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

Fade.propTypes = {
    children: PropTypes.element,
    in: PropTypes.bool.isRequired,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
};
