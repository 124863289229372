import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import {
    getAzureCosmosDBSuccess,
    getAzureCosmosDBFailure,
    getAzureCosmosDB1YTableDataFailure,
    getAzureCosmosDB1YTableDataSuccess,
    getAzureCosmosDB3MMetricFailure,
    getAzureCosmosDB3MMetricSuccess,
    getAzureCosmosDBTenantFailure,
    getAzureCosmosDBTenantSuccess
} from "../actions/actions";
const { REACT_APP_AZURE_COSMOSDB, REACT_APP_AZURE_COSMOSDB_1Y_TABLE_DATA, REACT_APP_AZURE_COSMOSDB_3M_METRICS,REACT_APP_AZURE_COSMOSDB_TENANT } = process.env;

function* getAzureCosmosDBData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AZURE_COSMOSDB, {
            params: {
                customerId: payload.customerId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureCosmosDBFailure(statusCode));
            } else {
                yield put(getAzureCosmosDBSuccess(res.data));
            }
        } else {
            yield put(getAzureCosmosDBFailure(res));
        }
    } catch (error) {
        yield put(getAzureCosmosDBFailure(error));
    }
}

function* getAzureCosmosDB1YTableData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AZURE_COSMOSDB_1Y_TABLE_DATA, {
            params: {
                customerId: payload.customerId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureCosmosDB1YTableDataFailure(statusCode));
            } else {
                yield put(getAzureCosmosDB1YTableDataSuccess(res.data));
            }
        } else {
            yield put(getAzureCosmosDB1YTableDataFailure(res));
        }
    } catch (error) {
        yield put(getAzureCosmosDB1YTableDataFailure(error));
    }
}

function* getAzureCosmosDB3MMetric({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AZURE_COSMOSDB_3M_METRICS, {
            params: { customerId: payload.customerId },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureCosmosDB3MMetricFailure(statusCode));
            } else {
                yield put(getAzureCosmosDB3MMetricSuccess(res.data));
            }
        } else {
            yield put(getAzureCosmosDB3MMetricFailure(res));
        }
    } catch (error) {
        yield put(getAzureCosmosDB3MMetricFailure(error));
    }
}
function* getAzureCosmosDBTenantData({ payload }) {

    try {
        const res = yield call(axios.get, REACT_APP_AZURE_COSMOSDB_TENANT, {
            params: { 
                     customerId: payload.customerId,
                     tenantId: payload.tenantId
                     },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureCosmosDBTenantFailure(statusCode));
            } else {
                yield put(getAzureCosmosDBTenantSuccess(res.data));
            }
        } else {
            yield put(getAzureCosmosDBTenantFailure(res));
        }
    } catch (error) {
        yield put(getAzureCosmosDBTenantFailure(error));
    }
}

export default function* azureCosmosDBSaga() {
    yield takeLatest(types.AZURE_COSMOSDB_REQUEST, getAzureCosmosDBData);
    yield takeLatest(types.AZURE_COSMOSDB_1Y_TABLE_DATA_REQUEST, getAzureCosmosDB1YTableData);
    yield takeLatest(types.AZURE_COSMOSDB_3M_METRIC_REQUEST, getAzureCosmosDB3MMetric);
    yield takeLatest(types.AZURE_COSMOSDB_TENANT_REQUEST, getAzureCosmosDBTenantData);

}
