import * as types from "../actions/types";

const userState = {
    azureFunctionData: null,
    azureFunctionSubData: null,
    azureFunctionTenantData:null,
    error: null,
    loading: false,
    azureFunctionSubLoading: false,
    azureFunctionTenantLoading:false
};

function azureFunctionReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.AZURE_FUNCTION_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AZURE_FUNCTION_SUCCESS: {
            return {
                ...state,
                azureFunctionData: payload.data,
                loading: false,
            };
        }
        case types.AZURE_FUNCTION_FAILURE: {
            return {
                ...state,
                loading: false,
                azureFunctionData: null,
                error: payload,
            };
        }

        case types.AZURE_FUNCTION_TENANT_REQUEST: {
            return {
                ...state,
                azureFunctionTenantLoading: true,
            };
        }
        case types.AZURE_FUNCTION_TENANT_SUCCESS: {
            return {
                ...state,
                azureFunctionTenantData: payload.data,
                azureFunctionTenantLoading: false,
            };
        }
        case types.AZURE_FUNCTION_TENANT_FAILURE: {
            return {
                ...state,
                azureFunctionTenantLoading: false,
                azureFunctionTenantData: null,
                error: payload,
            };
        }

        case types.AZURE_FUNCTION_ACCOUNT_REQUEST: {
            return {
                ...state,
                azureFunctionSubLoading: true,
            };
        }
        case types.AZURE_FUNCTION_ACCOUNT_SUCCESS: {
            return {
                ...state,
                azureFunctionSubData: payload.data,
                azureFunctionSubLoading: false,
            };
        }
        case types.AZURE_FUNCTION_ACCOUNT_FAILURE: {
            return {
                ...state,
                azureFunctionSubLoading: false,
                azureFunctionSubData: null,
                error: payload,
            };
        }
       
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                azureFunctionData: null,
                azureFunctionSubData: null,
                error: null,
                loading: false,
                azureFunctionLoading: false,
            };
        }

        default:
            return state;
    }
}
export default azureFunctionReducer;
