import React from "react";

export default function({data = [], renderItem, EmptyComponent, keyExtractor = ()=>null,
                            isLoading = false, LoadingComponent, loadingMessage}){
    const isEmpty = (data && data?.constructor?.name == "Array" && data.length === 0)?true:false;
    return isLoading?
        <LoadingComponent />:(
        <div>
            {!isEmpty && data.map((d, i)=>(<Component renderItem={()=>renderItem({item: d, index: i})} key={keyExtractor(d,i)}/>))}
            {isEmpty && <EmptyComponent />}
        </div>
    )
}

function Component({renderItem}){
    return renderItem();
}
