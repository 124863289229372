import cloudwatch from "./Arch_Amazon-CloudWatch_32.png";
import ec2 from "./Arch_Amazon-EC2_32.png";
import rds from "./Arch_Amazon-RDS_32.png";
import redshift from "./Arch_Amazon-Redshift_32.png";
import dmsvc from "./Arch_AWS-Database-Migration-Service_32.png";
import ds from "./Arch_AWS-Directory-Service_32.png";
import elb from "./Arch_Elastic-Load-Balancing_32.png";
import s3 from "./Res_Amazon-Simple-Storage-Service_Bucket_48.png";
export default {
    'AmazonCloudWatch': cloudwatch,
    'AmazonEC2': ec2,
    'AmazonRDS': rds,
    'AmazonRedshift': redshift,
    'AWSDatabaseMigrationSvc': dmsvc,
    'AWSDirectoryService': ds,
    'AWSELB': elb,
    'AmazonS3': s3,
}
