import { takeLatest, call, put, takeEvery } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getAWSCCKSuccess, getAWSCCKFailure, getAWSCCKSubFailure, getAWSCCKSubSuccess } from "../actions/actions";
const { REACT_APP_AWS_CCK, REACT_APP_AWS_SUB_CCK } = process.env;

function* getAWSCCKData({ payload }) {
    try {
        const res = yield call(axios.post, REACT_APP_AWS_CCK, {
            customerId: payload.customerId,
            dataType: payload.dataType,
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAWSCCKFailure(statusCode));
            } else {
                yield put(getAWSCCKSuccess({ data: res.data, dataType: payload.dataType }));
            }
        } else {
            yield put(getAWSCCKFailure(res));
        }
    } catch (error) {
        yield put(getAWSCCKFailure(error));
    }
}

function* getAWSCCKSubData({ payload }) {
    try {
        const res = yield call(axios.post, REACT_APP_AWS_SUB_CCK, {
            customerId: payload.customerId,
            tenantId: payload.tenantId,
            subscriptionId: payload.subscriptionId,
            dataType: payload.dataType,
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAWSCCKSubFailure(statusCode));
            } else {
                yield put(getAWSCCKSubSuccess({ data: res.data, dataType: payload.dataType }));
            }
        } else {
            yield put(getAWSCCKSubFailure(res));
        }
    } catch (error) {
        yield put(getAWSCCKSubFailure(error));
    }
}

export default function* AWSCCKSaga() {
    yield takeEvery(types.AWS_CCK_REQUEST, getAWSCCKData);
    yield takeEvery(types.AWS_CCK_SUB_REQUEST, getAWSCCKSubData);
}
