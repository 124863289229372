import * as types from "../actions/types";
import {
  GET_MESSAGES_FAILURE,
  GET_MESSAGES_REQUEST,
  GET_MESSAGES_SUCCESS, GET_SUBSCRIPTIONS_FAILURE,
  GET_SUBSCRIPTIONS_REQUEST, GET_SUBSCRIPTIONS_SUCCESS,
    PRICING_MODEL_SHOW
} from "../actions/types";

const userState = {
  ThisMonthSpend: null,
  LastMonthSpend: null,
  AllCloudSpending: null,
  SpendByServerless: null,
  TopFiveServerlessServices: null,
  TopFiveServices: null,
  TopFiveLocations: null,
  SpendByCategory: null,
  SpendByAccounts: null,
  currency: "USD",
  loading: false,
  error: null,
  messages: [],
  messagesLoading: false,
  messagesError: false,
  allSubscriptions: null,
  totalSummary: null
};

function allHomeReducer(state = userState, { type, payload }) {
  switch (type) {
      case types.ALL_HOME_LOADING: {
          return {
              ...state,
              loading: true,
          };
      }
      case types.ALL_HOME_SUCCESS: {
          const {
              currency,
              charts: { ThisMonthSpend, LastMonthSpend, AllCloudSpending, SpendByServerless, TopFiveServerlessServices, TopFiveServices, TopFiveLocations, SpendByCategory, SpendByAccounts },
              totalSummary
          } = payload.data;
          return {
              ...state,
              currency: currency,
              ThisMonthSpend: ThisMonthSpend,
              LastMonthSpend: LastMonthSpend,
              AllCloudSpending: AllCloudSpending,
              SpendByServerless: SpendByServerless,
              TopFiveServerlessServices: TopFiveServerlessServices,
              TopFiveServices: TopFiveServices,
              TopFiveLocations: TopFiveLocations,
              SpendByCategory: SpendByCategory,
              SpendByAccounts: SpendByAccounts,
              totalSummary,
              loading: false,
          };
      }
      case types.ALL_HOME_FAIL: {
          return {
              ...state,
              loading: false,
              error: payload,
          };
      }

      case GET_MESSAGES_REQUEST: {
          return {
              ...state,
              messagesLoading: true,
              messagesError: false,
          };
      }

      case GET_MESSAGES_SUCCESS: {
          return {
              ...state,
              messagesLoading: false,
              messages: payload?.messages || [],
          };
      }

      case GET_MESSAGES_FAILURE: {
          return {
              ...state,
              messagesLoading: false,
              messagesError: true,
          };
      }

      case GET_SUBSCRIPTIONS_REQUEST:
          return { ...state, allSubscriptions: null };

      case GET_SUBSCRIPTIONS_SUCCESS:
          return { ...state, allSubscriptions: payload };

      case GET_SUBSCRIPTIONS_FAILURE:
          return { ...state, allSubscriptions: null };

      case "SWITCH_TEAM_CLEAR": {
          return {
              ...state,
              messages: [],
              allSubscriptions: null,
              ThisMonthSpend: null,
              LastMonthSpend: null,
              AllCloudSpending: null,
              SpendByServerless: null,
              TopFiveServerlessServices: null,
              TopFiveServices: null,
              TopFiveLocations: null,
              SpendByCategory: null,
              SpendByAccounts: null,
              currency: "",
          };
      }
      default:
          return state;
  }
}
export default allHomeReducer;
