import React from "react";
import moment from "moment";

export default function StartEndDate(props) {
    const { startDate, endDate } = props;

    return startDate !== undefined && endDate !== undefined ? (
        <div style={{ marginRight: 20 }}>
            <span style={{ fontSize: 12 }}>{moment(startDate, "YYYYMMDD").format("MMM DD, YYYY")}</span> <span style={{ fontSize: 12 }}>-</span> <span style={{ fontSize: 12 }}>{moment(endDate, "YYYYMMDD").format("MMM DD, YYYY")}</span>
        </div>
    ) : null;
}
