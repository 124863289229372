import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { accountRequest, allHomeRequest } from "../../redux/actions/actions";
import "./style.css";
import { makeStyles, withStyles } from "MakeWithStyles";
import DateRange from "../../components/DateRange";
import Format from "components/NumberFormat";
import { Box, Grid, Collapse, IconButton, Paper, Divider, CircularProgress } from "@mui/material";
import axios from "../../connection/axios";
import YearTableData from "../../components/YearTableData";
import SlideShow from "components/CustomSlider/SlideShow";
import ReactD3GridPieChart from "components/D3Charts/ReactD3GridPieChart";
import ReactD3GridBarChart from "components/D3Charts/ReactD3GridChart";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";
import calendar_icon from "../../images/calendar_icon.png";
import YearCalendarData, { CalendarDataProcessor, Months } from "../../components/YearCalendarData";
import { LinearProgress } from "@mui/material";
import { getMonthsFromNow } from "../../util/DateFormatter";
import ComputeIcon from "../../images/Virtual-Machine.svg";
import Azure_cosmosDB_icon from "../../images/Azure-Cosmos-DB.svg";
import storage_account_icon from "../../images/resources/Storage_Account.svg";
import function_apps_icon from "../../images/function-apps.png";
import database_icon from "../../images/database.png";
import allCloudFunctions_icon from "../../images/allCloudFunctions.png";
import allCloudStorage_icon from "../../images/allCloudStorage.png";
import allCloudCompute_icon from "../../images/allCloudCompute.png";
import azure_subscription from "../../images/key.svg";
import gcp_billing_account from "../../images/gcp_billing.svg";
import PauseIcon from "@mui/icons-material/Pause";
import { KeyboardArrowDown, KeyboardArrowUp, PauseCircleFilled, PlayArrow, PlayCircleFilled, Print } from "@mui/icons-material";
import GroupedBarChart from "../../components/D3Charts/GroupedBarChart";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DashboardIcon from "../../images/newCloudTrakrIcons/MultiCloudIcon.png";
import Calendar_Spend_Icon from "../../images/calendar_months.svg";
import Total_Spend_Icon from "../../images/sigma_icon.svg";
import Total_Spend_Icon_New from "../../images/spend_icon.png";
import StackedBarChart2 from "./StackedBarChart2";
import AllCloudCompute from "../../pages/AllClouds/AllCloudCompute";
import AllCloudDatabase from "../../pages/AllClouds/AllCloudDatabase";
import AllCloudFunctions from "../../pages/AllClouds/AllCloudFunctions";
import AllCloudStorage from "../../pages/AllClouds/AllCloudStorage";
import AllCloudAccount from "../../pages/AllClouds/AllCloudAccount";
import AllCloudResources from "pages/AllClouds/AllCloudResources";
import AllCloudServices from "pages/AllClouds/AllCloudServices";

import all_resource from "../../images/All-Resources.svg";

import NO_DATA_AVAILABLE from "../../components/NO_DATA_AVAILABLE";
import { TIMERS } from "../../util/AppConstants";
import billing_icon from "../../images/billing_icon.svg";
import aws_account from "../../images/aws_org.svg";
import aws_region from "../../images/aws_region.svg";
import resource_group from "../../images/resource_group.svg";
import azure_resource_group from "../../images/azure_resource_group.svg";
import cloud_location from "../../images/cloud_location.png";
import moment from "moment";
import azure_location from "../../images/azure_location.svg";
import gcp_compute from "../../images/gcp_compute.svg";
import gcp_project from "../../images/gcp_project.svg";
import AZURE_LOGO from "../../images/Azure_logo.svg";
import AWS_LOGO from "../../images/aws_dark.svg";
import GCP_LOGO from "../../images/GCP_logo.svg";
import COMBINED_CLOUD_LOGO from "../../images/allCloudLogo_white.svg";
import Cost, { Currency } from "../../components/Cost";
import color from "../../styles/color";
import AllCloudRecommendation from "../../../src/pages/AllClouds/AllCloudRecommendation";
import aws_recommendation from "../../images/aws_recommendation.svg";
import { useReactToPrint } from "react-to-print";
import ContentLoader from "react-content-loader";
import CalendarLoader from "../../components/loaders/CalendarLoader";
import ContainerCardWithDateRange from "../../components/ContainerCardWithDateRange";
import { arraySaveToCSV } from "../../util/Miscellaneous";
import eye_icon from "../../images/eye_icon.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { SET_CLOUD_ACTIVE_TAB_REQUEST } from "../../redux/actions/types";
import AllCloudBillingTab from "../AllClouds/AllCloudBillingTab";
import { OverlayComponent } from "../../components/tab/AzureHomeTab";
import { objectIterator } from "../../util/Miscellaneous";
import ArrowUp from "../../images/double-chevron-up.svg";
import ArrowDown from "../../images/double-chevron-down.svg";
import MountUnmount from "../../components/MountUnmount";
import AWSTagsCostCategories from "../../components/tab/AWSTagsCostCategories";

const { REACT_APP_ALL_1Y_TABLE_DATA } = process.env;

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
    },
    paper: {
        padding: theme.spacing(2),
    },
    table: {
        minWidth: 650,
    },
    tab: {
        fontWeight: "bold",
        textTransform: "none",
    },
    tabPanel: {
        padding: theme.spacing(-10),
    },
}));

const styles = {
    tab: {
        outline: "none",
    },
    labelContainer: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        marginBottom: -10,
        // marginLeft: -10,
        // marginRight: -10
    },

    icon: { width: 18, height: 18, marginRight: 4 },
};

const TAB_SET = {
    ACCOUNTS: "ACCOUNTS",
    SERVICES: "SERVICES",
    RESOURCES: "RESOURCES",
    RECOMMENDATIONS: "RECOMMENDATIONS",
    DASHBOARD: "DASHBOARD",
    BILLING: "BILLING",
    DATABASE: "DATABASE",
    FUNCTIONS: "FUNCTIONS",
    COMPUTE: "COMPUTE",
    STORAGE: "STORAGE",
};

const CLOUD_ICONS = {
    azure: AZURE_LOGO,
    aws: AWS_LOGO,
    gcp: GCP_LOGO,
    total: COMBINED_CLOUD_LOGO,
    all: COMBINED_CLOUD_LOGO,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

export default function DashboardTabs(props) {
    const { customerId } = props;
    const [progress, setProgress] = useState(0);
    const [isSticky, setSticky] = useState(false);
    const [spendByMonthsChartView, setSpendByMonthsChartView] = useState(true);
    const [totalSpendChartView, setTotalSpendChartView] = useState(true);
    const [chartShowingIndex, setChartShowingIndex] = useState(0);
    const [chartTop5ShowingIndex, setChartTop5ShowingIndex] = useState(0);

    const [chart2ShowingIndex, setChart2ShowingIndex] = useState(0);
    const [period, setPeriod] = useState("1M");
    const [top5Period, setTop5Period] = useState("1M");

    const ref = useRef(null);
    const handleScroll = () => {
        if (ref.current) {
            setSticky(ref.current.getBoundingClientRect().top <= 80);
        }
    };

    const {classes} = useStyles();
    //const location = useLocation();
    // const [period, setPeriod] = useState("1M");
    const [tenantsList, setTenantsList] = useState([]);
    const [top5Services, setTop5Services] = useState(null);
    const [top5Locations, setTop5Locations] = useState(null);
    const [spendByCategory, setSpendByCategory] = useState(null);
    const [top5ServerlessServicess, setTop5ServerlessServicess] = useState(null);
    const [allCLoud, setAllCLoud] = useState(null);
    const [spendByAccounts, setSpendByAccounts] = useState(null);
    const [yearTableData, setYearTableData] = useState([]);
    const [yearWiseData, setYearWiseData] = useState(null);
    const [monthWiseData, setMonthWiseData] = useState(null);
    const [monthWiseChartData, setMonthWiseChartData] = useState([]);
    const [othersMonthWiseChartData, setOthersMonthWiseChartData] = useState([]);
    const [cloudCount, setCloudCount] = useState(0);
    const dispatch = useDispatch();
    // const { customerId } = useSelector((state) => ({
    //     customerId: state.userDetailsReducer.id,
    // }));
    const AllCloudSpending = useSelector((state) => state.allHomeReducer.AllCloudSpending);
    const ThisMonthSpend = useSelector((state) => state.allHomeReducer.ThisMonthSpend);
    const currency = useSelector((state) => state.allHomeReducer.currency);
    const services = useSelector((state) => state.allHomeReducer.TopFiveServices);
    const allCloudlocations = useSelector((state) => state.allHomeReducer.TopFiveLocations);
    const SpendByCategory = useSelector((state) => state.allHomeReducer.SpendByCategory);
    const serverlessServices = useSelector((state) => state.allHomeReducer.TopFiveServerlessServices);
    const SpendByAccounts = useSelector((state) => state.allHomeReducer.SpendByAccounts);
    const lastmonthCost = useSelector((state) => state.allHomeReducer.LastMonthSpend);
    const activeTab = useSelector((state) => state.appStateReducer.allActiveTab);

    const loading = useSelector((state) => state.allHomeReducer.loading || state.accountStatusReducer.loading);

    const allAccountStatus = useSelector((state) => state.accountStatusReducer.allAccountStatus);
    const [calendarLoading, setCalendarLoading] = useState(false);
    const [all1YCSVData, setAll1YCSVData] = useState(null);

    const [overlayData, setOverlayData] = useState(null);
    const [currentCloud, setCurrentCloud] = useState("total");
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState(null);
    const [monthInterval, setMonthInterval] = useState(null);
    const [yearToDateBillingCost, setYearToDateBillingCost] = useState(null);
    const [yearToDateBillingChart, setYearToDateBillingChart] = useState([]);
    const [parentWidth, setParentWidth] = useState(null);
    const [dashboardComponentsRef, setDashboardComponentsRef] = useState({});
    const [dashboardComponentsCollapsed, setDashboardComponentsCollapsed] = useState(false);

    const billingComponentRef = useRef();

    var loopIndex = 0;
    const months = getMonthsFromNow([], true)?.reverse();

    useEffect(() => {
        const legends = {
            all: "All cloud",
            azure: "Azure",
            aws: "AWS",
            gcp: "GCP",
        };
        if (yearToDateBillingCost) {
            setYearToDateBillingChart([
                {
                    dataLabel: "YTD 2023",
                    values: yearToDateBillingCost?.ytd?.list?.map(({ cost: value, cloud: legend }) => ({ value, legend: legend != "all" ? legends[legend] : null })).filter((l) => l.legend != null),
                },
                // {
                //     dataLabel: "2021",
                //     values: yearToDateBillingCost?.otherYear?.list?.map(({cost: value, cloud: legend}) => ({value, legend: legend != 'all'?legends[legend]:null}))
                //         .filter(l=>l.legend!=null)
                // },
                ...Object.keys(yearToDateBillingCost?.quarter).map((quarter) => ({
                    dataLabel: "Q" + quarter + " " + yearToDateBillingCost?.quarter[quarter]?.year,
                    values: yearToDateBillingCost?.quarter[quarter]?.list?.map(({ cost: value, cloud: legend }) => ({ value, legend: legend != "all" ? legends[legend] : null })).filter((l) => l.legend != null),
                })),
            ]);
        }
    }, [yearToDateBillingCost]);

    useEffect(() => {
        getYearToDateCost();
    }, [yearTableData]);

    const [buffer, setBuffer] = React.useState(10);
    const progressRef = React.useRef(() => {});
    React.useEffect(() => {
        progressRef.current = () => {
            if (progress > 100) {
                setProgress(0);
                setBuffer(10);
            } else {
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setProgress(progress + diff);
                setBuffer(progress + diff + diff2);
            }
        };
    });

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (currentMonth) {
            let month = Months.indexOf(currentMonth) + 1;
            month = month < 10 ? "0" + month : month;
            // console.log(awsAccountList);
            // debugger
            const { data, date } = getOverlayData(null, month, currentYear);
            setOverlayData({ cost: data?.cost, data, date });
        }
    }, [currentMonth, currentYear, currentCloud]);

    useEffect(() => {
        if (!monthWiseData) return;
        setCurrentYear(new Date().getFullYear());
        setCurrentMonth(Months[new Date().getMonth()]);
    }, [monthWiseData]);

    function getMonths() {
        // const months = new Array();
        const azureMonths = yearTableData?.azure?.SpendByMonth?.map((m) => m.month) || [];
        const awsMonths = yearTableData?.aws?.SpendByMonth?.map((m) => m.month) || [];
        const gcpMonths = yearTableData?.gcp?.SpendByMonth?.map((m) => m.month) || [];
        // azureMonths.length
        // if (azureMonths.length >= 10) {
        //     return azureMonths;
        // } else if (awsMonths.length >= 10) {
        //     return awsMonths;
        // } else if (gcpMonths.length >= 10) {
        //     return gcpMonths;
        // } else {
        //     return [];
        // }
        return new Array(13).fill(null);
        // Object.keys(yearTableData).forEach(key=>{
        //   months.push(yearTableData?.[key]?.SpendByMonth?.map(m=>m.month));
        // })
        // console.log(months)
    }

    function getOverlayData(d, m, y) {
        let data = null;
        let date = null;
        if (d && m && y) {
            data = monthWiseData?.[currentCloud == "total" ? "all" : currentCloud]?.SpendByDaily?.find((data) => data.year == y)
                ?.months?.find((data) => data.month == m)
                ?.dates.find((data) => data.date == y.toString() + m.toString() + d.toString());
            date = moment(data?.date || 0, "YYYYMMDD").format("MMM D, YYYY");
        } else if (m && y) {
            data = yearTableData?.[currentCloud == "total" ? "all" : currentCloud]?.SpendByMonth?.find((data) => data.month == y.toString() + m.toString());
            date = moment(data?.startDate || 0, "YYYYMMDD").format("MMM D, YYYY") + " - " + moment(data?.endDate || 0, "YYYYMMDD").format("MMM D, YYYY");
            if (currentCloud == "total") {
                const current = moment(currentYear + currentMonth || 0, "YYYYMMMM");
                date = moment(current).isValid() ? current.format("MMMM YYYY") : null;
            }
        }
        return { data, date };
    }

    function onResume() {
        return;
        setCurrentCloud("total");
        setMonthInterval(
            setInterval(() => {
                if (loopIndex == months.length) {
                    loopIndex = 0;
                    onMonthNext(months[0]);
                } else {
                    onMonthNext(months[loopIndex]);
                }
                loopIndex++;
            }, TIMERS.CALENDAR_INTERVAL)
        );
    }

    function onPause() {
        clearInterval(monthInterval);
        setMonthInterval(null);
    }

    function onMonthNext(monthYear = "") {
        if (monthYear) {
            const month = monthYear?.split(" ")[0];
            const year = monthYear?.split(" ")[1];
            setCurrentMonth(month);
            setCurrentYear(parseInt(year));
        }
    }

    async function onMonthSelect(m, y = null, cloud) {
        onPause();
        if (cloud) setCurrentCloud(cloud);
        if (monthWiseData == null || !monthWiseData) {
            await getAll1YearMonthlyData("daily")
                .then(() => setCurrentMonth(m))
                .finally(() => false);
            setCurrentMonth(m);
        } else {
            setCurrentMonth(m);
        }
        if (y) setCurrentYear(y);
    }

    const onPrint = useReactToPrint({
        content: () => billingComponentRef.current,
    });

    function getYearToDateCost() {
        const allCost = {
            ytd: {
                list: [],
            },
            otherYear: {
                list: [],
            },
            quarter: {
                1: {
                    list: [],
                },
                2: {
                    list: [],
                },
                3: {
                    list: [],
                },
                4: {
                    list: [],
                },
            },
        };
        const clouds = Object.keys(yearTableData);
        const currentYear = new Date().getFullYear();
        // console.log({quarter: yearTableData})
        clouds.forEach((cloud) => {
            let cost = yearTableData?.[cloud]?.SpendByMonth?.filter((s) => s.month.toString().startsWith(currentYear));

            // let temp = Object.assign(
            //     [],
            //     cost?.sort((a, b) => parseInt(a) - parseInt(b))
            // );
            // let temp = JSON.parse(JSON.stringify(cost));
            let costOth = yearTableData?.[cloud]?.SpendByMonth?.filter((s) => !s.month.toString().startsWith(currentYear));
            const otherYearMonths = getMonthsFromNow(months, false, true).filter((s) => s.year != currentYear);
            costOth = otherYearMonths
                .map((m) => {
                    return costOth.find((c) => moment(c?.month, "YYYYMM").isSame(moment(m.month + m.year, "MMMYYYY")));
                })
                .filter((f) => f != null && f != undefined)
                ?.sort((a, b) => a.month - b.month);
            const start = moment(cost?.[0]?.month, "YYYYMM").format("MMM");
            const end = moment(cost?.[cost?.length - 1]?.month, "YYYYMM").format("MMM");

            const startOth = moment(costOth?.[0]?.month, "YYYYMM").format("MMM");
            const endOth = moment(costOth?.[costOth?.length - 1]?.month, "YYYYMM").format("MMM");

            // 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11

            var quarters = {};
            const quarterRange = {
                0: [1, 3],
                1: [4, 6],
                2: [7, 9],
                3: [10, 12],
            };
            let quarterlyCost = yearTableData?.[cloud]?.SpendByMonth;
            // ?.filter((s) => s.month.toString().startsWith(currentYear));
            var currentQuarter = parseInt(moment().month() / 3);
            var lastQuarter = currentQuarter;
            var selectedYear = new Date().getFullYear();

            // quarterlyCost.filter(s=>s.month.toString().startsWith(selectedYear));
            var availableCurrentYear = quarterlyCost.filter((s) => s.month.toString().startsWith(selectedYear));

            while (currentQuarter > -1) {
                const range = quarterRange[currentQuarter];
                quarters[currentQuarter] = availableCurrentYear.filter((s) => parseInt(s.month.toString().substr(4, 5)) >= range[0] && parseInt(s.month.toString().substr(4, 5)) <= range[1]);
                currentQuarter--;
            }
            currentQuarter = 3 - lastQuarter;

            if (currentQuarter > -1) {
                availableCurrentYear = quarterlyCost.filter((s) => s.month.toString().startsWith(selectedYear - 1));
                while (currentQuarter < 4) {
                    if (!(currentQuarter in quarters)) {
                        const range = quarterRange[currentQuarter];
                        quarters[currentQuarter] = availableCurrentYear.filter((s) => parseInt(s.month.toString().substr(4, 5)) >= range[0] && parseInt(s.month.toString().substr(4, 5)) <= range[1]);
                    }
                    currentQuarter++;
                }
            }

            // let temp = Object.assign(
            //     [],
            //     quarterlyCost?.sort((a, b) => parseInt(a) - parseInt(b))
            // );

            // const quarter1 = temp.reverse()?.splice(0, 3);
            // const quarter2 = temp?.splice(0, 3);
            // const quarter3 = temp?.splice(0, 3);
            // const quarter4 = temp;

            allCost.quarter["1"].year = allCost?.quarter["1"]?.year || quarters?.[0]?.[0]?.month.toString().substr(0, 4);
            allCost.quarter["1"].start = 0;
            allCost.quarter["1"].end = 0;
            allCost.quarter["1"].list.push({
                cost: quarters?.[0]?.map((a) => parseFloat(a?.cost)).reduce((a, b) => a + b, 0),
                start: moment(quarters?.[0]?.[0]?.month, "YYYYMM").format("MMM"),
                end: moment(quarters?.[0]?.[quarters?.[0].length - 1]?.month, "YYYYMM").format("MMM"),
                cloud,
            });

            allCost.quarter["2"].year = allCost?.quarter["2"]?.year || quarters?.[1]?.[0]?.month.toString().substr(0, 4);
            allCost.quarter["2"].start = start;
            allCost.quarter["2"].end = end;
            allCost.quarter["2"].list.push({
                cost: quarters?.[1]?.map((a) => parseFloat(a?.cost)).reduce((a, b) => a + b, 0),
                start: moment(quarters?.[1]?.[0]?.month, "YYYYMM").format("MMM"),
                end: moment(quarters?.[1]?.[quarters?.[1].length - 1]?.month, "YYYYMM").format("MMM"),
                cloud,
            });

            allCost.quarter["3"].year = allCost?.quarter["3"]?.year || quarters?.[2]?.[0]?.month.toString().substr(0, 4);
            allCost.quarter["3"].start = start;
            allCost.quarter["3"].end = end;
            allCost.quarter["3"].list.push({
                cost: quarters?.[2]?.map((a) => parseFloat(a?.cost)).reduce((a, b) => a + b, 0),
                start: moment(quarters?.[2]?.[0]?.month, "YYYYMM").format("MMM"),
                end: moment(quarters?.[2]?.[quarters?.[2].length - 1]?.month, "YYYYMM").format("MMM"),
                cloud,
            });

            allCost.quarter["4"].year = allCost?.quarter["4"]?.year || quarters?.[3]?.[0]?.month.toString().substr(0, 4);
            allCost.quarter["4"].start = start;
            allCost.quarter["4"].end = end;
            allCost.quarter["4"].list.push({
                cost: quarters?.[3]?.map((a) => parseFloat(a?.cost)).reduce((a, b) => a + b, 0),
                start: moment(quarters?.[3]?.[0]?.month, "YYYYMM").format("MMM"),
                end: moment(quarters?.[3]?.[quarters?.[3].length - 1]?.month, "YYYYMM").format("MMM"),
                cloud,
            });

            allCost.ytd.year = currentYear;
            allCost.ytd.start = start;
            allCost.ytd.end = end;
            allCost.ytd.list.push({
                cost: cost?.map((a) => parseFloat(a?.cost)).reduce((a, b) => a + b, 0),
                cloud,
            });

            const filteredOther = costOth?.filter((f) => moment(f.month, "YYYYMM").isValid());

            allCost.otherYear.start = startOth;
            allCost.otherYear.end = endOth;
            allCost.otherYear.list.push({
                cost: costOth?.map((a) => parseFloat(a?.cost)).reduce((a, b) => a + b, 0),
                cloud,
            });
            if (filteredOther && filteredOther.length) allCost.otherYear.year = moment(filteredOther?.[0]?.month, "YYYYMM").year();
        });

        setYearToDateBillingCost(allCost);
        return allCost;
    }

    window.$(document).ready(rootLoaded);

    function rootLoaded() {
        if (parentWidth) return;
        const e = window.$("#root_dashboard");
        try {
            setParentWidth(e.width());
        } catch (e) {}
    }

    useEffect(() => {
        window.$(document).ready(() => {
            rootLoaded();
            // console.log((document.querySelector('#root_dashboard')));
        });
    }, []);

    function AllHome(id) {
        dispatch(allHomeRequest(id));
    }

    function DateRangeData(p) {
        if (AllCloudSpending && Object.keys(AllCloudSpending[p]).length !== 0) {
            if (p in AllCloudSpending) {
                const { dataSet, totalCost } = AllCloudSpending[p];
                let data = dataSet.sort((a, b) => b.value - a.value).slice(0, 5);
                setAllCLoud({
                    data: data,
                    cost: totalCost,
                });
            } else {
                setAllCLoud(null);
            }
        } else {
            setAllCLoud(null);
        }

        if (SpendByCategory && Object.keys(SpendByCategory[p]).length !== 0) {
            if (p in SpendByCategory) {
                const { dataSet, totalCost } = SpendByCategory[p];
                let data = objectIterator(mergeDataArray(dataSet));
                data.sort((a, b) => b.value - a.value).slice(0, 5);
                setSpendByCategory({ data: data, cost: totalCost });
            } else {
                setSpendByCategory(null);
            }
        } else {
            setSpendByCategory(null);
        }

        if (SpendByAccounts && Object.keys(SpendByAccounts[p]).length !== 0) {
            if (p in SpendByAccounts) {
                const { dataSet, totalCost } = SpendByAccounts[p];
                let data = dataSet.sort((a, b) => b.value - a.value).slice(0, 5);
                setSpendByAccounts({ data: data, cost: totalCost });
            } else {
                setSpendByAccounts(null);
            }
        } else {
            setSpendByAccounts(null);
        }
    }

    function mergeDataArray(t = []) {
        var g = {};
        t.forEach((t) => (t.label in g ? (g[t.label] += parseFloat(t.value)) : (g[t.label] = parseFloat(t.value))));
        return g;
    }

    function Top5DateRangeData(p) {
        if (services && Object.keys(services[p]).length !== 0) {
            if (p in services) {
                const { dataSet, totalCost } = services[p];
                let data = dataSet.sort((a, b) => b.value - a.value).slice(0, 5);
                setTop5Services({ data: data, cost: totalCost });
            } else {
                setTop5Services(null);
            }
        } else {
            setTop5Services(null);
        }
        if (allCloudlocations && Object.keys(allCloudlocations[p]).length !== 0) {
            if (p in allCloudlocations) {
                const { dataSet, totalCost } = allCloudlocations[p];
                let data = dataSet.sort((a, b) => b.value - a.value).slice(0, 5);
                setTop5Locations({ data: data, cost: totalCost });
            } else {
                setTop5Locations(null);
            }
        } else {
            setTop5Locations(null);
        }

        if (serverlessServices && Object.keys(serverlessServices[p]).length !== 0) {
            if (p in serverlessServices) {
                const { dataSet, totalCost } = serverlessServices[p];
                let data = dataSet.sort((a, b) => b.value - a.value).slice(0, 5);
                setTop5ServerlessServicess({ data: data, cost: totalCost });
            } else {
                setTop5ServerlessServicess(null);
            }
        } else {
            setTop5ServerlessServicess(null);
        }
    }

    useEffect(() => {
        DateRangeData(period);
        Top5DateRangeData(top5Period);
    }, [period, top5Period, services, AllCloudSpending, allCloudlocations, SpendByCategory, serverlessServices, SpendByAccounts]);

    function AccountStatus(id) {
        if (id) {
            dispatch(accountRequest(id));
        }
    }

    useEffect(() => {
        if (allAccountStatus != null) {
            if (allAccountStatus) {
                if (services == null) {
                    AllHome(customerId);
                }
            }
        } else {
            AccountStatus(customerId);
        }
    }, [customerId, services, allAccountStatus]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() =>{
        if(customerId)
            getAll1YearMonthlyData().then(async () => await getAll1YearMonthlyData("daily"))
    }, [customerId]);

    async function getAll1YearMonthlyData(period = "monthly") {
        setCalendarLoading(true);
        axios
            .get(REACT_APP_ALL_1Y_TABLE_DATA, {
                params: {
                    customerId,
                    view: period,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                if (period === "daily") {
                    setMonthWiseData(response?.data?.data);
                    return;
                }
                setYearTableData(response?.data?.data || []);
                setYearWiseData(response?.data?.data);
            })
            .catch((e) => {})
            .finally(() => setCalendarLoading(false));
    }

    useEffect(() => {
        if (yearWiseData) generateGroupedChartData(yearWiseData, setMonthWiseChartData, setCloudCount);
    }, [yearWiseData]);

    useEffect(() => {
        if (yearTableData?.all)
            generateGroupedChartData(
                { all: { SpendByMonth: yearTableData?.all.SpendByMonth } },
                (data) => {
                    setOthersMonthWiseChartData(
                        data?.map((t) => ({
                            ...t,
                            values: t.values.map((v) => ({
                                ...v,
                                value: {
                                    locations: v.value?.locations?.sort((a, b) => parseFloat(b.cost) - parseFloat(a.cost))?.slice(0, 5),
                                    services: v.value?.services?.sort((a, b) => parseFloat(b.cost) - parseFloat(a.cost))?.slice(0, 5),
                                },
                            })),
                        })) || []
                    );
                },
                () => {},
                ["locations", "services"],
                true
            );
    }, [yearTableData]);

    function onSaveAsCSV() {
        arraySaveToCSV(all1YCSVData, "all_cloud_billing_1year.csv");
    }

    function handleTabSelection(tab) {
        dispatch({
            type: SET_CLOUD_ACTIVE_TAB_REQUEST,
            payload: { cloud: "allActiveTab", tab },
        });
    }

    const pausePlayTotalRef = useRef(null);
    const pausePlayTop5Ref = useRef(null);
    const pausePlaySpendRef = useRef(null);

    function collapseCards() {
        const h = dashboardComponentsRef;
        for(const rg in h){
            h[rg](dashboardComponentsCollapsed);
        }
        setDashboardComponentsCollapsed(!dashboardComponentsCollapsed);
    }

    return (
        <div className={classes.root}>
            <TabContext value={activeTab}>
                <TabList onChange={(e, t) => handleTabSelection(t)} textColor="primary" indicatorColor="primary" variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example" style={{ maxWidth: "calc(100vw - 80px)" }}>
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={{ height: 26 }} src={billing_icon} />
                                Dashboard
                            </div>
                        }
                        value={TAB_SET.BILLING}
                        className={classes.tab}
                        {...a11yProps(TAB_SET.BILLING)}
                    />
                    {/*<Tab*/}
                    {/*    style={styles.tab}*/}
                    {/*    label={*/}
                    {/*        <div style={styles.labelContainer}>*/}
                    {/*            <img style={styles.icon} src={DashboardIcon} />*/}
                    {/*            Dashboard*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*    value={TAB_SET.DASHBOARD}*/}
                    {/*    className={classes.tab}*/}
                    {/*    // onClick={() => setresTab(false)}*/}
                    {/*    {...a11yProps(TAB_SET.DASHBOARD)}*/}
                    {/*/>*/}
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={aws_recommendation} />
                                Recommendations
                            </div>
                        }
                        value={TAB_SET.RECOMMENDATIONS}
                        className={classes.tab}
                    />
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={aws_account} />
                                Accounts
                            </div>
                        }
                        value={TAB_SET.ACCOUNTS}
                        className={classes.tab}
                    />

                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={resource_group} />
                                Services
                            </div>
                        }
                        value={TAB_SET.SERVICES}
                        className={classes.tab}
                        // onClick={() => setresTab(true)}
                    />
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={{ height: 40 }} src={all_resource} />
                                Resources
                            </div>
                        }
                        value={TAB_SET.RESOURCES}
                        className={classes.tab}
                        // onClick={() => setresTab(true)}
                    />

                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={allCloudCompute_icon} />
                                Compute
                            </div>
                        }
                        value={TAB_SET.COMPUTE}
                        className={classes.tab}
                        {...a11yProps(TAB_SET.COMPUTE)}
                    />
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={database_icon} />
                                Database
                            </div>
                        }
                        value={TAB_SET.DATABASE}
                        className={classes.tab}
                        {...a11yProps(TAB_SET.DATABASE)}
                    />
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={allCloudFunctions_icon} />
                                Functions
                            </div>
                        }
                        value={TAB_SET.FUNCTIONS}
                        className={classes.tab}
                        {...a11yProps(TAB_SET.FUNCTIONS)}
                    />
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={allCloudStorage_icon} />
                                Storage
                            </div>
                        }
                        value={TAB_SET.STORAGE}
                        className={classes.tab}
                        {...a11yProps(TAB_SET.STORAGE)}
                    />
                </TabList>
                <Divider />
                <div style={{ marginTop: 0 }}></div>
                <TabPanel value={"dep"+TAB_SET.DASHBOARD} style={{ marginTop: 0, paddingTop: 5 }}>
                    <div style={{ marginTop: 10 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <ContainerCardWithDateRange
                                    showDateRange={true}
                                    title={"Total spend"}
                                    titleIcon={Total_Spend_Icon_New}
                                    HeaderTitleComponent={(e, setCollapseClickable) => (
                                        <>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                                <span>Total spend</span>
                                                <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)}  onMouseOut={() => setCollapseClickable(true)} className={'pause-play-icon'}>
                                                    <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                        <div onClick={() => pausePlayTotalRef?.current?.click()}>
                                                            {pausePlayTotalRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    onPeriodChange={setPeriod}
                                    // saveAsCSV={true}
                                    // onSaveAsCSV={onSaveAsCSV}
                                >
                                    {() => (
                                        <div style={{}}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    position: "relative",
                                                    //  justifyContent: 'center'
                                                }}
                                            >
                                                <div style={{ position: "absolute", zIndex: 1 }}>
                                                    <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                        <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                    </IconButton>
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <SlideShow showingIndex={chartShowingIndex} names={["Spend by Clouds", "Spend by Accounts", "Spend by Category"]} view="All" arrowsHandled={true} pausePlayRef={pausePlayTotalRef}>
                                                        <div>
                                                            {/*{allCLoud !== null ? (*/}
                                                            <ReactD3GridPieChart title={"Spend by Clouds"} cost={allCLoud?.cost} currency={currency} data={allCLoud?.data} />
                                                            {/*) : ( <NO_DATA_AVAILABLE period={period} />*/}
                                                            {/*)}*/}
                                                        </div>
                                                        <div>
                                                            {/*{spendByAccounts !== null ? (*/}
                                                            <ReactD3GridBarChart title={"Spend by Accounts"} cost={spendByAccounts?.cost} currency={currency} mouseEffect={true} data={spendByAccounts?.data} />
                                                            {/*) : (*/}
                                                            {/*    <NO_DATA_AVAILABLE period={period} />*/}
                                                            {/*)}*/}
                                                        </div>
                                                        <div>
                                                            {/*{spendByCategory !== null ? (*/}
                                                            <ReactD3GridBarChart title={"Spend by Category"} cost={spendByCategory?.cost} currency={currency} mouseEffect={true} data={spendByCategory?.data} />
                                                            {/*) : (*/}
                                                            {/*    <NO_DATA_AVAILABLE period={period} />*/}
                                                            {/*)}*/}
                                                        </div>
                                                    </SlideShow>
                                                </div>
                                                <div style={{ position: "absolute", right: 1 }}>
                                                    <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                        <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </ContainerCardWithDateRange>
                            </Grid>
                            <Grid item xs={6}>
                                <ContainerCardWithDateRange
                                    showDateRange={true}
                                    title={"Total spend"}
                                    titleIcon={Total_Spend_Icon_New}
                                    HeaderTitleComponent={(e, setCollapseClickable) => (
                                        <>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                                <span>Total spend</span>
                                                <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)}  onMouseOut={() => setCollapseClickable(true)} className={'pause-play-icon'}>
                                                    <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                        <div onClick={() => pausePlayTop5Ref?.current?.click()}>
                                                            {pausePlayTop5Ref?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    onPeriodChange={setTop5Period}
                                    // saveAsCSV={true}
                                    // onSaveAsCSV={onSaveAsCSV}
                                >
                                    {() => (
                                        <div style={{}}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    //  justifyContent: 'center'
                                                }}
                                            >
                                                <div style={{ position: "absolute", zIndex: 10, marginLeft: 5 }}>
                                                    <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                        <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                    </IconButton>
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <SlideShow showingIndex={chartTop5ShowingIndex} names={["Top 5 Serverless Services", "Top 5 Locations", "Top 5 Services"]} view="All" arrowsHandled={true} pausePlayRef={pausePlayTop5Ref}>
                                                        <div>
                                                            {/*{top5ServerlessServicess !== null ? (*/}
                                                            <ReactD3GridBarChart title={"Top 5 Serverless Services"} cost={top5ServerlessServicess?.cost} currency={currency} mouseEffect={true} data={top5ServerlessServicess?.data} />
                                                            {/*) : (*/}
                                                            {/*    <NO_DATA_AVAILABLE period={period} />*/}
                                                            {/*)}*/}
                                                        </div>
                                                        <div>
                                                            {/*{top5Locations !== null ? (*/}
                                                            <ReactD3GridBarChart title={"Top 5 Locations"} cost={top5Locations?.cost} currency={currency} mouseEffect={true} data={top5Locations?.data} />
                                                            {/*) : (*/}
                                                            {/*<NO_DATA_AVAILABLE period={period} />*/}
                                                            {/*)}*/}
                                                        </div>
                                                        <div>
                                                            {/*{top5Services !== null ? (*/}
                                                            <ReactD3GridBarChart title={"Top 5 Services"} cost={top5Services?.cost} currency={currency} mouseEffect={true} data={top5Services?.data} />
                                                            {/*) : (*/}
                                                            {/*    <NO_DATA_AVAILABLE period={period} />*/}
                                                            {/*)}*/}
                                                        </div>
                                                    </SlideShow>
                                                </div>
                                                <div style={{ position: "absolute", right: 30, zIndex: 10 }}>
                                                    <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                        <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </ContainerCardWithDateRange>
                            </Grid>
                        </Grid>
                        <br />
                        
                            <Grid container spacing={1}>                        
                                <Grid item xs={6}>
                                    <ContainerCardWithDateRange showDateRange={false} title={"Calendar"} titleIcon={calendar_icon}>
                                        {() => (
                                            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                            {calendarLoading ? (
                                                <CircularProgress thickness={2} size={60} />
                                                 ) : (
                                             <YearCalendarData
                                                style={{ flex: 1 }}
                                                currentCloud={currentCloud}
                                                currency={Format.formatCurrencySymbol(currency || "USD")}
                                                year={currentYear}
                                                onNext={() => setCurrentYear(currentYear + 1)}
                                                onPrev={() => setCurrentYear(currentYear - 1)}
                                                yearData={CalendarDataProcessor(yearWiseData?.[currentCloud == "total" ? "all" : currentCloud]?.SpendByMonth, "year")}
                                                monthData={CalendarDataProcessor(monthWiseData?.[currentCloud == "total" ? "all" : currentCloud]?.SpendByDaily, "month")?.[currentYear]?.[currentMonth?.toString()?.substring(0, 3)] || null}
                                                onMonthSelect={onMonthSelect}
                                                month={currentMonth}
                                                renderOverlay={(d, m, y, cost) => {
                                                    const { data, date } = getOverlayData(d, m, y);

                                                    if (data) return setOverlayData({ data, date, cost: data?.cost });
                                                    return null;
                                                }}
                                                calendarWidth={"100%"}
                                            />
                                                )}
                                            </div>
                                        )}
                                        
                                    </ContainerCardWithDateRange>
                                </Grid>
                                                               
                                <Grid item xs={6}>                                
                                        <ContainerCardWithDateRange showDateRange={false} title={"Calendar details"} titleIcon={calendar_icon}>
                                            {() => (
                                                  <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                {!!!overlayData ? (
                                                    <CircularProgress thickness={2} size={60} />
                                                     ) : (
                                                        overlayData && overlayData.data && (
                                                <OverlayComponent
                                                    style={{ flex: 1 }}
                                                    showArrows={false}
                                                    cost={overlayData?.cost}
                                                    data={overlayData?.data}
                                                    date={overlayData?.date}
                                                    headerIcon={CLOUD_ICONS[currentCloud]}
                                                    dataKeys={[
                                                        {
                                                            key: currentCloud == "gcp" ? "billingAccounts" : "subscriptions",
                                                            title: {
                                                                total: "Top Accounts",
                                                                azure: "Top Azure Subscriptions",
                                                                aws: "Top AWS Accounts",
                                                                gcp: "Top GCP Billing Accounts",
                                                            }[currentCloud],
                                                            subKey: currentCloud == "gcp" ? "billingAccountName" : "subscriptionName",
                                                            subKey2: currentCloud == "gcp" ? "billingAccountId" : "subscriptionId",
                                                            onMouseOverSet: {
                                                                all: "subscriptionId",
                                                                azure: "subscriptionId",
                                                                aws: "subscriptionId",
                                                                // gcp: "subscriptionName",
                                                                // currentCloud == 'gcp' ? "billingAccountId" : null
                                                            },
                                                            iconSet: {
                                                                all: aws_account,
                                                                azure: azure_subscription,
                                                                aws: aws_account,
                                                                gcp: gcp_billing_account,
                                                            },
                                                            icon:
                                                                currentCloud != "total"
                                                                    ? {
                                                                          all: aws_account,
                                                                          azure: azure_subscription,
                                                                          aws: aws_account,
                                                                          gcp: gcp_billing_account,
                                                                      }[currentCloud]
                                                                    : null,
                                                        },
                                                        currentCloud == "gcp"
                                                            ? {
                                                                  key: "projects",
                                                                  title: "Top Projects",
                                                                  subKey: "projectId",
                                                                  icon: gcp_project,
                                                              }
                                                            : null,
                                                        {
                                                            key: "locations",
                                                            title: {
                                                                total: "Top Locations",
                                                                azure: "Top Azure Locations",
                                                                aws: "Top AWS Locations",
                                                                gcp: "Top GCP Locations",
                                                            }[currentCloud],
                                                            subKey: "location",
                                                            iconSet: {
                                                                all: aws_region,
                                                                azure: azure_location,
                                                                aws: aws_region,
                                                                gcp: cloud_location,
                                                            },
                                                            icon:
                                                                currentCloud != "total"
                                                                    ? {
                                                                          all: aws_region,
                                                                          azure: azure_location,
                                                                          aws: aws_region,
                                                                          gcp: cloud_location,
                                                                      }[currentCloud]
                                                                    : null,
                                                        },
                                                        {
                                                            key: "services",
                                                            title: {
                                                                total: "Top Services",
                                                                azure: "Top Azure Services",
                                                                aws: "Top AWS Services",
                                                                gcp: "Top GCP Services",
                                                            }[currentCloud],
                                                            subKey: "service",
                                                            subKey2: "serviceCode",
                                                            iconSet: {
                                                                all: resource_group,
                                                                azure: resource_group,
                                                                aws: resource_group,
                                                                gcp: gcp_compute,
                                                            },
                                                            icon:
                                                                currentCloud != "total"
                                                                    ? {
                                                                          all: resource_group,
                                                                          azure: resource_group,
                                                                          aws: resource_group,
                                                                          gcp: gcp_compute,
                                                                      }[currentCloud]
                                                                    : null,
                                                        },
                                                        currentCloud == "azure"
                                                            ? {
                                                                  key: "resourceGroups",
                                                                  title: "Top Azure Resource groups",
                                                                  subKey: "resourceGroupName",
                                                                  icon: azure_resource_group,
                                                              }
                                                            : null,
                                                        {
                                                            key: "resources",
                                                            title: {
                                                                total: "Top Resources",
                                                                azure: "Top Azure Resources",
                                                                aws: "Top AWS Resources",
                                                                gcp: "Top GCP Resources",
                                                            }[currentCloud],
                                                            subKey: currentCloud == "gcp" ? "skuId" : "resourceId",
                                                            // onMouseOver: currentCloud=='gcp'?"billingAccountId":null,
                                                            iconSet: {
                                                                all: resource_group,
                                                                azure: resource_group,
                                                                aws: resource_group,
                                                                gcp: resource_group,
                                                            },
                                                            icon:
                                                                currentCloud != "total"
                                                                    ? {
                                                                          all: resource_group,
                                                                          azure: resource_group,
                                                                          aws: resource_group,
                                                                          gcp: resource_group,
                                                                      }[currentCloud]
                                                                    : null,
                                                        },
                                                    ]}
                                                    cloud={currentCloud}
                                                    currency={Format.formatCurrencySymbol(currency)}
                                                    maxWidth={"100%"}
                                                    maxHeight={700}
                                                />
                                                ))}
                                                </div>
                                            )}
                                        </ContainerCardWithDateRange>                                   
                                </Grid>                              
                            </Grid>       
                        <br />
                        <ContainerCardWithDateRange
                            showDateRange={false}
                            title={"Spend by months"}
                            titleIcon={calendar_icon}
                            HeaderTitleComponent={(e, setCollapseClickable) => (
                                <>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                        <span>Spend by months</span>
                                        <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)}  onMouseOut={() => setCollapseClickable(true)} className={'pause-play-icon'}>
                                            <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                <div onClick={() => pausePlaySpendRef?.current?.click()}>
                                                    {pausePlaySpendRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </>
                            )}
                            onPeriodChange={setPeriod}
                            // saveAsCSV={true}
                            // onSaveAsCSV={onSaveAsCSV}
                        >
                            {() => (
                                <div style={{}}>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            //  justifyContent: 'center'
                                        }}
                                    >
                                        <div style={{ position: "absolute", zIndex: 10, marginLeft: 5 }}>
                                            <IconButton onClick={() => setChart2ShowingIndex(chart2ShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                            </IconButton>
                                        </div>

                                        <SlideShow pausePlayRef={pausePlaySpendRef} delay={TIMERS.LOWER_CHART_INTERVAL} names={["Spend by Clouds", "Top 5 Locations", "Top 5 Services"]} showingIndex={chart2ShowingIndex} arrowsHandled={true}>
                                            <>
                                                <GroupedBarChart
                                                    maxWidth={null}
                                                    currency={Format.formatCurrencySymbol(currency)}
                                                    totalCost={null}
                                                    // mouseEffect={true}
                                                    data={monthWiseChartData}
                                                    cloudCount={cloudCount}
                                                />
                                            </>
                                            <>
                                                <StackedBarChart2
                                                    maxWidth={null}
                                                    currency={Format.formatCurrencySymbol(currency)}
                                                    totalCost={null}
                                                    keyStack={"locations"}
                                                    keyField={"location"}
                                                    valueKey={"cost"}
                                                    // mouseEffect={true}
                                                    data={othersMonthWiseChartData}
                                                    cloudCount={1}
                                                />
                                            </>
                                            <>
                                                <StackedBarChart2
                                                    maxWidth={null}
                                                    currency={Format.formatCurrencySymbol(currency)}
                                                    totalCost={null}
                                                    keyStack={"services"}
                                                    keyField={"service"}
                                                    keyField2={"serviceCode"}
                                                    valueKey={"cost"}
                                                    // mouseEffect={true}
                                                    data={othersMonthWiseChartData}
                                                    cloudCount={1}
                                                />
                                            </>
                                        </SlideShow>
                                        <div style={{ position: "absolute", right: 30, zIndex: 10 }}>
                                            <IconButton onClick={() => setChart2ShowingIndex(chart2ShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </ContainerCardWithDateRange>
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.RECOMMENDATIONS} style={{paddingLeft: 5, paddingRight: 5}}>
                    <AllCloudRecommendation customerId={customerId} progress={progress} />
                </TabPanel>
                <TabPanel value={TAB_SET.BILLING} onLoad={rootLoaded} className={"root_dashboard"}>
                    <div style={{ maxWidth: "calc(100vw - 120px)" }} ref={billingComponentRef}>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <span onClick={collapseCards} className={'link-button'}>{dashboardComponentsCollapsed?"Expand All":"Collapse All"}
                                <img src={!dashboardComponentsCollapsed?ArrowUp:ArrowDown} height={20} />
                            </span>
                    </div>
                        <MountUnmount onUnmount={()=> {
                            setDashboardComponentsRef({});
                            setDashboardComponentsCollapsed(false);
                        }} />
                    <AllCloudBillingTab
                        cloudCount={cloudCount}
                        getAll1YearMonthlyData={getAll1YearMonthlyData}
                        yearWiseData={yearWiseData}
                        cloudIcons={CLOUD_ICONS}
                        currency={currency}
                        monthWiseData={monthWiseData}
                        calendarLoading={calendarLoading}
                        yearTableData={yearTableData}
                        somerefs={[
                            {how: setDashboardComponentsRef, what:'billing1', where: dashboardComponentsRef},
                            {how: setDashboardComponentsRef, what:'billing2', where: dashboardComponentsRef}
                        ]}
                    />
                    <br/>
                        <AWSTagsCostCategories customerId={customerId} currency={currency}/>
                        <br/>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                            <ContainerCardWithDateRange
                                someref={{how: setDashboardComponentsRef, what:'totalSpend1', where: dashboardComponentsRef}}
                                showDateRange={true}
                                title={"Total spend"}
                                titleIcon={Total_Spend_Icon_New}
                                HeaderTitleComponent={(e, setCollapseClickable) => (
                                    <>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                            <span>Total spend</span>
                                            <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)}  onMouseOut={() => setCollapseClickable(true)} className={'pause-play-icon'}>
                                                <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                    <div onClick={() => pausePlayTotalRef?.current?.click()}>
                                                        {pausePlayTotalRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </>
                                )}
                                onPeriodChange={setPeriod}
                                // saveAsCSV={true}
                                // onSaveAsCSV={onSaveAsCSV}
                            >
                                {() => (
                                    <div style={{}}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                position: "relative",
                                                //  justifyContent: 'center'
                                            }}
                                        >
                                            <div style={{ position: "absolute", zIndex: 1 }}>
                                                <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                    <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <SlideShow showingIndex={chartShowingIndex} names={["Spend by Clouds", "Spend by Accounts", "Spend by Category"]} view="All" arrowsHandled={true} pausePlayRef={pausePlayTotalRef}>
                                                    <div>
                                                        {/*{allCLoud !== null ? (*/}
                                                        <ReactD3GridPieChart title={"Spend by Clouds"} cost={allCLoud?.cost} currency={currency} data={allCLoud?.data} />
                                                        {/*) : ( <NO_DATA_AVAILABLE period={period} />*/}
                                                        {/*)}*/}
                                                    </div>
                                                    <div>
                                                        {/*{spendByAccounts !== null ? (*/}
                                                        <ReactD3GridBarChart title={"Spend by Accounts"} cost={spendByAccounts?.cost} currency={currency} mouseEffect={true} data={spendByAccounts?.data} />
                                                        {/*) : (*/}
                                                        {/*    <NO_DATA_AVAILABLE period={period} />*/}
                                                        {/*)}*/}
                                                    </div>
                                                    <div>
                                                        {/*{spendByCategory !== null ? (*/}
                                                        <ReactD3GridBarChart title={"Spend by Category"} cost={spendByCategory?.cost} currency={currency} mouseEffect={true} data={spendByCategory?.data} />
                                                        {/*) : (*/}
                                                        {/*    <NO_DATA_AVAILABLE period={period} />*/}
                                                        {/*)}*/}
                                                    </div>
                                                </SlideShow>
                                            </div>
                                            <div style={{ position: "absolute", right: 1 }}>
                                                <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                    <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </ContainerCardWithDateRange>
                        </Grid>
                        <Grid item xs={6}>
                            <ContainerCardWithDateRange
                                someref={{how: setDashboardComponentsRef, what:'totalSpend2', where: dashboardComponentsRef}}
                                showDateRange={true}
                                title={"Total spend"}
                                titleIcon={Total_Spend_Icon_New}
                                HeaderTitleComponent={(e, setCollapseClickable) => (
                                    <>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                            <span>Total spend</span>
                                            <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)}  onMouseOut={() => setCollapseClickable(true)} className={'pause-play-icon'}>
                                                <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                    <div onClick={() => pausePlayTop5Ref?.current?.click()}>
                                                        {pausePlayTop5Ref?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </>
                                )}
                                onPeriodChange={setTop5Period}
                                // saveAsCSV={true}
                                // onSaveAsCSV={onSaveAsCSV}
                            >
                                {() => (
                                    <div style={{}}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                //  justifyContent: 'center'
                                            }}
                                        >
                                            <div style={{ position: "absolute", zIndex: 10, marginLeft: 5 }}>
                                                <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                    <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <SlideShow showingIndex={chartTop5ShowingIndex} names={["Top 5 Serverless Services", "Top 5 Locations", "Top 5 Services"]} view="All" arrowsHandled={true} pausePlayRef={pausePlayTop5Ref}>
                                                    <div>
                                                        {/*{top5ServerlessServicess !== null ? (*/}
                                                        <ReactD3GridBarChart title={"Top 5 Serverless Services"} cost={top5ServerlessServicess?.cost} currency={currency} mouseEffect={true} data={top5ServerlessServicess?.data} />
                                                        {/*) : (*/}
                                                        {/*    <NO_DATA_AVAILABLE period={period} />*/}
                                                        {/*)}*/}
                                                    </div>
                                                    <div>
                                                        {/*{top5Locations !== null ? (*/}
                                                        <ReactD3GridBarChart title={"Top 5 Locations"} cost={top5Locations?.cost} currency={currency} mouseEffect={true} data={top5Locations?.data} />
                                                        {/*) : (*/}
                                                        {/*<NO_DATA_AVAILABLE period={period} />*/}
                                                        {/*)}*/}
                                                    </div>
                                                    <div>
                                                        {/*{top5Services !== null ? (*/}
                                                        <ReactD3GridBarChart title={"Top 5 Services"} cost={top5Services?.cost} currency={currency} mouseEffect={true} data={top5Services?.data} />
                                                        {/*) : (*/}
                                                        {/*    <NO_DATA_AVAILABLE period={period} />*/}
                                                        {/*)}*/}
                                                    </div>
                                                </SlideShow>
                                            </div>
                                            <div style={{ position: "absolute", right: 30, zIndex: 10 }}>
                                                <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                    <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </ContainerCardWithDateRange>
                        </Grid>
                    </Grid>
                    <br />
                    <ContainerCardWithDateRange
                        someref={{how: setDashboardComponentsRef, what:'spendByMonth', where: dashboardComponentsRef}}
                        showDateRange={false}
                        title={"Spend by months"}
                        titleIcon={calendar_icon}
                        HeaderTitleComponent={(e, setCollapseClickable) => (
                            <>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                    <span>Spend by months</span>
                                    <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)}  onMouseOut={() => setCollapseClickable(true)} className={'pause-play-icon'}>
                                        <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                            <div onClick={() => pausePlaySpendRef?.current?.click()}>
                                                {pausePlaySpendRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </>
                        )}
                        onPeriodChange={setPeriod}
                        // saveAsCSV={true}
                        // onSaveAsCSV={onSaveAsCSV}
                    >
                        {() => (
                            <div style={{}}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        //  justifyContent: 'center'
                                    }}
                                >
                                    <div style={{ position: "absolute", zIndex: 10, marginLeft: 5 }}>
                                        <IconButton onClick={() => setChart2ShowingIndex(chart2ShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                            <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                        </IconButton>
                                    </div>

                                    <SlideShow pausePlayRef={pausePlaySpendRef} delay={TIMERS.LOWER_CHART_INTERVAL} names={["Spend by Clouds", "Top 5 Locations", "Top 5 Services"]} showingIndex={chart2ShowingIndex} arrowsHandled={true}>
                                        <>
                                            <GroupedBarChart
                                                maxWidth={null}
                                                currency={Format.formatCurrencySymbol(currency)}
                                                totalCost={null}
                                                // mouseEffect={true}
                                                data={monthWiseChartData}
                                                cloudCount={cloudCount}
                                            />
                                        </>
                                        <>
                                            <StackedBarChart2
                                                maxWidth={null}
                                                currency={Format.formatCurrencySymbol(currency)}
                                                totalCost={null}
                                                keyStack={"locations"}
                                                keyField={"location"}
                                                valueKey={"cost"}
                                                // mouseEffect={true}
                                                data={othersMonthWiseChartData}
                                                cloudCount={1}
                                            />
                                        </>
                                        <>
                                            <StackedBarChart2
                                                maxWidth={null}
                                                currency={Format.formatCurrencySymbol(currency)}
                                                totalCost={null}
                                                keyStack={"services"}
                                                keyField={"service"}
                                                keyField2={"serviceCode"}
                                                valueKey={"cost"}
                                                // mouseEffect={true}
                                                data={othersMonthWiseChartData}
                                                cloudCount={1}
                                            />
                                        </>
                                    </SlideShow>
                                    <div style={{ position: "absolute", right: 30, zIndex: 10 }}>
                                        <IconButton onClick={() => setChart2ShowingIndex(chart2ShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                            <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        )}
                    </ContainerCardWithDateRange>
                        <div id={'final-spend-by-quarter'}>

                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.ACCOUNTS}>
                    <AllCloudAccount currency={Format.formatCurrencySymbol(currency)} progress={progress} customerId={customerId} />
                </TabPanel>
                <TabPanel value={TAB_SET.SERVICES}>
                    <AllCloudServices currency={Format.formatCurrencySymbol(currency)} progress={progress} customerId={customerId} />{" "}
                </TabPanel>
                <TabPanel value={TAB_SET.RESOURCES}>
                    <AllCloudResources currency={Format.formatCurrencySymbol(currency)} progress={progress} customerId={customerId} />{" "}
                </TabPanel>
                <TabPanel value={TAB_SET.COMPUTE}>
                    <div>
                        <AllCloudCompute currency={Format.formatCurrencySymbol(currency)} progress={progress} customerId={customerId} />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.DATABASE}>
                    <div>
                        <AllCloudDatabase currency={Format.formatCurrencySymbol(currency)} progress={progress} customerId={customerId} />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.FUNCTIONS}>
                    <div>
                        <AllCloudFunctions currency={Format.formatCurrencySymbol(currency)} progress={progress} customerId={customerId} />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.STORAGE}>
                    <div>
                        <AllCloudStorage currency={Format.formatCurrencySymbol(currency)} progress={progress} customerId={customerId} />
                    </div>
                </TabPanel>
            </TabContext>
        </div>
    );
}

export function generateGroupedChartData(yearWiseData, setData, setCloudCount, includeSubObjects = [], allData = false) {
    const finalM = [];
    function calculate(data, cloud, final = finalM) {
        if (!data) return null;
        var allMonths = {};
        const monthsFromNow = getMonthsFromNow([], false, true);
        new Set(monthsFromNow.map((t) => t.year)).forEach((year) => {
            allMonths[year] = [];
            monthsFromNow.forEach((m) => {
                if (year === m.year) allMonths[year].push(m.month);
            });
        });
        for (let year in allMonths) {
            allMonths[year].forEach((month) => {
                const found = data?.[year]?.[month];
                const g = final.findIndex((e) => e.dataLabel === month + " " + year);
                if (g > -1) {
                    // const prev = final[g].values[0].value * g;
                    if (found != null && found != undefined) {
                        const curr = includeSubObjects && includeSubObjects.length ? found : found.toFixed(2);
                        final[g].values.push({ value: parseFloat(curr).toFixed(2), legend: cloud });
                    } else {
                        // const curr = data[year][month].toFixed(2);
                        final[g].values.push({ value: "-", legend: cloud });
                    }
                } else {
                    const curr = found != null && found != undefined ? (includeSubObjects && includeSubObjects.length ? found : found.toFixed(2)) : "-";
                    final.push({ dataLabel: month + " " + year, values: [{ value: curr, legend: cloud }] });
                    // const curr = data[year][month].toFixed(2);
                    // final[g].values.push({value: "-", legend: cloud});
                }
            });
        }
        // Object.keys(data).reverse().forEach(year=>{
        //   for(const month in data[year]){
        //     const g = final.findIndex(e=>e.dataLabel===month + " " +year);
        //     if(g > -1){
        //       // const prev = final[g].values[0].value * g;
        //       const curr = data[year][month].toFixed(2);
        //       final[g].values.push({value: parseFloat(curr).toFixed(2), legend: cloud});
        //     } else {
        //       final.push({dataLabel: month + " " +year, values: [{value: data[year][month].toFixed(2), legend: cloud}]});
        //     }
        //   }
        // })
        // for(const year in data){
        //   // const final = [];
        //
        // }
    }

    if (yearWiseData) {
        var cloudCount = 0;
        const cdp = CalendarDataProcessor(yearWiseData?.["azure"]?.SpendByMonth, "year", includeSubObjects);
        cloudCount += cdp && cdp.length ? 1 : 0;
        calculate(cdp, "Azure");
        const cdp2 = CalendarDataProcessor(yearWiseData?.["aws"]?.SpendByMonth, "year", includeSubObjects);
        cloudCount += cdp2 && cdp2.length ? 1 : 0;
        calculate(cdp2, "AWS");
        const cdp3 = CalendarDataProcessor(yearWiseData?.["gcp"]?.SpendByMonth, "year", includeSubObjects);
        cloudCount += cdp3 && cdp3.length ? 1 : 0;
        calculate(cdp3, "GCP");

        if (allData) {
            const cdp4 = CalendarDataProcessor(yearWiseData?.["all"]?.SpendByMonth, "year", includeSubObjects);
            cloudCount += cdp4 && cdp4.length ? 1 : 0;
            calculate(cdp4, "All");
        }
        setCloudCount(cloudCount);
        setData(finalM);
        // setTimeout(()=>{
        //   const final = [];
        //   const cdp = CalendarDataProcessor(
        //       yearWiseData?.['azure']?.SpendByMonth, "year"
        //   );
        //   calculate(cdp, 'AWS', final);
        //   const cdp2 = CalendarDataProcessor(
        //       yearWiseData?.['aws']?.SpendByMonth, "year"
        //   );
        //   calculate(cdp2, 'Azure', final);
        //   setMonthWiseChartData(final);
        // }, 5000);
    }
}

function BillingComponent({ title, data }) {
    return (
        <Box
            padding={0.2}
            flexGrow={1}
            alignItems={"stretch"}
            flex={1}
            style={{
                marginTop: 0,
                marginBottom: 0,
                paddingTop: 0,
                paddingBottom: 0,
            }}
        >
            <Paper style={{ height: "100%", flexGrow: 1, flex: 1 }}>
                <div style={{ width: "100%", padding: 4, backgroundColor: color.blue, borderTopLeftRadius: 5, borderTopRightRadius: 8 }}>
                    <span style={{ color: "white" }}>{title}</span>
                </div>
                {data.map((c) => (
                    <div
                        style={{
                            marginRight: 5,
                            marginLeft: 5,
                            borderRadius: 19,
                            display: "flex",
                            marginTop: 3,
                            border: "1px solid #d3d3d3",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img style={{ height: 12, marginLeft: 5 }} src={CLOUD_ICONS[c.cloud]} />
                        </div>
                        <span style={{ fontSize: 12, marginLeft: 10 }}>
                            <Currency />
                            {c.cost && Format.formatNumber(c.cost) ? <Cost>{c.cost}</Cost> : "_._"}
                        </span>
                    </div>
                ))}
            </Paper>
        </Box>
    );
}

function YearToDateBilling({ data }) {
    // console.log({datassss: data})
    // return null
    return (
        <Box display={"flex"} style={{ marginLeft: 5, marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0 }} flex={1} flexDirection={"column"} alignItems={"stretch"} justifyContent={"space-evenly"}>
            <Box display={"flex"} flex={1} alignItems={"stretch"} justifyContent={"space-evenly"} style={{ marginTop: 0, marginBottom: 4, paddingTop: 0, paddingBottom: 0 }}>
                <BillingComponent title={data?.["ytd"]["year"] + " YTD"} data={data?.["ytd"]["list"]} />
                {/*<BillingComponent title={data?.["otherYear"]["year"] + " "+ (data?.["otherYear"]["start"]?.toUpperCase() + "-" + data?.["otherYear"]["end"]?.toUpperCase())} data={data?.["otherYear"]["list"]} />*/}
                <BillingComponent title={data?.["otherYear"]["year"] + " " + "Jan-Dec"} data={data?.["otherYear"]["list"]} />
            </Box>
            <Box display={"flex"} flex={1} alignItems={"stretch"} justifyContent={"space-evenly"} style={{ marginTop: 0, marginBottom: 2, paddingTop: 0, paddingBottom: 0 }}>
                <BillingComponent title={"Q1 " + data?.["quarter"]["1"].year} data={data?.["quarter"]["1"].list} />
                <BillingComponent title={"Q2 " + data?.["quarter"]["2"].year} data={data?.["quarter"]["2"].list} />
            </Box>
            <Box display={"flex"} flex={1} alignItems={"stretch"} justifyContent={"space-evenly"} style={{ marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0 }}>
                <BillingComponent title={"Q3 " + data?.["quarter"]["3"].year} data={data?.["quarter"]["3"].list} />
                <BillingComponent title={"Q4 " + data?.["quarter"]["4"].year} data={data?.["quarter"]["4"].list} />
            </Box>
        </Box>
    );
}
