import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import {
    getAWSEBSRecommendationsFailure, getAWSEBSRecommendationsSuccess
} from "../actions/actions";
const { REACT_APP_AWS_EBS_RECOMMENDATION } = process.env;

function* getawsEBSRecommendationSaga({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AWS_EBS_RECOMMENDATION, {
            params: {
                customerId: payload.customerId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAWSEBSRecommendationsFailure(statusCode));
            } else {
                yield put(getAWSEBSRecommendationsSuccess(res.data));
            }
        } else {
            yield put(getAWSEBSRecommendationsFailure(res));
        }
    } catch (error) {
        yield put(getAWSEBSRecommendationsFailure(error));
    }
}

export default function* () {
    yield takeLatest(types.AWS_EBS_RECOMMENDATION_REQUEST, getawsEBSRecommendationSaga);
}
