import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { gcpServerlessSuccess, gcpServerlessFail } from "../actions/actions";
const { REACT_APP_GCP_SERVERLESS } = process.env;

function* getGcpServerlessData({ payload }) {
    // const { customerId, value, organizationId, projectId } = payload;
    try {
        const res = yield call(axios.get, REACT_APP_GCP_SERVERLESS, {
            params: {
                customerId: payload.customerId,
                organizationId: payload.organizationId,
                billingAcountId: payload.billingAccountId,
                projectId: payload.projectId,
            },
        });

        const {
            data: { statusCode },
        } = res;
        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(gcpServerlessFail(statusCode));
            } else {
                yield put(gcpServerlessSuccess(res.data));
            }
        } else {
            yield put(gcpServerlessFail(res.data));
        }
    } catch (error) {
        yield put(gcpServerlessFail(error));
    }
}

export default function* gcpServerlessSaga() {
    yield takeLatest(types.GCP_SERVERLESS_REQUEST, getGcpServerlessData);
}
