import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { signInSuccess, signFailure } from "../actions/actions";
import {
  GET_CUSTOMER_STATUS_FAILURE,
  GET_CUSTOMER_STATUS_REQUEST, GET_CUSTOMER_STATUS_SUCCESS,
  GET_MESSAGES_FAILURE,
  GET_MESSAGES_REQUEST,
  GET_MESSAGES_SUCCESS,
  GET_WHITE_LABEL_FAILURE,
  GET_WHITE_LABEL_REQUEST,
  GET_WHITE_LABEL_SUCCESS,
    GET_CONNECTED_TEAMS_LIST_REQUEST,
    GET_CONNECTED_TEAMS_LIST_FAILURE,
    GET_CONNECTED_TEAMS_LIST_SUCCESS,
} from "../actions/types";
import moment from "moment";
const { REACT_APP_GET_INVITED_TEAMS, REACT_APP_SIGNUP_SIGNIN, REACT_APP_GET_MESSAGES, REACT_APP_GET_WHITE_LABELLING, REACT_APP_GET_CUSTOMER_STATUS, REACT_APP_GET_CUSTOMER_DETAILS } = process.env;

function* getUserData({ payload }) {
  try {
    const res = yield call(
      axios.get,
      REACT_APP_SIGNUP_SIGNIN + `signupOrSignin?id_token=${payload}`
    );
    yield put(signInSuccess(res.data));
  } catch (error) {
    yield put(signFailure(error));
  }
}

export default function* userSaga() {
  yield takeLatest(types.USER_API_LOADING, getUserData);
}

function* getAllMessages({ payload }) {
  try {
    const res = yield call(axios.get, REACT_APP_GET_MESSAGES, {
      params: { customerId: payload },
    });

    const {
      data: { status },
    } = res;
    if ("status" in res.data) {
      if (status != 200) {
        yield put({
          type: GET_MESSAGES_FAILURE,
          payload: null
        });
      } else {
        // console.log({res});
        yield put({
          type: GET_MESSAGES_SUCCESS,
          payload: res.data
        });
      }
    } else {
      yield put({
        type: GET_MESSAGES_FAILURE,
        payload: null
      });
    }
  } catch (error) {
    // console.log(error)
    yield put({
      type: GET_MESSAGES_FAILURE,
      payload: null
    });
  }
}

export function* getMessagesSaga() {
  yield takeLatest(GET_MESSAGES_REQUEST, getAllMessages);
}

export function* getWhiteLabel() {
  yield takeLatest(GET_WHITE_LABEL_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(axios.get, REACT_APP_GET_WHITE_LABELLING, {
        params: { customerId: payload },
      });

      const {
        status,
      } = res;

      if ("status" in res) {
        if (status != 200) {
          yield put({
            type: GET_WHITE_LABEL_FAILURE,
            payload: null
          });
        } else {

          yield put({
            type: GET_WHITE_LABEL_SUCCESS,
            payload: res.data
          });
        }
      } else {
        yield put({
          type: GET_WHITE_LABEL_FAILURE,
          payload: null
        });
      }
    } catch (error) {
      // console.log(error)
      yield put({
        type: GET_WHITE_LABEL_FAILURE,
        payload: null
      });
    }
  });
}

export function* getCustomerStatus() {
  yield takeLatest(GET_CUSTOMER_STATUS_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(axios.get, REACT_APP_GET_CUSTOMER_STATUS, {
        params: { customerId: payload.customerId },
      });

      const {
        data
      } = res;
      if ("status_code" in data) {
        if (parseInt(data.status_code) != 200) {
          yield put({
            type: GET_CUSTOMER_STATUS_FAILURE,
            payload: null
          });
        } else {
          // if(data?.payment_model &&
          //   data?.payment_status &&
          //   data?.status){
          //   // payload.dispatch({
          //   //   type: GET_PAYMENT_STATUS_REQUEST,
          //   //   payload: payload.customerId
          //   // })
          // }
          yield put({
            type: GET_CUSTOMER_STATUS_SUCCESS,
            payload: data
          });
        }
      } else {
        yield put({
          type: GET_CUSTOMER_STATUS_FAILURE,
          payload: null
        });
      }
    } catch (error) {
      // console.log(error)
      yield put({
        type: GET_CUSTOMER_STATUS_FAILURE,
        payload: null
      });
    }
  });
}

export function* getConnectedTeamsList() {
  yield takeLatest(GET_CONNECTED_TEAMS_LIST_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(axios.get, REACT_APP_GET_INVITED_TEAMS, {
        params: { customerId: payload },
      });

      const {
        status, data
      } = res;

      if ("status" in res) {
        if (status != 200) {
          yield put({
            type: GET_CONNECTED_TEAMS_LIST_FAILURE,
            payload: null
          });
        } else {
          const acceptedTeams = [];
          if (data && data.statusCode == 200) {
            let val = data.data;
            val.invitedTeam.map((i) => acceptedTeams.push({ label: i.team_name, value: i.customerId, role: i.role }));
          }
          yield put({
            type: GET_CONNECTED_TEAMS_LIST_SUCCESS,
            payload: acceptedTeams
          });
        }
      } else {
        yield put({
          type: GET_CONNECTED_TEAMS_LIST_FAILURE,
          payload: null
        });
      }
    } catch (error) {
      // console.log(error)
      yield put({
        type: GET_CONNECTED_TEAMS_LIST_FAILURE,
        payload: null
      });
    }
  });
}
