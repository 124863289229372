import React, { useState, useEffect } from "react";
import "./style.css";
import Format from "components/NumberFormat";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import moment from "moment";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import {Backdrop, Fade, LinearProgress, Modal} from "@mui/material";
import IconButton from "@mui/material/Icon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { makeStyles, withStyles } from "MakeWithStyles";
import Button from "@mui/material/Button";
import {CancelRounded, Help, Info} from "@mui/icons-material";
import RecommendationsSummaryCards from "./RecommendationSummaryCards";
import recommendation_icon from "../../images/recommended.png";
import { Tooltip, OverlayTrigger,Popover } from "react-bootstrap";
import AzureLogo from "../../images/Azure_logo.svg";
import vm_icon from '../../images/Virtual-Machine.svg'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TablePaginationActions from "../../components/TablePaginationActions";
import Color from "../../styles/color";
import Box from "@mui/material/Box";
import {bytesToSize}  from "../../util/Miscellaneous";
import Badge from "@mui/material/Badge";
import LinkText from "../../components/LinkText";
import axios from "../../connection/axios";

const {REACT_APP_LINKED_ACCOUNTS} = process.env;

const useStyles = makeStyles()((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        //marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
}));
const AZURE_VM_TERMINATE_HELP = "https://docs.microsoft.com/en-us/azure-stack/user/delete-vm?view=azs-2108&tabs=portal";

export default function AzureStoppedVmRecommendation({ someref, customerId, title, data, view,type=null, subscriptionId, lastUpdated, currency, cards, loading,tenantName }) {
    const {classes} = useStyles();
    const [totalExpand, setTotalExpand] = useState(true);
    const [selected, setSelected] = React.useState([]);
    const [progress, setProgress] = useState(0);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [list, setList] = useState(null);
    const [diskModalOpened, setDiskModalOpened] = useState(false);
    const [subscriptionsList, setSubscriptionsList] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    async function getLinkedAccounts() {
        axios.get(REACT_APP_LINKED_ACCOUNTS, {
                params: { customerId },
            })
            .then((response) => response.data)
            .then((response) => {
                const data = {};
                response.response.azure.forEach(t=>t.subscriptions.forEach(s=>{data[s.subscriptionId]={...s, ...t, subscriptions: null}}));
                setSubscriptionsList(data);
                // setAzureAccounts(response?.response?.azure);
            })
    }


    useEffect(()=>{
        if(customerId) getLinkedAccounts()
    }, [customerId]);

    return <>
        {
            loading ? (
                <LinearProgress variant="determinate" value={progress} />
            ) : (
                <div>
                    <ContainerCardWithDateRange
                        someref={someref}
                        title={title}
                        titleIcon={AzureLogo}
                        showDateRange={false}
                        collapsible={true}
                        HeaderTitleComponent={()=>(
                            <div style={{ whiteSpace: "nowrap" }}>
                                <img src={vm_icon} height={25} style={{marginLeft: 8, marginRight: 8}} />
                                <span>
                            {title}
                        </span>
                            </div>
                        )}
                        ExtraHeaderComponent={({ period }) =>
                            data != null && Object.keys(data)?.length ? (
                                <div style={{ marginTop: 4 }}>
                                    <span style={{ fontSize: 14 }}>Last updated {moment(lastUpdated).fromNow(true)} ago</span>
                                </div>
                            ) : null
                        }
                        CenterComponent={()=>(
                            <div>
                                {cards != null && cards ?<h6 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20}}>{cards?.[1]?.value} {cards?.[1]?.name}</h6>:null}
                            </div>
                        )}
                    >
                        {({ period }) => (
                            <>
                                {cards && <RecommendationsSummaryCards summary={cards}/>}

                                <div className={classes.root}>
                                    <Collapse in={totalExpand}>
                                        {data != null && data?.recommendations?.length ? (
                                            <Paper className={classes.paper}>
                                                <TableContainer style={{maxHeight:450}}>
                                                    <Table className={classes.table} aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                                        <AzureStoppedVMHead type={type} view={view} subscriptionId={subscriptionId} classes={classes} numSelected={selected.length} rowCount={data?.recommendations?.length} />
                                                        <TableBody>
                                                            {data?.recommendations?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                ?.sort((a, b) => b?.totalAvgDiskCostPerDay - a?.totalAvgDiskCostPerDay)?.map((row, index) => {
                                                                    return <MyTableRow onDiskOpen={(data)=>{
                                                                        setDiskModalOpened(data);
                                                                    }} subscriptionsList={subscriptionsList} type={type} view={view} subscriptionId={subscriptionId} row={row} index={index} list={list} setList={setList} currency={currency} />;
                                                                })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <TablePagination
                                                    ActionsComponent={TablePaginationActions}
                                                    rowsPerPageOptions={[10, 25, 50, 75]}
                                                    component="div"
                                                    count={data?.recommendations?.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            </Paper>
                                        ) : (
                                            <h6 style={{ textAlign: "center" }}>No data found</h6>
                                        )}
                                    </Collapse>
                                </div>
                            </>
                        )}
                    </ContainerCardWithDateRange>
                </div>
            )
        }
        <DiskModal data={diskModalOpened} currency={currency} open={diskModalOpened} onClose={()=>setDiskModalOpened(false)} />
    </>
    ;
}

const useStyles22 = makeStyles()((theme) => ({
    root: {
        backgroundColor: Color.tableHeader,
        padding: theme.spacing(0.5),
    },

    tableRow: {
        fontWeight: "bold",
    },
}));

function AzureStoppedVMHead({ view,type }) {

    const {classes} = useStyles22();

    return (
        <TableHead className={classes.root}>
            <TableRow>
                <TableCell className={classes.tableRow}>VM name</TableCell>
                <TableCell className={classes.tableRow}>Potential savings</TableCell>
                <TableCell className={classes.tableRow}># Disks attached</TableCell>
                <TableCell className={classes.tableRow}>Disk Size</TableCell>
                <TableCell className={classes.tableRow}># Days stopped</TableCell>
                <TableCell className={classes.tableRow}>Location</TableCell>
                {view == "all" ? <TableCell className={classes.tableRow}>Subscription</TableCell> : null}



                {/*<TableCell className={classes.tableRow}> Disk {type==='stopped'?<br/>:null}Savings {type==='idle'?<br/>:null}{type==='idle' && " (per month)"}</TableCell>*/}
                {/*<TableCell className={classes.tableRow}>OS Type</TableCell>*/}
                {/*<TableCell className={classes.tableRow}>Location</TableCell>*/}
                <TableCell className={classes.tableRow}>Action</TableCell>





                {/* <TableCell className={classes.tableRow}>Action <div>
                                                                    <OverlayTrigger placement="left"
                                                                                    overlay={<Tooltip id="button-tooltip">How to terminate virtual machines?</Tooltip>}>
                                                                        <Badge badgeContent={0} color="secondary">
                                                                            <Help onClick={()=>window.open(AZURE_VM_TERMINATE_HELP, "_blank")} style={{cursor: "pointer", fontSize:20, color: "#303" }} />
                                                                        </Badge>
                                                                    </OverlayTrigger>
                                                                </div>
               </TableCell> */}
            </TableRow>
        </TableHead>
    );
}



function MyTableRow({ onDiskOpen, row, type, view, index, list, setList, currency,tenantName, subscriptionsList }) {
    const [open, setOpen] = useState(true);
    const DAYS_PERIOD = 30;

    return (
        <React.Fragment>
            <TableRow hover key={row.stoppedDate}>
                {/*<TableCell>*/}
                {/*    <>*/}
                {/*        <IconButton*/}
                {/*            style={{ cursor: "pointer" }}*/}
                {/*            aria-label="expand row"*/}
                {/*            size="small"*/}
                {/*            onClick={() => {*/}
                {/*                setOpen(!open);*/}
                {/*                setList(index);*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}*/}
                {/*        </IconButton>{" "}*/}
                {/*        <span*/}
                {/*        style={{whiteSpace: 'nowrap'}}>*/}
                {/*        {row?.vmName || row?.vmId}{" "}*/}
                {/*        <OverlayTrigger placement="bottom" overlay={*/}
                {/*            <Tooltip id="button-tooltip">*/}
                {/*                <span style={{}}>*/}
                {/*                    <b>VM ID</b><br/>*/}
                {/*                    <span style={{color: Color.gray}}>{row?.vmId}</span>*/}
                {/*                </span>*/}
                {/*            </Tooltip>*/}
                {/*        }>*/}
                {/*            <Info aria-label={'disabled'} style={{cursor: "pointer", fontSize: 15, color: "#adadad" }} />*/}
                {/*        </OverlayTrigger>*/}
                {/*    </span>*/}
                {/*    </>*/}
                {/*</TableCell>*/}
                <TableCell align="left" style={{whiteSpace: "nowrap"}}>
                    {row?.vmName}{" "}
                    <OverlayTrigger placement="bottom" overlay={
                        <Tooltip id="button-tooltip">
                            <span style={{}}>
                                <b>VM ID: </b><span style={{color: Color.gray}}>{row?.vmId}</span>
                            </span>
                        </Tooltip>
                    }>
                        <Info aria-label={'disabled'} style={{cursor: "pointer", fontSize: 15, color: "#adadad" }} />
                    </OverlayTrigger>

                </TableCell>
                <TableCell align="left">
                    {/* {Format.formatCurrencySymbol(currency)} */}
                    {/* {Format.formatNumber(Number(row?.totalAvgDiskCostPerDay) * DAYS_PERIOD)} */}
                    {/* {
                                type=='stopped'?
                                    Format.formatNumber(Number(row?.totalAvgDiskCostPerDay) * DAYS_PERIOD):
                                    Format.formatNumber(Number(
                                        (parseFloat(row.avgCost) +
                                            row?.disks?.map(r=>parseFloat(r.avgCost))?.reduce((a,b)=>a+b, 0)) * DAYS_PERIOD).toFixed(2))
                            } / month */}

                    <div>

                        {Format.formatCurrencySymbol(currency)}
                        {Format.formatNumber(Number(row?.totalAvgDiskCostPerDay)*30)+ " /mon"}
                        <br />
                    <span style={{fontSize: 11, color: "#7f7f7f", whiteSpace: "nowrap"}}>
                        {/*{Format.formatCurrencySymbol(currency)}*/}
                        {/*{Format.formatNumber(Number(row?.totalAvgDiskCostPerDay)*365)+ " /yr"}*/}
                        {Format.formatCurrencySymbol(currency)}{Format.formatNumber(Number(row?.totalDiskCost||0))} / {row?.numberOfDaysStopped + ((parseInt(row?.numberOfDaysStopped) > 1? " days":" day" ))} <br/>
                        {Format.formatCurrencySymbol(currency)}{Format.formatNumber(row?.totalAvgDiskCostPerDay)} /day
                    </span>
                        {/*<span style={{paddingLeft: 2}}>*/}
                        {/*        <OverlayTrigger placement="bottom" overlay={*/}
                        {/*            <Tooltip id="button-tooltip">*/}
                        {/*                {Format.formatCurrencySymbol(currency)}{Format.formatNumber(Number(row?.totalDiskCost||0))} / {row?.numberOfDaysStopped + ((parseInt(row?.numberOfDaysStopped) > 1? " days":" day" ))}*/}
                        {/*            </Tooltip>*/}
                        {/*        }>*/}
                        {/*            <Info aria-label={'disabled'} style={{cursor: "pointer", fontSize: 15, color: "#adadad" }} />*/}
                        {/*        </OverlayTrigger>*/}
                        {/*</span>*/}
                    </div>



                </TableCell>
                <TableCell align="left">
                    <LinkText onClick={()=>onDiskOpen({numberOfDaysStopped: row?.numberOfDaysStopped,disks: row?.disks})} role={'Owner'}>
                        {
                            (row?.disks?.length?.toString()) +
                            (row?.disks?.length > 1?" disks": " disk")
                            || "-"
                        }
                    </LinkText>
                </TableCell>
                <TableCell align="left" style={{whiteSpace: "nowrap"}}>
                    {row?.totalDiskSizeGB} GB
                </TableCell>
                <TableCell align="left" style={{whiteSpace: 'nowrap'}}>{row?.numberOfDaysStopped} {(parseInt(row?.numberOfDaysStopped) > 1? " days":" day" )}</TableCell>
                <TableCell align="left" style={{whiteSpace: 'nowrap'}}>{row?.location}</TableCell>
                <TableCell align="left" style={{whiteSpace: 'nowrap'}}>
                    {/*<OverlayTrigger placement="bottom" overlay={*/}
                    {/*    <Tooltip id="button-tooltip">*/}
                    {/*        <span style={{}}>*/}
                    {/*            */}
                    {/*        </span>*/}
                    {/*    </Tooltip>*/}
                    {/*}>*/}
                    {/*    <span>*/}
                    {/*        {subscriptionsList?.[row?.id?.split('/')?.[2]]?.subName}*/}
                    {/*    </span>*/}
                    {/*</OverlayTrigger>*/}
                    {subscriptionsList?.[row?.id?.split('/')?.[2]]?.subName}<br/>
                    <span style={{fontSize: 11, color: "#7f7f7f"}}>
                        {row?.id?.split('/')?.[2]}
                    </span>

                </TableCell>
                {/*<TableCell align="left">*/}
                {/*    {Format.formatCurrencySymbol(currency)}{Format.formatNumber(row?.totalAvgDiskCostPerDay)} /day <br/>*/}
                {/*    {Format.formatCurrencySymbol(currency)}{Format.formatNumber(parseFloat(row?.totalAvgDiskCostPerDay) * 30)} /mon <br/>*/}
                {/*    {Format.formatCurrencySymbol(currency)}{Format.formatNumber(row?.totalDiskCost)} / {row?.numberOfDaysStopped} {(parseInt(row?.numberOfDaysStopped) > 1? " days":" day" )}*/}
                {/*</TableCell>*/}
                <TableCell>
                <Button
                    onClick={()=>0}
                    variant="contained" style={{ fontSize: 11, borderRadius: 15, backgroundColor: "#3f50b5", color: "#FFF", textTransform: "initial", whiteSpace: "nowrap" }}>
                    Fix in Azure
                </Button>
                </TableCell>
                {/*<TableCell>*/}
                {/*    <span style={{whiteSpace: 'nowrap'}}>*/}
                {/*        {Array.from(new Set(row.disks.map(d=>d.properties.osType)))?.[0]}*/}
                {/*    </span></TableCell>*/}

                {/*{view == "all" ? <TableCell>{row?.subscriptionName}</TableCell> : null}*/}

                {/*        {type == 'stopped' && <TableCell align="left">{*/}
                {/*            (row?.numberOfDaysStopped?.toString()) +*/}
                {/*            (parseInt(row?.numberOfDaysStopped) > 1? " days":" day" )*/}
                {/*            || "-"*/}
                {/*        }</TableCell>}*/}
                {/*        <TableCell align="left">*/}
                {/*            {*/}
                {/*            Format.formatCurrencySymbol(currency) +*/}
                {/*                Format.formatNumber(Number(row?.totalAvgDiskCostPerDay||0))*/}
                {/*            } / day*/}
                {/*        </TableCell>*/}
                {/*        {type == 'idle' &&*/}
                {/*            <TableCell align="left">*/}
                {/*                {*/}
                {/*                    Format.formatCurrencySymbol(currency) +*/}
                {/*                    Format.formatNumber(*/}
                {/*                        parseFloat(row?.totalAvgCostPerDay||0)*DAYS_PERIOD*/}
                {/*                    )*/}
                {/*                }*/}
                {/*            </TableCell>*/}
                {/*        }*/}


            </TableRow>
            {/*<ExpandLavelData numberOfDaysStopped={row?.numberOfDaysStopped} tenantName={tenantName} data={row} open={open} select={list} currency={currency} />*/}
        </React.Fragment>
    );
}

export function ExpandLavelData({ numberOfDaysStopped, tenantName, data, open, select, set, currency }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2.5}>
                            <Htmltable numberOfDaysStopped={numberOfDaysStopped} tenantName={tenantName} data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ numberOfDaysStopped, tenantName, data, currency }) {
    function generateURL(data){
        if(!tenantName && !data) return;
        const BASE_PATH = "https://portal.azure.com/#@";
            const TENANT_NAME_DOMAIN = tenantName + '.com';
            const RESOURCE = "/resource";
            const VM_PATH = data;

            const FINAL_URL = BASE_PATH + TENANT_NAME_DOMAIN + RESOURCE + VM_PATH;
            window.open(FINAL_URL, "_blank");

    }
    return (
        <>
            <div className="overflow-auto">

                {"disks" in data && data?.disks?.length ? (
                    <>

                        <table id="customers">
                            <tr>
                                <th>Disk name</th>
                                <th style={{ whiteSpace: "nowrap" }}><span>Avg cost</span></th>
                                <th>Total cost</th>
                                <th>
                                    {" "}
                                    Action

                                </th>
                            </tr>

                            {data &&
                                data?.disks
                                    ?.sort((a, b) => {
                                        return parseFloat(b?.totalCost || 0) - parseFloat(a?.totalCost || 0);
                                    })
                                    .map((m) => (
                                        <tr>
                                            <td><span style={{ whiteSpace: "nowrap" }}>{m?.name} </span></td>

                                            <td style={{whiteSpace: "nowrap" }}>
                                                   <span style={{ whiteSpace: "nowrap" }}>
                                                    {Format.formatCurrencySymbol(currency)}{Format.formatNumber(parseFloat(m?.avgCostPerDay||0)) + " /day"}
                                                  </span>
                                            </td>

                                            <td style={{whiteSpace: "nowrap" }}>
                                                   <span style={{ whiteSpace: "nowrap" }}>
                                                    {Format.formatCurrencySymbol(currency)}{Format.formatNumber(parseFloat(m?.totalCost||0))} / {numberOfDaysStopped} {numberOfDaysStopped>1?" days":" day"}
                                                  </span>
                                            </td>
                                            <td align="left">
                                                <Button
                                                 onClick={()=>generateURL(m?.id)}
                                                 variant="contained" style={{ fontSize: 11, borderRadius: 15, backgroundColor: "#3f50b5", color: "#FFF", textTransform: "initial", whiteSpace: "nowrap" }}>
                                                    Fix in Azure
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                        </table>
                    </>
                ) : null}
            </div>
        </>
    );
}


function DiskModal({open, onClose, data: data2, currency}){
    const {classes} = useStyles();
    const [data, setData] = useState(null);

    // useEffect(()=>{
    //     let myd = [];
    //     if(data2){
    //         myd = data2?.map(d=>({id: d?.volumeId, size: d?.size + " " +d?.unit,
    //             days: parseInt(parseFloat(d.totalCost) / parseFloat(d.avgCostPerDay)), cost: Format.formatNumber(d.avgCostPerDay),LineItemDescription:d?.LineItemDescription}))
    //     }
    //     setData(myd);
    // }, [data2]);

    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                className={classes.modal}
                closeAfterTransition
                // BackdropComponent={Backdrop}
                // BackdropProps={{
                //     timeout: 500,
                // }}
                style={{display: "flex", alignItems: 'center', justifyContent: "center"}}
            >
                <Fade in={open}>
                    <div
                        className={classes.paper3}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Paper elevation={3}>
                            <TableContainer className={classes.container}>
                                <Table
                                    className={classes.table}
                                    stickyHeader
                                    aria-label="sticky table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell
                                                align="left"
                                                style={{ fontSize: 16, fontWeight: "bold" }}
                                            >
                                                Disk name
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="center"
                                                style={{ fontSize: 16, fontWeight: "bold" }}
                                            >
                                                Sku
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="center"
                                                style={{ fontSize: 16, fontWeight: "bold" }}
                                            >
                                                Size
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="left"
                                                style={{ fontSize: 16, fontWeight: "bold" }}
                                            >
                                                # Days stopped
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="left"
                                                style={{ fontSize: 16, fontWeight: "bold" }}
                                            >
                                                Cost
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {(open && open?.disks.length)?open?.disks?.map(r=>(
                                            <StyledTableRow>
                                                <StyledTableCell style={{ }} align="left">
                                                    {r?.name}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ }} align="left">
                                                    {r?.sku?.name}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ }} align="left">
                                                    {r?.properties?.diskSizeGB} GB
                                                </StyledTableCell>
                                                <StyledTableCell style={{ }} align="left">
                                                    {open?.numberOfDaysStopped}{open?.numberOfDaysStopped > 1 ? " days" : " day"}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ }} align="left">
                                                    {Format.formatCurrencySymbol(currency)}{Format.formatNumber(r?.avgCostPerDay)} /day <br/>
                                                    {Format.formatCurrencySymbol(currency)}{Format.formatNumber(parseFloat(r?.avgCostPerDay) * 30)} /mon <br/>
                                                    {Format.formatCurrencySymbol(currency)}{Format.formatNumber(r?.totalCost)} / {open?.numberOfDaysStopped} {(parseInt(open?.numberOfDaysStopped) > 1? " days":" day" )}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )):null}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}

const StyledTableCell = withStyles(TableCell,(theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
        padding: theme.spacing(1.2),
    },
    body: {
        fontSize: 14,
    },
}));

const StyledTableRow = withStyles(TableRow,(theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}));
