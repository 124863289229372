import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getAWSOptimizerSuccess, getAWSOptimizerFailure } from "../actions/actions";
const { REACT_APP_AWS_COMPUTE_OPTIMIZER } = process.env;

function* getAwscomputeOptimizerData({ payload }) {
    try {
        const res = yield call(axios.post, REACT_APP_AWS_COMPUTE_OPTIMIZER, {
            customerId: payload.customerId,
            tenantId: payload.tenantId,
            subscriptionId: payload.subscriptionId,
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAWSOptimizerFailure(statusCode));
            } else {
                yield put(getAWSOptimizerSuccess(res.data));
            }
        } else {
            yield put(getAWSOptimizerFailure(res));
        }
    } catch (error) {
        yield put(getAWSOptimizerFailure(error));
    }
}

export default function* awsComputeOptimizerSaga() {
    yield takeLatest(types.AWS_COMPUTE_OPTIMIZER_REQUEST, getAwscomputeOptimizerData);
}
