import * as types from "../actions/types";

const userState = {
    StorageData: null,
    StorageAccountData: null,
    StorageProjectAccountData: null,
    error: null,
    loading: false,
    StorageAccountLoading: false,
    StorageProjectAccountLoading: false,
};

function gcpStorageReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.GCP_STORAGE_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.GCP_STORAGE_SUCCESS: {
            return {
                ...state,
                StorageData: payload.data,
                loading: false,
            };
        }
        case types.GCP_STORAGE_FAILURE: {
            return {
                ...state,
                loading: false,
                StorageData: null,
                error: payload,
            };
        }

        case types.GCP_STORAGE_ACCOUNT_REQUEST: {
            return {
                ...state,
                StorageAccountLoading: true,
            };
        }
        case types.GCP_STORAGE_ACCOUNT_SUCCESS: {
            return {
                ...state,
                StorageAccountData: payload.data,
                StorageAccountLoading: false,
            };
        }
        case types.GCP_STORAGE_ACCOUNT_FAILURE: {
            return {
                ...state,
                StorageAccountLoading: false,
                StorageAccountData: null,
                error: payload,
            };
        }

        case types.GCP_STORAGE_PROJECT_ACCOUNT_REQUEST: {
            return {
                ...state,
                StorageProjectAccountLoading: true,
            };
        }
        case types.GCP_STORAGE_PROJECT_ACCOUNT_SUCCESS: {
            return {
                ...state,
                StorageProjectAccountData: payload.data,
                StorageProjectAccountLoading: false,
            };
        }
        case types.GCP_STORAGE_PROJECT_ACCOUNT_FAILURE: {
            return {
                ...state,
                StorageProjectAccountLoading: false,
                StorageProjectAccountData: null,
                error: payload,
            };
        }

        default:
            return state;
    }
}
export default gcpStorageReducer;
