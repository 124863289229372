import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getGCPStorageSuccess, getGCPStorageFailure, getGCPStorageAccountFailure, getGCPStorageAccountSuccess, getGCPStorageProjectAccountSuccess, getGCPStorageProjectAccountFailure } from "../actions/actions";
const { REACT_APP_GCP_STORAGE, REACT_APP_GCP_STORAGE_ACCOUNT, REACT_APP_GCP_STORAGE_PROJECT_ACCOUNT } = process.env;

function* getStorageData({ payload }) {
    try {
        const res = yield call(axios.post, REACT_APP_GCP_STORAGE, {
            customerId: payload.customerId,
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getGCPStorageFailure(statusCode));
            } else {
                yield put(getGCPStorageSuccess(res.data));
            }
        } else {
            yield put(getGCPStorageFailure(res));
        }
    } catch (error) {
        yield put(getGCPStorageFailure(error));
    }
}

function* getStorageAccountData({ payload }) {
    try {
        const res = yield call(axios.post, REACT_APP_GCP_STORAGE_ACCOUNT, {
            customerId: payload.customerId,
            billingAccountId: payload.billingAccountId,
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getGCPStorageAccountFailure(statusCode));
            } else {
                yield put(getGCPStorageAccountSuccess(res.data));
            }
        } else {
            yield put(getGCPStorageAccountFailure(res));
        }
    } catch (error) {
        yield put(getGCPStorageAccountFailure(error));
    }
}

function* getStorageProjectAccountData({ payload }) {
    try {
        const res = yield call(axios.post, REACT_APP_GCP_STORAGE_PROJECT_ACCOUNT, {
            customerId: payload.customerId,
            projectId: payload.projectId,
            billingAccountId:payload.billingAccountId,
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getGCPStorageProjectAccountFailure(statusCode));
            } else {
                yield put(getGCPStorageProjectAccountSuccess(res.data));
            }
        } else {
            yield put(getGCPStorageProjectAccountFailure(res));
        }
    } catch (error) {
        yield put(getGCPStorageProjectAccountFailure(error));
    }
}

export default function* gcpStorageSaga() {
    yield takeLatest(types.GCP_STORAGE_REQUEST, getStorageData);
    yield takeLatest(types.GCP_STORAGE_ACCOUNT_REQUEST, getStorageAccountData);
    yield takeLatest(types.GCP_STORAGE_PROJECT_ACCOUNT_REQUEST, getStorageProjectAccountData);
}
