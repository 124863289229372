import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "MakeWithStyles";
import Paper from "@mui/material/Paper";
import Azure_logo from "../../images/Azure_logo.svg";
import AWS_LOGO from "../../images/aws_dark.svg";
import GCP_ICON from "../../images/GCP_logo.svg";
import { useSelector, useDispatch } from "react-redux";
import { getBudgetsRequest } from "../../redux/actions/actions";
import NumberFormat from "../../components/NumberFormat";
import Format from "../../components/NumberFormat";
import LinearProgress from "@mui/material/LinearProgress";
import ProgressBar from "react-bootstrap/ProgressBar";
import Table from "@mui/material/Table";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
const StyledTableCell = withStyles(TableCell,(theme) => ({
  head: {
    backgroundColor: "#cfdac8",
    color: "black",
    fontWeight: "bold",
    padding: theme.spacing(1.5),
  },
  body: {
    fontSize: 14,
    padding: theme.spacing(0.8),
  },
}));

const useStyles = makeStyles()((theme) => ({
  button: {
    margin: theme.spacing(0.5),
  },
  table: {
    maxHeight: 100,
  },
}));

const StyledTableRow = withStyles(TableRow,(theme) => ({
  logoSize: { height: 40, width: 40 },
}));

let total = 0;

export default function BudgetAlert({ data = [] }) {
  const {classes} = useStyles();
  const [alertData, setAlertData] = useState([]);
  const [alertLevelList, setAlertLevelList] = useState([]);
  useEffect(() => {
    total = 0;
    //  if (!data) return;
    //   const result = data
    //     .map((cloud) => {
    //       return cloud.budgets.map((b) => (
    //         <AlertRow
    //           cloud={cloud.cloudProvider}
    //           budgetName={b.budget_name}
    //           budgetAmount={b.budgetAmount}
    //           actualCost={b.actualCost}
    //           budgetPeriod={b.budgetPeriod}
    //           alertLevel={b.alertLevel}
    //         />
    //       ));
    //     })
    //     .filter((d) => (d != null ? d : null));
    //   setAlertLevelList(result);
    //   console.log("result", { data }, result);
  }, [data]);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        {total ? (
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Cloud provider</StyledTableCell>
              <StyledTableCell align="center">Budget Name</StyledTableCell>
              <StyledTableCell align="center">Alert Level</StyledTableCell>
            </TableRow>
          </TableHead>
        ) : null}

        <TableBody>
          {data.map((cloud) => {
            return cloud.budgets.map((b) => (
              <AlertRow
                cloud={cloud.cloudProvider}
                budgetName={b.budget_name}
                budgetAmount={b.budgetAmount}
                actualCost={b.actualCost}
                budgetPeriod={b.budgetPeriod}
                alertLevel={b.alertLevel}
              />
            ));
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function AlertRow({
  cloud,
  budgetAmount,
  budgetPeriod,
  alertLevel,
  budgetName,
  actualCost,
}) {
  return (alertLevel * budgetAmount) / 100 <= actualCost ? (
    <StyledTableRow hover key={cloud}>
      <StyledTableCell align="center" component="th" scope="row">
        {
          {
            azure: (
              <img
                style={{
                  width: 24,
                  height: 24,
                  marginRight: 4,
                  marginTops: 4,
                }}
                src={Azure_logo}
              />
            ),
            aws: (
              <img
                style={{
                  width: 24,
                  height: 24,
                  marginRight: 4,
                  marginTops: 4,
                }}
                src={AWS_LOGO}
              />
            ),
            gcp: (
              <img
                style={{
                  width: 24,
                  height: 24,
                  marginRight: 4,
                  marginTops: 4,
                }}
                src={GCP_ICON}
              />
            ),
          }[cloud]
        }
      </StyledTableCell>
      <StyledTableCell align="center">{budgetName}</StyledTableCell>
      <StyledTableCell align="center">
        <ProgressBar>
          {(alertLevel * budgetAmount) / 100 <= actualCost ? (
            <ProgressBar
              striped
              variant="success"
              key={2}
              now={
                (actualCost / ((alertLevel * budgetAmount) / 100)) * 100 -
                ((actualCost / ((alertLevel * budgetAmount) / 100)) * 100 - 100)
              }
            />
          ) : (
            <ProgressBar
              striped
              variant="success"
              key={1}
              now={(actualCost / ((alertLevel * budgetAmount) / 100)) * 100}
            />
          )}

          <ProgressBar
            striped
            variant="danger"
            key={1}
            now={(actualCost / ((alertLevel * budgetAmount) / 100)) * 100 - 100}
          />
        </ProgressBar>
        <div
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            display: "flex",
          }}
        >
          <span style={{ color: "green" }}>
            {(actualCost / ((alertLevel * budgetAmount) / 100)) * 100 -
              ((actualCost / ((alertLevel * budgetAmount) / 100)) * 100 - 100)}
            %
          </span>
          <span style={{ color: "red" }}>
            {(
              (actualCost / ((alertLevel * budgetAmount) / 100)) * 100 -
              100
            ).toFixed(2)}
            %
          </span>
          <span hidden={true}>{total++}</span>
        </div>
      </StyledTableCell>
    </StyledTableRow>
  ) : null;
}
