import React, {useEffect, useState} from "react";
import ContainerCardWithDateRange from "../../components/ContainerCardWithDateRange";
import Calendar_Spend_Icon from "../../images/newCloudTrakrIcons/AzureBilling.png";
import {Box, Select, MenuItem, Paper, CircularProgress} from "@mui/material";
import { makeStyles } from "MakeWithStyles";

import Format from "../NumberFormat";
import NO_DATA_AVAILABLE from "../NO_DATA_AVAILABLE";
import {useDispatch, useSelector} from "react-redux";
import {AWS_COST_CATEGORY_REQUEST, AWS_TAGS_REQUEST} from "../../redux/actions/types";
import cost_categories_icon from 'images/spend_cost_categories.png'
import cost_categories_icon_2 from 'images/cost_category_icon_2.png'
import tag_blank_icon from 'images/tag_blank.png'
import business_unit_icon from 'images/business_unit_1.svg'

import tag_custom_name_icon from 'images/tag_custom_name.png'
import tag_custom_env_icon from 'images/tag_custom_env.png'
import tag_custom_app_icon from 'images/tag_custom_app.png'
import tag_custom_costcenter_icon from 'images/tag_custom_costcenter.png'

const DEFAULT_PERIOD = 'Month to Date';

export default function AWSTagsCostCategories({customerId, somerefs = [], excludeOverlayKeys = [], currency}){
    const {classes} = useStyles();

    const [defaultLoading, setDefaultLoading] = useState(true);

    const [dropdownTags, setDropdownTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState('');
    const [tagsList, setTagsList] = useState([]);
    const [tagsFinalData, setTagsFinalData] = useState([]);

    const [dropdownCat, setDropdownCat] = useState([]);
    const [selectedCat, setSelectedCat] = useState('');
    const [catList, setCatList] = useState([]);
    const [catFinalData, setCatFinalData] = useState([]);

    const [tagTotalCost, setTagTotalCost] = useState(null);
    const [catTotalCost, setCatTotalCost] = useState(null);

    const [period, setPeriod] = useState(DEFAULT_PERIOD);

    const dispatch = useDispatch();

    const tagsAndCost = useSelector((state)=>({
        tagMTD: state.awsTagsAndCostCategoryReducer.awsTags.MTD,
        tagLM: state.awsTagsAndCostCategoryReducer.awsTags.LastMonthSpend,
        tagTQ: state.awsTagsAndCostCategoryReducer.awsTags.ThisQuarter,
        tagLQ: state.awsTagsAndCostCategoryReducer.awsTags.LastQuarter,
        tagYTD: state.awsTagsAndCostCategoryReducer.awsTags.YTD,

        catMTD: state.awsTagsAndCostCategoryReducer.awsCostCategory.MTD,
        catLM: state.awsTagsAndCostCategoryReducer.awsCostCategory.LastMonthSpend,
        catTQ: state.awsTagsAndCostCategoryReducer.awsCostCategory.ThisQuarter,
        catLQ: state.awsTagsAndCostCategoryReducer.awsCostCategory.LastQuarter,
        catYTD: state.awsTagsAndCostCategoryReducer.awsCostCategory.YTD
    }));

    const { tagLoading, catLoading } = useSelector((state)=>({
        tagLoading: state.awsTagsAndCostCategoryReducer.awsTagsLoading,
        catLoading: state.awsTagsAndCostCategoryReducer.awsCostCategoryLoading
    }));

    const mappingScheme = {
        'Month to Date':  ['MTD', {timeFrameType: 'MonthToDate', granularity: 'MONTHLY'}],
        'Last Month':     ['LM',  {timeFrameType: 'TheLastMonth',granularity: 'MONTHLY'}],
        // 'This Quarter':   ['TQ',  {timeFrameType: 'ThisQuarter', granularity: 'MONTHLY'}],
        'Last Quarter':   ['LQ',  {timeFrameType: 'LastQuarter', granularity: 'MONTHLY'}],
        'This Year':      ['YTD',  {timeFrameType: 'ThisYear', granularity: 'MONTHLY'}],
    }

    useEffect(()=>{
        loadData(mappingScheme['Month to Date'][1], 'tags', 'MTD');
        loadData(mappingScheme['Month to Date'][1], 'category', 'MTD');
        setDefaultLoading(false);

        return ()=>{
            setDefaultLoading(true);
        }
    }, []);

    function makeMergedData(data){
        if(data && data?.columns?.length && data?.rows?.length){
            const columns = data.columns.map(c=>c.name);
            const mergedData = data.rows.map((row) => {
                const mergedRow = {};
                columns.forEach((column, index) => {
                    mergedRow[column] = row[index];
                });
                return mergedRow;
            });
            return mergedData;
        }
        return null;
    }

    useEffect(()=>{
        if(!(tagLoading || catLoading)){
            let tag = tagsAndCost['tag' + mappingScheme[period][0]];
            let category = tagsAndCost['cat' + mappingScheme[period][0]];

            if(tag?.tagKeys && tag?.tagKeys?.constructor.name === 'Array'){
                setDropdownTags(tag?.tagKeys || []);
                setSelectedTag(tag?.tagKeys?.[0]);
            }
            if(category?.costCategories && category?.costCategories?.constructor.name === 'Array'){
                setDropdownCat(category?.costCategories || []);
                setSelectedCat(category?.costCategories?.[0]);
            }

            setTagsFinalData(makeMergedData(tag));
            setCatFinalData(makeMergedData(category));
        }
    }, [tagLoading, catLoading, period]);

    useEffect(()=>{
        if(tagsFinalData || catFinalData){
            const tags = Array.from(new Set(tagsFinalData?.map(t=>t.tagKey) || [])) || [];
            setDropdownTags(tags);
            setSelectedTag(tags[0]);
        }
    }, [tagsFinalData, catFinalData])

    useEffect(()=> {
        setTagsList([]);
        setTagTotalCost(null)
        if(selectedTag && tagsFinalData?.length && !tagLoading) {
            const data = tagsFinalData.filter(f=>f.tagKey == selectedTag);
            const commonTags = {};
            const finalData = [];

            let total = 0;

            data.forEach(t=>{
                if(t.tagValue in commonTags){
                    if(t.tagKey === commonTags[t.tagValue].tagKey){
                        commonTags[t.tagValue].cost += (parseFloat(t.cost) || 0);
                    }
                } else {
                    commonTags[t.tagValue] = {...t, cost: parseFloat(t.cost) || 0}
                }
            });

            for(let o in commonTags){
                total += parseFloat(commonTags[o]?.cost || 0);
                finalData.push(commonTags[o]);
            }
            setTagsList(finalData);
            setTagTotalCost(total);
        }
    }, [selectedTag, tagsFinalData]);

    useEffect(()=> {
        setCatList([]);

        if(selectedCat && catFinalData?.length && !catLoading) {
            const data = catFinalData.filter(f=>f.costCategoryKey == selectedCat);
            const commonCat = {};
            const finalData = [];
            let total = 0;

            data.forEach(t=>{
                if(t.costCategoryValue in commonCat){
                    if(t.costCategoryKey === commonCat[t.costCategoryValue].costCategoryKey){
                        commonCat[t.costCategoryValue].cost += (parseFloat(t.cost) || 0);
                    }
                } else {
                    commonCat[t.costCategoryValue] = {...t, cost: parseFloat(t.cost) || 0}
                }
            });

            for(let o in commonCat){
                total += parseFloat(commonCat[o]?.cost || 0);
                finalData.push(commonCat[o]);
            }
            setCatList(finalData);
            setCatTotalCost(total);
        }
    }, [selectedCat, catFinalData]);

    function onPeriodChange(period){
        setPeriod(period);
        setCatTotalCost(null);
        setTagTotalCost(null);

        let tag = mappingScheme[period];
        let category = mappingScheme[period];
        if(!tagsAndCost['tag' + tag[0]]){
            loadData(tag[1], 'tags', tag[0]);
        }
        if(!tagsAndCost['cat' + category[0]]){
            loadData(category[1], 'category', category[0]);
        }
    }

    function loadData(params, api, period){
        if(api)
            dispatch({
                type: api === 'tags'? AWS_TAGS_REQUEST: AWS_COST_CATEGORY_REQUEST,
                payload: {...params, customerId, period}
            })
    }

    return (
        <div>
                <ContainerCardWithDateRange someref={somerefs?.[0]} showDateRange={true}
                                            title={'Spend by Tags & Cost Categories'}
                                            titleIcon={Calendar_Spend_Icon}
                                            saveAsCSV={true}
                                            DateRangeValues={[
                                                'Month to Date',
                                                'Last Month',
                                                // 'This Quarter',
                                                'Last Quarter',
                                                'This Year',
                                            ]}
                                            onPeriodChange={onPeriodChange}
                                            defaultPeriod={'Month to Date'}
                                            DateRangeProps={{padding: 5}}
                >
                    {()=>(
                        <Box display={'flex'} padding={2} justifyContent={'space-evenly'}>
                            <Paper flex={1} style={{width: 500,  borderRadius: 10}}>
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{borderTopLeftRadius:10,borderTopRightRadius:10, color: 'white', backgroundColor: 'rgb(64 81 181)', padding: 10}}>
                                    <div style={{display: 'flex', alignItems: 'center' }}>
                                        <img alt={'tags'} src={tag_blank_icon} height={25}/>
                                        <h6 style={{margin: '0px', paddingLeft: 5}}>Spend by Tags</h6>
                                    </div>
                                    <Select size={"small"}
                                            labelId="greyish-dropdown-label"
                                            id="greyish-dropdown"
                                            value={selectedTag}
                                            onChange={e => setSelectedTag(e.target.value)}
                                            displayEmpty
                                            className={classes.selectEmpty}
                                            hidden={!(dropdownTags?.length) || tagLoading}
                                    >
                                        {
                                            dropdownTags.map(d => <MenuItem className={classes.menuItem} value={d}>{d}</MenuItem>)
                                        }
                                    </Select>
                                </Box>
                                <Box display={tagTotalCost !== null? 'flex': 'none'} justifyContent={'space-between'} style={{
                                    backgroundColor: '#dce0f3',
                                    paddingTop: 5,
                                    paddingRight: 10,
                                    paddingBottom: 5,
                                    paddingLeft: 10,
                                    color: '#3f52b5',
                                    fontWeight: 'bold'
                                }}>
                                    <span>
                                        Total
                                    </span>
                                    <span>
                                        {Format.formatCurrencySymbol(currency)}
                                        {
                                            tagTotalCost !== null?
                                            Format.formatNumber(tagTotalCost,false)    :
                                                "--"
                                        }
                                    </span>
                                </Box>
                                <Box display={'flex'} flexDirection={'column'} height={300} style={{overflowY: 'scroll', padding: 10}}>
                                    {
                                        defaultLoading || tagLoading?
                                            <div
                                                style={{
                                                    flex:1,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                            <CircularProgress thickness={2} size={80} />
                                            </div>:
                                        tagsList?.length?
                                            tagsList
                                                ?.sort((a,b)=>parseFloat(b.cost) - parseFloat(a.cost))
                                                ?.map(tag=>(
                                            <Box display={'flex'} justifyContent={'space-between'}>
                                                <Box display={'flex'} alignItems={'center'}>
                                                    {
                                                        <img src={
                                                            {
                                                                "Name": tag_custom_name_icon,
                                                                "name": tag_custom_name_icon,
                                                                "Application": tag_custom_app_icon,
                                                                "CostCenter": tag_custom_costcenter_icon,
                                                                "Environment": tag_custom_env_icon
                                                            }[selectedTag] || tag_blank_icon
                                                        } height={20} style={{paddingRight: 5}}/>
                                                    }
                                                {
                                                    ((tag.tagValue === "Untagged-" + selectedTag) || tag.tagValue.startsWith('Untagged'))?
                                                        <span className={'greyed-field'}>Untagged</span>:
                                                        <span>{tag.tagValue}</span>
                                                }
                                                </Box>
                                                <span>{Format.formatCurrencySymbol(tag.currency)}{Format.formatNumber(tag.cost, false)}</span>
                                            </Box>
                                        )):
                                            <NO_DATA_AVAILABLE />
                                    }
                                </Box>
                            </Paper>
                            <Paper flex={1} style={{width: 500,  borderRadius: 10}}>
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{borderTopLeftRadius:10,borderTopRightRadius:10, color: 'white', backgroundColor: 'rgb(64 81 181)', padding: 10}}>
                                    <div style={{display: 'flex', alignItems: 'center' }}>
                                        <img alt={'cost category'} src={cost_categories_icon_2} height={25}/>
                                        <h6 style={{margin: '0px', paddingLeft: 5}}>Spend by Cost Categories</h6>
                                    </div>
                                    <Select size={"small"}
                                            labelId="greyish-dropdown-label"
                                            id="greyish-dropdown"
                                            value={selectedCat}
                                            onChange={e => setSelectedCat(e.target.value)}
                                            className={classes.selectEmpty}
                                            hidden={!(dropdownCat?.length) || catLoading}
                                    >
                                        {
                                            dropdownCat.map(d => <MenuItem className={classes.menuItem} value={d}>{d}</MenuItem>)
                                        }
                                    </Select>
                                </Box>
                                <Box display={catTotalCost !== null? 'flex': 'none'} justifyContent={'space-between'} style={{
                                    backgroundColor: '#dce0f3',
                                    paddingTop: 5,
                                    paddingRight: 10,
                                    paddingBottom: 5,
                                    paddingLeft: 10,
                                    color: '#3f52b5',
                                    fontWeight: 'bold'
                                }}>
                                    <span>
                                        Total
                                    </span>
                                    <span>
                                        {Format.formatCurrencySymbol(currency)}
                                        {
                                            catTotalCost !== null?
                                                Format.formatNumber(catTotalCost,false)    :
                                                "--"
                                        }
                                    </span>
                                </Box>
                                <Box display={'flex'} flexDirection={'column'} height={300} style={{overflowY: 'scroll', padding: 10}}>
                                    {
                                        defaultLoading || catLoading?
                                            <div
                                                style={{
                                                    flex:1,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                            <CircularProgress thickness={2} size={80} />
                                            </div>:
                                            catList?.length?
                                            catList
                                                ?.sort((a,b)=>parseFloat(b.cost) - parseFloat(a.cost))
                                                ?.map(cat=>(
                                            <Box display={'flex'} justifyContent={'space-between'}>
                                                <Box display={'flex'} alignItems={'center'}>
                                                {
                                                    <img src={
                                                        {
                                                            "Business Unit": business_unit_icon
                                                        }[selectedCat] || tag_blank_icon
                                                    } height={15} style={{paddingRight: 5}}/>
                                                }
                                                {
                                                    ((cat.costCategoryValue === "Uncategorized" + selectedCat) || cat.costCategoryValue.startsWith('Uncategorized'))?
                                                        (<span className={'greyed-field'}>Uncategorized</span>):
                                                        (<span>{cat.costCategoryValue}</span>)
                                                }
                                                </Box>
                                                <span>{Format.formatCurrencySymbol(cat.currency)}{Format.formatNumber(cat.cost, false)}</span>
                                            </Box>
                                        )):
                                            <NO_DATA_AVAILABLE />
                                    }
                                </Box>
                            </Paper>
                        </Box>
                    )}
                </ContainerCardWithDateRange>
        </div>
    )
}

const useStyles = makeStyles()((theme) => ({
    selectEmpty: {
        height: 35,
        color: "white",
        fontSize: 14,
        '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255,255,255,0.85)',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255,255,255,0.85)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255,255,255,0.85)',
        },
        '.MuiSvgIcon-root ': {
            fill: "white !important",
        }
    },
    menuItem: {
        // backgroundColor: '#e0e0e0', // Greyish background color for options
        // color: "white",
        fontSize: 14
    },
}));
