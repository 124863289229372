import * as types from "../actions/types";

const userState = {
    azureStoppedVmRecommendationData: null,
    error: null,
    loading: false,
    azureStoppedVmRecommendationSubData:null,
    azureStoppedVmRecommendationTenantData: null
};

function azureStoppedVmRecommendationReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.AZURE_STOPPED_VM_RECOMMENDATION_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AZURE_STOPPED_VM_RECOMMENDATION_SUCCESS: {
            return {
                ...state,
                azureStoppedVmRecommendationData: payload.data,
                loading: false,
            };
        }
        case types.AZURE_STOPPED_VM_RECOMMENDATION_FAIL: {
            return {
                ...state,
                loading: false,
                azureStoppedVmRecommendationData: null,
                error: payload,
            };
        }

        case types.AZURE_STOPPED_VM_RECOMMENDATION_TENANT_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.AZURE_STOPPED_VM_RECOMMENDATION_TENANT_SUCCESS: {
            return {
                ...state,
                azureStoppedVmRecommendationTenantData: payload.data,
                loading: false,
            };
        }
        case types.AZURE_STOPPED_VM_RECOMMENDATION_TENANT_FAIL: {
            return {
                ...state,
                loading: false,
                azureStoppedVmRecommendationTenantData: null,
                error: payload,
            };
        }

      
       
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                azureStoppedVmRecommendationData: null,
                error: null,
                loading: false,
                azureStoppedVmRecommendationTenantData:null,
                azureStoppedVmRecommendationSubData:null

            };
        }

        default:
            return state;
    }
}
export default azureStoppedVmRecommendationReducer;
