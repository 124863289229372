import * as types from "../actions/types";

const userState = {
  spendByReservations: null,
  spendByAccountReservation: null,
  error: null,
  loading: false,
};

function awsReservationReducer(state = userState, { type, payload }) {
  switch (type) {
      case types.GET_AWS_RESERVATION_REQUEST: {
          return {
              ...state,
              loading: true,
          };
      }
      case types.GET_AWS_RESERVATION_SUCCESS: {
          return {
              ...state,
              spendByReservations: payload.data,
              loading: false,
          };
      }
      case types.AWS_RESERVATION_FAILURE: {
          return {
              ...state,
              loading: false,
              spendByReservations: null,
              error: payload,
          };
      }

      case types.GET_AWS_ACCOUNT_RESERVATION_REQUEST: {
          return {
              ...state,
              loading: true,
          };
      }
      case types.GET_AWS_ACCOUNT_RESERVATION_SUCCESS: {
          return {
              ...state,
              spendByAccountReservation: payload.data,
              loading: false,
          };
      }
      case types.AWS_ACCOUNT_RESERVATION_FAILURE: {
          return {
              ...state,
              loading: false,
              spendByAccountReservation: null,
              error: payload,
          };
      }
      case "SWITCH_TEAM_CLEAR": {
          return {
              ...state,
              spendByReservations: null,
              spendByAccountReservation: null,
              error: null,
              loading: false,
          };
      }

      default:
          return state;
  }
}
export default awsReservationReducer;
