import * as types from "../actions/types";
const initialState={
    uuid:null
};

function awsConnectionReducer(state = initialState, { type, payload }) {
    switch (type) {
        case types.AWS_CONNECTION_UUID: {
            return {
                ...state,
                uuid: payload,
            };
        } 
        default : 
        return state;
    }
}
export default awsConnectionReducer;