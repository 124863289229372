import React, { useEffect, useReducer } from "react";
import { makeStyles, withStyles } from "MakeWithStyles";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { GET_MESSAGES_REQUEST } from "../redux/actions/types";
import userDetailsReducer from "../redux/reducers/userDetailsReducer";
import { Card, CardHeader, CircularProgress } from "@mui/material";
import WebList from "../components/WebList";
import AWS_LOGO from "../images/AWS_LOGO";
import GCP_ICON from "../images/GCP_logo.svg";
import Azure_logo from "../images/Azure_logo.svg";
import moment from "moment";

const width = window.innerWidth;
const height = window.innerHeight;

const useStyles = makeStyles()((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

export default function MessagesBox() {
    const dispatch = useDispatch();
    const { customerId, list, loading, error } = useSelector((state) => ({
        customerId: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
        list: state.allHomeReducer.messages,
        loading: state.allHomeReducer.messagesLoading,
        error: state.allHomeReducer.messagesError,
    }));

    function getMessages() {
        dispatch({
            type: GET_MESSAGES_REQUEST,
            payload: customerId,
        });
    }

    useEffect(() => {
        if (customerId) {
            getMessages();
        }
    }, [customerId]);

    // console.log({list});
    return (
        <Card
            style={{
                display: "flex",
                flex: 1,
                // alignItems: 'center', justifyContent: 'center',
                width: 0.35 * width,
                height: 0.65 * height,
                padding: 20,
                overflowY: "scroll",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    border: "solid 0px black",
                    flexDirection: "column",
                }}
            >
                <div style={{ backgroundColor: "#ork3f51b5db" }}>
                    <h4 style={{ fontFamily: "SF_Pro_Text" }}>Messages</h4>
                </div>
                <div style={{ height: "1pt", backgroundColor: "#dcdcdc" }}></div>
                <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                    {/*{loading?<div style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>*/}
                    {/*    <span>Loading messages...</span>*/}
                    {/*</div>:*/}
                    <WebList
                        data={list}
                        EmptyComponent={() => (
                            <div
                                style={{
                                    display: "flex",
                                }}
                            >
                                <Typography>No new messages</Typography>
                            </div>
                        )}
                        keyExtractor={(item, index) => index.toString() + item.summary}
                        isLoading={loading}
                        renderItem={({ item, index }) => {
                            return <Message desc={item?.summary} description={item?.description} date={item?.documentDateCreated} cloudProvider={item?.cloudProvider} impact={item.impact?.toString().toLowerCase() || null} />;
                        }}
                        LoadingComponent={() => (
                            <div
                                style={{
                                    display: "flex",
                                    flex: 1,
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <CircularProgress />
                            </div>
                        )}
                    />
                    {/*//     (list && list?.constructor?.name === 'Array' && list.length)?*/}
                    {/*//     (list || []).map(m=>(*/}
                    {/*//        */}
                    {/*//     )):*/}
                    {/*//         <div style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>*/}
                    {/*//             <span>Loading messages...</span>*/}
                    {/*//         </div>*/}
                    {/*// }*/}
                    {/*<span>as</span>*/}
                </div>
            </div>
        </Card>
    );
}

function Message({ desc, description, date, cloudProvider, impact = null }) {
    var interval = moment(date, "YYYY-MM-DD").fromNow();

    return (
        <div
            style={{
                marginTop: 5,
                display: "flex",
                marginBottom: 5,
                borderRadius: 5,
                padding: 5,
            }}
        >
            <div
                style={{
                    border: "1px solid #e7e7e7",
                    height: 50,
                    width: 50,
                    borderRadius: 4,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "#fff",
                }}
            >
                {cloudProvider === "aws" ? <AWS_LOGO height={24} /> : cloudProvider === "gcp" ? <img height={24} src={GCP_ICON} /> : <img height={24} src={Azure_logo} />}
            </div>

            <div
                style={{
                    backgroundColor: "#f5f5f5",
                    marginLeft: 10,
                    borderRadius: 4,
                    display: "flex",
                    paddingRight: 0,
                    width: "100%",
                    justifyContent: "space-between",
                }}
            >
                <div style={{ padding: 10 }}>
                    <Typography style={{ fontWeight: "bold" }}>{desc}</Typography>

                    {typeof description == "object" ? <Typography style={{ color: "red" }}>{description?.errors?.[0].message}</Typography> : <Typography>{description}</Typography>}

                    <Typography style={{ fontSize: 12, marginTop: 4, color: "gray" }}>{interval}</Typography>
                </div>
                <div>
                    {impact === "high" && (
                        <div
                            style={{
                                backgroundColor: "red",
                                width: 5,
                                height: "100%",
                                borderTopRightRadius: 10,
                                borderBottomRightRadius: 10,
                                justifyContent: "end",
                            }}
                        ></div>
                    )}
                </div>
            </div>
        </div>
    );
}


export function showSnackBar(open, close, additionalConfig = {}) {
    const snackConfig = {
        variant: "error",
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
        },
        autoHideDuration: 8000,
        ...additionalConfig,
    };
    // setTimeout(close, 8000);
    return {
        error: (message) => open(message, snackConfig),
        info: (message) => {
            snackConfig.variant = "info";
            open(message, snackConfig);
        },
        success: (message) => {
            snackConfig.variant = "success";
            open(message, snackConfig);
        },
        warning: (message) => {
            snackConfig.variant = "warning";
            open(message, snackConfig);
        },
    };
}
