import React from "react";
import styles from './styles.css';
import colors from "../styles/color";

export default function LinkText({onClick, children,role}) {
    if(role == 'other'){
        return children;
    }
  return role == "Owner" || role == null ? (
      <div onClick={onClick} style={{ color: colors.blue, cursor: "pointer", textDecoration: "underline" }}>
          {children}
      </div>
  ) : (
      <div onClick={ null} style={{ color: colors.gray, cursor: "default", textDecoration: "none" }}>
          {children}
      </div>
  );
}
