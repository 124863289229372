import React from "react";

export const MainContainer = ({children}) => (
    <div style={{display: "flex", justifyContent: "space-evenly"}}>
        {children}
    </div>
)

export const RecHeader = ({children}) => (
    <div style={{padding: 14,  display: 'flex',  width: 'auto',  height: 55,  backgroundColor: '#cfdac8'}}>
        {children}
    </div>
)

export const HeaderText = ({children}) => (
    <h5 style={{fontFamily: "Poppins-SemiBold", marginLeft: 7}}>
        {children}
    </h5>
)

export const RecTotalText = ({children}) => (
    <h6 style={{fontFamily: "Poppins-SemiBold", marginLeft: 10}}>
        {children}
    </h6>
)

export const HeaderIcon = ({src}) => (
    <img src={src} style={{marginLeft: 5, width: 80, height: 80, marginTop: -25, marginInline: -15}} />
)

export const RecTotal = ({children, IR}) => (
    <div style={{
        marginLeft: IR?0:10,
        padding: 14,
        display: 'flex',
        justifyContent: IR?"center":null,
        alignItems: IR?"center":null
    }}>
        {children}
    </div>
)

export const NoRecommend = ({children}) => (
    <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: 195, padding: 15}}>
        {children}
    </div>
)
