import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import {
  allHomeSuccess,
  allHomeFailure,
  getSubscriptionsRequest,
  getSubscriptionsFailure,
  getSubscriptionsSuccess,
} from "../actions/actions";
const { REACT_APP_ALL_HOME, REACT_APP_LINKED_ACCOUNTS } = process.env;

function* getAllHomeData({ payload }) {
  try {
    const res = yield call(axios.get, REACT_APP_ALL_HOME, {
      params: { customerId: payload },
    });

    const {
      data: { statusCode },
    } = res;
    if ("statusCode" in res.data) {
      if (statusCode !== 200) {
        yield put(allHomeFailure(statusCode));
      } else {
        yield put(allHomeSuccess(res.data));
      }
    } else {
      yield put(allHomeFailure(res.data));
    }
  } catch (error) {
    yield put(allHomeFailure(error));
  }
}

export default function* allHomeSaga() {
  yield takeLatest(types.ALL_HOME_LOADING, getAllHomeData);
}

export function* getAllSubscriptions() {
  yield takeLatest(types.GET_SUBSCRIPTIONS_REQUEST, function* ({ payload }) {
    try {
      const res = yield call(axios.get, REACT_APP_LINKED_ACCOUNTS, {
        params: { customerId: payload },
      });

      const {
        data: { statusCode },
      } = res;
      if ("statusCode" in res.data) {
        if (statusCode !== 200) {
          yield put(getSubscriptionsFailure(res.data));
        } else {
          yield put(getSubscriptionsSuccess(res?.data?.response));
        }
      } else {
        yield put(getSubscriptionsFailure(res.data));
      }
    } catch (error) {
      yield put(getSubscriptionsFailure(error));
    }
  });
}
