import React, { useState, useEffect } from "react";
import "./style.css";
import Format from "components/NumberFormat";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import moment from "moment";
import {
    Backdrop,
    CircularProgress,
    Fade,
    Modal,
    Paper,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import RecommendationsTable from "./RecommendationTable";
import RecommendationsSummaryCards from "./RecommendationSummaryCards";
import aws_recommendation from "../../images/aws_recommendation.svg";
import Ec2Icon from "../../images/EC2.svg";

import AwsLogo from "../../images/aws_dark.svg";
import { makeStyles, withStyles } from "MakeWithStyles";

export default function AWSAccountRecommendations({ volumeLinkEnabled = false, title, data, customerId, tenantId, subscriptionId, lastUpdated, currency, cards, type, loading, collapseRef }) {
    const [list, setList] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [totalCost, setTotalCost] = useState(0.003);
    const [sum, setSum] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    function onVolumeClick(row){
        if(row){
            setModalData(row);
            setModalOpen(true);
        }
    }

    return (
        <div>
                <ContainerCardWithDateRange
                    someref={collapseRef}
                    title={title}
                    titleIcon={AwsLogo}
                    showDateRange={false}
                    collapsible={true}
                    HeaderTitleComponent={()=>(
                        <div>
                            <img src={Ec2Icon} height={25} style={{marginLeft: 8, marginRight: 8}} />
                            <span>
                            {title}
                        </span>
                        </div>
                    )}
                    CenterComponent={()=>(
                        <div>
                         {cards != null && cards ?<h6 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20}}>{cards?.[1]?.value} {cards?.[1]?.name} </h6>:null}
                      </div>
                        )}
                    ExtraHeaderComponent={({ period }) =>
                        data != null && data?.length ? (
                            <div style={{ marginTop: 4 }}>
                                <span style={{fontSize: 14}}>Last updated {moment(lastUpdated).fromNow(true)} ago</span>
                            </div>
                        ) : null
                    }
                >
                    {({ period }) => (
                         <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                          {!loading ?
                        <div style={{flex:1}}>
                           {data && Object.keys(data).length ? (
                             <>
                            {cards && <RecommendationsSummaryCards summary={cards}/>}
                            {data ? <RecommendationsTable volumeLinkEnabled={volumeLinkEnabled} onVolumeClick={onVolumeClick} type={type} subscriptionId={subscriptionId} list={data} currency={currency} customerId={customerId} /> :   <h6 style={{ textAlign: "center" }}>No data found</h6>}
                            </>
                            ) : (
                                <h6 style={{ textAlign: "center" }}>No data found</h6>
                            )}
                            </div>
                   :
                   <CircularProgress thickness={2} size={60} />
                            }
                   </div>
              )}
                </ContainerCardWithDateRange>
            <VolumeModal currency={currency} data={modalData} open={modalOpen} onClose={()=>setModalOpen(false)} />
        </div>
    );
}

const useStyles = makeStyles()((theme) => ({
    root1: {
        flexGrow: 1,
        marginBottom: 10,
    },
    paper1: {
        height: 250,
        width: 262,
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#eeeeee",
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper3: {
        //width: "99%",
        // height: 680,
        //  backgroundColor: "#eee",
        //  boxShadow: theme.shadows[5],
        padding: theme.spacing(2),
    },
}));

const StyledTableCell = withStyles(TableCell,(theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
        padding: theme.spacing(1.2),
    },
    body: {
        fontSize: 14,
    },
}));

const StyledTableRow = withStyles(TableRow,(theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}));



function VolumeModal({open, onClose, data: data2, currency}){
    const {classes} = useStyles();
    const [data, setData] = useState(null);

    useEffect(()=>{
        let myd = [];
        if(data2){
            myd = data2?.map(d=>({id: d?.volumeId, size: d?.size + " " +d?.unit,
                days: parseInt(parseFloat(d.totalCost) / parseFloat(d.avgCostPerDay)), cost: Format.formatNumber(d.avgCostPerDay),LineItemDescription:d?.LineItemDescription}))
        }
        setData(myd);
    }, [data2]);

    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                className={classes.modal}
                closeAfterTransition
                // BackdropComponent={Backdrop}
                // BackdropProps={{
                //     timeout: 500,
                // }}
            >
                <Fade in={open}>
                    <div
                        className={classes.paper3}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Paper elevation={3}>
                            <TableContainer className={classes.container}>
                                <Table
                                    className={classes.table}
                                    stickyHeader
                                    aria-label="sticky table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell
                                                align="left"
                                                style={{ fontSize: 16, fontWeight: "bold" }}
                                            >
                                                Volume id

                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="center"
                                                style={{ fontSize: 16, fontWeight: "bold" }}
                                            >
                                                Description
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="center"
                                                style={{ fontSize: 16, fontWeight: "bold" }}
                                            >
                                                Size of the volume
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="left"
                                                style={{ fontSize: 16, fontWeight: "bold" }}
                                            >
                                                # Days stopped
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="left"
                                                style={{ fontSize: 16, fontWeight: "bold" }}
                                            >
                                                Cost
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {data?.map(r=>(
                                            <StyledTableRow>
                                                <StyledTableCell style={{ }} align="left">
                                                    {r?.id}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ }} align="left">
                                                    {r?.LineItemDescription}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ }} align="left">
                                                    {r?.size}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ }} align="left">
                                                    {r?.days}{r?.days > 1 ? " days" : " day"}
                                                </StyledTableCell>
                                                <StyledTableCell style={{ }} align="left">
                                                    {Format.formatCurrencySymbol(currency) + r?.cost} {" /day"}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}
