import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import {
    getAzureCosmosDBSubSuccess,
    getAzureCosmosDBSubFailure,
    getAzureCosmosDBSub1YTableDataSuccess,
    getAzureCosmosDBSub1YTableDataFailure,
    getAzureCosmosDBSub3MMetricFailure,
    getAzureCosmosDBSub3MMetricSuccess
} from "../actions/actions";
const { REACT_APP_AZURE_COSMOSDB_SUB, REACT_APP_AZURE_COSMOSDB_SUB_1Y_TABLE_DATA, REACT_APP_AZURE_COSMOSDB_SUB_3M_METRICS } = process.env;

function* getAzureCosmosDBSubData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AZURE_COSMOSDB_SUB, {
            params: {
                customerId: payload.customerId,
                subscriptionId: payload.subscriptionId,
                tenantId:payload?.tenantId
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureCosmosDBSubFailure(statusCode));
            } else {
                yield put(getAzureCosmosDBSubSuccess(res.data));
            }
        } else {
            yield put(getAzureCosmosDBSubFailure(res));
        }
    } catch (error) {
        yield put(getAzureCosmosDBSubFailure(error));
    }
}

function* getAzureCosmosDBSub1YTableData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AZURE_COSMOSDB_SUB_1Y_TABLE_DATA, {
            params: {
                customerId: payload.customerId,
                subscriptionId: payload.subscriptionId,
                tenantId:payload?.tenantId
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureCosmosDBSub1YTableDataFailure(statusCode));
            } else {
                yield put(getAzureCosmosDBSub1YTableDataSuccess(res.data));
            }
        } else {
            yield put(getAzureCosmosDBSub1YTableDataFailure(res));
        }
    } catch (error) {
        yield put(getAzureCosmosDBSub1YTableDataFailure(error));
    }
}

function* getAzureCosmosDBSub3MMetric({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AZURE_COSMOSDB_SUB_3M_METRICS, {
            params: {
                customerId: payload.customerId,
                subscriptionId: payload.subscriptionId,
                tenantId:payload?.tenantId
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAzureCosmosDBSub3MMetricFailure(statusCode));
            } else {
                yield put(getAzureCosmosDBSub3MMetricSuccess(res.data));
            }
        } else {
            yield put(getAzureCosmosDBSub3MMetricFailure(res));
        }
    } catch (error) {
        yield put(getAzureCosmosDBSub3MMetricFailure(error));
    }
}

export default function* azureCosmosDBSubSaga() {
    yield takeLatest(types.AZURE_COSMOSDB_SUB_REQUEST, getAzureCosmosDBSubData);
    yield takeLatest(types.AZURE_COSMOSDB_SUB_1Y_TABLE_DATA_REQUEST, getAzureCosmosDBSub1YTableData);
    yield takeLatest(types.AZURE_COSMOSDB_SUB_3M_METRIC_REQUEST, getAzureCosmosDBSub3MMetric);
}
