import * as types from "../actions/types";

const userState = {
    cloudProvider: null,
    billingAccountId: null,
    orgId: null,
    projectId: null,
    charts: null,
    loading: false,
    error: null,
    currency: "",
};

function gcpServerlessReducer(state = userState, { type, payload }) {
    switch (type) {
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                cloudProvider: null,
                billingAccountId: null,
                orgId: null,
                projectId: null,
                charts: null,
                loading: false,
                error: null,
                currency: "",
            };
        }
        case types.GCP_SERVERLESS_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.GCP_SERVERLESS_SUCCESS: {
            const { cloudProvider, charts, billingAccountId, organizationId, projectId, currency } = payload.data;
            return {
                ...state,
                cloudProvider: cloudProvider,
                billingAccountId: billingAccountId,
                orgId: organizationId,
                projectId: projectId,
                currency: currency,
                charts: charts,
                loading: false,
            };
        }
        case types.GCP_SERVERLESS_FAIL: {
            return {
                ...state,
                loading: false,
                // charts: null,
                error: payload,
            };
        }

        default:
            return state;
    }
}
export default gcpServerlessReducer;
