import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getAwsRecommendationSummarySuccess, getAwsRecommendationSummaryFailure,getAwsAccountRecommendationSummarySuccess,getAwsAccountRecommendationSummaryFailure } from "../actions/actions";
const { REACT_APP_GET_AWS_RECOMMENDATION_SUMMARY,REACT_APP_GET_AWS_ACCOUNT_RECOMMENDATION_SUMMARY } = process.env;

function* getawsRecommendationSummaryData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_GET_AWS_RECOMMENDATION_SUMMARY, {
            params: {
                customerId: payload.customerId,
            },
        });
        const { statusCode } = res.data;
        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAwsRecommendationSummaryFailure(statusCode));
            } else {
                yield put(getAwsRecommendationSummarySuccess(res.data));
            }
        } else {
            yield put(getAwsRecommendationSummaryFailure(res));
        }
    } catch (error) {
        yield put(getAwsRecommendationSummaryFailure(error));
    }
}

function* getawsAccountRecommendationSummaryData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_GET_AWS_ACCOUNT_RECOMMENDATION_SUMMARY, {
            params: {
                customerId: payload.customerId,
                tenantId: payload.tenantId,
                subscriptionId: payload.subscriptionId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAwsAccountRecommendationSummaryFailure(statusCode));
            } else {
                yield put(getAwsAccountRecommendationSummarySuccess(res.data));
            }
        } else {
            yield put(getAwsAccountRecommendationSummaryFailure(res));
        }
    } catch (error) {
        yield put(getAwsAccountRecommendationSummaryFailure(error));
    }
}

export default function* awsRecommendationSummarySaga() {
    
    yield takeLatest(types.AWS_RECOMMENDATION_SUMMARY_REQUEST, getawsRecommendationSummaryData);
    yield takeLatest(types.AWS_ACCOUNT_RECOMMENDATION_SUMMARY_REQUEST, getawsAccountRecommendationSummaryData);
}
