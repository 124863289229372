import * as types from "../actions/types";

const userState = {
    allServicesData: null,
    error: null,
    loading: false,
};

function allServicesReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.ALL_SERVICES_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.ALL_SERVICES_SUCCESS: {
            return {
                ...state,
                allServicesData: payload.data,
                loading: false,
            };
        }
        case types.ALL_SERVICES_FAILURE: {
            return {
                ...state,
                loading: false,
                allServicesData: null,
                error: payload,
            };
        }

        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                allServicesData: null,
                error: null,
                loading: false,
            };
        }

        default:
            return state;
    }
}
export default allServicesReducer;
