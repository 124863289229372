import React, { useState, useEffect } from "react";
import { LogLevel,InteractionStatus } from "@azure/msal-browser";
import { useDispatch,useSelector } from "react-redux";
import { useMsal, useAccount } from "@azure/msal-react";
import { signInRequest } from "redux/actions/actions";

export default function MsalReactAuth() {
    const dispatch = useDispatch();
    const { instance, accounts, inProgress } = useMsal();
    const [loading, setLoading] = useState(false);
    const token = useSelector((state) => state.userDetailsReducer.token);

    const LoginHandler = () => {
        try {
            instance.loginRedirect(loginRequest);
        } catch (err) {
            console.log(err);
        }
    };
    const LogoutHandler = () => {
        localStorage.removeItem("team");
        localStorage.removeItem("role");
        try {
            dispatch({
                type: "SIGN_OUT",
            });
            instance.logout();
            //console.log(logoutResponse);
        } catch (err) {
            console.log(err);
        }
    };


    // useEffect(() => {
    //     setLoading(true);
    //     try {
    //         instance
    //             .handleRedirectPromise()
    //             .then((tokenResponse) => {
    //                  console.log("tokenResponse == ", tokenResponse);
    //                 setLoading(false);
    //                 // Check if the tokenResponse is null
    //                 // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
    //                 // If the tokenResponse === null, you are not coming back from an auth redirect.
    //             })
    //             .catch(async (error) => {
    //                 console.log("error tokenResponse == ", error);
    //                // await handleLoginErrorAsync(error);
    //                 setLoading(false);
    //                 // handle error, either in the library or coming back from the server
    //             })
    //             .finally(() => setLoading(false));
    //     } catch (error) {
    //         console.log("promise error == ",error);
    //     }
    // }, [instance,inProgress,accounts]);

    //tokens

    // const callingSIgninApi = () => {
    //     dispatch(signInRequest(token));
    // }

    useEffect(() => {
         setLoading(true);
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        try {
            //console.log("ddd == ", InteractionStatus, inProgress);
            if (inProgress == "none" && accounts.length > 0) {
                instance
                    .acquireTokenSilent(request)
                    .then((response) => {
                       // console.log("before idtoken == : " + JSON.stringify(response));
                        if (response) {
                            if (token == null) {
                                dispatch({ type: "ADB2C_idToken", payload: response?.idToken });
                                dispatch(signInRequest(response?.idToken));
                                setLoading(false);
                              }
                            
                        }
                    })
                    .catch((e) => {
                        console.log("count catch");
                        instance
                            .acquireTokenRedirect(request)
                            .then((response) => {
                               if (token == null) {
                                   dispatch({ type: "ADB2C_idToken", payload: response?.idToken });
                                   dispatch(signInRequest(response?.idToken));
                                    setLoading(false);
                               }
                               
                            })
                            .catch((e) => console.log("e =", e));
                    });
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.log("catch try error == ", error);
        }
    }, [inProgress,accounts,instance]);

    return {
        LoginHandler,
        LogoutHandler,
        loading,
    };
}

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_ADB2C_CLIENT_ID,
        authority: process.env.REACT_APP_ADB2C_SIGNIN_AUTHORITY,
        knownAuthorities: process.env.REACT_APP_ADB2C_DOMAIN_AUTHORITY?.split(","),
        redirectUri: process.env.REACT_APP_REDIRECT_URL,
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["openid","offline_access"]
};

