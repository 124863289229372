import React, { useEffect, useState } from "react";
import { Paper, Grid } from "@mui/material";
import { makeStyles, withStyles } from "MakeWithStyles";
import { MainContainer } from "./style";
import { resourcesExtraction } from "./AzureRecommendation";
import ResoucesIcon from "../../images/resource_group.svg";
import AllRecommend from "../../images/All-Resources.svg";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    padding: 20,
  },
}));

function TotalRecommend({ data, allRec }) {
  const {classes} = useStyles();
  const [bar, setBar] = useState({
    highWidh: null,
    mediumWidth: null,
    lowWidth: null,
  });

  const [recommend, setRecommend] = useState({
    Security: "",
    Availability: "",
    excellence: "",
    Cost: "",
    Performance: "",
    TotalRecommendations: "",
    TotalHighImpacts: "",
    TotalMediumImpacts: "",
    TotalLowImpacts: "",
    TotalResource: "",
  });
  const [total, setTotal] = useState({
    high: "",
    medium: "",
    low: "",
  });

  useEffect(() => {
    recommendCalculation(data);
  }, [data]);

  async function CalculateBar(h, m, l) {
    let sum = h + m + l;

    setBar({
      highWidh: (h / sum) * 500,
      mediumWidth: (m / sum) * 500,
      lowWidth: (l / sum) * 500,
    });
  }

  const recommendCalculation = async (values) => {
    let data = await resourcesExtraction(values);
    const { newArray, totalIR } = data;
    let totalRecommendations = 0;
    let totalHighImpacts = 0;
    let totalMediumImpacts = 0;
    let totalLowImpacts = 0;
    try {
      let highSecurity = 0;
      let mediumSecurity = 0;
      let lowSecurity = 0;
      let highAvail = 0;
      let mediumAvail = 0;
      let lowAvail = 0;
      let highExcel = 0;
      let mediumExcel = 0;
      let lowExcel = 0;
      let highCost = 0;
      let mediumCost = 0;
      let lowCost = 0;
      let highPer = 0;
      let mediumPer = 0;
      let lowPer = 0;
      let securityNumber = 0;
      let availabilityNumber = 0;
      let excellenceNumber = 0;
      let costNumber = 0;
      let performanceNumber = 0;

      if (newArray.length > 0) {
        for (let i = 0; i < newArray.length; i++) {
          if (newArray[i].category === "Security") {
            securityNumber++;
            if (newArray[i].impact === "High") {
              highSecurity++;
            }
            if (newArray[i].impact === "Medium") {
              mediumSecurity++;
            }
            if (newArray[i].impact === "Low") {
              lowSecurity++;
            }
          }
          if (newArray[i].category === "HighAvailability") {
            availabilityNumber++;
            if (newArray[i].impact === "High") {
              highAvail++;
            }
            if (newArray[i].impact === "Medium") {
              mediumAvail++;
            }
            if (newArray[i].impact === "Low") {
              lowAvail++;
            }
          }
          if (newArray[i].category === "OperationalExcellence") {
            excellenceNumber++;
            if (newArray[i].impact === "High") {
              highExcel++;
            }
            if (newArray[i].impact === "Medium") {
              mediumExcel++;
            }
            if (newArray[i].impact === "Low") {
              lowExcel++;
            }
          }
          if (newArray[i].category === "Cost") {
            costNumber++;
            if (newArray[i].impact === "High") {
              highCost++;
            }
            if (newArray[i].impact === "Medium") {
              mediumCost++;
            }
            if (newArray[i].impact === "Low") {
              lowCost++;
            }
          }
          if (newArray[i].category === "Performance") {
            performanceNumber++;
            if (newArray[i].impact === "High") {
              highPer++;
            }
            if (newArray[i].impact === "Medium") {
              mediumPer++;
            }
            if (newArray[i].impact === "Low") {
              lowPer++;
            }
          }
        }

        totalHighImpacts =
          highCost + highSecurity + highAvail + highExcel + highPer;
        totalMediumImpacts =
          mediumCost + mediumSecurity + mediumAvail + mediumExcel + mediumPer;
        totalLowImpacts = lowCost + lowSecurity + lowAvail + lowExcel + lowPer;
        totalRecommendations =
          totalHighImpacts + totalMediumImpacts + totalLowImpacts;

        setRecommend({
          Security: securityNumber,
          Availability: availabilityNumber,
          excellence: excellenceNumber,
          Cost: costNumber,
          Performance: performanceNumber,
          TotalRecommendations: totalRecommendations,
          TotalHighImpacts: totalHighImpacts,
          TotalMediumImpacts: totalMediumImpacts,
          TotalLowImpacts: totalLowImpacts,
          TotalResource: totalIR,
        });

        setTotal({
          high: totalHighImpacts,
          medium: totalMediumImpacts,
          low: totalLowImpacts,
        });
      }
    } catch (error) {}
    await CalculateBar(totalHighImpacts, totalMediumImpacts, totalLowImpacts);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={3}>
            <MainContainer>
              <div>
                {recommend && (
                  <div style={{ display: "flex" }}>
                    <img
                      src={AllRecommend}
                      width={55}
                      height={55}
                      style={{ marginTop: -10, marginRight: -8 }}
                    />
                    <h4 style={{ fontFamily: "Poppins-SemiBold" }}>
                      Total recommendations
                    </h4>
                  </div>
                )}

                <h3>{recommend.TotalRecommendations}</h3>
              </div>
              <div style={{ width: 500, height: 20 }}>
                <div
                  style={{
                    display: "flex",
                    width: 500,
                    height: 20,
                    marginTop: 5,
                  }}
                >
                  <div style={{ width: bar.highWidh, background: "#C85250" }} />
                  <div
                    style={{ width: bar.mediumWidth, background: "#FAD02C" }}
                  />
                  <div style={{ width: bar.lowWidth, background: "#41729F" }} />
                </div>
                {recommend && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                    }}
                  >
                    <div style={{}}>
                      <h6 style={{ color: "#C85250" }}>High impact</h6>
                      <b>{recommend.TotalHighImpacts}</b>
                    </div>

                    <div style={{}}>
                      <h6 style={{ color: "#FAD02C" }}>Medium impact</h6>
                      <b>{recommend.TotalMediumImpacts}</b>
                    </div>

                    <div style={{}}>
                      <h6 style={{ color: "#41729F" }}>Low impact</h6>
                      <b>{recommend.TotalLowImpacts}</b>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <div style={{ display: "flex" }}>
                  <img src={ResoucesIcon} width={40} height={40} />
                  <h4 style={{ fontFamily: "Poppins-SemiBold" }}>
                    Impacted resources
                  </h4>
                </div>

                <h3>
                  {allRec != null
                    ? allRec.impectedResources
                    : recommend.TotalResource}
                </h3>
              </div>
            </MainContainer>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default TotalRecommend;
