import { takeLatest, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "../../connection/axios";
import { getAwsRDSSuccess, getAwsRDSFailure,getAwsRDSAccountSuccess,getAwsRDSAccountFailure } from "../actions/actions";
const { REACT_APP_AWS_RDS_ACCOUNT, REACT_APP_AWS_RDS_HOME } = process.env;

function* getawsRDSSagaData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AWS_RDS_HOME, {
            params: {
                customerId: payload.customerId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAwsRDSFailure(statusCode));
            } else {
                yield put(getAwsRDSSuccess(res.data));
            }
        } else {
            yield put(getAwsRDSFailure(res));
        }
    } catch (error) {
        yield put(getAwsRDSFailure(error));
    }
}

function* getawsRDSAccountSagaData({ payload }) {
    try {
        const res = yield call(axios.get, REACT_APP_AWS_RDS_ACCOUNT, {
            params: {
                customerId: payload.customerId,
                tenantId: payload.tenantId,
                subscriptionId: payload.subscriptionId,
            },
        });

        const { statusCode } = res.data;

        if ("statusCode" in res.data) {
            if (statusCode !== 200) {
                yield put(getAwsRDSAccountFailure(statusCode));
            } else {
                yield put(getAwsRDSAccountSuccess(res.data));
            }
        } else {
            yield put(getAwsRDSAccountFailure(res));
        }
    } catch (error) {
        yield put(getAwsRDSAccountFailure(error));
    }
}

export default function* awsRDSSaga() {
    yield takeLatest(types.AWS_RDS_REQUEST, getawsRDSSagaData);
    yield takeLatest(types.AWS_RDS_ACCOUNT_REQUEST, getawsRDSAccountSagaData);
}
