import React, { useEffect, useState } from "react";
import { SpecialZoomLevel, Viewer, ZoomEvent,OpenFile } from "@react-pdf-viewer/core";
import { printPlugin } from "@react-pdf-viewer/print";
import { fullScreenPlugin } from "@react-pdf-viewer/full-screen";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import ReportList from "./ReportList";
import Report_Icon from "../../images/resources/Recovery_Services_Backup_Report.svg";
import { Assessment } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { getReportNameRequest, accountRequest } from "redux/actions/actions";
import { Grid, LinearProgress } from "@mui/material";
import { Link } from "react-router-dom";
import "./style.css";
import Colors from "../../styles/color";
import { Col } from "react-bootstrap";
import ReportLoader from "components/loaders/ReportLoader";
import {makeStyles} from "MakeWithStyles";
import RoutesList from "../../routes/RoutesList";

const useStyles = makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
    },
    paper: {
        padding: theme.spacing(2),
    },
}));

const Reports = () => {
    const dispatch = useDispatch();
    const {classes} = useStyles();

    const allAccountStatus = useSelector((state) => state.accountStatusReducer.allAccountStatus);
    const customerId = useSelector((state) => (state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId));
    const reportsName = useSelector((state) => state.reportsReducer.reportsName);
    const loading = useSelector((state) => state.reportsReducer.loading);

    useEffect(() => {
        if (allAccountStatus == null) {
            dispatch(accountRequest(customerId));
        }
    }, [customerId]);

    useEffect(() => {
        if (allAccountStatus != null && allAccountStatus) {
            if (reportsName == null) {
                dispatch(getReportNameRequest(customerId));
            }
        }
    }, [customerId, allAccountStatus]);


    return loading ? (
        <div className={classes.root}>
            <ReportLoader/>
        </div>
    ) : allAccountStatus == null ? (
        <div className={classes.root}>
            <ReportLoader/>
        </div>
    ) : allAccountStatus ? (
        <div
            style={{
                padding: 20,
                backgroundColor: Colors.background,
                minHeight: "95vh",
            }}
        >
            <div style={{ display: "flex" }}>
                <Assessment />
                <h4>Reports</h4>
            </div>
            {reportsName?.Code!=1002 ?
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    //  alignItems: "center",
                    marginTop: 20,
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {reportsName && "weekly" in reportsName ? (
                            reportsName.weekly.weekly.length > 0 ? (
                                <div>
                                    <h5>Weekly reports</h5>
                                    <ReportList
                                        column={[
                                            { id: "name", label: " Weekly report", align: "left" },
                                            // { id: "startDate", label: "Start Date", align: "left" },
                                            // { id: "endDate", label: "End Date", align: "left" },
                                            // { id: "dateCreated", label: "Date Created", align: "left" },
                                            { id: "action", label: "Action", align: "center" },
                                        ]}
                                        data={reportsName?.weekly?.weekly}
                                        param={reportsName?.weekly}
                                        customerId={customerId}
                                    />
                                </div>
                            ) : null
                        ) : null}
                    </Grid>
                    <Grid item xs={6}>
                        {reportsName && "monthly" in reportsName ? (
                            reportsName.monthly.monthly.length > 0 ? (
                                <div>
                                    <h5>Monthly reports</h5>
                                    <ReportList
                                        column={[
                                            { id: "name", label: " Monthly report", align: "left" },
                                            // { id: "startDate", label: "Start Date", align: "left" },
                                            // { id: "endDate", label: "End Date", align: "left" },
                                            // { id: "dateCreated", label: "Date Created", align: "left" },
                                            { id: "action", label: "Action", align: "center" },
                                        ]}
                                        data={reportsName.monthly.monthly}
                                        param={reportsName.monthly}
                                        customerId={customerId}
                                    />
                                </div>
                            ) : null
                        ) : null}
                    </Grid>
                </Grid>
            </div>
            :  <span style={{ display: "flex", justifyContent: "center" }}>No data found</span>}
        </div>
    ) : (
        <div style={{ padding: 20 }}>
            <div style={{ display: "flex" }}>
                <Assessment />
                <h4>Reports</h4>
            </div>
            <div className="addAccountDiv">
                <Link to={RoutesList.ACCOUNTS}>
                    <h5 className="addAcount">
                        You don't have any <span style={{ fontWeight: "bold" }}>Account</span> added, please add your cloud accounts
                    </h5>
                </Link>
            </div>
        </div>
    );
};

export default Reports;

export function Base64(props) {
    const { base, name,type } = props;
    const printPluginInstance = printPlugin();
    const { PrintButton } = printPluginInstance;

    const fullScreenPluginInstance = fullScreenPlugin();
    const { EnterFullScreenButton } = fullScreenPluginInstance;

    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file) => {
            // `file.name` is the URL of opened file
           // console.log("file file == ",file);
           // const fileName = file.name.substring(file.name.lastIndexOf('/') + 1);
            return `CloudTrakr-${type}-${name}`;
        },
    });
    const { DownloadButton } = getFilePluginInstance;

    const baseB64 = `data:application/pdf;base64,${base}`;

    const base64toBlob = (data) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const pdfContentType = "application/pdf";
        const base64WithoutPrefix = data.substr(`data:${pdfContentType};base64,`.length);

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: pdfContentType });
    };

    const url = URL.createObjectURL(base64toBlob(baseB64));


    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
            }}
        >
            <div
                style={{
                    alignItems: "center",
                    backgroundColor: "#cfdac8",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    // padding: "4px",
                    justifyContent: "space-between",
                }}
            >
                <EnterFullScreenButton />
                <div style={{ display: "flex" }}>
                    <ZoomOutButton />
                    <ZoomPopover />
                    <ZoomInButton />
                </div>
                <div style={{ display: "flex", marginRight: 10 }}>
                    <DownloadButton />
                    {/* <PrintButton /> */}
                </div>
            </div>

            <div
                style={{
                    flex: 1,
                    overflow: "hidden",
                }}
            >
                <Viewer
                    fileUrl={url}
                    defaultScale={SpecialZoomLevel.PageWidth}
                    plugins={[
                        zoomPluginInstance,
                        fullScreenPluginInstance,
                        getFilePluginInstance,
                        // printPluginInstance,
                    ]}
                />
            </div>
        </div>
    );
}
