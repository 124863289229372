import { takeLatest, call, put } from "redux-saga/effects";
import {SET_CLOUD_ACTIVE_TAB, SET_CLOUD_ACTIVE_TAB_REQUEST} from "../actions/types";

export default function* appStateSaga() {
  yield takeLatest(SET_CLOUD_ACTIVE_TAB_REQUEST, function* ({ payload }) {
    yield put({
      type: SET_CLOUD_ACTIVE_TAB,
      payload
    });
  });
}
